// eslint-disable-next-line no-restricted-imports
import {
  YearMonth,
  YMInterval,
} from '@watershed/shared-universal/utils/YearMonth';
import type {
  FilterValue,
  GQBiDataResult,
} from '@watershed/shared-universal/customScalarGqlTypes.ts';
import type { Expression } from '@watershed/shared-universal/biV2';
import { DateTime } from 'luxon';
import { JSONSchema7 } from 'json-schema';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Untyped according to the GraphQL spec, but typed according to codegen. see it's type def in workspaces/shared-universal/customScalarGqlTypes.ts */
  BiDataResult: { input: GQBiDataResult; output: GQBiDataResult };
  /**
   * The 'Date' scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: Date; output: Date };
  /**
   * The 'DateTime' scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: Date; output: Date };
  /** Untyped according to the GraphQL spec, but typed according to codegen as string | boolean. see it's type def in workspaces/shared-universal/customScalarGqlTypes.ts */
  FilterValue: { input: FilterValue; output: FilterValue };
  Int53: { input: number; output: number };
  /**
   * The 'JSONSchema' scalar type represents a JSONSchema
   * value as specified by
   * [JSONSchema Draft 7](https://datatracker.ietf.org/doc/html/draft-handrews-json-schema-01).
   */
  JSONSchema: { input: JSONSchema7; output: JSONSchema7 };
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: any; output: any };
  /**
   * Similar in concept to JSONString, but not (double-)encoded as a string. It's
   * just whatever data the server decided to send you. I hope you can make sense
   * of it!
   */
  UntypedData: { input: any; output: any };
  YMInterval: { input: YMInterval; output: YMInterval };
  YearMonth: { input: YearMonth; output: YearMonth };
};

export type GQAcceptFinanceImportDiffInput = {
  financeImportId: Scalars['ID']['input'];
};

export type GQAcceptFinanceImportDiffPayload = {
  __typename?: 'AcceptFinanceImportDiffPayload';
  financeImport: GQFinanceImport;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQAcceptFootprintTestSuiteExecutionResultsInput = {
  footprintAssemblyStep: GQFootprintAssemblyStep;
  footprintTestSuiteConfigId: Scalars['ID']['input'];
  footprintTestSuiteExecutionId: Scalars['ID']['input'];
};

export type GQAcceptFootprintTestSuiteExecutionResultsPayload = {
  __typename?: 'AcceptFootprintTestSuiteExecutionResultsPayload';
  footprintTestExecutionStepResult: GQFootprintTestExecutionStepResult;
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQAcceptMethodologyTestSuiteExecutionResultsInput = {
  methodologyTestSuiteExecutionId: Scalars['ID']['input'];
};

export type GQAcceptMethodologyTestSuiteExecutionResultsPayload = {
  __typename?: 'AcceptMethodologyTestSuiteExecutionResultsPayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQAcceptOrgStructureVersionUploadInput = {
  orgStructureVersionUploadId: Scalars['ID']['input'];
};

export type GQAcceptOrgStructureVersionUploadPayload = {
  __typename?: 'AcceptOrgStructureVersionUploadPayload';
  orgStructureVersionUpload: GQOrgStructureVersionUpload;
};

export type GQAcceptReportAnswerVerifierFailuresInput = {
  reportAnswerVerifierFailureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type GQAcceptReportAnswerVerifierFailuresPayload = {
  __typename?: 'AcceptReportAnswerVerifierFailuresPayload';
  reportAnswerVerifierFailureIds: Array<Maybe<Scalars['ID']['output']>>;
  updatedVerifierFailures: Array<GQReportAnswerVerifierFailure>;
};

export type GQActivityBasedInitiativeImpact = {
  __typename?: 'ActivityBasedInitiativeImpact';
  filterableReductionDifference: Array<GQFilterableReducibleFootprintAggregate>;
  reducedKgco2eFraction: Scalars['Float']['output'];
};

export type GQActivityDataExtractor = GQIdInterface & {
  __typename?: 'ActivityDataExtractor';
  activityDataTable: GQActivityDataTable;
  bartActivityType: Maybe<Scalars['String']['output']>;
  bartId: Maybe<Scalars['ID']['output']>;
  cleanAdtOutput: Maybe<GQParquetData>;
  createdAt: Scalars['DateTime']['output'];
  duckdbVersion: Scalars['String']['output'];
  error: Maybe<Scalars['String']['output']>;
  facilitiesSurveyStatus: GQAdeFacilitiesSurveyStatus;
  generated: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  kind: GQActivityDataExtractorKind;
  name: Scalars['String']['output'];
  published: Maybe<Scalars['Boolean']['output']>;
  pushedBuildings: Maybe<Scalars['Boolean']['output']>;
  runSqlOutput: Maybe<GQParquetData>;
  sql: Scalars['String']['output'];
  transformedOutput: Maybe<GQParquetData>;
  user: Maybe<GQUser>;
  userUploadedTables: Array<GQUserUploadedTable>;
  warnings: Maybe<Scalars['JSONString']['output']>;
};

export type GQActivityDataExtractorConnection = {
  __typename?: 'ActivityDataExtractorConnection';
  edges: Array<Maybe<GQActivityDataExtractorEdge>>;
  pageInfo: GQPageInfo;
};

export type GQActivityDataExtractorEdge = {
  __typename?: 'ActivityDataExtractorEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQActivityDataExtractor>;
};

export const GQActivityDataExtractorKind = {
  Bart: 'BART',
  Cadt: 'CADT',
  Reproduction: 'REPRODUCTION',
  Scratchpad: 'SCRATCHPAD',
} as const;

export type GQActivityDataExtractorKind =
  (typeof GQActivityDataExtractorKind)[keyof typeof GQActivityDataExtractorKind];
export type GQActivityDataTable = GQContextRelationInterface &
  GQIdInterface & {
    __typename?: 'ActivityDataTable';
    activityDataExtractors: GQActivityDataExtractorConnection;
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    autoBartActivityType: Maybe<Scalars['String']['output']>;
    autoBartKind: Maybe<GQAutoBartKind>;
    autoBartTaskApprovalState: GQAutoBartTaskApprovalState;
    connectedToAutoBart: Scalars['Boolean']['output'];
    datasets: Array<GQDataset>;
    datasources: Array<GQDatasource>;
    draftAutoBartHasPreviousCustomExtractor: Scalars['Boolean']['output'];
    draftBartExtractor: Maybe<GQActivityDataExtractor>;
    draftCadtExtractor: Maybe<GQActivityDataExtractor>;
    draftScratchpadExtractor: Maybe<GQActivityDataExtractor>;
    filterInterval: Scalars['YMInterval']['output'];
    id: Scalars['ID']['output'];
    lastRefreshedAt: Maybe<Scalars['DateTime']['output']>;
    latestBartActivityType: Maybe<Scalars['String']['output']>;
    measurementProject: Maybe<GQMeasurementProject>;
    name: Scalars['String']['output'];
    primaryDatasource: Maybe<GQDatasource>;
    publishedExtractor: Maybe<GQActivityDataExtractor>;
    recommendedActivityTypes: Array<Scalars['String']['output']>;
    /** @deprecated Not used. Delete after 2024-11-05. */
    reviews: Array<GQActivityDataTableReview>;
    stage: GQActivityDataTableStageKind;
  };

export type GQActivityDataTableActivityDataExtractorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQActivityDataTableConnection = {
  __typename?: 'ActivityDataTableConnection';
  edges: Array<Maybe<GQActivityDataTableEdge>>;
  pageInfo: GQPageInfo;
};

export type GQActivityDataTableEdge = {
  __typename?: 'ActivityDataTableEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQActivityDataTable>;
};

export type GQActivityDataTableReview = GQIdInterface & {
  __typename?: 'ActivityDataTableReview';
  activityDataTableId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  reviewer: GQUser;
  watershedReviewerId: Scalars['ID']['output'];
};

export const GQActivityDataTableStageKind = {
  BuildingsIdiUpload: 'BuildingsIdiUpload',
  ForFootprint: 'ForFootprint',
  PreBiodiversityTransform: 'PreBiodiversityTransform',
  PreMapping: 'PreMapping',
} as const;

export type GQActivityDataTableStageKind =
  (typeof GQActivityDataTableStageKind)[keyof typeof GQActivityDataTableStageKind];
export type GQActivityTypeSchema = {
  __typename?: 'ActivityTypeSchema';
  activityType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  schema: GQCanonicalSchema;
};

export type GQAddCalculationTagToReferenceDataInput = {
  sourceId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type GQAddCalculationTagToReferenceDataPayload = {
  __typename?: 'AddCalculationTagToReferenceDataPayload';
  success: Scalars['Boolean']['output'];
};

export type GQAddCalculationTagToStableModelInput = {
  stableId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type GQAddCalculationTagToStableModelPayload = {
  __typename?: 'AddCalculationTagToStableModelPayload';
  success: Scalars['Boolean']['output'];
};

export type GQAddCompaniesToPeerCompanyGroupsPayload = {
  __typename?: 'AddCompaniesToPeerCompanyGroupsPayload';
  peerCompanyGroups: Array<GQPeerCompanyGroup>;
};

export type GQAddEmissionsModelToOrgInput = {
  orgId: Scalars['ID']['input'];
  stableId: Scalars['ID']['input'];
};

export type GQAddEmissionsModelToOrgPayload = {
  __typename?: 'AddEmissionsModelToOrgPayload';
  emissionsModelStable: GQEmissionsModelStable;
};

export type GQAddMarketplaceSupplierPointOfContactConnectionInput = {
  pointOfContactId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type GQAddMarketplaceSupplierPointOfContactConnectionPayload = {
  __typename?: 'AddMarketplaceSupplierPointOfContactConnectionPayload';
  marketplacePointOfContact: GQMarketplacePointOfContact;
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQAddMarketplaceSupplierPointOfContactInput = {
  pointOfContactEmail: Scalars['String']['input'];
  pointOfContactName: Scalars['String']['input'];
  supplierId: Scalars['ID']['input'];
};

export type GQAddMarketplaceSupplierPointOfContactPayload = {
  __typename?: 'AddMarketplaceSupplierPointOfContactPayload';
  marketplacePointOfContact: GQMarketplacePointOfContact;
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQAddMarketplaceSupplierWatershedEmployeeInput = {
  supplierId: Scalars['ID']['input'];
  watershedEmployeeId: Scalars['ID']['input'];
};

export type GQAddMarketplaceSupplierWatershedEmployeePayload = {
  __typename?: 'AddMarketplaceSupplierWatershedEmployeePayload';
  marketplaceSupplier: GQMarketplaceSupplier;
  watershedEmployee: GQWatershedEmployee;
};

export type GQAddOrgAccessToReleaseInput = {
  orgIds: Array<Scalars['ID']['input']>;
  releaseId: Scalars['ID']['input'];
};

export type GQAddTestRowsToTestSuiteBartInput = {
  activityRows: Array<Scalars['JSONString']['input']>;
  measurementTestSuiteBartId: Scalars['ID']['input'];
};

export type GQAddTestRowsToTestSuiteBartPayload = {
  __typename?: 'AddTestRowsToTestSuiteBartPayload';
  measurementTestSuiteBart: GQMeasurementTestSuiteBart;
};

export type GQAddWatershedEmployeeInput = {
  email: Scalars['String']['input'];
};

export type GQAddWatershedEmployeePayload = {
  __typename?: 'AddWatershedEmployeePayload';
  watershedEmployee: GQWatershedEmployee;
};

export type GQAdditionalQuestionResponse = {
  __typename?: 'AdditionalQuestionResponse';
  answer: Scalars['JSONString']['output'];
  questionId: GQIngestionQuestionKey;
};

export const GQAdeFacilitiesSurveyStatus = {
  NotFacilitiesAdt: 'NotFacilitiesAdt',
  Stale: 'Stale',
  Unknown: 'Unknown',
  UpToDate: 'UpToDate',
} as const;

export type GQAdeFacilitiesSurveyStatus =
  (typeof GQAdeFacilitiesSurveyStatus)[keyof typeof GQAdeFacilitiesSurveyStatus];
export type GQAdminDatasourcePayload = {
  __typename?: 'AdminDatasourcePayload';
  datasource: GQDatasource;
  measurementProject: Maybe<GQMeasurementProject>;
};

export type GQAdminLineageQueryKey = {
  key: Scalars['ID']['input'];
  kind: GQAdminLineageQueryKeyKind;
};

export const GQAdminLineageQueryKeyKind = {
  BartInstanceId: 'BartInstanceId',
  BartStableId: 'BartStableId',
  DataRegistryTableId: 'DataRegistryTableId',
  DataRegistryTableName: 'DataRegistryTableName',
  FileMetadataId: 'FileMetadataId',
  FootprintSnapshotId: 'FootprintSnapshotId',
  UserUploadedTableId: 'UserUploadedTableId',
} as const;

export type GQAdminLineageQueryKeyKind =
  (typeof GQAdminLineageQueryKeyKind)[keyof typeof GQAdminLineageQueryKeyKind];
export type GQAdminSuppliers = {
  __typename?: 'AdminSuppliers';
  suppliersMerged: GQSuppliersData;
};

export const GQAggregateKind = {
  CustomIntensity: 'CustomIntensity',
  Electricity: 'Electricity',
  HeadcountIntensity: 'HeadcountIntensity',
  LocationBased: 'LocationBased',
  OriginalTotal: 'OriginalTotal',
  PercentageOfCurrentView: 'PercentageOfCurrentView',
  PercentageOfTotalFootprint: 'PercentageOfTotalFootprint',
  RevenueIntensity: 'RevenueIntensity',
  Total: 'Total',
} as const;

export type GQAggregateKind =
  (typeof GQAggregateKind)[keyof typeof GQAggregateKind];
export type GQApiKey = {
  __typename?: 'ApiKey';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<GQApiKeyRoleAssignment>;
  secret: Scalars['String']['output'];
};

export type GQApiKeyConnection = {
  __typename?: 'ApiKeyConnection';
  edges: Array<Maybe<GQApiKeyEdge>>;
  pageInfo: GQPageInfo;
};

export type GQApiKeyEdge = {
  __typename?: 'ApiKeyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQApiKey>;
};

export type GQApiKeyRoleAssignment = {
  __typename?: 'ApiKeyRoleAssignment';
  apiKey: GQApiKey;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  org: GQOrganization;
  revokedAt: Maybe<Scalars['DateTime']['output']>;
  role: GQRole;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQApiUpload = {
  __typename?: 'ApiUpload';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQApplyGlobalTagToTestDataInput = {
  rulesListPayload: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
};

export type GQApplyGlobalTagToTestDataPayload = {
  __typename?: 'ApplyGlobalTagToTestDataPayload';
  taggedResultParquetSignedUrl: Scalars['String']['output'];
};

export type GQApplyIntegrationConnectionInput = {
  datasourceIds: Array<Scalars['ID']['input']>;
  integrationConnectionId: Scalars['ID']['input'];
};

export type GQApplyIntegrationConnectionPayload = {
  __typename?: 'ApplyIntegrationConnectionPayload';
  datasources: Array<GQDatasource>;
  integrationConnection: GQIntegrationConnection;
};

export type GQApplyTagsToPipelineResultInput = {
  rulesListPayload: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
  targetPipelineResultId: Scalars['String']['input'];
};

export type GQApplyTagsToPipelineResultPayload = {
  __typename?: 'ApplyTagsToPipelineResultPayload';
  distinctCount: Scalars['Int']['output'];
  taggedResultParquetSignedUrl: Scalars['String']['output'];
};

export const GQApprovalStatus = {
  Approved: 'Approved',
  NotReadyForApproval: 'NotReadyForApproval',
  SubmittedForApproval: 'SubmittedForApproval',
} as const;

export type GQApprovalStatus =
  (typeof GQApprovalStatus)[keyof typeof GQApprovalStatus];
export type GQApprovalTarget = {
  approvalStatus: GQApprovalStatus;
  approvers: Array<GQUserWithApprovalStatus>;
  id: Scalars['ID']['output'];
  lockState: GQApprovalTargetLockState;
};

export const GQApprovalTargetLockState = {
  Locked: 'Locked',
  UnlockRequested: 'UnlockRequested',
  Unlocked: 'Unlocked',
} as const;

export type GQApprovalTargetLockState =
  (typeof GQApprovalTargetLockState)[keyof typeof GQApprovalTargetLockState];
export type GQApproveCtsTransformTestCaseInput = {
  ctsTransformTestRunId: Scalars['ID']['input'];
};

export type GQApproveCtsTransformTestCasePayload = {
  __typename?: 'ApproveCtsTransformTestCasePayload';
  ctsTransformTestCase: GQCustomerTargetSchemaTransformTestCase;
};

export type GQApproveReportAnswerVerifiersInput = {
  reportAnswerVerifierFailureIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type GQApproveReportAnswerVerifiersPayload = {
  __typename?: 'ApproveReportAnswerVerifiersPayload';
  errorMessages: Maybe<Array<GQReportAnswerVerifierErrorMessage>>;
  reportAnswerVerifierFailureIds: Array<Maybe<Scalars['ID']['output']>>;
  updatedAnswers: Array<GQReportAnswer>;
};

export type GQArchivePipelineResultInput = {
  id: Scalars['ID']['input'];
};

export type GQArchivePipelineResultPayload = {
  __typename?: 'ArchivePipelineResultPayload';
  pipelineResult: GQPipelineResult;
};

export type GQArchiveReferenceDataCitationPayload = {
  __typename?: 'ArchiveReferenceDataCitationPayload';
  referenceDataCitation: GQReferenceDataCitation;
};

export type GQArchiveReferenceDataRevisionInput = {
  id: Scalars['ID']['input'];
};

export type GQArchiveReferenceDataRevisionPayload = {
  __typename?: 'ArchiveReferenceDataRevisionPayload';
  referenceDataRevision: GQReferenceDataRevision;
};

export type GQArchiveReferenceDataSourceInput = {
  id: Scalars['ID']['input'];
};

export type GQArchiveReferenceDataSourcePayload = {
  __typename?: 'ArchiveReferenceDataSourcePayload';
  referenceDataSource: GQReferenceDataSource;
};

export type GQArchiveReferenceDataVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQArchiveReferenceDataVersionPayload = {
  __typename?: 'ArchiveReferenceDataVersionPayload';
  referenceDataVersion: GQReferenceDataVersion;
};

export type GQArchiveReportInput = {
  reportId: Scalars['ID']['input'];
};

export type GQArchiveReportPayload = {
  __typename?: 'ArchiveReportPayload';
  report: GQReport;
};

export type GQAssetAutoMatchData = {
  __typename?: 'AssetAutoMatchData';
  autoMatched: Maybe<GQAssetAutoMatched>;
  autoMatches: Array<GQCompanyAutoMatch>;
  id: Scalars['ID']['output'];
  ranAutoMapping: Scalars['Boolean']['output'];
};

export type GQAssetAutoMatched = {
  __typename?: 'AssetAutoMatched';
  confidenceScore: Scalars['Float']['output'];
};

export type GQAssetComment = {
  __typename?: 'AssetComment';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetCommercialRealEstateId: Maybe<Scalars['ID']['output']>;
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  attachments: Maybe<Array<GQAssetCommentAttachment>>;
  commentHistory: Maybe<Scalars['UntypedData']['output']>;
  commentText: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastEdited: Maybe<Scalars['DateTime']['output']>;
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
  userId: Scalars['ID']['output'];
};

export type GQAssetCommentAttachment = {
  __typename?: 'AssetCommentAttachment';
  assetCommentId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  fileMetadata: Maybe<GQFileMetadata>;
  fileMetadataId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQAssetCorporate = GQIFinanceAsset & {
  __typename?: 'AssetCorporate';
  anonymousCompanyUuid: Maybe<Scalars['String']['output']>;
  /** @deprecated fetch in bulk query instead */
  assetAutoMatchData: Maybe<GQAssetAutoMatchData>;
  assetComments: Array<GQAssetComment>;
  assetFinancialData: Maybe<GQAssetFinancialData>;
  assetGroup: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  benchmarks: Array<GQBenchmark>;
  buildingSizeUnit: Maybe<GQBuildingSizeUnit>;
  company: Maybe<GQCompany>;
  companyBea: Maybe<Scalars['String']['output']>;
  companyDunsNumber: Maybe<Scalars['String']['output']>;
  companyEin: Maybe<Scalars['String']['output']>;
  companyId: Maybe<Scalars['ID']['output']>;
  companyIsin: Maybe<Scalars['String']['output']>;
  companyLei: Maybe<Scalars['String']['output']>;
  companyLocalRegistryId: Maybe<Scalars['String']['output']>;
  companySAndPId: Maybe<Scalars['String']['output']>;
  companyTicker: Maybe<Scalars['String']['output']>;
  companyUrl: Maybe<Scalars['String']['output']>;
  companyVatId: Maybe<Scalars['String']['output']>;
  contacts: Array<GQSupplierContact>;
  countryAlpha2: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  disclosures: Array<GQDisclosure>;
  /** @deprecated Fetch from emissionsYear instead, deprecate after 2/21/25 */
  emissionsByGhgCategory: Maybe<GQEmissionsByGhgCategoryForFinance>;
  engagementTasks: Array<GQEngagementTask>;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  naicsCode: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  selfBenchmark: Maybe<GQBenchmark>;
  /** @deprecated use tasks */
  surveys: Array<GQCompanySurvey>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetCorporateAssetFinancialDataArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateEmissionsByGhgCategoryArgs = {
  emissionsYearId: InputMaybe<Scalars['ID']['input']>;
  year: Scalars['Int']['input'];
};

export type GQAssetCorporateFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateSelfBenchmarkArgs = {
  year: Scalars['Int']['input'];
};

export type GQAssetFinancialData = {
  __typename?: 'AssetFinancialData';
  assetValue: Maybe<Scalars['Float']['output']>;
  assetValueCurrencyCode: Maybe<GQCurrencyCode>;
  id: Scalars['ID']['output'];
  isPrivate: Maybe<Scalars['Boolean']['output']>;
  revenue: Maybe<Scalars['Float']['output']>;
  revenueCurrencyConversionRate: Maybe<Scalars['Float']['output']>;
  usedSAndPAssetValue: Maybe<Scalars['Boolean']['output']>;
  usedSAndPRevenue: Maybe<Scalars['Boolean']['output']>;
};

export type GQAssetGroup = GQIFinanceAsset & {
  __typename?: 'AssetGroup';
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Array<GQAssetGroupAsset>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetGroupAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupAsset =
  | GQAssetCorporate
  | GQAssetGroup
  | GQAssetRealEstate;

export type GQAssetIndustryCodeData = {
  __typename?: 'AssetIndustryCodeData';
  id: Scalars['ID']['output'];
  industryCode: Maybe<Scalars['String']['output']>;
  usedSAndPIndustryCode: Maybe<Scalars['Boolean']['output']>;
};

export type GQAssetPersonalMotorVehicleInsurance = GQIFinanceAsset & {
  __typename?: 'AssetPersonalMotorVehicleInsurance';
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetPersonalMotorVehicleInsuranceAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetPersonalMotorVehicleInsuranceAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetPersonalMotorVehicleInsuranceFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetPersonalMotorVehicleInsuranceRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstate = GQIFinanceAsset & {
  __typename?: 'AssetRealEstate';
  assetComments: Array<GQAssetComment>;
  assetGroup: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  buildingKind: Maybe<Scalars['String']['output']>;
  /** @deprecated Use name instead */
  buildingName: Scalars['String']['output'];
  buildingSizeUnit: Maybe<GQBuildingSizeUnit>;
  city: Maybe<Scalars['String']['output']>;
  companyId: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use countryAlpha2 instead */
  country: Maybe<Scalars['String']['output']>;
  countryAlpha2: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  /** @deprecated Use buildingSizeUnit instead */
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetRealEstateAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstateAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstateFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstateRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBond = GQIFinanceAsset & {
  __typename?: 'AssetSovereignBond';
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  sovereignEntity: Scalars['String']['output'];
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetSovereignBondAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBondAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBondFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBondRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export const GQAssetYearStatus = {
  Contracting: 'Contracting',
  Estimation: 'Estimation',
  Measurement: 'Measurement',
  Survey: 'Survey',
} as const;

export type GQAssetYearStatus =
  (typeof GQAssetYearStatus)[keyof typeof GQAssetYearStatus];
export type GQAssumptionChangeset = {
  __typename?: 'AssumptionChangeset';
  authors: Array<GQWatershedEmployee>;
  changesetType: Scalars['String']['output'];
  committedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  recordCount: Scalars['Int']['output'];
  records: Array<GQAssumptionRecordForAdmin>;
};

export type GQAssumptionDescription = {
  __typename?: 'AssumptionDescription';
  assumptionId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userVisibleConversionDescription: Scalars['String']['output'];
};

export type GQAssumptionRecordForAdmin = {
  __typename?: 'AssumptionRecordForAdmin';
  assumptionActivityType: Maybe<Scalars['String']['output']>;
  assumptionDescription: Maybe<GQAssumptionDescription>;
  assumptionId: Scalars['ID']['output'];
  assumptionSource: GQAssumptionSource;
  assumptionSourceId: Maybe<Scalars['String']['output']>;
  assumptionSourceMemo: Maybe<Scalars['String']['output']>;
  authorId: Scalars['ID']['output'];
  categoryId: Maybe<Scalars['String']['output']>;
  conversionDescription: Maybe<Scalars['String']['output']>;
  conversionMultiplier: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  endMonth: Maybe<Scalars['YearMonth']['output']>;
  excludeFromResults: Maybe<Scalars['Boolean']['output']>;
  ghgCategoryId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inputEmissionsSpecifier: Maybe<Scalars['String']['output']>;
  inputEmissionsSubspecifier: Maybe<Scalars['String']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  internalDedupCategory: Maybe<Scalars['String']['output']>;
  internalDedupDetail: Maybe<Scalars['String']['output']>;
  internalDedupSubcategory: Maybe<Scalars['String']['output']>;
  isEntry: Maybe<Scalars['Boolean']['output']>;
  isOutput: Maybe<Scalars['Boolean']['output']>;
  locationCity: Maybe<Scalars['String']['output']>;
  locationCountry: Maybe<Scalars['String']['output']>;
  locationGrid: Maybe<Scalars['String']['output']>;
  locationState: Maybe<Scalars['String']['output']>;
  methodologySourceId: Maybe<Scalars['String']['output']>;
  orgId: Maybe<Scalars['String']['output']>;
  outputEmissionsSpecifier: Maybe<Scalars['String']['output']>;
  outputEmissionsSubspecifier: Maybe<Scalars['String']['output']>;
  outputUnit: Maybe<Scalars['String']['output']>;
  passthrough: Maybe<Scalars['Boolean']['output']>;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Maybe<Scalars['DateTime']['output']>;
  specificityRank: Maybe<Scalars['Int']['output']>;
  startMonth: Maybe<Scalars['YearMonth']['output']>;
  subcategoryId: Maybe<Scalars['String']['output']>;
  vendorEntity: Maybe<Scalars['String']['output']>;
  vendorSubentity: Maybe<Scalars['String']['output']>;
};

export type GQAssumptionRecordForAdminConnection = {
  __typename?: 'AssumptionRecordForAdminConnection';
  edges: Array<Maybe<GQAssumptionRecordForAdminEdge>>;
  pageInfo: GQPageInfo;
};

export type GQAssumptionRecordForAdminEdge = {
  __typename?: 'AssumptionRecordForAdminEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQAssumptionRecordForAdmin>;
};

export type GQAssumptionSource = {
  __typename?: 'AssumptionSource';
  authorId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  externalSourceNotes: Maybe<Scalars['String']['output']>;
  externalSourceUrl: Maybe<Scalars['String']['output']>;
  hasMultiplierLicenseRestrictions: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sourceNotes: Maybe<Scalars['String']['output']>;
  sourceUrl: Scalars['String']['output'];
  userVisibleSourceUrl: Maybe<Scalars['String']['output']>;
};

export type GQAttachmentMetadata = {
  __typename?: 'AttachmentMetadata';
  fileMetadataId: Scalars['ID']['output'];
  signedUrl: Scalars['String']['output'];
};

export type GQAuditLogConnection = {
  __typename?: 'AuditLogConnection';
  edges: Array<Maybe<GQAuditLogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQAuditLogEdge = {
  __typename?: 'AuditLogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQAuditLogEntry>;
};

export type GQAuditLogEntry = {
  __typename?: 'AuditLogEntry';
  appType: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  eventKind: Scalars['String']['output'];
  eventMetadata: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Possibly sensitive data, use resolved fields instead. Remove EOW Aug 14 */
  loggingContext: Maybe<Scalars['JSONString']['output']>;
  userDisplayName: Maybe<Scalars['String']['output']>;
};

export const GQAutoBartKind = {
  BuildingList: 'BuildingList',
  FileUpload: 'FileUpload',
} as const;

export type GQAutoBartKind =
  (typeof GQAutoBartKind)[keyof typeof GQAutoBartKind];
export const GQAutoBartTaskApprovalState = {
  Approved: 'Approved',
  BuildingsSdi: 'BuildingsSdi',
  Error: 'Error',
  FacilitiesList: 'FacilitiesList',
  NeedsApproval: 'NeedsApproval',
  PreBiodiversityTransform: 'PreBiodiversityTransform',
  PreMapping: 'PreMapping',
  Unknown: 'Unknown',
} as const;

export type GQAutoBartTaskApprovalState =
  (typeof GQAutoBartTaskApprovalState)[keyof typeof GQAutoBartTaskApprovalState];
export type GQAutoMatched = {
  __typename?: 'AutoMatched';
  confidenceScore: Scalars['Float']['output'];
};

export type GQAutosaveEmissionsModelInput = {
  emissionsModelJson: Scalars['JSONString']['input'];
  matchers: Array<GQEmissionsModelMatcherInput>;
  parameterResolverJson: Scalars['JSONString']['input'];
  parentEmissionsModelVersionId: Scalars['ID']['input'];
};

export type GQAutosaveEmissionsModelPayload =
  | GQAutosaveEmissionsModelPayloadBlocked
  | GQAutosaveEmissionsModelPayloadError
  | GQAutosaveEmissionsModelPayloadOk;

export type GQAutosaveEmissionsModelPayloadBlocked = {
  __typename?: 'AutosaveEmissionsModelPayloadBlocked';
  authorId: Scalars['ID']['output'];
  authorName: Scalars['String']['output'];
  autosavedAt: Scalars['DateTime']['output'];
};

export type GQAutosaveEmissionsModelPayloadError = {
  __typename?: 'AutosaveEmissionsModelPayloadError';
  error: GQSerializableError;
};

export type GQAutosaveEmissionsModelPayloadOk = {
  __typename?: 'AutosaveEmissionsModelPayloadOk';
  emissionsModelActiveDraftRecord: GQEmissionsModelActiveDraft;
};

export type GQBackfillBuildingHistoryInput = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  orgIds: Array<Scalars['String']['input']>;
};

export type GQBackfillBuildingHistoryOrgPayload = {
  __typename?: 'BackfillBuildingHistoryOrgPayload';
  addedBuildingIds: Array<Scalars['String']['output']>;
  editedBuildingIds: Array<Scalars['String']['output']>;
  orgId: Scalars['String']['output'];
};

export type GQBackfillBuildingHistoryPayload = {
  __typename?: 'BackfillBuildingHistoryPayload';
  orgResults: Array<GQBackfillBuildingHistoryOrgPayload>;
};

export type GQBackfillFacilitiesUploadTaskAssociationsInput = {
  dryRun: Scalars['Boolean']['input'];
  orgId: Scalars['String']['input'];
};

export type GQBackfillFacilitiesUploadTaskAssociationsPayload = {
  __typename?: 'BackfillFacilitiesUploadTaskAssociationsPayload';
  numAssociationsCreated: Scalars['Int']['output'];
};

export type GQBackfillSupportingDocumentAssociationsInput = {
  dryRun: Scalars['Boolean']['input'];
  orgIds: Array<Scalars['String']['input']>;
};

export type GQBackfillSupportingDocumentAssociationsPayload = {
  __typename?: 'BackfillSupportingDocumentAssociationsPayload';
  missingAssociations: Array<GQMissingSupportingDocumentAssociations>;
};

export type GQBackgroundJob = GQIdInterface & {
  __typename?: 'BackgroundJob';
  args: Maybe<Scalars['JSONString']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator: Maybe<GQUser | GQWatershedEmployee>;
  durationSeconds: Maybe<Scalars['Float']['output']>;
  error: Maybe<GQSerializableError>;
  finishedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  queueName: GQBackgroundJobQueueName;
  remainingPreemptions: Maybe<Scalars['Int']['output']>;
  result: Maybe<Scalars['JSONString']['output']>;
  state: GQBackgroundJobState;
  /** @deprecated Use 'creator' instead */
  user: Maybe<GQUser>;
};

export type GQBackgroundJobEntry = GQIdInterface & {
  __typename?: 'BackgroundJobEntry';
  args: Maybe<Scalars['JSONString']['output']>;
  createdAt: Scalars['DateTime']['output'];
  error: Maybe<GQSerializableError>;
  finishedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  orgId: Maybe<Scalars['String']['output']>;
  queueName: Scalars['String']['output'];
  result: Maybe<Scalars['JSONString']['output']>;
  state: GQBackgroundJobState;
  user: Maybe<GQUser>;
};

export const GQBackgroundJobQueueName = {
  FinanceEstimations: 'financeEstimations',
  UserWaiting: 'userWaiting',
} as const;

export type GQBackgroundJobQueueName =
  (typeof GQBackgroundJobQueueName)[keyof typeof GQBackgroundJobQueueName];
export const GQBackgroundJobState = {
  Done: 'DONE',
  Error: 'ERROR',
  Failed: 'FAILED',
  Processing: 'PROCESSING',
  Queued: 'QUEUED',
} as const;

export type GQBackgroundJobState =
  (typeof GQBackgroundJobState)[keyof typeof GQBackgroundJobState];
export type GQBackgroundJobStatus = GQIdInterface & {
  __typename?: 'BackgroundJobStatus';
  error: Maybe<GQSerializableError>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  state: GQBackgroundJobState;
};

export type GQBartColumnDetails = {
  __typename?: 'BartColumnDetails';
  columnName: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  emissionsModelEvaluationId: Scalars['String']['output'];
};

export type GQBatsByCalculationTagNamesPayload = {
  __typename?: 'BatsByCalculationTagNamesPayload';
  businessActivityTypes: Array<Scalars['String']['output']>;
  tagId: Scalars['ID']['output'];
  tagName: Scalars['String']['output'];
};

export type GQBenchmark = {
  __typename?: 'Benchmark';
  amountKgco2e: Scalars['Int53']['output'];
  companyId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  industries: Array<Scalars['String']['output']>;
  kgco2eByScope: Array<GQBenchmarkScopeBreakdownDataPoint>;
  kind: GQBenchmarkKind;
  name: Scalars['String']['output'];
  normalizationKind: GQAggregateKind;
  publishingYear: Scalars['Int']['output'];
  qualityScore: Maybe<GQDisclosureQualityScore>;
  reportId: Maybe<Scalars['String']['output']>;
  reportingYear: Scalars['Int']['output'];
  scope2Source: Maybe<GQScope2Source>;
  source: Maybe<GQExternalReportType>;
};

export type GQBenchmarkBreakdownDataPoint = {
  __typename?: 'BenchmarkBreakdownDataPoint';
  businessCategory: Scalars['String']['output'];
  kgco2e: Scalars['Int']['output'];
};

export const GQBenchmarkKind = {
  /** Another business. */
  Business: 'Business',
  IndustryAverage: 'IndustryAverage',
  /** The active organization itself. */
  Self: 'Self',
} as const;

export type GQBenchmarkKind =
  (typeof GQBenchmarkKind)[keyof typeof GQBenchmarkKind];
export type GQBenchmarkScopeBreakdownDataPoint = {
  __typename?: 'BenchmarkScopeBreakdownDataPoint';
  kgco2e: Maybe<Scalars['Float']['output']>;
  scope: GQGhgScope;
};

export const GQBiDisplayMappingType = {
  ApprovalStatus: 'approvalStatus',
  AreaUnit: 'areaUnit',
  BeaCode: 'beaCode',
  DataOwners: 'dataOwners',
  DatasetId: 'datasetId',
  FileIds: 'fileIds',
  FinanceAssetHoldingClass: 'financeAssetHoldingClass',
  GhgRemovalActivity: 'ghgRemovalActivity',
  GhgRemovalStoragePool: 'ghgRemovalStoragePool',
  IndustryCode: 'industryCode',
  IndustrySectorCode: 'industrySectorCode',
  IndustrySectorLabel: 'industrySectorLabel',
  MeasurementProjectIds: 'measurementProjectIds',
  Natura2000SiteNames: 'natura2000SiteNames',
  OrgUnit: 'orgUnit',
  PollutantSubstanceOfConcernHazardClass:
    'pollutantSubstanceOfConcernHazardClass',
  ReleaseId: 'releaseId',
  SbtCommitmentStage: 'sbtCommitmentStage',
  UncollapsedCompanyId: 'uncollapsedCompanyId',
  Unit: 'unit',
  UploaderIds: 'uploaderIds',
  VehicleType: 'vehicleType',
  VehicleUsageReason: 'vehicleUsageReason',
} as const;

export type GQBiDisplayMappingType =
  (typeof GQBiDisplayMappingType)[keyof typeof GQBiDisplayMappingType];
export const GQBiFilterOperator = {
  Contains: 'Contains',
  DoesNotContain: 'DoesNotContain',
  Empty: 'Empty',
  Equal: 'Equal',
  GreaterThan: 'GreaterThan',
  GreaterThanOrEqual: 'GreaterThanOrEqual',
  In: 'In',
  LessThan: 'LessThan',
  LessThanOrEqual: 'LessThanOrEqual',
  NotEmpty: 'NotEmpty',
  NotEqual: 'NotEqual',
  NotIn: 'NotIn',
} as const;

export type GQBiFilterOperator =
  (typeof GQBiFilterOperator)[keyof typeof GQBiFilterOperator];
export type GQBiQueryFilter = {
  __typename?: 'BiQueryFilter';
  dimension: Scalars['String']['output'];
  operator: GQBiFilterOperator;
  value: Array<Maybe<Scalars['FilterValue']['output']>>;
};

export type GQBuildingCompletionIntervals = GQIdInterface & {
  __typename?: 'BuildingCompletionIntervals';
  data: Array<GQBuildingCompletionIntervalsData>;
  id: Scalars['ID']['output'];
};

export type GQBuildingCompletionIntervalsData = {
  __typename?: 'BuildingCompletionIntervalsData';
  fuelKind: Maybe<GQStationaryCombustionFuelKind>;
  hasOnsiteCleanElectricity: Maybe<Scalars['Boolean']['output']>;
  periodsWithActuals: Array<GQDateTimeInterval>;
  periodsWithSupportingDocs: Array<GQDateTimeInterval>;
  utilityType: GQBuildingUtilityType;
};

export type GQBuildingCompletionIntervalsUntyped = GQIdInterface & {
  __typename?: 'BuildingCompletionIntervalsUntyped';
  data: Scalars['UntypedData']['output'];
  id: Scalars['ID']['output'];
};

export type GQBuildingDiffError = {
  __typename?: 'BuildingDiffError';
  adminMessage: Scalars['String']['output'];
  code: GQBuildingDiffErrorCode;
  message: Scalars['String']['output'];
};

export const GQBuildingDiffErrorCode = {
  ForbiddenError: 'ForbiddenError',
  TimeIntersectionInData: 'TimeIntersectionInData',
} as const;

export type GQBuildingDiffErrorCode =
  (typeof GQBuildingDiffErrorCode)[keyof typeof GQBuildingDiffErrorCode];
export type GQBuildingDiffWarning = {
  __typename?: 'BuildingDiffWarning';
  adminMessage: Scalars['String']['output'];
  code: GQBuildingDiffWarningCode;
  message: Scalars['String']['output'];
};

export const GQBuildingDiffWarningCode = {
  KeyInfoChangeDetected: 'KeyInfoChangeDetected',
  TimeGapInData: 'TimeGapInData',
} as const;

export type GQBuildingDiffWarningCode =
  (typeof GQBuildingDiffWarningCode)[keyof typeof GQBuildingDiffWarningCode];
export const GQBuildingEditState = {
  Full: 'Full',
  NeedsConfirmation: 'NeedsConfirmation',
  Partial: 'Partial',
} as const;

export type GQBuildingEditState =
  (typeof GQBuildingEditState)[keyof typeof GQBuildingEditState];
export type GQBuildingEstimate = GQIdInterface & {
  __typename?: 'BuildingEstimate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modelInput: Maybe<Scalars['JSONString']['output']>;
  modelVersion: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  rawOutputFileMetadataId: Scalars['ID']['output'];
  scope1Kgco2e: Scalars['Float']['output'];
  scope2Kgco2e: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQBuildingFacilitiesTaskFilter = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQBuildingForDiff = GQBuildingInterface & {
  __typename?: 'BuildingForDiff';
  buildingKind: Maybe<Scalars['String']['output']>;
  buildingName: Scalars['String']['output'];
  buildingSubkind: Maybe<Scalars['String']['output']>;
  buildingUniqueId: Scalars['String']['output'];
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  editState: GQBuildingEditState;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  /** @deprecated Moved to BuildingPeriod */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
  isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  leaseType: Maybe<GQBuildingLeaseType>;
  longitude: Maybe<Scalars['Float']['output']>;
  periods: Array<GQBuildingPeriodForDiff>;
  postalCode: Maybe<Scalars['String']['output']>;
  revision: Maybe<Scalars['Int']['output']>;
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
};

export type GQBuildingHistory = GQBuildingInterface & {
  __typename?: 'BuildingHistory';
  buildingKind: Maybe<Scalars['String']['output']>;
  buildingName: Scalars['String']['output'];
  buildingSubkind: Maybe<Scalars['String']['output']>;
  buildingUniqueId: Scalars['String']['output'];
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  editState: GQBuildingEditState;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  id: Scalars['ID']['output'];
  isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
  isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  leaseType: Maybe<GQBuildingLeaseType>;
  longitude: Maybe<Scalars['Float']['output']>;
  periods: Array<GQBuildingPeriod>;
  postalCode: Maybe<Scalars['String']['output']>;
  revision: Maybe<Scalars['Int']['output']>;
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
};

export type GQBuildingInterface = {
  buildingKind: Maybe<Scalars['String']['output']>;
  buildingName: Scalars['String']['output'];
  buildingSubkind: Maybe<Scalars['String']['output']>;
  buildingUniqueId: Scalars['String']['output'];
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  editState: GQBuildingEditState;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
  isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  leaseType: Maybe<GQBuildingLeaseType>;
  longitude: Maybe<Scalars['Float']['output']>;
  periods: Array<GQBuildingPeriod | GQBuildingPeriodForDiff>;
  postalCode: Maybe<Scalars['String']['output']>;
  revision: Maybe<Scalars['Int']['output']>;
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
};

export const GQBuildingLeaseType = {
  CoworkingOrServicedOffice: 'CoworkingOrServicedOffice',
  DownstreamLeaseNotPayingUtilities: 'DownstreamLeaseNotPayingUtilities',
  DownstreamLeasePayingUtilities: 'DownstreamLeasePayingUtilities',
  DownstreamSubleaseNotPayingUtilities: 'DownstreamSubleaseNotPayingUtilities',
  DownstreamSubleasePayingUtilities: 'DownstreamSubleasePayingUtilities',
  Franchise: 'Franchise',
  Leased: 'Leased',
  Owned: 'Owned',
  SoldBuilding: 'SoldBuilding',
  Unknown: 'Unknown',
  UpstreamSublease: 'UpstreamSublease',
} as const;

export type GQBuildingLeaseType =
  (typeof GQBuildingLeaseType)[keyof typeof GQBuildingLeaseType];
export type GQBuildingLockedRevisions = {
  __typename?: 'BuildingLockedRevisions';
  id: Maybe<Scalars['String']['output']>;
  revision: Maybe<Scalars['Int']['output']>;
};

export type GQBuildingPeriod = GQBuildingPeriodInterface & {
  __typename?: 'BuildingPeriod';
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  customFieldId: Maybe<Scalars['String']['output']>;
  customFieldValue: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  id: Scalars['ID']['output'];
  isBuildingEmpty: Maybe<Scalars['Boolean']['output']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId: Maybe<Scalars['String']['output']>;
  orgUnitTypeStableId: Maybe<Scalars['String']['output']>;
  partialCleanPowerPercent: Maybe<Scalars['Float']['output']>;
  powerUsageEffectiveness: Maybe<Scalars['Float']['output']>;
  sizeQuantity: Maybe<Scalars['Float']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
};

export type GQBuildingPeriodForDiff = GQBuildingPeriodInterface & {
  __typename?: 'BuildingPeriodForDiff';
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  customFieldId: Maybe<Scalars['String']['output']>;
  customFieldValue: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  isBuildingEmpty: Maybe<Scalars['Boolean']['output']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId: Maybe<Scalars['String']['output']>;
  orgUnitTypeStableId: Maybe<Scalars['String']['output']>;
  partialCleanPowerPercent: Maybe<Scalars['Float']['output']>;
  powerUsageEffectiveness: Maybe<Scalars['Float']['output']>;
  sizeQuantity: Maybe<Scalars['Float']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
};

export type GQBuildingPeriodInterface = {
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  customFieldId: Maybe<Scalars['String']['output']>;
  customFieldValue: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  isBuildingEmpty: Maybe<Scalars['Boolean']['output']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId: Maybe<Scalars['String']['output']>;
  orgUnitTypeStableId: Maybe<Scalars['String']['output']>;
  partialCleanPowerPercent: Maybe<Scalars['Float']['output']>;
  powerUsageEffectiveness: Maybe<Scalars['Float']['output']>;
  sizeQuantity: Maybe<Scalars['Float']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
};

export const GQBuildingPeriodKind = {
  BuildingSize: 'BuildingSize',
  CleanPower: 'CleanPower',
  CustomField: 'CustomField',
  IsBuildingEmpty: 'IsBuildingEmpty',
  OrgUnit: 'OrgUnit',
  PowerUsageEffectiveness: 'PowerUsageEffectiveness',
} as const;

export type GQBuildingPeriodKind =
  (typeof GQBuildingPeriodKind)[keyof typeof GQBuildingPeriodKind];
export const GQBuildingSizeUnit = {
  SquareFeet: 'SquareFeet',
  SquareMeters: 'SquareMeters',
} as const;

export type GQBuildingSizeUnit =
  (typeof GQBuildingSizeUnit)[keyof typeof GQBuildingSizeUnit];
export type GQBuildingSurveyStatus = {
  __typename?: 'BuildingSurveyStatus';
  bartActivityType: Maybe<Scalars['String']['output']>;
  latestSnapshotIsEmptyFile: Scalars['Boolean']['output'];
  latestSnapshotUpToDate: Scalars['Boolean']['output'];
  latestSnapshotUut: Maybe<GQUserUploadedTable>;
  measurementProjectIntervalMatches: Scalars['Boolean']['output'];
  numAddedBuildingsSinceUutGenerated: Scalars['Int']['output'];
  numChangedBuildingsSinceUutGenerated: Scalars['Int']['output'];
  numDeletedBuildingsSinceUutGenerated: Scalars['Int']['output'];
  numOmittedPartialBuildings: Scalars['Int']['output'];
  numUnchangedBuildingsSinceUutGenerated: Scalars['Int']['output'];
};

export type GQBuildingUntyped = GQIdInterface & {
  __typename?: 'BuildingUntyped';
  data: Scalars['UntypedData']['output'];
  id: Scalars['ID']['output'];
};

export type GQBuildingUtility = GQIdInterface & {
  __typename?: 'BuildingUtility';
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  cleanPowerPercent: Maybe<Scalars['Float']['output']>;
  customFieldValues: Array<GQBuildingUtilityCustomField>;
  fuelCombustionReason: Maybe<Scalars['String']['output']>;
  fuelKind: Maybe<GQStationaryCombustionFuelKind>;
  id: Scalars['ID']['output'];
  isWasteHazardous: Maybe<Scalars['Boolean']['output']>;
  isWasteRadioactive: Maybe<Scalars['Boolean']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  onsiteCleanFuelKind: Maybe<Scalars['String']['output']>;
  periodEnd: Scalars['Date']['output'];
  periodStart: Scalars['Date']['output'];
  productExternalId: Maybe<Scalars['String']['output']>;
  productName: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  refrigerantLeakageSource: Maybe<GQRefrigerantLeakageSource>;
  refrigerantName: Maybe<Scalars['String']['output']>;
  sourceFile: Maybe<GQUserUpload>;
  supportingDocuments: Array<GQSupportingDocument>;
  unit: GQBuildingUtilityUnit;
  utilityType: GQBuildingUtilityType;
  wasteMaterial: Maybe<GQBuildingWasteMaterial>;
  wasteTreatment: Maybe<GQBuildingWasteTreatment>;
  waterUseKind: Maybe<GQBuildingWaterUseKind>;
};

export type GQBuildingUtilityConnection = {
  __typename?: 'BuildingUtilityConnection';
  edges: Array<Maybe<GQBuildingUtilityEdge>>;
  pageInfo: GQPageInfo;
};

export type GQBuildingUtilityCustomField = GQIdInterface & {
  __typename?: 'BuildingUtilityCustomField';
  customFieldValue: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ingestionCustomFieldId: Scalars['ID']['output'];
};

export type GQBuildingUtilityEdge = {
  __typename?: 'BuildingUtilityEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQBuildingUtility>;
};

export const GQBuildingUtilityType = {
  ElectricityConsumption: 'ElectricityConsumption',
  Refrigerant: 'Refrigerant',
  StationaryCombustion: 'StationaryCombustion',
  Waste: 'Waste',
  Water: 'Water',
} as const;

export type GQBuildingUtilityType =
  (typeof GQBuildingUtilityType)[keyof typeof GQBuildingUtilityType];
export const GQBuildingUtilityUnit = {
  Ccf: 'Ccf',
  Centigallon: 'Centigallon',
  Cf: 'Cf',
  CubicMeter: 'CubicMeter',
  G: 'G',
  Gallons: 'Gallons',
  Gj: 'Gj',
  Joule: 'Joule',
  Kbtu: 'Kbtu',
  Kg: 'Kg',
  Kilogallon: 'Kilogallon',
  Kw: 'Kw',
  Kwh: 'Kwh',
  Lb: 'Lb',
  Liter: 'Liter',
  M3NaturalGas: 'M3NaturalGas',
  Mcf: 'Mcf',
  Mj: 'Mj',
  Mmbtu: 'Mmbtu',
  Oz: 'Oz',
  Therm: 'Therm',
  Tonnes: 'Tonnes',
  Tons: 'Tons',
} as const;

export type GQBuildingUtilityUnit =
  (typeof GQBuildingUtilityUnit)[keyof typeof GQBuildingUtilityUnit];
export const GQBuildingWasteMaterial = {
  AluminumCans: 'AluminumCans',
  Beef: 'Beef',
  Bread: 'Bread',
  Clothing: 'Clothing',
  CommercialAndIndustrial: 'CommercialAndIndustrial',
  Concrete: 'Concrete',
  ConstructionWaste: 'ConstructionWaste',
  CorrugatedContainers: 'CorrugatedContainers',
  CrtDisplays: 'CrtDisplays',
  DairyProducts: 'DairyProducts',
  DesktopCpus: 'DesktopCpus',
  ElectronicPeripherals: 'ElectronicPeripherals',
  FlatPanelDisplays: 'FlatPanelDisplays',
  FoodWaste: 'FoodWaste',
  FoodWasteMeatOnly: 'FoodWasteMeatOnly',
  FoodWasteNonMeat: 'FoodWasteNonMeat',
  FruitsAndVegetables: 'FruitsAndVegetables',
  Glass: 'Glass',
  Grains: 'Grains',
  HardCopyDevices: 'HardCopyDevices',
  Hdpe: 'Hdpe',
  Ldpe: 'Ldpe',
  Lldpe: 'Lldpe',
  MixedElectronics: 'MixedElectronics',
  MixedMetals: 'MixedMetals',
  MixedMsw: 'MixedMsw',
  MixedOrganics: 'MixedOrganics',
  MixedPaperGeneral: 'MixedPaperGeneral',
  MixedPlastics: 'MixedPlastics',
  MixedRecyclables: 'MixedRecyclables',
  Newspaper: 'Newspaper',
  Pet: 'Pet',
  Pla: 'Pla',
  PortableElectronicDevices: 'PortableElectronicDevices',
  Poultry: 'Poultry',
  Pp: 'Pp',
  Ps: 'Ps',
  Pvc: 'Pvc',
  SteelCans: 'SteelCans',
  Unspecified: 'Unspecified',
  Wood: 'Wood',
} as const;

export type GQBuildingWasteMaterial =
  (typeof GQBuildingWasteMaterial)[keyof typeof GQBuildingWasteMaterial];
export const GQBuildingWasteTreatment = {
  AnaerobicallyDigested: 'AnaerobicallyDigested',
  Combusted: 'Combusted',
  Composted: 'Composted',
  Landfilled: 'Landfilled',
  PreparedForReuse: 'PreparedForReuse',
  Recycled: 'Recycled',
  Unspecified: 'Unspecified',
} as const;

export type GQBuildingWasteTreatment =
  (typeof GQBuildingWasteTreatment)[keyof typeof GQBuildingWasteTreatment];
export const GQBuildingWasteUnit = {
  G: 'G',
  Kg: 'Kg',
  Lb: 'Lb',
  Tonnes: 'Tonnes',
  Tons: 'Tons',
} as const;

export type GQBuildingWasteUnit =
  (typeof GQBuildingWasteUnit)[keyof typeof GQBuildingWasteUnit];
export const GQBuildingWaterUnit = {
  Ccf: 'Ccf',
  Centigallon: 'Centigallon',
  Cf: 'Cf',
  CubicMeter: 'CubicMeter',
  Gallons: 'Gallons',
  Kilogallon: 'Kilogallon',
  Liter: 'Liter',
  Mcf: 'Mcf',
} as const;

export type GQBuildingWaterUnit =
  (typeof GQBuildingWaterUnit)[keyof typeof GQBuildingWaterUnit];
export const GQBuildingWaterUseKind = {
  Consumed: 'Consumed',
  Discharged: 'Discharged',
  Recycled: 'Recycled',
  Stored: 'Stored',
  Used: 'Used',
} as const;

export type GQBuildingWaterUseKind =
  (typeof GQBuildingWaterUseKind)[keyof typeof GQBuildingWaterUseKind];
export type GQBuildingWithUtilities = GQIdInterface & {
  __typename?: 'BuildingWithUtilities';
  completionIntervals: GQBuildingCompletionIntervals;
  id: Scalars['ID']['output'];
  utilities: Array<GQBuildingUtility>;
  utilitiesPaginated: GQBuildingUtilityConnection;
};

export type GQBuildingWithUtilitiesCompletionIntervalsArgs = {
  combineFuelKinds: InputMaybe<Scalars['Boolean']['input']>;
  separateOnsiteCleanElectricity: InputMaybe<Scalars['Boolean']['input']>;
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
};

export type GQBuildingWithUtilitiesUtilitiesPaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  notUserUploadTaskId: InputMaybe<Scalars['ID']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
  utilityType: InputMaybe<GQBuildingUtilityType>;
};

export const GQBuildingsMergeStrategy = {
  MergePeriods: 'mergePeriods',
  OverwriteDuplicates: 'overwriteDuplicates',
} as const;

export type GQBuildingsMergeStrategy =
  (typeof GQBuildingsMergeStrategy)[keyof typeof GQBuildingsMergeStrategy];
export type GQBulkImportMarketplaceAllocationInstructionsInput = {
  instructions: Array<GQCreateMarketplaceAllocationInstructionInput>;
};

export type GQBulkImportMarketplaceAllocationInstructionsPayload = {
  __typename?: 'BulkImportMarketplaceAllocationInstructionsPayload';
  allocationInstructions: Array<GQMarketplaceAllocationInstruction>;
};

export type GQBulkUpdateMarketplacePurchaseLineItemInput = {
  amount: Scalars['Float']['input'];
  amountUnit?: InputMaybe<GQMarketplaceProjectArchetypeUnit>;
  cleanPowerContractualInstrument?: InputMaybe<GQCleanPowerContractualInstrument>;
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  coverageInterval: Scalars['YMInterval']['input'];
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  eacMetadata?: InputMaybe<GQUpdateMarketplacePurchaseLineItemEacMetadataInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mechanism?: InputMaybe<GQMarketplaceProjectArchetypeMechanism>;
  noteMd: Scalars['String']['input'];
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  status: GQMarketplacePurchaseLineItemStatus;
  unitFeeUsdCents: Scalars['Float']['input'];
  unitPriceUsdCents: Scalars['Float']['input'];
};

export type GQBulkUpdateMarketplacePurchaseLineItemsInput = {
  lineItems: Array<GQBulkUpdateMarketplacePurchaseLineItemInput>;
  orgId: Scalars['ID']['input'];
  purchaseId: Scalars['ID']['input'];
};

export type GQBulkUpdateMarketplacePurchaseLineItemsPayload = {
  __typename?: 'BulkUpdateMarketplacePurchaseLineItemsPayload';
  createdLineItems: Maybe<Array<GQMarketplacePurchaseLineItem>>;
  deletedLineItems: Maybe<Array<GQMarketplacePurchaseLineItem>>;
  marketplacePurchase: GQMarketplacePurchase;
  updatedLineItems: Maybe<Array<GQMarketplacePurchaseLineItem>>;
};

export type GQBulkUpdateReportQuestionsInput = {
  allowNotes?: InputMaybe<Scalars['Boolean']['input']>;
  allowedAttachmentKinds?: InputMaybe<Array<GQReportAttachmentItemKind>>;
  componentIds: Array<Scalars['String']['input']>;
  reportConfigId: Scalars['ID']['input'];
};

export type GQBulkUpdateReportQuestionsPayload = {
  __typename?: 'BulkUpdateReportQuestionsPayload';
  reportQuestions: Array<GQReportQuestion>;
};

export type GQBusinessActivityType = {
  __typename?: 'BusinessActivityType';
  description: Scalars['String']['output'];
  fields: Array<GQBusinessActivityTypeField>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  schemaActivationMode: GQBusinessActivityTypeSettingsSchemaActivationModes;
  schemaJson: Scalars['JSONString']['output'];
  tSchema: Scalars['JSONString']['output'];
  tschemaVersionId: Maybe<Scalars['ID']['output']>;
  version: GQBusinessActivityTypeVersion;
  versionId: Scalars['ID']['output'];
  versionName: Scalars['String']['output'];
};

export type GQBusinessActivityTypeField = {
  __typename?: 'BusinessActivityTypeField';
  description: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  fieldType: GQBusinessActivityTypeFieldType;
  fieldValueOneOf: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  isProratable: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  typeId: Scalars['ID']['output'];
  unitFieldName: Maybe<Scalars['String']['output']>;
  versionId: Scalars['ID']['output'];
};

export const GQBusinessActivityTypeFieldType = {
  Boolean: 'BOOLEAN',
  Bytes: 'BYTES',
  Date: 'DATE',
  Float: 'FLOAT',
  Int: 'INT',
  Json: 'JSON',
  OneOf: 'ONE_OF',
  String: 'STRING',
  Timestamp: 'TIMESTAMP',
  Uuid: 'UUID',
} as const;

export type GQBusinessActivityTypeFieldType =
  (typeof GQBusinessActivityTypeFieldType)[keyof typeof GQBusinessActivityTypeFieldType];
export type GQBusinessActivityTypeSettings = {
  __typename?: 'BusinessActivityTypeSettings';
  businessActivityTypeName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataReadWriteMode: GQBusinessActivityTypeSettingsDataReadWriteModes;
  id: Scalars['ID']['output'];
  latestTypeInstance: GQBusinessActivityType;
  schemaActivationMode: GQBusinessActivityTypeSettingsSchemaActivationModes;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQBusinessActivityTypeSettingsConnection = {
  __typename?: 'BusinessActivityTypeSettingsConnection';
  edges: Array<Maybe<GQBusinessActivityTypeSettingsEdge>>;
  pageInfo: GQPageInfo;
};

export const GQBusinessActivityTypeSettingsDataReadWriteModes = {
  DataRegistryOnly: 'DataRegistryOnly',
  DualWriteBlocking: 'DualWriteBlocking',
  DualWriteNonBlocking: 'DualWriteNonBlocking',
  FileMetadataOnly: 'FileMetadataOnly',
  ReadDataRegistry: 'ReadDataRegistry',
} as const;

export type GQBusinessActivityTypeSettingsDataReadWriteModes =
  (typeof GQBusinessActivityTypeSettingsDataReadWriteModes)[keyof typeof GQBusinessActivityTypeSettingsDataReadWriteModes];
export type GQBusinessActivityTypeSettingsEdge = {
  __typename?: 'BusinessActivityTypeSettingsEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQBusinessActivityTypeSettings>;
};

export const GQBusinessActivityTypeSettingsSchemaActivationModes = {
  Legacy: 'Legacy',
  TSchema: 'TSchema',
} as const;

export type GQBusinessActivityTypeSettingsSchemaActivationModes =
  (typeof GQBusinessActivityTypeSettingsSchemaActivationModes)[keyof typeof GQBusinessActivityTypeSettingsSchemaActivationModes];
export type GQBusinessActivityTypeVersion = {
  __typename?: 'BusinessActivityTypeVersion';
  businessActivityTypes: Array<GQBusinessActivityType>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  primaryAndExtensionBusinessActivityTypes: Array<GQBusinessActivityType>;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
};

export type GQBusinessCategoryCollection = {
  __typename?: 'BusinessCategoryCollection';
  id: Scalars['ID']['output'];
  records: Array<Scalars['String']['output']>;
};

export type GQCadtBuildingDiff = {
  __typename?: 'CadtBuildingDiff';
  buildingPresentInBart: Maybe<Scalars['Boolean']['output']>;
  errors: Array<GQBuildingDiffError>;
  new: Maybe<GQBuildingForDiff>;
  old: Maybe<GQBuildingForDiff>;
  warnings: Array<GQBuildingDiffWarning>;
};

export type GQCadtBuildingDiffSet = {
  __typename?: 'CadtBuildingDiffSet';
  added: Scalars['Int']['output'];
  changed: Scalars['Int']['output'];
  diffs: Array<GQCadtBuildingDiff>;
  removed: Scalars['Int']['output'];
  unchanged: Scalars['Int']['output'];
};

export type GQCadtBuildingsPushPreview = {
  __typename?: 'CadtBuildingsPushPreview';
  customFields: Array<GQIngestionCustomField>;
  mergePeriodsDiffUntyped: Maybe<Scalars['UntypedData']['output']>;
  orgUnitTypes: Array<GQOrgUnitType>;
  orgUnits: Array<GQOrgUnit>;
  overwriteDuplicatesDiffUntyped: Maybe<Scalars['UntypedData']['output']>;
};

export type GQCadtRowsToMapForOrgOutput = {
  __typename?: 'CadtRowsToMapForOrgOutput';
  cadtRowsToMap: GQRawCadtRowToMapConnection;
};

export type GQCalculationTag = {
  __typename?: 'CalculationTag';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQCalculationTagPayload = {
  __typename?: 'CalculationTagPayload';
  tag: GQCalculationTag;
};

export type GQCalculationTagWithUsage = {
  __typename?: 'CalculationTagWithUsage';
  emissionsModels: Array<GQEmissionsModelStable>;
  measurementTestSuites: Array<GQMeasurementTestSuite>;
  referenceDataSources: Array<GQReferenceDataSource>;
  tag: GQCalculationTag;
};

export type GQCancelWorkflowInput = {
  workflowId: Scalars['String']['input'];
};

export type GQCancelWorkflowPayload = {
  __typename?: 'CancelWorkflowPayload';
  success: Scalars['Boolean']['output'];
};

export type GQCanonicalClimateProgramProject = GQIdInterface & {
  __typename?: 'CanonicalClimateProgramProject';
  commitmentType: Maybe<Scalars['String']['output']>;
  costEstimate: Maybe<Scalars['String']['output']>;
  datasetRequirements: Array<GQCanonicalClimateProgramProjectRequirementDataset>;
  description: Scalars['String']['output'];
  ghgpRequirements: Array<GQCanonicalClimateProgramProjectRequirementGhgp>;
  hasBenchmarkMapping: Scalars['Boolean']['output'];
  hidden: Maybe<Scalars['Boolean']['output']>;
  hiddenFromMeasurement: Maybe<Scalars['Boolean']['output']>;
  iconType: GQClimateProgramProjectIconType;
  iconTypeOverride: Maybe<GQClimateProgramProjectIconType>;
  id: Scalars['ID']['output'];
  kind: GQClimateProgramProjectKind;
  matchObjectType: Maybe<Scalars['String']['output']>;
  measurementObjectCopy: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  peerBenchmarkCompanies: Array<GQCompany>;
  reportKind: Maybe<Scalars['String']['output']>;
  routeType: Maybe<Scalars['String']['output']>;
  timeEstimate: Maybe<Scalars['String']['output']>;
};

export type GQCanonicalClimateProgramProjectPayload = {
  __typename?: 'CanonicalClimateProgramProjectPayload';
  canonicalClimateProgramProject: GQCanonicalClimateProgramProject;
};

export type GQCanonicalClimateProgramProjectRequirementDataset =
  GQIdInterface & {
    __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
    additionalNotesMd: Maybe<Scalars['String']['output']>;
    canonicalDataset: GQCanonicalDataset;
    id: Scalars['ID']['output'];
  };

export type GQCanonicalClimateProgramProjectRequirementDatasetPayload = {
  __typename?: 'CanonicalClimateProgramProjectRequirementDatasetPayload';
  canonicalClimateProgramProject: GQCanonicalClimateProgramProject;
  canonicalClimateProgramProjectRequirementDataset: GQCanonicalClimateProgramProjectRequirementDataset;
};

export type GQCanonicalClimateProgramProjectRequirementGhgp = GQIdInterface & {
  __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
  additionalNotesMd: Maybe<Scalars['String']['output']>;
  ghgScope: Scalars['Int']['output'];
  ghgScope3Category: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  minimumFidelity: GQClimateProgramProjectRequirementFidelity;
};

export type GQCanonicalClimateProgramProjectRequirementGhgpPayload = {
  __typename?: 'CanonicalClimateProgramProjectRequirementGhgpPayload';
  canonicalClimateProgramProject: GQCanonicalClimateProgramProject;
  canonicalClimateProgramProjectRequirementGhgp: GQCanonicalClimateProgramProjectRequirementGhgp;
};

export type GQCanonicalDataset = GQIdInterface & {
  __typename?: 'CanonicalDataset';
  canonicalDatasources: Array<GQCanonicalDatasource>;
  customerTargetSchemas: Array<GQCustomerTargetSchema>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions: Maybe<GQInstructionsBundle>;
  kind: GQCanonicalDatasetKind;
  name: Scalars['String']['output'];
  potentialOverlaps: Maybe<Scalars['String']['output']>;
  selfServeDisabledReason: Maybe<Scalars['String']['output']>;
  usedFor: Maybe<Scalars['String']['output']>;
};

export type GQCanonicalDatasetCustomerTargetSchemasArgs = {
  hasPublishedVersion: InputMaybe<Scalars['Boolean']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
};

export const GQCanonicalDatasetKind = {
  BillOfMaterials: 'BillOfMaterials',
  BriberyAndCorruption: 'BriberyAndCorruption',
  Buildings: 'Buildings',
  CapitalExpenses: 'CapitalExpenses',
  CarbonCreditRetirements: 'CarbonCreditRetirements',
  CareInstructions: 'CareInstructions',
  ChartOfAccounts: 'ChartOfAccounts',
  CloudCosts: 'CloudCosts',
  CloudUsage: 'CloudUsage',
  CloudUsageAndCost: 'CloudUsageAndCost',
  CorporateInvestments: 'CorporateInvestments',
  CostOfRevenue: 'CostOfRevenue',
  CryptoHoldings: 'CryptoHoldings',
  CryptoTransactions: 'CryptoTransactions',
  CustomEmissionsFactor: 'CustomEmissionsFactor',
  DataCenters: 'DataCenters',
  DirectEmissions: 'DirectEmissions',
  DownstreamLogistics: 'DownstreamLogistics',
  EmployeeTravelExpenses: 'EmployeeTravelExpenses',
  Employees: 'Employees',
  EndOfLifeTreatmentOfSoldProducts: 'EndOfLifeTreatmentOfSoldProducts',
  EnergyAttributeCertificatesPurchased: 'EnergyAttributeCertificatesPurchased',
  Factories: 'Factories',
  FinancedEmissions: 'FinancedEmissions',
  Financials: 'Financials',
  Flights: 'Flights',
  Food: 'Food',
  GhgRemoval: 'GhgRemoval',
  HistoricalResults: 'HistoricalResults',
  Hotels: 'Hotels',
  HumanRightsViolations: 'HumanRightsViolations',
  Lobbying: 'Lobbying',
  MaterialAggregate: 'MaterialAggregate',
  MaterialContents: 'MaterialContents',
  Microplastics: 'Microplastics',
  OperatingExpenses: 'OperatingExpenses',
  OperationalPayments: 'OperationalPayments',
  Packaging: 'Packaging',
  PrivateFlights: 'PrivateFlights',
  ProductsPurchased: 'ProductsPurchased',
  ProductsSold: 'ProductsSold',
  Rails: 'Rails',
  Refrigerants: 'Refrigerants',
  Revenue: 'Revenue',
  SafetyIncidents: 'SafetyIncidents',
  Sites: 'Sites',
  SoldProductCircularity: 'SoldProductCircularity',
  SubstanceInflow: 'SubstanceInflow',
  Substances: 'Substances',
  SupplierFactoriesTier1: 'SupplierFactoriesTier1',
  SupplierUpstreamLogistics: 'SupplierUpstreamLogistics',
  TextileProductSales: 'TextileProductSales',
  UpstreamLogistics: 'UpstreamLogistics',
  UseOfGoods: 'UseOfGoods',
  UseOfSoldProductsCommonRetail: 'UseOfSoldProductsCommonRetail',
  UseOfSoldProductsDataTransfer: 'UseOfSoldProductsDataTransfer',
  UseOfSoldProductsFuels: 'UseOfSoldProductsFuels',
  UseOfSoldProductsRefrigerants: 'UseOfSoldProductsRefrigerants',
  UseOfSoldProductsVehicles: 'UseOfSoldProductsVehicles',
  UsePhaseOfGoods: 'UsePhaseOfGoods',
  Utilities: 'Utilities',
  Vehicles: 'Vehicles',
  Waste: 'Waste',
  WaterUsage: 'WaterUsage',
  WeightOfGoodsPurchased: 'WeightOfGoodsPurchased',
  WorkforceSocial: 'WorkforceSocial',
  WorkplaceReports: 'WorkplaceReports',
} as const;

export type GQCanonicalDatasetKind =
  (typeof GQCanonicalDatasetKind)[keyof typeof GQCanonicalDatasetKind];
export type GQCanonicalDatasetPayload = {
  __typename?: 'CanonicalDatasetPayload';
  canonicalDataset: GQCanonicalDataset;
};

export type GQCanonicalDatasource = GQIdInterface & {
  __typename?: 'CanonicalDatasource';
  activeIntegrationConnection: Maybe<GQIntegrationConnection>;
  canonicalDataset: GQCanonicalDataset;
  externalId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  instructions: Maybe<GQInstructionsBundle>;
  integrationPartner: Maybe<GQIntegrationPartner>;
  name: Scalars['String']['output'];
};

export type GQCanonicalDatasourcePayload = {
  __typename?: 'CanonicalDatasourcePayload';
  canonicalDatasource: GQCanonicalDatasource;
};

export const GQCanonicalReportObjective = {
  All: 'All',
  Awareness: 'Awareness',
  Regulatory: 'Regulatory',
  Stakeholder: 'Stakeholder',
} as const;

export type GQCanonicalReportObjective =
  (typeof GQCanonicalReportObjective)[keyof typeof GQCanonicalReportObjective];
export type GQCanonicalSchema = {
  __typename?: 'CanonicalSchema';
  exampleData: GQCanonicalSchemaExampleData;
  id: Scalars['ID']['output'];
  jsonSchema: Scalars['JSONString']['output'];
};

export type GQCanonicalSchemaExampleData = {
  __typename?: 'CanonicalSchemaExampleData';
  columns: Array<Scalars['String']['output']>;
  rows: Array<GQCanonicalSchemaExampleRow>;
};

export type GQCanonicalSchemaExampleRow = {
  __typename?: 'CanonicalSchemaExampleRow';
  fields: Array<GQCanonicalSchemaExampleRowFields>;
  id: Scalars['String']['output'];
};

export type GQCanonicalSchemaExampleRowFields = {
  __typename?: 'CanonicalSchemaExampleRowFields';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQCarbonNeutralCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'CarbonNeutralCommitment';
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    targetYear: Maybe<Scalars['Int']['output']>;
  };

export type GQCarbonNeutralCommitmentInput = {
  targetYear?: InputMaybe<Scalars['Int']['input']>;
};

export type GQCategorizedEmissionData = {
  __typename?: 'CategorizedEmissionData';
  amountKgco2e: Scalars['Float']['output'];
  businessCategory: Scalars['String']['output'];
  businessSubcategory: Maybe<Scalars['String']['output']>;
  proportion: Scalars['Float']['output'];
};

export type GQCategoryInfo = {
  __typename?: 'CategoryInfo';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GQCdpApiRegistration = {
  __typename?: 'CdpApiRegistration';
  cdpOrganizationId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQCdpCandidateColumnIdMapping = {
  __typename?: 'CdpCandidateColumnIdMapping';
  cdpColumnQuestionJson: Scalars['JSONString']['output'];
  cdpParentQuestionJson: Scalars['JSONString']['output'];
  cdpParentRefId: Scalars['String']['output'];
  cdpRefId: Scalars['String']['output'];
  columnId: Maybe<Scalars['String']['output']>;
  columnLabel: Maybe<Scalars['String']['output']>;
  otherColumnIdMapping: Maybe<Scalars['JSONString']['output']>;
  parentReportQuestion: Maybe<GQReportQuestion>;
};

export type GQCdpCandidateQuestionIdMapping = {
  __typename?: 'CdpCandidateQuestionIdMapping';
  cdpQuestionJson: Scalars['JSONString']['output'];
  cdpRefId: Scalars['String']['output'];
  reportQuestion: Maybe<GQReportQuestion>;
};

export type GQCdpColumnIdMapping = {
  __typename?: 'CdpColumnIdMapping';
  cdpRefId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  otherColumnIdMapping: Maybe<Scalars['JSONString']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  watershedColumnId: Maybe<Scalars['String']['output']>;
};

export type GQCdpColumnMappingInput = {
  cdpRefId: Scalars['String']['input'];
  otherColumnIdMapping?: InputMaybe<Scalars['JSONString']['input']>;
  watershedColumnId?: InputMaybe<Scalars['String']['input']>;
};

export type GQCdpIdMappingsPayload = {
  __typename?: 'CdpIdMappingsPayload';
  columnIdMappings: Array<GQCdpColumnIdMapping>;
  id: Scalars['ID']['output'];
  questionIdMappings: Array<GQCdpQuestionIdMapping>;
};

export type GQCdpMissingIdMappingsPayload = {
  __typename?: 'CdpMissingIdMappingsPayload';
  candidateColumnMappings: Array<GQCdpCandidateColumnIdMapping>;
  candidateQuestionMappings: Array<GQCdpCandidateQuestionIdMapping>;
  unmappedWatershedQuestions: Array<GQReportQuestion>;
};

export type GQCdpOrganization = {
  __typename?: 'CdpOrganization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQCdpOrganizationPayload = {
  __typename?: 'CdpOrganizationPayload';
  error: Maybe<Scalars['String']['output']>;
  organizations: Maybe<Array<GQCdpOrganization>>;
  success: Scalars['Boolean']['output'];
};

export type GQCdpQuestionIdMapping = {
  __typename?: 'CdpQuestionIdMapping';
  cdpRefId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  reportQuestionIdentifier: GQReportQuestionIdentifier;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQCdpQuestionMappingInput = {
  cdpRefId?: InputMaybe<Scalars['String']['input']>;
  reportQuestionIdentifierId: Scalars['ID']['input'];
};

export type GQCdpSyncQuestionHistoryConnection = {
  __typename?: 'CdpSyncQuestionHistoryConnection';
  edges: Array<Maybe<GQCdpSyncQuestionHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCdpSyncQuestionHistoryEdge = {
  __typename?: 'CdpSyncQuestionHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCdpSyncQuestionStatus>;
};

export type GQCdpSyncQuestionStatus = {
  __typename?: 'CdpSyncQuestionStatus';
  actor: GQUser;
  actorName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  failureDetails: Maybe<Scalars['JSONString']['output']>;
  failureKind: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSuccess: Maybe<Scalars['Boolean']['output']>;
  oldCdpResponses: Maybe<Scalars['JSONString']['output']>;
  reportId: Scalars['ID']['output'];
  reportQuestion: GQReportQuestion;
  reportQuestionId: Scalars['ID']['output'];
  syncTime: Scalars['DateTime']['output'];
};

export type GQCdpVendorData = {
  __typename?: 'CdpVendorData';
  disclosureQualityScore: GQDisclosureQualityScore;
  id: Scalars['ID']['output'];
  pctEvaluationStatusesMatchResponse: Scalars['Float']['output'];
  publishingYear: Scalars['Int']['output'];
  reportingYear: Scalars['Int']['output'];
  scope1Nonzero: Scalars['Boolean']['output'];
  scope1Verified: Scalars['Boolean']['output'];
  scope2Nonzero: Scalars['Boolean']['output'];
  scope2Verified: Scalars['Boolean']['output'];
  scope3Nonzero: Scalars['Boolean']['output'];
  scope3Verified: Scalars['Boolean']['output'];
  scope301EvaluationStatus: GQScope3EvaluationStatus;
  scope301Or302Nonzero: Scalars['Boolean']['output'];
  scope302EvaluationStatus: GQScope3EvaluationStatus;
  scope303EvaluationStatus: GQScope3EvaluationStatus;
  scope304EvaluationStatus: GQScope3EvaluationStatus;
  scope305EvaluationStatus: GQScope3EvaluationStatus;
  scope306EvaluationStatus: GQScope3EvaluationStatus;
  scope307EvaluationStatus: GQScope3EvaluationStatus;
  scope308EvaluationStatus: GQScope3EvaluationStatus;
  scope309EvaluationStatus: GQScope3EvaluationStatus;
  scope310EvaluationStatus: GQScope3EvaluationStatus;
  scope311EvaluationStatus: GQScope3EvaluationStatus;
  scope312EvaluationStatus: GQScope3EvaluationStatus;
  scope313EvaluationStatus: GQScope3EvaluationStatus;
  scope314EvaluationStatus: GQScope3EvaluationStatus;
  scope315EvaluationStatus: GQScope3EvaluationStatus;
  scope316EvaluationStatus: GQScope3EvaluationStatus;
  scope317EvaluationStatus: GQScope3EvaluationStatus;
  totalEmissionsNonzero: Scalars['Boolean']['output'];
};

export type GQChangeset = GQAssumptionChangeset;

export type GQCheckAndHealForecast = {
  __typename?: 'CheckAndHealForecast';
  currentVersion: Scalars['String']['output'];
  error: Maybe<Scalars['String']['output']>;
  healed: Maybe<Scalars['Boolean']['output']>;
  latestVersion: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  valid: Scalars['Boolean']['output'];
};

export type GQCheckAndHealForecastInput = {
  orgIds: Array<Scalars['ID']['input']>;
  shouldHeal?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCheckAndHealForecastPayload = {
  __typename?: 'CheckAndHealForecastPayload';
  results: Array<GQCheckAndHealForecast>;
};

export type GQCleanEnergyCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'CleanEnergyCommitment';
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    targetPercentageCleanEnergy: Maybe<Scalars['Int']['output']>;
    targetYear: Maybe<Scalars['Int']['output']>;
  };

export type GQCleanEnergyCommitmentInput = {
  targetPercentageCleanEnergy?: InputMaybe<Scalars['Int']['input']>;
  targetYear?: InputMaybe<Scalars['Int']['input']>;
};

export const GQCleanPowerContractualInstrument = {
  CompetitiveRetailProduct: 'CompetitiveRetailProduct',
  Ppa: 'PPA',
  SpotEac: 'SpotEAC',
  Unknown: 'Unknown',
  UtilityCleanPowerProgram: 'UtilityCleanPowerProgram',
  UtilityGreenTariff: 'UtilityGreenTariff',
  Vppa: 'VPPA',
} as const;

export type GQCleanPowerContractualInstrument =
  (typeof GQCleanPowerContractualInstrument)[keyof typeof GQCleanPowerContractualInstrument];
export type GQCleanPowerImpact = {
  __typename?: 'CleanPowerImpact';
  appliedInstructions: Array<GQMarketplaceAllocationInstructionImpact>;
};

export type GQCleanUpDuplicateSupportingDocAssociationsInput = {
  activityDataUserUploadIds: Array<Scalars['ID']['input']>;
};

export type GQCleanUpDuplicateSupportingDocAssociationsPayload = {
  __typename?: 'CleanUpDuplicateSupportingDocAssociationsPayload';
  deletedSupportingDocAssociationIds: Array<Scalars['ID']['output']>;
};

export type GQClearAllReportAnswerVerifiersFailurePayload = {
  __typename?: 'ClearAllReportAnswerVerifiersFailurePayload';
  num: Scalars['Int']['output'];
};

export type GQClearReportAnswerVerifierFailureInput = {
  reportAnswerId: Scalars['ID']['input'];
};

export type GQClearReportAnswerVerifierFailurePayload = {
  __typename?: 'ClearReportAnswerVerifierFailurePayload';
  reportAnswerId: Scalars['ID']['output'];
};

export type GQClearReportAnswerVerifierFailuresInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQClearReportAnswerVerifierFailuresPayload = {
  __typename?: 'ClearReportAnswerVerifierFailuresPayload';
  num: Scalars['Int']['output'];
};

export type GQClimateProgramCalendarEvent = {
  __typename?: 'ClimateProgramCalendarEvent';
  endDate: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
};

export type GQClimateProgramInfo = {
  __typename?: 'ClimateProgramInfo';
  hasCustomizedSteps: Scalars['Boolean']['output'];
  steps: Array<GQClimateProgramInfoStep>;
  targets: Array<GQClimateProgramInfoTarget>;
};

export type GQClimateProgramInfoInput = {
  steps?: InputMaybe<Array<GQClimateProgramInfoStepInput>>;
};

export type GQClimateProgramInfoStep = {
  __typename?: 'ClimateProgramInfoStep';
  descriptionMd: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQClimateProgramInfoStepInput = {
  descriptionMd: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type GQClimateProgramInfoTarget = {
  __typename?: 'ClimateProgramInfoTarget';
  amount: Scalars['String']['output'];
  descriptor: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GQClimateProgramLifecycle = {
  __typename?: 'ClimateProgramLifecycle';
  completedStage: GQCompletedLifecycleStage;
  kickoffBlurb: Maybe<Scalars['String']['output']>;
  nextStage: Maybe<GQNextLifecycleStage>;
};

export type GQClimateProgramProject = GQClimateProgramTimelineObject &
  GQIClimateProgramProject &
  GQIdInterface & {
    __typename?: 'ClimateProgramProject';
    canonicalClimateProgramProject: Maybe<GQCanonicalClimateProgramProject>;
    completedAt: Maybe<Scalars['DateTime']['output']>;
    datasetRequirements: Array<GQClimateProgramProjectRequirementDataset>;
    description: Scalars['String']['output'];
    endDate: Maybe<Scalars['Date']['output']>;
    iconType: GQClimateProgramProjectIconType;
    id: Scalars['ID']['output'];
    kind: GQClimateProgramProjectKind;
    measurementProject: Maybe<GQMeasurementProject>;
    name: Scalars['String']['output'];
    plannedYear: Scalars['Int']['output'];
    startDate: Maybe<Scalars['Date']['output']>;
    status: GQClimateProgramProjectStatus;
  };

export const GQClimateProgramProjectIconType = {
  CarbonOffset: 'CarbonOffset',
  CarbonRemoval: 'CarbonRemoval',
  CleanPower: 'CleanPower',
  Commitments: 'Commitments',
  Custom: 'Custom',
  GenericReduction: 'GenericReduction',
  Kickoff: 'Kickoff',
  ReductionPlan: 'ReductionPlan',
  ReportDisclosure: 'ReportDisclosure',
  ReportGoverningBody: 'ReportGoverningBody',
  ReportPublic: 'ReportPublic',
  Simulate: 'Simulate',
  SupplierEngagement: 'SupplierEngagement',
  Targets: 'Targets',
  UnderstandEmissions: 'UnderstandEmissions',
} as const;

export type GQClimateProgramProjectIconType =
  (typeof GQClimateProgramProjectIconType)[keyof typeof GQClimateProgramProjectIconType];
export const GQClimateProgramProjectKind = {
  Custom: 'Custom',
  Insight: 'Insight',
  Reduction: 'Reduction',
  Report: 'Report',
  Target: 'Target',
} as const;

export type GQClimateProgramProjectKind =
  (typeof GQClimateProgramProjectKind)[keyof typeof GQClimateProgramProjectKind];
export type GQClimateProgramProjectRequirementDataset = GQIdInterface & {
  __typename?: 'ClimateProgramProjectRequirementDataset';
  additionalNotesMd: Maybe<Scalars['String']['output']>;
  canonicalGhgpRequirement: Maybe<GQCanonicalClimateProgramProjectRequirementGhgp>;
  climateProgramProject: GQClimateProgramProject;
  dataset: GQDataset;
  id: Scalars['ID']['output'];
};

export const GQClimateProgramProjectRequirementFidelity = {
  ActivityBased: 'ActivityBased',
  Cee: 'Cee',
  SpendBased: 'SpendBased',
  SquareFootageBased: 'SquareFootageBased',
  UtilitiesBased: 'UtilitiesBased',
} as const;

export type GQClimateProgramProjectRequirementFidelity =
  (typeof GQClimateProgramProjectRequirementFidelity)[keyof typeof GQClimateProgramProjectRequirementFidelity];
export const GQClimateProgramProjectStatus = {
  Active: 'Active',
  Completed: 'Completed',
  Overdue: 'Overdue',
  Planned: 'Planned',
} as const;

export type GQClimateProgramProjectStatus =
  (typeof GQClimateProgramProjectStatus)[keyof typeof GQClimateProgramProjectStatus];
export const GQClimateProgramProjectSubkind = {
  MarketBased: 'MarketBased',
  OperationalSolutions: 'OperationalSolutions',
} as const;

export type GQClimateProgramProjectSubkind =
  (typeof GQClimateProgramProjectSubkind)[keyof typeof GQClimateProgramProjectSubkind];
export type GQClimateProgramTimelineObject = {
  id: Scalars['ID']['output'];
};

export type GQClimateProgramTimelineProject = GQIClimateProgramProject &
  GQIdInterface & {
    __typename?: 'ClimateProgramTimelineProject';
    canonicalClimateProgramProject: Maybe<GQCanonicalClimateProgramProject>;
    completedAt: Maybe<Scalars['DateTime']['output']>;
    description: Scalars['String']['output'];
    endDate: Maybe<Scalars['Date']['output']>;
    iconType: GQClimateProgramProjectIconType;
    id: Scalars['ID']['output'];
    kind: GQClimateProgramProjectKind;
    name: Scalars['String']['output'];
    object: GQClimateProgramProject | GQMeasurementProject | GQReport;
    objectType: GQClimateProgramTimelineProjectObjectType;
    plannedYear: Scalars['Int']['output'];
    startDate: Maybe<Scalars['Date']['output']>;
  };

export const GQClimateProgramTimelineProjectObjectType = {
  ClimateProgramProject: 'ClimateProgramProject',
  MeasurementProject: 'MeasurementProject',
  Report: 'Report',
} as const;

export type GQClimateProgramTimelineProjectObjectType =
  (typeof GQClimateProgramTimelineProjectObjectType)[keyof typeof GQClimateProgramTimelineProjectObjectType];
export type GQCliqReferenceDataConnection = {
  __typename?: 'CliqReferenceDataConnection';
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  referenceDataSource: GQReferenceDataSource;
};

export const GQColumnDimension = {
  Monthly: 'Monthly',
  Summary: 'Summary',
  Yearly: 'Yearly',
} as const;

export type GQColumnDimension =
  (typeof GQColumnDimension)[keyof typeof GQColumnDimension];
export type GQColumnMapping = {
  __typename?: 'ColumnMapping';
  sourceFileColumnName: Maybe<Scalars['String']['output']>;
  watershedColumnName: Scalars['String']['output'];
};

export type GQComment = {
  __typename?: 'Comment';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  person: Maybe<GQUser | GQWatershedEmployee>;
};

export type GQCommentConnection = {
  __typename?: 'CommentConnection';
  edges: Array<Maybe<GQCommentEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQComment>;
};

export type GQCompany = GQIdInterface & {
  __typename?: 'Company';
  alternateNames: Maybe<Array<Scalars['String']['output']>>;
  beas: Array<GQCompanyBea>;
  betterCompanyId: Maybe<Scalars['ID']['output']>;
  climateProgress: GQCompanyClimateProgress;
  companyIngestionRecordUpdateId: Maybe<Scalars['ID']['output']>;
  companyRelationships: Array<GQCompanyRelationship>;
  companyWebsite: Maybe<GQCompanyUrl>;
  companyWebsiteUrlId: Maybe<Scalars['ID']['output']>;
  contacts: Array<GQSupplierContact>;
  countries: Array<GQCompanyCountry>;
  country: Maybe<Scalars['String']['output']>;
  countryAlpha2: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  disclosures: Array<GQDisclosure>;
  disclosuresV2: Array<GQDisclosure>;
  duns: Array<GQCompanyDuns>;
  editor: Maybe<GQUser>;
  eins: Array<GQCompanyEin>;
  fullLogoUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDemo: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isWatershedCorporateCustomer: Scalars['Boolean']['output'];
  isins: Array<GQCompanyIsin>;
  leis: Array<GQCompanyLei>;
  localRegistries: Array<GQCompanyLocalRegistry>;
  logo: Maybe<GQCompanyUrl>;
  logoUrl: Maybe<Scalars['String']['output']>;
  logoUrlId: Maybe<Scalars['ID']['output']>;
  naicsCode: Maybe<Scalars['String']['output']>;
  naicsCodeSource: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  names: Array<GQCompanyName>;
  parentRelationships: Array<GQParentRelationship>;
  replacedBy: Maybe<GQCompany>;
  sAndP: Maybe<GQCompanySAndP>;
  sbtiStage: GQCompanySbtCommitmentStage;
  sfdcOpportunityId: Maybe<Scalars['String']['output']>;
  sfdcStage: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  sourceId: Scalars['ID']['output'];
  sourceKey: Maybe<GQCompanySourceKey>;
  sustainabilityWebsite: Maybe<GQCompanyUrl>;
  sustainabilityWebsiteUrl: Maybe<Scalars['String']['output']>;
  sustainabilityWebsiteUrlId: Maybe<Scalars['ID']['output']>;
  tickerSymbols: Array<GQCompanyTickerSymbol>;
  updatedAt: Scalars['DateTime']['output'];
  url: Maybe<Scalars['String']['output']>;
  userEditorId: Maybe<Scalars['ID']['output']>;
  vats: Array<GQCompanyVat>;
};

export type GQCompanyDisclosuresArgs = {
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQCompanyDisclosuresV2Args = {
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQCompanyParentRelationshipsArgs = {
  onlyGetActiveRelationships: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCompanyAutoMatch = {
  __typename?: 'CompanyAutoMatch';
  company: GQCompany;
  confidenceScore: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
};

export type GQCompanyBea = GQIdInterface & {
  __typename?: 'CompanyBea';
  bea: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyBeaInput = {
  bea: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyChangeRequest = {
  __typename?: 'CompanyChangeRequest';
  action: GQCompanyChangeRequestAction;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  org: GQOrganization;
  payload: Scalars['JSONString']['output'];
  userEditor: Maybe<GQUser>;
};

export const GQCompanyChangeRequestAction = {
  Create: 'Create',
} as const;

export type GQCompanyChangeRequestAction =
  (typeof GQCompanyChangeRequestAction)[keyof typeof GQCompanyChangeRequestAction];
export type GQCompanyClimateCommitment = {
  commitmentMadeDate: Maybe<Scalars['Date']['output']>;
  commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
  commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
  description: Maybe<Scalars['String']['output']>;
  externalUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: GQCompanyClimateCommitmentKind;
};

export const GQCompanyClimateCommitmentKind = {
  CarbonNeutral: 'CarbonNeutral',
  CleanEnergy: 'CleanEnergy',
  NetZero: 'NetZero',
  ScienceBasedTarget: 'ScienceBasedTarget',
} as const;

export type GQCompanyClimateCommitmentKind =
  (typeof GQCompanyClimateCommitmentKind)[keyof typeof GQCompanyClimateCommitmentKind];
export type GQCompanyClimateCommitmentPayload = {
  __typename?: 'CompanyClimateCommitmentPayload';
  commitment:
    | GQCarbonNeutralCommitment
    | GQCleanEnergyCommitment
    | GQNetZeroCommitment
    | GQScienceBasedTargetCommitment;
};

export type GQCompanyClimateCommitmentRaw = {
  __typename?: 'CompanyClimateCommitmentRaw';
  commitmentMadeDate: Maybe<Scalars['Date']['output']>;
  commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
  commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  externalUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: GQCompanyClimateCommitmentKind;
  orgId: Maybe<Scalars['ID']['output']>;
  privateDisclosureId: Maybe<Scalars['ID']['output']>;
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
  sbtBaselineYear: Maybe<Scalars['Int']['output']>;
  sbtCommitmentDeadline: Maybe<Scalars['Date']['output']>;
  sbtCommitmentStage: Maybe<GQSbtCommitmentStage>;
  sbtCommitmentType: Maybe<GQSbtCommitmentType>;
  sbtLongTermTargetYear: Maybe<Scalars['Int']['output']>;
  sbtNearTermTargetYear: Maybe<Scalars['Int']['output']>;
  sbtNetZeroCommitted: Maybe<Scalars['Boolean']['output']>;
  sbtNetZeroTargetYear: Maybe<Scalars['Int']['output']>;
  sbtTargetClassification: Maybe<GQSbtTargetClassification>;
  targetPercentageCleanEnergy: Maybe<Scalars['Float']['output']>;
  targetYear: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userEditorId: Maybe<Scalars['ID']['output']>;
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export const GQCompanyClimateProgress = {
  DisclosedEmissions: 'DisclosedEmissions',
  DisclosedEmissionsAndSetCommitments: 'DisclosedEmissionsAndSetCommitments',
  DisclosedEmissionsInitiatives: 'DisclosedEmissionsInitiatives',
  DisclosedEmissionsTargetsInitiatives: 'DisclosedEmissionsTargetsInitiatives',
  DisclosedInitiatives: 'DisclosedInitiatives',
  DisclosedTargets: 'DisclosedTargets',
  DisclosedTargetsInitiatives: 'DisclosedTargetsInitiatives',
  None: 'None',
  Unknown: 'Unknown',
} as const;

export type GQCompanyClimateProgress =
  (typeof GQCompanyClimateProgress)[keyof typeof GQCompanyClimateProgress];
export type GQCompanyColorsInput = {
  primary?: InputMaybe<Scalars['String']['input']>;
  secondary?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type GQCompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<Maybe<GQCompanyEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCompanyCountry = GQIdInterface & {
  __typename?: 'CompanyCountry';
  countryAlpha2: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyCountryInput = {
  countryAlpha2: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  sourceId: Scalars['String']['input'];
};

export type GQCompanyDataFiltersForMetric = {
  cdpScoreSet?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Scalars['String']['input']>;
  peerCompanyGroupId?: InputMaybe<Scalars['ID']['input']>;
  reportingYear?: InputMaybe<Scalars['Int']['input']>;
};

export type GQCompanyDuns = GQIdInterface & {
  __typename?: 'CompanyDuns';
  dunsNumber: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyDunsInput = {
  dunsNumber: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCompany>;
};

export type GQCompanyEin = GQIdInterface & {
  __typename?: 'CompanyEin';
  editor: GQUser;
  ein: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyEinInput = {
  ein: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyEmissionsInterface = {
  scope1Kgco2e: Maybe<Scalars['Float']['output']>;
  scope2LocationKgco2e: Maybe<Scalars['Float']['output']>;
  scope2MarketKgco2e: Maybe<Scalars['Float']['output']>;
  scope3Kgco2e: Maybe<Scalars['Float']['output']>;
  scope301Kgco2e: Maybe<Scalars['Float']['output']>;
  scope302Kgco2e: Maybe<Scalars['Float']['output']>;
  scope303Kgco2e: Maybe<Scalars['Float']['output']>;
  scope304Kgco2e: Maybe<Scalars['Float']['output']>;
  scope305Kgco2e: Maybe<Scalars['Float']['output']>;
  scope306Kgco2e: Maybe<Scalars['Float']['output']>;
  scope307Kgco2e: Maybe<Scalars['Float']['output']>;
  scope308Kgco2e: Maybe<Scalars['Float']['output']>;
  scope309Kgco2e: Maybe<Scalars['Float']['output']>;
  scope310Kgco2e: Maybe<Scalars['Float']['output']>;
  scope311Kgco2e: Maybe<Scalars['Float']['output']>;
  scope312Kgco2e: Maybe<Scalars['Float']['output']>;
  scope313Kgco2e: Maybe<Scalars['Float']['output']>;
  scope314Kgco2e: Maybe<Scalars['Float']['output']>;
  scope315Kgco2e: Maybe<Scalars['Float']['output']>;
  scope316Kgco2e: Maybe<Scalars['Float']['output']>;
  scope317Kgco2e: Maybe<Scalars['Float']['output']>;
};

export type GQCompanyEmissionsInterfaceV2 = {
  revenueCurrency: Maybe<Scalars['String']['output']>;
  scope1: Maybe<Scalars['Float']['output']>;
  scope2: Maybe<Scalars['Float']['output']>;
  scope2Location: Maybe<Scalars['Float']['output']>;
  scope2Market: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
  scope301: Maybe<Scalars['Float']['output']>;
  scope302: Maybe<Scalars['Float']['output']>;
  scope303: Maybe<Scalars['Float']['output']>;
  scope304: Maybe<Scalars['Float']['output']>;
  scope305: Maybe<Scalars['Float']['output']>;
  scope306: Maybe<Scalars['Float']['output']>;
  scope307: Maybe<Scalars['Float']['output']>;
  scope308: Maybe<Scalars['Float']['output']>;
  scope309: Maybe<Scalars['Float']['output']>;
  scope310: Maybe<Scalars['Float']['output']>;
  scope311: Maybe<Scalars['Float']['output']>;
  scope312: Maybe<Scalars['Float']['output']>;
  scope313: Maybe<Scalars['Float']['output']>;
  scope314: Maybe<Scalars['Float']['output']>;
  scope315: Maybe<Scalars['Float']['output']>;
  scope316: Maybe<Scalars['Float']['output']>;
  scope317: Maybe<Scalars['Float']['output']>;
  units: GQCompanyEmissionsUnits;
};

export const GQCompanyEmissionsUnits = {
  AllocatedKgco2e: 'allocatedKgco2e',
  Kgco2e: 'kgco2e',
  Kgco2ePerDollar: 'kgco2ePerDollar',
} as const;

export type GQCompanyEmissionsUnits =
  (typeof GQCompanyEmissionsUnits)[keyof typeof GQCompanyEmissionsUnits];
export type GQCompanyIngestionDiff = GQIdInterface & {
  __typename?: 'CompanyIngestionDiff';
  company: Maybe<GQCompany>;
  companyMatchResults: Maybe<Scalars['JSONString']['output']>;
  companyMatchScore: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isCompanyDemo: Scalars['Boolean']['output'];
  primaryCompanyName: Scalars['String']['output'];
  processedAt: Maybe<Scalars['DateTime']['output']>;
  processedBy: Maybe<GQUser>;
  recordUpdates: Array<GQCompanyIngestionRecordUpdate>;
  sourceRecord: Maybe<GQCompanyIngestionSourceRecord>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQCompanyIngestionDiffError = {
  __typename?: 'CompanyIngestionDiffError';
  companyIngestionDiffId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  errorCode: Scalars['String']['output'];
  errorData: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export const GQCompanyIngestionOperation = {
  Insert: 'Insert',
  Update: 'Update',
} as const;

export type GQCompanyIngestionOperation =
  (typeof GQCompanyIngestionOperation)[keyof typeof GQCompanyIngestionOperation];
export type GQCompanyIngestionRecordUpdate = GQIdInterface & {
  __typename?: 'CompanyIngestionRecordUpdate';
  createdAt: Scalars['DateTime']['output'];
  diff: GQCompanyIngestionDiff;
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['JSONString']['output']>;
  oldValue: Maybe<Scalars['JSONString']['output']>;
  recordId: Maybe<Scalars['String']['output']>;
  status: GQCompanyIngestionRecordUpdateStatus;
  tableName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQCompanyIngestionRecordUpdateFilters = {
  filterToHighCdpDisclosureQuality?: InputMaybe<Scalars['Boolean']['input']>;
  isCompanyDemo?: InputMaybe<Scalars['Boolean']['input']>;
  matches?: InputMaybe<Array<GQCompanyMatchScore>>;
  operation?: InputMaybe<GQCompanyIngestionOperation>;
  statuses?: InputMaybe<Array<GQCompanyIngestionRecordUpdateStatus>>;
};

export type GQCompanyIngestionRecordUpdateRequestConnection = {
  __typename?: 'CompanyIngestionRecordUpdateRequestConnection';
  edges: Array<Maybe<GQCompanyIngestionRecordUpdateRequestEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCompanyIngestionRecordUpdateRequestEdge = {
  __typename?: 'CompanyIngestionRecordUpdateRequestEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCompanyIngestionRecordUpdate>;
};

export const GQCompanyIngestionRecordUpdateStatus = {
  Approved: 'Approved',
  Declined: 'Declined',
  Unprocessed: 'Unprocessed',
} as const;

export type GQCompanyIngestionRecordUpdateStatus =
  (typeof GQCompanyIngestionRecordUpdateStatus)[keyof typeof GQCompanyIngestionRecordUpdateStatus];
export type GQCompanyIngestionSourceRecord =
  | GQCdpVendorData
  | GQCompany
  | GQCompanyChangeRequest
  | GQIngestedSbtiCompanyUpdate
  | GQSbtTarget;

export type GQCompanyInput = {
  beas?: InputMaybe<Array<GQCompanyBeaInput>>;
  companyWebsiteUrl?: InputMaybe<GQCompanyUrlInput>;
  countries?: InputMaybe<Array<GQCompanyCountryInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  duns?: InputMaybe<Array<GQCompanyDunsInput>>;
  eins?: InputMaybe<Array<GQCompanyEinInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isDemo: Scalars['Boolean']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isins?: InputMaybe<Array<GQCompanyIsinInput>>;
  leis?: InputMaybe<Array<GQCompanyLeiInput>>;
  localRegistries?: InputMaybe<Array<GQCompanyLocalRegistryInput>>;
  logoUrl?: InputMaybe<GQCompanyUrlInput>;
  names?: InputMaybe<Array<GQCompanyNameInput>>;
  replacedById?: InputMaybe<Scalars['String']['input']>;
  sAndPs?: InputMaybe<Array<GQCompanySAndPInput>>;
  salesforce?: InputMaybe<GQCompanySalesforceInput>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  sustainabilityWebsiteUrl?: InputMaybe<GQCompanyUrlInput>;
  tickerSymbols?: InputMaybe<Array<GQCompanyTickerSymbolInput>>;
  vats?: InputMaybe<Array<GQCompanyVatInput>>;
};

export type GQCompanyIsin = GQIdInterface & {
  __typename?: 'CompanyIsin';
  editor: GQUser;
  id: Scalars['ID']['output'];
  isin: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyIsinInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isin: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyLei = GQIdInterface & {
  __typename?: 'CompanyLei';
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  lei: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyLeiInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  lei: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyLocalRegistry = GQIdInterface & {
  __typename?: 'CompanyLocalRegistry';
  countryAlpha2: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  localRegistryId: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyLocalRegistryInput = {
  countryAlpha2: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  localRegistryId: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyMatch = GQIdInterface & {
  __typename?: 'CompanyMatch';
  beaMatch: Maybe<Scalars['String']['output']>;
  company: GQCompany;
  id: Scalars['ID']['output'];
  isinMatch: Maybe<Scalars['String']['output']>;
  leiMatch: Maybe<Scalars['String']['output']>;
  nameMatch: Maybe<Scalars['String']['output']>;
  sAndPIdMatch: Maybe<Scalars['String']['output']>;
  score: GQCompanyMatchScore;
  tickerMatch: Maybe<Scalars['String']['output']>;
};

export const GQCompanyMatchScore = {
  Conflict: 'CONFLICT',
  Exact: 'EXACT',
  High: 'HIGH',
  Mid: 'MID',
  None: 'NONE',
  VeryHigh: 'VERY_HIGH',
} as const;

export type GQCompanyMatchScore =
  (typeof GQCompanyMatchScore)[keyof typeof GQCompanyMatchScore];
export type GQCompanyName = GQIdInterface & {
  __typename?: 'CompanyName';
  cleanedName: Maybe<Scalars['String']['output']>;
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyNameInput = {
  cleanedName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
};

export type GQCompanyOverrideMatchInput = {
  autoApprove: Scalars['Boolean']['input'];
  insertNewCompany: Scalars['Boolean']['input'];
  updateCompanyId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCompanyPortalSettings = {
  __typename?: 'CompanyPortalSettings';
  id: Maybe<Scalars['ID']['output']>;
  portalCallout: Maybe<Scalars['String']['output']>;
  portalTitle: Scalars['String']['output'];
  sidebarContent: Maybe<Scalars['String']['output']>;
  toggleHeaderImage: Maybe<Scalars['Boolean']['output']>;
};

export type GQCompanyPublicDisclosure = GQIdInterface & {
  __typename?: 'CompanyPublicDisclosure';
  editor: GQUser;
  id: Scalars['ID']['output'];
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
  source: GQCompanySourceKey;
  sourceId: Scalars['ID']['output'];
};

export type GQCompanyRelationship = {
  __typename?: 'CompanyRelationship';
  companyA: GQCompany;
  companyAId: Scalars['ID']['output'];
  companyB: GQCompany;
  companyBId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  relationshipEndDate: Maybe<Scalars['YearMonth']['output']>;
  relationshipStartDate: Maybe<Scalars['YearMonth']['output']>;
  relationshipType: GQCompanyRelationshipType;
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export const GQCompanyRelationshipType = {
  ParentChild: 'ParentChild',
} as const;

export type GQCompanyRelationshipType =
  (typeof GQCompanyRelationshipType)[keyof typeof GQCompanyRelationshipType];
export type GQCompanyReportingFacts = GQIdInterface & {
  __typename?: 'CompanyReportingFacts';
  asOfMonth: Scalars['YearMonth']['output'];
  assetsEuUsd: Maybe<Scalars['Float']['output']>;
  assetsGlobalUsd: Maybe<Scalars['Float']['output']>;
  assetsUkUsd: Maybe<Scalars['Float']['output']>;
  companyId: Scalars['ID']['output'];
  federalContractsUkUsd: Maybe<Scalars['Float']['output']>;
  federalContractsUsUsd: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isBank: Maybe<Scalars['Boolean']['output']>;
  isDirectorsFilerUk: Maybe<Scalars['Boolean']['output']>;
  isDoingBusinessCa: Maybe<Scalars['Boolean']['output']>;
  isInsurance: Maybe<Scalars['Boolean']['output']>;
  isLlpUk: Maybe<Scalars['Boolean']['output']>;
  isNfisFilerUk: Maybe<Scalars['Boolean']['output']>;
  isPublicEu: Maybe<Scalars['Boolean']['output']>;
  isSmallCreditInsurance: Maybe<Scalars['Boolean']['output']>;
  isTradedBankingLlpUk: Maybe<Scalars['Boolean']['output']>;
  largestContractUkUsd: Maybe<Scalars['Float']['output']>;
  legalEntityCountries: Maybe<Array<Scalars['String']['output']>>;
  listedExchanges: Maybe<Array<Scalars['String']['output']>>;
  lseListingStatus: Maybe<Scalars['String']['output']>;
  nfrdFilerStatus: Maybe<Scalars['String']['output']>;
  numEmployeesEu: Maybe<Scalars['Int']['output']>;
  numEmployeesGlobal: Maybe<Scalars['Int']['output']>;
  numEmployeesUk: Maybe<Scalars['Int']['output']>;
  overriddenAt: Maybe<Scalars['DateTime']['output']>;
  primaryJurisdiction: Maybe<Scalars['String']['output']>;
  revenueEuUsd: Maybe<Scalars['Float']['output']>;
  revenueGlobalUsd: Maybe<Scalars['Float']['output']>;
  revenueUkUsd: Maybe<Scalars['Float']['output']>;
  secFilerStatus: Maybe<Scalars['String']['output']>;
};

export type GQCompanyReportingFactsInput = {
  assetsEuUsd?: InputMaybe<Scalars['Float']['input']>;
  assetsGlobalUsd?: InputMaybe<Scalars['Float']['input']>;
  assetsUkUsd?: InputMaybe<Scalars['Float']['input']>;
  federalContractsUkUsd?: InputMaybe<Scalars['Float']['input']>;
  federalContractsUsUsd?: InputMaybe<Scalars['Float']['input']>;
  isBank?: InputMaybe<Scalars['Boolean']['input']>;
  isDirectorsFilerUk?: InputMaybe<Scalars['Boolean']['input']>;
  isDoingBusinessCa?: InputMaybe<Scalars['Boolean']['input']>;
  isInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  isLlpUk?: InputMaybe<Scalars['Boolean']['input']>;
  isNfisFilerUk?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEu?: InputMaybe<Scalars['Boolean']['input']>;
  isSmallCreditInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  isTradedBankingLlpUk?: InputMaybe<Scalars['Boolean']['input']>;
  largestContractUkUsd?: InputMaybe<Scalars['Float']['input']>;
  legalEntityCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  listedExchanges?: InputMaybe<Array<Scalars['String']['input']>>;
  lseListingStatus?: InputMaybe<Scalars['String']['input']>;
  nfrdFilerStatus?: InputMaybe<Scalars['String']['input']>;
  numEmployeesEu?: InputMaybe<Scalars['Int']['input']>;
  numEmployeesGlobal?: InputMaybe<Scalars['Int']['input']>;
  numEmployeesUk?: InputMaybe<Scalars['Int']['input']>;
  primaryJurisdiction?: InputMaybe<Scalars['String']['input']>;
  revenueEuUsd?: InputMaybe<Scalars['Float']['input']>;
  revenueGlobalUsd?: InputMaybe<Scalars['Float']['input']>;
  revenueUkUsd?: InputMaybe<Scalars['Float']['input']>;
  secFilerStatus?: InputMaybe<Scalars['String']['input']>;
};

export type GQCompanySAndP = GQIdInterface & {
  __typename?: 'CompanySAndP';
  editor: GQUser;
  id: Scalars['ID']['output'];
  sAndPId: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanySAndPInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  sAndPId: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
};

export const GQCompanySbtCommitmentStage = {
  Committed: 'Committed',
  None: 'None',
  TargetsDeveloped: 'TargetsDeveloped',
  TargetsSet: 'TargetsSet',
  TargetsSubmitted: 'TargetsSubmitted',
} as const;

export type GQCompanySbtCommitmentStage =
  (typeof GQCompanySbtCommitmentStage)[keyof typeof GQCompanySbtCommitmentStage];
export type GQCompanySalesforce = GQIdInterface & {
  __typename?: 'CompanySalesforce';
  editor: GQUser;
  id: Scalars['ID']['output'];
  opportunityId: Maybe<Scalars['String']['output']>;
  opportunityStage: Maybe<Scalars['String']['output']>;
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanySalesforceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  opportunityId?: InputMaybe<Scalars['String']['input']>;
  opportunityStage?: InputMaybe<Scalars['String']['input']>;
  sourceId: Scalars['ID']['input'];
};

export type GQCompanySearchResult = {
  __typename?: 'CompanySearchResult';
  companyIdsWithSupplierSpecificEfs: Array<Scalars['String']['output']>;
  results: Array<GQCompany>;
};

export type GQCompanySourceKey = GQIdInterface & {
  __typename?: 'CompanySourceKey';
  createdAt: Scalars['DateTime']['output'];
  editor: Maybe<GQUser>;
  id: Scalars['ID']['output'];
  priority: Scalars['Int']['output'];
  sourceKey: Scalars['String']['output'];
  sourceNotes: Maybe<Scalars['String']['output']>;
  sourceUrl: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userVisibleSourceNotes: Maybe<Scalars['String']['output']>;
  userVisibleSourceUrl: Maybe<Scalars['String']['output']>;
};

export type GQCompanySourceKeyPayload = {
  __typename?: 'CompanySourceKeyPayload';
  companySourceKey: GQCompanySourceKey;
};

export type GQCompanySurvey = {
  __typename?: 'CompanySurvey';
  approvedAt: Maybe<Scalars['DateTime']['output']>;
  comments: Array<GQCompanySurveyComment>;
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  crossOrgModelId: Scalars['String']['output'];
  currentAssignee: GQCompanySurveyAssignee;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  dueDate: Maybe<Scalars['Date']['output']>;
  edits: Array<GQCompanySurveyEdit>;
  engagementTaskBatchId: Maybe<Scalars['ID']['output']>;
  engagementTaskConfig: GQEngagementTaskConfig;
  engagementTaskConfigId: Scalars['ID']['output'];
  expenseCategories: Maybe<Array<GQDiscloseEmissionsExpenseCategories>>;
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  facilityName: Maybe<Scalars['String']['output']>;
  footprintEstimate: Maybe<GQFootprintEstimateOutput>;
  id: Scalars['ID']['output'];
  initialSupplierAssigneeEmail: Maybe<Scalars['String']['output']>;
  latestCeeWorkflowId: Maybe<Scalars['ID']['output']>;
  latestPleeWorkflowId: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  proposeTargetEmissionsTarget: Maybe<GQSimpleTimeseries>;
  proposeTargetFilters: Maybe<GQFilterExpressionGroup>;
  proposeTargetIntensityType: Maybe<GQDisclosureTargetIntensityType>;
  reportingYear: Maybe<Scalars['Int']['output']>;
  rootAssignee: Maybe<Scalars['ID']['output']>;
  rootCompany: GQCompany;
  rootCompanyId: Scalars['ID']['output'];
  rootOrgId: Scalars['ID']['output'];
  status: GQCompanySurveyStatus;
  statusChangedAt: Maybe<Scalars['DateTime']['output']>;
  submittedAnswers: Array<GQCompanySurveyAnswer>;
  submittedAt: Maybe<Scalars['DateTime']['output']>;
  supplierAssignee: Maybe<Scalars['ID']['output']>;
  supplierFacingDescription: Maybe<Scalars['String']['output']>;
  supplierOrgId: Maybe<Scalars['ID']['output']>;
  /** @deprecated replaced with custom portal callout configuration */
  supplierPortalCustomEducation: Maybe<Scalars['Boolean']['output']>;
  surveyKind: GQCompanySurveyKind;
  watershedGeneric: Maybe<Scalars['Boolean']['output']>;
};

export type GQCompanySurveyAnswer = {
  __typename?: 'CompanySurveyAnswer';
  answer: Maybe<Scalars['JSONString']['output']>;
  attachments: Array<GQCompanySurveyAnswerAttachment>;
  id: Scalars['ID']['output'];
  questionIndex: Maybe<Scalars['Int']['output']>;
  questionKey: Scalars['String']['output'];
  skipped: Scalars['Boolean']['output'];
  surveyId: Scalars['ID']['output'];
};

export type GQCompanySurveyAnswerAttachment = {
  __typename?: 'CompanySurveyAnswerAttachment';
  fileMetadata: GQFileMetadata;
  originalFilename: Scalars['String']['output'];
  questionKey: Scalars['String']['output'];
  signedDownloadUrl: Maybe<Scalars['String']['output']>;
};

export const GQCompanySurveyAssignee = {
  RootCustomer: 'RootCustomer',
  Supplier: 'Supplier',
} as const;

export type GQCompanySurveyAssignee =
  (typeof GQCompanySurveyAssignee)[keyof typeof GQCompanySurveyAssignee];
export type GQCompanySurveyComment = {
  __typename?: 'CompanySurveyComment';
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  userEditorId: Maybe<Scalars['ID']['output']>;
  userEditorName: Maybe<Scalars['String']['output']>;
};

export type GQCompanySurveyConnection = {
  __typename?: 'CompanySurveyConnection';
  edges: Array<GQCompanySurveyEdge>;
  pageInfo: GQPageInfo;
};

export type GQCompanySurveyEdge = {
  __typename?: 'CompanySurveyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCompanySurvey>;
};

export type GQCompanySurveyEdit = {
  __typename?: 'CompanySurveyEdit';
  columnName: GQEditColumn;
  createdAt: Scalars['DateTime']['output'];
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['String']['output']>;
  oldValue: Maybe<Scalars['String']['output']>;
  userEditorId: Maybe<Scalars['ID']['output']>;
  userEditorName: Maybe<Scalars['String']['output']>;
  valueType: Maybe<GQEditValueType>;
};

export const GQCompanySurveyKind = {
  Nzsep: 'NZSEP',
  PortfolioCompany: 'PortfolioCompany',
  Supplier: 'Supplier',
} as const;

export type GQCompanySurveyKind =
  (typeof GQCompanySurveyKind)[keyof typeof GQCompanySurveyKind];
export const GQCompanySurveyQuestionKey = {
  AcceptedProposeTarget: 'AcceptedProposeTarget',
  ActivitiesNaics: 'ActivitiesNaics',
  AllocateEmissionsMethod: 'AllocateEmissionsMethod',
  AllocateEmissionsMethodV2: 'AllocateEmissionsMethodV2',
  AllocationMethodology: 'AllocationMethodology',
  AnnualPercentTurnover: 'AnnualPercentTurnover',
  BoardDiversity: 'BoardDiversity',
  CSuiteDiversity: 'CSuiteDiversity',
  CarbonCommitments: 'CarbonCommitments',
  CarbonMeasurementCompletionDate: 'CarbonMeasurementCompletionDate',
  CarbonMeasurementPlan: 'CarbonMeasurementPlan',
  CarbonMeasurementProvider: 'CarbonMeasurementProvider',
  CarbonRemoval: 'CarbonRemoval',
  CarbonRemovalAmount: 'CarbonRemovalAmount',
  CeeAbsoluteEmissionsByScope: 'CeeAbsoluteEmissionsByScope',
  CeeAggregatedBuildingSize: 'CeeAggregatedBuildingSize',
  CeeBiomassPercentage: 'CeeBiomassPercentage',
  CeeBuildings: 'CeeBuildings',
  CeeBusinessAirMiles: 'CeeBusinessAirMiles',
  CeeBusinessAirSpend: 'CeeBusinessAirSpend',
  CeeBusinessAirSpendCurrency: 'CeeBusinessAirSpendCurrency',
  CeeBusinessGroundMiles: 'CeeBusinessGroundMiles',
  CeeCloud: 'CeeCloud',
  CeeCloudProviders: 'CeeCloudProviders',
  CeeCloudTotalInstanceHours: 'CeeCloudTotalInstanceHours',
  CeeComputationNonCloud: 'CeeComputationNonCloud',
  CeeComputationNonCloudCleanPower: 'CeeComputationNonCloudCleanPower',
  CeeConsulting: 'CeeConsulting',
  CeeDairyAvgMilkGallonsPerYear: 'CeeDairyAvgMilkGallonsPerYear',
  CeeDairyManureManagement: 'CeeDairyManureManagement',
  CeeDairyManureManagementLiquidCap: 'CeeDairyManureManagementLiquidCap',
  CeeDairyNumberOfCows: 'CeeDairyNumberOfCows',
  CeeDairyProductionSystem: 'CeeDairyProductionSystem',
  CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeed:
    'CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeed',
  CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeedKgPerCow:
    'CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeedKgPerCow',
  CeeLogisticsAirKmTonnes: 'CeeLogisticsAirKmTonnes',
  CeeLogisticsAirLoading: 'CeeLogisticsAirLoading',
  CeeLogisticsFreezerSeaKmTonnes: 'CeeLogisticsFreezerSeaKmTonnes',
  CeeLogisticsFreezerTruckKmTonnes: 'CeeLogisticsFreezerTruckKmTonnes',
  CeeLogisticsRefrigeratedSeaKmTonnes: 'CeeLogisticsRefrigeratedSeaKmTonnes',
  CeeLogisticsRefrigeratedTruckKmTonnes:
    'CeeLogisticsRefrigeratedTruckKmTonnes',
  CeeLogisticsSeaKmTonnes: 'CeeLogisticsSeaKmTonnes',
  CeeLogisticsSeaLoading: 'CeeLogisticsSeaLoading',
  CeeLogisticsTrainKmTonnes: 'CeeLogisticsTrainKmTonnes',
  CeeLogisticsTrainLoading: 'CeeLogisticsTrainLoading',
  CeeLogisticsTruckKmTonnes: 'CeeLogisticsTruckKmTonnes',
  CeeLogisticsTruckLoading: 'CeeLogisticsTruckLoading',
  CeeOfficeDaysPerWeek: 'CeeOfficeDaysPerWeek',
  CeePaperCleanPowerPercentage: 'CeePaperCleanPowerPercentage',
  CeePaperless: 'CeePaperless',
  CeeRemotePercent: 'CeeRemotePercent',
  CeeRevenueIntensityByScope: 'CeeRevenueIntensityByScope',
  CeeTmsFileUpload: 'CeeTmsFileUpload',
  CeeTmsManualInput: 'CeeTmsManualInput',
  CeeWoodByCountry: 'CeeWoodByCountry',
  ChallengesProcuringRenewableForCustomer:
    'ChallengesProcuringRenewableForCustomer',
  CleanPowerDescription: 'CleanPowerDescription',
  CleanPowerImportReport: 'CleanPowerImportReport',
  CleanPowerInitiativeRenewableEnergyUsage:
    'CleanPowerInitiativeRenewableEnergyUsage',
  CleanPowerInitiativeRenewableEnergyUsageImport:
    'CleanPowerInitiativeRenewableEnergyUsageImport',
  CleanPowerPercentage: 'CleanPowerPercentage',
  CleanPowerPurchased: 'CleanPowerPurchased',
  Comment: 'Comment',
  CompanyIndustry: 'CompanyIndustry',
  CompanyName: 'CompanyName',
  ContactEmail: 'ContactEmail',
  ContactName: 'ContactName',
  ContactTitle: 'ContactTitle',
  CountriesOfOperation: 'CountriesOfOperation',
  CountryOfHeadquarters: 'CountryOfHeadquarters',
  CustomQuestions: 'CustomQuestions',
  CustomerSpendInReportingYear: 'CustomerSpendInReportingYear',
  DaysLostDueToInjury: 'DaysLostDueToInjury',
  DerivedSustainabilityInitiatives: 'DerivedSustainabilityInitiatives',
  EdciAdditionalGhgTargetDetails: 'EdciAdditionalGhgTargetDetails',
  EdciAdditionalMethodologyDetails: 'EdciAdditionalMethodologyDetails',
  EdciAnnualPercentTurnoverForFtes: 'EdciAnnualPercentTurnoverForFtes',
  EdciAnnualRevenueInMostRecentCalendarYear:
    'EdciAnnualRevenueInMostRecentCalendarYear',
  EdciCompanyGrowthStage: 'EdciCompanyGrowthStage',
  EdciCompanyStructure: 'EdciCompanyStructure',
  EdciCountryOfDomicile: 'EdciCountryOfDomicile',
  EdciCountryOfOperations: 'EdciCountryOfOperations',
  EdciDaysLostToInjury: 'EdciDaysLostToInjury',
  EdciDecarbonizationPlan: 'EdciDecarbonizationPlan',
  EdciEmissionsDataReviewDetails: 'EdciEmissionsDataReviewDetails',
  EdciEmployeeSurvey: 'EdciEmployeeSurvey',
  EdciFinancialDataCurrency: 'EdciFinancialDataCurrency',
  EdciFteCountEndOfPriorYear: 'EdciFteCountEndOfPriorYear',
  EdciFteCountEndOfYear: 'EdciFteCountEndOfYear',
  EdciLgbtqBoardMembers: 'EdciLgbtqBoardMembers',
  EdciLongTermNetZeroGoal: 'EdciLongTermNetZeroGoal',
  EdciNetChangeFteDueToMnA: 'EdciNetChangeFteDueToMnA',
  EdciOrganicNetNewHires: 'EdciOrganicNetNewHires',
  EdciPercentResponseEmployeeSurvey: 'EdciPercentResponseEmployeeSurvey',
  EdciPrimaryIndustryOfOperations: 'EdciPrimaryIndustryOfOperations',
  EdciPrimarySectorOfOperations: 'EdciPrimarySectorOfOperations',
  EdciScope1Emissions: 'EdciScope1Emissions',
  EdciScope1Methodology: 'EdciScope1Methodology',
  EdciScope2Emissions: 'EdciScope2Emissions',
  EdciScope2Methodology: 'EdciScope2Methodology',
  EdciScope3Emissions: 'EdciScope3Emissions',
  EdciScope3Methdology: 'EdciScope3Methdology',
  EdciShortTermReductionTarget: 'EdciShortTermReductionTarget',
  EdciTotalBoardMembers: 'EdciTotalBoardMembers',
  EdciTotalCSuitePositions: 'EdciTotalCSuitePositions',
  EdciTotalEnergyConsumption: 'EdciTotalEnergyConsumption',
  EdciTotalNetNewHires: 'EdciTotalNetNewHires',
  EdciTotalRenewableEnergyConsumption: 'EdciTotalRenewableEnergyConsumption',
  EdciTotalWomenCSuitePositions: 'EdciTotalWomenCSuitePositions',
  EdciUnderrepresentedGroupBoardMembers:
    'EdciUnderrepresentedGroupBoardMembers',
  EdciWomenBoardMembers: 'EdciWomenBoardMembers',
  EdciWorkRelatedFatalities: 'EdciWorkRelatedFatalities',
  EdciWorkRelatedInjuries: 'EdciWorkRelatedInjuries',
  EmployeeDiversity: 'EmployeeDiversity',
  EmployeeSurvey: 'EmployeeSurvey',
  EmployeeSurveyKinds: 'EmployeeSurveyKinds',
  EmployeeSurveyResponseRate: 'EmployeeSurveyResponseRate',
  EnergyUseEstimateMethod: 'EnergyUseEstimateMethod',
  EnvironmentalRiskAssessment: 'EnvironmentalRiskAssessment',
  EsgBoardDiscussion: 'EsgBoardDiscussion',
  EsgBoardMember: 'EsgBoardMember',
  EsgRiskAssessment: 'EsgRiskAssessment',
  EvaluateProposedTarget: 'EvaluateProposedTarget',
  ExpenseCategories: 'ExpenseCategories',
  ExpenseCategoryEmissions: 'ExpenseCategoryEmissions',
  ExpenseCategoryShareMethod: 'ExpenseCategoryShareMethod',
  ExpensesNaics: 'ExpensesNaics',
  ExternalReporting: 'ExternalReporting',
  FacilityBiomassNonWoodUsageAmount: 'FacilityBiomassNonWoodUsageAmount',
  FacilityBiomassNonWoodUsageFileUpload:
    'FacilityBiomassNonWoodUsageFileUpload',
  FacilityBiomassNonWoodUsageUnit: 'FacilityBiomassNonWoodUsageUnit',
  FacilityBrackishSurfaceWaterOrSeawaterAmount:
    'FacilityBrackishSurfaceWaterOrSeawaterAmount',
  FacilityBrackishSurfaceWaterOrSeawaterFileUpload:
    'FacilityBrackishSurfaceWaterOrSeawaterFileUpload',
  FacilityBrackishSurfaceWaterOrSeawaterUnit:
    'FacilityBrackishSurfaceWaterOrSeawaterUnit',
  FacilityCleanPowerDescribeCleanPowerSources:
    'FacilityCleanPowerDescribeCleanPowerSources',
  FacilityCleanPowerDoesUseCleanPower: 'FacilityCleanPowerDoesUseCleanPower',
  FacilityCleanPowerRelevantReports: 'FacilityCleanPowerRelevantReports',
  FacilityCleanPowerTotalAmountInMWhCoveredByCleanPower:
    'FacilityCleanPowerTotalAmountInMWhCoveredByCleanPower',
  FacilityCoalUsageAmount: 'FacilityCoalUsageAmount',
  FacilityCoalUsageFileUpload: 'FacilityCoalUsageFileUpload',
  FacilityCoalUsageUnit: 'FacilityCoalUsageUnit',
  FacilityDetailedAllocationDoesSubMeterOrTrackProperties:
    'FacilityDetailedAllocationDoesSubMeterOrTrackProperties',
  FacilityDetailedAllocationDoesTrackMetricForCompanyProducts:
    'FacilityDetailedAllocationDoesTrackMetricForCompanyProducts',
  FacilityDetailedAllocationMetricUnit: 'FacilityDetailedAllocationMetricUnit',
  FacilityDetailedAllocationRelevantReport:
    'FacilityDetailedAllocationRelevantReport',
  FacilityDetailedAllocationTotalAmountOfMetricForCompany:
    'FacilityDetailedAllocationTotalAmountOfMetricForCompany',
  FacilityDetailedAllocationTotalAmountOfMetricForEntireFacility:
    'FacilityDetailedAllocationTotalAmountOfMetricForEntireFacility',
  FacilityElectrictyFromGridUsageAmount:
    'FacilityElectrictyFromGridUsageAmount',
  FacilityElectrictyFromGridUsageFileUpload:
    'FacilityElectrictyFromGridUsageFileUpload',
  FacilityElectrictyFromGridUsageUnit: 'FacilityElectrictyFromGridUsageUnit',
  FacilityEnergySources: 'FacilityEnergySources',
  FacilityGroundwaterAmount: 'FacilityGroundwaterAmount',
  FacilityGroundwaterFileUpload: 'FacilityGroundwaterFileUpload',
  FacilityGroundwaterUnit: 'FacilityGroundwaterUnit',
  FacilityInfoAddress: 'FacilityInfoAddress',
  FacilityInfoCountryOfOperation: 'FacilityInfoCountryOfOperation',
  FacilityInfoHeadcount: 'FacilityInfoHeadcount',
  FacilityInfoIndustry: 'FacilityInfoIndustry',
  FacilityInfoListAllProcesses: 'FacilityInfoListAllProcesses',
  FacilityInfoName: 'FacilityInfoName',
  FacilityMunicipalWaterAmount: 'FacilityMunicipalWaterAmount',
  FacilityMunicipalWaterFileUpload: 'FacilityMunicipalWaterFileUpload',
  FacilityMunicipalWaterUnit: 'FacilityMunicipalWaterUnit',
  FacilityNaturalGasUsageAmount: 'FacilityNaturalGasUsageAmount',
  FacilityNaturalGasUsageFileUpload: 'FacilityNaturalGasUsageFileUpload',
  FacilityNaturalGasUsageUnit: 'FacilityNaturalGasUsageUnit',
  FacilityOtherAmount: 'FacilityOtherAmount',
  FacilityOtherFileUpload: 'FacilityOtherFileUpload',
  FacilityOtherUnit: 'FacilityOtherUnit',
  FacilityPetrolUsageAmount: 'FacilityPetrolUsageAmount',
  FacilityPetrolUsageFileUpload: 'FacilityPetrolUsageFileUpload',
  FacilityPetrolUsageUnit: 'FacilityPetrolUsageUnit',
  FacilityProductionListOfTypesOfProducts:
    'FacilityProductionListOfTypesOfProducts',
  FacilityProductionTotalNumberOfUnits: 'FacilityProductionTotalNumberOfUnits',
  FacilityPropaneUsageAmount: 'FacilityPropaneUsageAmount',
  FacilityPropaneUsageFileUpload: 'FacilityPropaneUsageFileUpload',
  FacilityPropaneUsageUnit: 'FacilityPropaneUsageUnit',
  FacilityRainwaterAmount: 'FacilityRainwaterAmount',
  FacilityRainwaterFileUpload: 'FacilityRainwaterFileUpload',
  FacilityRainwaterUnit: 'FacilityRainwaterUnit',
  FacilitySolarUsageAmount: 'FacilitySolarUsageAmount',
  FacilitySolarUsageFileUpload: 'FacilitySolarUsageFileUpload',
  FacilitySolarUsageUnit: 'FacilitySolarUsageUnit',
  FacilityWastewaterAmount: 'FacilityWastewaterAmount',
  FacilityWastewaterFileUpload: 'FacilityWastewaterFileUpload',
  FacilityWastewaterUnit: 'FacilityWastewaterUnit',
  FacilityWaterSources: 'FacilityWaterSources',
  FacilityWoodUsageAmount: 'FacilityWoodUsageAmount',
  FacilityWoodUsageFileUpload: 'FacilityWoodUsageFileUpload',
  FacilityWoodUsageUnit: 'FacilityWoodUsageUnit',
  FootprintMethodologyImportReport: 'FootprintMethodologyImportReport',
  FootprintProviderName: 'FootprintProviderName',
  GhgInventoryInLastYear: 'GhgInventoryInLastYear',
  GhgInventoryInLastYearMethodology: 'GhgInventoryInLastYearMethodology',
  GhgScope1CustomerShareOfEmissionsTonnesCo2e:
    'GhgScope1CustomerShareOfEmissionsTonnesCo2e',
  GhgScope1FugitiveEmissionsRevenueIntensity:
    'GhgScope1FugitiveEmissionsRevenueIntensity',
  GhgScope1FugitiveEmissionsTonnesCo2e: 'GhgScope1FugitiveEmissionsTonnesCo2e',
  GhgScope1Methodology: 'GhgScope1Methodology',
  GhgScope1MobileCombustionRevenueIntensity:
    'GhgScope1MobileCombustionRevenueIntensity',
  GhgScope1MobileCombustionTonnesCo2e: 'GhgScope1MobileCombustionTonnesCo2e',
  GhgScope1RevenueIntensity: 'GhgScope1RevenueIntensity',
  GhgScope1StationaryCombustionRevenueIntensity:
    'GhgScope1StationaryCombustionRevenueIntensity',
  GhgScope1StationaryCombustionTonnesCo2e:
    'GhgScope1StationaryCombustionTonnesCo2e',
  GhgScope1TonnesCo2e: 'GhgScope1TonnesCo2e',
  GhgScope2CustomerShareOfEmissionsTonnesCo2e:
    'GhgScope2CustomerShareOfEmissionsTonnesCo2e',
  GhgScope2DisclosureIncludesLeasedBuildings:
    'GhgScope2DisclosureIncludesLeasedBuildings',
  GhgScope2LocationBasedRevenueIntensity:
    'GhgScope2LocationBasedRevenueIntensity',
  GhgScope2LocationBasedTonnesCo2e: 'GhgScope2LocationBasedTonnesCo2e',
  GhgScope2MarketBasedRevenueIntensity: 'GhgScope2MarketBasedRevenueIntensity',
  GhgScope2MarketBasedTonnesCo2e: 'GhgScope2MarketBasedTonnesCo2e',
  GhgScope3ByCategoryRevenueIntensity: 'GhgScope3ByCategoryRevenueIntensity',
  GhgScope3ByCategoryTonnesCo2e: 'GhgScope3ByCategoryTonnesCo2e',
  GhgScope3CustomerShareOfEmissionsTonnesCo2e:
    'GhgScope3CustomerShareOfEmissionsTonnesCo2e',
  GhgScope3Methodology: 'GhgScope3Methodology',
  GhgScope3TotalRevenueIntensity: 'GhgScope3TotalRevenueIntensity',
  GhgScope3TotalTonnesCo2e: 'GhgScope3TotalTonnesCo2e',
  HasEnvironmentalTeam: 'HasEnvironmentalTeam',
  HasMeasuredScope3: 'HasMeasuredScope3',
  HasSbtTargets: 'HasSbtTargets',
  HasSubmittedSbtiTargets: 'HasSubmittedSbtiTargets',
  HasTargets: 'HasTargets',
  HaveCommittedSbtiTargets: 'HaveCommittedSbtiTargets',
  HaveMeasuredEmissions: 'HaveMeasuredEmissions',
  HaveMeasuredEmissionsV2: 'HaveMeasuredEmissionsV2',
  Headcount: 'Headcount',
  HealthBenefits: 'HealthBenefits',
  HelpNeeded: 'HelpNeeded',
  HelpNeededMeasuringEmissions: 'HelpNeededMeasuringEmissions',
  HelpNeededReductionTargets: 'HelpNeededReductionTargets',
  HelpNeededSbtTargets: 'HelpNeededSbtTargets',
  HelpNeededSettingTargets: 'HelpNeededSettingTargets',
  HelpNeededSustainabilityProgram: 'HelpNeededSustainabilityProgram',
  HelpNeededToSetSbtTargets: 'HelpNeededToSetSbtTargets',
  HelpNeededToSetTargets: 'HelpNeededToSetTargets',
  ImportReport: 'ImportReport',
  LearningBusinessCaseCorporateClimateActionBenefits:
    'LearningBusinessCaseCorporateClimateActionBenefits',
  LearningBusinessCaseEmployees: 'LearningBusinessCaseEmployees',
  LearningBusinessCaseMarketTrends: 'LearningBusinessCaseMarketTrends',
  LearningBusinessCaseOperationalCosts: 'LearningBusinessCaseOperationalCosts',
  LearningBusinessCaseRisk: 'LearningBusinessCaseRisk',
  LearningCorporateClimateActionReduceYourEmissions:
    'LearningCorporateClimateActionReduceYourEmissions',
  LearningCorporateClimateActionStep2: 'LearningCorporateClimateActionStep2',
  LearningCorporateClimateActionStep3: 'LearningCorporateClimateActionStep3',
  LearningEngageYourTeamEngagingEmployeesTactics:
    'LearningEngageYourTeamEngagingEmployeesTactics',
  LearningEngageYourTeamEngagingLeadershipTactics:
    'LearningEngageYourTeamEngagingLeadershipTactics',
  LearningEngageYourTeamIdentifyingStakeholders:
    'LearningEngageYourTeamIdentifyingStakeholders',
  LearningFinanceImpAuditSlider: 'LearningFinanceImpAuditSlider',
  LearningFinanceImpAuditingVideoFinished:
    'LearningFinanceImpAuditingVideoFinished',
  LearningFinanceImpDataManagementSlider:
    'LearningFinanceImpDataManagementSlider',
  LearningFinanceImpDataManagementVideoFinished:
    'LearningFinanceImpDataManagementVideoFinished',
  LearningFinanceImpDeepDiveVideoFinished:
    'LearningFinanceImpDeepDiveVideoFinished',
  LearningFinanceImpEmissionsQuantificationVideoFinished:
    'LearningFinanceImpEmissionsQuantificationVideoFinished',
  LearningFinanceImpManagementToolsVideoFinished:
    'LearningFinanceImpManagementToolsVideoFinished',
  LearningFinanceImpRecap: 'LearningFinanceImpRecap',
  LearningFinanceImpValue: 'LearningFinanceImpValue',
  LearningLaunchingAClimateProgramAmbitionLevelSpectrum:
    'LearningLaunchingAClimateProgramAmbitionLevelSpectrum',
  LearningLaunchingAClimateProgramLongTerm:
    'LearningLaunchingAClimateProgramLongTerm',
  LearningLaunchingAClimateProgramSupport:
    'LearningLaunchingAClimateProgramSupport',
  LearningLaunchingAClimateProgramVoluntaryReporting:
    'LearningLaunchingAClimateProgramVoluntaryReporting',
  LearningMeasuringEmissionsBusinessActivities:
    'LearningMeasuringEmissionsBusinessActivities',
  LearningMeasuringEmissionsCarbonAccounting:
    'LearningMeasuringEmissionsCarbonAccounting',
  LearningMeasuringEmissionsEmissionsFactors:
    'LearningMeasuringEmissionsEmissionsFactors',
  LearningMeasuringEmissionsScopeOne: 'LearningMeasuringEmissionsScopeOne',
  LearningMeasuringEmissionsScopeThree: 'LearningMeasuringEmissionsScopeThree',
  LearningMeasuringEmissionsScopeTwo: 'LearningMeasuringEmissionsScopeTwo',
  LearningMeasuringEmissionsSpectrum: 'LearningMeasuringEmissionsSpectrum',
  LearningScienceBasedTargetsClimateScienceBasics:
    'LearningScienceBasedTargetsClimateScienceBasics',
  LearningScienceBasedTargetsDefinition3:
    'LearningScienceBasedTargetsDefinition3',
  LearningScienceBasedTargetsDevelopTargetKeyQuestions:
    'LearningScienceBasedTargetsDevelopTargetKeyQuestions',
  LearningScienceBasedTargetsNumberOfCompaniesCommitted:
    'LearningScienceBasedTargetsNumberOfCompaniesCommitted',
  LearningVideosFinished: 'LearningVideosFinished',
  LivingWage: 'LivingWage',
  MeasurementProjectsToShare: 'MeasurementProjectsToShare',
  ModifiedProposeTarget: 'ModifiedProposeTarget',
  NetChangeEmployeesNonOrganic: 'NetChangeEmployeesNonOrganic',
  NoGhgInventoryHelpNeeded: 'NoGhgInventoryHelpNeeded',
  NoSustainabilityProgramHelpNeeded: 'NoSustainabilityProgramHelpNeeded',
  Operations: 'Operations',
  OtherTargets: 'OtherTargets',
  Paperless: 'Paperless',
  ParentalLeave: 'ParentalLeave',
  PlanOfRecordCommitToMeasureGhg: 'PlanOfRecordCommitToMeasureGhg',
  PlanOfRecordCommitToMeasureGhgNoExplain:
    'PlanOfRecordCommitToMeasureGhgNoExplain',
  PlanOfRecordCommitToWorkingTowardsSbt:
    'PlanOfRecordCommitToWorkingTowardsSbt',
  PlanOfRecordCommitToWorkingTowardsSbtNoExplain:
    'PlanOfRecordCommitToWorkingTowardsSbtNoExplain',
  PlanOfRecordSubmittedSbtLetter: 'PlanOfRecordSubmittedSbtLetter',
  PlanOfRecordSubmittedSbtLetterExpectedDate:
    'PlanOfRecordSubmittedSbtLetterExpectedDate',
  PlanOfRecordSubmittedSbtLetterFileUpload:
    'PlanOfRecordSubmittedSbtLetterFileUpload',
  PlanOfRecordSubmittedSbtLetterNoExplain:
    'PlanOfRecordSubmittedSbtLetterNoExplain',
  PleeEstimationOutputs: 'PleeEstimationOutputs',
  ProcessCheckEmissionsAlreadyAllocated:
    'ProcessCheckEmissionsAlreadyAllocated',
  ProviderVerifiedFootprint: 'ProviderVerifiedFootprint',
  ReducedTravelPercentage: 'ReducedTravelPercentage',
  ReductionInitiatives: 'ReductionInitiatives',
  ReductionInitiativesSet: 'ReductionInitiativesSet',
  ReductionTargets: 'ReductionTargets',
  ReductionTargetsPlanOfAction: 'ReductionTargetsPlanOfAction',
  ReductionTargetsSet: 'ReductionTargetsSet',
  RemainingSitesElectricityMWh: 'RemainingSitesElectricityMWh',
  RenewableEnergyTargets: 'RenewableEnergyTargets',
  Revenue: 'Revenue',
  RevenueCurrency: 'RevenueCurrency',
  SasbIndustry: 'SasbIndustry',
  SasbSector: 'SasbSector',
  SbtDevelopTargetHasDevelopedTarget: 'SbtDevelopTargetHasDevelopedTarget',
  SbtEngagementStatus: 'SbtEngagementStatus',
  SbtGhgMeasurementHasCompletedMeasurement:
    'SbtGhgMeasurementHasCompletedMeasurement',
  SbtSubmitTargetHasSubmittedTarget: 'SbtSubmitTargetHasSubmittedTarget',
  SbtSubmitTargetSubmissionLetterImport:
    'SbtSubmitTargetSubmissionLetterImport',
  SbtiCommitmentLetterImport: 'SbtiCommitmentLetterImport',
  Scope1And2EmissionTargets: 'Scope1And2EmissionTargets',
  Scope3EmissionTargets: 'Scope3EmissionTargets',
  SfdrBiodiversitySensitiveAreas: 'SfdrBiodiversitySensitiveAreas',
  SfdrBoardGenderDiversity: 'SfdrBoardGenderDiversity',
  SfdrEmissionsToWater: 'SfdrEmissionsToWater',
  SfdrEnergyConsumptionHighImpact: 'SfdrEnergyConsumptionHighImpact',
  SfdrExposureToControversialWeapons: 'SfdrExposureToControversialWeapons',
  SfdrFossilFuelSector: 'SfdrFossilFuelSector',
  SfdrGenderPayGap: 'SfdrGenderPayGap',
  SfdrHazardousWaste: 'SfdrHazardousWaste',
  SfdrUnGlobalCompactCompliance: 'SfdrUnGlobalCompactCompliance',
  SfdrUnGlobalCompactViolations: 'SfdrUnGlobalCompactViolations',
  ShareEmissionsPreference: 'ShareEmissionsPreference',
  ShareFootprint: 'ShareFootprint',
  ShareFootprintDisclosureData: 'ShareFootprintDisclosureData',
  ShareMethod: 'ShareMethod',
  ShareMethodV2: 'ShareMethodV2',
  SitesElectricityData: 'SitesElectricityData',
  SmeHeadcount: 'SmeHeadcount',
  SubmittedSbtiCommitment: 'SubmittedSbtiCommitment',
  SustainabilityCommitments: 'SustainabilityCommitments',
  SustainabilityInitiatives: 'SustainabilityInitiatives',
  SustainabilityInitiativesWithDetails: 'SustainabilityInitiativesWithDetails',
  SustainabilityProgram: 'SustainabilityProgram',
  TopMaterialIssues: 'TopMaterialIssues',
  TotalEmployeesPriorYearEnd: 'TotalEmployeesPriorYearEnd',
  TotalEmployeesYearEnd: 'TotalEmployeesYearEnd',
  TotalEnergyConsumption: 'TotalEnergyConsumption',
  VisibilityOfCountryForAnimalFeed: 'VisibilityOfCountryForAnimalFeed',
  WasteManagement: 'WasteManagement',
  WorkplaceFatality: 'WorkplaceFatality',
  WorkplaceInjury: 'WorkplaceInjury',
  YearFullyRenewableForCustomer: 'YearFullyRenewableForCustomer',
} as const;

export type GQCompanySurveyQuestionKey =
  (typeof GQCompanySurveyQuestionKey)[keyof typeof GQCompanySurveyQuestionKey];
export const GQCompanySurveyStatus = {
  Approved: 'Approved',
  AutoApproved: 'AutoApproved',
  ChangesRequested: 'ChangesRequested',
  Closed: 'Closed',
  InProgress: 'InProgress',
  LoggedIn: 'LoggedIn',
  Submitted: 'Submitted',
  SurveyCreated: 'SurveyCreated',
} as const;

export type GQCompanySurveyStatus =
  (typeof GQCompanySurveyStatus)[keyof typeof GQCompanySurveyStatus];
export const GQCompanySurveyStep = {
  Activities: 'Activities',
  AllocateEmissionsMethod: 'AllocateEmissionsMethod',
  CarbonMeasurementPlan: 'CarbonMeasurementPlan',
  CleanPowerInitiativeConfirm: 'CleanPowerInitiativeConfirm',
  CleanPowerInitiativeElectricitySource:
    'CleanPowerInitiativeElectricitySource',
  CleanPowerInitiativeIntro: 'CleanPowerInitiativeIntro',
  Company: 'Company',
  Confirm: 'Confirm',
  CustomQuestions: 'CustomQuestions',
  ElectricitySites: 'ElectricitySites',
  ElectricitySource: 'ElectricitySource',
  EmissionAllocation: 'EmissionAllocation',
  Environmental: 'Environmental',
  EsgdcSocial: 'EsgdcSocial',
  EstimationCompanyInfo: 'EstimationCompanyInfo',
  ExpenseCategoryEmissions: 'ExpenseCategoryEmissions',
  ExpenseCategoryIntro: 'ExpenseCategoryIntro',
  ExpenseCategoryShareMethod: 'ExpenseCategoryShareMethod',
  Expenses: 'Expenses',
  FacilityBiomassNonWoodReporting: 'FacilityBiomassNonWoodReporting',
  FacilityBrackishSurfaceWaterOrSeawaterReporting:
    'FacilityBrackishSurfaceWaterOrSeawaterReporting',
  FacilityCleanPower: 'FacilityCleanPower',
  FacilityCoalReporting: 'FacilityCoalReporting',
  FacilityDetailedAllocation: 'FacilityDetailedAllocation',
  FacilityElectrictyFromGridReporting: 'FacilityElectrictyFromGridReporting',
  FacilityEnergySources: 'FacilityEnergySources',
  FacilityGroundwaterReporting: 'FacilityGroundwaterReporting',
  FacilityInfo: 'FacilityInfo',
  FacilityMunicipalWaterReporting: 'FacilityMunicipalWaterReporting',
  FacilityNaturalGasReporting: 'FacilityNaturalGasReporting',
  FacilityOtherReporting: 'FacilityOtherReporting',
  FacilityPetrolReporting: 'FacilityPetrolReporting',
  FacilityProductionInfo: 'FacilityProductionInfo',
  FacilityPropaneReporting: 'FacilityPropaneReporting',
  FacilityRainwaterReporting: 'FacilityRainwaterReporting',
  FacilitySolarReporting: 'FacilitySolarReporting',
  FacilityWastewaterReporting: 'FacilityWastewaterReporting',
  FacilityWaterSources: 'FacilityWaterSources',
  FacilityWoodReporting: 'FacilityWoodReporting',
  FootprintQuality: 'FootprintQuality',
  GhgMeasurementProgress: 'GhgMeasurementProgress',
  Governance: 'Governance',
  HaveMeasuredMethod: 'HaveMeasuredMethod',
  ImportReport: 'ImportReport',
  Intro: 'Intro',
  LearningContent: 'LearningContent',
  LearningVideo: 'LearningVideo',
  ProposeTarget: 'ProposeTarget',
  ProposeTargetConfirm: 'ProposeTargetConfirm',
  ProposeTargetIntro: 'ProposeTargetIntro',
  ProposeTargetModify: 'ProposeTargetModify',
  ReductionInitiatives: 'ReductionInitiatives',
  ReductionTargets: 'ReductionTargets',
  Revenue: 'Revenue',
  SbtCommitmentCommitted: 'SbtCommitmentCommitted',
  SbtCommitmentConfirm: 'SbtCommitmentConfirm',
  SbtCommitmentIntro: 'SbtCommitmentIntro',
  SbtCommitmentUploadLetter: 'SbtCommitmentUploadLetter',
  SbtDevelopTargetConfirm: 'SbtDevelopTargetConfirm',
  SbtDevelopTargetIntro: 'SbtDevelopTargetIntro',
  SbtDevelopTargetProgress: 'SbtDevelopTargetProgress',
  SbtGhgMeasurementConfirm: 'SbtGhgMeasurementConfirm',
  SbtGhgMeasurementIntro: 'SbtGhgMeasurementIntro',
  SbtGhgMeasurementProgress: 'SbtGhgMeasurementProgress',
  SbtGhgMeasurementShareMethod: 'SbtGhgMeasurementShareMethod',
  SbtProgress: 'SbtProgress',
  SbtProgressConfirm: 'SbtProgressConfirm',
  SbtProgressIntro: 'SbtProgressIntro',
  SbtProgressSbtEngagementStatus: 'SbtProgressSbtEngagementStatus',
  SbtSubmitTargetConfirm: 'SbtSubmitTargetConfirm',
  SbtSubmitTargetIntro: 'SbtSubmitTargetIntro',
  SbtSubmitTargetProgress: 'SbtSubmitTargetProgress',
  Scope1And2Emission: 'Scope1And2Emission',
  Scope3Emission: 'Scope3Emission',
  SfdrClimateAndEnvironmentIndicators: 'SfdrClimateAndEnvironmentIndicators',
  SfdrSocialIndicators: 'SfdrSocialIndicators',
  ShareMethod: 'ShareMethod',
  SmeStatus: 'SmeStatus',
  Social: 'Social',
  SustainabilityMaturity: 'SustainabilityMaturity',
  SustainabilityMaturityTargetFollowup: 'SustainabilityMaturityTargetFollowup',
} as const;

export type GQCompanySurveyStep =
  (typeof GQCompanySurveyStep)[keyof typeof GQCompanySurveyStep];
export type GQCompanyTickerSymbol = GQIdInterface & {
  __typename?: 'CompanyTickerSymbol';
  editor: GQUser;
  exchangeSymbol: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSourcePrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
  tickerSymbol: Scalars['String']['output'];
};

export type GQCompanyTickerSymbolInput = {
  exchangeSymbol?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isSourcePrimary: Scalars['Boolean']['input'];
  sourceId: Scalars['ID']['input'];
  tickerSymbol: Scalars['String']['input'];
};

export type GQCompanyUrl = GQIdInterface & {
  __typename?: 'CompanyUrl';
  createdAt: Scalars['DateTime']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type GQCompanyUrlInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  sourceId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type GQCompanyVat = GQIdInterface & {
  __typename?: 'CompanyVat';
  countryAlpha2: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
  vatId: Scalars['String']['output'];
};

export type GQCompanyVatInput = {
  countryAlpha2: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  sourceId: Scalars['ID']['input'];
  vatId: Scalars['String']['input'];
};

export type GQCompleteMeasurementProjectInput = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQCompleteMeasurementProjectPayload = {
  __typename?: 'CompleteMeasurementProjectPayload';
  measurementProject: GQMeasurementProject;
};

export const GQCompletedLifecycleStage = {
  Kickoff: 'Kickoff',
  Measurement: 'Measurement',
  Reductions: 'Reductions',
} as const;

export type GQCompletedLifecycleStage =
  (typeof GQCompletedLifecycleStage)[keyof typeof GQCompletedLifecycleStage];
export type GQCompositeDataOrgDetail = {
  __typename?: 'CompositeDataOrgDetail';
  latestPublishedRevision: Maybe<GQReferenceDataRevision>;
  organization: Maybe<GQOrganization>;
};

export type GQComputeReportAnswerWarningsForOrgInput = {
  componentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['ID']['input'];
};

export type GQComputeReportAnswerWarningsForOrgPayload = {
  __typename?: 'ComputeReportAnswerWarningsForOrgPayload';
  numVerified: Scalars['Int']['output'];
};

export type GQComputeReportQuestionMetricInput = {
  dataFilters?: InputMaybe<GQCompanyDataFiltersForMetric>;
  metricId: Scalars['ID']['input'];
  metricInputs: Scalars['JSONString']['input'];
  questionIdentifier: Scalars['String']['input'];
};

export type GQComputedFilterExpressionPrimitive = {
  __typename?: 'ComputedFilterExpressionPrimitive';
  field: GQComputedFilterField;
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

/** Computed fields are derived from attributes of the footprint. */
export const GQComputedFilterField = {
  ElectricityType: 'electricityType',
} as const;

export type GQComputedFilterField =
  (typeof GQComputedFilterField)[keyof typeof GQComputedFilterField];
export type GQConfigLinterError = {
  __typename?: 'ConfigLinterError';
  message: Scalars['String']['output'];
  reportObjectId: Scalars['ID']['output'];
  type: GQConfigLinterErrorType;
};

export const GQConfigLinterErrorType = {
  Error: 'Error',
  Warning: 'Warning',
} as const;

export type GQConfigLinterErrorType =
  (typeof GQConfigLinterErrorType)[keyof typeof GQConfigLinterErrorType];
export const GQConfigLinterStatus = {
  Invalid: 'Invalid',
  Valid: 'Valid',
  ValidWithWarnings: 'ValidWithWarnings',
} as const;

export type GQConfigLinterStatus =
  (typeof GQConfigLinterStatus)[keyof typeof GQConfigLinterStatus];
export type GQConfigureAutoBartInput = {
  activityDataTableId?: InputMaybe<Scalars['String']['input']>;
  datasourceId: Scalars['String']['input'];
  measurementProjectId: Scalars['String']['input'];
};

export type GQConfigureAutoBartPayload = {
  __typename?: 'ConfigureAutoBartPayload';
  assignedAdt: Maybe<GQActivityDataTable>;
  unassignedAdt: Maybe<GQActivityDataTable>;
};

export type GQContextRelationInterface = {
  adminUrl: Scalars['String']['output'];
  /** @deprecated Context items are being deprecated */
  ancestorRelations: Array<
    | GQActivityDataTable
    | GQDataIssue
    | GQDataset
    | GQDatasource
    | GQFileMetadata
    | GQFinancialsReviewItem
    | GQMeasurementProject
  >;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export const GQContractingStatus = {
  InDiscussion: 'InDiscussion',
  MovingToMeasurement: 'MovingToMeasurement',
  NotMovingForward: 'NotMovingForward',
  Referred: 'Referred',
} as const;

export type GQContractingStatus =
  (typeof GQContractingStatus)[keyof typeof GQContractingStatus];
export type GQConvertCtsBackInput = {
  ctsId: Scalars['ID']['input'];
};

export type GQConvertCtsBackPayload = {
  __typename?: 'ConvertCtsBackPayload';
  cts: Maybe<GQCustomerTargetSchema>;
};

export type GQConvertCtsToTSchemaInput = {
  ctsId: Scalars['ID']['input'];
};

export type GQConvertCtsToTSchemaPayload = {
  __typename?: 'ConvertCtsToTSchemaPayload';
  cts: Maybe<GQCustomerTargetSchema>;
};

export type GQCopyDataToNewTasksByBuildingDestinationInput = {
  buildingIds?: InputMaybe<Array<Scalars['String']['input']>>;
  destinationTaskId: Scalars['String']['input'];
};

export type GQCopyDataToNewTasksByBuildingInput = {
  destinations: Array<GQCopyDataToNewTasksByBuildingDestinationInput>;
  dryRun: Scalars['Boolean']['input'];
  orgId: Scalars['String']['input'];
  skipTaskAssociationCleanup: Scalars['Boolean']['input'];
  sourceTaskId: Scalars['String']['input'];
};

export type GQCopyDataToNewTasksByBuildingPayload = {
  __typename?: 'CopyDataToNewTasksByBuildingPayload';
  result: Array<GQCopyDataToNewTasksByBuildingTaskResult>;
};

export type GQCopyDataToNewTasksByBuildingTaskResult = {
  __typename?: 'CopyDataToNewTasksByBuildingTaskResult';
  destinationTaskId: Scalars['String']['output'];
  removedTaskAssociations: Array<GQFacilitiesUploadTaskAssociation>;
  result: Array<GQCopyDataToNewTasksByBuildingUploadResult>;
};

export type GQCopyDataToNewTasksByBuildingUploadResult = {
  __typename?: 'CopyDataToNewTasksByBuildingUploadResult';
  buildingIds: Array<Scalars['String']['output']>;
  ctsId: Scalars['String']['output'];
  periodEnd: Maybe<Scalars['Date']['output']>;
  periodStart: Maybe<Scalars['Date']['output']>;
  rowCount: Scalars['Int']['output'];
  uploadId: Scalars['String']['output'];
};

export type GQCopyEngagementTaskConfigToOrgForAdminInput = {
  orgId: Scalars['ID']['input'];
  taskConfigId: Scalars['ID']['input'];
};

export type GQCopyReportQuestionAnswersInput = {
  destinationQuestionId: Scalars['ID']['input'];
  isDryRun: Scalars['Boolean']['input'];
  reportAnswerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceQuestionId: Scalars['ID']['input'];
};

export type GQCopyReportQuestionAnswersPayload = {
  __typename?: 'CopyReportQuestionAnswersPayload';
  numCopied: Scalars['Int']['output'];
};

export const GQCountryAlpha2 = {
  Ad: 'AD',
  Ae: 'AE',
  Af: 'AF',
  Ag: 'AG',
  Ai: 'AI',
  Al: 'AL',
  Am: 'AM',
  Ao: 'AO',
  Aq: 'AQ',
  Ar: 'AR',
  As: 'AS',
  At: 'AT',
  Au: 'AU',
  Aw: 'AW',
  Ax: 'AX',
  Az: 'AZ',
  Ba: 'BA',
  Bb: 'BB',
  Bd: 'BD',
  Be: 'BE',
  Bf: 'BF',
  Bg: 'BG',
  Bh: 'BH',
  Bi: 'BI',
  Bj: 'BJ',
  Bl: 'BL',
  Bm: 'BM',
  Bn: 'BN',
  Bo: 'BO',
  Bq: 'BQ',
  Br: 'BR',
  Bs: 'BS',
  Bt: 'BT',
  Bv: 'BV',
  Bw: 'BW',
  By: 'BY',
  Bz: 'BZ',
  Ca: 'CA',
  Cc: 'CC',
  Cd: 'CD',
  Cf: 'CF',
  Cg: 'CG',
  Ch: 'CH',
  Ci: 'CI',
  Ck: 'CK',
  Cl: 'CL',
  Cm: 'CM',
  Cn: 'CN',
  Co: 'CO',
  Cr: 'CR',
  Cu: 'CU',
  Cv: 'CV',
  Cw: 'CW',
  Cx: 'CX',
  Cy: 'CY',
  Cz: 'CZ',
  De: 'DE',
  Dj: 'DJ',
  Dk: 'DK',
  Dm: 'DM',
  Do: 'DO',
  Dz: 'DZ',
  Ec: 'EC',
  Ee: 'EE',
  Eg: 'EG',
  Eh: 'EH',
  Er: 'ER',
  Es: 'ES',
  Et: 'ET',
  Fi: 'FI',
  Fj: 'FJ',
  Fk: 'FK',
  Fm: 'FM',
  Fo: 'FO',
  Fr: 'FR',
  Ga: 'GA',
  Gb: 'GB',
  Gd: 'GD',
  Ge: 'GE',
  Gf: 'GF',
  Gg: 'GG',
  Gh: 'GH',
  Gi: 'GI',
  Gl: 'GL',
  Gm: 'GM',
  Gn: 'GN',
  Gp: 'GP',
  Gq: 'GQ',
  Gr: 'GR',
  Gs: 'GS',
  Gt: 'GT',
  Gu: 'GU',
  Gw: 'GW',
  Gy: 'GY',
  Hk: 'HK',
  Hm: 'HM',
  Hn: 'HN',
  Hr: 'HR',
  Ht: 'HT',
  Hu: 'HU',
  Id: 'ID',
  Ie: 'IE',
  Il: 'IL',
  Im: 'IM',
  In: 'IN',
  Io: 'IO',
  Iq: 'IQ',
  Ir: 'IR',
  Is: 'IS',
  It: 'IT',
  Je: 'JE',
  Jm: 'JM',
  Jo: 'JO',
  Jp: 'JP',
  Ke: 'KE',
  Kg: 'KG',
  Kh: 'KH',
  Ki: 'KI',
  Km: 'KM',
  Kn: 'KN',
  Kp: 'KP',
  Kr: 'KR',
  Kw: 'KW',
  Ky: 'KY',
  Kz: 'KZ',
  La: 'LA',
  Lb: 'LB',
  Lc: 'LC',
  Li: 'LI',
  Lk: 'LK',
  Lr: 'LR',
  Ls: 'LS',
  Lt: 'LT',
  Lu: 'LU',
  Lv: 'LV',
  Ly: 'LY',
  Ma: 'MA',
  Mc: 'MC',
  Md: 'MD',
  Me: 'ME',
  Mf: 'MF',
  Mg: 'MG',
  Mh: 'MH',
  Mk: 'MK',
  Ml: 'ML',
  Mm: 'MM',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Mq: 'MQ',
  Mr: 'MR',
  Ms: 'MS',
  Mt: 'MT',
  Mu: 'MU',
  Mv: 'MV',
  Mw: 'MW',
  Mx: 'MX',
  My: 'MY',
  Mz: 'MZ',
  Na: 'NA',
  Nc: 'NC',
  Ne: 'NE',
  Nf: 'NF',
  Ng: 'NG',
  Ni: 'NI',
  Nl: 'NL',
  No: 'NO',
  Np: 'NP',
  Nr: 'NR',
  Nu: 'NU',
  Nz: 'NZ',
  Om: 'OM',
  Pa: 'PA',
  Pe: 'PE',
  Pf: 'PF',
  Pg: 'PG',
  Ph: 'PH',
  Pk: 'PK',
  Pl: 'PL',
  Pm: 'PM',
  Pn: 'PN',
  Pr: 'PR',
  Ps: 'PS',
  Pt: 'PT',
  Pw: 'PW',
  Py: 'PY',
  Qa: 'QA',
  Re: 'RE',
  Ro: 'RO',
  Rs: 'RS',
  Ru: 'RU',
  Rw: 'RW',
  Sa: 'SA',
  Sb: 'SB',
  Sc: 'SC',
  Sd: 'SD',
  Se: 'SE',
  Sg: 'SG',
  Sh: 'SH',
  Si: 'SI',
  Sj: 'SJ',
  Sk: 'SK',
  Sl: 'SL',
  Sm: 'SM',
  Sn: 'SN',
  So: 'SO',
  Sr: 'SR',
  Ss: 'SS',
  St: 'ST',
  Sv: 'SV',
  Sx: 'SX',
  Sy: 'SY',
  Sz: 'SZ',
  Tc: 'TC',
  Td: 'TD',
  Tf: 'TF',
  Tg: 'TG',
  Th: 'TH',
  Tj: 'TJ',
  Tk: 'TK',
  Tl: 'TL',
  Tm: 'TM',
  Tn: 'TN',
  To: 'TO',
  Tr: 'TR',
  Tt: 'TT',
  Tv: 'TV',
  Tw: 'TW',
  Tz: 'TZ',
  Ua: 'UA',
  Ug: 'UG',
  Um: 'UM',
  Us: 'US',
  Uy: 'UY',
  Uz: 'UZ',
  Va: 'VA',
  Vc: 'VC',
  Ve: 'VE',
  Vg: 'VG',
  Vi: 'VI',
  Vn: 'VN',
  Vu: 'VU',
  Wf: 'WF',
  Ws: 'WS',
  Ye: 'YE',
  Yt: 'YT',
  Za: 'ZA',
  Zm: 'ZM',
  Zw: 'ZW',
} as const;

export type GQCountryAlpha2 =
  (typeof GQCountryAlpha2)[keyof typeof GQCountryAlpha2];
export type GQCreateActivityDataTableInput = {
  /** @deprecated Field no longer supported */
  datasourceId?: InputMaybe<Scalars['ID']['input']>;
  datasourceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  filterEndYearMonth: Scalars['YearMonth']['input'];
  filterStartYearMonth: Scalars['YearMonth']['input'];
  measurementProjectId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  primaryDatasourceId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateActivityDataTablePayload = {
  __typename?: 'CreateActivityDataTablePayload';
  activityDataTables: Maybe<GQActivityDataTableConnection>;
  table: GQActivityDataTable;
};

export type GQCreateActivityDataTablePayloadActivityDataTablesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQCreateAdminUploadInput = {
  category: GQFileCategory;
  directory: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  revisionRoot?: InputMaybe<Scalars['ID']['input']>;
  size: Scalars['Int53']['input'];
};

export type GQCreateAdminUploadPayload = {
  __typename?: 'CreateAdminUploadPayload';
  fileId: Scalars['ID']['output'];
  remotePath: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GQCreateApiKeyInput = {
  orgId: Scalars['ID']['input'];
  roleId: Scalars['ID']['input'];
  testMode: Scalars['Boolean']['input'];
};

export type GQCreateApiKeyPayload = {
  __typename?: 'CreateApiKeyPayload';
  key: GQApiKey;
};

export type GQCreateAssetCommentAttachmentInput = {
  contentSize: Scalars['Int']['input'];
  filename: Scalars['String']['input'];
};

export type GQCreateAssetCommentInput = {
  assetCommercialRealEstateId?: InputMaybe<Scalars['ID']['input']>;
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  assetPersonalMotorVehicleInsuranceId?: InputMaybe<Scalars['ID']['input']>;
  assetSovereignBondId?: InputMaybe<Scalars['ID']['input']>;
  attachments: Array<GQCreateAssetCommentAttachmentInput>;
  commentText: Scalars['String']['input'];
};

export type GQCreateAssetCommentPayload = {
  __typename?: 'CreateAssetCommentPayload';
  assetComment: GQAssetComment;
  attachmentMetadata: Array<GQAttachmentMetadata>;
};

export type GQCreateAssetCorporatePayload = {
  __typename?: 'CreateAssetCorporatePayload';
  asset: GQAssetCorporate;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetGroupPayload = {
  __typename?: 'CreateAssetGroupPayload';
  asset: GQAssetGroup;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetHoldingNewInput = {
  action: GQFinanceAuditAction;
  assetClass: GQFundAssetClass;
  assetYearId: Scalars['ID']['input'];
  attributionFactorOverride?: InputMaybe<Scalars['Float']['input']>;
  currencyCode: GQCurrencyCode;
  fundId: Scalars['ID']['input'];
  outstandingAmountNative?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ1?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ2?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ3?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ4?: InputMaybe<Scalars['Float']['input']>;
  premiumNative?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  yearOfInitialInvestment?: InputMaybe<Scalars['Int']['input']>;
};

export type GQCreateAssetHoldingPayload = {
  __typename?: 'CreateAssetHoldingPayload';
  assetHolding: GQFinanceAssetHolding;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetPersonalMotorVehicleInsurancePayload = {
  __typename?: 'CreateAssetPersonalMotorVehicleInsurancePayload';
  asset: GQAssetPersonalMotorVehicleInsurance;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetRealEstatePayload = {
  __typename?: 'CreateAssetRealEstatePayload';
  asset: GQAssetRealEstate;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetSovereignBondPayload = {
  __typename?: 'CreateAssetSovereignBondPayload';
  asset: GQAssetSovereignBond;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetYearNewInput = {
  action: GQFinanceAuditAction;
  assetCommercialRealEstateId?: InputMaybe<Scalars['ID']['input']>;
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  assetPersonalMotorVehicleInsuranceId?: InputMaybe<Scalars['ID']['input']>;
  assetSovereignBondId?: InputMaybe<Scalars['ID']['input']>;
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  cloudSpendNative?: InputMaybe<Scalars['Float']['input']>;
  committedToNearTermSbtAlignedNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  currencyCode: GQCurrencyCode;
  demonstratingYoyEmissionsInLineWithNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  emissionsYearId?: InputMaybe<Scalars['String']['input']>;
  estimationMethodology?: InputMaybe<GQEstimationMethodology>;
  flightMiles?: InputMaybe<Scalars['Int']['input']>;
  headcount?: InputMaybe<Scalars['Int']['input']>;
  iciAlignmentOverride?: InputMaybe<GQIciAlignmentStatus>;
  initiatedDecarbonizationPlan?: InputMaybe<Scalars['Boolean']['input']>;
  measuringMaterialScope3Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  measuringScope1And2Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  percentEmployeesWfh?: InputMaybe<Scalars['Float']['input']>;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  revenueNative?: InputMaybe<Scalars['Float']['input']>;
  revenueSourceEmissionsYearId?: InputMaybe<Scalars['String']['input']>;
  scenario?: InputMaybe<Scalars['String']['input']>;
  scope1OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope3OverrideKind?: InputMaybe<GQScope3OverrideKind>;
  scope3OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope3OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope301OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope302OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope303OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope304OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope305OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope306OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope307OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope308OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope309OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope310OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope311OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope312OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope313OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope314OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope315OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  totalMwh?: InputMaybe<Scalars['Float']['input']>;
  valueNative?: InputMaybe<Scalars['Float']['input']>;
  year: Scalars['Int']['input'];
};

export type GQCreateAssetYearNewPayload = {
  __typename?: 'CreateAssetYearNewPayload';
  assetYear: GQFinanceAssetYearNew;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateCalculationTagInput = {
  name: Scalars['String']['input'];
};

export type GQCreateCanonicalClimateProgramProjectInput = {
  commitmentType?: InputMaybe<Scalars['String']['input']>;
  costEstimate?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  iconTypeOverride?: InputMaybe<GQClimateProgramProjectIconType>;
  kind: GQClimateProgramProjectKind;
  matchObjectType?: InputMaybe<Scalars['String']['input']>;
  measurementObjectCopy?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reportKind?: InputMaybe<Scalars['String']['input']>;
  routeType?: InputMaybe<Scalars['String']['input']>;
  timeEstimate?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCanonicalClimateProgramProjectRequirementDatasetInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  canonicalClimateProgramProjectId: Scalars['ID']['input'];
  canonicalDatasetId: Scalars['ID']['input'];
};

export type GQCreateCanonicalClimateProgramProjectRequirementGhgpInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  canonicalClimateProgramProjectId: Scalars['ID']['input'];
  ghgScope: Scalars['Int']['input'];
  ghgScope3Category?: InputMaybe<Scalars['Int']['input']>;
  minimumFidelity: GQClimateProgramProjectRequirementFidelity;
};

export type GQCreateCanonicalDatasetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  instructions: GQIngestionInstructionsInput;
  kind: GQCanonicalDatasetKind;
  name: Scalars['String']['input'];
};

export type GQCreateCanonicalDatasourceInput = {
  canonicalDatasetId: Scalars['String']['input'];
  instructions: GQIngestionInstructionsInput;
  integrationPartnerId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type GQCreateCdpColumnMappingsInput = {
  mappings: Array<GQCdpColumnMappingInput>;
};

export type GQCreateCdpColumnMappingsPayload = {
  __typename?: 'CreateCdpColumnMappingsPayload';
  mappings: GQCdpIdMappingsPayload;
};

export type GQCreateCdpQuestionMappingsInput = {
  mappings: Array<GQCdpQuestionMappingInput>;
};

export type GQCreateCdpQuestionMappingsPayload = {
  __typename?: 'CreateCdpQuestionMappingsPayload';
  mappings: GQCdpIdMappingsPayload;
};

export type GQCreateCompanyBaseInput = {
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCompanyClimateCommitmentInput = {
  carbonNeutralCommitment?: InputMaybe<GQCarbonNeutralCommitmentInput>;
  cleanEnergyCommitment?: InputMaybe<GQCleanEnergyCommitmentInput>;
  commitmentMadeDate?: InputMaybe<Scalars['Date']['input']>;
  commitmentPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  commitmentPeriodStart?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<GQCompanyClimateCommitmentKind>;
  netZeroCommitment?: InputMaybe<GQNetZeroCommitmentInput>;
  privateDisclosureId?: InputMaybe<Scalars['String']['input']>;
  publicDisclosureId?: InputMaybe<Scalars['String']['input']>;
  publicUrl?: InputMaybe<Scalars['String']['input']>;
  sbtCommitment?: InputMaybe<GQSbtCommitmentInput>;
};

export type GQCreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload';
  company: GQCompany;
};

export type GQCreateCompanyRelationshipInput = {
  companyAId: Scalars['ID']['input'];
  companyBId: Scalars['ID']['input'];
  relationshipEndDate?: InputMaybe<Scalars['YearMonth']['input']>;
  relationshipStartDate?: InputMaybe<Scalars['YearMonth']['input']>;
  relationshipType: GQCompanyRelationshipType;
};

export type GQCreateCompanyRelationshipPayload = {
  __typename?: 'CreateCompanyRelationshipPayload';
  companyRelationship: GQCompanyRelationship;
};

export type GQCreateCompanySourceKeyInput = {
  priority: Scalars['Int']['input'];
  sourceKey: Scalars['String']['input'];
  sourceNotes?: InputMaybe<Scalars['String']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  userVisibleSourceNotes?: InputMaybe<Scalars['String']['input']>;
  userVisibleSourceUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCompositeDataSourceInput = {
  externalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  versionVintage: Scalars['DateTime']['input'];
};

export type GQCreateCompositeDataSourcePayload = {
  __typename?: 'CreateCompositeDataSourcePayload';
  referenceDataSource: GQReferenceDataSource;
};

export type GQCreateCorporateSandboxEstimationInput = {
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  cloudSpendNative?: InputMaybe<Scalars['Float']['input']>;
  countryAlpha2: GQCountryAlpha2;
  currencyCode: GQCurrencyCode;
  estimationMethodology: GQEstimationMethodology;
  flightMiles?: InputMaybe<Scalars['Int']['input']>;
  headcount?: InputMaybe<Scalars['Int']['input']>;
  naicsCode: Scalars['String']['input'];
  percentEmployeesWfh?: InputMaybe<Scalars['Float']['input']>;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  revenueNative: Scalars['Float']['input'];
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope3OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  shouldIncludeDownstreamScope3?: InputMaybe<Scalars['Boolean']['input']>;
  totalMwh?: InputMaybe<Scalars['Float']['input']>;
};

export type GQCreateCorporateSandboxEstimationPayload = {
  __typename?: 'CreateCorporateSandboxEstimationPayload';
  backgroundJobId: Scalars['String']['output'];
};

export type GQCreateCtsInput = {
  canonicalDatasetIds: Array<Scalars['ID']['input']>;
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  rank: Scalars['Int']['input'];
};

export type GQCreateCtsPayload = {
  __typename?: 'CreateCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQCreateCtsTransformTestCaseInput = {
  ctsTransformId: Scalars['ID']['input'];
  fileMetadataId?: InputMaybe<Scalars['ID']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCtsTransformTestCasePayload = {
  __typename?: 'CreateCtsTransformTestCasePayload';
  ctsTransformTestCase: GQCustomerTargetSchemaTransformTestCase;
};

export type GQCreateCtsVersionInput = {
  businessActivityTypeId?: InputMaybe<Scalars['String']['input']>;
  businessActivityTypeName?: InputMaybe<Scalars['String']['input']>;
  customerTargetSchemaId: Scalars['ID']['input'];
  exampleData?: InputMaybe<Scalars['JSONString']['input']>;
  formattingNotesMd?: InputMaybe<Scalars['String']['input']>;
  oneSchemaJson?: InputMaybe<Scalars['JSONString']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  schemaJson?: InputMaybe<Scalars['JSONString']['input']>;
  semverVersion: Scalars['String']['input'];
  sql: Scalars['String']['input'];
  transformTarget: GQCustomerTargetSchemaTransformTarget;
  versionNotesMd: Scalars['String']['input'];
};

export type GQCreateCtsVersionPayload = {
  __typename?: 'CreateCtsVersionPayload';
  customerTargetSchema: Maybe<GQCustomerTargetSchema>;
};

export type GQCreateCustomIntensityConfigInput = {
  description: Scalars['String']['input'];
  humanReadable: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isSbtValidated?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  quantityType: GQCustomIntensityConfigQuantityType;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateDataIssueCommentInput = {
  issueId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export type GQCreateDataIssueCommentPayload = {
  __typename?: 'CreateDataIssueCommentPayload';
  comment: GQComment;
  issue: GQDataIssue;
};

export type GQCreateDataIssueInput = {
  description: Scalars['String']['input'];
  fileIds: Array<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  shouldCcClimateLeads?: InputMaybe<Scalars['Boolean']['input']>;
  shouldPublish?: InputMaybe<Scalars['Boolean']['input']>;
  state: GQDataIssueState;
  tags?: InputMaybe<Array<GQDataIssueTag>>;
  title: Scalars['String']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQCreateDataIssuePayload = {
  __typename?: 'CreateDataIssuePayload';
  issue: GQDataIssue;
  measurementProject: GQMeasurementProject;
  userUploadTask: GQUserUploadTask;
};

export type GQCreateDatasetInput = {
  canonicalDatasetId?: InputMaybe<Scalars['ID']['input']>;
  customExplainer?: InputMaybe<Scalars['String']['input']>;
  customName?: InputMaybe<Scalars['String']['input']>;
  customPotentialOverlaps?: InputMaybe<Scalars['String']['input']>;
  customUsedFor?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
  measurementProjectIdToAddTaskIn?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['String']['input'];
};

export type GQCreateDatasourceAdminInput = {
  canonicalDatasourceId?: InputMaybe<Scalars['ID']['input']>;
  customerTargetSchemaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  datasetId: Scalars['ID']['input'];
  importInstructionsMd?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
  isUserConfirmed: Scalars['Boolean']['input'];
  measurementProjectIdToAddTaskIn?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  notesFromWatershedMd?: InputMaybe<Scalars['String']['input']>;
  refreshCadence?: InputMaybe<GQDatasetCadence>;
  userIdsWithPermission?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQCreateDemoOrgInput = {
  companySourceKey: Scalars['String']['input'];
  grantAccessUserIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  stagingOrg: Scalars['Boolean']['input'];
  testOrg: Scalars['Boolean']['input'];
  userName: Scalars['String']['input'];
};

export type GQCreateDemoOrgPayload = {
  __typename?: 'CreateDemoOrgPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQCreateEacMarketplaceProjectsInput = {
  countryCodes: Array<Scalars['String']['input']>;
  marketplaceProjectArchetypeId: Scalars['ID']['input'];
  marketplaceSupplierIds: Array<Scalars['ID']['input']>;
  vintageYears: Array<Scalars['Int']['input']>;
};

export type GQCreateEacMarketplaceProjectsPayload = {
  __typename?: 'CreateEacMarketplaceProjectsPayload';
  affectedMarketplaceProjectIds: Array<Scalars['ID']['output']>;
};

export type GQCreateEacPriceEstimateInput = {
  alternativeCountries: Array<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  defaultProjectId?: InputMaybe<Scalars['ID']['input']>;
  minCreditPurchase: Scalars['Int']['input'];
  priceEstimateUsdCents: Scalars['Int']['input'];
  standard: Scalars['String']['input'];
};

export type GQCreateEacPriceEstimatePayload = {
  __typename?: 'CreateEacPriceEstimatePayload';
  eacPriceEstimate: GQEacPriceEstimate;
  id: Scalars['ID']['output'];
};

export type GQCreateEmissionsModelInput = {
  description: Scalars['String']['input'];
  emissionsModelJson: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type GQCreateEmissionsModelPayload =
  | GQCreateEmissionsModelPayloadInvalid
  | GQCreateEmissionsModelPayloadValid;

export type GQCreateEmissionsModelPayloadInvalid = {
  __typename?: 'CreateEmissionsModelPayloadInvalid';
  validationErrors: Array<GQEmissionsModelError>;
};

export type GQCreateEmissionsModelPayloadValid = {
  __typename?: 'CreateEmissionsModelPayloadValid';
  emissionsModelStableRecord: GQEmissionsModelStable;
  emissionsModelVersionRecord: GQEmissionsModelVersion;
  parameterResolverRecord: GQParameterResolver;
};

export type GQCreateEmissionsModelReleaseInput = {
  businessActivityTypeName: Scalars['String']['input'];
  displayName: Scalars['String']['input'];
  externalNotes: Scalars['String']['input'];
  internalNotes: Scalars['String']['input'];
  isOrgGated: Scalars['Boolean']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateEmissionsYearInput = {
  numEmployees?: InputMaybe<Scalars['Float']['input']>;
  reportingYear: Scalars['Int']['input'];
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenueCurrency?: InputMaybe<Scalars['String']['input']>;
  scope1?: InputMaybe<Scalars['Float']['input']>;
  scope2Location?: InputMaybe<Scalars['Float']['input']>;
  scope2Market?: InputMaybe<Scalars['Float']['input']>;
  scope3?: InputMaybe<Scalars['Float']['input']>;
  scope301?: InputMaybe<Scalars['Float']['input']>;
  scope302?: InputMaybe<Scalars['Float']['input']>;
  scope303?: InputMaybe<Scalars['Float']['input']>;
  scope304?: InputMaybe<Scalars['Float']['input']>;
  scope305?: InputMaybe<Scalars['Float']['input']>;
  scope306?: InputMaybe<Scalars['Float']['input']>;
  scope307?: InputMaybe<Scalars['Float']['input']>;
  scope308?: InputMaybe<Scalars['Float']['input']>;
  scope309?: InputMaybe<Scalars['Float']['input']>;
  scope310?: InputMaybe<Scalars['Float']['input']>;
  scope311?: InputMaybe<Scalars['Float']['input']>;
  scope312?: InputMaybe<Scalars['Float']['input']>;
  scope313?: InputMaybe<Scalars['Float']['input']>;
  scope314?: InputMaybe<Scalars['Float']['input']>;
  scope315?: InputMaybe<Scalars['Float']['input']>;
  scope316?: InputMaybe<Scalars['Float']['input']>;
  scope317?: InputMaybe<Scalars['Float']['input']>;
  units: GQCompanyEmissionsUnits;
};

export type GQCreateExternalReportQuestionInput = {
  externalReportTypeId: Scalars['ID']['input'];
  identifier: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type GQCreateExternalReportQuestionPayload = {
  __typename?: 'CreateExternalReportQuestionPayload';
  question: GQExternalReportQuestion;
};

export type GQCreateExternalReportSectionUrlInput = {
  sectionKind: GQExternalReportSectionKind;
  urlFragment: Scalars['String']['input'];
};

export type GQCreateExternalReportTypeWithRevisionInput = {
  revision: Scalars['String']['input'];
  specUrl?: InputMaybe<Scalars['String']['input']>;
  type: GQExternalReportType;
};

export type GQCreateExternalReportTypeWithRevisionPayload = {
  __typename?: 'CreateExternalReportTypeWithRevisionPayload';
  externalReportTypeWithRevision: GQExternalReportTypeWithRevision;
};

export type GQCreateFacilitiesSetupTaskInput = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQCreateFacilitiesSetupTaskPayload = {
  __typename?: 'CreateFacilitiesSetupTaskPayload';
  measurementProject: GQMeasurementProject;
};

export type GQCreateFinanceImportInput = {
  dataFormat: GQOneSchemaDataFormat;
  oneSchemaEmbedId: Scalars['Int']['input'];
  oneSchemaErrorMessages: Scalars['UntypedData']['input'];
  oneSchemaSheetId: Scalars['Int']['input'];
  originalFileName: Scalars['String']['input'];
};

export type GQCreateFinanceImportPayload = {
  __typename?: 'CreateFinanceImportPayload';
  backgroundJobId: Scalars['ID']['output'];
  financeImport: GQFinanceImport;
};

export type GQCreateFinanceUploadTemplateInput = {
  dataFormat: GQOneSchemaDataFormat;
};

export type GQCreateFinanceUploadTemplatePayload = {
  __typename?: 'CreateFinanceUploadTemplatePayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQCreateFinancialsReviewItemDraftInput = {
  accountDescription?: InputMaybe<Scalars['String']['input']>;
  accountId: Scalars['String']['input'];
  displayAccountId: Scalars['String']['input'];
  exclusionReason?: InputMaybe<Scalars['String']['input']>;
  initialIndustryClassificationId?: InputMaybe<Scalars['ID']['input']>;
  initiallyIncluded: Scalars['Boolean']['input'];
  kind: GQFinancialsReviewItemKind;
  prompt?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateFinancialsReviewItemDraftsInput = {
  inputs: Array<GQCreateFinancialsReviewItemDraftInput>;
  measurementProjectId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQCreateFinancialsReviewItemDraftsPayload = {
  __typename?: 'CreateFinancialsReviewItemDraftsPayload';
  financialsReviewItems: GQFinancialsReviewItemConnection;
};

export type GQCreateFootprintDisclosureInput = {
  footprintSnapshotId: Scalars['ID']['input'];
  granularity: GQFootprintDisclosureGranularity;
  year: Scalars['Int']['input'];
};

export type GQCreateFootprintDisclosurePayload = {
  __typename?: 'CreateFootprintDisclosurePayload';
  footprintDisclosure: GQFootprintDisclosure;
};

export type GQCreateFootprintSnapshotInput = {
  adminFootprintCreationOtherText?: InputMaybe<Scalars['String']['input']>;
  adminFootprintCreationReason: Scalars['String']['input'];
  configs: Array<GQPipelineConfigInput>;
  description: Scalars['String']['input'];
  extraAdeIdsForNonEmissiveActivity?: InputMaybe<
    Array<Scalars['String']['input']>
  >;
  extraAdtIdsForIntDenoms?: InputMaybe<Array<Scalars['String']['input']>>;
  footprintId?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  selectedAllocationInstructionIds: Array<Scalars['ID']['input']>;
  shouldSkipApplyingCategoryRules?: InputMaybe<Scalars['Boolean']['input']>;
  shouldSkipApplyingExclusionRules?: InputMaybe<Scalars['Boolean']['input']>;
  tagIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQCreateFootprintSnapshotPayload = {
  __typename?: 'CreateFootprintSnapshotPayload';
  job: GQBackgroundJobEntry;
};

export type GQCreateForecastInput = {
  footprintSnapshotId: Scalars['ID']['input'];
};

export type GQCreateForecastPayload = {
  __typename?: 'CreateForecastPayload';
  forecast: GQForecast;
};

export type GQCreateFundInputAdmin = {
  excludeAsSandbox?: InputMaybe<Scalars['Boolean']['input']>;
  fundGroup?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nameSortable?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateFundPayload = {
  __typename?: 'CreateFundPayload';
  fund: GQFund;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateGrowthForecastInput = {
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  forecast: GQCreateSimpleTimeseriesInput;
  growthFactorType: GQGrowthFactorType;
  historicalGrowthValues?: InputMaybe<GQCreateSimpleTimeseriesInput>;
  isDefaultScalingFactor?: InputMaybe<Scalars['Boolean']['input']>;
  scalingFactorFilters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQCreateLifecycleAssessmentInput = {
  boundary: GQLifecycleAssessmentBoundaryEnum;
  dataUsedNotes?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  productDescription?: InputMaybe<Scalars['String']['input']>;
  productName: Scalars['String']['input'];
  productUnit: Scalars['String']['input'];
  referenceEndDate: Scalars['DateTime']['input'];
  referenceStartDate: Scalars['DateTime']['input'];
  supplierProductExternalId?: InputMaybe<Scalars['String']['input']>;
  validityEndDate: Scalars['DateTime']['input'];
  validityStartDate: Scalars['DateTime']['input'];
};

export type GQCreateMarketplaceAllocationInstructionInput = {
  amountKwh: Scalars['Int']['input'];
  buildingName?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  footprintKind?: InputMaybe<Scalars['String']['input']>;
  ghgCategoryId: Scalars['String']['input'];
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
  isZeroedOut?: InputMaybe<Scalars['Boolean']['input']>;
  locationCity?: InputMaybe<Scalars['String']['input']>;
  locationCountry?: InputMaybe<Scalars['String']['input']>;
  locationGrid?: InputMaybe<Scalars['String']['input']>;
  locationState?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
  purchaseLineItemId: Scalars['ID']['input'];
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  vendorEntity?: InputMaybe<Scalars['String']['input']>;
  vendorSubentity?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateMarketplaceAllocationInstructionPayload = {
  __typename?: 'CreateMarketplaceAllocationInstructionPayload';
  allocationInstruction: GQMarketplaceAllocationInstruction;
};

export type GQCreateMarketplaceDeveloperInput = {
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateMarketplaceDeveloperPayload = {
  __typename?: 'CreateMarketplaceDeveloperPayload';
  marketplaceDeveloper: GQMarketplaceDeveloper;
};

export type GQCreateMarketplaceEacPurchaseCountryInput = {
  countryWithEacs: Scalars['String']['input'];
  countryWithEmissions: Scalars['String']['input'];
  coverageInterval: Scalars['YMInterval']['input'];
  eacFeeUsdCents: Scalars['Int']['input'];
  eacPriceUsdCents: Scalars['Int']['input'];
  marketTco2e: Scalars['Float']['input'];
  mwh: Scalars['Float']['input'];
  numEacsRequested: Scalars['Int']['input'];
};

export type GQCreateMarketplaceEacPurchasePayload = {
  __typename?: 'CreateMarketplaceEacPurchasePayload';
  marketplacePurchase: Maybe<GQMarketplacePurchase>;
  marketplacePurchaseId: Maybe<Scalars['ID']['output']>;
};

export type GQCreateMarketplaceProjectArchetypeInput = {
  additionalityRating?: InputMaybe<Scalars['Int']['input']>;
  bannerImageUrl?: InputMaybe<Scalars['String']['input']>;
  certificateBlurb?: InputMaybe<Scalars['String']['input']>;
  certificateColor?: InputMaybe<Scalars['String']['input']>;
  descriptionMd: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isUserVisible: Scalars['Boolean']['input'];
  kind: GQMarketplaceProjectArchetypeKind;
  name: Scalars['String']['input'];
  permanenceRating?: InputMaybe<Scalars['String']['input']>;
  summaryMd: Scalars['String']['input'];
  unit: GQMarketplaceProjectArchetypeUnit;
};

export type GQCreateMarketplaceProjectArchetypePayload = {
  __typename?: 'CreateMarketplaceProjectArchetypePayload';
  marketplaceProjectArchetype: GQMarketplaceProjectArchetype;
};

export type GQCreateMarketplaceProjectInput = {
  archetypeId: Scalars['ID']['input'];
  developerId?: InputMaybe<Scalars['ID']['input']>;
  internalNotesMd?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  registryId?: InputMaybe<Scalars['String']['input']>;
  status: GQMarketplaceProjectStatus;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateMarketplaceProjectOfferingInput = {
  availability: Scalars['Float']['input'];
  isAddingVintage?: InputMaybe<Scalars['Boolean']['input']>;
  noteMd: Scalars['String']['input'];
  priceUsdCents: Scalars['Float']['input'];
  projectId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
  vintage?: InputMaybe<Scalars['Int']['input']>;
};

export type GQCreateMarketplaceProjectOfferingPayload = {
  __typename?: 'CreateMarketplaceProjectOfferingPayload';
  addedVintageOfferings: Maybe<Array<GQMarketplaceProjectOffering>>;
  marketplaceProject: GQMarketplaceProject;
  marketplaceProjectOffering: GQMarketplaceProjectOffering;
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQCreateMarketplaceProjectPayload = {
  __typename?: 'CreateMarketplaceProjectPayload';
  marketplaceProject: GQMarketplaceProject;
};

export type GQCreateMarketplacePurchaseInput = {
  name: Scalars['String']['input'];
  noteMd?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  status: GQMarketplacePurchaseStatus;
};

export type GQCreateMarketplacePurchaseLineItemEacMetadataInput = {
  countryWithEacs: Scalars['String']['input'];
  countryWithEmissions: Scalars['String']['input'];
  marketTco2e: Scalars['Float']['input'];
  mwh: Scalars['Float']['input'];
  numEacsRequested: Scalars['Int']['input'];
};

export type GQCreateMarketplacePurchaseLineItemInput = {
  amount: Scalars['Float']['input'];
  amountUnit?: InputMaybe<GQMarketplaceProjectArchetypeUnit>;
  appliedToFootprint?: InputMaybe<Scalars['Boolean']['input']>;
  cleanPowerContractualInstrument?: InputMaybe<GQCleanPowerContractualInstrument>;
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  coverageInterval: Scalars['YMInterval']['input'];
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  eacMetadata?: InputMaybe<GQCreateMarketplacePurchaseLineItemEacMetadataInput>;
  mechanism?: InputMaybe<GQMarketplaceProjectArchetypeMechanism>;
  noteMd: Scalars['String']['input'];
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  purchaseId: Scalars['ID']['input'];
  status: GQMarketplacePurchaseLineItemStatus;
  unitFeeUsdCents: Scalars['Float']['input'];
  unitPriceUsdCents: Scalars['Float']['input'];
};

export type GQCreateMarketplacePurchaseLineItemPayload = {
  __typename?: 'CreateMarketplacePurchaseLineItemPayload';
  marketplacePurchase: GQMarketplacePurchase;
  marketplacePurchaseLineItem: GQMarketplacePurchaseLineItem;
};

export type GQCreateMarketplacePurchasePayload = {
  __typename?: 'CreateMarketplacePurchasePayload';
  marketplacePurchase: GQMarketplacePurchase;
};

/**
 * #####################################################
 * Marketplace
 */
export type GQCreateMarketplaceSupplierInput = {
  name: Scalars['String']['input'];
};

export type GQCreateMarketplaceSupplierPayload = {
  __typename?: 'CreateMarketplaceSupplierPayload';
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQCreateMaterialityAssessmentDataPointInput = {
  frameworkDataPointId: Scalars['ID']['input'];
  status: GQMaterialityStatus;
};

export type GQCreateMaterialityAssessmentInput = {
  dataPoints: Array<GQCreateMaterialityAssessmentDataPointInput>;
  name: Scalars['String']['input'];
};

export type GQCreateMaterialityAssessmentPayload = {
  __typename?: 'CreateMaterialityAssessmentPayload';
  materialityAssessment: GQMaterialityAssessment;
};

export type GQCreateMeasurementProjectPayload = {
  __typename?: 'CreateMeasurementProjectPayload';
  measurementProject: Maybe<GQMeasurementProject>;
  org: Maybe<GQOrganization>;
};

export type GQCreateMeasurementTestSuiteBartInput = {
  activityTypeName: Scalars['String']['input'];
  addFixtureData: Scalars['Boolean']['input'];
  businessActivityTypeId: Scalars['String']['input'];
  fixtureCustomIdentifier: Scalars['String']['input'];
  measurementTestSuiteId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type GQCreateMeasurementTestSuiteBartPayload = {
  __typename?: 'CreateMeasurementTestSuiteBartPayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQCreateMeasurementTestSuiteInput = {
  description: Scalars['String']['input'];
  kind?: InputMaybe<GQMeasurementTestSuiteKind>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  releaseIds: Array<Scalars['ID']['input']>;
  tagIds: Array<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type GQCreateMeasurementTestSuitePayload = {
  __typename?: 'CreateMeasurementTestSuitePayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQCreateOrgCompaniesAndCompaniesInput = {
  creationInfo: Array<GQOrgCompanyAndCompanyNameForCompany>;
  orgId: Scalars['String']['input'];
  source: GQOrgCompanySource;
};

export type GQCreateOrgCompaniesForCompanyInput = {
  companyId: Scalars['String']['input'];
  names: Array<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  source: GQOrgCompanySource;
};

export type GQCreateOrgCompaniesForCompanyPayload = {
  __typename?: 'CreateOrgCompaniesForCompanyPayload';
  organization: GQOrganization;
};

export type GQCreateOrgCompanyPayload = {
  __typename?: 'CreateOrgCompanyPayload';
  organization: GQOrganization;
};

export type GQCreateOrgRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  permissions?: InputMaybe<Array<GQCreatePermissionItemNestedInput>>;
};

export type GQCreateOrgStructureVersionUploadInput = {
  oneSchemaEmbedId: Scalars['Int']['input'];
  orgStructureVersionId: Scalars['ID']['input'];
  originalFileName: Scalars['String']['input'];
};

export type GQCreateOrgStructureVersionUploadPayload = {
  __typename?: 'CreateOrgStructureVersionUploadPayload';
  backgroundJobId: Scalars['ID']['output'];
  orgStructureVersionUpload: GQOrgStructureVersionUpload;
};

export type GQCreateOrganizationInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  country: Scalars['String']['input'];
  countryAlpha2?: InputMaybe<Scalars['String']['input']>;
  demoOrg: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  domains: Array<Scalars['String']['input']>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  passwordAuthDisabled: Scalars['Boolean']['input'];
  sAndPId?: InputMaybe<Scalars['String']['input']>;
  stagingOrg?: InputMaybe<Scalars['Boolean']['input']>;
  testOrg?: InputMaybe<Scalars['Boolean']['input']>;
  watershedPlanLegacy: GQWatershedPlanLegacy;
};

export type GQCreatePermissionItemInput = {
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  orgAccessId?: InputMaybe<Scalars['ID']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  permission: GQPermissionType;
  roleId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreatePermissionItemNestedInput = {
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  permission: GQPermissionType;
};

export type GQCreatePermissionItemPayload = {
  __typename?: 'CreatePermissionItemPayload';
  permissionItem: GQPermissionItem;
};

export type GQCreatePlanInput = {
  autogenerateSbtTargets?: InputMaybe<Scalars['Boolean']['input']>;
  baselineYearStart: Scalars['YearMonth']['input'];
  footprintSnapshotId: Scalars['String']['input'];
  forecastIntervalEndYearMonth: Scalars['YearMonth']['input'];
  forecastReferencePeriodEnd: Scalars['YearMonth']['input'];
  forecastReferencePeriodStart: Scalars['YearMonth']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isInNativeCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  overrideFootprintKind?: InputMaybe<Scalars['String']['input']>;
  termLength?: InputMaybe<GQSbtTermLength>;
};

export type GQCreatePlanPayload = {
  __typename?: 'CreatePlanPayload';
  plan: GQPlan;
};

export type GQCreatePublicDisclosureInput = {
  companyId: Scalars['ID']['input'];
  emissionsYearsData?: InputMaybe<Array<GQCreateEmissionsYearInput>>;
  gsUrl?: InputMaybe<Scalars['String']['input']>;
  publicUrl: Scalars['String']['input'];
  publishingYear: Scalars['Int']['input'];
  reportType: GQExternalReportType;
  sectionUrls?: InputMaybe<Array<GQCreateExternalReportSectionUrlInput>>;
};

export type GQCreatePublicDisclosurePayload = {
  __typename?: 'CreatePublicDisclosurePayload';
  publicDisclosure: GQPublicDisclosure;
};

export type GQCreatePublicDisclosureTargetInput = {
  disclosureTarget: GQDisclosureTargetInput;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  publicDisclosureId: Scalars['ID']['input'];
};

export type GQCreatePublicDisclosureTargetPayload = {
  __typename?: 'CreatePublicDisclosureTargetPayload';
  disclosureTarget: GQDisclosureTargetDetails;
};

export type GQCreateRealEstateSandboxEstimationInput = {
  buildingKind: Scalars['String']['input'];
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  buildingSizeUnit: GQBuildingSizeUnit;
  countryAlpha2: GQCountryAlpha2;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
};

export type GQCreateRealEstateSandboxEstimationPayload = {
  __typename?: 'CreateRealEstateSandboxEstimationPayload';
  backgroundJobId: Scalars['String']['output'];
};

export type GQCreateReferenceDataCitationInput = {
  displayName: Scalars['String']['input'];
  externalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  externalSourceUrl?: InputMaybe<Scalars['String']['input']>;
  hasLicenseRestrictions: Scalars['Boolean']['input'];
  internalSourceNotes: Scalars['String']['input'];
  internalSourceUrl: Scalars['String']['input'];
  isCustomerProvided?: InputMaybe<Scalars['Boolean']['input']>;
  isSupplierSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  privateDisclosureId?: InputMaybe<Scalars['String']['input']>;
  publicDisclosureId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type GQCreateReferenceDataCitationPayload = {
  __typename?: 'CreateReferenceDataCitationPayload';
  referenceDataCitation: GQReferenceDataCitation;
};

export type GQCreateReferenceDataOverlayTemplateInput = {
  orgId: Scalars['String']['input'];
  referenceDataVersionId: Scalars['ID']['input'];
  skippedColumns?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQCreateReferenceDataOverlayTemplatePayload = {
  __typename?: 'CreateReferenceDataOverlayTemplatePayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQCreateReferenceDataSourceInput = {
  externalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  versionState?: InputMaybe<GQReferenceDataVersionState>;
};

export type GQCreateReferenceDataSourcePayload = {
  __typename?: 'CreateReferenceDataSourcePayload';
  referenceDataSource: GQReferenceDataSource;
};

export type GQCreateReferenceDataVersionInput = {
  kind?: InputMaybe<GQReferenceDataKind>;
  sourceId: Scalars['String']['input'];
  state?: InputMaybe<GQReferenceDataVersionState>;
  versionName: Scalars['String']['input'];
  versionVintage: Scalars['DateTime']['input'];
};

export type GQCreateReferenceDataVersionPayload = {
  __typename?: 'CreateReferenceDataVersionPayload';
  referenceDataVersion: GQReferenceDataVersion;
};

export type GQCreateReportAttachmentFileInput = {
  filename: Scalars['String']['input'];
  size: Scalars['Int53']['input'];
};

export type GQCreateReportAttachmentsInput = {
  attachmentItemIds: Array<Scalars['ID']['input']>;
  reportId: Scalars['ID']['input'];
  reportQuestionId: Scalars['ID']['input'];
};

export type GQCreateReportAttachmentsPayload = {
  __typename?: 'CreateReportAttachmentsPayload';
  attachmentItems: Array<GQReportAttachmentItem>;
};

export type GQCreateReportBCorpInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportCdpInput = {
  baseInterval: Scalars['YMInterval']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  previouslyFiled?: InputMaybe<Scalars['Boolean']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportConfigFromSrcInput = {
  configIdToExtend?: InputMaybe<Scalars['ID']['input']>;
  importBehaviorFlags?: InputMaybe<GQReportConfigImportBehaviorFlagsInput>;
  src: Scalars['JSONString']['input'];
};

export type GQCreateReportConfigInput = {
  brandColor: Scalars['String']['input'];
  description: Scalars['String']['input'];
  isCorporateReport: Scalars['Boolean']['input'];
  isCreationBlocked: Scalars['Boolean']['input'];
  isFinanceReport: Scalars['Boolean']['input'];
  isHiddenFromOverview: Scalars['Boolean']['input'];
  isRegulatoryExposureBlocked: Scalars['Boolean']['input'];
  longName: Scalars['String']['input'];
  reportObjectives: Array<GQCanonicalReportObjective>;
  reportType: Scalars['String']['input'];
  requiredPermissions: Array<GQPermissionType>;
  shortName: Scalars['String']['input'];
  status?: InputMaybe<GQReportConfigStatus>;
  useApprovals?: InputMaybe<Scalars['Boolean']['input']>;
  useMateriality?: InputMaybe<Scalars['Boolean']['input']>;
  useOverrides?: InputMaybe<Scalars['Boolean']['input']>;
  useReportingIntention?: InputMaybe<Scalars['Boolean']['input']>;
  useTableDisplay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreateReportConfigPayload = {
  __typename?: 'CreateReportConfigPayload';
  reportConfig: GQReportConfig;
};

export type GQCreateReportEuropeTcfdInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval?: InputMaybe<Scalars['YMInterval']['input']>;
};

export type GQCreateReportFileAttachmentItemPayload = {
  __typename?: 'CreateReportFileAttachmentItemPayload';
  attachment: GQReportAttachmentItem;
  url: Scalars['String']['output'];
};

export type GQCreateReportFileAttachmentsInput = {
  files: Array<GQCreateReportAttachmentFileInput>;
};

export type GQCreateReportFileAttachmentsPayload = {
  __typename?: 'CreateReportFileAttachmentsPayload';
  items: Array<GQCreateReportFileAttachmentItemPayload>;
};

export type GQCreateReportPayload = {
  __typename?: 'CreateReportPayload';
  report: GQReport;
  reportGenerationJobId: Maybe<Scalars['String']['output']>;
};

export type GQCreateReportQuestionContainerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>;
  guidance: Scalars['JSONString']['input'];
  insertionIndex?: InputMaybe<Scalars['Int']['input']>;
  items?: InputMaybe<Array<Scalars['ID']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  reportConfigId: Scalars['ID']['input'];
  status: Scalars['JSONString']['input'];
};

export type GQCreateReportQuestionContainerPayload = {
  __typename?: 'CreateReportQuestionContainerPayload';
  container: GQReportQuestionContainer;
};

export type GQCreateReportQuestionInput = {
  allowNotes: Scalars['Boolean']['input'];
  allowedAttachmentKinds?: InputMaybe<Array<GQReportAttachmentItemKind>>;
  componentId: Scalars['String']['input'];
  dynamicInputs?: InputMaybe<Scalars['JSONString']['input']>;
  guidance: Scalars['JSONString']['input'];
  identifier: Scalars['String']['input'];
  isComputed: Scalars['Boolean']['input'];
  isDynamic: Scalars['Boolean']['input'];
  isInput?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  reportConfigId: Scalars['ID']['input'];
  staticInputs?: InputMaybe<Scalars['JSONString']['input']>;
  status: Scalars['JSONString']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateReportQuestionPayload = {
  __typename?: 'CreateReportQuestionPayload';
  question: GQReportQuestion;
};

export type GQCreateReportSasbInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  industry: GQSasbIndustry;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportSecrInput = {
  balanceSheet?: InputMaybe<Scalars['Boolean']['input']>;
  baseInterval: Scalars['YMInterval']['input'];
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  employees?: InputMaybe<Scalars['Boolean']['input']>;
  listed?: InputMaybe<Scalars['Boolean']['input']>;
  previouslyFiled?: InputMaybe<Scalars['Boolean']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
  turnover?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreateReportTcfdInput = {
  baseInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportUrlAttachmentItemInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type GQCreateReportUrlAttachmentItemPayload = {
  __typename?: 'CreateReportURLAttachmentItemPayload';
  attachment: GQReportAttachmentItem;
  url: Scalars['String']['output'];
};

export type GQCreateReportUkTenderInput = {
  baseInterval: Scalars['YMInterval']['input'];
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId: Scalars['ID']['input'];
  reductionPctIn5Years: Scalars['Float']['input'];
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportWithConfigAdminInput = {
  configId: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export type GQCreateReportWsInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportingFrameworkDataPointsInput = {
  dataPoints: Array<GQReportingFrameworkDataPointInput>;
  reportingFrameworkId: Scalars['ID']['input'];
};

export type GQCreateReportingFrameworkDataPointsPayload = {
  __typename?: 'CreateReportingFrameworkDataPointsPayload';
  dataPoints: Array<GQReportingFrameworkDataPoint>;
};

export type GQCreateReportingFrameworkInput = {
  dataPoints: Array<GQReportingFrameworkDataPointInput>;
  description: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GQCreateReportingFrameworkPayload = {
  __typename?: 'CreateReportingFrameworkPayload';
  reportingFramework: GQReportingFramework;
};

export type GQCreateRolePayload = {
  __typename?: 'CreateRolePayload';
  role: GQRole;
};

export type GQCreateSimpleTimeseriesInput = {
  base: Scalars['Date']['input'];
  frequency: GQTimeseriesFrequency;
  values: Array<Scalars['Float']['input']>;
};

export type GQCreateSupplierChartInputAdmin = {
  orgId: Scalars['ID']['input'];
  supplierChartInput: GQSupplierChartInput;
};

export type GQCreateSupplierChartPayloadAdmin = {
  __typename?: 'CreateSupplierChartPayloadAdmin';
  chart:
    | GQSupplierEmissionsForecastChartConfig
    | GQSupplierEngagementFunnelChartConfig
    | GQSupplierMetricsChartConfig
    | GQSupplierSbtCommitmentOverTimeChartConfig
    | GQSupplierStandardChartConfig
    | GQSupplierTaskStatusChangeOverTimeChartConfig;
  views: Array<GQSupplierView>;
};

export type GQCreateTargetInput = {
  orgId: Scalars['ID']['input'];
  target: GQTargetInput;
};

export type GQCreateTargetPayload = {
  __typename?: 'CreateTargetPayload';
  org: GQOrganization;
  target: GQTarget;
};

export type GQCreateTcfdArchetypeOpportunityInput = {
  archetypeRiskId?: InputMaybe<Scalars['ID']['input']>;
  distributionModel: GQTcfdStrategyModuleDistributionModel;
  geographies: Array<Scalars['String']['input']>;
  sectors: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userDescription: Scalars['String']['input'];
};

export type GQCreateTcfdArchetypeRiskInput = {
  distributionModel: GQTcfdStrategyModuleDistributionModel;
  geographies: Array<Scalars['String']['input']>;
  highTrajectoryImpact: GQTcfdRiskImpact;
  kind: GQTcfdRiskKind;
  lowTrajectoryImpact: GQTcfdRiskImpact;
  mediumTrajectoryImpact: GQTcfdRiskImpact;
  physicalRiskSeverity?: InputMaybe<GQTcfdPhysicalRiskSeverity>;
  possibleImpactDescription: Scalars['String']['input'];
  sectors: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  userDescription: Scalars['String']['input'];
  whyWeChoseThisMd: Scalars['String']['input'];
};

export const GQCreateUserAdminSendUserEmails = {
  FromWatershed: 'FromWatershed',
  None: 'None',
} as const;

export type GQCreateUserAdminSendUserEmails =
  (typeof GQCreateUserAdminSendUserEmails)[keyof typeof GQCreateUserAdminSendUserEmails];
export type GQCreateUserAdminVersionInput = {
  accessExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  accessReason?: InputMaybe<Scalars['String']['input']>;
  activateUser?: InputMaybe<Scalars['Boolean']['input']>;
  allowOutsideDomain?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<
    Array<InputMaybe<GQCreatePermissionItemNestedInput>>
  >;
  roles?: InputMaybe<Array<Scalars['ID']['input']>>;
  sendUserEmails: GQCreateUserAdminSendUserEmails;
};

export type GQCreateUserAdminVersionPayload = {
  __typename?: 'CreateUserAdminVersionPayload';
  org: GQOrganization;
  user: GQUser;
};

export type GQCreateUserUploadTaskInput = {
  datasourceId: Scalars['String']['input'];
  measurementProjectId: Scalars['ID']['input'];
};

export type GQCreateUserUploadTaskPayload = {
  __typename?: 'CreateUserUploadTaskPayload';
  measurementProject: GQMeasurementProject;
  userUploadTask: GQUserUploadTask;
};

export type GQCsrdDataRequirementsConfig = {
  __typename?: 'CsrdDataRequirementsConfig';
  configJson: Maybe<Scalars['JSONString']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  editedByUser: Maybe<GQUser>;
  editedByUserId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  reportId: Maybe<Scalars['ID']['output']>;
};

export type GQCtsForm = {
  __typename?: 'CtsForm';
  closedAt: Maybe<Scalars['Date']['output']>;
  createdBy: GQUser;
  ctsVersionId: Scalars['ID']['output'];
  customerTargetSchemaId: Scalars['ID']['output'];
  dataset: GQDataset;
  datasetId: Scalars['ID']['output'];
  description: Maybe<Scalars['String']['output']>;
  entries: Array<GQCtsFormEntry>;
  id: Scalars['ID']['output'];
  title: Maybe<Scalars['String']['output']>;
};

export type GQCtsFormEntry = {
  __typename?: 'CtsFormEntry';
  createdBy: GQUser;
  data: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
};

export const GQCtsTestRunStatus = {
  Approved: 'Approved',
  Error: 'Error',
  Fail: 'Fail',
  Pass: 'Pass',
  Stale: 'Stale',
} as const;

export type GQCtsTestRunStatus =
  (typeof GQCtsTestRunStatus)[keyof typeof GQCtsTestRunStatus];
export const GQCtsTransformTestKind = {
  File: 'File',
  Json: 'Json',
} as const;

export type GQCtsTransformTestKind =
  (typeof GQCtsTransformTestKind)[keyof typeof GQCtsTransformTestKind];
export type GQCtsvOneSchemaFileFeed = {
  __typename?: 'CtsvOneSchemaFileFeed';
  ctsVersionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastRun: Maybe<GQCtsvOneSchemaFileFeedImport>;
  numErrorImports: Scalars['Int']['output'];
  numSuccessfulImports: Scalars['Int']['output'];
  oneSchemaFileFeedId: Scalars['String']['output'];
};

export type GQCtsvOneSchemaFileFeedImport = {
  __typename?: 'CtsvOneSchemaFileFeedImport';
  createdAt: Scalars['DateTime']['output'];
  ctsvOneSchemaFileFeed: Maybe<GQCtsvOneSchemaFileFeed>;
  destinationDataPreview: Maybe<Scalars['UntypedData']['output']>;
  id: Scalars['ID']['output'];
  oneSchemaFileFeedImportId: Scalars['String']['output'];
  sourceUserUploadCandidateId: Scalars['ID']['output'];
  status: GQCtsvOneSchemaFileFeedImportStatus;
};

export const GQCtsvOneSchemaFileFeedImportStatus = {
  Error: 'Error',
  Pending: 'Pending',
  Success: 'Success',
} as const;

export type GQCtsvOneSchemaFileFeedImportStatus =
  (typeof GQCtsvOneSchemaFileFeedImportStatus)[keyof typeof GQCtsvOneSchemaFileFeedImportStatus];
export const GQCurrencyCode = {
  Aed: 'AED',
  Afn: 'AFN',
  All: 'ALL',
  Amd: 'AMD',
  Ang: 'ANG',
  Aoa: 'AOA',
  Ars: 'ARS',
  Aud: 'AUD',
  Awg: 'AWG',
  Azn: 'AZN',
  Bam: 'BAM',
  Bbd: 'BBD',
  Bdt: 'BDT',
  Bgn: 'BGN',
  Bhd: 'BHD',
  Bif: 'BIF',
  Bmd: 'BMD',
  Bnd: 'BND',
  Bob: 'BOB',
  Brl: 'BRL',
  Bsd: 'BSD',
  Btn: 'BTN',
  Bwp: 'BWP',
  Byn: 'BYN',
  Bzd: 'BZD',
  Cad: 'CAD',
  Cdf: 'CDF',
  Chf: 'CHF',
  Clp: 'CLP',
  Cny: 'CNY',
  Cop: 'COP',
  Crc: 'CRC',
  Cup: 'CUP',
  Cve: 'CVE',
  Czk: 'CZK',
  Djf: 'DJF',
  Dkk: 'DKK',
  Dop: 'DOP',
  Dzd: 'DZD',
  Egp: 'EGP',
  Ern: 'ERN',
  Etb: 'ETB',
  Eur: 'EUR',
  Fjd: 'FJD',
  Fkp: 'FKP',
  Gbp: 'GBP',
  Gel: 'GEL',
  Ggp: 'GGP',
  Ghs: 'GHS',
  Gip: 'GIP',
  Gmd: 'GMD',
  Gnf: 'GNF',
  Gtq: 'GTQ',
  Gyd: 'GYD',
  Hkd: 'HKD',
  Hnl: 'HNL',
  Hrk: 'HRK',
  Htg: 'HTG',
  Huf: 'HUF',
  Idr: 'IDR',
  Ils: 'ILS',
  Imp: 'IMP',
  Inr: 'INR',
  Iqd: 'IQD',
  Irr: 'IRR',
  Isk: 'ISK',
  Jep: 'JEP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Jpy: 'JPY',
  Kes: 'KES',
  Kgs: 'KGS',
  Khr: 'KHR',
  Kmf: 'KMF',
  Kpw: 'KPW',
  Krw: 'KRW',
  Kwd: 'KWD',
  Kyd: 'KYD',
  Kzt: 'KZT',
  Lak: 'LAK',
  Lbp: 'LBP',
  Lkr: 'LKR',
  Lrd: 'LRD',
  Lsl: 'LSL',
  Lyd: 'LYD',
  Mad: 'MAD',
  Mdl: 'MDL',
  Mga: 'MGA',
  Mkd: 'MKD',
  Mmk: 'MMK',
  Mnt: 'MNT',
  Mop: 'MOP',
  Mru: 'MRU',
  Mur: 'MUR',
  Mvr: 'MVR',
  Mwk: 'MWK',
  Mxn: 'MXN',
  Myr: 'MYR',
  Mzn: 'MZN',
  Nad: 'NAD',
  Ngn: 'NGN',
  Nio: 'NIO',
  Nok: 'NOK',
  Npr: 'NPR',
  Nzd: 'NZD',
  Omr: 'OMR',
  Pab: 'PAB',
  Pen: 'PEN',
  Pgk: 'PGK',
  Php: 'PHP',
  Pkr: 'PKR',
  Pln: 'PLN',
  Pyg: 'PYG',
  Qar: 'QAR',
  Ron: 'RON',
  Rsd: 'RSD',
  Rub: 'RUB',
  Rur: 'RUR',
  Rwf: 'RWF',
  Sar: 'SAR',
  Sbd: 'SBD',
  Scr: 'SCR',
  Sdg: 'SDG',
  Sek: 'SEK',
  Sgd: 'SGD',
  Shp: 'SHP',
  Sll: 'SLL',
  Sos: 'SOS',
  Srd: 'SRD',
  Ssp: 'SSP',
  Std: 'STD',
  Svc: 'SVC',
  Syp: 'SYP',
  Szl: 'SZL',
  Thb: 'THB',
  Tjs: 'TJS',
  Tmt: 'TMT',
  Tnd: 'TND',
  Top: 'TOP',
  Try: 'TRY',
  Ttd: 'TTD',
  Tvd: 'TVD',
  Twd: 'TWD',
  Tzs: 'TZS',
  Uah: 'UAH',
  Ugx: 'UGX',
  Usd: 'USD',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vef: 'VEF',
  Ves: 'VES',
  Vnd: 'VND',
  Vuv: 'VUV',
  Wst: 'WST',
  Xaf: 'XAF',
  Xcd: 'XCD',
  Xof: 'XOF',
  Xpf: 'XPF',
  Yer: 'YER',
  Zar: 'ZAR',
  Zmw: 'ZMW',
} as const;

export type GQCurrencyCode =
  (typeof GQCurrencyCode)[keyof typeof GQCurrencyCode];
export type GQCurrencyOverride = {
  __typename?: 'CurrencyOverride';
  selectedCurrency: Scalars['String']['output'];
};

export type GQCustomEmissionsFactorsSetup = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'CustomEmissionsFactorsSetup';
    id: Scalars['ID']['output'];
  };

export type GQCustomIntensityConfig = {
  __typename?: 'CustomIntensityConfig';
  description: Scalars['String']['output'];
  humanReadable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isSbtValidated: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  quantityType: GQCustomIntensityConfigQuantityType;
  unit: Maybe<Scalars['String']['output']>;
};

export type GQCustomIntensityConfigInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  humanReadable?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isSbtValidated?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quantityType?: InputMaybe<GQCustomIntensityConfigQuantityType>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export const GQCustomIntensityConfigQuantityType = {
  Currency: 'Currency',
  Number: 'Number',
} as const;

export type GQCustomIntensityConfigQuantityType =
  (typeof GQCustomIntensityConfigQuantityType)[keyof typeof GQCustomIntensityConfigQuantityType];
export type GQCustomIntensityDataPoint = {
  customIntensityId: Scalars['String']['output'];
  value: Maybe<Scalars['Float']['output']>;
};

export type GQCustomIntensityMetadata = {
  __typename?: 'CustomIntensityMetadata';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  intensityUnit: Scalars['String']['output'];
  label: Scalars['String']['output'];
  scaleFactor: Scalars['Float']['output'];
  tooltipUnit: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  unitUserFacing: Scalars['String']['output'];
};

export const GQCustomerStatus = {
  Canceled: 'Canceled',
  Completed: 'Completed',
  ConfirmedByCustomer: 'ConfirmedByCustomer',
  ConfirmedByWatershed: 'ConfirmedByWatershed',
  ContractReceived: 'ContractReceived',
  ContractSigned: 'ContractSigned',
  Expired: 'Expired',
  Initiated: 'Initiated',
  InvoicePaid: 'InvoicePaid',
  InvoiceReceived: 'InvoiceReceived',
} as const;

export type GQCustomerStatus =
  (typeof GQCustomerStatus)[keyof typeof GQCustomerStatus];
export type GQCustomerTargetSchema = {
  __typename?: 'CustomerTargetSchema';
  canonicalDatasets: Array<GQCanonicalDataset>;
  datasets: Array<GQDataset>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMd: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latestPublishedVersion: Maybe<GQCustomerTargetSchemaVersion>;
  name: Scalars['String']['output'];
  org: Maybe<GQOrganization>;
  publishedVersions: Array<GQCustomerTargetSchemaVersion>;
  rank: Scalars['Int']['output'];
  schemaId: Maybe<Scalars['ID']['output']>;
  utilityType: Maybe<GQBuildingUtilityType>;
  versions: Array<GQCustomerTargetSchemaVersion>;
};

export type GQCustomerTargetSchemaTransform = {
  __typename?: 'CustomerTargetSchemaTransform';
  businessActivityType: Maybe<GQBusinessActivityType>;
  businessActivityTypeId: Maybe<Scalars['String']['output']>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  runs: Array<GQCustomerTargetSchemaTransformRun>;
  sql: Scalars['String']['output'];
  testCases: Array<GQCustomerTargetSchemaTransformTestCase>;
  transformTarget: GQCustomerTargetSchemaTransformTarget;
};

export const GQCustomerTargetSchemaTransformParentType = {
  CtsVersion: 'CtsVersion',
  UserUpload: 'UserUpload',
} as const;

export type GQCustomerTargetSchemaTransformParentType =
  (typeof GQCustomerTargetSchemaTransformParentType)[keyof typeof GQCustomerTargetSchemaTransformParentType];
export type GQCustomerTargetSchemaTransformRun = {
  __typename?: 'CustomerTargetSchemaTransformRun';
  createdAt: Scalars['DateTime']['output'];
  error: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  input: GQFileMetadata;
  output: Maybe<GQParquetData>;
  runner: GQUser;
  sql: Scalars['String']['output'];
  standardizedOutput: Maybe<GQParquetData>;
  transform: GQCustomerTargetSchemaTransform;
  warnings: Array<GQValidationWarning>;
};

export const GQCustomerTargetSchemaTransformTarget = {
  Bart: 'Bart',
  ReferenceData: 'ReferenceData',
  Utilities: 'Utilities',
} as const;

export type GQCustomerTargetSchemaTransformTarget =
  (typeof GQCustomerTargetSchemaTransformTarget)[keyof typeof GQCustomerTargetSchemaTransformTarget];
export type GQCustomerTargetSchemaTransformTestCase = {
  __typename?: 'CustomerTargetSchemaTransformTestCase';
  expectedOutput: Maybe<GQParquetData>;
  id: Scalars['ID']['output'];
  inputJson: Maybe<Scalars['JSONString']['output']>;
  kind: GQCtsTransformTestKind;
  latestRun: Maybe<GQCustomerTargetSchemaTransformTestRun>;
  name: Scalars['String']['output'];
  transform: GQCustomerTargetSchemaTransform;
};

export type GQCustomerTargetSchemaTransformTestRun = {
  __typename?: 'CustomerTargetSchemaTransformTestRun';
  id: Scalars['ID']['output'];
  status: GQCtsTestRunStatus;
  testDiffResults: Maybe<Scalars['JSONString']['output']>;
  transformRun: GQCustomerTargetSchemaTransformRun;
};

export type GQCustomerTargetSchemaVersion = {
  __typename?: 'CustomerTargetSchemaVersion';
  createdAt: Scalars['DateTime']['output'];
  deprecatedAt: Maybe<Scalars['DateTime']['output']>;
  exampleData: Scalars['JSONString']['output'];
  existsInRegionalDb: Maybe<Scalars['Boolean']['output']>;
  formattingNotesMd: Maybe<Scalars['String']['output']>;
  globalIngestionExampleData: GQIngestionExampleData;
  id: Scalars['ID']['output'];
  lastEditedByUser: GQUser;
  oneSchemaFileFeed: Maybe<GQCtsvOneSchemaFileFeed>;
  orgDatasetExampleData: Array<GQIngestionExampleData>;
  orgSchemaJson: Scalars['JSONString']['output'];
  plannedRemovedAt: Maybe<Scalars['DateTime']['output']>;
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  publishedForTestingAt: Maybe<Scalars['DateTime']['output']>;
  removedAt: Maybe<Scalars['DateTime']['output']>;
  schema: GQCustomerTargetSchema;
  schemaJson: Scalars['JSONString']['output'];
  schemaVersionId: Maybe<Scalars['ID']['output']>;
  semverVersion: Scalars['String']['output'];
  transforms: Array<GQCustomerTargetSchemaTransform>;
  transformsIncludingDeleted: Array<GQCustomerTargetSchemaTransform>;
  unpublishedAt: Maybe<Scalars['DateTime']['output']>;
  versionNotesMd: Scalars['String']['output'];
};

export type GQCustomerTargetSchemaVersionOrgSchemaJsonArgs = {
  buildingId: InputMaybe<Scalars['ID']['input']>;
  datasetId: Scalars['ID']['input'];
  datasourceId: InputMaybe<Scalars['ID']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
};

export type GQDataCollectionEmissionsData = {
  __typename?: 'DataCollectionEmissionsData';
  amountKgco2e: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  surveyStatus: Maybe<GQCompanySurveyStatus>;
};

export const GQDataGridFilterLogicOperator = {
  And: 'And',
  Or: 'Or',
} as const;

export type GQDataGridFilterLogicOperator =
  (typeof GQDataGridFilterLogicOperator)[keyof typeof GQDataGridFilterLogicOperator];
export const GQDataGridFilterOperator = {
  Contains: 'contains',
  EndsWith: 'endsWith',
  Equals: 'equals',
  GreaterThan: 'greaterThan',
  GreaterThanOrEqual: 'greaterThanOrEqual',
  IsAnyOf: 'isAnyOf',
  IsEmpty: 'isEmpty',
  IsNotAnyOf: 'isNotAnyOf',
  IsNotEmpty: 'isNotEmpty',
  LessThan: 'lessThan',
  LessThanOrEqual: 'lessThanOrEqual',
  NotEquals: 'notEquals',
  StartsWith: 'startsWith',
} as const;

export type GQDataGridFilterOperator =
  (typeof GQDataGridFilterOperator)[keyof typeof GQDataGridFilterOperator];
export const GQDataGridSortDirection = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type GQDataGridSortDirection =
  (typeof GQDataGridSortDirection)[keyof typeof GQDataGridSortDirection];
export type GQDataGridSortItem = {
  __typename?: 'DataGridSortItem';
  field: Scalars['String']['output'];
  sort: GQDataGridSortDirection;
};

export type GQDataGridSortItemInput = {
  field: Scalars['String']['input'];
  sort: GQDataGridSortDirection;
};

export type GQDataIssue = GQContextRelationInterface &
  GQIdInterface &
  GQMeasurementTaskObject &
  GQQuestion & {
    __typename?: 'DataIssue';
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    anchor: GQDiscussionAnchorPayload;
    assignee: Maybe<GQUser>;
    comments: GQCommentConnection;
    createdAt: Scalars['DateTime']['output'];
    description: Maybe<Scalars['String']['output']>;
    files: GQFileMetadataConnection;
    id: Scalars['ID']['output'];
    isCustomerInitiated: Scalars['Boolean']['output'];
    isPublished: Scalars['Boolean']['output'];
    linkedObject: Maybe<GQDataIssueLinkedObject>;
    measurementProject: GQMeasurementProject;
    measurementProjectId: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    state: GQDataIssueState;
    tags: Maybe<Array<GQDataIssueTag>>;
    title: Scalars['String']['output'];
  };

export type GQDataIssueCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDataIssueConnection = {
  __typename?: 'DataIssueConnection';
  edges: Array<Maybe<GQDataIssueEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDataIssueEdge = {
  __typename?: 'DataIssueEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataIssue>;
};

export type GQDataIssueLinkedObject =
  | GQFinancialsReviewItem
  | GQUserUploadTask
  | GQValueMappingTask;

export const GQDataIssueState = {
  Done: 'Done',
  NeedsUserResponse: 'NeedsUserResponse',
} as const;

export type GQDataIssueState =
  (typeof GQDataIssueState)[keyof typeof GQDataIssueState];
export const GQDataIssueTag = {
  ConfirmColumnToUse: 'ConfirmColumnToUse',
  ConfirmDataAnomaly: 'ConfirmDataAnomaly',
  ConfirmDataInterpretation: 'ConfirmDataInterpretation',
  ConfirmDataSourceToUse: 'ConfirmDataSourceToUse',
  MissingColumn: 'MissingColumn',
  MissingDataForCategory: 'MissingDataForCategory',
  MissingDataForDateRange: 'MissingDataForDateRange',
  Other: 'Other',
} as const;

export type GQDataIssueTag =
  (typeof GQDataIssueTag)[keyof typeof GQDataIssueTag];
export type GQDataRegistryBatchMigratorRun = {
  __typename?: 'DataRegistryBatchMigratorRun';
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  error: Maybe<Scalars['String']['output']>;
  failureCount: Maybe<Scalars['Int']['output']>;
  filters: Maybe<Scalars['JSONString']['output']>;
  fromSchemaVersion: GQSchemaRegistrySchemaVersion;
  fromSchemaVersionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  region: Maybe<GQRegion>;
  status: Scalars['String']['output'];
  successCount: Maybe<Scalars['Int']['output']>;
  toSchemaVersion: GQSchemaRegistrySchemaVersion;
  toSchemaVersionId: Scalars['ID']['output'];
  workflowId: Maybe<Scalars['String']['output']>;
};

export type GQDataRegistryBatchMigratorRunConnection = {
  __typename?: 'DataRegistryBatchMigratorRunConnection';
  edges: Array<Maybe<GQDataRegistryBatchMigratorRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDataRegistryBatchMigratorRunEdge = {
  __typename?: 'DataRegistryBatchMigratorRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataRegistryBatchMigratorRun>;
};

export const GQDataRegistryDualWriteMode = {
  Blocking: 'Blocking',
  NonBlocking: 'NonBlocking',
} as const;

export type GQDataRegistryDualWriteMode =
  (typeof GQDataRegistryDualWriteMode)[keyof typeof GQDataRegistryDualWriteMode];
export type GQDataRegistryMigrator = {
  __typename?: 'DataRegistryMigrator';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inputSchemaVersionId: Scalars['ID']['output'];
  inputVersion: Maybe<GQSchemaRegistrySchemaVersion>;
  migrationCode: Maybe<Scalars['String']['output']>;
  migratorType: Scalars['String']['output'];
  outputSchemaVersionId: Scalars['ID']['output'];
  outputVersion: Maybe<GQSchemaRegistrySchemaVersion>;
};

export type GQDataRegistryMigratorAndSchema = {
  __typename?: 'DataRegistryMigratorAndSchema';
  migrator: GQDataRegistryMigrator;
  outputSchema: GQSchemaRegistrySchemaVersion;
};

export type GQDataRegistryMigratorConnection = {
  __typename?: 'DataRegistryMigratorConnection';
  edges: Array<Maybe<GQDataRegistryMigratorEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDataRegistryMigratorEdge = {
  __typename?: 'DataRegistryMigratorEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataRegistryMigrator>;
};

export type GQDataRegistryMigratorRun = {
  __typename?: 'DataRegistryMigratorRun';
  batchMigratorRunId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  errorMessage: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inputTable: GQDataRegistryTable;
  inputTableId: Scalars['ID']['output'];
  migrator: GQDataRegistryMigrator;
  migratorId: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  organization: Maybe<GQOrganization>;
  outputTable: Maybe<GQDataRegistryTable>;
  outputTableId: Maybe<Scalars['ID']['output']>;
  status: Scalars['String']['output'];
  workflowId: Maybe<Scalars['String']['output']>;
};

export type GQDataRegistryMigratorRunConnection = {
  __typename?: 'DataRegistryMigratorRunConnection';
  edges: Array<Maybe<GQDataRegistryMigratorRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDataRegistryMigratorRunEdge = {
  __typename?: 'DataRegistryMigratorRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataRegistryMigratorRun>;
};

export type GQDataRegistryMigratorRunFilters = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type GQDataRegistryTable = {
  __typename?: 'DataRegistryTable';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isDraft: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  schemaId: Scalars['ID']['output'];
  schemaVersionId: Scalars['ID']['output'];
};

export type GQDataset = GQContextRelationInterface &
  GQIdInterface &
  GQPermissionObject &
  GQPermissionObjectWithChildren & {
    __typename?: 'Dataset';
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    canonicalDataset: Maybe<GQCanonicalDataset>;
    children: Array<GQDatasource>;
    customExampleData: Maybe<GQTabularData>;
    customPotentialOverlaps: Maybe<Scalars['String']['output']>;
    customUsedFor: Maybe<Scalars['String']['output']>;
    datasources: Array<GQDatasource>;
    explainer: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    instructions: Maybe<GQInstructionsBundle>;
    name: Scalars['String']['output'];
    potentialOverlaps: Maybe<Scalars['String']['output']>;
    usedFor: Maybe<Scalars['String']['output']>;
  };

export type GQDatasetChildrenArgs = {
  search: InputMaybe<Scalars['String']['input']>;
};

export const GQDatasetCadence = {
  Never: 'Never',
  Once: 'Once',
  Quarterly: 'Quarterly',
  Yearly: 'Yearly',
} as const;

export type GQDatasetCadence =
  (typeof GQDatasetCadence)[keyof typeof GQDatasetCadence];
export type GQDatasetConnection = {
  __typename?: 'DatasetConnection';
  edges: Array<Maybe<GQDatasetEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDatasetEdge = {
  __typename?: 'DatasetEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataset>;
};

export type GQDatasetForOrgUnitType = {
  __typename?: 'DatasetForOrgUnitType';
  datasetId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type GQDatasetPayload = {
  __typename?: 'DatasetPayload';
  dataset: GQDataset;
  org: Maybe<GQOrganization>;
};

export const GQDatasetStage = {
  NeedsMapping: 'NeedsMapping',
  NeedsResponse: 'NeedsResponse',
  NeedsUpload: 'NeedsUpload',
  Processing: 'Processing',
  ReadyForFootprint: 'ReadyForFootprint',
} as const;

export type GQDatasetStage =
  (typeof GQDatasetStage)[keyof typeof GQDatasetStage];
export type GQDatasetSummaryFilter = {
  __typename?: 'DatasetSummaryFilter';
  field: Scalars['String']['output'];
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

export type GQDatasetWithStage = GQIdInterface & {
  __typename?: 'DatasetWithStage';
  areAllTasksComplete: Scalars['Boolean']['output'];
  areUploadsComplete: Scalars['Boolean']['output'];
  dataset: Maybe<GQDataset>;
  hasNoApprovedData: Scalars['Boolean']['output'];
  hasUnsupportedBarts: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isNonEmissive: Scalars['Boolean']['output'];
  isUsableInFootprint: Scalars['Boolean']['output'];
  largeUserUploadedFiles: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Please check whether uploads or tasks are complete instead */
  stage: GQDatasetStage;
  taskIds: Array<Scalars['String']['output']>;
};

export type GQDatasource = GQContextRelationInterface &
  GQIdInterface &
  GQPermissionObject & {
    __typename?: 'Datasource';
    activeIntegrationConnection: Maybe<GQIntegrationConnection>;
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    canDelete: Scalars['Boolean']['output'];
    canonicalDatasource: Maybe<GQCanonicalDatasource>;
    customPotentialOverlaps: Maybe<Scalars['String']['output']>;
    customUsedFor: Maybe<Scalars['String']['output']>;
    customerTargetSchemaIds: Maybe<Array<Scalars['ID']['output']>>;
    dataset: GQDataset;
    hasApiAccess: Scalars['Boolean']['output'];
    hasIntegration: Scalars['Boolean']['output'];
    hasIntegrationOption: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    importInstructionsMd: Maybe<Scalars['String']['output']>;
    instructions: Maybe<GQInstructionsBundle>;
    isApi: Maybe<Scalars['Boolean']['output']>;
    isUserConfirmed: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    notesFromWatershedMd: Maybe<Scalars['String']['output']>;
    permissions: Maybe<Array<GQPermissionItem>>;
    potentialOverlaps: Maybe<Scalars['String']['output']>;
    presetCustomTags: Array<GQPresetCustomTag>;
    /** @deprecated Use presetCustomTags instead */
    presetIngestionCustomFields: Array<GQIngestionCustomFieldDatasource>;
    presetOrgUnits: Array<GQPresetOrgUnit>;
    refreshCadence: GQDatasetCadence;
    sharedFiles: GQSharedFileConnection;
    /** @deprecated No longer used */
    summary: Maybe<Array<GQDatasourceSummary>>;
    /** @deprecated No longer used. */
    summaryWithMetadata: Maybe<GQDatasourceSummaryWithMetadata>;
    usedFor: Maybe<Scalars['String']['output']>;
    /** @deprecated Use userCanManageDatasource instead */
    userCanAccessDetails: Maybe<Scalars['Boolean']['output']>;
    userCanApproveDatasource: Maybe<Scalars['Boolean']['output']>;
    userCanManageDatasource: Maybe<Scalars['Boolean']['output']>;
    userUploadTaskForMeasurementProject: GQUserUploadTask;
    userUploadTasks: GQUserUploadTaskConnection;
  };

export type GQDatasourceSharedFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  forUserId: InputMaybe<Scalars['ID']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDatasourceUserUploadTaskForMeasurementProjectArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQDatasourceUserUploadTasksArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDatasourceConnection = {
  __typename?: 'DatasourceConnection';
  edges: Array<GQDatasourceEdge>;
  pageInfo: GQPageInfo;
};

export type GQDatasourceEdge = {
  __typename?: 'DatasourceEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDatasource>;
};

export const GQDatasourceImportState = {
  Complete: 'Complete',
  ConfirmDetails: 'ConfirmDetails',
  DataCleaning: 'DataCleaning',
  HasDataIssues: 'HasDataIssues',
  InProgress: 'InProgress',
  InReview: 'InReview',
  NoTask: 'NoTask',
  NotStarted: 'NotStarted',
} as const;

export type GQDatasourceImportState =
  (typeof GQDatasourceImportState)[keyof typeof GQDatasourceImportState];
export type GQDatasourcePayload = {
  __typename?: 'DatasourcePayload';
  datasource: GQDatasource;
};

export type GQDatasourceSummary = {
  __typename?: 'DatasourceSummary';
  bartActivityType: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQDatasourceSummaryWithMetadata = {
  __typename?: 'DatasourceSummaryWithMetadata';
  id: Scalars['ID']['output'];
  legacyUploads: Array<GQUserUpload>;
  summaries: Array<GQDatasourceSummary>;
  unsupportedUploads: Array<GQUserUpload>;
};

export type GQDatasourceValueMappingRevisionData = {
  __typename?: 'DatasourceValueMappingRevisionData';
  id: Scalars['ID']['output'];
  revisionState: Maybe<GQReferenceDataRevisionState>;
};

export type GQDatasourceValueMappingRule = GQIdInterface & {
  __typename?: 'DatasourceValueMappingRule';
  applicableDatasetKinds: Array<GQCanonicalDatasetKind>;
  /** @deprecated As we start using reverse feature flags, this makes less sense. Instead, encode flagging behavior in getSelfIfPassingFlag */
  featureFlag: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  revisionData: GQDatasourceValueMappingRevisionData;
  /** @deprecated With SSP we won't fetch whole ValueMapping at once */
  valueMapping: Maybe<GQValueMapping>;
  valueMappingConfigId: Scalars['ID']['output'];
  valueMappingTask: GQValueMappingTask;
};

export type GQDateTimeInterval = {
  __typename?: 'DateTimeInterval';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type GQDeleteAllAnswersForReportPayload = {
  __typename?: 'DeleteAllAnswersForReportPayload';
  deleteCount: Scalars['Float']['output'];
};

export type GQDeleteAllFinanceThingsInput = {
  dataFormat: GQOneSchemaDataFormat;
};

export type GQDeleteAllFinanceThingsPayload = {
  __typename?: 'DeleteAllFinanceThingsPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQDeleteAllMarketplaceAllocationInstructionsInput = {
  purchaseLineItemId: Scalars['ID']['input'];
};

export type GQDeleteAllMarketplaceAllocationInstructionsPayload = {
  __typename?: 'DeleteAllMarketplaceAllocationInstructionsPayload';
  allocationInstructions: Array<GQMarketplaceAllocationInstruction>;
};

export type GQDeleteAllReductionsDataInput = {
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQDeleteAllReductionsDataPayload = {
  __typename?: 'DeleteAllReductionsDataPayload';
  message: Scalars['String']['output'];
};

export type GQDeleteAssetCommentInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteAssetCommentPayload = {
  __typename?: 'DeleteAssetCommentPayload';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  id: Scalars['ID']['output'];
};

export type GQDeleteAssetHoldingInput = {
  action: GQFinanceAuditAction;
  id: Scalars['ID']['input'];
};

export type GQDeleteAssetHoldingPayload = {
  __typename?: 'DeleteAssetHoldingPayload';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  deletedFinanceDataFlagIds: Maybe<Array<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQDeleteAssetsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteAssetsPayload = {
  __typename?: 'DeleteAssetsPayload';
  assetCorporateIds: Array<Scalars['ID']['output']>;
  assetGroupIds: Array<Scalars['ID']['output']>;
  assetHoldingIds: Array<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceIds: Array<Scalars['ID']['output']>;
  assetRealEstateIds: Array<Scalars['ID']['output']>;
  assetSovereignBondIds: Array<Scalars['ID']['output']>;
  assetYearIds: Array<Scalars['ID']['output']>;
  emissionsYearIds: Array<Scalars['ID']['output']>;
  footprintEstimateOutputIds: Array<Scalars['ID']['output']>;
  fundHoldingBenchmarkIds: Array<Scalars['ID']['output']>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
  privateDisclosureIds: Array<Scalars['ID']['output']>;
};

export type GQDeleteCalculationTagInput = {
  name: Scalars['String']['input'];
};

export type GQDeleteCalculationTagPayload = {
  __typename?: 'DeleteCalculationTagPayload';
  success: Scalars['Boolean']['output'];
};

export type GQDeleteCanonicalClimateProgramProjectPayload = {
  __typename?: 'DeleteCanonicalClimateProgramProjectPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteCanonicalClimateProgramProjectRequirementDatasetInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCanonicalClimateProgramProjectRequirementGhgpInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCdpColumnMappingsInput = {
  idsToDelete: Array<Scalars['ID']['input']>;
};

export type GQDeleteCdpColumnMappingsPayload = {
  __typename?: 'DeleteCdpColumnMappingsPayload';
  mappings: GQCdpIdMappingsPayload;
};

export type GQDeleteCdpQuestionMappingsInput = {
  idsToDelete: Array<Scalars['ID']['input']>;
};

export type GQDeleteCdpQuestionMappingsPayload = {
  __typename?: 'DeleteCdpQuestionMappingsPayload';
  mappings: GQCdpIdMappingsPayload;
};

export type GQDeleteCommentInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  question: GQDataIssue | GQDiscussion;
};

export type GQDeleteCompanyClimateCommitmentInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCompanyClimateCommitmentPayload = {
  __typename?: 'DeleteCompanyClimateCommitmentPayload';
  disclosure: GQDisclosure;
};

export type GQDeleteCompanyRelationshipInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCompanyRelationshipPayload = {
  __typename?: 'DeleteCompanyRelationshipPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteCompanySourceKeyPayload = {
  __typename?: 'DeleteCompanySourceKeyPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteCtsInput = {
  ctsId: Scalars['ID']['input'];
};

export type GQDeleteCtsPayload = {
  __typename?: 'DeleteCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQDeleteCtsTransformTestCaseInput = {
  ctsTransformTestCaseId: Scalars['ID']['input'];
};

export type GQDeleteCtsTransformTestCasePayload = {
  __typename?: 'DeleteCtsTransformTestCasePayload';
  ctsTransform: GQCustomerTargetSchemaTransform;
};

export type GQDeleteDataIssueInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteDataIssuePayload = {
  __typename?: 'DeleteDataIssuePayload';
  measurementProject: GQMeasurementProject;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQDeleteDataRegistryTablesInput = {
  batchMigratorRunId: Scalars['ID']['input'];
};

export type GQDeleteDataRegistryTablesPayload = {
  __typename?: 'DeleteDataRegistryTablesPayload';
  deletedCount: Scalars['Int']['output'];
};

export type GQDeleteDatasetInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteDatasetPayload = {
  __typename?: 'DeleteDatasetPayload';
  id: Scalars['ID']['output'];
  org: Maybe<GQOrganization>;
};

export type GQDeleteDatasourceAdminPayload = {
  __typename?: 'DeleteDatasourceAdminPayload';
  dataset: GQDataset;
  id: Scalars['ID']['output'];
};

export type GQDeleteDatasourceInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteEacPriceEstimateInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteEacPriceEstimatePayload = {
  __typename?: 'DeleteEacPriceEstimatePayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteEmissionsModelReleasePayload = {
  __typename?: 'DeleteEmissionsModelReleasePayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteEmissionsYearInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteEmissionsYearPayload = {
  __typename?: 'DeleteEmissionsYearPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteEngagementTaskConfigForAdminPayload = {
  __typename?: 'DeleteEngagementTaskConfigForAdminPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteEngagementTasksAndAnswersByCrossOrgModelIdInput = {
  engagementTaskCrossOrgModelId: Scalars['ID']['input'];
};

export type GQDeleteEngagementTasksAndAnswersByCrossOrgModelIdPayload = {
  __typename?: 'DeleteEngagementTasksAndAnswersByCrossOrgModelIdPayload';
  engagementTasks: Array<GQEngagementTask>;
};

export type GQDeleteExternalReportQuestionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteExternalReportQuestionPayload = {
  __typename?: 'DeleteExternalReportQuestionPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteExternalReportTypeWithRevisionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteExternalReportTypeWithRevisionPayload = {
  __typename?: 'DeleteExternalReportTypeWithRevisionPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteFacilitiesSetupTaskInput = {
  taskId: Scalars['ID']['input'];
};

export type GQDeleteFacilitiesSetupTaskPayload = {
  __typename?: 'DeleteFacilitiesSetupTaskPayload';
  measurementProject: GQMeasurementProject;
};

export type GQDeleteFileInput = {
  fileId: Scalars['ID']['input'];
};

export type GQDeleteFilePayload = {
  __typename?: 'DeleteFilePayload';
  datasource: Maybe<GQDatasource>;
  file: Maybe<GQFileMetadata>;
  measurementProject: Maybe<GQMeasurementProject>;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQDeleteFinanceThingsInput = {
  dataFormat: GQOneSchemaDataFormat;
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteFinanceThingsPayload = {
  __typename?: 'DeleteFinanceThingsPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQDeleteFinancialsReviewItemInput = {
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQDeleteFinancialsReviewItemPayload = {
  __typename?: 'DeleteFinancialsReviewItemPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteFootprintTagInput = {
  tagId: Scalars['ID']['input'];
};

export type GQDeleteFootprintTagPayload = {
  __typename?: 'DeleteFootprintTagPayload';
  footprintTag: GQFootprintTag;
};

export type GQDeleteFootprintTestSuiteConfigInput = {
  measurementTestSuiteId: Scalars['ID']['input'];
};

export type GQDeleteFootprintTestSuiteConfigPayload = {
  __typename?: 'DeleteFootprintTestSuiteConfigPayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQDeleteFundsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type GQDeleteFundsPayload = {
  __typename?: 'DeleteFundsPayload';
  ids: Array<Scalars['String']['output']>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQDeleteMarketplaceAllocationInstructionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplaceAllocationInstructionPayload = {
  __typename?: 'DeleteMarketplaceAllocationInstructionPayload';
  allocationInstruction: GQMarketplaceAllocationInstruction;
};

export type GQDeleteMarketplaceDeveloperInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplaceDeveloperPayload = {
  __typename?: 'DeleteMarketplaceDeveloperPayload';
  marketplaceDeveloper: GQMarketplaceDeveloper;
  marketplaceProjects: Array<GQMarketplaceProject>;
};

export type GQDeleteMarketplaceDocumentInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplaceDocumentPayload = {
  __typename?: 'DeleteMarketplaceDocumentPayload';
  document: GQMarketplaceDocument;
};

export type GQDeleteMarketplaceProjectInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplaceProjectPayload = {
  __typename?: 'DeleteMarketplaceProjectPayload';
  marketplaceProject: GQMarketplaceProject;
};

export type GQDeleteMarketplacePurchaseInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplacePurchaseLineItemInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteMarketplacePurchaseLineItemPayload = {
  __typename?: 'DeleteMarketplacePurchaseLineItemPayload';
  marketplacePurchase: GQMarketplacePurchase;
};

export type GQDeleteMarketplacePurchasePayload = {
  __typename?: 'DeleteMarketplacePurchasePayload';
  marketplacePurchase: GQMarketplacePurchase;
};

export type GQDeleteMaterialVariantsForOrgInput = {
  orgId: Scalars['ID']['input'];
};

export type GQDeleteMaterialVariantsForOrgPayload = {
  __typename?: 'DeleteMaterialVariantsForOrgPayload';
  success: Scalars['Boolean']['output'];
};

export type GQDeleteMaterialityAssessmentInput = {
  materialityAssessmentId: Scalars['ID']['input'];
};

export type GQDeleteMaterialityAssessmentPayload = {
  __typename?: 'DeleteMaterialityAssessmentPayload';
  materialityAssessments: Array<GQMaterialityAssessment>;
  success: Scalars['Boolean']['output'];
};

export type GQDeleteMeasurementProjectInput = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQDeleteMeasurementTestRowsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteMeasurementTestRowsPayload = {
  __typename?: 'DeleteMeasurementTestRowsPayload';
  ids: Array<Scalars['ID']['output']>;
};

export type GQDeleteMeasurementTestSuiteBartPayload = {
  __typename?: 'DeleteMeasurementTestSuiteBartPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteMeasurementTestSuitePayload = {
  __typename?: 'DeleteMeasurementTestSuitePayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteOneSchemaTemplateInput = {
  oneSchemaTemplateKey: Scalars['String']['input'];
};

export type GQDeleteOneSchemaTemplatePayload = {
  __typename?: 'DeleteOneSchemaTemplatePayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQDeleteOrganizationInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  id: Scalars['ID']['output'];
};

export type GQDeletePeerCompanyGroupInput = {
  id: Scalars['ID']['input'];
};

export type GQDeletePeerCompanyGroupPayload = {
  __typename?: 'DeletePeerCompanyGroupPayload';
  id: Scalars['ID']['output'];
};

export type GQDeletePlanAdminPayload = {
  __typename?: 'DeletePlanAdminPayload';
  plans: Array<GQPlan>;
};

export type GQDeletePlanTargetInput = {
  id: Scalars['ID']['input'];
};

export type GQDeletePlanTargetPayload = {
  __typename?: 'DeletePlanTargetPayload';
  plan: GQPlan;
};

export type GQDeletePublicDisclosureInput = {
  id: Scalars['ID']['input'];
};

export type GQDeletePublicDisclosurePayload = {
  __typename?: 'DeletePublicDisclosurePayload';
  id: Scalars['ID']['output'];
};

export type GQDeletePublicDisclosureTargetInput = {
  id: Scalars['ID']['input'];
};

export type GQDeletePublicDisclosureTargetPayload = {
  __typename?: 'DeletePublicDisclosureTargetPayload';
  disclosureTarget: GQDisclosureTargetDetails;
};

export type GQDeleteReportAnswerWarningsByValidatorIdInput = {
  validatorId: Scalars['String']['input'];
};

export type GQDeleteReportAnswerWarningsByValidatorIdPayload = {
  __typename?: 'DeleteReportAnswerWarningsByValidatorIdPayload';
  numDeleted: Scalars['Int']['output'];
};

export type GQDeleteReportAnswersPayload = {
  __typename?: 'DeleteReportAnswersPayload';
  deleteCount: Scalars['Float']['output'];
};

export type GQDeleteReportAttachmentInput = {
  itemId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  reportQuestionId: Scalars['ID']['input'];
};

export type GQDeleteReportAttachmentItemInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportAttachmentItemPayload = {
  __typename?: 'DeleteReportAttachmentItemPayload';
  success: Scalars['Boolean']['output'];
};

export type GQDeleteReportAttachmentPayload = {
  __typename?: 'DeleteReportAttachmentPayload';
  success: Scalars['Boolean']['output'];
};

export type GQDeleteReportConfigInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportConfigPayload = {
  __typename?: 'DeleteReportConfigPayload';
  reportConfig: GQReportConfig;
};

export type GQDeleteReportQuestionContainerInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportQuestionContainerPayload = {
  __typename?: 'DeleteReportQuestionContainerPayload';
  container: GQReportQuestionContainer;
  parentId: Scalars['ID']['output'];
};

export type GQDeleteReportQuestionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportQuestionPayload = {
  __typename?: 'DeleteReportQuestionPayload';
  parentId: Scalars['ID']['output'];
  question: GQReportQuestion;
};

export type GQDeleteReportingFrameworkDataPointsInput = {
  dataPointIds: Array<Scalars['ID']['input']>;
};

export type GQDeleteReportingFrameworkDataPointsPayload = {
  __typename?: 'DeleteReportingFrameworkDataPointsPayload';
  dataPointIds: Array<Scalars['ID']['output']>;
};

export type GQDeleteReportingFrameworkInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportingFrameworkPayload = {
  __typename?: 'DeleteReportingFrameworkPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteRoleInput = {
  roleId: Scalars['ID']['input'];
};

export type GQDeleteRolePayload = {
  __typename?: 'DeleteRolePayload';
  orgId: Scalars['ID']['output'];
  roleId: Scalars['ID']['output'];
};

export type GQDeleteSupplierChartInputAdmin = {
  chartId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQDeleteSupplierChartPayloadAdmin = {
  __typename?: 'DeleteSupplierChartPayloadAdmin';
  views: Array<GQSupplierView>;
};

export type GQDeleteTargetInput = {
  targetId: Scalars['ID']['input'];
};

export type GQDeleteTargetPayload = {
  __typename?: 'DeleteTargetPayload';
  org: GQOrganization;
  target: GQTarget;
};

export type GQDeleteTcfdArchetypeRiskInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteUserUploadTaskInput = {
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQDeleteUserUploadTaskPayload = {
  __typename?: 'DeleteUserUploadTaskPayload';
  measurementProject: GQMeasurementProject;
};

export type GQDemoPlatformRun = {
  __typename?: 'DemoPlatformRun';
  config: Scalars['JSONString']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  statusJson: Maybe<Scalars['JSONString']['output']>;
  user: GQUser;
};

export type GQDemoPlatformRunConnection = {
  __typename?: 'DemoPlatformRunConnection';
  edges: Array<Maybe<GQDemoPlatformRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDemoPlatformRunEdge = {
  __typename?: 'DemoPlatformRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDemoPlatformRun>;
};

export type GQDependentQuestionFilter = {
  excludedComputed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQDependentReportItems = {
  __typename?: 'DependentReportItems';
  containerProgresses: Array<GQReportQuestionContainer>;
  containerStatuses: Array<GQReportQuestionContainer>;
  inputs: Array<GQReportQuestion>;
  questionStatuses: Array<GQReportQuestion>;
};

export type GQDetatchOrgCompaniesFromCompanyInput = {
  orgCompanyIds: Array<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
};

export type GQDetatchOrgCompaniesFromCompanyPayload = {
  __typename?: 'DetatchOrgCompaniesFromCompanyPayload';
  organization: GQOrganization;
};

export type GQDiscloseEmissionsExpenseCategories = {
  __typename?: 'DiscloseEmissionsExpenseCategories';
  beaCode: Scalars['String']['output'];
  /** @deprecated This is no longer used */
  inputQuantity: Scalars['Float']['output'];
  /** @deprecated This is no longer used */
  inputUnit: Scalars['String']['output'];
  kgco2e: Scalars['Float']['output'];
};

export type GQDisclosure = {
  __typename?: 'Disclosure';
  climateCommitments: Maybe<
    Array<
      | GQCarbonNeutralCommitment
      | GQCleanEnergyCommitment
      | GQNetZeroCommitment
      | GQScienceBasedTargetCommitment
    >
  >;
  company: Maybe<GQCompany>;
  historicalEmissionsYears: Maybe<Array<GQEmissionsYear>>;
  id: Scalars['ID']['output'];
  initiatives: Maybe<Array<GQDisclosureInitiative>>;
  privateDisclosure: Maybe<GQPrivateDisclosure>;
  publicDisclosure: Maybe<GQPublicDisclosure>;
  qualityScore: Maybe<GQDisclosureQualityScore>;
  targets: Maybe<Array<GQDisclosureTargetDetails>>;
};

export const GQDisclosureCenterMadLibAnnualTurnover = {
  Between36And500Million: 'Between36And500Million',
  LessThan36Million: 'LessThan36Million',
  MoreThan500Million: 'MoreThan500Million',
} as const;

export type GQDisclosureCenterMadLibAnnualTurnover =
  (typeof GQDisclosureCenterMadLibAnnualTurnover)[keyof typeof GQDisclosureCenterMadLibAnnualTurnover];
export const GQDisclosureCenterMadLibCompanyBasedIn = {
  Eu: 'EU',
  Uk: 'UK',
  Us: 'US',
} as const;

export type GQDisclosureCenterMadLibCompanyBasedIn =
  (typeof GQDisclosureCenterMadLibCompanyBasedIn)[keyof typeof GQDisclosureCenterMadLibCompanyBasedIn];
export const GQDisclosureCenterMadLibEmployeeCount = {
  LessThan250: 'LessThan250',
  MoreThan250LessThan499: 'MoreThan250LessThan499',
  MoreThan500: 'MoreThan500',
} as const;

export type GQDisclosureCenterMadLibEmployeeCount =
  (typeof GQDisclosureCenterMadLibEmployeeCount)[keyof typeof GQDisclosureCenterMadLibEmployeeCount];
export type GQDisclosureCenterMadLibInput = {
  annualTurnover: GQDisclosureCenterMadLibAnnualTurnover;
  companyBasedIn: GQDisclosureCenterMadLibCompanyBasedIn;
  employeeCount: GQDisclosureCenterMadLibEmployeeCount;
  isBankingInsurance: Scalars['Boolean']['input'];
  isMoreThan18MillionBalanceSheet: Scalars['Boolean']['input'];
  isPubliclyListed: Scalars['Boolean']['input'];
};

export type GQDisclosureClimateCommitmentFilters = {
  commitmentKind?: InputMaybe<GQCompanyClimateCommitmentKind>;
};

export type GQDisclosureHistoricalYearFilters = {
  allScopes?: InputMaybe<Scalars['Boolean']['input']>;
  includeExpenseCategoryEmissions?: InputMaybe<Scalars['Boolean']['input']>;
  onlyMarketBased?: InputMaybe<Scalars['Boolean']['input']>;
  revenueMustExist?: InputMaybe<Scalars['Boolean']['input']>;
  units?: InputMaybe<GQCompanyEmissionsUnits>;
  withinOrderOfMagnitudeOfNaicsEf?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQDisclosureIdWithReportingYear = {
  disclosureId: Scalars['String']['input'];
  reportingYear: Scalars['Int']['input'];
};

export type GQDisclosureInitiative = GQIdInterface & {
  __typename?: 'DisclosureInitiative';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  emissionsTimeseries: Maybe<GQSimpleTimeseries>;
  emissionsTimeseriesId: Maybe<Scalars['ID']['output']>;
  endDate: Maybe<Scalars['Int']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  filters: Maybe<GQFilterExpressionGroup>;
  id: Scalars['ID']['output'];
  initiativeType: GQDisclosureInitiativeType;
  intensityType: Maybe<GQDisclosureTargetIntensityType>;
  name: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  privateDisclosureId: Scalars['ID']['output'];
  startDate: Maybe<Scalars['Int']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQDisclosureInitiativeFilters = {
  placeholder?: InputMaybe<Scalars['Boolean']['input']>;
};

export const GQDisclosureInitiativeType = {
  AlternativeFuels: 'AlternativeFuels',
  BusinessTravelEfficiency: 'BusinessTravelEfficiency',
  CarbonRemoval: 'CarbonRemoval',
  Circularity: 'Circularity',
  ConductInventory: 'ConductInventory',
  DeforestationPrevention: 'DeforestationPrevention',
  EnergyEfficiency: 'EnergyEfficiency',
  EngageSuppliers: 'EngageSuppliers',
  HeavyTransportEfficiency: 'HeavyTransportEfficiency',
  ManureManagement: 'ManureManagement',
  MeasureFootprint: 'MeasureFootprint',
  Other: 'Other',
  RegenerativeAgriculture: 'RegenerativeAgriculture',
  RenewableEnergy: 'RenewableEnergy',
} as const;

export type GQDisclosureInitiativeType =
  (typeof GQDisclosureInitiativeType)[keyof typeof GQDisclosureInitiativeType];
export type GQDisclosureInput = {
  climateCommitmentFilters?: InputMaybe<GQDisclosureClimateCommitmentFilters>;
  excludeArchivedDisclosures?: InputMaybe<Scalars['Boolean']['input']>;
  historicalYearFilters?: InputMaybe<GQDisclosureHistoricalYearFilters>;
  initiativeFilters?: InputMaybe<GQDisclosureInitiativeFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  privateDisclosureFilters?: InputMaybe<GQPrivateDisclosureInput>;
  publicDisclosureFilters?: InputMaybe<GQPublicDisclosureInput>;
  targetFilters?: InputMaybe<GQDisclosureTargetFilters>;
};

export const GQDisclosureQualityScore = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
  Unusable: 'Unusable',
  VeryHigh: 'VeryHigh',
} as const;

export type GQDisclosureQualityScore =
  (typeof GQDisclosureQualityScore)[keyof typeof GQDisclosureQualityScore];
export type GQDisclosureTargetDetails = GQIdInterface & {
  __typename?: 'DisclosureTargetDetails';
  baseYear: Scalars['YearMonth']['output'];
  companyClimateCommitmentId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  emissionsTarget: GQSimpleTimeseries;
  emissionsTargetId: Scalars['ID']['output'];
  emissionsType: GQDisclosureTargetEmissionsType;
  filters: GQFilterExpressionGroup;
  id: Scalars['ID']['output'];
  intensityType: GQDisclosureTargetIntensityType;
  name: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  privateDisclosureId: Maybe<Scalars['ID']['output']>;
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
  reductionRate: GQDisclosureTargetReductionRate;
  unit: Maybe<Scalars['String']['output']>;
  unitDescription: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const GQDisclosureTargetEmissionsType = {
  GrossEmissions: 'GrossEmissions',
  NetEmissions: 'NetEmissions',
} as const;

export type GQDisclosureTargetEmissionsType =
  (typeof GQDisclosureTargetEmissionsType)[keyof typeof GQDisclosureTargetEmissionsType];
export type GQDisclosureTargetFilters = {
  placeholder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQDisclosureTargetInput = {
  baseYear: Scalars['YearMonth']['input'];
  description: Scalars['String']['input'];
  emissionsType: GQDisclosureTargetEmissionsType;
  filters: GQFilterExpressionGroupInput;
  intensityType: GQDisclosureTargetIntensityType;
  name: Scalars['String']['input'];
  reductionRate: GQDisclosureTargetReductionRate;
  reductionTarget: Scalars['Float']['input'];
  targetYear: Scalars['YearMonth']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  unitDescription?: InputMaybe<Scalars['String']['input']>;
};

export const GQDisclosureTargetIntensityType = {
  Absolute: 'Absolute',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  Revenue: 'Revenue',
  SupplierEngagement: 'SupplierEngagement',
  Unit: 'Unit',
} as const;

export type GQDisclosureTargetIntensityType =
  (typeof GQDisclosureTargetIntensityType)[keyof typeof GQDisclosureTargetIntensityType];
export const GQDisclosureTargetReductionRate = {
  LinearAnnualReduction: 'LinearAnnualReduction',
  Logarithmic: 'Logarithmic',
  YearOverYear: 'YearOverYear',
} as const;

export type GQDisclosureTargetReductionRate =
  (typeof GQDisclosureTargetReductionRate)[keyof typeof GQDisclosureTargetReductionRate];
export const GQDisclosureType = {
  Cdp: 'CDP',
  Estimate: 'Estimate',
  PublicDisclosure: 'PublicDisclosure',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQDisclosureType =
  (typeof GQDisclosureType)[keyof typeof GQDisclosureType];
export type GQDiscussion = GQIdInterface &
  GQQuestion & {
    __typename?: 'Discussion';
    anchor: GQDiscussionAnchorPayload;
    comments: GQCommentConnection;
    createdAt: Scalars['DateTime']['output'];
    description: Maybe<Scalars['String']['output']>;
    files: GQFileMetadataConnection;
    id: Scalars['ID']['output'];
    isCustomerInitiated: Scalars['Boolean']['output'];
    state: GQDataIssueState;
    title: Scalars['String']['output'];
  };

export type GQDiscussionCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDiscussionAnchor = {
  buildingId?: InputMaybe<Scalars['String']['input']>;
  dataApprovalEventId?: InputMaybe<Scalars['String']['input']>;
  financialsReviewItemId?: InputMaybe<Scalars['String']['input']>;
  measurementVerificationItemQuestionId?: InputMaybe<
    Scalars['String']['input']
  >;
  productSection?: InputMaybe<GQDiscussionProductSection>;
  reportQuestionInstance?: InputMaybe<GQDiscussionAnchorReportQuestionInstance>;
  userUploadTaskId?: InputMaybe<Scalars['String']['input']>;
  valueMappingTaskId?: InputMaybe<Scalars['String']['input']>;
};

export type GQDiscussionAnchorPayload = {
  __typename?: 'DiscussionAnchorPayload';
  financialsReviewItemId: Maybe<Scalars['String']['output']>;
  /** @deprecated Remove Feb 21st, 2024 */
  measurementProjectId: Maybe<Scalars['String']['output']>;
  measurementVerificationItemQuestionId: Maybe<Scalars['String']['output']>;
  productSection: Maybe<GQDiscussionProductSection>;
  reportQuestionInstance: Maybe<GQReportQuestionInstancePayload>;
  userUploadTaskId: Maybe<Scalars['String']['output']>;
  valueMappingTaskId: Maybe<Scalars['String']['output']>;
};

export type GQDiscussionAnchorReportQuestionInstance = {
  reportId?: InputMaybe<Scalars['String']['input']>;
  reportQuestionId?: InputMaybe<Scalars['String']['input']>;
};

export const GQDiscussionProductSection = {
  EngagementTaskPortal: 'EngagementTaskPortal',
  Exclusions: 'Exclusions',
  Facilities: 'Facilities',
  Finance: 'Finance',
  FinanceFootprintAssembly: 'FinanceFootprintAssembly',
  FootprintQuestion: 'FootprintQuestion',
  Global: 'Global',
  Recategorization: 'Recategorization',
  SupplyChain: 'SupplyChain',
} as const;

export type GQDiscussionProductSection =
  (typeof GQDiscussionProductSection)[keyof typeof GQDiscussionProductSection];
export const GQDiscussionType = {
  Comment: 'Comment',
  Support: 'Support',
} as const;

export type GQDiscussionType =
  (typeof GQDiscussionType)[keyof typeof GQDiscussionType];
export type GQDocumentParseAttempt = GQIdInterface & {
  __typename?: 'DocumentParseAttempt';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modelType: GQDocumentParseAttemptModelType;
  updatedAt: Scalars['DateTime']['output'];
  userUploadId: Scalars['ID']['output'];
  utilityUsageData: Maybe<GQUtilityUsageData>;
};

export const GQDocumentParseAttemptModelType = {
  HumanReview: 'HumanReview',
  InvoiceParser: 'InvoiceParser',
  UtilityParser: 'UtilityParser',
} as const;

export type GQDocumentParseAttemptModelType =
  (typeof GQDocumentParseAttemptModelType)[keyof typeof GQDocumentParseAttemptModelType];
export const GQDrilldownAggregateKind = {
  Electricity: 'Electricity',
  Emissions: 'Emissions',
  Intensity: 'Intensity',
  LocationBasedEmissions: 'LocationBasedEmissions',
  PercentageEmissions: 'PercentageEmissions',
  PercentageOfCurrentView: 'PercentageOfCurrentView',
} as const;

export type GQDrilldownAggregateKind =
  (typeof GQDrilldownAggregateKind)[keyof typeof GQDrilldownAggregateKind];
export const GQDrilldownInclusionKind = {
  Gross: 'Gross',
  Net: 'Net',
  Removal: 'Removal',
} as const;

export type GQDrilldownInclusionKind =
  (typeof GQDrilldownInclusionKind)[keyof typeof GQDrilldownInclusionKind];
export type GQDrilldownParameters = {
  aggregateKind: GQDrilldownAggregateKind;
  defaultTimeInterval: Maybe<Scalars['YMInterval']['output']>;
  filterExpressions: Maybe<GQFilterExpressionGroupWithNewFilters>;
  footprintKind: Scalars['String']['output'];
  groupBy: Maybe<Array<Scalars['String']['output']>>;
  inclusionKind: GQDrilldownInclusionKind;
  intensityDenominatorName: Maybe<Scalars['String']['output']>;
  timeSeriesKind: GQDrilldownTimeSeriesKind;
};

export type GQDrilldownSavedView = GQDrilldownParameters &
  GQIdInterface & {
    __typename?: 'DrilldownSavedView';
    aggregateKind: GQDrilldownAggregateKind;
    biSavedViewId: Maybe<Scalars['ID']['output']>;
    defaultTimeInterval: Maybe<Scalars['YMInterval']['output']>;
    description: Maybe<Scalars['String']['output']>;
    filterExpressions: Maybe<GQFilterExpressionGroupWithNewFilters>;
    footprintKind: Scalars['String']['output'];
    groupBy: Maybe<Array<Scalars['String']['output']>>;
    hasDataTable: Scalars['Boolean']['output'];
    hasPieChart: Scalars['Boolean']['output'];
    hasTimeSeriesChart: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    inclusionKind: GQDrilldownInclusionKind;
    intensityDenominatorName: Maybe<Scalars['String']['output']>;
    isPrivate: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    searchTerm: Maybe<Scalars['String']['output']>;
    timeSeriesKind: GQDrilldownTimeSeriesKind;
  };

export const GQDrilldownTimeSeriesKind = {
  Monthly: 'Monthly',
  Summary: 'Summary',
  Yearly: 'Yearly',
} as const;

export type GQDrilldownTimeSeriesKind =
  (typeof GQDrilldownTimeSeriesKind)[keyof typeof GQDrilldownTimeSeriesKind];
export const GQDuckAccessory = {
  CowboyHat: 'CowboyHat',
  LuckyHat: 'LuckyHat',
  None: 'None',
  PumpkinHat: 'PumpkinHat',
  WitchHat: 'WitchHat',
} as const;

export type GQDuckAccessory =
  (typeof GQDuckAccessory)[keyof typeof GQDuckAccessory];
export const GQDuckDbInstanceKind = {
  Compute: 'Compute',
  External: 'External',
  ExtraLarge: 'ExtraLarge',
  Internal: 'Internal',
  Local: 'Local',
  Postgres: 'Postgres',
  Spatial: 'Spatial',
  Test: 'Test',
  TestExtraLarge: 'TestExtraLarge',
} as const;

export type GQDuckDbInstanceKind =
  (typeof GQDuckDbInstanceKind)[keyof typeof GQDuckDbInstanceKind];
export type GQDuckHuntLeaderboardEntry = {
  __typename?: 'DuckHuntLeaderboardEntry';
  hunter: GQWatershedEmployee;
  lastFoundAt: Scalars['DateTime']['output'];
  numDucks: Scalars['Int']['output'];
};

export const GQDuckType = {
  MallardF: 'MallardF',
  MallardM: 'MallardM',
  White: 'White',
  Yellow: 'Yellow',
} as const;

export type GQDuckType = (typeof GQDuckType)[keyof typeof GQDuckType];
export type GQDuplicateCtsInput = {
  customerTargetSchemaId: Scalars['ID']['input'];
};

export type GQDuplicateCtsPayload = {
  __typename?: 'DuplicateCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQDuplicateMeasurementTestSuiteInput = {
  id: Scalars['ID']['input'];
  includeExpectations: Scalars['Boolean']['input'];
};

export type GQDuplicateMeasurementTestSuitePayload = {
  __typename?: 'DuplicateMeasurementTestSuitePayload';
  id: Scalars['ID']['output'];
};

export const GQEmReleaseLifecyclePhase = {
  Alpha: 'Alpha',
  Beta: 'Beta',
  Current: 'Current',
  EndOfLife: 'EndOfLife',
  Lts: 'LTS',
  Maintenance: 'Maintenance',
} as const;

export type GQEmReleaseLifecyclePhase =
  (typeof GQEmReleaseLifecyclePhase)[keyof typeof GQEmReleaseLifecyclePhase];
export type GQEacPriceEstimate = GQIdInterface & {
  __typename?: 'EacPriceEstimate';
  alternativeCountries: Array<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  defaultProject: Maybe<GQMarketplaceProject>;
  id: Scalars['ID']['output'];
  minCreditPurchase: Scalars['Int']['output'];
  priceEstimateUsdCents: Scalars['Int']['output'];
  standard: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const GQEdciCompanyGrowthStageOption = {
  Buyout: 'Buyout',
  Growth: 'Growth',
  Venture: 'Venture',
} as const;

export type GQEdciCompanyGrowthStageOption =
  (typeof GQEdciCompanyGrowthStageOption)[keyof typeof GQEdciCompanyGrowthStageOption];
export const GQEdciCompanyStructureOption = {
  Private: 'Private',
  Public: 'Public',
} as const;

export type GQEdciCompanyStructureOption =
  (typeof GQEdciCompanyStructureOption)[keyof typeof GQEdciCompanyStructureOption];
export const GQEdciScope1MethodologyOption = {
  ActivityBased: 'ActivityBased',
  DirectMonitoring: 'DirectMonitoring',
  Other: 'Other',
  SpendBased: 'SpendBased',
} as const;

export type GQEdciScope1MethodologyOption =
  (typeof GQEdciScope1MethodologyOption)[keyof typeof GQEdciScope1MethodologyOption];
export const GQEdciScope2MethodologyOption = {
  LocationBased: 'LocationBased',
  MarketBased: 'MarketBased',
  Other: 'Other',
} as const;

export type GQEdciScope2MethodologyOption =
  (typeof GQEdciScope2MethodologyOption)[keyof typeof GQEdciScope2MethodologyOption];
export const GQEditColumn = {
  DueDate: 'DueDate',
  Status: 'Status',
} as const;

export type GQEditColumn = (typeof GQEditColumn)[keyof typeof GQEditColumn];
export type GQEditEmissionsYearInput = {
  id: Scalars['ID']['input'];
  numEmployees?: InputMaybe<Scalars['Float']['input']>;
  reportingYear: Scalars['Int']['input'];
  revenue?: InputMaybe<Scalars['Float']['input']>;
  revenueCurrency?: InputMaybe<Scalars['String']['input']>;
  scope1?: InputMaybe<Scalars['Float']['input']>;
  scope2Location?: InputMaybe<Scalars['Float']['input']>;
  scope2Market?: InputMaybe<Scalars['Float']['input']>;
  scope3?: InputMaybe<Scalars['Float']['input']>;
  scope301?: InputMaybe<Scalars['Float']['input']>;
  scope302?: InputMaybe<Scalars['Float']['input']>;
  scope303?: InputMaybe<Scalars['Float']['input']>;
  scope304?: InputMaybe<Scalars['Float']['input']>;
  scope305?: InputMaybe<Scalars['Float']['input']>;
  scope306?: InputMaybe<Scalars['Float']['input']>;
  scope307?: InputMaybe<Scalars['Float']['input']>;
  scope308?: InputMaybe<Scalars['Float']['input']>;
  scope309?: InputMaybe<Scalars['Float']['input']>;
  scope310?: InputMaybe<Scalars['Float']['input']>;
  scope311?: InputMaybe<Scalars['Float']['input']>;
  scope312?: InputMaybe<Scalars['Float']['input']>;
  scope313?: InputMaybe<Scalars['Float']['input']>;
  scope314?: InputMaybe<Scalars['Float']['input']>;
  scope315?: InputMaybe<Scalars['Float']['input']>;
  scope316?: InputMaybe<Scalars['Float']['input']>;
  scope317?: InputMaybe<Scalars['Float']['input']>;
  units: GQCompanyEmissionsUnits;
};

export type GQEditEmissionsYearPayload = {
  __typename?: 'EditEmissionsYearPayload';
  emissionsYear: GQEmissionsYear;
};

export type GQEditExternalReportQuestionInput = {
  id: Scalars['ID']['input'];
  identifier: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type GQEditExternalReportQuestionPayload = {
  __typename?: 'EditExternalReportQuestionPayload';
  question: GQExternalReportQuestion;
};

export type GQEditExternalReportTypeWithRevisionInput = {
  id: Scalars['ID']['input'];
  revision: Scalars['String']['input'];
  specUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQEditExternalReportTypeWithRevisionPayload = {
  __typename?: 'EditExternalReportTypeWithRevisionPayload';
  externalReportTypeWithRevision: GQExternalReportTypeWithRevision;
};

export type GQEditMappedExternalReportQuestionsInput = {
  externalReportQuestionId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  identicalQuestionIds: Array<Scalars['ID']['input']>;
  relatedQuestionIds: Array<Scalars['ID']['input']>;
};

export type GQEditMappedExternalReportQuestionsPayload = {
  __typename?: 'EditMappedExternalReportQuestionsPayload';
  mappedExternalReportQuestions1: GQMappedExternalReportQuestions;
  mappedExternalReportQuestions2: GQMappedExternalReportQuestions;
};

export type GQEditPublicDisclosureInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  emissionsYearData?: InputMaybe<GQEditEmissionsYearInput>;
  gsUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  publicUrl: Scalars['String']['input'];
  publishingYear: Scalars['Int']['input'];
  reportType: GQExternalReportType;
  sectionUrls?: InputMaybe<Array<GQCreateExternalReportSectionUrlInput>>;
};

export type GQEditPublicDisclosurePayload = {
  __typename?: 'EditPublicDisclosurePayload';
  publicDisclosure: GQPublicDisclosure;
};

export const GQEditValueType = {
  Date: 'Date',
  String: 'String',
} as const;

export type GQEditValueType =
  (typeof GQEditValueType)[keyof typeof GQEditValueType];
export const GQElectricityType = {
  Clean: 'Clean',
  Grid: 'Grid',
} as const;

export type GQElectricityType =
  (typeof GQElectricityType)[keyof typeof GQElectricityType];
export type GQEmReleaseGlobalFootprintTagInput = {
  globalFootprintTagId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type GQEmailContents = {
  __typename?: 'EmailContents';
  bccs: Maybe<Array<Maybe<GQEmailUser>>>;
  bodyHtml: Scalars['String']['output'];
  ccs: Maybe<Array<GQEmailUser>>;
  recipients: Array<GQEmailUser>;
  sender: GQEmailUser;
  subject: Scalars['String']['output'];
};

export type GQEmailContentsInput = {
  bccs?: InputMaybe<Array<InputMaybe<GQEmailUserInput>>>;
  bodyHtml: Scalars['String']['input'];
  ccs?: InputMaybe<Array<GQEmailUserInput>>;
  recipients: Array<GQEmailUserInput>;
  sender: GQEmailUserInput;
  subject: Scalars['String']['input'];
};

export type GQEmailContentsWithUserRecipient = {
  __typename?: 'EmailContentsWithUserRecipient';
  emailContents: GQEmailContents;
  userId: Scalars['ID']['output'];
};

export const GQEmailRelationshipKind = {
  Bcc: 'Bcc',
  Cc: 'Cc',
  To: 'To',
} as const;

export type GQEmailRelationshipKind =
  (typeof GQEmailRelationshipKind)[keyof typeof GQEmailRelationshipKind];
export type GQEmailSent = {
  __typename?: 'EmailSent';
  clickCount: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fromEmail: Scalars['String']['output'];
  htmlContent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  openCount: Maybe<Scalars['Int']['output']>;
  org: GQOrganization;
  subject: Scalars['String']['output'];
  users: Array<GQEmailSentUser>;
};

export type GQEmailSentConnection = {
  __typename?: 'EmailSentConnection';
  edges: Array<GQEmailSentEdge>;
  pageInfo: GQPageInfo;
};

export type GQEmailSentEdge = {
  __typename?: 'EmailSentEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQEmailSent>;
};

export type GQEmailSentUser = {
  __typename?: 'EmailSentUser';
  id: Scalars['ID']['output'];
  latestEmailStatus: GQEmailStatus;
  recipientEmail: Scalars['String']['output'];
  recipientUser: Maybe<GQUser>;
  /** @deprecated redundant, use recipientUser.id instead */
  recipientUserId: Maybe<Scalars['ID']['output']>;
  relationshipKind: GQEmailRelationshipKind;
};

export const GQEmailStatus = {
  Bounce: 'Bounce',
  Deferred: 'Deferred',
  Delivered: 'Delivered',
  Dropped: 'Dropped',
  Processed: 'Processed',
  Unknown: 'Unknown',
} as const;

export type GQEmailStatus = (typeof GQEmailStatus)[keyof typeof GQEmailStatus];
export type GQEmailUser = {
  __typename?: 'EmailUser';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GQEmailUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GQEmeCalculationSampler = {
  __typename?: 'EmeCalculationSampler';
  samples: Array<GQEmeCalculationSamplerItem>;
};

export type GQEmeCalculationSamplerItem = {
  __typename?: 'EmeCalculationSamplerItem';
  equation: GQEmissionsModelEvalEquation;
  fingerprint: Scalars['String']['output'];
  rowId: Scalars['Int']['output'];
};

export type GQEmeMissingBartValueMetadata = {
  __typename?: 'EmeMissingBartValueMetadata';
  businessActivityTypeName: Scalars['String']['output'];
  column: Scalars['String']['output'];
};

export type GQEmeMissingReferenceValueMetadata = {
  __typename?: 'EmeMissingReferenceValueMetadata';
  column: Scalars['String']['output'];
  revisionId: Scalars['String']['output'];
  rowId: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};

export type GQEmeMissingTraceMetadata = {
  __typename?: 'EmeMissingTraceMetadata';
  missingBartValueMetadata: Array<GQEmeMissingBartValueMetadata>;
  missingReferenceValueCitations: Array<GQEmeMissingReferenceValueMetadata>;
  missingReferenceValueDescriptions: Array<GQEmeMissingReferenceValueMetadata>;
};

export type GQEmissionsByGhgCategoryForFinance = {
  __typename?: 'EmissionsByGhgCategoryForFinance';
  /** @deprecated No longer used, deprecate after 2/21/25 */
  fullIntervalGross: Maybe<Scalars['Float']['output']>;
  topGhgCategories: Array<GQGhgCategorizedEmissionData>;
  /** @deprecated No longer used, deprecate after 2/21/25 */
  topGhgCategoriesWithSubcategories: Array<GQGhgCategorizedEmissionData>;
};

export type GQEmissionsByGhgScope = {
  __typename?: 'EmissionsByGhgScope';
  id: Scalars['ID']['output'];
  isEstimated: Scalars['Boolean']['output'];
  kgco2e: Scalars['Int53']['output'];
  scope: GQGhgScope;
  sourceUrl: Maybe<Scalars['String']['output']>;
  sourceYear: Maybe<Scalars['Int']['output']>;
};

export type GQEmissionsFactorDescription = {
  __typename?: 'EmissionsFactorDescription';
  emissionsFactorId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userVisibleEfName: Scalars['String']['output'];
};

export type GQEmissionsFactorRecordForDashboard = {
  __typename?: 'EmissionsFactorRecordForDashboard';
  assumptionSource: Maybe<GQAssumptionSource>;
  assumptionSourceMemo: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  efActivityType: Maybe<Scalars['String']['output']>;
  efName: Maybe<Scalars['String']['output']>;
  emissionsFactorDescription: Maybe<GQEmissionsFactorDescription>;
  emissionsFactorId: Scalars['ID']['output'];
  endMonth: Scalars['YearMonth']['output'];
  id: Scalars['ID']['output'];
  isCleanPower: Maybe<Scalars['Boolean']['output']>;
  isElectricityMarketEf: Maybe<Scalars['Boolean']['output']>;
  isVendorSpecific: Maybe<Scalars['Boolean']['output']>;
  kgCo2e: Maybe<Scalars['Float']['output']>;
  startMonth: Scalars['YearMonth']['output'];
  supplierDisclosure: Maybe<GQDisclosure>;
  unit: Maybe<Scalars['String']['output']>;
};

export type GQEmissionsFactorRecordForDashboardSupplierDisclosureArgs = {
  opts: GQDisclosureInput;
};

export type GQEmissionsModelActiveDraft = {
  __typename?: 'EmissionsModelActiveDraft';
  activeAuthor: GQWatershedEmployee;
  activeAuthorId: Scalars['ID']['output'];
  autosavedAt: Scalars['DateTime']['output'];
  emissionsModelJson: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  importedDescendants: Array<GQEmissionsModelVersion>;
  isCheckedOut: Scalars['Boolean']['output'];
  matchers: Scalars['JSONString']['output'];
  parameterResolverJson: Maybe<Scalars['JSONString']['output']>;
  releasedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQEmissionsModelAncestor = {
  __typename?: 'EmissionsModelAncestor';
  ancestorForkOriginStableModelId: Maybe<Scalars['ID']['output']>;
  ancestorStableModel: GQEmissionsModelStable;
  relationship: GQEmissionsModelImportRelationship;
  rootIsLatestPublishedVersion: Scalars['Boolean']['output'];
  rootVersionId: Scalars['String']['output'];
};

export type GQEmissionsModelAncestry = {
  __typename?: 'EmissionsModelAncestry';
  ancestors: Array<GQEmissionsModelAncestor>;
};

export type GQEmissionsModelEditorEvalInput = {
  activityRecordJson: Scalars['JSONString']['input'];
  businessActivityTypeName?: InputMaybe<Scalars['String']['input']>;
  emissionsModelJson: Scalars['JSONString']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  parameterResolverJson: Scalars['JSONString']['input'];
  unboundParameterBindings?: InputMaybe<Array<GQUnboundParameterBinding>>;
};

export type GQEmissionsModelEditorEvalPayload = {
  __typename?: 'EmissionsModelEditorEvalPayload';
  emissionsEquations: Array<GQEmissionsModelEvalEquation>;
  resultJson: Scalars['JSONString']['output'];
  warnings: Array<Scalars['String']['output']>;
};

export type GQEmissionsModelEditorResolvedParameters = {
  __typename?: 'EmissionsModelEditorResolvedParameters';
  modelParameters: Scalars['JSONString']['output'];
};

export type GQEmissionsModelEmissionsFactor = {
  __typename?: 'EmissionsModelEmissionsFactor';
  externalDescription: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSupplierSpecific: Maybe<Scalars['Boolean']['output']>;
  supplierDisclosure: Maybe<GQDisclosure>;
  unit: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['Float']['output']>;
};

export type GQEmissionsModelEmissionsFactorSupplierDisclosureArgs = {
  opts: GQDisclosureInput;
};

export type GQEmissionsModelError = {
  __typename?: 'EmissionsModelError';
  msg: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  severity: GQEmissionsModelErrorSeverity;
};

export const GQEmissionsModelErrorSeverity = {
  Error: 'error',
  Warning: 'warning',
} as const;

export type GQEmissionsModelErrorSeverity =
  (typeof GQEmissionsModelErrorSeverity)[keyof typeof GQEmissionsModelErrorSeverity];
export type GQEmissionsModelEvalEquation = {
  __typename?: 'EmissionsModelEvalEquation';
  emissionsVariableName: Scalars['String']['output'];
  trace: Scalars['String']['output'];
  traceDetails: GQTraceDetails;
};

export type GQEmissionsModelEvaluation = GQIdInterface & {
  __typename?: 'EmissionsModelEvaluation';
  activityDataExtractor: Maybe<GQActivityDataExtractor>;
  bartTotalRowCount: Maybe<Scalars['Int']['output']>;
  calculationSampler: Maybe<GQEmeCalculationSampler>;
  createdAt: Scalars['DateTime']['output'];
  emissionsModelVersion: GQEmissionsModelVersion;
  emissionsModelVersionId: Scalars['ID']['output'];
  evaluatedBartId: Maybe<Scalars['ID']['output']>;
  evaluatedEmissionsModelMatcher: Maybe<Scalars['JSONString']['output']>;
  evaluatedEmtaFmdId: Maybe<Scalars['ID']['output']>;
  evaluatedExtendedBart: Maybe<GQExtendedBart>;
  evaluatedExtendedBartId: Maybe<Scalars['ID']['output']>;
  fingerprintCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  missingTraceMetadata: GQEmeMissingTraceMetadata;
  resultFileMetadata: GQFileMetadata;
  resultFileMetadataId: Scalars['String']['output'];
  uniqueBartRowCount: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelForRdv = {
  __typename?: 'EmissionsModelForRdv';
  isArchived: Scalars['Boolean']['output'];
  latestPublishedEmvChangelog: Scalars['String']['output'];
  latestPublishedEmvCreatedAt: Scalars['DateTime']['output'];
  latestPublishedEmvId: Scalars['String']['output'];
  latestPublishedEmvUsesRdv: Scalars['Boolean']['output'];
  stableId: Scalars['ID']['output'];
  stableName: Scalars['String']['output'];
};

export type GQEmissionsModelFork = {
  __typename?: 'EmissionsModelFork';
  createdAt: Scalars['DateTime']['output'];
  forkedParameterResolverId: Scalars['ID']['output'];
  forkedStable: GQEmissionsModelStable;
  forkedStableId: Scalars['ID']['output'];
  forkedVersionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  newStable: GQEmissionsModelStable;
  newStableId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelForkMatcher = {
  __typename?: 'EmissionsModelForkMatcher';
  createdAt: Scalars['DateTime']['output'];
  emissionsModelForkId: Scalars['ID']['output'];
  forkedMatcherId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelForkRelationshipsPayload = {
  __typename?: 'EmissionsModelForkRelationshipsPayload';
  downstreamEntries: Maybe<Array<GQEmissionsModelFork>>;
  upstreamEntry: Maybe<GQEmissionsModelFork>;
};

export type GQEmissionsModelImportRelationship = {
  __typename?: 'EmissionsModelImportRelationship';
  childId: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
};

export type GQEmissionsModelMatcher = {
  __typename?: 'EmissionsModelMatcher';
  bindings: Scalars['JSONString']['output'];
  businessActivityType: Scalars['String']['output'];
  businessActivityTypeId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  emissionsModelStableId: Scalars['ID']['output'];
  filterExpression: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keepBatVersionAtLatest: Scalars['Boolean']['output'];
  kind: GQEmissionsModelMatcherKind;
  orgId: Maybe<Scalars['String']['output']>;
  quantitySpecifier: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelMatcherInput = {
  bindings: Scalars['JSONString']['input'];
  businessActivityType: Scalars['String']['input'];
  businessActivityTypeId?: InputMaybe<Scalars['String']['input']>;
  emissionsModelStableId: Scalars['ID']['input'];
  filterExpression?: InputMaybe<Scalars['String']['input']>;
  keepBatVersionAtLatest: Scalars['Boolean']['input'];
  quantitySpecifier?: InputMaybe<Scalars['String']['input']>;
};

export const GQEmissionsModelMatcherKind = {
  Draft: 'Draft',
  Published: 'Published',
} as const;

export type GQEmissionsModelMatcherKind =
  (typeof GQEmissionsModelMatcherKind)[keyof typeof GQEmissionsModelMatcherKind];
export type GQEmissionsModelRelease = {
  __typename?: 'EmissionsModelRelease';
  businessActivityTypeName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  emissionsModels: Array<GQEmissionsModelStable>;
  externalNotes: Scalars['String']['output'];
  globalFootprintTags: Array<GQGlobalFootprintTag>;
  id: Scalars['ID']['output'];
  internalNotes: Scalars['String']['output'];
  isCustomToUserOrg: Maybe<Scalars['Boolean']['output']>;
  isOrgGated: Scalars['Boolean']['output'];
  latestCompatibleBat: Maybe<GQBusinessActivityType>;
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  lifecyclePhaseHistory: Array<GQEmissionsModelReleaseLifecyclePhase>;
  measurementTestSuites: Array<GQMeasurementTestSuite>;
  org: Maybe<GQOrganization>;
  orgAccess: Array<GQEmissionsModelReleaseOrgAccess>;
  orgId: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
  userAuthorId: Maybe<Scalars['ID']['output']>;
};

export type GQEmissionsModelReleaseByBatName = {
  __typename?: 'EmissionsModelReleaseByBatName';
  batName: Scalars['String']['output'];
  currentLifecyclePhase: Maybe<GQEmReleaseLifecyclePhase>;
  currentLifecyclePhaseUpdateDate: Maybe<Scalars['Date']['output']>;
  lifecyclePhase: Maybe<GQEmReleaseLifecyclePhase>;
  release: GQEmissionsModelRelease;
};

export type GQEmissionsModelReleaseEmissionsModelsStable = {
  __typename?: 'EmissionsModelReleaseEmissionsModelsStable';
  models: Array<GQEmissionsModelStable>;
  release: GQEmissionsModelRelease;
};

export type GQEmissionsModelReleaseIdInput = {
  releaseId: Scalars['ID']['input'];
};

export type GQEmissionsModelReleaseLifecyclePhase = {
  __typename?: 'EmissionsModelReleaseLifecyclePhase';
  id: Scalars['ID']['output'];
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelReleaseOrgAccess = {
  __typename?: 'EmissionsModelReleaseOrgAccess';
  createdAt: Scalars['DateTime']['output'];
  emissionsModelRelease: GQEmissionsModelRelease;
  emissionsModelReleaseId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  org: GQOrganization;
  orgId: Scalars['ID']['output'];
};

export type GQEmissionsModelReleasePayload = {
  __typename?: 'EmissionsModelReleasePayload';
  emissionsModelRelease: GQEmissionsModelRelease;
};

export type GQEmissionsModelResolvedParameters = GQIdInterface & {
  __typename?: 'EmissionsModelResolvedParameters';
  emissionsModelVersionId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modelParameters: Scalars['JSONString']['output'];
};

export type GQEmissionsModelStable = GQIdInterface & {
  __typename?: 'EmissionsModelStable';
  ancestry: GQEmissionsModelAncestry;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isMutable: Scalars['Boolean']['output'];
  kind: GQEmissionsModelStableKind;
  latestPublishedVersion: Maybe<GQEmissionsModelVersion>;
  latestVersion: GQEmissionsModelVersion;
  matchers: Array<GQEmissionsModelMatcher>;
  org: Maybe<GQOrganization>;
  relatedReleases: Array<GQEmissionsModelRelease>;
  releases: Array<GQEmissionsModelRelease>;
  scope: GQEmissionsModelStableScope;
  tags: Array<GQCalculationTag>;
  title: Scalars['String']['output'];
  versions: Maybe<Array<GQEmissionsModelVersion>>;
};

export type GQEmissionsModelStableConnection = {
  __typename?: 'EmissionsModelStableConnection';
  edges: Array<GQEmissionsModelStableEdge>;
  pageInfo: GQPageInfo;
};

export type GQEmissionsModelStableEdge = {
  __typename?: 'EmissionsModelStableEdge';
  cursor: Scalars['String']['output'];
  node: GQEmissionsModelStable;
};

export const GQEmissionsModelStableKind = {
  Core: 'core',
  Customer: 'customer',
} as const;

export type GQEmissionsModelStableKind =
  (typeof GQEmissionsModelStableKind)[keyof typeof GQEmissionsModelStableKind];
export const GQEmissionsModelStableScope = {
  Full: 'full',
  Partial: 'partial',
} as const;

export type GQEmissionsModelStableScope =
  (typeof GQEmissionsModelStableScope)[keyof typeof GQEmissionsModelStableScope];
export type GQEmissionsModelVariableDetails = {
  __typename?: 'EmissionsModelVariableDetails';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emissionsModelEvaluationId: Scalars['String']['output'];
  variableName: Scalars['String']['output'];
};

export type GQEmissionsModelVersion = GQIdInterface & {
  __typename?: 'EmissionsModelVersion';
  activeDraftForVersion: Maybe<GQEmissionsModelActiveDraft>;
  author: Maybe<GQUser>;
  changelog: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  emissionsModelJson: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  importedDescendants: Array<GQEmissionsModelVersion>;
  kind: GQEmissionsModelVersionKind;
  parameterResolver: GQParameterResolver;
  parameterResolverJson: Scalars['JSONString']['output'];
  referenceDataVersions: Array<GQReferenceDataVersion>;
  schemaVersion: Scalars['String']['output'];
  stableId: Scalars['String']['output'];
  stableModel: GQEmissionsModelStable;
  title: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type GQEmissionsModelVersionHistory = {
  __typename?: 'EmissionsModelVersionHistory';
  id: Scalars['ID']['output'];
  records: Array<GQEmissionsModelVersion>;
};

export type GQEmissionsModelVersionHistoryConnection = {
  __typename?: 'EmissionsModelVersionHistoryConnection';
  edges: Array<GQEmissionsModelVersionHistoryEdge>;
  pageInfo: GQPageInfo;
};

export type GQEmissionsModelVersionHistoryEdge = {
  __typename?: 'EmissionsModelVersionHistoryEdge';
  cursor: Scalars['String']['output'];
  node: GQEmissionsModelVersion;
};

export const GQEmissionsModelVersionKind = {
  Draft: 'Draft',
  Published: 'Published',
} as const;

export type GQEmissionsModelVersionKind =
  (typeof GQEmissionsModelVersionKind)[keyof typeof GQEmissionsModelVersionKind];
export const GQEmissionsSource = {
  Cee: 'CEE',
  CompanyNaicsCodeEstimate: 'CompanyNaicsCodeEstimate',
  ExternalReport: 'ExternalReport',
  ManualEntry: 'ManualEntry',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQEmissionsSource =
  (typeof GQEmissionsSource)[keyof typeof GQEmissionsSource];
export type GQEmissionsYear = GQCompanyEmissionsInterfaceV2 &
  GQIdInterface & {
    __typename?: 'EmissionsYear';
    assetCorporateId: Maybe<Scalars['ID']['output']>;
    buildingEstimateOutputId: Maybe<Scalars['ID']['output']>;
    /** @deprecated unused, remove jul23 */
    cleanEnergyNotes: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    currencyConversionRate: Maybe<Scalars['Float']['output']>;
    emissionsByGhgCategory: Maybe<GQEmissionsByGhgCategoryForFinance>;
    emissionsYearSource: GQEmissionsYearSource;
    expenseCategory: Maybe<Scalars['String']['output']>;
    footprintEstimateOutputId: Maybe<Scalars['ID']['output']>;
    id: Scalars['ID']['output'];
    numEmployees: Maybe<Scalars['Float']['output']>;
    orgId: Maybe<Scalars['ID']['output']>;
    percentageCleanEnergy: Maybe<Scalars['Float']['output']>;
    priorEstimateInputs: Maybe<Scalars['JSONString']['output']>;
    privateDisclosureId: Maybe<Scalars['ID']['output']>;
    publicDisclosureId: Maybe<Scalars['ID']['output']>;
    reportingYear: Scalars['Int']['output'];
    revenue: Maybe<Scalars['Float']['output']>;
    revenueCurrency: Maybe<Scalars['String']['output']>;
    revenueUsd: Maybe<Scalars['Float']['output']>;
    scenario: Maybe<Scalars['String']['output']>;
    scope1: Maybe<Scalars['Float']['output']>;
    scope2: Maybe<Scalars['Float']['output']>;
    scope2Location: Maybe<Scalars['Float']['output']>;
    scope2Market: Maybe<Scalars['Float']['output']>;
    scope3: Maybe<Scalars['Float']['output']>;
    scope123RevenueIntensityKgco2e: Maybe<GQExternalReportRevenueIntensityKgco2eByScope>;
    scope301: Maybe<Scalars['Float']['output']>;
    scope302: Maybe<Scalars['Float']['output']>;
    scope303: Maybe<Scalars['Float']['output']>;
    scope304: Maybe<Scalars['Float']['output']>;
    scope305: Maybe<Scalars['Float']['output']>;
    scope306: Maybe<Scalars['Float']['output']>;
    scope307: Maybe<Scalars['Float']['output']>;
    scope308: Maybe<Scalars['Float']['output']>;
    scope309: Maybe<Scalars['Float']['output']>;
    scope310: Maybe<Scalars['Float']['output']>;
    scope311: Maybe<Scalars['Float']['output']>;
    scope312: Maybe<Scalars['Float']['output']>;
    scope313: Maybe<Scalars['Float']['output']>;
    scope314: Maybe<Scalars['Float']['output']>;
    scope315: Maybe<Scalars['Float']['output']>;
    scope316: Maybe<Scalars['Float']['output']>;
    scope317: Maybe<Scalars['Float']['output']>;
    totalMwh: Maybe<Scalars['Int']['output']>;
    units: GQCompanyEmissionsUnits;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQEmissionsYearConnection = {
  __typename?: 'EmissionsYearConnection';
  edges: Array<GQEmissionsYearEdge>;
  pageInfo: GQPageInfo;
};

export type GQEmissionsYearEdge = {
  __typename?: 'EmissionsYearEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQEmissionsYear>;
};

export const GQEmissionsYearScenario = {
  Actual: 'Actual',
  BauForecast: 'BAUForecast',
  Sandbox: 'Sandbox',
} as const;

export type GQEmissionsYearScenario =
  (typeof GQEmissionsYearScenario)[keyof typeof GQEmissionsYearScenario];
export const GQEmissionsYearSource = {
  BuildingEstimationEngine: 'BuildingEstimationEngine',
  CompanyEstimatationEngine: 'CompanyEstimatationEngine',
  ManualEntry: 'ManualEntry',
  PrivateCdp: 'PrivateCdp',
  PublicDisclosure: 'PublicDisclosure',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQEmissionsYearSource =
  (typeof GQEmissionsYearSource)[keyof typeof GQEmissionsYearSource];
export type GQEngagementFunnel = {
  __typename?: 'EngagementFunnel';
  displayOnSupplierDetailsPage: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  steps: Array<GQEngagementFunnelStep>;
};

export type GQEngagementFunnelInput = {
  displayOnSupplierDetailsPage: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  steps: Array<GQEngagementFunnelStepInput>;
};

export type GQEngagementFunnelStep = {
  __typename?: 'EngagementFunnelStep';
  engagementTaskConfigId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sbtStage: Maybe<GQCompanySbtCommitmentStage>;
  stepName: Scalars['String']['output'];
  stepType: GQEngagementFunnelStepType;
  supplierTableColumn: Maybe<GQSupplierTableColumn>;
  supplierTableColumnValues: Maybe<Array<Scalars['String']['output']>>;
};

export type GQEngagementFunnelStepInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  sbtStage?: InputMaybe<GQCompanySbtCommitmentStage>;
  stepName: Scalars['String']['input'];
  stepType: GQEngagementFunnelStepType;
  supplierTableColumnId: Scalars['ID']['input'];
  supplierTableColumnValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

export const GQEngagementFunnelStepType = {
  CustomSupplierTableColumn: 'CustomSupplierTableColumn',
  EmissionsDisclosed: 'EmissionsDisclosed',
  InitiativesDisclosed: 'InitiativesDisclosed',
  NotStarted: 'NotStarted',
  SbtStage: 'SbtStage',
  SupplierLoggedIn: 'SupplierLoggedIn',
  SurveyApproved: 'SurveyApproved',
  SurveyCreated: 'SurveyCreated',
  SurveyInProgress: 'SurveyInProgress',
  SurveySubmitted: 'SurveySubmitted',
  TargetsDisclosed: 'TargetsDisclosed',
} as const;

export type GQEngagementFunnelStepType =
  (typeof GQEngagementFunnelStepType)[keyof typeof GQEngagementFunnelStepType];
export type GQEngagementNotificationSetting = GQIdInterface & {
  __typename?: 'EngagementNotificationSetting';
  companyId: Maybe<Scalars['ID']['output']>;
  engagementTaskId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  shouldNotify: Scalars['Boolean']['output'];
  userId: Scalars['ID']['output'];
};

export type GQEngagementNotificationSettingInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  engagementTaskId?: InputMaybe<Scalars['ID']['input']>;
  shouldNotify: Scalars['Boolean']['input'];
};

export const GQEngagementNotificationSide = {
  MappedCompany: 'MappedCompany',
  RootCustomer: 'RootCustomer',
} as const;

export type GQEngagementNotificationSide =
  (typeof GQEngagementNotificationSide)[keyof typeof GQEngagementNotificationSide];
export type GQEngagementTask = {
  __typename?: 'EngagementTask';
  approvedAt: Maybe<Scalars['DateTime']['output']>;
  comments: Array<GQEngagementTaskComment>;
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  crossOrgModelId: Scalars['String']['output'];
  currentAssignee: GQCompanySurveyAssignee;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  dueDate: Maybe<Scalars['Date']['output']>;
  edits: Array<GQCompanySurveyEdit>;
  engagementTaskBatchId: Maybe<Scalars['ID']['output']>;
  engagementTaskConfig: GQEngagementTaskConfig;
  engagementTaskConfigId: Scalars['ID']['output'];
  expenseCategories: Maybe<Array<GQDiscloseEmissionsExpenseCategories>>;
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  facilityName: Maybe<Scalars['String']['output']>;
  footprintEstimate: Maybe<GQFootprintEstimateOutput>;
  id: Scalars['ID']['output'];
  initialSupplierAssigneeEmail: Maybe<Scalars['String']['output']>;
  latestCeeWorkflowId: Maybe<Scalars['ID']['output']>;
  latestFootprintDisclosureWorkflowId: Maybe<Scalars['ID']['output']>;
  latestPleeWorkflowId: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  proposeTargetEmissionsTarget: Maybe<GQSimpleTimeseries>;
  proposeTargetFilters: Maybe<GQFilterExpressionGroup>;
  proposeTargetIntensityType: Maybe<GQDisclosureTargetIntensityType>;
  reportingYear: Maybe<Scalars['Int']['output']>;
  rootAssignee: Maybe<Scalars['ID']['output']>;
  rootAssigneeDisplayName: Maybe<Scalars['String']['output']>;
  rootCompany: GQCompany;
  rootCompanyId: Scalars['ID']['output'];
  rootOrgId: Scalars['ID']['output'];
  status: GQCompanySurveyStatus;
  statusChangedAt: Maybe<Scalars['DateTime']['output']>;
  submittedAnswers: Array<GQEngagementTaskAnswer>;
  submittedAt: Maybe<Scalars['DateTime']['output']>;
  supplierAssignee: Maybe<Scalars['ID']['output']>;
  supplierFacingDescription: Maybe<Scalars['String']['output']>;
  supplierOrgId: Scalars['ID']['output'];
  surveyKind: GQCompanySurveyKind;
  watershedGeneric: Maybe<Scalars['Boolean']['output']>;
};

export type GQEngagementTaskAnswer = {
  __typename?: 'EngagementTaskAnswer';
  answer: Maybe<Scalars['JSONString']['output']>;
  attachments: Array<GQEngagementTaskAnswerAttachment>;
  id: Scalars['ID']['output'];
  questionIndex: Maybe<Scalars['Int']['output']>;
  questionKey: Scalars['String']['output'];
  skipped: Scalars['Boolean']['output'];
  surveyId: Scalars['ID']['output'];
};

export type GQEngagementTaskAnswerAttachment = {
  __typename?: 'EngagementTaskAnswerAttachment';
  fileMetadata: GQFileMetadata;
  originalFilename: Scalars['String']['output'];
  questionKey: Scalars['String']['output'];
  signedDownloadUrl: Maybe<Scalars['String']['output']>;
};

export type GQEngagementTaskComment = {
  __typename?: 'EngagementTaskComment';
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  userEditorId: Maybe<Scalars['ID']['output']>;
  userEditorName: Maybe<Scalars['String']['output']>;
};

export type GQEngagementTaskConfig = GQIdInterface & {
  __typename?: 'EngagementTaskConfig';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  baseConfig: Maybe<GQEngagementTaskConfig>;
  canDelete: Scalars['Boolean']['output'];
  canDeleteInAdmin: Scalars['Boolean']['output'];
  category: GQSurveyDefinitionCategory;
  configGroup: Maybe<GQEngagementTaskConfigGroup>;
  configHasTaskAssignedToCompany: Scalars['Boolean']['output'];
  containers: Array<GQEngagementTaskContainer>;
  crossOrgModelId: Maybe<Scalars['String']['output']>;
  defaultSupplierFacingDescription: Maybe<Scalars['String']['output']>;
  engagementTaskInputs: Array<GQEngagementTaskInput>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
  order: Scalars['Int']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  rootFacingDescription: Maybe<Scalars['String']['output']>;
  sidebarTitle: Scalars['String']['output'];
  timeNeeded: Maybe<Scalars['Int']['output']>;
  type: Maybe<GQEngagementTaskConfigType>;
};

export type GQEngagementTaskConfigCreateInputForAdmin = {
  category: GQSurveyDefinitionCategory;
  configGroup?: InputMaybe<GQEngagementTaskConfigGroup>;
  defaultSupplierFacingDescription?: InputMaybe<Scalars['String']['input']>;
  engagementTaskInputs: Array<GQEngagementTaskInput>;
  name: Scalars['String']['input'];
  optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
  order: Scalars['Int']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  rootFacingDescription?: InputMaybe<Scalars['String']['input']>;
  rootOrgId?: InputMaybe<Scalars['String']['input']>;
  sidebarTitle: Scalars['String']['input'];
  timeNeeded?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<GQEngagementTaskConfigType>;
};

export const GQEngagementTaskConfigGroup = {
  Finance: 'Finance',
  SupplyChain: 'SupplyChain',
} as const;

export type GQEngagementTaskConfigGroup =
  (typeof GQEngagementTaskConfigGroup)[keyof typeof GQEngagementTaskConfigGroup];
export type GQEngagementTaskConfigInputForAdmin = {
  category: GQSurveyDefinitionCategory;
  configGroup?: InputMaybe<GQEngagementTaskConfigGroup>;
  containers: Array<GQEngagementTaskContainerInputForAdmin>;
  defaultSupplierFacingDescription?: InputMaybe<Scalars['String']['input']>;
  engagementTaskInputs: Array<GQEngagementTaskInput>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
  order: Scalars['Int']['input'];
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rootFacingDescription?: InputMaybe<Scalars['String']['input']>;
  sidebarTitle: Scalars['String']['input'];
  timeNeeded?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<GQEngagementTaskConfigType>;
};

export type GQEngagementTaskConfigPayload = {
  __typename?: 'EngagementTaskConfigPayload';
  engagementTaskConfig: GQEngagementTaskConfig;
};

export const GQEngagementTaskConfigType = {
  DiscloseEmissions: 'DiscloseEmissions',
  DiscloseReductionProjects: 'DiscloseReductionProjects',
  DiscloseTargets: 'DiscloseTargets',
  LearningBusinessCaseForClimateAction: 'LearningBusinessCaseForClimateAction',
  LearningEngageYourTeam: 'LearningEngageYourTeam',
  LearningGettingStartedWithCorporateScienceBasedTargets:
    'LearningGettingStartedWithCorporateScienceBasedTargets',
  LearningLaunchingAClimateProgram: 'LearningLaunchingAClimateProgram',
  LearningMeasuringEmissions: 'LearningMeasuringEmissions',
  LearningScienceBasedTargets: 'LearningScienceBasedTargets',
  SbtCommitment: 'SbtCommitment',
  SbtGhgMeasurement: 'SbtGhgMeasurement',
  SbtProgress: 'SbtProgress',
  SetASpecificTarget: 'SetASpecificTarget',
  ShareFootprint: 'ShareFootprint',
  ShareMeasurementProjectStatus: 'ShareMeasurementProjectStatus',
  SustainabilityScreening: 'SustainabilityScreening',
} as const;

export type GQEngagementTaskConfigType =
  (typeof GQEngagementTaskConfigType)[keyof typeof GQEngagementTaskConfigType];
export type GQEngagementTaskConnection = {
  __typename?: 'EngagementTaskConnection';
  edges: Array<GQEngagementTaskEdge>;
  pageInfo: GQPageInfo;
};

export type GQEngagementTaskContainer = GQIdInterface & {
  __typename?: 'EngagementTaskContainer';
  componentId: Maybe<GQEngagementTaskContainerComponentId>;
  containers: Array<GQEngagementTaskContainer>;
  description: Maybe<Scalars['String']['output']>;
  editable: Scalars['Boolean']['output'];
  eyebrowText: Maybe<Scalars['String']['output']>;
  headerImage: Maybe<GQEngagementTaskImageId>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  questions: Array<GQEngagementTaskQuestion>;
  sidebarStepLabelOverride: Maybe<Scalars['String']['output']>;
  statusJson: Scalars['JSONString']['output'];
};

export const GQEngagementTaskContainerComponentId = {
  EngagementTaskConfirmSection: 'EngagementTaskConfirmSection',
  EngagementTaskDiscloseReductionProjectsIntroSteps:
    'EngagementTaskDiscloseReductionProjectsIntroSteps',
  EngagementTaskDiscloseTargetsIntroSteps:
    'EngagementTaskDiscloseTargetsIntroSteps',
  EngagementTaskEmissionsIntroSteps: 'EngagementTaskEmissionsIntroSteps',
  EngagementTaskExpenseCategoryIntro: 'EngagementTaskExpenseCategoryIntro',
  EngagementTaskFinanceImpBaseYearImage:
    'EngagementTaskFinanceImpBaseYearImage',
  EngagementTaskFinanceImpCaseIntro: 'EngagementTaskFinanceImpCaseIntro',
  EngagementTaskFinanceImpDataQualityScores:
    'EngagementTaskFinanceImpDataQualityScores',
  EngagementTaskFinanceImpDataTables: 'EngagementTaskFinanceImpDataTables',
  EngagementTaskFinanceImpGoals: 'EngagementTaskFinanceImpGoals',
  EngagementTaskFinanceImpScheduleCall: 'EngagementTaskFinanceImpScheduleCall',
  EngagementTaskFinanceImpSection1_4Image:
    'EngagementTaskFinanceImpSection1_4Image',
  EngagementTaskFinanceImpSection3_2Image:
    'EngagementTaskFinanceImpSection3_2Image',
  EngagementTaskFinanceImpSection3_3Image:
    'EngagementTaskFinanceImpSection3_3Image',
  EngagementTaskFinanceImpSection3_6Image:
    'EngagementTaskFinanceImpSection3_6Image',
  EngagementTaskFinanceImpSubmit: 'EngagementTaskFinanceImpSubmit',
  EngagementTaskFinanceUsingImp: 'EngagementTaskFinanceUsingImp',
  EngagementTaskLearningActivatingEmployeesDeepDive:
    'EngagementTaskLearningActivatingEmployeesDeepDive',
  EngagementTaskLearningActivatingEmployeesRecap:
    'EngagementTaskLearningActivatingEmployeesRecap',
  EngagementTaskLearningBuildingSupportDeepDive:
    'EngagementTaskLearningBuildingSupportDeepDive',
  EngagementTaskLearningBuildingSupportRecap:
    'EngagementTaskLearningBuildingSupportRecap',
  EngagementTaskLearningBusinessCaseCompletion:
    'EngagementTaskLearningBusinessCaseCompletion',
  EngagementTaskLearningBusinessCaseIntro:
    'EngagementTaskLearningBusinessCaseIntro',
  EngagementTaskLearningBusinessPartners:
    'EngagementTaskLearningBusinessPartners',
  EngagementTaskLearningCorporateScienceBasedTargetsAfterSettingSbt:
    'EngagementTaskLearningCorporateScienceBasedTargetsAfterSettingSbt',
  EngagementTaskLearningCorporateScienceBasedTargetsCompletion:
    'EngagementTaskLearningCorporateScienceBasedTargetsCompletion',
  EngagementTaskLearningCorporateScienceBasedTargetsEmployeeEngagementStrategy:
    'EngagementTaskLearningCorporateScienceBasedTargetsEmployeeEngagementStrategy',
  EngagementTaskLearningCorporateScienceBasedTargetsFoundationForChange:
    'EngagementTaskLearningCorporateScienceBasedTargetsFoundationForChange',
  EngagementTaskLearningCorporateScienceBasedTargetsIntro:
    'EngagementTaskLearningCorporateScienceBasedTargetsIntro',
  EngagementTaskLearningCorporateScienceBasedTargetsJourneyImage:
    'EngagementTaskLearningCorporateScienceBasedTargetsJourneyImage',
  EngagementTaskLearningCorporateScienceBasedTargetsLearnMoreAboutSbts:
    'EngagementTaskLearningCorporateScienceBasedTargetsLearnMoreAboutSbts',
  EngagementTaskLearningCorporateScienceBasedTargetsMeasureImage:
    'EngagementTaskLearningCorporateScienceBasedTargetsMeasureImage',
  EngagementTaskLearningCorporateScienceBasedTargetsRegulatoryDemands:
    'EngagementTaskLearningCorporateScienceBasedTargetsRegulatoryDemands',
  EngagementTaskLearningCorporateScienceBasedTargetsStartingWithSbt:
    'EngagementTaskLearningCorporateScienceBasedTargetsStartingWithSbt',
  EngagementTaskLearningCorporateScienceBasedTargetsStep1:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep1',
  EngagementTaskLearningCorporateScienceBasedTargetsStep4:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep4',
  EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbts:
    'EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbts',
  EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbtsVmWareOnly:
    'EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbtsVMWareOnly',
  EngagementTaskLearningDeepDives: 'EngagementTaskLearningDeepDives',
  EngagementTaskLearningEmissionsScopes:
    'EngagementTaskLearningEmissionsScopes',
  EngagementTaskLearningEngageYourTeamCompletion:
    'EngagementTaskLearningEngageYourTeamCompletion',
  EngagementTaskLearningEngageYourTeamIntro:
    'EngagementTaskLearningEngageYourTeamIntro',
  EngagementTaskLearningEngagingLeadershipDeepDive:
    'EngagementTaskLearningEngagingLeadershipDeepDive',
  EngagementTaskLearningEngagingLeadershipRecap:
    'EngagementTaskLearningEngagingLeadershipRecap',
  EngagementTaskLearningEngagingPartners:
    'EngagementTaskLearningEngagingPartners',
  EngagementTaskLearningGettingStartedWithSbt:
    'EngagementTaskLearningGettingStartedWithSbt',
  EngagementTaskLearningIdentifyingPartnersRecap:
    'EngagementTaskLearningIdentifyingPartnersRecap',
  EngagementTaskLearningInvestors: 'EngagementTaskLearningInvestors',
  EngagementTaskLearningLaunchingAClimateProgramAmbitionLevel:
    'EngagementTaskLearningLaunchingAClimateProgramAmbitionLevel',
  EngagementTaskLearningLaunchingAClimateProgramCompletion:
    'EngagementTaskLearningLaunchingAClimateProgramCompletion',
  EngagementTaskLearningLaunchingAClimateProgramInsightsIntoAction:
    'EngagementTaskLearningLaunchingAClimateProgramInsightsIntoAction',
  EngagementTaskLearningLaunchingAClimateProgramIntro:
    'EngagementTaskLearningLaunchingAClimateProgramIntro',
  EngagementTaskLearningLaunchingAClimateProgramMandatoryReporting:
    'EngagementTaskLearningLaunchingAClimateProgramMandatoryReporting',
  EngagementTaskLearningLaunchingAClimateProgramMeasurementWork:
    'EngagementTaskLearningLaunchingAClimateProgramMeasurementWork',
  EngagementTaskLearningLaunchingAClimateProgramMeasuringEmissions:
    'EngagementTaskLearningLaunchingAClimateProgramMeasuringEmissions',
  EngagementTaskLearningLaunchingAClimateProgramPartingThoughts:
    'EngagementTaskLearningLaunchingAClimateProgramPartingThoughts',
  EngagementTaskLearningLaunchingAClimateProgramRationale:
    'EngagementTaskLearningLaunchingAClimateProgramRationale',
  EngagementTaskLearningLaunchingAClimateProgramReporting:
    'EngagementTaskLearningLaunchingAClimateProgramReporting',
  EngagementTaskLearningLaunchingAClimateProgramShortTerm:
    'EngagementTaskLearningLaunchingAClimateProgramShortTerm',
  EngagementTaskLearningLaunchingAClimateProgramStrategy:
    'EngagementTaskLearningLaunchingAClimateProgramStrategy',
  EngagementTaskLearningMeasuredAndManaged:
    'EngagementTaskLearningMeasuredAndManaged',
  EngagementTaskLearningMeasurement: 'EngagementTaskLearningMeasurement',
  EngagementTaskLearningMeasuringEmissionsCompletion:
    'EngagementTaskLearningMeasuringEmissionsCompletion',
  EngagementTaskLearningMeasuringEmissionsIntro:
    'EngagementTaskLearningMeasuringEmissionsIntro',
  EngagementTaskLearningRegulations: 'EngagementTaskLearningRegulations',
  EngagementTaskLearningScienceBasedTargetsApproved:
    'EngagementTaskLearningScienceBasedTargetsApproved',
  EngagementTaskLearningScienceBasedTargetsCompletion:
    'EngagementTaskLearningScienceBasedTargetsCompletion',
  EngagementTaskLearningScienceBasedTargetsDefinition1:
    'EngagementTaskLearningScienceBasedTargetsDefinition1',
  EngagementTaskLearningScienceBasedTargetsDefinition2:
    'EngagementTaskLearningScienceBasedTargetsDefinition2',
  EngagementTaskLearningScienceBasedTargetsDisclose:
    'EngagementTaskLearningScienceBasedTargetsDisclose',
  EngagementTaskLearningScienceBasedTargetsExpertsAdvice:
    'EngagementTaskLearningScienceBasedTargetsExpertsAdvice',
  EngagementTaskLearningScienceBasedTargetsFaq:
    'EngagementTaskLearningScienceBasedTargetsFAQ',
  EngagementTaskLearningScienceBasedTargetsIntro:
    'EngagementTaskLearningScienceBasedTargetsIntro',
  EngagementTaskLearningScienceBasedTargetsNextSteps:
    'EngagementTaskLearningScienceBasedTargetsNextSteps',
  EngagementTaskLearningScienceBasedTargetsSubmit:
    'EngagementTaskLearningScienceBasedTargetsSubmit',
  EngagementTaskSbtCommitmentIntroSteps:
    'EngagementTaskSbtCommitmentIntroSteps',
  EngagementTaskSbtGhgMeasurementIntroSteps:
    'EngagementTaskSbtGhgMeasurementIntroSteps',
  EngagementTaskSbtProgressIntroSteps: 'EngagementTaskSbtProgressIntroSteps',
  EngagementTaskSetTargetConfirmSection:
    'EngagementTaskSetTargetConfirmSection',
  EngagementTaskSetTargetIntroSteps: 'EngagementTaskSetTargetIntroSteps',
  EngagementTaskSustainabilityIntroSteps:
    'EngagementTaskSustainabilityIntroSteps',
  EngagementTaskVmWarePlanofRecordIntroSteps:
    'EngagementTaskVMWarePlanofRecordIntroSteps',
} as const;

export type GQEngagementTaskContainerComponentId =
  (typeof GQEngagementTaskContainerComponentId)[keyof typeof GQEngagementTaskContainerComponentId];
export type GQEngagementTaskContainerInputForAdmin = {
  componentId?: InputMaybe<GQEngagementTaskContainerComponentId>;
  containers: Array<GQEngagementTaskSubContainerInputForAdmin>;
  description?: InputMaybe<Scalars['String']['input']>;
  editable: Scalars['Boolean']['input'];
  eyebrowText?: InputMaybe<Scalars['String']['input']>;
  headerImage?: InputMaybe<GQEngagementTaskImageId>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
  questions: Array<GQEngagementTaskQuestionInputForAdmin>;
  sidebarStepLabelOverride?: InputMaybe<Scalars['String']['input']>;
  statusJson?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQEngagementTaskEdge = {
  __typename?: 'EngagementTaskEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQEngagementTask>;
};

export const GQEngagementTaskImageId = {
  BriefcaseBrimmingWithGrass: 'BriefcaseBrimmingWithGrass',
  ClimateBuildingBlocks: 'ClimateBuildingBlocks',
  ClimateCurve: 'ClimateCurve',
  MeasuringGif: 'MeasuringGif',
  Target: 'Target',
  ToolsForClimateChange: 'ToolsForClimateChange',
} as const;

export type GQEngagementTaskImageId =
  (typeof GQEngagementTaskImageId)[keyof typeof GQEngagementTaskImageId];
export const GQEngagementTaskInput = {
  ExpenseCategories: 'expenseCategories',
  FacilityName: 'facilityName',
  ProposeTargetFilters: 'proposeTargetFilters',
  ProposeTargetIntensityType: 'proposeTargetIntensityType',
  ProposeTargetSimpleTimeseries: 'proposeTargetSimpleTimeseries',
  ReportingYear: 'reportingYear',
} as const;

export type GQEngagementTaskInput =
  (typeof GQEngagementTaskInput)[keyof typeof GQEngagementTaskInput];
export type GQEngagementTaskLimitedProfile = {
  __typename?: 'EngagementTaskLimitedProfile';
  id: Scalars['ID']['output'];
  rootCompany: GQCompany;
  rootCompanyId: Scalars['ID']['output'];
};

export type GQEngagementTaskQuestion = GQIdInterface & {
  __typename?: 'EngagementTaskQuestion';
  answerIsPrivate: Scalars['Boolean']['output'];
  componentId: GQEngagementTaskQuestionComponentId;
  deletable: Scalars['Boolean']['output'];
  description: Maybe<Scalars['String']['output']>;
  editable: Scalars['Boolean']['output'];
  engagementTaskConfigId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Maybe<Scalars['String']['output']>;
  questionKey: Scalars['String']['output'];
  statusJson: Scalars['JSONString']['output'];
  videoLink: Maybe<Scalars['String']['output']>;
  whyCollectThis: Maybe<Scalars['String']['output']>;
};

export const GQEngagementTaskQuestionComponentId = {
  EngagementTaskAcceptedProposeTargetInput:
    'EngagementTaskAcceptedProposeTargetInput',
  EngagementTaskAllocateEmissionsMethodInput:
    'EngagementTaskAllocateEmissionsMethodInput',
  EngagementTaskAllocateEmissionsMethodV2Input:
    'EngagementTaskAllocateEmissionsMethodV2Input',
  EngagementTaskCarbonMeasurementPlanInput:
    'EngagementTaskCarbonMeasurementPlanInput',
  EngagementTaskCeeBuildingsInput: 'EngagementTaskCeeBuildingsInput',
  EngagementTaskCeeBusinessAirMilesInput:
    'EngagementTaskCeeBusinessAirMilesInput',
  EngagementTaskCeeBusinessAirSpendCurrencyInput:
    'EngagementTaskCeeBusinessAirSpendCurrencyInput',
  EngagementTaskCeeBusinessAirSpendInput:
    'EngagementTaskCeeBusinessAirSpendInput',
  EngagementTaskCeeBusinessGroundMilesInput:
    'EngagementTaskCeeBusinessGroundMilesInput',
  EngagementTaskCeeCloudInput: 'EngagementTaskCeeCloudInput',
  EngagementTaskCeeCloudProvidersInput: 'EngagementTaskCeeCloudProvidersInput',
  EngagementTaskCeeComputationNonCloudInput:
    'EngagementTaskCeeComputationNonCloudInput',
  EngagementTaskCeeEstimationOutputsInput:
    'EngagementTaskCeeEstimationOutputsInput',
  EngagementTaskCeeOfficeDaysPerMonthInput:
    'EngagementTaskCeeOfficeDaysPerMonthInput',
  EngagementTaskCleanPowerDescriptionInput:
    'EngagementTaskCleanPowerDescriptionInput',
  EngagementTaskCompanyGrowthStageSelectField:
    'EngagementTaskCompanyGrowthStageSelectField',
  EngagementTaskCompanyIndustryInput: 'EngagementTaskCompanyIndustryInput',
  EngagementTaskCompanyStructureSelectField:
    'EngagementTaskCompanyStructureSelectField',
  EngagementTaskCountryAutocompleteField:
    'EngagementTaskCountryAutocompleteField',
  EngagementTaskDecarbonizationPlanSelectField:
    'EngagementTaskDecarbonizationPlanSelectField',
  EngagementTaskDiversityInput: 'EngagementTaskDiversityInput',
  EngagementTaskEmissionAndContextAnswer:
    'EngagementTaskEmissionAndContextAnswer',
  EngagementTaskEmployeeSurveyKindsMultiSelect:
    'EngagementTaskEmployeeSurveyKindsMultiSelect',
  EngagementTaskEvaluateProposedTargetInput:
    'EngagementTaskEvaluateProposedTargetInput',
  EngagementTaskExpenseCategoryEmissionsInput:
    'EngagementTaskExpenseCategoryEmissionsInput',
  EngagementTaskExpenseCategoryShareMethodInput:
    'EngagementTaskExpenseCategoryShareMethodInput',
  EngagementTaskExpensesInput: 'EngagementTaskExpensesInput',
  EngagementTaskFinanceImpAuditSlider: 'EngagementTaskFinanceImpAuditSlider',
  EngagementTaskFinanceImpRecap: 'EngagementTaskFinanceImpRecap',
  EngagementTaskFinanceImpSlider: 'EngagementTaskFinanceImpSlider',
  EngagementTaskFinanceImpValue: 'EngagementTaskFinanceImpValue',
  EngagementTaskFootprintDisclosureOutputs:
    'EngagementTaskFootprintDisclosureOutputs',
  EngagementTaskFootprintShareSingleSelect:
    'EngagementTaskFootprintShareSingleSelect',
  EngagementTaskGhgMethodologySelectField:
    'EngagementTaskGhgMethodologySelectField',
  EngagementTaskHasEnvironmentalTeamInput:
    'EngagementTaskHasEnvironmentalTeamInput',
  EngagementTaskHaveCommittedSbtiTargetsInput:
    'EngagementTaskHaveCommittedSbtiTargetsInput',
  EngagementTaskHaveMeasuredEmissionsInput:
    'EngagementTaskHaveMeasuredEmissionsInput',
  EngagementTaskHaveMeasuredEmissionsSbtGhgV2Input:
    'EngagementTaskHaveMeasuredEmissionsSbtGhgV2Input',
  EngagementTaskHaveMeasuredEmissionsV2Input:
    'EngagementTaskHaveMeasuredEmissionsV2Input',
  EngagementTaskHaveMeasuredEmissionsV2MustMeasureInput:
    'EngagementTaskHaveMeasuredEmissionsV2MustMeasureInput',
  EngagementTaskHelpNeededMeasuringEmissions:
    'EngagementTaskHelpNeededMeasuringEmissions',
  EngagementTaskHelpNeededSettingTargets:
    'EngagementTaskHelpNeededSettingTargets',
  EngagementTaskHelpNeededSustainabilityProgram:
    'EngagementTaskHelpNeededSustainabilityProgram',
  EngagementTaskImportReportInput: 'EngagementTaskImportReportInput',
  EngagementTaskLearningBusinessActivities:
    'EngagementTaskLearningBusinessActivities',
  EngagementTaskLearningCarbonAccounting:
    'EngagementTaskLearningCarbonAccounting',
  EngagementTaskLearningCorporateClimateActionBenefits:
    'EngagementTaskLearningCorporateClimateActionBenefits',
  EngagementTaskLearningCorporateScienceBasedTargetsReduceYourEmissions:
    'EngagementTaskLearningCorporateScienceBasedTargetsReduceYourEmissions',
  EngagementTaskLearningCorporateScienceBasedTargetsStep2:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep2',
  EngagementTaskLearningCorporateScienceBasedTargetsStep3:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep3',
  EngagementTaskLearningEmissionsFactors:
    'EngagementTaskLearningEmissionsFactors',
  EngagementTaskLearningEmployees: 'EngagementTaskLearningEmployees',
  EngagementTaskLearningEngagingEmployeesTactics:
    'EngagementTaskLearningEngagingEmployeesTactics',
  EngagementTaskLearningEngagingLeadershipTactics:
    'EngagementTaskLearningEngagingLeadershipTactics',
  EngagementTaskLearningIdentifyingStakeholders:
    'EngagementTaskLearningIdentifyingStakeholders',
  EngagementTaskLearningLaunchingAClimateProgramAmbitionLevelSpectrum:
    'EngagementTaskLearningLaunchingAClimateProgramAmbitionLevelSpectrum',
  EngagementTaskLearningLaunchingAClimateProgramLongTerm:
    'EngagementTaskLearningLaunchingAClimateProgramLongTerm',
  EngagementTaskLearningLaunchingAClimateProgramSupport:
    'EngagementTaskLearningLaunchingAClimateProgramSupport',
  EngagementTaskLearningLaunchingAClimateProgramVoluntaryReporting:
    'EngagementTaskLearningLaunchingAClimateProgramVoluntaryReporting',
  EngagementTaskLearningMarketTrends: 'EngagementTaskLearningMarketTrends',
  EngagementTaskLearningOperationalCosts:
    'EngagementTaskLearningOperationalCosts',
  EngagementTaskLearningRisk: 'EngagementTaskLearningRisk',
  EngagementTaskLearningScienceBasedTargetsClimateScienceBasics:
    'EngagementTaskLearningScienceBasedTargetsClimateScienceBasics',
  EngagementTaskLearningScienceBasedTargetsDefinition3:
    'EngagementTaskLearningScienceBasedTargetsDefinition3',
  EngagementTaskLearningScienceBasedTargetsDevelopTargetKeyQuestions:
    'EngagementTaskLearningScienceBasedTargetsDevelopTargetKeyQuestions',
  EngagementTaskLearningScienceBasedTargetsNumberOfCompaniesCommitted:
    'EngagementTaskLearningScienceBasedTargetsNumberOfCompaniesCommitted',
  EngagementTaskLearningScopeOne: 'EngagementTaskLearningScopeOne',
  EngagementTaskLearningScopeThree: 'EngagementTaskLearningScopeThree',
  EngagementTaskLearningScopeTwo: 'EngagementTaskLearningScopeTwo',
  EngagementTaskLongTermNetZeroSelectField:
    'EngagementTaskLongTermNetZeroSelectField',
  EngagementTaskMeasurementProjectMultiSelect:
    'EngagementTaskMeasurementProjectMultiSelect',
  EngagementTaskMeasuringEmissionsSpectrum:
    'EngagementTaskMeasuringEmissionsSpectrum',
  EngagementTaskModifyProposedTargetInput:
    'EngagementTaskModifyProposedTargetInput',
  EngagementTaskMonthYearInput: 'EngagementTaskMonthYearInput',
  EngagementTaskNullComponent: 'EngagementTaskNullComponent',
  EngagementTaskNumberField: 'EngagementTaskNumberField',
  EngagementTaskNumberFieldOptional: 'EngagementTaskNumberFieldOptional',
  EngagementTaskNumberFieldWithBooleanSelect:
    'EngagementTaskNumberFieldWithBooleanSelect',
  EngagementTaskParentalLeaveInput: 'EngagementTaskParentalLeaveInput',
  EngagementTaskPercentageNumberField: 'EngagementTaskPercentageNumberField',
  EngagementTaskPleeEstimationOutputsInput:
    'EngagementTaskPleeEstimationOutputsInput',
  EngagementTaskPrimaryIndustryOfOperationsSelectField:
    'EngagementTaskPrimaryIndustryOfOperationsSelectField',
  EngagementTaskPrimarySectorOperationsSelectField:
    'EngagementTaskPrimarySectorOperationsSelectField',
  EngagementTaskProviderVerifiedFootprintInput:
    'EngagementTaskProviderVerifiedFootprintInput',
  EngagementTaskReductionInitiativesInput:
    'EngagementTaskReductionInitiativesInput',
  EngagementTaskReductionTargetsInput: 'EngagementTaskReductionTargetsInput',
  EngagementTaskSasbIndustrySelectField:
    'EngagementTaskSASBIndustrySelectField',
  EngagementTaskSasbSelectField: 'EngagementTaskSASBSelectField',
  EngagementTaskSbtEngagementStatusInput:
    'EngagementTaskSbtEngagementStatusInput',
  EngagementTaskScope1MethodologySelectField:
    'EngagementTaskScope1MethodologySelectField',
  EngagementTaskScope2MethodologySelectField:
    'EngagementTaskScope2MethodologySelectField',
  EngagementTaskScope3ByCategoryInput: 'EngagementTaskScope3ByCategoryInput',
  EngagementTaskScopeBoxInput: 'EngagementTaskScopeBoxInput',
  EngagementTaskSelectCurrencyField: 'EngagementTaskSelectCurrencyField',
  EngagementTaskSfdrUnGlobalCompactComplianceMultiSelect:
    'EngagementTaskSfdrUnGlobalCompactComplianceMultiSelect',
  EngagementTaskSfdrUnGlobalCompactViolationsYesNoMaybeInput:
    'EngagementTaskSfdrUnGlobalCompactViolationsYesNoMaybeInput',
  EngagementTaskShareMethodInput: 'EngagementTaskShareMethodInput',
  EngagementTaskShareMethodV2Input: 'EngagementTaskShareMethodV2Input',
  EngagementTaskShortTermReductionTargetSelectField:
    'EngagementTaskShortTermReductionTargetSelectField',
  EngagementTaskSingleCountryAutocompleteField:
    'EngagementTaskSingleCountryAutocompleteField',
  EngagementTaskSustainabilityCommitmentsInput:
    'EngagementTaskSustainabilityCommitmentsInput',
  EngagementTaskSustainabilityInitiativesWithDetailsInput:
    'EngagementTaskSustainabilityInitiativesWithDetailsInput',
  EngagementTaskTextField: 'EngagementTaskTextField',
  EngagementTaskTextFieldMultiline: 'EngagementTaskTextFieldMultiline',
  EngagementTaskVideo: 'EngagementTaskVideo',
  EngagementTaskYesNoInput: 'EngagementTaskYesNoInput',
  EngagementTaskYesNoMaybeInput: 'EngagementTaskYesNoMaybeInput',
} as const;

export type GQEngagementTaskQuestionComponentId =
  (typeof GQEngagementTaskQuestionComponentId)[keyof typeof GQEngagementTaskQuestionComponentId];
export type GQEngagementTaskQuestionInputForAdmin = {
  answerIsPrivate: Scalars['Boolean']['input'];
  componentId: GQEngagementTaskQuestionComponentId;
  deletable: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  editable: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  questionKey: Scalars['String']['input'];
  statusJson?: InputMaybe<Scalars['JSONString']['input']>;
  videoLink?: InputMaybe<Scalars['String']['input']>;
  whyCollectThis?: InputMaybe<Scalars['String']['input']>;
};

export type GQEngagementTaskQuestionSelectOption = {
  __typename?: 'EngagementTaskQuestionSelectOption';
  componentId: GQEngagementTaskQuestionComponentId;
  description: Maybe<Scalars['String']['output']>;
  engagementTaskConfigId: Scalars['ID']['output'];
  engagementTaskConfigName: Scalars['String']['output'];
  questionKey: Scalars['String']['output'];
  taskConfigsWithQuestion: Array<Scalars['String']['output']>;
};

export type GQEngagementTaskSubContainerInputForAdmin = {
  componentId?: InputMaybe<GQEngagementTaskContainerComponentId>;
  description?: InputMaybe<Scalars['String']['input']>;
  editable: Scalars['Boolean']['input'];
  eyebrowText?: InputMaybe<Scalars['String']['input']>;
  headerImage?: InputMaybe<GQEngagementTaskImageId>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
  questions: Array<GQEngagementTaskQuestionInputForAdmin>;
  sidebarStepLabelOverride?: InputMaybe<Scalars['String']['input']>;
  statusJson?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQEnqueueBackgroundJobInput = {
  args: Scalars['JSONString']['input'];
  kind: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  queueName?: InputMaybe<GQBackgroundJobQueueName>;
};

export type GQEnqueueBackgroundJobPayload = {
  __typename?: 'EnqueueBackgroundJobPayload';
  job: GQBackgroundJob;
};

export type GQEraseReportComponentInput = {
  componentId: Scalars['String']['input'];
};

export type GQEraseReportComponentPayload = {
  __typename?: 'EraseReportComponentPayload';
  affectedOrgs: Array<GQOrganization>;
  affectedReportConfigs: Array<GQReportConfig>;
  affectedReports: Array<GQReport>;
  componentId: Scalars['String']['output'];
  deletedAnswersCount: Scalars['Int']['output'];
  deletedQuestionsCount: Scalars['Int']['output'];
};

export const GQEsgdcGhgMethodologyType = {
  ActivityBased: 'ActivityBased',
  DirectEmissionsOrSupplierBased: 'DirectEmissionsOrSupplierBased',
  Other: 'Other',
  SpendBased: 'SpendBased',
} as const;

export type GQEsgdcGhgMethodologyType =
  (typeof GQEsgdcGhgMethodologyType)[keyof typeof GQEsgdcGhgMethodologyType];
export const GQEstimationMethodology = {
  Ceda: 'CEDA',
  CedaV5: 'CEDA_V5',
  CedaV7: 'CEDA_V7',
  Useeio: 'USEEIO',
  Useeio_2_1: 'USEEIO_2_1',
  Useeio_2_2_22: 'USEEIO_2_2_22',
} as const;

export type GQEstimationMethodology =
  (typeof GQEstimationMethodology)[keyof typeof GQEstimationMethodology];
export const GQEstimationStatus = {
  Estimated: 'Estimated',
  JustEmissions: 'JustEmissions',
  JustHoldings: 'JustHoldings',
  None: 'None',
} as const;

export type GQEstimationStatus =
  (typeof GQEstimationStatus)[keyof typeof GQEstimationStatus];
export type GQExampleTraceByFingerprint = {
  __typename?: 'ExampleTraceByFingerprint';
  emissionsModelEvaluationId: Scalars['String']['output'];
  exampleTrace: Scalars['String']['output'];
  fingerprint: Scalars['String']['output'];
};

export type GQExampleTracesForFingerprints = {
  __typename?: 'ExampleTracesForFingerprints';
  bartColumnDetails: Array<GQBartColumnDetails>;
  emissionsModelVariableDetails: Array<GQEmissionsModelVariableDetails>;
  referenceValueDetails: Array<GQReferenceValueDetails>;
  tracesByFingerprint: Array<Scalars['UntypedData']['output']>;
};

export type GQExportMeasurementTestSuiteBundlePayload = {
  __typename?: 'ExportMeasurementTestSuiteBundlePayload';
  downloadUrl: Scalars['String']['output'];
};

export type GQExportMeasurementTestSuitePayload = {
  __typename?: 'ExportMeasurementTestSuitePayload';
  downloadUrl: Scalars['String']['output'];
};

export type GQExtendedBart = {
  __typename?: 'ExtendedBart';
  bartInstanceId: Scalars['ID']['output'];
  dashboardFileMetadataId: Scalars['ID']['output'];
  extensionKind: Maybe<Scalars['String']['output']>;
};

export type GQExternalReportAnswer = {
  __typename?: 'ExternalReportAnswer';
  answer: Scalars['JSONString']['output'];
  answerType: Scalars['String']['output'];
  externalReportQuestionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  publicDisclosure: GQPublicDisclosure;
  question: GQExternalReportQuestion;
  questionPhrasing: Maybe<Scalars['String']['output']>;
  watershedQualityScore: Maybe<GQDisclosureQualityScore>;
};

export type GQExternalReportAnswerEdge = {
  __typename?: 'ExternalReportAnswerEdge';
  cursor: Scalars['String']['output'];
  node: GQExternalReportAnswer;
};

export type GQExternalReportAnswerSuggestion = {
  __typename?: 'ExternalReportAnswerSuggestion';
  externalReportExtractionRun: GQExternalReportExtractionRun;
  externalReportQuestion: GQExternalReportQuestion;
  id: Scalars['ID']['output'];
  suggestion: Scalars['String']['output'];
  suggestionSource: GQSuggestionSource;
  suggestionSourceType: GQSuggestionSourceType;
};

export type GQExternalReportAnswersConnection = {
  __typename?: 'ExternalReportAnswersConnection';
  edges: Array<Maybe<GQExternalReportAnswerEdge>>;
  pageInfo: GQPageInfo;
};

export type GQExternalReportExtractionRun = {
  __typename?: 'ExternalReportExtractionRun';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  publicDisclosure: GQPublicDisclosure;
};

export type GQExternalReportQuestion = {
  __typename?: 'ExternalReportQuestion';
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  reportQuestionIdentifier: GQReportQuestionIdentifier;
  type: GQExternalReportTypeWithRevision;
};

export type GQExternalReportRevenueIntensityKgco2eByScope = {
  __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
  id: Scalars['ID']['output'];
  scope1: Maybe<Scalars['Float']['output']>;
  scope2Location: Maybe<Scalars['Float']['output']>;
  scope2Market: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
};

export const GQExternalReportSectionKind = {
  E1: 'E1',
  E2: 'E2',
  E3: 'E3',
  E4: 'E4',
  E5: 'E5',
  G1: 'G1',
  Governance: 'Governance',
  Metrics: 'Metrics',
  Risk: 'Risk',
  S1: 'S1',
  S2: 'S2',
  S3: 'S3',
  S4: 'S4',
  Strategy: 'Strategy',
  Targets: 'Targets',
  Taxonomy: 'Taxonomy',
} as const;

export type GQExternalReportSectionKind =
  (typeof GQExternalReportSectionKind)[keyof typeof GQExternalReportSectionKind];
export type GQExternalReportSectionUrl = {
  __typename?: 'ExternalReportSectionUrl';
  id: Scalars['ID']['output'];
  sectionKind: GQExternalReportSectionKind;
  urlFragment: Scalars['String']['output'];
};

export const GQExternalReportType = {
  BCorp: 'BCorp',
  Carb: 'CARB',
  Cdp: 'CDP',
  Csrd: 'CSRD',
  Djsi: 'DJSI',
  Edci: 'EDCI',
  Edgar: 'EDGAR',
  Esg: 'ESG',
  Issb: 'ISSB',
  Impact: 'Impact',
  Msci: 'MSCI',
  Marketing: 'Marketing',
  Other: 'Other',
  Sasb: 'SASB',
  Sb253: 'SB253',
  Sbt: 'SBT',
  Sec: 'SEC',
  Secr: 'SECR',
  Sfdr: 'SFDR',
  Sustainalytics: 'Sustainalytics',
  Tcfd: 'TCFD',
  Unfccc: 'UNFCCC',
  UkTender: 'UkTender',
} as const;

export type GQExternalReportType =
  (typeof GQExternalReportType)[keyof typeof GQExternalReportType];
export type GQExternalReportTypeWithRevision = {
  __typename?: 'ExternalReportTypeWithRevision';
  id: Scalars['ID']['output'];
  questions: Array<GQExternalReportQuestion>;
  revision: Scalars['String']['output'];
  specUrl: Maybe<Scalars['String']['output']>;
  type: GQExternalReportType;
};

export type GQExtractorRun = GQIdInterface &
  GQQueryRunInterface & {
    __typename?: 'ExtractorRun';
    activityDataExtractor: Maybe<GQActivityDataExtractor>;
    createdAt: Scalars['DateTime']['output'];
    error: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    jobError: Maybe<GQSerializableError>;
    state: GQBackgroundJobState;
  };

export type GQFacilitiesFilter = {
  filter: Array<GQFacilitiesFilterInput>;
  filterLogicOperator: GQDataGridFilterLogicOperator;
};

export type GQFacilitiesFilterInput = {
  field: GQFacilityFieldInput;
  operator: GQDataGridFilterOperator;
  value: Array<Scalars['String']['input']>;
};

export type GQFacilitiesMetadata = {
  __typename?: 'FacilitiesMetadata';
  buildingsDataset: Maybe<GQDataset>;
  utilitiesDataset: Maybe<GQDataset>;
};

export type GQFacilitiesPreview = {
  __typename?: 'FacilitiesPreview';
  preview: Array<GQFacility>;
  totalCount: Scalars['Int']['output'];
};

export type GQFacilitiesSetup = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'FacilitiesSetup';
    id: Scalars['ID']['output'];
  };

export type GQFacilitiesSortInput = {
  direction: GQDataGridSortDirection;
  field: GQFacilityFieldInput;
};

export const GQFacilitiesType = {
  Buildings: 'Buildings',
  Utilities: 'Utilities',
} as const;

export type GQFacilitiesType =
  (typeof GQFacilitiesType)[keyof typeof GQFacilitiesType];
export type GQFacilitiesUploadTaskAssociation = {
  __typename?: 'FacilitiesUploadTaskAssociation';
  buildingId: Scalars['String']['output'];
  measurementProjectId: Scalars['String']['output'];
  userUploadTaskId: Scalars['String']['output'];
};

export type GQFacility = GQBuildingInterface &
  GQIdInterface & {
    __typename?: 'Facility';
    buildingKind: Maybe<Scalars['String']['output']>;
    buildingName: Scalars['String']['output'];
    /** @deprecated Use periods instead, removing this soon */
    buildingPeriods: Array<GQBuildingPeriod>;
    buildingSubkind: Maybe<Scalars['String']['output']>;
    buildingUniqueId: Scalars['String']['output'];
    buildingWithUtilities: GQBuildingWithUtilities;
    city: Maybe<Scalars['String']['output']>;
    country: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    editState: GQBuildingEditState;
    endYearMonth: Maybe<Scalars['YearMonth']['output']>;
    history: Maybe<Array<GQBuildingHistory>>;
    id: Scalars['ID']['output'];
    isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
    isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
    /** @deprecated Use lockedRevisionForTask instead. Remove after Nov 11, 2024. */
    latestApprovedVersion: Maybe<GQBuildingForDiff>;
    latitude: Maybe<Scalars['Float']['output']>;
    leaseType: Maybe<GQBuildingLeaseType>;
    lockedRevisionForMeasurementProject: Maybe<GQBuildingHistory>;
    /** @deprecated Use lockedRevisionForMeasurementProject instead. Remove after Nov 14, 2024. */
    lockedRevisionForTask: Maybe<GQBuildingHistory>;
    longitude: Maybe<Scalars['Float']['output']>;
    periods: Array<GQBuildingPeriod>;
    postalCode: Maybe<Scalars['String']['output']>;
    revision: Maybe<Scalars['Int']['output']>;
    sizeUnit: Maybe<GQBuildingSizeUnit>;
    startYearMonth: Maybe<Scalars['YearMonth']['output']>;
    state: Maybe<Scalars['String']['output']>;
    streetAddress: Maybe<Scalars['String']['output']>;
    streetAddress2: Maybe<Scalars['String']['output']>;
    supportingDocuments: Array<GQSupportingDocument>;
    tasks: Array<GQUserUploadTask>;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQFacilityLockedRevisionForMeasurementProjectArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQFacilityLockedRevisionForTaskArgs = {
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
};

export type GQFacilityTasksArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQFacilityConnection = {
  __typename?: 'FacilityConnection';
  edges: Array<Maybe<GQFacilityEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFacilityEdge = {
  __typename?: 'FacilityEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFacility>;
};

export type GQFacilityFieldInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  type: GQFacilityFieldType;
};

export const GQFacilityFieldType = {
  BuildingId: 'BuildingId',
  BuildingIdentifier: 'BuildingIdentifier',
  BuildingKind: 'BuildingKind',
  BuildingTaskName: 'BuildingTaskName',
  City: 'City',
  Completion: 'Completion',
  Country: 'Country',
  CustomField: 'CustomField',
  EndYearMonth: 'EndYearMonth',
  Latitude: 'Latitude',
  LeaseType: 'LeaseType',
  Longitude: 'Longitude',
  OrgUnit: 'OrgUnit',
  Sqft: 'Sqft',
  StartYearMonth: 'StartYearMonth',
  State: 'State',
  StreetAddress: 'StreetAddress',
  UserUploadTask: 'UserUploadTask',
  UtilitiesTaskName: 'UtilitiesTaskName',
} as const;

export type GQFacilityFieldType =
  (typeof GQFacilityFieldType)[keyof typeof GQFacilityFieldType];
export type GQFeatureFlag = {
  __typename?: 'FeatureFlag';
  description: Scalars['String']['output'];
  enabledForAllOrgs: Scalars['Boolean']['output'];
  enabledForOrgs: GQOrgConnection;
  featureTags: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: GQFlags;
  stage: Scalars['String']['output'];
  team: GQTeam;
};

export type GQFeatureFlagConnection = {
  __typename?: 'FeatureFlagConnection';
  edges: Array<Maybe<GQFeatureFlagEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFeatureFlagEdge = {
  __typename?: 'FeatureFlagEdge';
  cursor: Scalars['String']['output'];
  node: GQFeatureFlag;
};

export type GQFeatureFlagsDiff = {
  __typename?: 'FeatureFlagsDiff';
  add: Array<GQFlags>;
  remove: Array<Scalars['String']['output']>;
};

export type GQFetchIntegrationDataInput = {
  activityDataTableId: Scalars['ID']['input'];
};

export type GQFetchIntegrationDataPayload = {
  __typename?: 'FetchIntegrationDataPayload';
  job: GQFetchIntegrationDataWorkflow;
};

export type GQFetchIntegrationDataWorkflow = {
  __typename?: 'FetchIntegrationDataWorkflow';
  id: Scalars['String']['output'];
};

export const GQFileCategory = {
  Admin: 'Admin',
  Automapper: 'Automapper',
  BuildingSurvey: 'BuildingSurvey',
  BusinessActivityRecordTable: 'BusinessActivityRecordTable',
  Cdp: 'CDP',
  DataRegistryTable: 'DataRegistryTable',
  DrilldownExport: 'DrilldownExport',
  EmissionsModel: 'EmissionsModel',
  FileExport: 'FileExport',
  Fixture: 'Fixture',
  GlobalReferenceData: 'GlobalReferenceData',
  Integration: 'Integration',
  MarketplaceCustomer: 'MarketplaceCustomer',
  MeasurementArtifact: 'MeasurementArtifact',
  NetworkedDataTemporaryDataTransfer: 'NetworkedDataTemporaryDataTransfer',
  Public: 'Public',
  RawVendors: 'RawVendors',
  ReferenceData: 'ReferenceData',
  Report: 'Report',
  SAndP: 'SAndP',
  Supplier: 'Supplier',
  SupplierActivity: 'SupplierActivity',
  SupplierAttachment: 'SupplierAttachment',
  SuppliersCache: 'SuppliersCache',
  Support: 'Support',
  System: 'System',
  Table: 'Table',
  TcfdReportUpload: 'TcfdReportUpload',
  User: 'User',
  UserAccessibleSystemData: 'UserAccessibleSystemData',
  UserBackup: 'UserBackup',
} as const;

export type GQFileCategory =
  (typeof GQFileCategory)[keyof typeof GQFileCategory];
export type GQFileMetadata = GQContextRelationInterface &
  GQIdInterface & {
    __typename?: 'FileMetadata';
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    category: GQFileCategory;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    description: Maybe<Scalars['String']['output']>;
    directory: Scalars['String']['output'];
    gcloudStorageUrl: Scalars['String']['output'];
    hidden: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    remotePath: Scalars['String']['output'];
    remoteWritten: Scalars['Boolean']['output'];
    revisionRoot: Maybe<Scalars['ID']['output']>;
    revisionRootFileMetadata: Maybe<GQFileMetadata>;
    signedParquetUrl: Scalars['String']['output'];
    size: Scalars['Int53']['output'];
    uploader: Maybe<GQUser | GQWatershedEmployee>;
    userUploadTask: Maybe<GQUserUploadTask>;
  };

export type GQFileMetadataConnection = {
  __typename?: 'FileMetadataConnection';
  edges: Array<Maybe<GQFileMetadataEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'FileMetadata' and its cursor. */
export type GQFileMetadataEdge = {
  __typename?: 'FileMetadataEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFileMetadata>;
};

export type GQFileMetadataFilterInput = {
  categories?: InputMaybe<Array<GQFileCategory>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export const GQFilterConjunction = {
  AndConjunction: 'andConjunction',
} as const;

export type GQFilterConjunction =
  (typeof GQFilterConjunction)[keyof typeof GQFilterConjunction];
export type GQFilterExpressionGroup = {
  __typename?: 'FilterExpressionGroup';
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitive>;
};

export type GQFilterExpressionGroupInput = {
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitiveInput>;
};

export type GQFilterExpressionGroupWithNewFilters = {
  __typename?: 'FilterExpressionGroupWithNewFilters';
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitiveWithNewFilters>;
};

export type GQFilterExpressionGroupWithNewFiltersInput = {
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitiveWithNewFiltersInput>;
};

export type GQFilterExpressionPrimitive = {
  __typename?: 'FilterExpressionPrimitive';
  field: GQFilterFieldLegacy;
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

export type GQFilterExpressionPrimitiveInput = {
  field: GQFilterFieldLegacy;
  operator: GQFilterOperator;
  value: Array<Scalars['String']['input']>;
};

export type GQFilterExpressionPrimitiveWithNewFilters = {
  __typename?: 'FilterExpressionPrimitiveWithNewFilters';
  field: Scalars['String']['output'];
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

export type GQFilterExpressionPrimitiveWithNewFiltersInput = {
  field: Scalars['String']['input'];
  operator: GQFilterOperator;
  value: Array<Scalars['UntypedData']['input']>;
};

/** Primitive fields map directly to attributes of the footprint. */
export const GQFilterFieldLegacy = {
  BuildingName: 'buildingName',
  BusinessCategory: 'businessCategory',
  BusinessSubcategory: 'businessSubcategory',
  Description: 'description',
  ElectricityType: 'electricityType',
  FootprintKind: 'footprintKind',
  GhgCategoryId: 'ghgCategoryId',
  GhgScope: 'ghgScope',
  Location: 'location',
  LocationCountry: 'locationCountry',
  Product: 'product',
  Vendor: 'vendor',
} as const;

export type GQFilterFieldLegacy =
  (typeof GQFilterFieldLegacy)[keyof typeof GQFilterFieldLegacy];
/**
 * These fields map directly to known attributes of the footprint in the new
 * Measurement Artifacts Interface, in contrast with fields custom to customers.
 * Listed in the same order as the fields in FilterField (but with the new
 * names), with the exception of productMarket - that has been removed.
 */
export const GQFilterFieldWatershed = {
  BuildingName: 'buildingName',
  CategoryId: 'categoryId',
  CompanyId: 'companyId',
  Description: 'description',
  ElectricityType: 'electricityType',
  FootprintKind: 'footprintKind',
  GhgCategoryId: 'ghgCategoryId',
  GhgScope: 'ghgScope',
  InputUnit: 'inputUnit',
  IsSupplierSpecific: 'isSupplierSpecific',
  Location: 'location',
  LocationCity: 'locationCity',
  LocationCountry: 'locationCountry',
  LocationState: 'locationState',
  Product: 'product',
  SubcategoryId: 'subcategoryId',
  Vendor: 'vendor',
} as const;

export type GQFilterFieldWatershed =
  (typeof GQFilterFieldWatershed)[keyof typeof GQFilterFieldWatershed];
export const GQFilterOperator = {
  In: 'in',
  NotIn: 'notIn',
} as const;

export type GQFilterOperator =
  (typeof GQFilterOperator)[keyof typeof GQFilterOperator];
export type GQFilterableReducibleFootprintAggregate = {
  __typename?: 'FilterableReducibleFootprintAggregate';
  footprintRowCategoryIds: Array<Scalars['String']['output']>;
  footprintRowSubcategoryIds: Array<Scalars['String']['output']>;
  ghgCategory: Scalars['String']['output'];
  locationCity: Scalars['String']['output'];
  locationCountry: Scalars['String']['output'];
  locationGrid: Scalars['String']['output'];
  locationState: Scalars['String']['output'];
  quantityKgco2e: Scalars['Float']['output'];
  reductionKgco2e: Scalars['Float']['output'];
};

export type GQFinanceAggregateMetric = {
  __typename?: 'FinanceAggregateMetric';
  additionalInfo: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  isMissingInputs: Scalars['Boolean']['output'];
  metric: GQFinanceMetric;
  name: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  unit: Maybe<Scalars['String']['output']>;
};

export type GQFinanceAggregateMetricRecord = {
  __typename?: 'FinanceAggregateMetricRecord';
  assetId: Scalars['String']['output'];
  assetName: Scalars['String']['output'];
  colorIndex: Scalars['Int']['output'];
  isEstimate: Maybe<Scalars['Boolean']['output']>;
  value: Maybe<GQFinanceAggregateMetric>;
};

export type GQFinanceAnyMetric =
  | GQFinanceAggregateMetric
  | GQFinanceStringMetric;

export type GQFinanceAssetAuditReportOption = {
  __typename?: 'FinanceAssetAuditReportOption';
  emissionsYearId: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type GQFinanceAssetHolding = {
  __typename?: 'FinanceAssetHolding';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetClass: GQFundAssetClass;
  assetYearId: Scalars['ID']['output'];
  attributionFactorOverride: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  currencyConversionRate: Scalars['Float']['output'];
  emissionsYearId: Maybe<Scalars['ID']['output']>;
  fund: Maybe<GQFund>;
  fundId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  outstandingAmountNative: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ1: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ2: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ3: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ4: Maybe<Scalars['Float']['output']>;
  premiumNative: Maybe<Scalars['Float']['output']>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
  year: Maybe<Scalars['Int']['output']>;
  yearOfInitialInvestment: Maybe<Scalars['Int']['output']>;
};

export const GQFinanceAssetIigccStatus = {
  AchievingNetZero: 'AchievingNetZero',
  Aligned: 'Aligned',
  Aligning: 'Aligning',
  CommittedToAligning: 'CommittedToAligning',
  NotAligned: 'NotAligned',
} as const;

export type GQFinanceAssetIigccStatus =
  (typeof GQFinanceAssetIigccStatus)[keyof typeof GQFinanceAssetIigccStatus];
export type GQFinanceAssetYear = {
  __typename?: 'FinanceAssetYear';
  assetCommercialRealEstateId: Maybe<Scalars['String']['output']>;
  assetCorporateId: Maybe<Scalars['String']['output']>;
  assetGroupId: Maybe<Scalars['String']['output']>;
  buildingEstimationErr: Maybe<Scalars['String']['output']>;
  buildingSizeNative: Maybe<Scalars['Int']['output']>;
  cloudSpendNative: Maybe<Scalars['Float']['output']>;
  companyEstimationErr: Maybe<Scalars['String']['output']>;
  currencyCode: GQCurrencyCode;
  currencyConversionRate: Scalars['Float']['output'];
  emissionsYearId: Maybe<Scalars['ID']['output']>;
  estimationStatus: Maybe<Scalars['String']['output']>;
  flightMiles: Maybe<Scalars['Int']['output']>;
  headcount: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  percentEmployeesWfh: Maybe<Scalars['Float']['output']>;
  percentRenewable: Maybe<Scalars['Float']['output']>;
  revenueNative: Maybe<Scalars['Float']['output']>;
  revenueSourceEmissionsYearId: Maybe<Scalars['ID']['output']>;
  scenario: Maybe<Scalars['String']['output']>;
  scope1OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope1OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2LocationOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2LocationOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2MarketOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2MarketOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope3OverrideKind: Maybe<GQScope3OverrideKind>;
  scope3OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope3OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope301OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope302OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope303OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope304OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope305OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope306OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope307OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope308OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope309OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope310OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope311OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope312OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope313OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope314OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope315OverrideTco2e: Maybe<Scalars['Float']['output']>;
  surveyPortalUrl: Maybe<Scalars['String']['output']>;
  surveyStatus: Maybe<GQCompanySurveyStatus>;
  totalMwh: Maybe<Scalars['Float']['output']>;
  valueNative: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type GQFinanceAssetYearNew = {
  __typename?: 'FinanceAssetYearNew';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetCommercialRealEstateId: Maybe<Scalars['ID']['output']>;
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceId: Maybe<Scalars['ID']['output']>;
  assetSovereignBondId: Maybe<Scalars['ID']['output']>;
  buildingEstimationErr: Maybe<Scalars['String']['output']>;
  buildingSizeNative: Maybe<Scalars['Int']['output']>;
  cloudSpendNative: Maybe<Scalars['Float']['output']>;
  committedToNearTermSbtAlignedNetZero: Maybe<Scalars['Boolean']['output']>;
  companyEstimationErr: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  currencyConversionRate: Scalars['Float']['output'];
  demonstratingYoyEmissionsInLineWithNetZero: Maybe<
    Scalars['Boolean']['output']
  >;
  emissionsYear: Maybe<GQEmissionsYear>;
  emissionsYearId: Maybe<Scalars['ID']['output']>;
  estimationMethodology: Maybe<GQEstimationMethodology>;
  flightMiles: Maybe<Scalars['Int']['output']>;
  headcount: Maybe<Scalars['Int']['output']>;
  iciAlignmentOverride: Maybe<GQIciAlignmentStatus>;
  iciAlignmentStatus: Maybe<GQIciAlignmentStatus>;
  id: Scalars['ID']['output'];
  initiatedDecarbonizationPlan: Maybe<Scalars['Boolean']['output']>;
  measuringMaterialScope3Emissions: Maybe<Scalars['Boolean']['output']>;
  measuringScope1And2Emissions: Maybe<Scalars['Boolean']['output']>;
  orgId: Scalars['ID']['output'];
  percentEmployeesWfh: Maybe<Scalars['Float']['output']>;
  percentRenewable: Maybe<Scalars['Float']['output']>;
  revenueNative: Maybe<Scalars['Float']['output']>;
  revenueSourceEmissionsYearId: Maybe<Scalars['ID']['output']>;
  scenario: Maybe<Scalars['String']['output']>;
  scope1OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope1OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2LocationOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2LocationOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2MarketOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2MarketOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope3OverrideKind: Maybe<GQScope3OverrideKind>;
  scope3OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope3OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope301OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope302OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope303OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope304OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope305OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope306OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope307OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope308OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope309OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope310OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope311OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope312OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope313OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope314OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope315OverrideTco2e: Maybe<Scalars['Float']['output']>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  totalMwh: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
  valueNative: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type GQFinanceAssetYearOverYearEdge = {
  __typename?: 'FinanceAssetYearOverYearEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceAssetsYearOverYear = {
  __typename?: 'FinanceAssetsYearOverYear';
  edges: Array<Maybe<GQFinanceAssetYearOverYearEdge>>;
  pageInfo: GQPageInfo;
};

export const GQFinanceAuditAction = {
  ApiRequest: 'APIRequest',
  AssetPageUpdate: 'AssetPageUpdate',
  BulkImport: 'BulkImport',
  BulkUpdateAssetFromFlags: 'BulkUpdateAssetFromFlags',
  CompanyMatchUpdate: 'CompanyMatchUpdate',
  CreateAssetDialog: 'CreateAssetDialog',
  EngagementTask: 'EngagementTask',
  EstimateEmissions: 'EstimateEmissions',
  SidebarUpdate: 'SidebarUpdate',
  WatershedUpdate: 'WatershedUpdate',
} as const;

export type GQFinanceAuditAction =
  (typeof GQFinanceAuditAction)[keyof typeof GQFinanceAuditAction];
export type GQFinanceAuditFileInfoInput = {
  fileMetadataId?: InputMaybe<Scalars['ID']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  financeImportId: Scalars['ID']['input'];
};

export type GQFinanceAuditReportData = {
  emissionsYearId: Scalars['ID']['input'];
  label: Scalars['String']['input'];
};

export type GQFinanceAuditReportForAssetYearInput = {
  assetId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQFinanceAuditReportForAssetYearPayload = {
  __typename?: 'FinanceAuditReportForAssetYearPayload';
  jobId: Scalars['ID']['output'];
};

export type GQFinanceAutomaticReminder = {
  __typename?: 'FinanceAutomaticReminder';
  deadline: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  reminderConfig: Scalars['JSONString']['output'];
  reminderType: GQFinanceReminderType;
};

export type GQFinanceChangelogConnection = {
  __typename?: 'FinanceChangelogConnection';
  edges: Array<Maybe<GQFinanceChangelogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFinanceChangelogEdge = {
  __typename?: 'FinanceChangelogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFinanceChangelogEntry>;
};

export type GQFinanceChangelogEntry = {
  __typename?: 'FinanceChangelogEntry';
  assetId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  eventKind: Scalars['String']['output'];
  fundId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lineItems: Array<GQFinanceChangelogLineItem>;
  objectLabel: Scalars['String']['output'];
  userDisplayName: Scalars['String']['output'];
};

export type GQFinanceChangelogLineItem = {
  __typename?: 'FinanceChangelogLineItem';
  field: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['String']['output']>;
  oldValue: Maybe<Scalars['String']['output']>;
};

export type GQFinanceColumn = {
  __typename?: 'FinanceColumn';
  columnConfiguration: Maybe<
    GQGridColumnConfigurationMultiSelect | GQGridColumnConfigurationSingleSelect
  >;
  columnFormat: GQGridColumnFormat;
  description: Maybe<Scalars['String']['output']>;
  field: Scalars['String']['output'];
  headerName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  width: Maybe<Scalars['Int']['output']>;
};

export type GQFinanceDataFlag = {
  __typename?: 'FinanceDataFlag';
  assetYearId: Maybe<Scalars['ID']['output']>;
  category: GQFinanceDataFlagCategory;
  checkKind: GQFinanceDataFlagCheck;
  columns: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  finSnapAssetId: Maybe<Scalars['ID']['output']>;
  finSnapFundId: Maybe<Scalars['ID']['output']>;
  finSnapHoldingId: Maybe<Scalars['ID']['output']>;
  finSnapYearId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  objectKind: GQFinanceDataFlagObjectKind;
  rowData: Scalars['UntypedData']['output'];
  stepsToResolve: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export const GQFinanceDataFlagCategory = {
  NoFinancedEmissions: 'NoFinancedEmissions',
  NoTotalEmissions: 'NoTotalEmissions',
  PossibleDataImprovement: 'PossibleDataImprovement',
  PossibleDataIssue: 'PossibleDataIssue',
} as const;

export type GQFinanceDataFlagCategory =
  (typeof GQFinanceDataFlagCategory)[keyof typeof GQFinanceDataFlagCategory];
export const GQFinanceDataFlagCheck = {
  AnomalousAssetValue: 'AnomalousAssetValue',
  AnomalousAttributionFactor: 'AnomalousAttributionFactor',
  AnomalousMwhValue: 'AnomalousMwhValue',
  AnomalousOutstandingAmount: 'AnomalousOutstandingAmount',
  AnomalousRevenueMultiple: 'AnomalousRevenueMultiple',
  AnomalousRevenueValue: 'AnomalousRevenueValue',
  AnomalousTotalBuildingArea: 'AnomalousTotalBuildingArea',
  AnomalousYearOverYearAssetValue: 'AnomalousYearOverYearAssetValue',
  AnomalousYearOverYearFinancedEmissions:
    'AnomalousYearOverYearFinancedEmissions',
  AnomalousYearOverYearOutstandingAmount:
    'AnomalousYearOverYearOutstandingAmount',
  AnomalousYearOverYearRevenueValue: 'AnomalousYearOverYearRevenueValue',
  AnomalousYearOverYearTotalEmissions: 'AnomalousYearOverYearTotalEmissions',
  AssetInEmissionsIntensiveIndustryWithEstimatesNoOverrides:
    'AssetInEmissionsIntensiveIndustryWithEstimatesNoOverrides',
  AssetUsingAdjustmentCode: 'AssetUsingAdjustmentCode',
  BottomUpFootprintAvailable: 'BottomUpFootprintAvailable',
  CedaForAluminumSector: 'CedaForAluminumSector',
  CedaForPowerSector: 'CedaForPowerSector',
  CedaForWasteSector: 'CedaForWasteSector',
  ExternalReportAvailable: 'ExternalReportAvailable',
  FundHighEconomicIntensity: 'FundHighEconomicIntensity',
  FundHighWaciScope1And2: 'FundHighWaciScope1And2',
  FundHighWaciScope123: 'FundHighWaciScope123',
  HighQualityCdpReportAvailable: 'HighQualityCdpReportAvailable',
  InvestmentVolumeSignificantPortionOfAssetValue:
    'InvestmentVolumeSignificantPortionOfAssetValue',
  MissingAssetValue: 'MissingAssetValue',
  MissingBuildingKind: 'MissingBuildingKind',
  MissingCountry: 'MissingCountry',
  MissingEmissionsData: 'MissingEmissionsData',
  MissingEstimation: 'MissingEstimation',
  MissingIndustry: 'MissingIndustry',
  MissingInvestmentVolume: 'MissingInvestmentVolume',
  MissingNextYearAnnualData: 'MissingNextYearAnnualData',
  MissingNextYearHoldingData: 'MissingNextYearHoldingData',
  MissingOutstandingAmount: 'MissingOutstandingAmount',
  MissingRevenue: 'MissingRevenue',
  MissingTotalBuildingArea: 'MissingTotalBuildingArea',
  OutstandingAmountSignificantPortionOfAssetValue:
    'OutstandingAmountSignificantPortionOfAssetValue',
  Scope2MarketOverrideGreaterThanLocation:
    'Scope2MarketOverrideGreaterThanLocation',
  Scope3TotalOverrideZero: 'Scope3TotalOverrideZero',
  WatershedMeasurementAvailable: 'WatershedMeasurementAvailable',
} as const;

export type GQFinanceDataFlagCheck =
  (typeof GQFinanceDataFlagCheck)[keyof typeof GQFinanceDataFlagCheck];
export const GQFinanceDataFlagObjectKind = {
  Asset: 'Asset',
  AssetCorporate: 'AssetCorporate',
  AssetHolding: 'AssetHolding',
  AssetRealEstate: 'AssetRealEstate',
  AssetYear: 'AssetYear',
  Fund: 'Fund',
} as const;

export type GQFinanceDataFlagObjectKind =
  (typeof GQFinanceDataFlagObjectKind)[keyof typeof GQFinanceDataFlagObjectKind];
export type GQFinanceFundYearOverYearEdge = {
  __typename?: 'FinanceFundYearOverYearEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceFundsYearOverYear = {
  __typename?: 'FinanceFundsYearOverYear';
  edges: Array<Maybe<GQFinanceFundYearOverYearEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFinanceHeader = {
  __typename?: 'FinanceHeader';
  fundExcluded: Scalars['Boolean']['output'];
  isAssetGroupItem: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type GQFinanceImport = {
  __typename?: 'FinanceImport';
  accepted: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataFormat: Maybe<Scalars['String']['output']>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  diff: Maybe<Scalars['UntypedData']['output']>;
  diffFileMetadataId: Maybe<Scalars['ID']['output']>;
  errorMessages: Scalars['UntypedData']['output'];
  fileMetadata: Maybe<GQFileMetadata>;
  fileMetadataId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  rawOutput: Scalars['UntypedData']['output'];
  recordsCreated: Maybe<Scalars['Int']['output']>;
  recordsUnchanged: Maybe<Scalars['Int']['output']>;
  recordsUpdated: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFinanceInputDataFlag = {
  __typename?: 'FinanceInputDataFlag';
  assetCommercialRealEstateId: Maybe<Scalars['ID']['output']>;
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetHoldingId: Maybe<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceId: Maybe<Scalars['ID']['output']>;
  assetSovereignBondId: Maybe<Scalars['ID']['output']>;
  assetYearId: Maybe<Scalars['ID']['output']>;
  checkKind: Scalars['String']['output'];
  columns: Array<Scalars['String']['output']>;
  fundId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  rowData: Scalars['UntypedData']['output'];
};

export type GQFinanceInsightsBlock = GQIFinanceViewSection & {
  __typename?: 'FinanceInsightsBlock';
  id: Scalars['ID']['output'];
  insights: Array<GQFinanceInsightsBlock>;
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  renderInCard: Maybe<Scalars['Boolean']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQFinanceMeasurementProgressRow = {
  __typename?: 'FinanceMeasurementProgressRow';
  assetId: Scalars['String']['output'];
  assetName: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  contacts: Maybe<Array<GQSupplierContact>>;
  coverageEndDate: Maybe<Scalars['Date']['output']>;
  coverageStartDate: Maybe<Scalars['Date']['output']>;
  fundIds: Array<Scalars['String']['output']>;
  hasApprovedProgressRequest: Maybe<Scalars['Boolean']['output']>;
  hasRequestedFootprint: Maybe<Scalars['Boolean']['output']>;
  hasRequestedProgress: Maybe<Scalars['Boolean']['output']>;
  measuredFootprintDetails: Maybe<GQMeasuredFootprintDetails>;
  portcoFootprintReviewRequestMetadata: Maybe<
    Array<GQPortcoFootprintReviewRequestMetadata>
  >;
  projectName: Maybe<Scalars['String']['output']>;
  taskStatusDumps: Maybe<Array<GQTaskStatusDump>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export const GQFinanceMetric = {
  AssetCount: 'AssetCount',
  EconomicIntensity: 'EconomicIntensity',
  EconomicIntensityScope1And2: 'EconomicIntensityScope1And2',
  FinancedEmissions: 'FinancedEmissions',
  FinancedEmissionsScope1: 'FinancedEmissionsScope1',
  FinancedEmissionsScope1And2: 'FinancedEmissionsScope1And2',
  FinancedEmissionsScope2: 'FinancedEmissionsScope2',
  FinancedEmissionsScope3: 'FinancedEmissionsScope3',
  HoldingCount: 'HoldingCount',
  IciAlignment: 'IciAlignment',
  Pcaf: 'Pcaf',
  PcafScope1: 'PcafScope1',
  PcafScope2: 'PcafScope2',
  PcafScope3: 'PcafScope3',
  RevenueIntensity: 'RevenueIntensity',
  RevenueIntensityScope1: 'RevenueIntensityScope1',
  RevenueIntensityScope2: 'RevenueIntensityScope2',
  RevenueIntensityScope3: 'RevenueIntensityScope3',
  TotalEmissions: 'TotalEmissions',
  TotalEmissionsScope1: 'TotalEmissionsScope1',
  TotalEmissionsScope2: 'TotalEmissionsScope2',
  TotalEmissionsScope3: 'TotalEmissionsScope3',
  TotalOutstandingAmount: 'TotalOutstandingAmount',
  Waci: 'Waci',
  WaciScope1: 'WaciScope1',
  WaciScope2: 'WaciScope2',
  WaciScope3: 'WaciScope3',
} as const;

export type GQFinanceMetric =
  (typeof GQFinanceMetric)[keyof typeof GQFinanceMetric];
export type GQFinanceMetricBar = {
  __typename?: 'FinanceMetricBar';
  emptyMetrics: Array<GQFinanceAnyMetric>;
  id: Scalars['ID']['output'];
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  metrics: Array<GQFinanceAnyMetric>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export const GQFinanceReminderType = {
  FootprintFinalization: 'FootprintFinalization',
  MeasurementProject: 'MeasurementProject',
} as const;

export type GQFinanceReminderType =
  (typeof GQFinanceReminderType)[keyof typeof GQFinanceReminderType];
export type GQFinanceSavedView = {
  __typename?: 'FinanceSavedView';
  charts: Maybe<Array<Scalars['String']['output']>>;
  filters: Maybe<Scalars['UntypedData']['output']>;
  fundGroup: Maybe<Scalars['String']['output']>;
  fundId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  layout: Maybe<Scalars['String']['output']>;
  metrics: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  /** @deprecated Use charts length instead */
  showInsights: Scalars['Boolean']['output'];
  /** @deprecated Use metrics length instead */
  showMetrics: Scalars['Boolean']['output'];
  standardView: Maybe<GQFinanceStandardView>;
};

export type GQFinanceSectorEmissions = {
  __typename?: 'FinanceSectorEmissions';
  absoluteFinancedEmissionsTco2e: Maybe<Scalars['Int']['output']>;
  holdingNames: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type GQFinanceShowYourWorkData = {
  __typename?: 'FinanceShowYourWorkData';
  aggregateRows: Maybe<Scalars['UntypedData']['output']>;
  rowsWithOnlyFinancedEmissions: Maybe<Scalars['UntypedData']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
};

export type GQFinanceSnapshot = {
  __typename?: 'FinanceSnapshot';
  completedAt: Maybe<Scalars['DateTime']['output']>;
  config: Maybe<Scalars['UntypedData']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<GQUser>;
  executionStatus: GQFinanceSnapshotExecutionStatus;
  executionStatusMessage: Scalars['String']['output'];
  flagsPopulated: Maybe<Scalars['Boolean']['output']>;
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintSnapshotId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isStale: Maybe<Scalars['Boolean']['output']>;
  note: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  snapshotName: Maybe<Scalars['String']['output']>;
};

export type GQFinanceSnapshotAsset = {
  __typename?: 'FinanceSnapshotAsset';
  asset: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssetEdge = {
  __typename?: 'FinanceSnapshotAssetEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssetGroupedByCompany = {
  __typename?: 'FinanceSnapshotAssetGroupedByCompany';
  asset: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssetGroupedByCompanyEdge = {
  __typename?: 'FinanceSnapshotAssetGroupedByCompanyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssets = {
  __typename?: 'FinanceSnapshotAssets';
  edges: Array<Maybe<GQFinanceSnapshotAssetEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export type GQFinanceSnapshotAssetsGroupedByCompany = {
  __typename?: 'FinanceSnapshotAssetsGroupedByCompany';
  edges: Array<Maybe<GQFinanceSnapshotAssetGroupedByCompanyEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export const GQFinanceSnapshotExecutionStatus = {
  Error: 'ERROR',
  Processing: 'PROCESSING',
  Success: 'SUCCESS',
} as const;

export type GQFinanceSnapshotExecutionStatus =
  (typeof GQFinanceSnapshotExecutionStatus)[keyof typeof GQFinanceSnapshotExecutionStatus];
export type GQFinanceSnapshotFund = {
  __typename?: 'FinanceSnapshotFund';
  fund: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotFundEdge = {
  __typename?: 'FinanceSnapshotFundEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotFunds = {
  __typename?: 'FinanceSnapshotFunds';
  edges: Array<Maybe<GQFinanceSnapshotFundEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export type GQFinanceSnapshotHoldingEdge = {
  __typename?: 'FinanceSnapshotHoldingEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotHoldings = {
  __typename?: 'FinanceSnapshotHoldings';
  edges: Array<Maybe<GQFinanceSnapshotHoldingEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export const GQFinanceSnapshotMethodology = {
  Ceda: 'CEDA',
  CedaV5: 'CEDA_V5',
  CedaV7: 'CEDA_V7',
  Useeio: 'USEEIO',
  Useeio_2_1: 'USEEIO_2_1',
  Useeio_2_2_22: 'USEEIO_2_2_22',
  Custom: 'custom',
} as const;

export type GQFinanceSnapshotMethodology =
  (typeof GQFinanceSnapshotMethodology)[keyof typeof GQFinanceSnapshotMethodology];
export type GQFinanceSnapshotTag = {
  __typename?: 'FinanceSnapshotTag';
  columnConfiguration: Maybe<Scalars['UntypedData']['output']>;
  columnFormat: GQGridColumnFormat;
  description: Maybe<Scalars['String']['output']>;
  financeTag: GQFinanceTag;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tagType: GQFinanceTagType;
};

export const GQFinanceStandardView = {
  Asset: 'Asset',
  Fund: 'Fund',
  FundCategory: 'FundCategory',
  FundGroup: 'FundGroup',
  Overview: 'Overview',
  YearOverYear: 'YearOverYear',
} as const;

export type GQFinanceStandardView =
  (typeof GQFinanceStandardView)[keyof typeof GQFinanceStandardView];
export type GQFinanceStringMetric = {
  __typename?: 'FinanceStringMetric';
  additionalInfo: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  isMissingInputs: Scalars['Boolean']['output'];
  metric: GQFinanceMetric;
  name: Scalars['String']['output'];
  unit: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type GQFinanceTag = {
  __typename?: 'FinanceTag';
  columnConfiguration: Maybe<Scalars['UntypedData']['output']>;
  columnFormat: GQGridColumnFormat;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  tagCategory: Maybe<Scalars['String']['output']>;
  tagType: GQFinanceTagType;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Maybe<Scalars['String']['output']>;
};

export const GQFinanceTagType = {
  AssetTag: 'AssetTag',
  AssetYearTag: 'AssetYearTag',
  FundTag: 'FundTag',
  HoldingTag: 'HoldingTag',
} as const;

export type GQFinanceTagType =
  (typeof GQFinanceTagType)[keyof typeof GQFinanceTagType];
export const GQFinanceViewSidebar = {
  AssetSidebar: 'AssetSidebar',
} as const;

export type GQFinanceViewSidebar =
  (typeof GQFinanceViewSidebar)[keyof typeof GQFinanceViewSidebar];
export type GQFinanceWorksheet = GQIdInterface & {
  __typename?: 'FinanceWorksheet';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  hiddenColumns: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  orgColumns: Array<GQFinanceColumn>;
  sortModel: Array<GQDataGridSortItem>;
  standardView: Maybe<GQFinanceStandardView>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQFinanceWorksheetColumn = {
  __typename?: 'FinanceWorksheetColumn';
  columnConfiguration: Maybe<
    GQGridColumnConfigurationMultiSelect | GQGridColumnConfigurationSingleSelect
  >;
  columnFormat: GQGridColumnFormat;
  description: Scalars['String']['output'];
  field: Scalars['String']['output'];
  financeWorksheetId: Maybe<Scalars['ID']['output']>;
  headerName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  width: Maybe<Scalars['Int']['output']>;
};

export type GQFinancialMappingGroupHistory = {
  __typename?: 'FinancialMappingGroupHistory';
  id: Scalars['ID']['output'];
  json: Scalars['JSONString']['output'];
};

export type GQFinancialsAccount = {
  __typename?: 'FinancialsAccount';
  accountId: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  displayAccountId: Scalars['String']['output'];
};

export type GQFinancialsReviewGroup = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'FinancialsReviewGroup';
    datasources: Array<GQDatasource>;
    id: Scalars['ID']['output'];
    measurementProject: GQMeasurementProject;
  };

export type GQFinancialsReviewItem = GQContextRelationInterface &
  GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'FinancialsReviewItem';
    account: GQFinancialsAccount;
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    askingEmployee: Maybe<GQWatershedEmployee>;
    createdAt: Scalars['DateTime']['output'];
    discussion: Maybe<GQDataIssue>;
    exclusionReason: Maybe<Scalars['String']['output']>;
    group: GQFinancialsReviewGroup;
    id: Scalars['ID']['output'];
    initialIndustryClassification: Maybe<GQIndustryClassification>;
    initiallyIncluded: Maybe<Scalars['Boolean']['output']>;
    kind: GQFinancialsReviewItemKind;
    name: Scalars['String']['output'];
    prompt: Maybe<Scalars['String']['output']>;
    respondingUser: Maybe<GQUser>;
    status: GQFinancialsReviewItemStatus;
    userResponse: Maybe<Scalars['String']['output']>;
  };

export type GQFinancialsReviewItemConnection = {
  __typename?: 'FinancialsReviewItemConnection';
  edges: Array<Maybe<GQFinancialsReviewItemEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFinancialsReviewItemEdge = {
  __typename?: 'FinancialsReviewItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFinancialsReviewItem>;
};

export const GQFinancialsReviewItemKind = {
  FreeText: 'FreeText',
  YesNo: 'YesNo',
} as const;

export type GQFinancialsReviewItemKind =
  (typeof GQFinancialsReviewItemKind)[keyof typeof GQFinancialsReviewItemKind];
export const GQFinancialsReviewItemStatus = {
  UserApproved: 'UserApproved',
  UserRejected: 'UserRejected',
  UserReplied: 'UserReplied',
  WatershedActioned: 'WatershedActioned',
  WatershedDraft: 'WatershedDraft',
  WatershedSubmitted: 'WatershedSubmitted',
} as const;

export type GQFinancialsReviewItemStatus =
  (typeof GQFinancialsReviewItemStatus)[keyof typeof GQFinancialsReviewItemStatus];
export type GQFindDuckInput = {
  duckName: Scalars['String']['input'];
};

export type GQFindDuckPayload = {
  __typename?: 'FindDuckPayload';
  description: Scalars['String']['output'];
};

export type GQFixReduxValidationErrorsInput = {
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQFixReduxValidationErrorsPayload = {
  __typename?: 'FixReduxValidationErrorsPayload';
  fixedPlans: Array<GQPlan>;
};

export const GQFlags = {
  ActivityDataAllActivityButton: 'ActivityDataAllActivityButton',
  ActivityDataListShowAllSchemas: 'ActivityDataListShowAllSchemas',
  ActivityDataMultipleMeasures: 'ActivityDataMultipleMeasures',
  ActivityDataNumericCustomColumns: 'ActivityDataNumericCustomColumns',
  AlwaysAllowTcfdQualitative: 'AlwaysAllowTcfdQualitative',
  ApiUploadDataRateLimiter: 'ApiUploadDataRateLimiter',
  ApiUploadDataRateLimiterLarge: 'ApiUploadDataRateLimiterLarge',
  ArchiveFootprints: 'ArchiveFootprints',
  AssetManagerAlternateAssetCorporateDownload:
    'AssetManagerAlternateAssetCorporateDownload',
  AssetManagerAlternateAssetYearDownload:
    'AssetManagerAlternateAssetYearDownload',
  AssetManagerAlternateDataManagementDownload:
    'AssetManagerAlternateDataManagementDownload',
  AssetManagerAutoMapping: 'AssetManagerAutoMapping',
  AssetManagerConfigureDataQualityFlags:
    'AssetManagerConfigureDataQualityFlags',
  AssetManagerCurrencyDisplay: 'AssetManagerCurrencyDisplay',
  AssetManagerDataExplorerSyw: 'AssetManagerDataExplorerSyw',
  AssetManagerDataFlagsFromInputModels: 'AssetManagerDataFlagsFromInputModels',
  AssetManagerDisableAuditReports: 'AssetManagerDisableAuditReports',
  AssetManagerDisableSnapshotCreation: 'AssetManagerDisableSnapshotCreation',
  AssetManagerDueDiligence: 'AssetManagerDueDiligence',
  AssetManagerEnableAuditReports: 'AssetManagerEnableAuditReports',
  AssetManagerEstimationMethodologyVersioning:
    'AssetManagerEstimationMethodologyVersioning',
  AssetManagerGroupByCompanyName: 'AssetManagerGroupByCompanyName',
  AssetManagerHistoricalAuditReports: 'AssetManagerHistoricalAuditReports',
  AssetManagerMortgagesHoldingType: 'AssetManagerMortgagesHoldingType',
  AssetManagerNegativeOutstandingAmount:
    'AssetManagerNegativeOutstandingAmount',
  AssetManagerPcafScore5ValueBasedEstimation:
    'AssetManagerPcafScore5ValueBasedEstimation',
  AssetManagerProjectFinanceAssetClass: 'AssetManagerProjectFinanceAssetClass',
  AssetManagerRecordAttributionFactorOverride:
    'AssetManagerRecordAttributionFactorOverride',
  AssetManagerRenameFunds: 'AssetManagerRenameFunds',
  AssetManagerScope2Subscopes: 'AssetManagerScope2Subscopes',
  AssetManagerSidebarCanCollapse: 'AssetManagerSidebarCanCollapse',
  AssetManagerSkipFlagCreation: 'AssetManagerSkipFlagCreation',
  AssetManagerSkipLogEventCreation: 'AssetManagerSkipLogEventCreation',
  AssetManagerSkipOrgCheckDuringCompanyMatch:
    'AssetManagerSkipOrgCheckDuringCompanyMatch',
  AssetManagerSkipSAndPbqSync: 'AssetManagerSkipSAndPBQSync',
  AssetManagerSkipSywFootprintSnapshot: 'AssetManagerSkipSywFootprintSnapshot',
  AssetManagerSnapshotDeletion: 'AssetManagerSnapshotDeletion',
  AssetManagerUseBiV2ForSnapshotGrid: 'AssetManagerUseBiV2ForSnapshotGrid',
  AssetManagerUseSAndPData: 'AssetManagerUseSAndPData',
  AssetManagerUseSAndPIndustryCodes: 'AssetManagerUseSAndPIndustryCodes',
  AssetManagerViewEdciFieldsPreset: 'AssetManagerViewEdciFieldsPreset',
  AssetManagerWriteIndustrySectorCodeToBart:
    'AssetManagerWriteIndustrySectorCodeToBart',
  AssetManagerYearOverYearComparison: 'AssetManagerYearOverYearComparison',
  AutoRunFootprintOnUserUpload: 'AutoRunFootprintOnUserUpload',
  BenchmarkRevenueAtRisk: 'BenchmarkRevenueAtRisk',
  BiDrilldownAiQuerying: 'BiDrilldownAiQuerying',
  BiDrilldownFacilitiesMeasures: 'BiDrilldownFacilitiesMeasures',
  BiDrilldownMultipleMeasures: 'BiDrilldownMultipleMeasures',
  BiDrilldownSupplierMeasures: 'BiDrilldownSupplierMeasures',
  BiEnableMetricDescriptions: 'BiEnableMetricDescriptions',
  BiReadFromActivityDataset: 'BiReadFromActivityDataset',
  BiV2DarkQuery: 'BiV2DarkQuery',
  BiV2FrontEndFormulation: 'BiV2FrontEndFormulation',
  BiV2Query: 'BiV2Query',
  BigRocksUiFinance: 'BigRocksUiFinance',
  BulkUploadTaskManagement: 'BulkUploadTaskManagement',
  BumpCleanPowerLimits: 'BumpCleanPowerLimits',
  ByPassChonkerChecker: 'ByPassChonkerChecker',
  CalculationEmEvalRefactorCut: 'CalculationEmEvalRefactorCut',
  CalculationEmEvalRefactorParity: 'CalculationEmEvalRefactorParity',
  CalculationEnableAlphaReleases: 'CalculationEnableAlphaReleases',
  CalculationEnablePipelinesPrototype: 'CalculationEnablePipelinesPrototype',
  CalculationKeepZerosInPipelineResults:
    'CalculationKeepZerosInPipelineResults',
  CalculationMethodologyCustomizationPage:
    'CalculationMethodologyCustomizationPage',
  CalculationMethodologyInProductPage: 'CalculationMethodologyInProductPage',
  CompleteFiscalYearInOverview: 'CompleteFiscalYearInOverview',
  ConsumerGoodsApparelNewMethodologies: 'ConsumerGoodsApparelNewMethodologies',
  ContractOverrideUpsellBenchmarks: 'ContractOverrideUpsellBenchmarks',
  CsrdAvoidedCleanPower: 'CsrdAvoidedCleanPower',
  CsrdDataRequirementsValidation: 'CsrdDataRequirementsValidation',
  CsrdEnableAiExtractedAnswers: 'CsrdEnableAiExtractedAnswers',
  CsrdEnableReportQuestionApprovals: 'CsrdEnableReportQuestionApprovals',
  DangerousDoNotUseIntentionallyBreakThings:
    'DangerousDoNotUseIntentionallyBreakThings',
  DashboardCleanPowerAdvancedEstimation:
    'DashboardCleanPowerAdvancedEstimation',
  DataInfraBumpMaxExpressionDepthFootAssembly:
    'DataInfraBumpMaxExpressionDepthFootAssembly',
  DataInfraCreateTempTableInCleanPower: 'DataInfraCreateTempTableInCleanPower',
  DataInfraMaterializeTableInTraceAddendum:
    'DataInfraMaterializeTableInTraceAddendum',
  DataInfraOnlyUseDuckDbTestExtraLargeServer:
    'DataInfraOnlyUseDuckDbTestExtraLargeServer',
  DataInfraOnlyUseDuckDbTestServersForResourceEstimation:
    'DataInfraOnlyUseDuckDbTestServersForResourceEstimation',
  DataInfraUseDuckDbResourceEstimation: 'DataInfraUseDuckDbResourceEstimation',
  DataIngestionAllowUploadsOnActivityDataPage:
    'DataIngestionAllowUploadsOnActivityDataPage',
  DataIngestionApiDataRegistry: 'DataIngestionApiDataRegistry',
  DataIngestionColocatedDataCentersInFacilities:
    'DataIngestionColocatedDataCentersInFacilities',
  DataIngestionCsrdApprovalFlow: 'DataIngestionCsrdApprovalFlow',
  DataIngestionDataAuditTransformation: 'DataIngestionDataAuditTransformation',
  DataIngestionDatasourceFilesRedesign: 'DataIngestionDatasourceFilesRedesign',
  DataIngestionDirectEntry: 'DataIngestionDirectEntry',
  DataIngestionDisableExportFacilitiesDocs:
    'DataIngestionDisableExportFacilitiesDocs',
  DataIngestionDisableOneSchemaAi: 'DataIngestionDisableOneSchemaAi',
  DataIngestionDisableValueMappingTaskAssignment:
    'DataIngestionDisableValueMappingTaskAssignment',
  DataIngestionEditablePowerUsageEffectiveness:
    'DataIngestionEditablePowerUsageEffectiveness',
  DataIngestionFacilitiesApprovals: 'DataIngestionFacilitiesApprovals',
  DataIngestionFacilitiesExtraLargeMode:
    'DataIngestionFacilitiesExtraLargeMode',
  DataIngestionFacilitiesTracker: 'DataIngestionFacilitiesTracker',
  DataIngestionFacilitiesUseLoader: 'DataIngestionFacilitiesUseLoader',
  DataIngestionFilefeeds: 'DataIngestionFilefeeds',
  DataIngestionGlobalFacilitiesPage: 'DataIngestionGlobalFacilitiesPage',
  DataIngestionOnlyRegenerateStaleFacilitiesBarts:
    'DataIngestionOnlyRegenerateStaleFacilitiesBarts',
  DataIngestionSkipFacilitiesBartsAutoRefreshInFootprintGen:
    'DataIngestionSkipFacilitiesBartsAutoRefreshInFootprintGen',
  DataIngestionSkipSendingApprovalFlowEmails:
    'DataIngestionSkipSendingApprovalFlowEmails',
  DataIngestionSnowfall: 'DataIngestionSnowfall',
  DataIngestionTestCts: 'DataIngestionTestCts',
  DataIngestionUserUploadEdit: 'DataIngestionUserUploadEdit',
  DataIngestionValueMappingDisableVendorPreviews:
    'DataIngestionValueMappingDisableVendorPreviews',
  DataIngestionValueMappingForCustomersBetaDoNotUseWithoutPermission:
    'DataIngestionValueMappingForCustomersBetaDoNotUseWithoutPermission',
  DataIngestionValueMappingsVendorRowsExpanded:
    'DataIngestionValueMappingsVendorRowsExpanded',
  DataLineageFeedback: 'DataLineageFeedback',
  DataLineageV2: 'DataLineageV2',
  DefaultDrilldownChartsClosed: 'DefaultDrilldownChartsClosed',
  DemoPlatformEnableForRestrictedOrg: 'DemoPlatformEnableForRestrictedOrg',
  DisableContractorAccess: 'DisableContractorAccess',
  DisableDataGovernancePage: 'DisableDataGovernancePage',
  DisableFacilitiesGeocoding: 'DisableFacilitiesGeocoding',
  DisableFinancialMappingAiSuggestions: 'DisableFinancialMappingAiSuggestions',
  DisableFootprintGeneration: 'DisableFootprintGeneration',
  DisableSalesforceSyncSupportPermissions:
    'DisableSalesforceSyncSupportPermissions',
  DustBotInProductPrototype: 'DustBotInProductPrototype',
  EditFootprintAssemblyJsonConfig: 'EditFootprintAssemblyJsonConfig',
  EmissionsModelMinimizeVariableNameInSql:
    'EmissionsModelMinimizeVariableNameInSql',
  EnableCompanyMergingAdmin: 'EnableCompanyMergingAdmin',
  EnableEditProductRulesAlways: 'EnableEditProductRulesAlways',
  EnableFacilitiesWasteForGhg: 'EnableFacilitiesWasteForGhg',
  EnableFacilitiesWaterForGhg: 'EnableFacilitiesWaterForGhg',
  EnableGeneralAnomalyDetection: 'EnableGeneralAnomalyDetection',
  EnablePreSetCustomTagsForBuildings: 'EnablePreSetCustomTagsForBuildings',
  EnablePublishFileUploadFacilities: 'EnablePublishFileUploadFacilities',
  EnableUserUploadCountsOnMeasurementPage:
    'EnableUserUploadCountsOnMeasurementPage',
  EnableUtilityFileScanning: 'EnableUtilityFileScanning',
  EntFoundAccessSecuritySettings: 'EntFoundAccessSecuritySettings',
  EntFoundDirectorySync: 'EntFoundDirectorySync',
  EntFoundDirectorySyncWriteChanges: 'EntFoundDirectorySyncWriteChanges',
  EntFoundEmailRestrictions: 'EntFoundEmailRestrictions',
  EntFoundIdleLogout: 'EntFoundIdleLogout',
  EntFoundSettingsV2: 'EntFoundSettingsV2',
  EntFoundSkilljarLinks: 'EntFoundSkilljarLinks',
  EntFoundTempApiSecretCompareToHash: 'EntFoundTempApiSecretCompareToHash',
  FootprintAuditBundleRowLimit: 'FootprintAuditBundleRowLimit',
  FootprintBenchmarksForNonCorporate: 'FootprintBenchmarksForNonCorporate',
  FootprintGenerationAllowPreEmissionsModelGaActivityData:
    'FootprintGenerationAllowPreEmissionsModelGaActivityData',
  FootprintProductLevelEmissions: 'FootprintProductLevelEmissions',
  FootprintShowProductModels: 'FootprintShowProductModels',
  FootprintSuppliersPage: 'FootprintSuppliersPage',
  GranularFootprintPermissions: 'GranularFootprintPermissions',
  IgnoreFootprintRowLimit: 'IgnoreFootprintRowLimit',
  LearningHubReplaceUrls: 'LearningHubReplaceUrls',
  LimitLargeGroupDataLineage: 'LimitLargeGroupDataLineage',
  LocalizationDynamicStrings: 'LocalizationDynamicStrings',
  LogAuditEventsToWorkOs: 'LogAuditEventsToWorkOs',
  MeasurementIntegrationsArcadia: 'MeasurementIntegrationsArcadia',
  MeasurementIntegrationsMergeFinancials:
    'MeasurementIntegrationsMergeFinancials',
  MeasurementIntegrationsNetSuite: 'MeasurementIntegrationsNetSuite',
  MeasurementIntegrationsQuickBooks: 'MeasurementIntegrationsQuickBooks',
  MeasurementIntegrationsUrjanet: 'MeasurementIntegrationsUrjanet',
  MeasurementProjectDateEditingInAdmin: 'MeasurementProjectDateEditingInAdmin',
  MultipleDatasourcesPerAdt: 'MultipleDatasourcesPerAdt',
  OrgStructure: 'OrgStructure',
  OrgStructureAutofillingEnabled: 'OrgStructureAutofillingEnabled',
  OrgStructureChangelog: 'OrgStructureChangelog',
  OrgStructureMultipleVersions: 'OrgStructureMultipleVersions',
  OrganizationDestroyLogEvents: 'OrganizationDestroyLogEvents',
  OrganizationDestroyWithFkDependencies:
    'OrganizationDestroyWithFkDependencies',
  OverrideBlockedPipelineV2Dialog: 'OverrideBlockedPipelineV2Dialog',
  PricingFy25BlockAllScope3Temp: 'PricingFy25BlockAllScope3Temp',
  PricingFy25BlockScope1And2Temp: 'PricingFy25BlockScope1And2Temp',
  PricingFy25BlockScope3_1Temp: 'PricingFy25BlockScope3_1Temp',
  PricingFy25BlockScope3_2Temp: 'PricingFy25BlockScope3_2Temp',
  PricingFy25BlockScope3_3Temp: 'PricingFy25BlockScope3_3Temp',
  PricingFy25BlockScope3_4Temp: 'PricingFy25BlockScope3_4Temp',
  PricingFy25BlockScope3_5Temp: 'PricingFy25BlockScope3_5Temp',
  PricingFy25BlockScope3_6Temp: 'PricingFy25BlockScope3_6Temp',
  PricingFy25BlockScope3_7Temp: 'PricingFy25BlockScope3_7Temp',
  PricingFy25BlockScope3_8Temp: 'PricingFy25BlockScope3_8Temp',
  PricingFy25BlockScope3_9Temp: 'PricingFy25BlockScope3_9Temp',
  PricingFy25BlockScope3_10Temp: 'PricingFy25BlockScope3_10Temp',
  PricingFy25BlockScope3_11Temp: 'PricingFy25BlockScope3_11Temp',
  PricingFy25BlockScope3_12Temp: 'PricingFy25BlockScope3_12Temp',
  PricingFy25BlockScope3_13Temp: 'PricingFy25BlockScope3_13Temp',
  PricingFy25BlockScope3_14Temp: 'PricingFy25BlockScope3_14Temp',
  PricingFy25CsrdE1ModuleTemp: 'PricingFy25CsrdE1ModuleTemp',
  PricingFy25CsrdE2ModuleTemp: 'PricingFy25CsrdE2ModuleTemp',
  PricingFy25CsrdE3ModuleTemp: 'PricingFy25CsrdE3ModuleTemp',
  PricingFy25CsrdE4ModuleTemp: 'PricingFy25CsrdE4ModuleTemp',
  PricingFy25CsrdE5ModuleTemp: 'PricingFy25CsrdE5ModuleTemp',
  PricingFy25CsrdReportBuilderModuleTemp:
    'PricingFy25CsrdReportBuilderModuleTemp',
  PricingFy25CsrdSocialAndGovernanceModuleTemp:
    'PricingFy25CsrdSocialAndGovernanceModuleTemp',
  PricingFy25ReductionsModuleTemp: 'PricingFy25ReductionsModuleTemp',
  PricingFy25ReportingModuleTemp: 'PricingFy25ReportingModuleTemp',
  ProductRulesContainsOperator: 'ProductRulesContainsOperator',
  ProductionGraphCompareMaterials: 'ProductionGraphCompareMaterials',
  ProductionGraphPurchaseOrdersDataset: 'ProductionGraphPurchaseOrdersDataset',
  RebuildValueMappingContextOnDuckDbExtraLarge:
    'RebuildValueMappingContextOnDuckDbExtraLarge',
  ReduxContributionsIntervalOptimization:
    'ReduxContributionsIntervalOptimization',
  ReduxDisableCustomTagsInContributions:
    'ReduxDisableCustomTagsInContributions',
  ReduxExperimentalCalculations: 'ReduxExperimentalCalculations',
  ReduxSupplierEngagementBySpend: 'ReduxSupplierEngagementBySpend',
  ReduxUseLocationBasedEmissions: 'ReduxUseLocationBasedEmissions',
  ReportAnswerPermissions: 'ReportAnswerPermissions',
  ReportPermissionManagement: 'ReportPermissionManagement',
  ReportingCdpAlwaysOverwriteConflicts: 'ReportingCdpAlwaysOverwriteConflicts',
  ReportingEnableCdpApi: 'ReportingEnableCdpApi',
  ReportingEnableCdpApiUnscoredDeadline:
    'ReportingEnableCdpApiUnscoredDeadline',
  ReportingEnableNewClimateIntelligencePanel:
    'ReportingEnableNewClimateIntelligencePanel',
  ReportingFreezeConfigEdits: 'ReportingFreezeConfigEdits',
  ReportingIgnoreCdpPlanRestrictions: 'ReportingIgnoreCdpPlanRestrictions',
  ReportingShowCdpApiIsDownWarnings: 'ReportingShowCdpApiIsDownWarnings',
  ReportingShowRegulatoryCheckup: 'ReportingShowRegulatoryCheckup',
  ReportingTextInputRichText: 'ReportingTextInputRichText',
  ReportingUseBiV2: 'ReportingUseBiV2',
  ReportingUseTitleSubtitleMigratedColumns:
    'ReportingUseTitleSubtitleMigratedColumns',
  SendEmailsOnDemoOrTestOrg: 'SendEmailsOnDemoOrTestOrg',
  ShouldAllowPipelineResultFootprintKind:
    'ShouldAllowPipelineResultFootprintKind',
  ShouldUseExtraLargeDuckDbServer: 'ShouldUseExtraLargeDuckDbServer',
  ShouldUseTestDuckDbServer: 'ShouldUseTestDuckDbServer',
  ShouldUseTestTemporalTaskQueue: 'ShouldUseTestTemporalTaskQueue',
  ShowAllLanguages: 'ShowAllLanguages',
  ShowTopSuppliersTableInFootprintOverview:
    'ShowTopSuppliersTableInFootprintOverview',
  ShowYourWorkNumeratorAndDenominator: 'ShowYourWorkNumeratorAndDenominator',
  ShowYourWorkTotalRow: 'ShowYourWorkTotalRow',
  SupplierEngagementSurveys: 'SupplierEngagementSurveys',
  SupplierExpenseCategories: 'SupplierExpenseCategories',
  SupplierFootprintUncollapse: 'SupplierFootprintUncollapse',
  SupplyChainAdminEditCompany: 'SupplyChainAdminEditCompany',
  SupplyChainAutoMap10k: 'SupplyChainAutoMap10k',
  SupplyChainAutoMappingDuckDbPagination:
    'SupplyChainAutoMappingDuckDbPagination',
  SupplyChainBulkImportContacts: 'SupplyChainBulkImportContacts',
  SupplyChainBulkSurveyResponseExport: 'SupplyChainBulkSurveyResponseExport',
  SupplyChainChartsConfigAdvancedControls:
    'SupplyChainChartsConfigAdvancedControls',
  SupplyChainCleanPowerQuoteEnabled: 'SupplyChainCleanPowerQuoteEnabled',
  SupplyChainCustomSupplierConfig: 'SupplyChainCustomSupplierConfig',
  SupplyChainDisableAutoMappingButton: 'SupplyChainDisableAutoMappingButton',
  SupplyChainDisableLearningTasks: 'SupplyChainDisableLearningTasks',
  SupplyChainEmissionsExport: 'SupplyChainEmissionsExport',
  SupplyChainEnableDefaultMappingCompanySearch:
    'SupplyChainEnableDefaultMappingCompanySearch',
  SupplyChainExperimentalCache: 'SupplyChainExperimentalCache',
  SupplyChainFilterIngestionToHighCdpDisclosureQuality:
    'SupplyChainFilterIngestionToHighCdpDisclosureQuality',
  SupplyChainGeneralMillsCompanyEngagementEmail:
    'SupplyChainGeneralMillsCompanyEngagementEmail',
  SupplyChainHideArchivedConfigsFromCharts:
    'SupplyChainHideArchivedConfigsFromCharts',
  SupplyChainManualTargets: 'SupplyChainManualTargets',
  SupplyChainManualTargetsUpdatedDesign:
    'SupplyChainManualTargetsUpdatedDesign',
  SupplyChainPaginateCompanies: 'SupplyChainPaginateCompanies',
  SupplyChainReopenApprovedTask: 'SupplyChainReopenApprovedTask',
  SupplyChainShowHiddenCompaniesInIngestionSearch:
    'SupplyChainShowHiddenCompaniesInIngestionSearch',
  SupplyChainTargetsInitiativesExport: 'SupplyChainTargetsInitiativesExport',
  SupplyChainTargetsVsActual: 'SupplyChainTargetsVsActual',
  SupplyChainUseCacheArtifactV2: 'SupplyChainUseCacheArtifactV2',
  SupplyChainUseNewRefDataStructure: 'SupplyChainUseNewRefDataStructure',
  SupplyChainUseSAndPDataFromParquet: 'SupplyChainUseSAndPDataFromParquet',
  TempIndividualWritesForMappings: 'TempIndividualWritesForMappings',
  TransformRunWrite: 'TransformRunWrite',
  TurnOffCdpUpdateFeatureOnboardingDialog:
    'TurnOffCdpUpdateFeatureOnboardingDialog',
  Unknown: 'Unknown',
  UseBartForCustomIntensity: 'UseBartForCustomIntensity',
  UseBartForHeadcountIntensity: 'UseBartForHeadcountIntensity',
  UseBartForMonetaryIntensity: 'UseBartForMonetaryIntensity',
  UseDedicatedTaskQueueForTraceAddendum:
    'UseDedicatedTaskQueueForTraceAddendum',
  UseDirectlyAssignedPermissions: 'UseDirectlyAssignedPermissions',
  UseV2SyncPgToParquet: 'UseV2SyncPgToParquet',
  UserUploadLimit: 'UserUploadLimit',
  ValueMappingSkipSeedingOnUpload: 'ValueMappingSkipSeedingOnUpload',
} as const;

export type GQFlags = (typeof GQFlags)[keyof typeof GQFlags];
export type GQFootprint = GQIdInterface &
  GQPermissionObject & {
    __typename?: 'Footprint';
    canApprove: Scalars['Boolean']['output'];
    canEdit: Scalars['Boolean']['output'];
    createdAt: Scalars['DateTime']['output'];
    createdBy: Maybe<GQUser>;
    defaultFootprintSnapshot: Maybe<GQFootprintSnapshot>;
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    footprintSnapshots: Array<GQFootprintSnapshot>;
    id: Scalars['ID']['output'];
    isGenerating: Scalars['Boolean']['output'];
    latestDraftFootprintSnapshot: Maybe<GQFootprintSnapshot>;
    latestFootprintAssemblyRun: Maybe<GQFootprintAssemblyRun>;
    latestFootprintSnapshot: Maybe<GQFootprintSnapshot>;
    latestPublishedFootprintSnapshot: Maybe<GQFootprintSnapshot>;
    locked: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    nextFootprintVersionIdentifier: Scalars['String']['output'];
    orgId: Scalars['ID']['output'];
    status: GQFootprintStatus;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQFootprintFootprintSnapshotsArgs = {
  includeNonUserVisible: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQFootprintAnalysisCustomIntensityDataPoint =
  GQCustomIntensityDataPoint & {
    __typename?: 'FootprintAnalysisCustomIntensityDataPoint';
    customIntensityId: Scalars['String']['output'];
    value: Maybe<Scalars['Float']['output']>;
  };

export type GQFootprintAnalysisTimeSeries = {
  __typename?: 'FootprintAnalysisTimeSeries';
  id: Scalars['String']['output'];
  kind: GQAggregateKind;
  series: Array<GQFootprintAnalysisTimeseriesSeries>;
};

export type GQFootprintAnalysisTimeSeriesDataPoint = {
  __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
  customIntensities: Maybe<Array<GQFootprintAnalysisCustomIntensityDataPoint>>;
  date: Scalars['Date']['output'];
  headcount: Maybe<Scalars['Int']['output']>;
  revenue: Maybe<Scalars['Int53']['output']>;
  value: Scalars['Int53']['output'];
};

export type GQFootprintAnalysisTimeseriesSeries = {
  __typename?: 'FootprintAnalysisTimeseriesSeries';
  data: Array<GQFootprintAnalysisTimeSeriesDataPoint>;
  label: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type GQFootprintAssemblyRun = GQIdInterface & {
  __typename?: 'FootprintAssemblyRun';
  footprintId: Maybe<Scalars['ID']['output']>;
  footprintSnapshotId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  status: GQFootprintAssemblyRunStatus;
  workflowId: Maybe<Scalars['String']['output']>;
};

export const GQFootprintAssemblyRunStatus = {
  Canceled: 'Canceled',
  Failed: 'Failed',
  Running: 'Running',
  Success: 'Success',
  UserError: 'UserError',
} as const;

export type GQFootprintAssemblyRunStatus =
  (typeof GQFootprintAssemblyRunStatus)[keyof typeof GQFootprintAssemblyRunStatus];
export const GQFootprintAssemblyStep = {
  AssembledFootprintSnapshot: 'assembledFootprintSnapshot',
  CleanPowerAppliedFootprintSnapshot: 'cleanPowerAppliedFootprintSnapshot',
  EmEvalPipelineResult: 'emEvalPipelineResult',
  FinalFootprint: 'finalFootprint',
  MergedPipelineResultsWithTags: 'mergedPipelineResultsWithTags',
} as const;

export type GQFootprintAssemblyStep =
  (typeof GQFootprintAssemblyStep)[keyof typeof GQFootprintAssemblyStep];
export type GQFootprintCategoryRule = GQIdInterface & {
  __typename?: 'FootprintCategoryRule';
  author: Maybe<GQUser>;
  filters: Array<GQBiQueryFilter>;
  id: Scalars['ID']['output'];
  latestActiveRule: Maybe<GQFootprintCategoryRule>;
  outputColumn: Scalars['String']['output'];
  outputValue: Scalars['String']['output'];
  ruleOrder: Scalars['Int']['output'];
  stableId: Scalars['String']['output'];
  timeIntervals: Maybe<Array<Scalars['YMInterval']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFootprintConfigCarbonRemoval = {
  __typename?: 'FootprintConfigCarbonRemoval';
  id: Scalars['ID']['output'];
  totalTco2e: Scalars['Float']['output'];
};

export type GQFootprintConfiguration = {
  __typename?: 'FootprintConfiguration';
  carbonRemoval: Maybe<GQFootprintConfigCarbonRemoval>;
  categoryRules: Array<GQFootprintCategoryRule>;
  createdAt: Scalars['DateTime']['output'];
  exclusionRules: Array<GQFootprintExclusionRule>;
  footprintInterval: Scalars['YMInterval']['output'];
  id: Scalars['ID']['output'];
  marketplaceAllocationInstructions: GQMarketplaceAllocationInstructionConnection;
  measurementProjectDatasets: Array<GQFootprintMeasurementProjectDataset>;
  methodologyConfig: Maybe<GQMethodologyConfig>;
  releasesByBat: Array<GQEmissionsModelReleaseByBatName>;
};

export type GQFootprintConnection = {
  __typename?: 'FootprintConnection';
  edges: Array<Maybe<GQFootprintEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintContributionLegacy = {
  __typename?: 'FootprintContributionLegacy';
  buildingName: Maybe<Scalars['String']['output']>;
  businessCategory: Scalars['String']['output'];
  businessSubcategory: Maybe<Scalars['String']['output']>;
  conversionFactorId: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  electricityType: Maybe<GQElectricityType>;
  fileMetadataIds: Array<Scalars['ID']['output']>;
  footprintKind: Scalars['String']['output'];
  ghgCategoryId: Scalars['String']['output'];
  ghgScope: Scalars['String']['output'];
  inputQuantity: Maybe<Scalars['Float']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  kgco2e: Scalars['Float']['output'];
  location: Maybe<Scalars['String']['output']>;
  locationBasedKgco2e: Maybe<Scalars['Float']['output']>;
  locationCountry: Maybe<Scalars['String']['output']>;
  product: Maybe<Scalars['String']['output']>;
  productMarket: Maybe<Scalars['String']['output']>;
  quantityUnit: Maybe<Scalars['String']['output']>;
  quantityValue: Maybe<Scalars['Int']['output']>;
  rowId: Scalars['String']['output'];
  subEntity: Maybe<Scalars['String']['output']>;
  vendor: Maybe<Scalars['String']['output']>;
  vendorId: Maybe<Scalars['ID']['output']>;
  vendorProgram: Maybe<Scalars['String']['output']>;
  yearMonth: Scalars['YearMonth']['output'];
};

export type GQFootprintDatasetOrDatasetGroup = {
  __typename?: 'FootprintDatasetOrDatasetGroup';
  dataset: Maybe<GQDataset>;
  datasetGroupName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type GQFootprintDebugPayload = GQIdInterface & {
  __typename?: 'FootprintDebugPayload';
  appliedExclusionRules: Array<GQFootprintSnapshotExclusionRule>;
  cleanPowerImpact: Maybe<GQCleanPowerImpact>;
  id: Scalars['ID']['output'];
  intermediateFileIds: Maybe<Scalars['JSONString']['output']>;
};

export type GQFootprintDisclosure = GQIdInterface & {
  __typename?: 'FootprintDisclosure';
  createdAt: Scalars['DateTime']['output'];
  fileMetadata: GQFileMetadata;
  footprintSnapshot: GQFootprintSnapshot;
  granularity: GQFootprintDisclosureGranularity;
  id: Scalars['ID']['output'];
  reportingYear: Maybe<Scalars['Int']['output']>;
  shareLogs: Array<GQFootprintDisclosureShareLog>;
};

export type GQFootprintDisclosureConnection = {
  __typename?: 'FootprintDisclosureConnection';
  edges: Array<Maybe<GQFootprintDisclosureEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintDisclosureEdge = {
  __typename?: 'FootprintDisclosureEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprintDisclosure>;
};

export const GQFootprintDisclosureGranularity = {
  AggregatesOnly: 'AggregatesOnly',
  Full: 'Full',
} as const;

export type GQFootprintDisclosureGranularity =
  (typeof GQFootprintDisclosureGranularity)[keyof typeof GQFootprintDisclosureGranularity];
export type GQFootprintDisclosureShareLog = GQIdInterface & {
  __typename?: 'FootprintDisclosureShareLog';
  footprintDisclosureId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  sharedByUserId: Scalars['ID']['output'];
  sharedToOrgId: Scalars['ID']['output'];
};

export type GQFootprintDisclosureShareLogConnection = {
  __typename?: 'FootprintDisclosureShareLogConnection';
  edges: Array<Maybe<GQFootprintDisclosureShareLogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintDisclosureShareLogEdge = {
  __typename?: 'FootprintDisclosureShareLogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprintDisclosureShareLog>;
};

export type GQFootprintEdge = {
  __typename?: 'FootprintEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprint>;
};

export type GQFootprintEstimateOutput = GQIdInterface & {
  __typename?: 'FootprintEstimateOutput';
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  companyId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  output: Maybe<GQFootprintEstimateOutputByScope>;
};

export type GQFootprintEstimateOutputByScope = GQCompanyEmissionsInterfaceV2 & {
  __typename?: 'FootprintEstimateOutputByScope';
  revenueCurrency: Maybe<Scalars['String']['output']>;
  scope1: Maybe<Scalars['Float']['output']>;
  scope2: Maybe<Scalars['Float']['output']>;
  scope2Location: Maybe<Scalars['Float']['output']>;
  scope2Market: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
  scope301: Maybe<Scalars['Float']['output']>;
  scope302: Maybe<Scalars['Float']['output']>;
  scope303: Maybe<Scalars['Float']['output']>;
  scope304: Maybe<Scalars['Float']['output']>;
  scope305: Maybe<Scalars['Float']['output']>;
  scope306: Maybe<Scalars['Float']['output']>;
  scope307: Maybe<Scalars['Float']['output']>;
  scope308: Maybe<Scalars['Float']['output']>;
  scope309: Maybe<Scalars['Float']['output']>;
  scope310: Maybe<Scalars['Float']['output']>;
  scope311: Maybe<Scalars['Float']['output']>;
  scope312: Maybe<Scalars['Float']['output']>;
  scope313: Maybe<Scalars['Float']['output']>;
  scope314: Maybe<Scalars['Float']['output']>;
  scope315: Maybe<Scalars['Float']['output']>;
  scope316: Maybe<Scalars['Float']['output']>;
  scope317: Maybe<Scalars['Float']['output']>;
  units: GQCompanyEmissionsUnits;
};

export type GQFootprintExclusionRule = GQIdInterface & {
  __typename?: 'FootprintExclusionRule';
  author: GQUser;
  description: Maybe<Scalars['String']['output']>;
  filters: Array<GQBiQueryFilter>;
  id: Scalars['ID']['output'];
  latestActiveRule: Maybe<GQFootprintExclusionRule>;
  outputValue: Scalars['Float']['output'];
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  ruleOrder: Scalars['Int']['output'];
  stableId: Scalars['String']['output'];
  timeIntervals: Maybe<Array<Scalars['YMInterval']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFootprintExclusionRuleEmissions = {
  __typename?: 'FootprintExclusionRuleEmissions';
  excludedMwhConsumed: Maybe<Scalars['Float']['output']>;
  excludedTco2eQuantity: Scalars['Float']['output'];
};

export type GQFootprintExclusionSuggestion = GQIdInterface & {
  __typename?: 'FootprintExclusionSuggestion';
  id: Scalars['ID']['output'];
  suggestion:
    | GQFootprintExclusionSuggestionSuggestionConflict
    | GQFootprintExclusionSuggestionSuggestionUnmappedActivity;
};

export const GQFootprintExclusionSuggestionConflictingSourceKind = {
  Activity: 'Activity',
  Spend: 'Spend',
} as const;

export type GQFootprintExclusionSuggestionConflictingSourceKind =
  (typeof GQFootprintExclusionSuggestionConflictingSourceKind)[keyof typeof GQFootprintExclusionSuggestionConflictingSourceKind];
export type GQFootprintExclusionSuggestionSuggestion = {
  kind: GQFootprintExclusionSuggestionSuggestionKind;
};

export type GQFootprintExclusionSuggestionSuggestionConflict =
  GQFootprintExclusionSuggestionSuggestion & {
    __typename?: 'FootprintExclusionSuggestionSuggestionConflict';
    beaCode: Scalars['String']['output'];
    beaCodeDescription: Scalars['String']['output'];
    conflictingSources: Array<
      | GQFootprintExclusionSuggestionSuggestionConflictActivity
      | GQFootprintExclusionSuggestionSuggestionConflictSpend
    >;
    kind: GQFootprintExclusionSuggestionSuggestionKind;
  };

export type GQFootprintExclusionSuggestionSuggestionConflictActivity =
  GQFootprintExclusionSuggestionSuggestionConflictingSource & {
    __typename?: 'FootprintExclusionSuggestionSuggestionConflictActivity';
    dataset: GQDataset;
    source: GQFootprintExclusionSuggestionConflictingSourceKind;
    tCO2e: Scalars['Float']['output'];
  };

export type GQFootprintExclusionSuggestionSuggestionConflictSpend =
  GQFootprintExclusionSuggestionSuggestionConflictingSource & {
    __typename?: 'FootprintExclusionSuggestionSuggestionConflictSpend';
    financialAccountId: Scalars['String']['output'];
    financialAccountName: Scalars['String']['output'];
    source: GQFootprintExclusionSuggestionConflictingSourceKind;
    tCO2e: Scalars['Float']['output'];
    vendorEntity: Maybe<Scalars['String']['output']>;
  };

export type GQFootprintExclusionSuggestionSuggestionConflictingSource = {
  source: GQFootprintExclusionSuggestionConflictingSourceKind;
  tCO2e: Scalars['Float']['output'];
};

export const GQFootprintExclusionSuggestionSuggestionKind = {
  Conflict: 'Conflict',
  UnmappedActivity: 'UnmappedActivity',
} as const;

export type GQFootprintExclusionSuggestionSuggestionKind =
  (typeof GQFootprintExclusionSuggestionSuggestionKind)[keyof typeof GQFootprintExclusionSuggestionSuggestionKind];
export type GQFootprintExclusionSuggestionSuggestionUnmappedActivity =
  GQFootprintExclusionSuggestionSuggestion & {
    __typename?: 'FootprintExclusionSuggestionSuggestionUnmappedActivity';
    dataset: GQDataset;
    kind: GQFootprintExclusionSuggestionSuggestionKind;
    tCO2e: Scalars['Float']['output'];
  };

export type GQFootprintGenerationInfo = {
  __typename?: 'FootprintGenerationInfo';
  totalPublishedRecordsForOrg: Scalars['Int']['output'];
};

export type GQFootprintGenerationSource = GQApiKey | GQUser;

export type GQFootprintKindBreakdown = {
  __typename?: 'FootprintKindBreakdown';
  footprintKinds: Array<GQFootprintKindBreakdownItem>;
  id: Scalars['ID']['output'];
};

export type GQFootprintKindBreakdownItem = {
  __typename?: 'FootprintKindBreakdownItem';
  footprintKind: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kgco2e: Scalars['Float']['output'];
  percentOfTotal: Scalars['Float']['output'];
};

export type GQFootprintMeasurementProjectDataset = {
  __typename?: 'FootprintMeasurementProjectDataset';
  datasetsAndDatasetGroups: Array<GQFootprintDatasetOrDatasetGroup>;
  id: Scalars['ID']['output'];
  measurementProject: GQMeasurementProject;
};

export type GQFootprintQuantityKindMetadata = {
  __typename?: 'FootprintQuantityKindMetadata';
  includedFootprintQuantityKinds: Array<Scalars['String']['output']>;
};

export const GQFootprintReportKind = {
  CloudComputeReport: 'CloudComputeReport',
  Custom: 'Custom',
  ElectricityConsumption: 'ElectricityConsumption',
  ElectricityConsumptionDetail: 'ElectricityConsumptionDetail',
  FootprintAuditMonthlyReport: 'FootprintAuditMonthlyReport',
  FootprintAuditReport: 'FootprintAuditReport',
  GoldmanEsg: 'GoldmanEsg',
  GoldmanEsgEmissions: 'GoldmanEsgEmissions',
  LocationBasedFootprintAuditReport: 'LocationBasedFootprintAuditReport',
  MarketVsLocationReport: 'MarketVsLocationReport',
  Servicenow: 'Servicenow',
} as const;

export type GQFootprintReportKind =
  (typeof GQFootprintReportKind)[keyof typeof GQFootprintReportKind];
export type GQFootprintSliceInitiative = GQIdInterface &
  GQInitiative & {
    __typename?: 'FootprintSliceInitiative';
    costTimeseries: GQSimpleTimeseries;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    description: Maybe<Scalars['String']['output']>;
    enabled: Scalars['Boolean']['output'];
    filters: GQFilterExpressionGroupWithNewFilters;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    plan: GQPlan;
    reductionTimeseries: GQSimpleTimeseries;
    scheduleType: GQScheduleType;
  };

export type GQFootprintSnapshot = GQIdInterface & {
  __typename?: 'FootprintSnapshot';
  approvedAt: Maybe<Scalars['DateTime']['output']>;
  approvedBy: Maybe<GQUser>;
  assemblyDebugJson: Maybe<Scalars['JSONString']['output']>;
  categoryRules: Array<GQFootprintCategoryRule>;
  configs: Array<GQPipelineConfig>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<GQUser>;
  dataRegistryTableName: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  excludedScopesWithPriceGating: Array<Scalars['String']['output']>;
  exclusionRules: Array<GQFootprintExclusionRule>;
  extraAdeIdsForIntDenoms: Array<Scalars['String']['output']>;
  extraAdeIdsForNonEmissiveActivity: Array<Scalars['String']['output']>;
  footprint: GQFootprint;
  footprintAssemblyRun: Maybe<GQFootprintAssemblyRun>;
  footprintConfiguration: GQFootprintConfiguration;
  footprintInterval: Scalars['YMInterval']['output'];
  globalTags: Array<GQGlobalFootprintTag>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  intermediateFileIds: Maybe<Scalars['JSONString']['output']>;
  isAutoGenerated: Scalars['Boolean']['output'];
  isLatest: Scalars['Boolean']['output'];
  isUserVisible: Scalars['Boolean']['output'];
  marketplaceAllocationInstructionIds: Array<Scalars['String']['output']>;
  marketplaceAllocationInstructions: Array<GQMarketplaceAllocationInstruction>;
  name: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  reportsUsingFootprint: Array<GQReport>;
  reviewStatus: GQFootprintStatus;
  status: GQFootprintSnapshotStatus;
  summary: GQFootprintSnapshotSummary;
  tags: Array<GQFootprintTag>;
  userUploadedTableIds: Array<Scalars['String']['output']>;
  watershedFootprintReviewRequest: Maybe<GQWatershedFootprintReviewRequest>;
};

export type GQFootprintSnapshotConnection = {
  __typename?: 'FootprintSnapshotConnection';
  edges: Array<Maybe<GQFootprintSnapshotEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintSnapshotEdge = {
  __typename?: 'FootprintSnapshotEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprintSnapshot>;
};

export type GQFootprintSnapshotExclusionRule = GQIdInterface & {
  __typename?: 'FootprintSnapshotExclusionRule';
  footprintExclusionRule: GQFootprintExclusionRule;
  footprintSnapshot: GQFootprintSnapshot;
  id: Scalars['ID']['output'];
  impactedEmissions: GQFootprintExclusionRuleEmissions;
};

export const GQFootprintSnapshotStatus = {
  Draft: 'Draft',
  Publish: 'Publish',
} as const;

export type GQFootprintSnapshotStatus =
  (typeof GQFootprintSnapshotStatus)[keyof typeof GQFootprintSnapshotStatus];
export type GQFootprintSnapshotSummary = {
  __typename?: 'FootprintSnapshotSummary';
  emissions: Array<GQFootprintSnapshotSummaryEmission>;
  format: Scalars['Int']['output'];
};

export type GQFootprintSnapshotSummaryEmission = {
  __typename?: 'FootprintSnapshotSummaryEmission';
  businessCategory: Maybe<Scalars['String']['output']>;
  footprintKind: Scalars['String']['output'];
  kgCo2e: Scalars['Float']['output'];
  month: Scalars['Date']['output'];
  pipeline: Scalars['String']['output'];
};

export type GQFootprintSourceOption = {
  __typename?: 'FootprintSourceOption';
  externalScore: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  pcafScore: Maybe<Scalars['Int']['output']>;
  source: GQEmissionsYearSource;
  year: Scalars['Int']['output'];
};

export const GQFootprintStatus = {
  Approved: 'Approved',
  Draft: 'Draft',
  PendingWatershedReview: 'PendingWatershedReview',
  ReviewedByWatershed: 'ReviewedByWatershed',
} as const;

export type GQFootprintStatus =
  (typeof GQFootprintStatus)[keyof typeof GQFootprintStatus];
export type GQFootprintTag = GQIdInterface & {
  __typename?: 'FootprintTag';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFinance: Scalars['Boolean']['output'];
  orgId: Scalars['ID']['output'];
  rulesList: Scalars['JSONString']['output'];
  rulesSchemaVersion: Scalars['String']['output'];
  tagName: Scalars['String']['output'];
  watershedEditor: Maybe<GQWatershedEmployee>;
};

export type GQFootprintTestExecutionRecordDiffData = {
  __typename?: 'FootprintTestExecutionRecordDiffData';
  actual: Maybe<Scalars['UntypedData']['output']>;
  equal: Scalars['Boolean']['output'];
  expected: Maybe<Scalars['UntypedData']['output']>;
  fieldName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQFootprintTestExecutionStepResult = {
  __typename?: 'FootprintTestExecutionStepResult';
  id: Scalars['ID']['output'];
  resultRecords: Array<GQFootprintTestExecutionStepResultRecord>;
};

export type GQFootprintTestExecutionStepResultRecord = {
  __typename?: 'FootprintTestExecutionStepResultRecord';
  allocationLocationQuantityKgCo2E: Maybe<Scalars['Float']['output']>;
  bartName: Scalars['String']['output'];
  bartRowId: Scalars['Int']['output'];
  categoryId: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  emStableId: Scalars['String']['output'];
  emStableTitle: Scalars['String']['output'];
  emVariableName: Scalars['String']['output'];
  fullDiff: Scalars['UntypedData']['output'];
  ghgCategoryId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inActual: Maybe<Scalars['Boolean']['output']>;
  inExpected: Maybe<Scalars['Boolean']['output']>;
  month: Scalars['String']['output'];
  quantityKgCo2e: Maybe<Scalars['Float']['output']>;
  resultAccepted: Maybe<Scalars['Boolean']['output']>;
  rowSplitKey: Maybe<Scalars['String']['output']>;
  status: GQFootprintTestExecutionStepStatus;
  subcategoryId: Maybe<Scalars['String']['output']>;
};

export const GQFootprintTestExecutionStepStatus = {
  Failed: 'Failed',
  Passed: 'Passed',
} as const;

export type GQFootprintTestExecutionStepStatus =
  (typeof GQFootprintTestExecutionStepStatus)[keyof typeof GQFootprintTestExecutionStepStatus];
export type GQFootprintTestSuiteConfig = {
  __typename?: 'FootprintTestSuiteConfig';
  categoryRuleIds: Maybe<Array<Scalars['ID']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  customTagNames: Maybe<Array<Scalars['String']['output']>>;
  exclusionRuleIds: Maybe<Array<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  marketplaceAllocationInstructionIds: Maybe<Array<Scalars['ID']['output']>>;
  pipelineEndDate: Maybe<Scalars['YearMonth']['output']>;
  pipelineStartDate: Maybe<Scalars['YearMonth']['output']>;
  shouldExclude: Scalars['Boolean']['output'];
};

export type GQFootprintTestSuiteConfigFields = {
  categoryRuleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  customTagNames?: InputMaybe<Array<Scalars['String']['input']>>;
  exclusionRuleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  marketplaceAllocationInstructionIds?: InputMaybe<
    Array<Scalars['ID']['input']>
  >;
  pipelineEndDate?: InputMaybe<Scalars['YearMonth']['input']>;
  pipelineStartDate?: InputMaybe<Scalars['YearMonth']['input']>;
  shouldExclude: Scalars['Boolean']['input'];
};

export type GQFootprintTestSuiteConfigPayload = {
  __typename?: 'FootprintTestSuiteConfigPayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQFootprintTestSuiteExecution = {
  __typename?: 'FootprintTestSuiteExecution';
  createdAt: Scalars['DateTime']['output'];
  error: Maybe<Scalars['String']['output']>;
  failedStep: Maybe<GQFootprintAssemblyStep>;
  id: Scalars['ID']['output'];
  status: GQMeasurementTestSuiteStageExecutionStatus;
  updatedAt: Scalars['DateTime']['output'];
  warnings: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GQFootprintWithSnapshot = {
  __typename?: 'FootprintWithSnapshot';
  footprint: GQFootprint;
  footprintSnapshot: GQFootprintSnapshot;
};

export type GQForecast = GQIdInterface & {
  __typename?: 'Forecast';
  acknowledgedFootprintSnapshotId: Maybe<Scalars['ID']['output']>;
  businessCategories: Array<GQCategorizedEmissionData>;
  customIntensityConfigs: Array<GQCustomIntensityConfig>;
  electricityConsumptionBreakdown: Array<GQRenewableEnergyPercentagePoint>;
  footprintInterval: Scalars['YMInterval']['output'];
  footprintKinds: Array<Scalars['String']['output']>;
  footprintKindsMeta: Array<GQSnapshotFootprintKindMeta>;
  /** @deprecated This shouldn't be user-visible, there is no dashboard resolver for this */
  footprintSnapshot: GQFootprintSnapshot;
  footprintSnapshotId: Scalars['ID']['output'];
  historicalPeriod: GQForecastPeriod;
  id: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
  name: Scalars['String']['output'];
  overrideFootprintKind: Maybe<Scalars['String']['output']>;
  planTargets: Array<GQPlanTarget>;
  referencePeriod: GQForecastPeriod;
  referencePeriodInterval: Scalars['YMInterval']['output'];
  scenarios: Array<GQForecastScenario>;
  validReferenceInterval: Scalars['YMInterval']['output'];
  validYearsForIntervalEnd: Scalars['YMInterval']['output'];
};

export type GQForecastBusinessCategoriesArgs = {
  useLegacyFootprintVersionSelection: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQForecastCustomIntensityDataPoint = GQCustomIntensityDataPoint & {
  __typename?: 'ForecastCustomIntensityDataPoint';
  customIntensityId: Scalars['String']['output'];
  value: Maybe<Scalars['Float']['output']>;
};

export type GQForecastPeriod = {
  __typename?: 'ForecastPeriod';
  data: Array<GQForecastPeriodPoint>;
  interval: Scalars['YMInterval']['output'];
};

export type GQForecastPeriodPoint = {
  __typename?: 'ForecastPeriodPoint';
  customGrowthFactors: Array<GQForecastCustomIntensityDataPoint>;
  date: Scalars['Date']['output'];
  headcount: Maybe<Scalars['Int']['output']>;
  revenue: Maybe<Scalars['Int53']['output']>;
};

export type GQForecastScenario = GQIdInterface & {
  __typename?: 'ForecastScenario';
  description: Maybe<Scalars['String']['output']>;
  growthForecasts: Array<GQGrowthForecast>;
  historicalUserInputtedGrowthFactorDataPoints: Array<GQHistoricalUserInputtedGrowthFactorDataPoint>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedByName: Maybe<Scalars['String']['output']>;
};

export type GQForkEmissionsModelInput = {
  description: Scalars['String']['input'];
  stableId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type GQForkEmissionsModelPayload = {
  __typename?: 'ForkEmissionsModelPayload';
  emissionsModelStableRecord: GQEmissionsModelStable;
  emissionsModelVersionRecord: GQEmissionsModelVersion;
};

export type GQForkThenLink = {
  description: Scalars['String']['input'];
  stableId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type GQFrozenDatasourceSummary = {
  __typename?: 'FrozenDatasourceSummary';
  datasource: GQDatasource;
  isStale: Scalars['Boolean']['output'];
  signedUrl: Scalars['String']['output'];
};

export type GQFulfillShareFileRequestInput = {
  sharedFileRequestIds: Array<Scalars['ID']['input']>;
};

export type GQFulfillShareFileRequestPayload = {
  __typename?: 'FulfillShareFileRequestPayload';
  newSharedFiles: Array<GQSharedFile>;
  sharedFileRequests: Array<GQSharedFileRequest>;
};

export type GQFund = GQPermissionObject & {
  __typename?: 'Fund';
  anonymousFundUuid: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  excludeAsSandbox: Maybe<Scalars['Boolean']['output']>;
  externalId: Maybe<Scalars['ID']['output']>;
  fundCategory: Maybe<Scalars['String']['output']>;
  fundGroup: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameSortable: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const GQFundAssetClass = {
  AssetGroup: 'AssetGroup',
  BusinessLoans: 'BusinessLoans',
  CommercialLineOfInsurance: 'CommercialLineOfInsurance',
  CommercialRealEstate: 'CommercialRealEstate',
  CorporateBonds: 'CorporateBonds',
  ListedEquity: 'ListedEquity',
  Mortgages: 'Mortgages',
  PersonalMotorVehicleInsurance: 'PersonalMotorVehicleInsurance',
  ProjectFinance: 'ProjectFinance',
  SovereignDebt: 'SovereignDebt',
  UnlistedEquity: 'UnlistedEquity',
} as const;

export type GQFundAssetClass =
  (typeof GQFundAssetClass)[keyof typeof GQFundAssetClass];
export type GQFundConnection = {
  __typename?: 'FundConnection';
  edges: Array<Maybe<GQFundEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFundEdge = {
  __typename?: 'FundEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFund>;
};

export type GQFundId = {
  __typename?: 'FundId';
  fundId: Scalars['ID']['output'];
};

export type GQGenerateFinanceAuditReportsForAssetInput = {
  auditDatum: Array<GQFinanceAuditReportData>;
  orgId: Scalars['ID']['input'];
};

export type GQGenerateFinanceAuditReportsForAssetPayload = {
  __typename?: 'GenerateFinanceAuditReportsForAssetPayload';
  jobId: Scalars['ID']['output'];
};

export type GQGenerateGmailXmlInput = {
  existingFilters?: InputMaybe<Scalars['String']['input']>;
  handle: Scalars['String']['input'];
  teams?: InputMaybe<Array<Scalars['String']['input']>>;
  watching?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQGenerateGmailXmlPayload = {
  __typename?: 'GenerateGmailXmlPayload';
  xml: Scalars['String']['output'];
};

export type GQGenericObjectPayload = GQIdInterface & {
  __typename?: 'GenericObjectPayload';
  data: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  model: Scalars['String']['output'];
};

export const GQGeocodingClient = {
  GoogleMaps: 'GoogleMaps',
  Mapbox: 'Mapbox',
} as const;

export type GQGeocodingClient =
  (typeof GQGeocodingClient)[keyof typeof GQGeocodingClient];
export type GQGetAdminLineageGraphInput = {
  direction: GQLineageDirection;
  endKey?: InputMaybe<GQAdminLineageQueryKey>;
  maxDepth?: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
  startKey: GQAdminLineageQueryKey;
};

export type GQGetAdminLineageGraphPayload = {
  __typename?: 'GetAdminLineageGraphPayload';
  serializedGraph: Maybe<Scalars['UntypedData']['output']>;
};

export type GQGetEmissionsModelsForOrgInput = {
  orgId: Scalars['ID']['input'];
};

export type GQGetEmissionsModelsForOrgPayload = {
  __typename?: 'GetEmissionsModelsForOrgPayload';
  emissionsModels: Array<GQEmissionsModelStable>;
};

export type GQGetNewDecryptedTokenInput = {
  integrationConnectionId: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
  ticketLink: Scalars['String']['input'];
};

export type GQGetNewDecryptedTokenPayload = {
  __typename?: 'GetNewDecryptedTokenPayload';
  decryptedToken: Maybe<Scalars['JSONString']['output']>;
};

export type GQGetReportObjectConfigPathInput = {
  id: Scalars['ID']['input'];
};

export type GQGetReportObjectConfigPathPayload = {
  __typename?: 'GetReportObjectConfigPathPayload';
  path: Maybe<Array<GQReportObjectConfigPathItem>>;
};

export type GQGhgCategorizedEmissionData = {
  __typename?: 'GhgCategorizedEmissionData';
  amountKgco2e: Scalars['Float']['output'];
  businessSubcategory: Maybe<Scalars['String']['output']>;
  ghgCategoryId: Scalars['String']['output'];
  proportion: Scalars['Float']['output'];
};

export const GQGhgScope = {
  AdditionalCleanPower: 'AdditionalCleanPower',
  CarbonRemoval: 'CarbonRemoval',
  Scope1: 'Scope1',
  Scope2: 'Scope2',
  Scope3: 'Scope3',
} as const;

export type GQGhgScope = (typeof GQGhgScope)[keyof typeof GQGhgScope];
export type GQGlobalFootprintTag = GQIdInterface & {
  __typename?: 'GlobalFootprintTag';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  rulesList: Scalars['JSONString']['output'];
  rulesSchemaVersion: Scalars['String']['output'];
  tagName: Scalars['String']['output'];
  watershedEditor: Maybe<GQWatershedEmployee>;
};

export type GQGrantContractorAccessToOrgInput = {
  accessReason: Scalars['String']['input'];
  email: Scalars['String']['input'];
  expiresAt: Scalars['DateTime']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQGrantContractorAccessToOrgPayload = {
  __typename?: 'GrantContractorAccessToOrgPayload';
  user: GQUser;
};

export type GQGrantCurrentUserAdminAccessToOrgInput = {
  accessReason: Scalars['String']['input'];
  expiresAt: Scalars['DateTime']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQGrantCurrentUserAdminAccessToOrgPayload = {
  __typename?: 'GrantCurrentUserAdminAccessToOrgPayload';
  user: GQUser;
};

export type GQGrantFinanceUsersPortcoAccessesInput = {
  assetManagerUserIds: Array<Scalars['ID']['input']>;
  portcoOrgIds: Array<Scalars['ID']['input']>;
};

export type GQGrantFinanceUsersPortcoAccessesPayload = {
  __typename?: 'GrantFinanceUsersPortcoAccessesPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQGrantUserRoleInput = {
  orgAccessId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  roleId: Scalars['ID']['input'];
};

export type GQGrantUserRolePayload = {
  __typename?: 'GrantUserRolePayload';
  userRoleAssignment: Maybe<GQUserRoleAssignment>;
};

export type GQGridColumnConfiguration = {
  columnFormat: GQGridColumnFormat;
};

export type GQGridColumnConfigurationMultiSelect = GQGridColumnConfiguration & {
  __typename?: 'GridColumnConfigurationMultiSelect';
  columnFormat: GQGridColumnFormat;
  options: Array<Scalars['String']['output']>;
};

export type GQGridColumnConfigurationSingleSelect =
  GQGridColumnConfiguration & {
    __typename?: 'GridColumnConfigurationSingleSelect';
    columnFormat: GQGridColumnFormat;
    options: Array<Scalars['String']['output']>;
  };

export const GQGridColumnFormat = {
  Boolean: 'Boolean',
  Checkbox: 'Checkbox',
  Country: 'Country',
  Currency: 'Currency',
  Date: 'Date',
  DateTime: 'DateTime',
  Float: 'Float',
  Integer: 'Integer',
  Metric: 'Metric',
  MultiSelect: 'MultiSelect',
  NullableCheckbox: 'NullableCheckbox',
  Percentage: 'Percentage',
  PercentageChange: 'PercentageChange',
  SingleSelect: 'SingleSelect',
  String: 'String',
} as const;

export type GQGridColumnFormat =
  (typeof GQGridColumnFormat)[keyof typeof GQGridColumnFormat];
export type GQGroupedAuditLogConnection = {
  __typename?: 'GroupedAuditLogConnection';
  edges: Array<Maybe<GQGroupedAuditLogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQGroupedAuditLogEdge = {
  __typename?: 'GroupedAuditLogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQGroupedAuditLogEntry>;
};

export type GQGroupedAuditLogEntry = {
  __typename?: 'GroupedAuditLogEntry';
  createdAt: Scalars['DateTime']['output'];
  eventKind: Scalars['String']['output'];
  firstEventMetadata: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  numEvents: Scalars['Int']['output'];
  objectKind: Maybe<Scalars['String']['output']>;
  user: Maybe<GQUser>;
  userId: Maybe<Scalars['String']['output']>;
};

export const GQGrowthFactor = {
  Headcount: 'Headcount',
  Revenue: 'Revenue',
} as const;

export type GQGrowthFactor =
  (typeof GQGrowthFactor)[keyof typeof GQGrowthFactor];
export type GQGrowthFactorDataPoint = {
  __typename?: 'GrowthFactorDataPoint';
  customIntensityConfigId: Maybe<Scalars['ID']['output']>;
  growthFactorType: GQGrowthFactorType;
  value: Maybe<Scalars['Float']['output']>;
};

export const GQGrowthFactorType = {
  Custom: 'Custom',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  NightsStayed: 'NightsStayed',
  Orders: 'Orders',
  Patients: 'Patients',
  Revenue: 'Revenue',
  SupplierSpend: 'SupplierSpend',
} as const;

export type GQGrowthFactorType =
  (typeof GQGrowthFactorType)[keyof typeof GQGrowthFactorType];
export type GQGrowthForecast = {
  __typename?: 'GrowthForecast';
  customIntensityConfigId: Maybe<Scalars['ID']['output']>;
  forecast: GQSimpleTimeseries;
  growthFactorType: GQGrowthFactorType;
  hasUserInputtedHistoricalPeriod: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDefaultScalingFactor: Scalars['Boolean']['output'];
  scalingFactorFilters: Maybe<GQFilterExpressionGroupWithNewFilters>;
};

export type GQGrowthForecastInput = {
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  forecast?: InputMaybe<GQSimpleTimeseriesInput>;
  growthFactorType: GQGrowthFactorType;
  historicalGrowthValues?: InputMaybe<GQCreateSimpleTimeseriesInput>;
  isDefaultScalingFactor?: InputMaybe<Scalars['Boolean']['input']>;
  scalingFactorFilters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQHasPermissionInput = {
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  permissionType: GQPermissionType;
};

export type GQHistoricalUserInputtedGrowthFactorDataPoint = {
  __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
  date: Scalars['Date']['output'];
  growthFactors: Array<GQGrowthFactorDataPoint>;
};

export type GQIClimateProgramProject = {
  canonicalClimateProgramProject: Maybe<GQCanonicalClimateProgramProject>;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  endDate: Maybe<Scalars['Date']['output']>;
  iconType: GQClimateProgramProjectIconType;
  id: Scalars['ID']['output'];
  kind: GQClimateProgramProjectKind;
  name: Scalars['String']['output'];
  startDate: Maybe<Scalars['Date']['output']>;
};

export type GQIContact = {
  companyId: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  role: Maybe<Scalars['String']['output']>;
};

export type GQIdInterface = {
  id: Scalars['ID']['output'];
};

export type GQIFinanceAsset = {
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQIFinanceAssetAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceAssetAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceAssetFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceAssetRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceViewSection = {
  id: Scalars['ID']['output'];
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQIReportItem = {
  id: Scalars['ID']['output'];
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export type GQITcfdOpportunity = {
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  userDescription: Scalars['String']['output'];
};

export type GQITcfdRisk = {
  highTrajectoryImpact: GQTcfdRiskImpact;
  id: Scalars['ID']['output'];
  kind: GQTcfdRiskKind;
  lowTrajectoryImpact: GQTcfdRiskImpact;
  mediumTrajectoryImpact: GQTcfdRiskImpact;
  physicalRiskSeverity: Maybe<GQTcfdPhysicalRiskSeverity>;
  possibleImpactDescription: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userDescription: Scalars['String']['output'];
};

export const GQIciAlignmentStatus = {
  Aligned: 'Aligned',
  Aligning: 'Aligning',
  CapturingData: 'CapturingData',
  NotStarted: 'NotStarted',
  PreparingToDecarbonize: 'PreparingToDecarbonize',
} as const;

export type GQIciAlignmentStatus =
  (typeof GQIciAlignmentStatus)[keyof typeof GQIciAlignmentStatus];
export type GQIdInput = {
  id: Scalars['ID']['input'];
};

export const GQImportFlowDataCleaningStep = {
  AdditionalQuestions: 'AdditionalQuestions',
  ColumnMapping: 'ColumnMapping',
  DataFormat: 'DataFormat',
  DataMelt: 'DataMelt',
  FooterSelection: 'FooterSelection',
  HeaderSelection: 'HeaderSelection',
} as const;

export type GQImportFlowDataCleaningStep =
  (typeof GQImportFlowDataCleaningStep)[keyof typeof GQImportFlowDataCleaningStep];
export type GQImportMeasurementTestSuiteInput = {
  rawJson: Scalars['JSONString']['input'];
};

export type GQImportMeasurementTestSuitePayload = {
  __typename?: 'ImportMeasurementTestSuitePayload';
  id: Scalars['ID']['output'];
};

export type GQIndustryClassification = {
  __typename?: 'IndustryClassification';
  id: Scalars['ID']['output'];
  longDescription: Scalars['String']['output'];
  shortDescription: Scalars['String']['output'];
};

export type GQIngestedSbtiCompanyUpdate = GQIdInterface & {
  __typename?: 'IngestedSbtiCompanyUpdate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sbtiCompanyId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workflowId: Scalars['String']['output'];
};

export type GQIngestionCustomField = GQIdInterface & {
  __typename?: 'IngestionCustomField';
  appliesToAllDatasets: Scalars['Boolean']['output'];
  appliesToAllUtilityTypes: Scalars['Boolean']['output'];
  author: Maybe<GQUser>;
  bartTagName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  exampleValues: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  ingestionCustomFieldDatasources: Maybe<
    Array<GQIngestionCustomFieldDatasource>
  >;
  isMetric: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  possibleValues: Maybe<Array<Scalars['String']['output']>>;
  specificDatasets: Array<GQDataset>;
  specificUtilityTypes: Array<GQBuildingUtilityType>;
  type: GQIngestionCustomFieldType;
  usedValues: Array<Scalars['String']['output']>;
};

export type GQIngestionCustomFieldDatasource = GQIdInterface & {
  __typename?: 'IngestionCustomFieldDatasource';
  createdAt: Scalars['DateTime']['output'];
  datasetId: Scalars['ID']['output'];
  datasourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  ingestionCustomFieldId: Scalars['ID']['output'];
  ingestionCustomFieldValue: Scalars['String']['output'];
  numUserUploadTasks: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const GQIngestionCustomFieldType = {
  Boolean: 'Boolean',
  Date: 'Date',
  Number: 'Number',
  String: 'String',
  StringEnum: 'StringEnum',
} as const;

export type GQIngestionCustomFieldType =
  (typeof GQIngestionCustomFieldType)[keyof typeof GQIngestionCustomFieldType];
export type GQIngestionExampleData = {
  __typename?: 'IngestionExampleData';
  columns: Array<GQIngestionExampleDataColumn>;
  datasetId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  rows: Array<GQIngestionExampleDataRow>;
  title: Scalars['String']['output'];
};

export type GQIngestionExampleDataColumn = {
  __typename?: 'IngestionExampleDataColumn';
  defaultValue: Maybe<Scalars['JSONString']['output']>;
  description: Scalars['String']['output'];
  examples: Array<Scalars['String']['output']>;
  fieldName: Scalars['String']['output'];
  optionalToMap: Maybe<Scalars['Boolean']['output']>;
  required: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type GQIngestionExampleDataRow = {
  __typename?: 'IngestionExampleDataRow';
  fields: Array<GQIngestionExampleDataRowField>;
};

export type GQIngestionExampleDataRowField = {
  __typename?: 'IngestionExampleDataRowField';
  fieldName: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQIngestionInstructionStepInput = {
  descriptionMd: Scalars['String']['input'];
  fileMetadataId?: InputMaybe<Scalars['String']['input']>;
  remotePath?: InputMaybe<Scalars['String']['input']>;
};

export type GQIngestionInstructionsInput = {
  customExampleData?: InputMaybe<GQTabularDataInput>;
  ghgScopes?: InputMaybe<Array<GQGhgScope>>;
  instructionsDataFormattingMd?: InputMaybe<Scalars['String']['input']>;
  instructionsIntroMd?: InputMaybe<Scalars['String']['input']>;
  instructionsSteps?: InputMaybe<Array<GQIngestionInstructionStepInput>>;
  potentialOverlaps?: InputMaybe<Scalars['String']['input']>;
  sdiInstructionsDataFormattingMd?: InputMaybe<Scalars['String']['input']>;
  sdiInstructionsFaq?: InputMaybe<Array<GQSdiInstructionsFaq>>;
  sdiInstructionsIntroMd?: InputMaybe<Scalars['String']['input']>;
  sdiInstructionsSteps?: InputMaybe<Array<GQSdiIngestionInstructionStepInput>>;
  usedFor?: InputMaybe<Scalars['String']['input']>;
};

export type GQIngestionQuestion = GQIdInterface & {
  __typename?: 'IngestionQuestion';
  id: Scalars['ID']['output'];
  options: Array<GQIngestionQuestionOption>;
  responseType: GQIngestionQuestionResponseType;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GQIngestionQuestionAnswer = {
  __typename?: 'IngestionQuestionAnswer';
  booleanValue: Maybe<Scalars['Boolean']['output']>;
  numberValue: Maybe<Scalars['Int']['output']>;
  stringValue: Maybe<Scalars['String']['output']>;
  yearMonthValue: Maybe<Scalars['YearMonth']['output']>;
};

export const GQIngestionQuestionKey = {
  DataFormatIsUnmelted: 'DataFormatIsUnmelted',
  EmployeeTravelExpensesIncludedTravelExpenseTypes:
    'EmployeeTravelExpensesIncludedTravelExpenseTypes',
  EmployeeTravelExpensesPercentCoverage:
    'EmployeeTravelExpensesPercentCoverage',
  EmployeeTravelExpensesPercentCoverageIsUnknown:
    'EmployeeTravelExpensesPercentCoverageIsUnknown',
  EmployeeTravelExpensesTimeRangeEndDate:
    'EmployeeTravelExpensesTimeRangeEndDate',
  EmployeeTravelExpensesTimeRangeStartDate:
    'EmployeeTravelExpensesTimeRangeStartDate',
  EmployeesAccountedForCovid: 'EmployeesAccountedForCovid',
  EmployeesIncludedContractors: 'EmployeesIncludedContractors',
  EmployeesTimeDifferedBetweenOffices: 'EmployeesTimeDifferedBetweenOffices',
  EmployeesTimeDifferedBetweenOfficesAdditionalInfo:
    'EmployeesTimeDifferedBetweenOfficesAdditionalInfo',
  EmployeesWorkedRemotely: 'EmployeesWorkedRemotely',
  EmployeesWorkedRemotelyEndDate: 'EmployeesWorkedRemotelyEndDate',
  EmployeesWorkedRemotelyStartDate: 'EmployeesWorkedRemotelyStartDate',
  FlightsCancellationsIncluded: 'FlightsCancellationsIncluded',
  FlightsCancellationsIncludedColumn: 'FlightsCancellationsIncludedColumn',
  FlightsCancellationsIncludedOther: 'FlightsCancellationsIncludedOther',
  FlightsDataCoverageEndDate: 'FlightsDataCoverageEndDate',
  FlightsDataCoverageStartDate: 'FlightsDataCoverageStartDate',
  FlightsRoundTripsIncluded: 'FlightsRoundTripsIncluded',
  FlightsRoundTripsIncludedColumn: 'FlightsRoundTripsIncludedColumn',
  RailCancellationsIncluded: 'RailCancellationsIncluded',
  RailCancellationsIncludedColumn: 'RailCancellationsIncludedColumn',
  RailCancellationsIncludedOther: 'RailCancellationsIncludedOther',
  RailDataCoverageEndDate: 'RailDataCoverageEndDate',
  RailDataCoverageStartDate: 'RailDataCoverageStartDate',
  RailRoundTripsIncluded: 'RailRoundTripsIncluded',
  RailRoundTripsIncludedColumn: 'RailRoundTripsIncludedColumn',
} as const;

export type GQIngestionQuestionKey =
  (typeof GQIngestionQuestionKey)[keyof typeof GQIngestionQuestionKey];
export type GQIngestionQuestionOption = {
  __typename?: 'IngestionQuestionOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQIngestionQuestionResponse = {
  __typename?: 'IngestionQuestionResponse';
  answer: Maybe<GQIngestionQuestionAnswer>;
  questionId: Scalars['String']['output'];
  responseType: GQIngestionQuestionResponseType;
};

export const GQIngestionQuestionResponseType = {
  Boolean: 'Boolean',
  Number: 'Number',
  String: 'String',
  YearMonth: 'YearMonth',
} as const;

export type GQIngestionQuestionResponseType =
  (typeof GQIngestionQuestionResponseType)[keyof typeof GQIngestionQuestionResponseType];
export type GQInitializeDefaultSupplierViewAdminInput = {
  orgId: Scalars['ID']['input'];
};

export type GQInitializeDefaultSupplierViewAdminPayload = {
  __typename?: 'InitializeDefaultSupplierViewAdminPayload';
  view: GQSupplierView;
};

export type GQInitializeWorkosInput = {
  orgId: Scalars['ID']['input'];
};

export type GQInitializeWorkosPayload = {
  __typename?: 'InitializeWorkosPayload';
  org: GQOrganization;
};

export type GQInitiative = {
  costTimeseries: GQSimpleTimeseries;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  filters: GQFilterExpressionGroupWithNewFilters;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  plan: GQPlan;
  scheduleType: GQScheduleType;
};

export const GQInitiativeType = {
  Buildings: 'Buildings',
  Cloud: 'Cloud',
  Commute: 'Commute',
  Custom: 'Custom',
  Travel: 'Travel',
} as const;

export type GQInitiativeType =
  (typeof GQInitiativeType)[keyof typeof GQInitiativeType];
export type GQInsertReportQuestionIdentifierInput = {
  identifier: Scalars['String']['input'];
};

export type GQInstructionsBundle = {
  __typename?: 'InstructionsBundle';
  ghgScopes: Maybe<Array<GQGhgScope>>;
  instructionsDataFormattingMd: Maybe<Scalars['String']['output']>;
  instructionsIntroMd: Maybe<Scalars['String']['output']>;
  instructionsSteps: Maybe<Array<GQInstructionsStep>>;
  potentialOverlaps: Maybe<Scalars['String']['output']>;
  sdiInstructionsDataFormattingMd: Maybe<Scalars['String']['output']>;
  sdiInstructionsFaq: Maybe<Array<GQSdiInstructionsFaqType>>;
  sdiInstructionsIntroMd: Maybe<Scalars['String']['output']>;
  sdiInstructionsSteps: Maybe<Array<GQSdiInstructionsStep>>;
  usedFor: Maybe<Scalars['String']['output']>;
};

export type GQInstructionsStep = {
  __typename?: 'InstructionsStep';
  description: Scalars['String']['output'];
  descriptionMd: Scalars['String']['output'];
  fileMetadataId: Maybe<Scalars['String']['output']>;
  publicUrl: Maybe<Scalars['String']['output']>;
  remotePath: Maybe<Scalars['String']['output']>;
};

export type GQIntegrationConnection = GQIdInterface & {
  __typename?: 'IntegrationConnection';
  additionalIdentifier: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  datasources: GQDatasourceConnection;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  integrationDataPulls: GQIntegrationDataPullConnection;
  integrationPartner: GQIntegrationPartner;
  integrationPartnerId: Scalars['ID']['output'];
  integrationRequests: GQIntegrationRequestConnection;
  isActive: Scalars['Boolean']['output'];
  netsuiteIntegrationSettings: Maybe<GQNetsuiteIntegrationSettings>;
  org: GQOrganization;
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQIntegrationConnectionDatasourcesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQIntegrationConnectionIntegrationDataPullsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQIntegrationConnectionIntegrationRequestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQIntegrationConnectionConnection = {
  __typename?: 'IntegrationConnectionConnection';
  edges: Array<Maybe<GQIntegrationConnectionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationConnectionEdge = {
  __typename?: 'IntegrationConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationConnection>;
};

export type GQIntegrationDataPull = GQIdInterface & {
  __typename?: 'IntegrationDataPull';
  adtName: Scalars['String']['output'];
  adtUrl: Scalars['String']['output'];
  backgroundJob: Maybe<GQBackgroundJob>;
  chunksFetched: Scalars['Int']['output'];
  completed: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQWatershedEmployee;
  endYearMonth: Scalars['YearMonth']['output'];
  filesProcessed: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  integrationConnection: GQIntegrationConnection;
  startYearMonth: Scalars['YearMonth']['output'];
  totalChunksToFetch: Maybe<Scalars['Int']['output']>;
  totalFilesToProcess: Maybe<Scalars['Int']['output']>;
  userUploadedTable: Maybe<GQUserUploadedTable>;
};

export type GQIntegrationDataPullConnection = {
  __typename?: 'IntegrationDataPullConnection';
  edges: Array<Maybe<GQIntegrationDataPullEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationDataPullEdge = {
  __typename?: 'IntegrationDataPullEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationDataPull>;
};

export type GQIntegrationPartner = GQIdInterface & {
  __typename?: 'IntegrationPartner';
  activeIntegrationConnections: Array<GQIntegrationConnection>;
  applicableDatasources: Array<GQDatasource>;
  dataType: Scalars['String']['output'];
  externalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isRequestOnly: Scalars['Boolean']['output'];
  logoUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GQIntegrationPartnerConnection = {
  __typename?: 'IntegrationPartnerConnection';
  edges: Array<Maybe<GQIntegrationPartnerEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationPartnerEdge = {
  __typename?: 'IntegrationPartnerEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationPartner>;
};

export type GQIntegrationRequest = {
  __typename?: 'IntegrationRequest';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  integrationConnectionId: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  requestBody: Maybe<Scalars['String']['output']>;
  requestMethod: Scalars['String']['output'];
  requestUrl: Maybe<Scalars['String']['output']>;
  responseBodyFileMetadataId: Maybe<Scalars['ID']['output']>;
  responseFileMetadata: Maybe<GQFileMetadata>;
  responseStatus: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQIntegrationRequestConnection = {
  __typename?: 'IntegrationRequestConnection';
  edges: Array<Maybe<GQIntegrationRequestEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationRequestEdge = {
  __typename?: 'IntegrationRequestEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationRequest>;
};

export const GQIntensityDenominatorKind = {
  Gmv: 'GMV',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  Megawatts: 'Megawatts',
  Merchants: 'Merchants',
  MonthlyActiveUsers: 'MonthlyActiveUsers',
  NetRevenue: 'NetRevenue',
  NightsStayed: 'NightsStayed',
  OperationalRevenue: 'OperationalRevenue',
  Orders: 'Orders',
  OrdersKg: 'OrdersKg',
  Patients: 'Patients',
  PayingSitesUnderManagement: 'PayingSitesUnderManagement',
  Revenue: 'Revenue',
  Shipments: 'Shipments',
  SquareFeet: 'SquareFeet',
  SupplierSpend: 'SupplierSpend',
  Units: 'Units',
} as const;

export type GQIntensityDenominatorKind =
  (typeof GQIntensityDenominatorKind)[keyof typeof GQIntensityDenominatorKind];
export type GQInterimTargetInput = {
  reductionAmount?: InputMaybe<Scalars['Float']['input']>;
  targetYearInclusive?: InputMaybe<Scalars['YearMonth']['input']>;
};

export const GQInternalFieldWatershed = {
  InternalCategoryId: 'internalCategoryId',
  InternalDescription: 'internalDescription',
  InternalGhgCategoryId: 'internalGhgCategoryId',
  InternalSubcategoryId: 'internalSubcategoryId',
} as const;

export type GQInternalFieldWatershed =
  (typeof GQInternalFieldWatershed)[keyof typeof GQInternalFieldWatershed];
export type GQInvalidateBackgroundJobCachePayload = {
  __typename?: 'InvalidateBackgroundJobCachePayload';
  success: Scalars['Boolean']['output'];
};

export type GQInvalidateMeasurementTestSuitePagePayload = {
  __typename?: 'InvalidateMeasurementTestSuitePagePayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQLabeledTimeseries = {
  __typename?: 'LabeledTimeseries';
  label: Scalars['String']['output'];
  timeseries: GQTimeseries;
};

export type GQLegacyAssumptionAsMeasurementMapping =
  GQMeasurementMappingFields & {
    __typename?: 'LegacyAssumptionAsMeasurementMapping';
    author: GQWatershedEmployee;
    authorExplanation: Maybe<Scalars['String']['output']>;
    categoryId: Maybe<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    endMonth: Scalars['YearMonth']['output'];
    ghgCategoryId: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    inputEmissionsSpecifier: Scalars['String']['output'];
    inputEmissionsSubspecifier: Scalars['String']['output'];
    outputEmissionsSpecifier: Scalars['String']['output'];
    outputEmissionsSubspecifier: Scalars['String']['output'];
    shouldInclude: Scalars['Boolean']['output'];
    startMonth: Scalars['YearMonth']['output'];
    subcategoryId: Maybe<Scalars['String']['output']>;
    updatedAt: Maybe<Scalars['DateTime']['output']>;
    vendorEntity: Scalars['String']['output'];
  };

export type GQLegacyAssumptionAsMeasurementMappingConnection = {
  __typename?: 'LegacyAssumptionAsMeasurementMappingConnection';
  edges: Array<Maybe<GQLegacyAssumptionAsMeasurementMappingEdge>>;
  pageInfo: GQPageInfo;
};

export type GQLegacyAssumptionAsMeasurementMappingEdge = {
  __typename?: 'LegacyAssumptionAsMeasurementMappingEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQLegacyAssumptionAsMeasurementMapping>;
};

export type GQLifecycleAssessment = {
  __typename?: 'LifecycleAssessment';
  boundary: GQLifecycleAssessmentBoundaryEnum;
  createdAt: Scalars['DateTime']['output'];
  dataUsedNotes: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  productDescription: Maybe<Scalars['String']['output']>;
  productName: Scalars['String']['output'];
  productUnit: Scalars['String']['output'];
  productionGraphData: Maybe<GQProductionGraphData>;
  referenceEndDate: Scalars['DateTime']['output'];
  referenceStartDate: Scalars['DateTime']['output'];
  supplierProductExternalId: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  validityEndDate: Scalars['DateTime']['output'];
  validityStartDate: Scalars['DateTime']['output'];
};

export const GQLifecycleAssessmentBoundaryEnum = {
  CradleToGate: 'CradleToGate',
  CradleToGrave: 'CradleToGrave',
  CradleToSite: 'CradleToSite',
  GateToGate: 'GateToGate',
} as const;

export type GQLifecycleAssessmentBoundaryEnum =
  (typeof GQLifecycleAssessmentBoundaryEnum)[keyof typeof GQLifecycleAssessmentBoundaryEnum];
export const GQLifecycleStage = {
  A1: 'A1',
  A2: 'A2',
  A3: 'A3',
  A4: 'A4',
} as const;

export type GQLifecycleStage =
  (typeof GQLifecycleStage)[keyof typeof GQLifecycleStage];
export const GQLineageDirection = {
  Downstream: 'Downstream',
  Upstream: 'Upstream',
} as const;

export type GQLineageDirection =
  (typeof GQLineageDirection)[keyof typeof GQLineageDirection];
export type GQLineageRun = {
  __typename?: 'LineageRun';
  additionalOutput: Maybe<Scalars['JSONString']['output']>;
  endedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  jobSlug: Scalars['String']['output'];
  parameters: Maybe<Scalars['JSONString']['output']>;
  startedAt: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
};

export type GQLinkAndUnlinkEmissionsModelsToReleaseInput = {
  emissionsModelStableIds: Array<Scalars['ID']['input']>;
  releaseId: Scalars['ID']['input'];
};

export type GQLinkGlobalFootprintTagsToReleaseInput = {
  globalFootprintTagInputs: Array<GQEmReleaseGlobalFootprintTagInput>;
  releaseId: Scalars['ID']['input'];
};

export type GQLintReportConfigInput = {
  id: Scalars['ID']['input'];
};

export type GQLintReportConfigPayload = {
  __typename?: 'LintReportConfigPayload';
  lintErrors: Array<GQConfigLinterError>;
  status: GQConfigLinterStatus;
};

export type GQLoginAsUserInput = {
  targetOrgId: Scalars['String']['input'];
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};

export type GQLoginAsUserPayload = {
  __typename?: 'LoginAsUserPayload';
  isSuccessful: Scalars['Boolean']['output'];
  token: Maybe<Scalars['String']['output']>;
};

export type GQMakeFootprintSnapshotUserVisibleInput = {
  footprintSnapshotId: Scalars['ID']['input'];
};

export type GQMappedExternalReportQuestions = {
  __typename?: 'MappedExternalReportQuestions';
  id: Scalars['ID']['output'];
  identicalQuestions: Array<GQExternalReportQuestion>;
  relatedQuestions: Array<GQExternalReportQuestion>;
};

export type GQMappingCategoryIds = {
  __typename?: 'MappingCategoryIds';
  categoryId: Array<GQCategoryInfo>;
  ghgCategoryId: Array<GQCategoryInfo>;
  subcategoryId: Array<GQCategoryInfo>;
};

export type GQMarkAdminUploadCompletedInput = {
  fileId: Scalars['ID']['input'];
  parse: Scalars['Boolean']['input'];
};

export type GQMarkAdminUploadCompletedPayload = {
  __typename?: 'MarkAdminUploadCompletedPayload';
  file: GQFileMetadata;
  parseJobId: Maybe<Scalars['ID']['output']>;
};

export type GQMarkMeasurementProjectDatasetCompletedInput = {
  isCompleted: Scalars['Boolean']['input'];
  measurementProjectDatasetId: Scalars['ID']['input'];
};

export type GQMarkMeasurementProjectDatasetCompletedPayload = {
  __typename?: 'MarkMeasurementProjectDatasetCompletedPayload';
  measurementProject: GQMeasurementProject;
  measurementProjectDataset: GQMeasurementProjectDataset;
};

export type GQMarkUserUploadAcknowledgedInput = {
  id: Scalars['ID']['input'];
};

export type GQMarkUserUploadAcknowledgedPayload = {
  __typename?: 'MarkUserUploadAcknowledgedPayload';
  userUpload: GQUserUpload;
};

export type GQMarkUserUploadedTablesIgnoredInput = {
  userUploadedTableIds: Array<Scalars['ID']['input']>;
};

export type GQMarkUserUploadedTablesIgnoredPayload = {
  __typename?: 'MarkUserUploadedTablesIgnoredPayload';
  userUploadedTables: Array<GQUserUploadedTable>;
};

export type GQMarkdownCardInfo = {
  __typename?: 'MarkdownCardInfo';
  contentMd: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQMarketplaceAllocationInstruction = {
  __typename?: 'MarketplaceAllocationInstruction';
  amountKwh: Scalars['Int']['output'];
  buildingName: Maybe<Scalars['String']['output']>;
  categoryId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  footprintKind: Maybe<Scalars['String']['output']>;
  ghgCategoryId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
  isZeroedOut: Scalars['Boolean']['output'];
  locationCity: Maybe<Scalars['String']['output']>;
  locationCountry: Maybe<Scalars['String']['output']>;
  locationGrid: Maybe<Scalars['String']['output']>;
  locationState: Maybe<Scalars['String']['output']>;
  product: Maybe<Scalars['String']['output']>;
  purchaseLineItem: GQMarketplacePurchaseLineItem;
  results: Maybe<GQMarketplaceAllocationInstructionResultConnection>;
  subcategoryId: Maybe<Scalars['String']['output']>;
  usedInFootprintSnapshot: Scalars['Boolean']['output'];
  vendorEntity: Maybe<Scalars['String']['output']>;
  vendorSubentity: Maybe<Scalars['String']['output']>;
};

export type GQMarketplaceAllocationInstructionResultsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceAllocationInstructionConnection = {
  __typename?: 'MarketplaceAllocationInstructionConnection';
  edges: Array<Maybe<GQMarketplaceAllocationInstructionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceAllocationInstructionEdge = {
  __typename?: 'MarketplaceAllocationInstructionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceAllocationInstruction>;
};

export type GQMarketplaceAllocationInstructionImpact = GQIdInterface & {
  __typename?: 'MarketplaceAllocationInstructionImpact';
  allocatedKwh: Scalars['Float']['output'];
  amountKwh: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplaceAllocationInstructionResult = {
  __typename?: 'MarketplaceAllocationInstructionResult';
  amountAllocatedKwh: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isZeroedOut: Scalars['Boolean']['output'];
};

export type GQMarketplaceAllocationInstructionResultConnection = {
  __typename?: 'MarketplaceAllocationInstructionResultConnection';
  edges: Array<Maybe<GQMarketplaceAllocationInstructionResultEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceAllocationInstructionResultEdge = {
  __typename?: 'MarketplaceAllocationInstructionResultEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceAllocationInstructionResult>;
};

export type GQMarketplaceDeveloper = GQIdInterface & {
  __typename?: 'MarketplaceDeveloper';
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMd: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  projects: GQMarketplaceProjectConnection;
  url: Maybe<Scalars['String']['output']>;
};

export type GQMarketplaceDeveloperConnection = {
  __typename?: 'MarketplaceDeveloperConnection';
  edges: Array<Maybe<GQMarketplaceDeveloperEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceDeveloperEdge = {
  __typename?: 'MarketplaceDeveloperEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceDeveloper>;
};

export type GQMarketplaceDocument = GQIdInterface & {
  __typename?: 'MarketplaceDocument';
  createdAt: Scalars['Date']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  downloadUrl: Scalars['String']['output'];
  fileMetadata: GQFileMetadata;
  id: Scalars['ID']['output'];
  kind: GQMarketplaceDocumentKind;
  name: Scalars['String']['output'];
  noteMd: Maybe<Scalars['String']['output']>;
  org: GQOrganization;
  purchase: Maybe<GQMarketplacePurchase>;
  purchaseLineItem: Maybe<GQMarketplacePurchaseLineItem>;
  updatedAt: Scalars['Date']['output'];
  uploader: Maybe<GQWatershedEmployee>;
};

export type GQMarketplaceDocumentConnection = {
  __typename?: 'MarketplaceDocumentConnection';
  edges: Array<Maybe<GQMarketplaceDocumentEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceDocumentEdge = {
  __typename?: 'MarketplaceDocumentEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceDocument>;
};

export const GQMarketplaceDocumentKind = {
  ContractAppendix: 'ContractAppendix',
  ContractMasterAgreement: 'ContractMasterAgreement',
  ContractSupplierErpa: 'ContractSupplierERPA',
  ContractSupplierOffering: 'ContractSupplierOffering',
  DeliveryCertificateSupplier: 'DeliveryCertificateSupplier',
  DeliveryCertificateWatershed: 'DeliveryCertificateWatershed',
  InvoiceCustomer: 'InvoiceCustomer',
  InvoiceSupplier: 'InvoiceSupplier',
  RetirementNotice: 'RetirementNotice',
} as const;

export type GQMarketplaceDocumentKind =
  (typeof GQMarketplaceDocumentKind)[keyof typeof GQMarketplaceDocumentKind];
export type GQMarketplacePointOfContact = GQIdInterface & {
  __typename?: 'MarketplacePointOfContact';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQMarketplacePointOfContactConnection = {
  __typename?: 'MarketplacePointOfContactConnection';
  edges: Array<Maybe<GQMarketplacePointOfContactEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePointOfContactEdge = {
  __typename?: 'MarketplacePointOfContactEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePointOfContact>;
};

export type GQMarketplaceProject = GQIdInterface & {
  __typename?: 'MarketplaceProject';
  archetype: GQMarketplaceProjectArchetype;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  developer: Maybe<GQMarketplaceDeveloper>;
  id: Scalars['ID']['output'];
  internalNotesMd: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offerings: GQMarketplaceProjectOfferingConnection;
  registryId: Maybe<Scalars['String']['output']>;
  status: GQMarketplaceProjectStatus;
  supplier: Maybe<GQMarketplaceSupplier>;
};

export type GQMarketplaceProjectOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetype = GQIdInterface & {
  __typename?: 'MarketplaceProjectArchetype';
  additionalityRating: Maybe<Scalars['Int']['output']>;
  availability: Scalars['Float']['output'];
  bannerImageUrl: Maybe<Scalars['String']['output']>;
  certificateBlurb: Maybe<Scalars['String']['output']>;
  certificateColor: Maybe<Scalars['String']['output']>;
  descriptionMd: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  isUserVisible: Scalars['Boolean']['output'];
  kind: GQMarketplaceProjectArchetypeKind;
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  medianPriceUsdCents: Maybe<Scalars['Int']['output']>;
  medianPriceWithFeeUsdCents: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  offerings: GQMarketplaceProjectOfferingConnection;
  permanenceRating: Maybe<Scalars['String']['output']>;
  priceUsdCentsMax: Maybe<Scalars['Int']['output']>;
  priceUsdCentsMin: Maybe<Scalars['Int']['output']>;
  projects: GQMarketplaceProjectConnection;
  summaryMd: Scalars['String']['output'];
  unit: GQMarketplaceProjectArchetypeUnit;
  visibleDevelopers: GQMarketplaceDeveloperConnection;
};

export type GQMarketplaceProjectArchetypeOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetypeProjectsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetypeVisibleDevelopersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetypeConnection = {
  __typename?: 'MarketplaceProjectArchetypeConnection';
  edges: Array<Maybe<GQMarketplaceProjectArchetypeEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceProjectArchetypeEdge = {
  __typename?: 'MarketplaceProjectArchetypeEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceProjectArchetype>;
};

export const GQMarketplaceProjectArchetypeKind = {
  CarbonOffset: 'CarbonOffset',
  CleanEnergyCertificate: 'CleanEnergyCertificate',
  HighDemandCarbonOffset: 'HighDemandCarbonOffset',
  MatureCarbonRemoval: 'MatureCarbonRemoval',
  RAndDCarbonRemoval: 'RAndDCarbonRemoval',
} as const;

export type GQMarketplaceProjectArchetypeKind =
  (typeof GQMarketplaceProjectArchetypeKind)[keyof typeof GQMarketplaceProjectArchetypeKind];
export const GQMarketplaceProjectArchetypeMechanism = {
  CleanPower: 'CleanPower',
  Offset: 'Offset',
  Removal: 'Removal',
} as const;

export type GQMarketplaceProjectArchetypeMechanism =
  (typeof GQMarketplaceProjectArchetypeMechanism)[keyof typeof GQMarketplaceProjectArchetypeMechanism];
export const GQMarketplaceProjectArchetypeUnit = {
  MWh: 'MWh',
  Tonnes: 'Tonnes',
} as const;

export type GQMarketplaceProjectArchetypeUnit =
  (typeof GQMarketplaceProjectArchetypeUnit)[keyof typeof GQMarketplaceProjectArchetypeUnit];
export type GQMarketplaceProjectConnection = {
  __typename?: 'MarketplaceProjectConnection';
  edges: Array<Maybe<GQMarketplaceProjectEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceProjectEdge = {
  __typename?: 'MarketplaceProjectEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceProject>;
};

export type GQMarketplaceProjectOffering = GQIdInterface & {
  __typename?: 'MarketplaceProjectOffering';
  availability: Scalars['Float']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  noteMd: Maybe<Scalars['String']['output']>;
  priceUsdCents: Scalars['Float']['output'];
  priceWithFeeUsdCents: Scalars['Float']['output'];
  project: GQMarketplaceProject;
  supplier: GQMarketplaceSupplier;
  vintage: Maybe<Scalars['Float']['output']>;
};

export type GQMarketplaceProjectOfferingConnection = {
  __typename?: 'MarketplaceProjectOfferingConnection';
  edges: Array<Maybe<GQMarketplaceProjectOfferingEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceProjectOfferingEdge = {
  __typename?: 'MarketplaceProjectOfferingEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceProjectOffering>;
};

export const GQMarketplaceProjectStatus = {
  Active: 'Active',
  LowQuality: 'LowQuality',
  Unknown: 'Unknown',
} as const;

export type GQMarketplaceProjectStatus =
  (typeof GQMarketplaceProjectStatus)[keyof typeof GQMarketplaceProjectStatus];
export type GQMarketplacePurchase = GQIdInterface &
  GQMarketplacePurchaseTrackedProperties & {
    __typename?: 'MarketplacePurchase';
    createdAt: Scalars['DateTime']['output'];
    customerStatus: GQCustomerStatus;
    customerUser: Maybe<GQUser>;
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    documents: GQMarketplaceDocumentConnection;
    eacMetadata: Maybe<GQMarketplacePurchaseEacMetadata>;
    editor: Maybe<GQTrackedEditor>;
    history: GQMarketplacePurchaseHistoryConnection;
    id: Scalars['ID']['output'];
    lineItems: GQMarketplacePurchaseLineItemConnection;
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offerExpiration: Maybe<Scalars['DateTime']['output']>;
    org: GQOrganization;
    status: GQMarketplacePurchaseStatus;
    totalCostUsdCents: Scalars['Int53']['output'];
    totalFeeUsdCents: Scalars['Int53']['output'];
    updatedAt: Scalars['DateTime']['output'];
    waitingOnParty: Maybe<GQMarketplaceWaitingOnParty>;
  };

export type GQMarketplacePurchaseDocumentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseLineItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseConnection = {
  __typename?: 'MarketplacePurchaseConnection';
  edges: Array<Maybe<GQMarketplacePurchaseEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseEacMetadata = {
  __typename?: 'MarketplacePurchaseEacMetadata';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  ghgCategoryIds: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
  marketTco2e: Scalars['Float']['output'];
  org: GQOrganization;
  purchase: GQMarketplacePurchase;
  requestNotes: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplacePurchaseEdge = {
  __typename?: 'MarketplacePurchaseEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchase>;
};

export type GQMarketplacePurchaseHistory = GQIdInterface &
  GQMarketplacePurchaseTrackedProperties & {
    __typename?: 'MarketplacePurchaseHistory';
    createdAt: Scalars['DateTime']['output'];
    customerUser: Maybe<GQUser>;
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    editor: Maybe<GQTrackedEditor>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offerExpiration: Maybe<Scalars['DateTime']['output']>;
    org: GQOrganization;
    purchaseId: Scalars['ID']['output'];
    recordEndAt: Maybe<Scalars['DateTime']['output']>;
    recordStartAt: Scalars['DateTime']['output'];
    status: GQMarketplacePurchaseStatus;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQMarketplacePurchaseHistoryConnection = {
  __typename?: 'MarketplacePurchaseHistoryConnection';
  edges: Array<Maybe<GQMarketplacePurchaseHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseHistoryEdge = {
  __typename?: 'MarketplacePurchaseHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchaseHistory>;
};

export type GQMarketplacePurchaseLineItem = GQIdInterface &
  GQMarketplacePurchaseLineItemTrackedProperties & {
    __typename?: 'MarketplacePurchaseLineItem';
    allocations: GQMarketplaceAllocationInstructionConnection;
    amount: Scalars['Float']['output'];
    amountUnit: GQMarketplaceProjectArchetypeUnit;
    appliedToFootprint: Maybe<Scalars['Boolean']['output']>;
    archetype: Maybe<GQMarketplaceProjectArchetype>;
    cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
    closeDate: Maybe<Scalars['Date']['output']>;
    coverageInterval: Scalars['YMInterval']['output'];
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    deliveryDate: Maybe<Scalars['Date']['output']>;
    documents: GQMarketplaceDocumentConnection;
    eacMetadata: Maybe<GQMarketplacePurchaseLineItemEacMetadata>;
    editor: Maybe<GQTrackedEditor>;
    history: GQMarketplacePurchaseLineItemHistoryConnection;
    id: Scalars['ID']['output'];
    isClosed: Scalars['Boolean']['output'];
    isConfirmed: Scalars['Boolean']['output'];
    isDelivered: Scalars['Boolean']['output'];
    mechanism: GQMarketplaceProjectArchetypeMechanism;
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offering: Maybe<GQMarketplaceProjectOffering>;
    org: GQOrganization;
    project: Maybe<GQMarketplaceProject>;
    purchase: GQMarketplacePurchase;
    status: GQMarketplacePurchaseLineItemStatus;
    unitCostUsdCents: Scalars['Float']['output'];
    unitFeeUsdCents: Scalars['Float']['output'];
    unitPriceUsdCents: Scalars['Float']['output'];
    updatedAt: Scalars['DateTime']['output'];
    vintage: Maybe<Scalars['Int']['output']>;
    waitingOnParty: Maybe<GQMarketplaceWaitingOnParty>;
  };

export type GQMarketplacePurchaseLineItemAllocationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseLineItemDocumentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseLineItemConnection = {
  __typename?: 'MarketplacePurchaseLineItemConnection';
  edges: Array<Maybe<GQMarketplacePurchaseLineItemEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseLineItemEacMetadata = {
  __typename?: 'MarketplacePurchaseLineItemEacMetadata';
  countryWithEacs: Scalars['String']['output'];
  countryWithEmissions: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  marketTco2e: Scalars['Float']['output'];
  mwh: Scalars['Float']['output'];
  numEacsRequested: Scalars['Int']['output'];
  org: GQOrganization;
  purchaseLineItem: GQMarketplacePurchaseLineItem;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplacePurchaseLineItemEdge = {
  __typename?: 'MarketplacePurchaseLineItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchaseLineItem>;
};

export type GQMarketplacePurchaseLineItemHistory = GQIdInterface &
  GQMarketplacePurchaseLineItemTrackedProperties & {
    __typename?: 'MarketplacePurchaseLineItemHistory';
    amount: Scalars['Float']['output'];
    amountUnit: GQMarketplaceProjectArchetypeUnit;
    archetype: Maybe<GQMarketplaceProjectArchetype>;
    cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
    closeDate: Maybe<Scalars['Date']['output']>;
    coverageInterval: Scalars['YMInterval']['output'];
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    deliveryDate: Maybe<Scalars['Date']['output']>;
    editor: Maybe<GQTrackedEditor>;
    id: Scalars['ID']['output'];
    isClosed: Scalars['Boolean']['output'];
    isDelivered: Scalars['Boolean']['output'];
    lineItemId: Scalars['ID']['output'];
    mechanism: GQMarketplaceProjectArchetypeMechanism;
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offering: Maybe<GQMarketplaceProjectOffering>;
    org: GQOrganization;
    project: Maybe<GQMarketplaceProject>;
    purchase: GQMarketplacePurchase;
    recordEndAt: Maybe<Scalars['DateTime']['output']>;
    recordStartAt: Scalars['DateTime']['output'];
    status: GQMarketplacePurchaseLineItemStatus;
    unitCostUsdCents: Scalars['Float']['output'];
    unitFeeUsdCents: Scalars['Float']['output'];
    unitPriceUsdCents: Scalars['Float']['output'];
    updatedAt: Scalars['DateTime']['output'];
    vintage: Maybe<Scalars['Int']['output']>;
  };

export type GQMarketplacePurchaseLineItemHistoryConnection = {
  __typename?: 'MarketplacePurchaseLineItemHistoryConnection';
  edges: Array<Maybe<GQMarketplacePurchaseLineItemHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseLineItemHistoryEdge = {
  __typename?: 'MarketplacePurchaseLineItemHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchaseLineItemHistory>;
};

export const GQMarketplacePurchaseLineItemStatus = {
  Draft: 'Draft',
  SupplierDeliveredToWatershed: 'SupplierDeliveredToWatershed',
  SupplierSentInvoiceToWatershed: 'SupplierSentInvoiceToWatershed',
  WatershedDeliveredToCustomer: 'WatershedDeliveredToCustomer',
  WatershedPaidInvoiceToSupplier: 'WatershedPaidInvoiceToSupplier',
  WatershedSentRequestToSupplier: 'WatershedSentRequestToSupplier',
} as const;

export type GQMarketplacePurchaseLineItemStatus =
  (typeof GQMarketplacePurchaseLineItemStatus)[keyof typeof GQMarketplacePurchaseLineItemStatus];
export type GQMarketplacePurchaseLineItemTrackedProperties = {
  amount: Scalars['Float']['output'];
  amountUnit: GQMarketplaceProjectArchetypeUnit;
  archetype: Maybe<GQMarketplaceProjectArchetype>;
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  closeDate: Maybe<Scalars['Date']['output']>;
  coverageInterval: Scalars['YMInterval']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  deliveryDate: Maybe<Scalars['Date']['output']>;
  editor: Maybe<GQTrackedEditor>;
  isClosed: Scalars['Boolean']['output'];
  isDelivered: Scalars['Boolean']['output'];
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  name: Scalars['String']['output'];
  noteMd: Scalars['String']['output'];
  offering: Maybe<GQMarketplaceProjectOffering>;
  project: Maybe<GQMarketplaceProject>;
  purchase: GQMarketplacePurchase;
  status: GQMarketplacePurchaseLineItemStatus;
  unitCostUsdCents: Scalars['Float']['output'];
  unitFeeUsdCents: Scalars['Float']['output'];
  unitPriceUsdCents: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vintage: Maybe<Scalars['Int']['output']>;
};

export const GQMarketplacePurchaseStatus = {
  CustomerCanceled: 'CustomerCanceled',
  CustomerConfirmedPurchase: 'CustomerConfirmedPurchase',
  CustomerInitiatedPurchase: 'CustomerInitiatedPurchase',
  CustomerPaidInvoiceAwaitingDelivery: 'CustomerPaidInvoiceAwaitingDelivery',
  CustomerRequestToWatershed: 'CustomerRequestToWatershed',
  CustomerSignedContract: 'CustomerSignedContract',
  WatershedConfirmedForCustomer: 'WatershedConfirmedForCustomer',
  WatershedFullyDelivered: 'WatershedFullyDelivered',
  WatershedPartiallyDelivered: 'WatershedPartiallyDelivered',
  WatershedSentContract: 'WatershedSentContract',
  WatershedSentInvoice: 'WatershedSentInvoice',
} as const;

export type GQMarketplacePurchaseStatus =
  (typeof GQMarketplacePurchaseStatus)[keyof typeof GQMarketplacePurchaseStatus];
export type GQMarketplacePurchaseTrackedProperties = {
  createdAt: Scalars['DateTime']['output'];
  customerUser: Maybe<GQUser>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  editor: Maybe<GQTrackedEditor>;
  name: Scalars['String']['output'];
  noteMd: Scalars['String']['output'];
  offerExpiration: Maybe<Scalars['DateTime']['output']>;
  status: GQMarketplacePurchaseStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplaceSupplier = GQIdInterface & {
  __typename?: 'MarketplaceSupplier';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offerings: GQMarketplaceProjectOfferingConnection;
  pointsOfContact: Array<GQMarketplacePointOfContact>;
  projects: GQMarketplaceProjectConnection;
  watershedEmployees: Array<GQWatershedEmployee>;
};

export type GQMarketplaceSupplierOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceSupplierProjectsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceSupplierConnection = {
  __typename?: 'MarketplaceSupplierConnection';
  edges: Array<Maybe<GQMarketplaceSupplierEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceSupplierEdge = {
  __typename?: 'MarketplaceSupplierEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceSupplier>;
};

export type GQMarketplaceVoluntaryCarbonMarketDisclosure = {
  __typename?: 'MarketplaceVoluntaryCarbonMarketDisclosure';
  url: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export const GQMarketplaceWaitingOnParty = {
  Customer: 'Customer',
  Supplier: 'Supplier',
  Watershed: 'Watershed',
} as const;

export type GQMarketplaceWaitingOnParty =
  (typeof GQMarketplaceWaitingOnParty)[keyof typeof GQMarketplaceWaitingOnParty];
export type GQMaterial = {
  __typename?: 'Material';
  consumingFacilityExternalId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  materialExternalId: Scalars['String']['output'];
  materialGroupExternalId: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  quantityUnit: Scalars['String']['output'];
  supplierExternalId: Maybe<Scalars['String']['output']>;
  supplierFacilityExternalId: Maybe<Scalars['String']['output']>;
  supplierMaterialExternalId: Maybe<Scalars['String']['output']>;
  tier: Scalars['Int']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMaterialVariant = {
  __typename?: 'MaterialVariant';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefaultVariant: Scalars['Boolean']['output'];
  lifecycleAssessment: Maybe<GQLifecycleAssessment>;
  lifecycleAssessmentId: Maybe<Scalars['ID']['output']>;
  materialExternalId: Maybe<Scalars['String']['output']>;
  materialGroupExternalId: Maybe<Scalars['String']['output']>;
  materialName: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  quantityUnit: Maybe<Scalars['String']['output']>;
  supplierExternalId: Maybe<Scalars['String']['output']>;
  supplierName: Maybe<Scalars['String']['output']>;
  tier: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMaterialityAssessment = {
  __typename?: 'MaterialityAssessment';
  createdAt: Scalars['DateTime']['output'];
  dataPoints: Array<GQMaterialityAssessmentDataPoint>;
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  org: GQOrganization;
  reports: Array<GQReport>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: GQUser;
};

export type GQMaterialityAssessmentDataPoint = {
  __typename?: 'MaterialityAssessmentDataPoint';
  frameworkDataPoint: GQReportingFrameworkDataPoint;
  id: Scalars['ID']['output'];
  status: GQMaterialityStatus;
};

export const GQMaterialityStatus = {
  Material: 'Material',
  NotMaterial: 'NotMaterial',
} as const;

export type GQMaterialityStatus =
  (typeof GQMaterialityStatus)[keyof typeof GQMaterialityStatus];
export type GQMeasuredFootprintDetails = {
  __typename?: 'MeasuredFootprintDetails';
  emissionsTco2e: Scalars['Float']['output'];
  footprintSharedAt: Scalars['Date']['output'];
};

export type GQMeasurementDataMappingTask = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'MeasurementDataMappingTask';
    completedAt: Maybe<Scalars['DateTime']['output']>;
    dataset: GQDataset;
    id: Scalars['ID']['output'];
    measurementProject: GQMeasurementProject;
  };

export type GQMeasurementMapping = GQIdInterface &
  GQMeasurementMappingFields & {
    __typename?: 'MeasurementMapping';
    author: GQWatershedEmployee;
    authorExplanation: Maybe<Scalars['String']['output']>;
    categoryId: Maybe<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    endMonth: Scalars['YearMonth']['output'];
    ghgCategoryId: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    inputEmissionsSpecifier: Scalars['String']['output'];
    inputEmissionsSubspecifier: Scalars['String']['output'];
    outputEmissionsSpecifier: Scalars['String']['output'];
    outputEmissionsSubspecifier: Scalars['String']['output'];
    shouldInclude: Scalars['Boolean']['output'];
    startMonth: Scalars['YearMonth']['output'];
    subcategoryId: Maybe<Scalars['String']['output']>;
    updatedAt: Maybe<Scalars['DateTime']['output']>;
    vendorEntity: Scalars['String']['output'];
  };

export type GQMeasurementMappingConnection = {
  __typename?: 'MeasurementMappingConnection';
  edges: Array<Maybe<GQMeasurementMappingEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementMappingEdge = {
  __typename?: 'MeasurementMappingEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMeasurementMapping>;
};

export type GQMeasurementMappingFields = {
  author: GQWatershedEmployee;
  authorExplanation: Maybe<Scalars['String']['output']>;
  categoryId: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  endMonth: Scalars['YearMonth']['output'];
  ghgCategoryId: Maybe<Scalars['String']['output']>;
  inputEmissionsSpecifier: Scalars['String']['output'];
  inputEmissionsSubspecifier: Scalars['String']['output'];
  outputEmissionsSpecifier: Scalars['String']['output'];
  outputEmissionsSubspecifier: Scalars['String']['output'];
  shouldInclude: Scalars['Boolean']['output'];
  startMonth: Scalars['YearMonth']['output'];
  subcategoryId: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  vendorEntity: Scalars['String']['output'];
};

export type GQMeasurementMappingOutputOption = {
  __typename?: 'MeasurementMappingOutputOption';
  description: Scalars['String']['output'];
  efKgCo2eAverage: Maybe<Scalars['Float']['output']>;
  efKgCo2eDescription: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inputEmissionsSpecifier: Scalars['String']['output'];
  outputEmissionsSpecifier: Scalars['String']['output'];
  outputEmissionsSubspecifier: Scalars['String']['output'];
  units: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMeasurementMappingOutputOptionConnection = {
  __typename?: 'MeasurementMappingOutputOptionConnection';
  edges: Array<Maybe<GQMeasurementMappingOutputOptionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementMappingOutputOptionEdge = {
  __typename?: 'MeasurementMappingOutputOptionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMeasurementMappingOutputOption>;
};

export type GQMeasurementParentTask = GQIdInterface &
  GQMeasurementTask & {
    __typename?: 'MeasurementParentTask';
    approvers: Array<GQUser>;
    assignees: Array<GQUser>;
    dataset: Maybe<GQDataset>;
    id: Scalars['ID']['output'];
    isAssignedToWatershed: Scalars['Boolean']['output'];
    link: Maybe<Scalars['String']['output']>;
    lockState: Maybe<GQApprovalTargetLockState>;
    object:
      | GQCustomEmissionsFactorsSetup
      | GQDataIssue
      | GQFacilitiesSetup
      | GQFinancialsReviewGroup
      | GQFinancialsReviewItem
      | GQMeasurementDataMappingTask
      | GQMeasurementProject
      | GQMeasurementProjectDataset
      | GQUserUploadTask
      | GQValueMappingTask
      | GQVendorMatchingTask;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    subTasks: Array<GQMeasurementParentTask | GQMeasurementTaskFlat>;
    taskGroup: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type GQMeasurementProject = GQClimateProgramTimelineObject &
  GQContextRelationInterface &
  GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'MeasurementProject';
    active: Scalars['Boolean']['output'];
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQActivityDataTable
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    buildingSurveyStatus: Array<GQBuildingSurveyStatus>;
    canEditCoverageInterval: Scalars['Boolean']['output'];
    climateProgramProjects: Array<GQClimateProgramProject>;
    completedAt: Maybe<Scalars['Date']['output']>;
    coverageEndDate: Scalars['Date']['output'];
    coverageInterval: Scalars['YMInterval']['output'];
    coverageStartDate: Scalars['Date']['output'];
    createdAt: Scalars['DateTime']['output'];
    dataIssues: Array<GQDataIssue>;
    datasetsWithStages: Array<GQDatasetWithStage>;
    deadline: Maybe<Scalars['Date']['output']>;
    deletedAt: Maybe<Scalars['Date']['output']>;
    facilitiesBartsStatus: GQMeasurementProjectFacilitiesBartsStatus;
    footprintStatus: GQMeasurementProjectFootprintStatus;
    id: Scalars['ID']['output'];
    isApprovalFlowEnabled: Scalars['Boolean']['output'];
    measurementProjectDatasets: Array<GQMeasurementProjectDataset>;
    measurementTasks: Array<GQMeasurementParentTask>;
    measurementTimeline: Maybe<GQMeasurementTimeline>;
    name: Scalars['String']['output'];
    orgId: Scalars['ID']['output'];
    setupAt: Maybe<Scalars['Date']['output']>;
    setupBy: Maybe<GQUser>;
    sharedFiles: GQSharedFileConnection;
    status: GQMeasurementProjectStatus;
    supportedValueMappingRules: Array<Scalars['String']['output']>;
    taskStatusDumps: Array<GQTaskStatusDump>;
    updatedAt: Scalars['DateTime']['output'];
    userUploadDataGovernance: Array<GQUserUploadDataGovernanceInfo>;
    userUploadTasks: GQUserUploadTaskConnection;
    /** @deprecated Use userUploadDataGovernance instead */
    userUploadVerificationLogs: Array<Maybe<GQUserUploadVerificationLog>>;
    valueMappingTask: Maybe<GQValueMappingTask>;
  };

export type GQMeasurementProjectSharedFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  forUserId: InputMaybe<Scalars['ID']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMeasurementProjectUserUploadTasksArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMeasurementProjectValueMappingTaskArgs = {
  valueMappingRuleId: Scalars['String']['input'];
};

export type GQMeasurementProjectConnection = {
  __typename?: 'MeasurementProjectConnection';
  edges: Array<Maybe<GQMeasurementProjectEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementProjectDataset = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'MeasurementProjectDataset';
    dataset: GQDataset;
    id: Scalars['ID']['output'];
    isCompleted: Scalars['Boolean']['output'];
    modelId: Maybe<Scalars['ID']['output']>;
  };

export type GQMeasurementProjectDatasetProgress = {
  __typename?: 'MeasurementProjectDatasetProgress';
  completed: Array<GQDataset>;
  needsUpload: Array<GQDataset>;
  processing: Array<GQDataset>;
};

export type GQMeasurementProjectEdge = {
  __typename?: 'MeasurementProjectEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMeasurementProject>;
};

export type GQMeasurementProjectFacilitiesBartsStatus = {
  __typename?: 'MeasurementProjectFacilitiesBartsStatus';
  debugInfo: Array<Maybe<Scalars['String']['output']>>;
  upToDate: Scalars['Boolean']['output'];
};

export const GQMeasurementProjectFootprintStatus = {
  Draft: 'Draft',
  None: 'None',
  Published: 'Published',
} as const;

export type GQMeasurementProjectFootprintStatus =
  (typeof GQMeasurementProjectFootprintStatus)[keyof typeof GQMeasurementProjectFootprintStatus];
export const GQMeasurementProjectStatus = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  Planned: 'Planned',
} as const;

export type GQMeasurementProjectStatus =
  (typeof GQMeasurementProjectStatus)[keyof typeof GQMeasurementProjectStatus];
export type GQMeasurementProjectStatusShare = {
  __typename?: 'MeasurementProjectStatusShare';
  id: Scalars['ID']['output'];
  measurementProjectId: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQMeasurementResourceEvent = {
  __typename?: 'MeasurementResourceEvent';
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  eventData: Scalars['JSONString']['output'];
  eventKind: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userId: Maybe<Scalars['ID']['output']>;
};

export const GQMeasurementResourcePublicationErrorCode = {
  AcceptedExecutionNotLatest: 'AcceptedExecutionNotLatest',
} as const;

export type GQMeasurementResourcePublicationErrorCode =
  (typeof GQMeasurementResourcePublicationErrorCode)[keyof typeof GQMeasurementResourcePublicationErrorCode];
export const GQMeasurementResourcePublishWorkflowOutcome = {
  ErrorOnEmissionsModelValidation: 'ErrorOnEmissionsModelValidation',
  ErrorOnTestResultAcceptance: 'ErrorOnTestResultAcceptance',
  TestResultsAcceptedAndResourcePublished:
    'TestResultsAcceptedAndResourcePublished',
  TestResultsRejected: 'TestResultsRejected',
  TimeoutOnTestResultAcceptance: 'TimeoutOnTestResultAcceptance',
} as const;

export type GQMeasurementResourcePublishWorkflowOutcome =
  (typeof GQMeasurementResourcePublishWorkflowOutcome)[keyof typeof GQMeasurementResourcePublishWorkflowOutcome];
export const GQMeasurementStatus = {
  AtRisk: 'AtRisk',
  Complete: 'Complete',
  Measuring: 'Measuring',
  OffTrack: 'OffTrack',
  OnTrack: 'OnTrack',
  Planning: 'Planning',
} as const;

export type GQMeasurementStatus =
  (typeof GQMeasurementStatus)[keyof typeof GQMeasurementStatus];
export type GQMeasurementTask = {
  approvers: Array<GQUser>;
  assignees: Array<GQUser>;
  dataset: Maybe<GQDataset>;
  id: Scalars['ID']['output'];
  isAssignedToWatershed: Scalars['Boolean']['output'];
  link: Maybe<Scalars['String']['output']>;
  lockState: Maybe<GQApprovalTargetLockState>;
  object:
    | GQCustomEmissionsFactorsSetup
    | GQDataIssue
    | GQFacilitiesSetup
    | GQFinancialsReviewGroup
    | GQFinancialsReviewItem
    | GQMeasurementDataMappingTask
    | GQMeasurementProject
    | GQMeasurementProjectDataset
    | GQUserUploadTask
    | GQValueMappingTask
    | GQVendorMatchingTask;
  status: GQTaskWatershedProcessState;
  statusSimplified: GQTaskWatershedProcessStateSimplified;
  taskGroup: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQMeasurementTaskFlat = GQIdInterface &
  GQMeasurementTask & {
    __typename?: 'MeasurementTaskFlat';
    approvers: Array<GQUser>;
    assignees: Array<GQUser>;
    dataset: Maybe<GQDataset>;
    id: Scalars['ID']['output'];
    isAssignedToWatershed: Scalars['Boolean']['output'];
    link: Maybe<Scalars['String']['output']>;
    lockState: Maybe<GQApprovalTargetLockState>;
    object:
      | GQCustomEmissionsFactorsSetup
      | GQDataIssue
      | GQFacilitiesSetup
      | GQFinancialsReviewGroup
      | GQFinancialsReviewItem
      | GQMeasurementDataMappingTask
      | GQMeasurementProject
      | GQMeasurementProjectDataset
      | GQUserUploadTask
      | GQValueMappingTask
      | GQVendorMatchingTask;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    taskGroup: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export const GQMeasurementTaskForDataIssueKind = {
  UserUploadTask: 'UserUploadTask',
  ValueMappingTask: 'ValueMappingTask',
} as const;

export type GQMeasurementTaskForDataIssueKind =
  (typeof GQMeasurementTaskForDataIssueKind)[keyof typeof GQMeasurementTaskForDataIssueKind];
export type GQMeasurementTaskObject = {
  id: Scalars['ID']['output'];
};

export type GQMeasurementTestRow = {
  __typename?: 'MeasurementTestRow';
  activityRow: Scalars['JSONString']['output'];
  bartRowId: Scalars['Int']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export const GQMeasurementTestRowResultStatus = {
  Added: 'Added',
  Changed: 'Changed',
  Matched: 'Matched',
  Removed: 'Removed',
} as const;

export type GQMeasurementTestRowResultStatus =
  (typeof GQMeasurementTestRowResultStatus)[keyof typeof GQMeasurementTestRowResultStatus];
export type GQMeasurementTestSuite = {
  __typename?: 'MeasurementTestSuite';
  calculationTags: Array<GQCalculationTag>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  emissionsModelReleases: Array<GQEmissionsModelRelease>;
  footprintTestSuiteConfig: Maybe<GQFootprintTestSuiteConfig>;
  id: Scalars['ID']['output'];
  kind: Maybe<GQMeasurementTestSuiteKind>;
  latestChangelogEntry: GQMeasurementTestSuiteChangelog;
  latestFootprintTestSuiteExecution: Maybe<GQFootprintTestSuiteExecution>;
  latestMethodologyTestSuiteExecution: Maybe<GQMethodologyTestSuiteExecution>;
  measurementTestSuiteBarts: Array<GQMeasurementTestSuiteBart>;
  organization: Maybe<GQOrganization>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMeasurementTestSuiteBart = {
  __typename?: 'MeasurementTestSuiteBart';
  activityTypeName: Scalars['String']['output'];
  businessActivityType: GQBusinessActivityType;
  fieldDefaults: Scalars['JSONString']['output'];
  hiddenFields: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  measurementTestRowCount: Scalars['Int']['output'];
  measurementTestRows: Array<GQMeasurementTestRow>;
  title: Scalars['String']['output'];
};

export type GQMeasurementTestSuiteChangelog = {
  __typename?: 'MeasurementTestSuiteChangelog';
  author: Maybe<GQUser>;
  authorId: Maybe<Scalars['ID']['output']>;
  changelog: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  measurementResourceEvent: Maybe<GQMeasurementResourceEvent>;
  measurementResourceEventId: Maybe<Scalars['ID']['output']>;
};

export type GQMeasurementTestSuiteChangelogConnection = {
  __typename?: 'MeasurementTestSuiteChangelogConnection';
  edges: Array<GQMeasurementTestSuiteChangelogEdge>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementTestSuiteChangelogEdge = {
  __typename?: 'MeasurementTestSuiteChangelogEdge';
  cursor: Scalars['String']['output'];
  node: GQMeasurementTestSuiteChangelog;
};

export const GQMeasurementTestSuiteKind = {
  Critical: 'Critical',
} as const;

export type GQMeasurementTestSuiteKind =
  (typeof GQMeasurementTestSuiteKind)[keyof typeof GQMeasurementTestSuiteKind];
export const GQMeasurementTestSuiteStage = {
  Footprint: 'footprint',
  Methodology: 'methodology',
} as const;

export type GQMeasurementTestSuiteStage =
  (typeof GQMeasurementTestSuiteStage)[keyof typeof GQMeasurementTestSuiteStage];
export const GQMeasurementTestSuiteStageExecutionStatus = {
  Errored: 'Errored',
  Failed: 'Failed',
  Invalid: 'Invalid',
  Passed: 'Passed',
  Queued: 'Queued',
  Running: 'Running',
} as const;

export type GQMeasurementTestSuiteStageExecutionStatus =
  (typeof GQMeasurementTestSuiteStageExecutionStatus)[keyof typeof GQMeasurementTestSuiteStageExecutionStatus];
export type GQMeasurementTimeline = GQIdInterface & {
  __typename?: 'MeasurementTimeline';
  deadline: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  kickoff: Scalars['Date']['output'];
};

export type GQMergeAssetsInput = {
  assetIdToDelete: Scalars['ID']['input'];
  assetIdToKeep: Scalars['ID']['input'];
};

export type GQMergeAssetsPayload = {
  __typename?: 'MergeAssetsPayload';
  id: Scalars['ID']['output'];
};

export type GQMethodologyConfig = {
  __typename?: 'MethodologyConfig';
  id: Scalars['ID']['output'];
  spendMethodology: Maybe<GQSpendMethodology>;
  wasteEmissionsExclusions: Maybe<Array<GQWasteEmissionsExclusion>>;
};

export type GQMethodologyTestRowResult = {
  __typename?: 'MethodologyTestRowResult';
  createdAt: Scalars['DateTime']['output'];
  diff: Scalars['JSONString']['output'];
  emissionsModelStable: GQEmissionsModelStable;
  emissionsModelStableId: Scalars['ID']['output'];
  frozenExpectation: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  measurementTestRow: GQMeasurementTestRow;
  measurementTestRowId: Scalars['ID']['output'];
  result: Scalars['JSONString']['output'];
  resultAccepted: Scalars['Boolean']['output'];
  status: GQMeasurementTestRowResultStatus;
};

export type GQMethodologyTestSuiteExecution = {
  __typename?: 'MethodologyTestSuiteExecution';
  author: Maybe<GQWatershedEmployee>;
  authorId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  methodologyTestRowResults: Array<GQMethodologyTestRowResult>;
  status: GQMeasurementTestSuiteStageExecutionStatus;
};

export type GQMethodologyTestSuiteValidationResults = {
  __typename?: 'MethodologyTestSuiteValidationResults';
  id: Scalars['ID']['output'];
  isValid: Scalars['Boolean']['output'];
  warningMessage: Maybe<Scalars['String']['output']>;
};

export type GQMigrateCardControls024524Payload = {
  __typename?: 'MigrateCardControls024524Payload';
  badReportConfigIds: Array<Scalars['ID']['output']>;
  count: Scalars['Int']['output'];
};

export type GQMigrateOrgPlansToEnterpriseRedux = {
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQMigrateOrgPlansToEnterpriseReduxPayload = {
  __typename?: 'MigrateOrgPlansToEnterpriseReduxPayload';
  migratedPlans: Array<GQPlan>;
};

export type GQMigrateOrgPlansToOrgFiscalMonthInput = {
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQMigrateOrgPlansToOrgFiscalMonthPayload = {
  __typename?: 'MigrateOrgPlansToOrgFiscalMonthPayload';
  forecasts: Array<GQForecast>;
  plans: Array<GQPlan>;
};

export type GQMigratePermissionViewFootprintDetailToManageSuppliersInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQMigratePermissionViewFootprintDetailToManageSuppliersPayload = {
  __typename?: 'MigratePermissionViewFootprintDetailToManageSuppliersPayload';
  userAndRoleIds: Array<Scalars['ID']['output']>;
};

export type GQMissingSupportingDocumentAssociations = {
  __typename?: 'MissingSupportingDocumentAssociations';
  numMissingUploadAssociations: Scalars['Int']['output'];
  numMissingUtilityRowAssociations: Scalars['Int']['output'];
  orgId: Scalars['String']['output'];
};

export type GQMutation = {
  __typename?: 'Mutation';
  acceptFootprintTestSuiteExecutionResults: GQAcceptFootprintTestSuiteExecutionResultsPayload;
  acceptMethodologyTestSuiteExecutionResults: GQAcceptMethodologyTestSuiteExecutionResultsPayload;
  acceptReportAnswerVerifierFailures: Maybe<GQAcceptReportAnswerVerifierFailuresPayload>;
  addCalculationTagToReferenceData: GQAddCalculationTagToReferenceDataPayload;
  addCalculationTagToStableModel: GQAddCalculationTagToStableModelPayload;
  addEmissionsModelToOrg: GQAddEmissionsModelToOrgPayload;
  addMarketplaceSupplierPointOfContact: Maybe<GQAddMarketplaceSupplierPointOfContactPayload>;
  addMarketplaceSupplierPointOfContactConnection: Maybe<GQAddMarketplaceSupplierPointOfContactConnectionPayload>;
  addMarketplaceSupplierWatershedEmployee: Maybe<GQAddMarketplaceSupplierWatershedEmployeePayload>;
  addOrgAccessToRelease: GQEmissionsModelReleasePayload;
  addTestRowsToTestSuiteBart: GQAddTestRowsToTestSuiteBartPayload;
  addWatershedEmployee: Maybe<GQAddWatershedEmployeePayload>;
  adminUpdatePlan: Maybe<GQUpdatePlanPayload>;
  applyGlobalTagToTestData: Maybe<GQApplyGlobalTagToTestDataPayload>;
  applyIntegrationConnection: GQApplyIntegrationConnectionPayload;
  applyTagsToPipelineResult: Maybe<GQApplyTagsToPipelineResultPayload>;
  approveCompanyIngestionDiff: GQProcessCompanyIngestionDiffPayload;
  approveCompanyIngestionUpdate: GQProcessCompanyIngestionUpdatePayload;
  approveCtsTransformTestCase: GQApproveCtsTransformTestCasePayload;
  approveReportAnswerVerifiers: Maybe<GQApproveReportAnswerVerifiersPayload>;
  archivePipelineResult: Maybe<GQArchivePipelineResultPayload>;
  archiveReferenceDataCitation: GQArchiveReferenceDataCitationPayload;
  archiveReferenceDataRevision: Maybe<GQArchiveReferenceDataRevisionPayload>;
  archiveReferenceDataSource: Maybe<GQArchiveReferenceDataSourcePayload>;
  archiveReferenceDataVersion: Maybe<GQArchiveReferenceDataVersionPayload>;
  archiveReport: Maybe<GQArchiveReportPayload>;
  autosaveEmissionsModel: GQAutosaveEmissionsModelPayload;
  backfillBuildingHistory: GQBackfillBuildingHistoryPayload;
  backfillFacilitiesUploadTaskAssociations: GQBackfillFacilitiesUploadTaskAssociationsPayload;
  backfillSupportingDocumentAssociations: GQBackfillSupportingDocumentAssociationsPayload;
  bulkImportMarketplaceAllocationInstructions: GQBulkImportMarketplaceAllocationInstructionsPayload;
  bulkUpdateMarketplacePurchaseLineItems: Maybe<GQBulkUpdateMarketplacePurchaseLineItemsPayload>;
  bulkUpdateReportQuestions: GQBulkUpdateReportQuestionsPayload;
  cancelWorkflow: Maybe<GQCancelWorkflowPayload>;
  checkAndHealForecast: GQCheckAndHealForecastPayload;
  cleanUpDuplicateSupportingDocAssociations: GQCleanUpDuplicateSupportingDocAssociationsPayload;
  clearAllReportAnswerVerifierFailures: Maybe<GQClearAllReportAnswerVerifiersFailurePayload>;
  clearReportAnswerVerifierFailure: Maybe<GQClearReportAnswerVerifierFailurePayload>;
  clearReportAnswerVerifierFailures: Maybe<GQClearReportAnswerVerifierFailuresPayload>;
  completeMeasurementProject: Maybe<GQCompleteMeasurementProjectPayload>;
  computeReportAnswerWarningsForOrg: Maybe<GQComputeReportAnswerWarningsForOrgPayload>;
  configureAutoBart: Maybe<GQConfigureAutoBartPayload>;
  convertCtsBack: GQConvertCtsBackPayload;
  convertCtsToTSchema: GQConvertCtsToTSchemaPayload;
  convertGlobalReleaseToOrgGated: GQEmissionsModelReleasePayload;
  convertOrgGatedReleaseToGlobal: GQEmissionsModelReleasePayload;
  copyDataToNewTasksByBuilding: GQCopyDataToNewTasksByBuildingPayload;
  copyEngagementTaskConfigToOrgForAdmin: GQEngagementTaskConfig;
  copyReportQuestionAnswers: Maybe<GQCopyReportQuestionAnswersPayload>;
  createActivityDataTable: Maybe<GQCreateActivityDataTablePayload>;
  createAdminUpload: Maybe<GQCreateAdminUploadPayload>;
  createApiKey: Maybe<GQCreateApiKeyPayload>;
  createCalculationTag: GQCalculationTagPayload;
  createCanonicalClimateProgramProject: GQCanonicalClimateProgramProjectPayload;
  createCanonicalClimateProgramProjectRequirementDataset: GQCanonicalClimateProgramProjectRequirementDatasetPayload;
  createCanonicalClimateProgramProjectRequirementGhgp: GQCanonicalClimateProgramProjectRequirementGhgpPayload;
  createCanonicalDataset: Maybe<GQCanonicalDatasetPayload>;
  createCanonicalDatasource: Maybe<GQCanonicalDatasourcePayload>;
  createCdpColumnMappings: Maybe<GQCreateCdpColumnMappingsPayload>;
  createCdpQuestionMappings: Maybe<GQCreateCdpQuestionMappingsPayload>;
  createCompanyClimateCommitment: Maybe<GQCompanyClimateCommitmentPayload>;
  createCompanyRelationship: GQCreateCompanyRelationshipPayload;
  createCompanySourceKey: GQCompanySourceKeyPayload;
  createCompositeDataSource: GQCreateCompositeDataSourcePayload;
  createCts: GQCreateCtsPayload;
  createCtsTransformTestCase: Maybe<GQCreateCtsTransformTestCasePayload>;
  createCtsVersion: GQCreateCtsVersionPayload;
  /** @deprecated Support cases are now the law of the land */
  createDataIssue: Maybe<GQCreateDataIssuePayload>;
  /** @deprecated Support cases are now the law of the land */
  createDataIssueComment: Maybe<GQCreateDataIssueCommentPayload>;
  createDataset: Maybe<GQDatasetPayload>;
  createDatasourceAdmin: GQAdminDatasourcePayload;
  createDemoOrg: Maybe<GQCreateDemoOrgPayload>;
  createEacMarketplaceProjects: GQCreateEacMarketplaceProjectsPayload;
  createEacPriceEstimate: GQCreateEacPriceEstimatePayload;
  createEmissionsModel: GQCreateEmissionsModelPayload;
  createEmissionsModelRelease: GQEmissionsModelReleasePayload;
  createEngagementTaskConfigForAdmin: GQEngagementTaskConfigPayload;
  createExternalReportQuestion: GQCreateExternalReportQuestionPayload;
  createExternalReportTypeWithRevision: GQCreateExternalReportTypeWithRevisionPayload;
  createFacilitiesSetupTask: GQCreateFacilitiesSetupTaskPayload;
  createFinancialsReviewItemDrafts: Maybe<GQCreateFinancialsReviewItemDraftsPayload>;
  createFootprintDisclosure: Maybe<GQCreateFootprintDisclosurePayload>;
  createFootprintSnapshot: GQCreateFootprintSnapshotPayload;
  createFundAdmin: Maybe<GQCreateFundPayload>;
  createLifecycleAssessment: GQLifecycleAssessment;
  createMarketplaceAllocationInstruction: Maybe<GQCreateMarketplaceAllocationInstructionPayload>;
  createMarketplaceDeveloper: Maybe<GQCreateMarketplaceDeveloperPayload>;
  createMarketplaceProject: Maybe<GQCreateMarketplaceProjectPayload>;
  createMarketplaceProjectArchetype: Maybe<GQCreateMarketplaceProjectArchetypePayload>;
  createMarketplaceProjectOffering: Maybe<GQCreateMarketplaceProjectOfferingPayload>;
  createMarketplacePurchase: Maybe<GQCreateMarketplacePurchasePayload>;
  createMarketplacePurchaseLineItem: Maybe<GQCreateMarketplacePurchaseLineItemPayload>;
  createMarketplaceSupplier: Maybe<GQCreateMarketplaceSupplierPayload>;
  createMeasurementTestSuite: GQCreateMeasurementTestSuitePayload;
  createMeasurementTestSuiteBart: GQCreateMeasurementTestSuiteBartPayload;
  createOrgRole: Maybe<GQCreateRolePayload>;
  createOrganization: Maybe<GQOrganizationPayload>;
  createPermissionItem: Maybe<GQCreatePermissionItemPayload>;
  createPlan: Maybe<GQCreatePlanPayload>;
  createPublicDisclosure: GQCreatePublicDisclosurePayload;
  createPublicDisclosureTarget: GQCreatePublicDisclosureTargetPayload;
  createReferenceDataCitation: GQCreateReferenceDataCitationPayload;
  createReferenceDataOverlayTemplate: Maybe<GQCreateReferenceDataOverlayTemplatePayload>;
  createReferenceDataSource: GQCreateReferenceDataSourcePayload;
  createReferenceDataVersion: GQCreateReferenceDataVersionPayload;
  createReportConfig: Maybe<GQCreateReportConfigPayload>;
  createReportConfigFromSrc: Maybe<GQCreateReportConfigPayload>;
  createReportQuestion: Maybe<GQCreateReportQuestionPayload>;
  createReportQuestionContainer: Maybe<GQCreateReportQuestionContainerPayload>;
  createReportQuestionIdentifierMapping: GQUpdateReportQuestionIdentifierMappingPayload;
  createReportWithConfigAdmin: GQReportWithConfig;
  createReportingFramework: Maybe<GQCreateReportingFrameworkPayload>;
  createReportingFrameworkDataPoints: Maybe<GQCreateReportingFrameworkDataPointsPayload>;
  createSupplierChartAdmin: Maybe<GQCreateSupplierChartPayloadAdmin>;
  createTarget: GQCreateTargetPayload;
  createTcfdArchetypeOpportunity: GQTcfdArchetypeOpportunity;
  createTcfdArchetypeRisk: GQTcfdArchetypeRisk;
  createUserAdminVersion: Maybe<GQCreateUserAdminVersionPayload>;
  createUserUploadTask: Maybe<GQCreateUserUploadTaskPayload>;
  debugPlaygroundSql: Maybe<Scalars['JSONString']['output']>;
  declineCompanyIngestionDiff: GQProcessCompanyIngestionDiffPayload;
  declineCompanyIngestionUpdate: GQProcessCompanyIngestionUpdatePayload;
  deleteAllAnswersForReport: Maybe<GQDeleteAllAnswersForReportPayload>;
  deleteAllMarketplaceAllocationInstructions: Maybe<GQDeleteAllMarketplaceAllocationInstructionsPayload>;
  deleteAllReductionsData: GQDeleteAllReductionsDataPayload;
  deleteCalculationTag: GQDeleteCalculationTagPayload;
  deleteCanonicalClimateProgramProject: GQDeleteCanonicalClimateProgramProjectPayload;
  deleteCanonicalClimateProgramProjectRequirementDataset: GQCanonicalClimateProgramProjectPayload;
  deleteCanonicalClimateProgramProjectRequirementGhgp: GQCanonicalClimateProgramProjectPayload;
  deleteCdpColumnMappings: Maybe<GQDeleteCdpColumnMappingsPayload>;
  deleteCdpQuestionMappings: Maybe<GQDeleteCdpQuestionMappingsPayload>;
  deleteComment: Maybe<GQDeleteCommentPayload>;
  deleteCompanyClimateCommitment: Maybe<GQDeleteCompanyClimateCommitmentPayload>;
  deleteCompanyRelationship: GQDeleteCompanyRelationshipPayload;
  deleteCompanySourceKey: GQDeleteCompanySourceKeyPayload;
  deleteCts: GQDeleteCtsPayload;
  deleteCtsTransformTestCase: Maybe<GQDeleteCtsTransformTestCasePayload>;
  /** @deprecated Support cases are now the law of the land */
  deleteDataIssue: Maybe<GQDeleteDataIssuePayload>;
  deleteDataRegistryTables: GQDeleteDataRegistryTablesPayload;
  deleteDataset: Maybe<GQDeleteDatasetPayload>;
  deleteDatasourceAdmin: Maybe<GQDeleteDatasourceAdminPayload>;
  deleteEacPriceEstimate: GQDeleteEacPriceEstimatePayload;
  deleteEmissionsModelRelease: GQDeleteEmissionsModelReleasePayload;
  deleteEmissionsYear: GQDeleteEmissionsYearPayload;
  deleteEngagementTaskConfigForAdmin: Maybe<GQDeleteEngagementTaskConfigForAdminPayload>;
  deleteEngagementTasksAndAnswersByCrossOrgModelId: Maybe<GQDeleteEngagementTasksAndAnswersByCrossOrgModelIdPayload>;
  deleteExternalReportQuestion: GQDeleteExternalReportQuestionPayload;
  deleteExternalReportTypeWithRevision: GQDeleteExternalReportTypeWithRevisionPayload;
  deleteFacilitiesSetupTask: GQDeleteFacilitiesSetupTaskPayload;
  deleteFile: Maybe<GQDeleteFilePayload>;
  deleteFinancialsReviewItem: Maybe<GQDeleteFinancialsReviewItemPayload>;
  deleteFootprintTag: Maybe<GQDeleteFootprintTagPayload>;
  deleteFootprintTestSuiteConfig: GQDeleteFootprintTestSuiteConfigPayload;
  deleteFunds: Maybe<GQDeleteFundsPayload>;
  deleteMarketplaceAllocationInstruction: Maybe<GQDeleteMarketplaceAllocationInstructionPayload>;
  deleteMarketplaceDeveloper: Maybe<GQDeleteMarketplaceDeveloperPayload>;
  deleteMarketplaceDocument: Maybe<GQDeleteMarketplaceDocumentPayload>;
  deleteMarketplaceProject: Maybe<GQDeleteMarketplaceProjectPayload>;
  deleteMarketplacePurchase: Maybe<GQDeleteMarketplacePurchasePayload>;
  deleteMarketplacePurchaseLineItem: Maybe<GQDeleteMarketplacePurchaseLineItemPayload>;
  deleteMaterialVariantsForOrg: GQDeleteMaterialVariantsForOrgPayload;
  deleteMeasurementProject: Maybe<GQOrganizationPayload>;
  deleteMeasurementTestRows: GQDeleteMeasurementTestRowsPayload;
  deleteMeasurementTestSuite: GQDeleteMeasurementTestSuitePayload;
  deleteMeasurementTestSuiteBart: GQDeleteMeasurementTestSuiteBartPayload;
  deleteOneSchemaTemplate: GQDeleteOneSchemaTemplatePayload;
  deleteOrganization: Maybe<GQDeleteOrganizationPayload>;
  deletePlanAdmin: GQDeletePlanAdminPayload;
  deletePublicDisclosure: GQDeletePublicDisclosurePayload;
  deletePublicDisclosureTarget: GQDeletePublicDisclosureTargetPayload;
  deleteReportAnswerWarningsByValidatorId: Maybe<GQDeleteReportAnswerWarningsByValidatorIdPayload>;
  deleteReportAnswers: Maybe<GQDeleteReportAnswersPayload>;
  deleteReportConfig: Maybe<GQDeleteReportConfigPayload>;
  deleteReportQuestion: Maybe<GQDeleteReportQuestionPayload>;
  deleteReportQuestionContainer: Maybe<GQDeleteReportQuestionContainerPayload>;
  deleteReportQuestionIdentifierMapping: GQUpdateReportQuestionIdentifierMappingPayload;
  deleteReportingFramework: Maybe<GQDeleteReportingFrameworkPayload>;
  deleteReportingFrameworkDataPoints: Maybe<GQDeleteReportingFrameworkDataPointsPayload>;
  deleteRole: Maybe<GQDeleteRolePayload>;
  deleteSupplierChartAdmin: Maybe<GQDeleteSupplierChartPayloadAdmin>;
  deleteTarget: GQDeleteTargetPayload;
  deleteTcfdArchetypeRisk: GQTcfdArchetypeRisk;
  deleteUserUploadTask: Maybe<GQDeleteUserUploadTaskPayload>;
  duplicateCts: GQDuplicateCtsPayload;
  duplicateMeasurementTestSuite: GQDuplicateMeasurementTestSuitePayload;
  duplicatePlanAdmin: GQPlan;
  editEmissionsYear: GQEditEmissionsYearPayload;
  editExternalReportQuestion: GQEditExternalReportQuestionPayload;
  editExternalReportTypeWithRevision: GQEditExternalReportTypeWithRevisionPayload;
  editPublicDisclosure: GQEditPublicDisclosurePayload;
  /** @deprecated Use `startWorkflow` instead. */
  enqueueBackgroundJob: Maybe<GQEnqueueBackgroundJobPayload>;
  eraseReportComponent: Maybe<GQEraseReportComponentPayload>;
  exportMeasurementTestSuite: GQExportMeasurementTestSuitePayload;
  exportMeasurementTestSuiteBundle: GQExportMeasurementTestSuiteBundlePayload;
  fetchIntegrationData: GQFetchIntegrationDataPayload;
  findDuck: GQFindDuckPayload;
  fixReduxValidationErrors: GQFixReduxValidationErrorsPayload;
  forkEmissionsModel: GQForkEmissionsModelPayload;
  fulfillShareFileRequest: Maybe<GQFulfillShareFileRequestPayload>;
  generateGmailXml: Maybe<GQGenerateGmailXmlPayload>;
  getNewDecryptedToken: Maybe<GQGetNewDecryptedTokenPayload>;
  grantContractorAccessToOrg: Maybe<GQGrantContractorAccessToOrgPayload>;
  grantCurrentUserAdminAccessToOrg: Maybe<GQGrantCurrentUserAdminAccessToOrgPayload>;
  grantFinanceUsersPortcoAccesses: Maybe<GQGrantFinanceUsersPortcoAccessesPayload>;
  grantUserRole: Maybe<GQGrantUserRolePayload>;
  importMeasurementTestSuite: GQImportMeasurementTestSuitePayload;
  initializeDefaultSupplierViewAdmin: GQInitializeDefaultSupplierViewAdminPayload;
  initializeWorkos: Maybe<GQInitializeWorkosPayload>;
  insertReportQuestionIdentifier: GQReportQuestionIdentifier;
  invalidateBackgroundJobCache: Maybe<GQInvalidateBackgroundJobCachePayload>;
  invalidateMeasurementTestSuitePage: GQInvalidateMeasurementTestSuitePagePayload;
  linkAndUnlinkEmissionsModelsToRelease: GQEmissionsModelReleasePayload;
  linkGlobalFootprintTagsToRelease: GQEmissionsModelReleasePayload;
  lintReportConfig: Maybe<GQLintReportConfigPayload>;
  logLocalMeasurementState: Maybe<Scalars['Boolean']['output']>;
  loginAsUser: GQLoginAsUserPayload;
  makeFootprintSnapshotUserVisible: GQFootprintSnapshot;
  markAdminUploadCompleted: Maybe<GQMarkAdminUploadCompletedPayload>;
  markMeasurementProjectDatasetCompleted: GQMarkMeasurementProjectDatasetCompletedPayload;
  markUserUploadAcknowledged: Maybe<GQMarkUserUploadAcknowledgedPayload>;
  markUserUploadedTablesIgnored: Maybe<GQMarkUserUploadedTablesIgnoredPayload>;
  mergeAssets: GQMergeAssetsPayload;
  migrateOrgPlansToOrgFiscalMonth: GQMigrateOrgPlansToOrgFiscalMonthPayload;
  parquetDataToCsv: GQParquetDataToCsvPayload;
  /** @deprecated Support cases are now the law of the land */
  previewDataIssueEmails: Maybe<GQPreviewDataIssueEmailsPayload>;
  previewFinancialsReviewEmails: Maybe<GQPreviewFinancialsReviewEmailsPayload>;
  processMaterialVariantAndLCAFile: GQProcessMaterialVariantAndLcaFilePayload;
  processProductionGraphFile: GQProcessProductionGraphFilePayload;
  publishCtsVersion: GQPublishCtsVersionPayload;
  publishCtsVersionForTesting: GQPublishCtsVersionPayload;
  /** @deprecated Support cases are now the law of the land */
  publishDataIssues: Maybe<GQPublishDataIssuesPayload>;
  publishEngagementTaskConfigForAdmin: Maybe<GQPublishEngagementTaskConfigForAdminPayload>;
  publishExtractor: Maybe<GQPublishExtractorPayload>;
  publishFinancialsReviewItems: Maybe<GQPublishFinancialsReviewItemsPayload>;
  publishReferenceDataVersion: GQPublishReferenceDataVersionPayload;
  pushCtsVersionToOneSchema: GQPushCtsVersionToOneSchemaPayload;
  pushStagingReportConfigToProd: GQPushStagingReportConfigToProdPayload;
  refreshAssetYearEstimations: Maybe<GQRefreshAssetYearEstimationsPayload>;
  refreshCarbonRemovalActivityDataTable: Maybe<GQRefreshCarbonRemovalActivityDataTablePayload>;
  regenerateUserSignupTokenAdminVersion: Maybe<GQRegenerateUserSignupTokenPayload>;
  reingestCompanyIngestionDiffs: GQReingestCompanyIngestionDiffPayload;
  releaseEmissionsModelActiveDraft: GQReleaseEmissionsModelActiveDraftPayload;
  removeCalculationTagFromReferenceData: GQRemoveCalculationTagFromReferenceDataPayload;
  removeCalculationTagFromStableModel: GQRemoveCalculationTagFromStableModelPayload;
  removeMarketplaceSupplierPointOfContact: Maybe<GQRemoveMarketplaceSupplierPointOfContactPayload>;
  removeMarketplaceSupplierWatershedEmployee: Maybe<GQRemoveMarketplaceSupplierWatershedEmployeePayload>;
  removeUserAdminVersion: Maybe<GQRemoveUserAdminVersionPayload>;
  renameCustomEmissionsFactorSetupTask: GQRenameCustomEmissionsFactorSetupTaskPayload;
  replaceReportingCdpMappings: GQReplaceReportingCdpMappingsPayload;
  resetLockedUserAdminVersion: Maybe<GQResetLockedUserAdminVersionPayload>;
  restoreReport: Maybe<GQRestoreReportAdminPayload>;
  reviewPrepublicationTestResultsForEmissionsModel: GQReviewPrepublicationTestResultsForEmissionsModelPayload;
  reviewPrepublicationTestResultsForReferenceDataRevision: GQReviewPrepublicationTestResultsForReferenceDataRevisionPayload;
  reviewPrepublicationTestResultsForRelease: GQReviewPrepublicationTestResultsForReleasePayload;
  revokePermissionItem: Maybe<GQRevokePermissionItemPayload>;
  revokeUserRole: Maybe<GQRevokeUserRolePayload>;
  runCtsTransformTestCase: GQRunCtsTransformEditorPayload;
  runDataRegistryMigration: Scalars['ID']['output'];
  runExtractor: Maybe<GQRunExtractorPayload>;
  runPlaygroundSql: Maybe<GQRunPlaygroundSqlPayload>;
  runReportAnswerVerifier: Maybe<GQRunReportAnswerVerifierPayload>;
  runSchemaValidationForReferenceDataVersion: GQRunSchemaValidationForReferenceDataVersionPayload;
  saveEmissionsModelDraft: GQSaveEmissionsModelDraftPayload;
  saveFootprintTag: Maybe<GQSaveFootprintTagPayload>;
  saveGlobalFootprintTag: Maybe<GQSaveGlobalFootprintTagPayload>;
  scoreAnswer: GQExternalReportAnswer;
  seedBatOntology: GQSeedBatOntologyPayload;
  setCalculationTagReferenceDatas: Scalars['Boolean']['output'];
  setCalculationTagStableModels: Scalars['Boolean']['output'];
  setEmissionsModelIsArchived: GQSetEmissionsModelIsArchivedPayload;
  setForecastFootprintSnapshotId: GQSetForecastFootprintSnapshotIdPayload;
  setOrgsForFeatureFlag: GQSetOrgsForFeatureFlagPayload;
  setPreferredLocale: GQSetPreferredLocalePayload;
  setSchemaRegistrySettings: GQSetSchemaRegistrySettingsPayload;
  setUserUploadedTableNotIgnored: Maybe<GQSetUserUploadedTableNotIgnoredPayload>;
  setUserUploadedTableSqlTableName: Maybe<GQSetUserUploadedTableSqlTableNamePayload>;
  shareFiles: Maybe<GQShareFilesPayload>;
  shareMeasurementProject: Maybe<GQShareMeasurementProjectPayload>;
  signalExecuteMeasurementTestSuite: GQSignalExecuteMeasurementTestSuitePayload;
  startPrepublicationTestsForEmissionsModel: GQStartPrepublicationTestsPayload;
  startPrepublicationTestsForReferenceDataRevision: GQStartPrepublicationTestsPayload;
  startPrepublicationTestsForRelease: GQStartPrepublicationTestsPayload;
  startPublishingEmissionsModel: GQStartPublishingEmissionsModelPayload;
  startWorkflow: Maybe<GQStartWorkflowPayload>;
  swapEmissionsModelExpectationsForFootprintTestRows: GQSwapEmissionsModelExpectationsForFootprintTestRowsPayload;
  swapEmissionsModelExpectationsForTestRows: GQSwapEmissionsModelExpectationsForMeasurementTestRowsPayload;
  syncFeatureFlags: GQSyncFeatureFlagsPayload;
  syncMeasurementMappings: Maybe<GQSyncMeasurementMappingsPayload>;
  testOrgCompanyDuckDbPerformance: Maybe<GQOrgCompanyDuckDbPerformanceResult>;
  throwError: Maybe<GQThrowErrorPayload>;
  toggleCliqMutabilityForEmissionsModel: GQToggleCliqMutabilityForEmissionsModelPayload;
  toggleCliqSyncForReferenceDataSource: GQToggleCliqSyncForReferenceDataSourcePayload;
  toggleMeasurementTestSuiteCritical: GQUpdateMeasurementTestSuitePayload;
  toggleVendorMatchingTaskAutomapping: Maybe<Scalars['Boolean']['output']>;
  unassociateIntegrationConnectionFromDatasource: GQUnassociateIntegrationConnectionFromDatasourcePayload;
  uncompleteMeasurementProject: Maybe<GQUncompleteMeasurementProjectPayload>;
  undeleteMeasurementTestSuite: GQUndeleteMeasurementTestSuitePayload;
  undoFacilitiesApprovalsMigration: GQUndoFacilitiesApprovalsMigrationPayload;
  unlinkGlobalFootprintTagsFromRelease: GQEmissionsModelReleasePayload;
  unpublishActivityDataTable: Maybe<GQUnpublishActivityDataTablePayload>;
  unpublishCtsVersion: GQUnpublishCtsVersionPayload;
  updateActivityDataTable: Maybe<GQUpdateActivityDataTablePayload>;
  updateCalculationTag: GQUpdateCalculationTagPayload;
  updateCanonicalClimateProgramProject: GQCanonicalClimateProgramProjectPayload;
  updateCanonicalClimateProgramProjectRequirementDataset: GQCanonicalClimateProgramProjectRequirementDatasetPayload;
  updateCanonicalClimateProgramProjectRequirementGhgp: GQCanonicalClimateProgramProjectRequirementGhgpPayload;
  updateCanonicalDataset: Maybe<GQCanonicalDatasetPayload>;
  updateCanonicalDatasource: Maybe<GQCanonicalDatasourcePayload>;
  updateComment: Maybe<GQUpdateCommentPayload>;
  updateCompanyClimateCommitment: Maybe<GQCompanyClimateCommitmentPayload>;
  updateCompanySourceKey: GQCompanySourceKeyPayload;
  updateCts: GQUpdateCtsPayload;
  updateCtsTransformTestCase: Maybe<GQUpdateCtsTransformTestCasePayload>;
  /** @deprecated Support cases are now the law of the land */
  updateDataIssue: Maybe<GQUpdateDataIssuePayload>;
  /** @deprecated Support cases are now the law of the land */
  updateDataIssues: Maybe<GQUpdateDataIssuesPayload>;
  updateDataRegistryBatchMigratorRun: GQDataRegistryBatchMigratorRun;
  updateDataset: Maybe<GQDatasetPayload>;
  updateDatasourceAdmin: Maybe<GQDatasourcePayload>;
  updateEacPriceEstimate: GQUpdateEacPriceEstimatePayload;
  updateEacPriceEstimates: GQUpdateEacPriceEstimatesPayload;
  updateEmissionsModelReleaseLifecyclePhase: GQEmissionsModelReleasePayload;
  updateEmissionsModelReleaseMetadata: GQEmissionsModelReleasePayload;
  updateEmissionsModelStable: Maybe<GQUpdateEmissionsModelStablePayload>;
  updateEngagementTaskConfigForAdmin: GQEngagementTaskConfigPayload;
  updateFileMetadataPermissionDelegates: GQUpdateFileMetadataPermissionDelegatesPayload;
  updateFinancialsReviewItem: Maybe<GQUpdateFinancialsReviewItemPayload>;
  updateForecast: GQUpdateForecastPayload;
  updateFund: Maybe<GQUpdateFundPayload>;
  updateIngestionInstructions: Maybe<GQUpdateIngestionInstructionsPayload>;
  updateIntegrationConnection: GQUpdateIntegrationConnectionPayload;
  updateLifecycleAssessment: GQLifecycleAssessment;
  updateMarketplaceAllocationInstruction: Maybe<GQUpdateMarketplaceAllocationInstructionPayload>;
  updateMarketplaceDeveloper: Maybe<GQUpdateMarketplaceDeveloperPayload>;
  updateMarketplaceDocument: Maybe<GQUpdateMarketplaceDocumentPayload>;
  updateMarketplaceProject: Maybe<GQUpdateMarketplaceProjectPayload>;
  updateMarketplaceProjectArchetype: Maybe<GQUpdateMarketplaceProjectArchetypePayload>;
  updateMarketplacePurchase: Maybe<GQUpdateMarketplacePurchasePayload>;
  updateMarketplacePurchaseLineItem: Maybe<GQUpdateMarketplacePurchaseLineItemPayload>;
  updateMarketplaceSupplier: Maybe<GQUpdateMarketplaceSupplierPayload>;
  updateMeasurementProject: Maybe<GQUpdateMeasurementProjectPayload>;
  updateMeasurementTestSuite: GQUpdateMeasurementTestSuitePayload;
  updateMeasurementTestSuiteBart: GQUpdateMeasurementTestSuiteBartPayload;
  updateOrgForFeatureFlag: GQUpdateOrgForFeatureFlagPayload;
  updateOrgProfile: GQUpdateOrgProfilePayload;
  updateOrganization: Maybe<GQOrganizationPayload>;
  updatePipelineResult: Maybe<GQUpdatePipelineResultPayload>;
  updatePublicDisclosureTarget: GQUpdatePublicDisclosureTargetPayload;
  updateReferenceDataCitation: GQUpdateReferenceDataCitationPayload;
  updateReferenceDataRevision: GQUpdateReferenceDataRevisionPayload;
  updateReferenceDataSource: GQUpdateReferenceDataSourcePayload;
  updateReferenceDataVersion: GQUpdateReferenceDataVersionPayload;
  updateReferenceDataVersionSchema: GQUpdateReferenceDataVersionSchemaPayload;
  updateReport: Maybe<GQUpdateReportPayload>;
  updateReportConfig: Maybe<GQUpdateReportConfigPayload>;
  updateReportEsrsMapping: GQUpdateReportEsrsMappingPayload;
  updateReportQuestion: Maybe<GQUpdateReportQuestionPayload>;
  updateReportQuestionContainer: Maybe<GQUpdateReportQuestionContainerPayload>;
  updateReportingFramework: Maybe<GQUpdateReportingFrameworkPayload>;
  updateReportingFrameworkDataPoints: Maybe<GQUpdateReportingFrameworkDataPointsPayload>;
  updateReportingIntentionsForOrg: Maybe<GQUpdateReportingIntentionsForOrgPayload>;
  updateRole: Maybe<GQUpdateRolePayload>;
  updateSupplierChartAdmin: Maybe<GQUpdateSupplierChartPayloadAdmin>;
  updateSuppliersSettingsAdmin: Maybe<GQUpdateSuppliersSettingsPayload>;
  updateTarget: GQUpdateTargetPayload;
  updateTcfdArchetypeOpportunity: GQTcfdArchetypeOpportunity;
  updateTcfdArchetypeRisk: GQTcfdArchetypeRisk;
  updateTestRowsInTestSuiteBart: GQUpdateTestRowsInTestSuiteBartPayload;
  updateUserEmailAdminVersion: Maybe<GQUpdateUserEmailAdminVersionPayload>;
  updateUserNameAdminVersion: Maybe<GQUpdateUserNameAdminVersionPayload>;
  updateUserUploadTask: Maybe<GQUpdateUserUploadTaskPayload>;
  uploadMarketplaceDocuments: Maybe<GQUploadMarketplaceDocumentsPayload>;
  upsertCdpColumnMappings: Maybe<GQUpsertCdpColumnMappingsPayload>;
  upsertCdpQuestionMappings: Maybe<GQUpsertCdpQuestionMappingsPayload>;
  upsertCompany: GQUpsertCompanyPayload;
  upsertDocumentParseAttempt: GQDocumentParseAttempt;
  upsertFootprintTestSuiteConfig: GQFootprintTestSuiteConfigPayload;
  validateEmissionsModelMatcher: GQValidateEmissionsModelMatcherPayload;
  validateSchemaRegistrySettings: GQValidateSchemaRegistrySettingsPayload;
  verifyReportAnswerWithAlert: GQVerifyReportAnswerWithAlertPayload;
  verifyReportAnswers: Maybe<GQVerifyReportAnswersPayload>;
};

export type GQMutationAcceptFootprintTestSuiteExecutionResultsArgs = {
  input: GQAcceptFootprintTestSuiteExecutionResultsInput;
};

export type GQMutationAcceptMethodologyTestSuiteExecutionResultsArgs = {
  input: GQAcceptMethodologyTestSuiteExecutionResultsInput;
};

export type GQMutationAcceptReportAnswerVerifierFailuresArgs = {
  input: GQAcceptReportAnswerVerifierFailuresInput;
};

export type GQMutationAddCalculationTagToReferenceDataArgs = {
  input: GQAddCalculationTagToReferenceDataInput;
};

export type GQMutationAddCalculationTagToStableModelArgs = {
  input: GQAddCalculationTagToStableModelInput;
};

export type GQMutationAddEmissionsModelToOrgArgs = {
  input: GQAddEmissionsModelToOrgInput;
};

export type GQMutationAddMarketplaceSupplierPointOfContactArgs = {
  input: GQAddMarketplaceSupplierPointOfContactInput;
};

export type GQMutationAddMarketplaceSupplierPointOfContactConnectionArgs = {
  input: GQAddMarketplaceSupplierPointOfContactConnectionInput;
};

export type GQMutationAddMarketplaceSupplierWatershedEmployeeArgs = {
  input: GQAddMarketplaceSupplierWatershedEmployeeInput;
};

export type GQMutationAddOrgAccessToReleaseArgs = {
  input: GQAddOrgAccessToReleaseInput;
};

export type GQMutationAddTestRowsToTestSuiteBartArgs = {
  input: GQAddTestRowsToTestSuiteBartInput;
};

export type GQMutationAddWatershedEmployeeArgs = {
  input: GQAddWatershedEmployeeInput;
};

export type GQMutationAdminUpdatePlanArgs = {
  input: GQUpdatePlanInput;
};

export type GQMutationApplyGlobalTagToTestDataArgs = {
  input: GQApplyGlobalTagToTestDataInput;
};

export type GQMutationApplyIntegrationConnectionArgs = {
  input: GQApplyIntegrationConnectionInput;
};

export type GQMutationApplyTagsToPipelineResultArgs = {
  input: GQApplyTagsToPipelineResultInput;
};

export type GQMutationApproveCompanyIngestionDiffArgs = {
  input: GQIdInput;
};

export type GQMutationApproveCompanyIngestionUpdateArgs = {
  input: GQIdInput;
};

export type GQMutationApproveCtsTransformTestCaseArgs = {
  input: GQApproveCtsTransformTestCaseInput;
};

export type GQMutationApproveReportAnswerVerifiersArgs = {
  input: GQApproveReportAnswerVerifiersInput;
};

export type GQMutationArchivePipelineResultArgs = {
  input: GQArchivePipelineResultInput;
};

export type GQMutationArchiveReferenceDataCitationArgs = {
  input: GQIdInput;
};

export type GQMutationArchiveReferenceDataRevisionArgs = {
  input: GQArchiveReferenceDataRevisionInput;
};

export type GQMutationArchiveReferenceDataSourceArgs = {
  input: GQArchiveReferenceDataSourceInput;
};

export type GQMutationArchiveReferenceDataVersionArgs = {
  input: GQArchiveReferenceDataVersionInput;
};

export type GQMutationArchiveReportArgs = {
  input: GQArchiveReportInput;
};

export type GQMutationAutosaveEmissionsModelArgs = {
  input: GQAutosaveEmissionsModelInput;
};

export type GQMutationBackfillBuildingHistoryArgs = {
  input: GQBackfillBuildingHistoryInput;
};

export type GQMutationBackfillFacilitiesUploadTaskAssociationsArgs = {
  input: GQBackfillFacilitiesUploadTaskAssociationsInput;
};

export type GQMutationBackfillSupportingDocumentAssociationsArgs = {
  input: GQBackfillSupportingDocumentAssociationsInput;
};

export type GQMutationBulkImportMarketplaceAllocationInstructionsArgs = {
  input: GQBulkImportMarketplaceAllocationInstructionsInput;
};

export type GQMutationBulkUpdateMarketplacePurchaseLineItemsArgs = {
  input: GQBulkUpdateMarketplacePurchaseLineItemsInput;
};

export type GQMutationBulkUpdateReportQuestionsArgs = {
  input: GQBulkUpdateReportQuestionsInput;
};

export type GQMutationCancelWorkflowArgs = {
  input: GQCancelWorkflowInput;
};

export type GQMutationCheckAndHealForecastArgs = {
  input: GQCheckAndHealForecastInput;
};

export type GQMutationCleanUpDuplicateSupportingDocAssociationsArgs = {
  input: GQCleanUpDuplicateSupportingDocAssociationsInput;
};

export type GQMutationClearReportAnswerVerifierFailureArgs = {
  input: GQClearReportAnswerVerifierFailureInput;
};

export type GQMutationClearReportAnswerVerifierFailuresArgs = {
  input: GQClearReportAnswerVerifierFailuresInput;
};

export type GQMutationCompleteMeasurementProjectArgs = {
  input: GQCompleteMeasurementProjectInput;
};

export type GQMutationComputeReportAnswerWarningsForOrgArgs = {
  input: GQComputeReportAnswerWarningsForOrgInput;
};

export type GQMutationConfigureAutoBartArgs = {
  input: GQConfigureAutoBartInput;
};

export type GQMutationConvertCtsBackArgs = {
  input: GQConvertCtsBackInput;
};

export type GQMutationConvertCtsToTSchemaArgs = {
  input: GQConvertCtsToTSchemaInput;
};

export type GQMutationConvertGlobalReleaseToOrgGatedArgs = {
  input: GQEmissionsModelReleaseIdInput;
};

export type GQMutationConvertOrgGatedReleaseToGlobalArgs = {
  input: GQEmissionsModelReleaseIdInput;
};

export type GQMutationCopyDataToNewTasksByBuildingArgs = {
  input: GQCopyDataToNewTasksByBuildingInput;
};

export type GQMutationCopyEngagementTaskConfigToOrgForAdminArgs = {
  input: GQCopyEngagementTaskConfigToOrgForAdminInput;
};

export type GQMutationCopyReportQuestionAnswersArgs = {
  input: GQCopyReportQuestionAnswersInput;
};

export type GQMutationCreateActivityDataTableArgs = {
  input: GQCreateActivityDataTableInput;
};

export type GQMutationCreateAdminUploadArgs = {
  input: GQCreateAdminUploadInput;
};

export type GQMutationCreateApiKeyArgs = {
  input: GQCreateApiKeyInput;
};

export type GQMutationCreateCalculationTagArgs = {
  input: GQCreateCalculationTagInput;
};

export type GQMutationCreateCanonicalClimateProgramProjectArgs = {
  input: GQCreateCanonicalClimateProgramProjectInput;
};

export type GQMutationCreateCanonicalClimateProgramProjectRequirementDatasetArgs =
  {
    input: GQCreateCanonicalClimateProgramProjectRequirementDatasetInput;
  };

export type GQMutationCreateCanonicalClimateProgramProjectRequirementGhgpArgs =
  {
    input: GQCreateCanonicalClimateProgramProjectRequirementGhgpInput;
  };

export type GQMutationCreateCanonicalDatasetArgs = {
  input: GQCreateCanonicalDatasetInput;
};

export type GQMutationCreateCanonicalDatasourceArgs = {
  input: GQCreateCanonicalDatasourceInput;
};

export type GQMutationCreateCdpColumnMappingsArgs = {
  input: GQCreateCdpColumnMappingsInput;
};

export type GQMutationCreateCdpQuestionMappingsArgs = {
  input: GQCreateCdpQuestionMappingsInput;
};

export type GQMutationCreateCompanyClimateCommitmentArgs = {
  input: GQCreateCompanyClimateCommitmentInput;
};

export type GQMutationCreateCompanyRelationshipArgs = {
  input: GQCreateCompanyRelationshipInput;
};

export type GQMutationCreateCompanySourceKeyArgs = {
  input: GQCreateCompanySourceKeyInput;
};

export type GQMutationCreateCompositeDataSourceArgs = {
  input: GQCreateCompositeDataSourceInput;
};

export type GQMutationCreateCtsArgs = {
  input: GQCreateCtsInput;
};

export type GQMutationCreateCtsTransformTestCaseArgs = {
  input: GQCreateCtsTransformTestCaseInput;
};

export type GQMutationCreateCtsVersionArgs = {
  input: GQCreateCtsVersionInput;
};

export type GQMutationCreateDataIssueArgs = {
  input: GQCreateDataIssueInput;
};

export type GQMutationCreateDataIssueCommentArgs = {
  input: GQCreateDataIssueCommentInput;
};

export type GQMutationCreateDatasetArgs = {
  input: GQCreateDatasetInput;
};

export type GQMutationCreateDatasourceAdminArgs = {
  input: GQCreateDatasourceAdminInput;
};

export type GQMutationCreateDemoOrgArgs = {
  input: GQCreateDemoOrgInput;
};

export type GQMutationCreateEacMarketplaceProjectsArgs = {
  input: GQCreateEacMarketplaceProjectsInput;
};

export type GQMutationCreateEacPriceEstimateArgs = {
  input: GQCreateEacPriceEstimateInput;
};

export type GQMutationCreateEmissionsModelArgs = {
  input: GQCreateEmissionsModelInput;
};

export type GQMutationCreateEmissionsModelReleaseArgs = {
  input: GQCreateEmissionsModelReleaseInput;
};

export type GQMutationCreateEngagementTaskConfigForAdminArgs = {
  input: GQEngagementTaskConfigCreateInputForAdmin;
};

export type GQMutationCreateExternalReportQuestionArgs = {
  input: GQCreateExternalReportQuestionInput;
};

export type GQMutationCreateExternalReportTypeWithRevisionArgs = {
  input: GQCreateExternalReportTypeWithRevisionInput;
};

export type GQMutationCreateFacilitiesSetupTaskArgs = {
  input: GQCreateFacilitiesSetupTaskInput;
};

export type GQMutationCreateFinancialsReviewItemDraftsArgs = {
  input: GQCreateFinancialsReviewItemDraftsInput;
};

export type GQMutationCreateFootprintDisclosureArgs = {
  input: GQCreateFootprintDisclosureInput;
};

export type GQMutationCreateFootprintSnapshotArgs = {
  input: GQCreateFootprintSnapshotInput;
};

export type GQMutationCreateFundAdminArgs = {
  input: GQCreateFundInputAdmin;
};

export type GQMutationCreateLifecycleAssessmentArgs = {
  input: GQCreateLifecycleAssessmentInput;
};

export type GQMutationCreateMarketplaceAllocationInstructionArgs = {
  input: GQCreateMarketplaceAllocationInstructionInput;
};

export type GQMutationCreateMarketplaceDeveloperArgs = {
  input: GQCreateMarketplaceDeveloperInput;
};

export type GQMutationCreateMarketplaceProjectArgs = {
  input: GQCreateMarketplaceProjectInput;
};

export type GQMutationCreateMarketplaceProjectArchetypeArgs = {
  input: GQCreateMarketplaceProjectArchetypeInput;
};

export type GQMutationCreateMarketplaceProjectOfferingArgs = {
  input: GQCreateMarketplaceProjectOfferingInput;
};

export type GQMutationCreateMarketplacePurchaseArgs = {
  input: GQCreateMarketplacePurchaseInput;
};

export type GQMutationCreateMarketplacePurchaseLineItemArgs = {
  input: GQCreateMarketplacePurchaseLineItemInput;
};

export type GQMutationCreateMarketplaceSupplierArgs = {
  input: GQCreateMarketplaceSupplierInput;
};

export type GQMutationCreateMeasurementTestSuiteArgs = {
  input: GQCreateMeasurementTestSuiteInput;
};

export type GQMutationCreateMeasurementTestSuiteBartArgs = {
  input: GQCreateMeasurementTestSuiteBartInput;
};

export type GQMutationCreateOrgRoleArgs = {
  input: GQCreateOrgRoleInput;
};

export type GQMutationCreateOrganizationArgs = {
  input: GQCreateOrganizationInput;
};

export type GQMutationCreatePermissionItemArgs = {
  input: GQCreatePermissionItemInput;
};

export type GQMutationCreatePlanArgs = {
  input: GQCreatePlanInput;
};

export type GQMutationCreatePublicDisclosureArgs = {
  input: GQCreatePublicDisclosureInput;
};

export type GQMutationCreatePublicDisclosureTargetArgs = {
  input: GQCreatePublicDisclosureTargetInput;
};

export type GQMutationCreateReferenceDataCitationArgs = {
  input: GQCreateReferenceDataCitationInput;
};

export type GQMutationCreateReferenceDataOverlayTemplateArgs = {
  input: GQCreateReferenceDataOverlayTemplateInput;
};

export type GQMutationCreateReferenceDataSourceArgs = {
  input: GQCreateReferenceDataSourceInput;
};

export type GQMutationCreateReferenceDataVersionArgs = {
  input: GQCreateReferenceDataVersionInput;
};

export type GQMutationCreateReportConfigArgs = {
  input: GQCreateReportConfigInput;
};

export type GQMutationCreateReportConfigFromSrcArgs = {
  input: GQCreateReportConfigFromSrcInput;
};

export type GQMutationCreateReportQuestionArgs = {
  input: GQCreateReportQuestionInput;
};

export type GQMutationCreateReportQuestionContainerArgs = {
  input: GQCreateReportQuestionContainerInput;
};

export type GQMutationCreateReportQuestionIdentifierMappingArgs = {
  identifierId1: Scalars['ID']['input'];
  identifierId2: Scalars['ID']['input'];
};

export type GQMutationCreateReportWithConfigAdminArgs = {
  input: GQCreateReportWithConfigAdminInput;
};

export type GQMutationCreateReportingFrameworkArgs = {
  input: GQCreateReportingFrameworkInput;
};

export type GQMutationCreateReportingFrameworkDataPointsArgs = {
  input: GQCreateReportingFrameworkDataPointsInput;
};

export type GQMutationCreateSupplierChartAdminArgs = {
  input: GQCreateSupplierChartInputAdmin;
};

export type GQMutationCreateTargetArgs = {
  input: GQCreateTargetInput;
};

export type GQMutationCreateTcfdArchetypeOpportunityArgs = {
  input: GQCreateTcfdArchetypeOpportunityInput;
};

export type GQMutationCreateTcfdArchetypeRiskArgs = {
  input: GQCreateTcfdArchetypeRiskInput;
};

export type GQMutationCreateUserAdminVersionArgs = {
  input: GQCreateUserAdminVersionInput;
};

export type GQMutationCreateUserUploadTaskArgs = {
  input: GQCreateUserUploadTaskInput;
};

export type GQMutationDebugPlaygroundSqlArgs = {
  input: GQRunPlaygroundSqlInput;
};

export type GQMutationDeclineCompanyIngestionDiffArgs = {
  input: GQIdInput;
};

export type GQMutationDeclineCompanyIngestionUpdateArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteAllAnswersForReportArgs = {
  id: Scalars['ID']['input'];
};

export type GQMutationDeleteAllMarketplaceAllocationInstructionsArgs = {
  input: GQDeleteAllMarketplaceAllocationInstructionsInput;
};

export type GQMutationDeleteAllReductionsDataArgs = {
  input: GQDeleteAllReductionsDataInput;
};

export type GQMutationDeleteCalculationTagArgs = {
  input: GQDeleteCalculationTagInput;
};

export type GQMutationDeleteCanonicalClimateProgramProjectArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteCanonicalClimateProgramProjectRequirementDatasetArgs =
  {
    input: GQDeleteCanonicalClimateProgramProjectRequirementDatasetInput;
  };

export type GQMutationDeleteCanonicalClimateProgramProjectRequirementGhgpArgs =
  {
    input: GQDeleteCanonicalClimateProgramProjectRequirementGhgpInput;
  };

export type GQMutationDeleteCdpColumnMappingsArgs = {
  input: GQDeleteCdpColumnMappingsInput;
};

export type GQMutationDeleteCdpQuestionMappingsArgs = {
  input: GQDeleteCdpQuestionMappingsInput;
};

export type GQMutationDeleteCommentArgs = {
  input: GQDeleteCommentInput;
};

export type GQMutationDeleteCompanyClimateCommitmentArgs = {
  input: GQDeleteCompanyClimateCommitmentInput;
};

export type GQMutationDeleteCompanyRelationshipArgs = {
  input: GQDeleteCompanyRelationshipInput;
};

export type GQMutationDeleteCompanySourceKeyArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteCtsArgs = {
  input: GQDeleteCtsInput;
};

export type GQMutationDeleteCtsTransformTestCaseArgs = {
  input: GQDeleteCtsTransformTestCaseInput;
};

export type GQMutationDeleteDataIssueArgs = {
  input: GQDeleteDataIssueInput;
};

export type GQMutationDeleteDataRegistryTablesArgs = {
  input: GQDeleteDataRegistryTablesInput;
};

export type GQMutationDeleteDatasetArgs = {
  input: GQDeleteDatasetInput;
};

export type GQMutationDeleteDatasourceAdminArgs = {
  input: GQDeleteDatasourceInput;
};

export type GQMutationDeleteEacPriceEstimateArgs = {
  input: GQDeleteEacPriceEstimateInput;
};

export type GQMutationDeleteEmissionsModelReleaseArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteEmissionsYearArgs = {
  input: GQDeleteEmissionsYearInput;
};

export type GQMutationDeleteEngagementTaskConfigForAdminArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteEngagementTasksAndAnswersByCrossOrgModelIdArgs = {
  input: GQDeleteEngagementTasksAndAnswersByCrossOrgModelIdInput;
};

export type GQMutationDeleteExternalReportQuestionArgs = {
  input: GQDeleteExternalReportQuestionInput;
};

export type GQMutationDeleteExternalReportTypeWithRevisionArgs = {
  input: GQDeleteExternalReportTypeWithRevisionInput;
};

export type GQMutationDeleteFacilitiesSetupTaskArgs = {
  input: GQDeleteFacilitiesSetupTaskInput;
};

export type GQMutationDeleteFileArgs = {
  input: GQDeleteFileInput;
};

export type GQMutationDeleteFinancialsReviewItemArgs = {
  input: GQDeleteFinancialsReviewItemInput;
};

export type GQMutationDeleteFootprintTagArgs = {
  input: GQDeleteFootprintTagInput;
};

export type GQMutationDeleteFootprintTestSuiteConfigArgs = {
  input: GQDeleteFootprintTestSuiteConfigInput;
};

export type GQMutationDeleteFundsArgs = {
  input: GQDeleteFundsInput;
};

export type GQMutationDeleteMarketplaceAllocationInstructionArgs = {
  input: GQDeleteMarketplaceAllocationInstructionInput;
};

export type GQMutationDeleteMarketplaceDeveloperArgs = {
  input: GQDeleteMarketplaceDeveloperInput;
};

export type GQMutationDeleteMarketplaceDocumentArgs = {
  input: GQDeleteMarketplaceDocumentInput;
};

export type GQMutationDeleteMarketplaceProjectArgs = {
  input: GQDeleteMarketplaceProjectInput;
};

export type GQMutationDeleteMarketplacePurchaseArgs = {
  input: GQDeleteMarketplacePurchaseInput;
};

export type GQMutationDeleteMarketplacePurchaseLineItemArgs = {
  input: GQDeleteMarketplacePurchaseLineItemInput;
};

export type GQMutationDeleteMaterialVariantsForOrgArgs = {
  input: GQDeleteMaterialVariantsForOrgInput;
};

export type GQMutationDeleteMeasurementProjectArgs = {
  input: GQDeleteMeasurementProjectInput;
};

export type GQMutationDeleteMeasurementTestRowsArgs = {
  input: GQDeleteMeasurementTestRowsInput;
};

export type GQMutationDeleteMeasurementTestSuiteArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteMeasurementTestSuiteBartArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteOneSchemaTemplateArgs = {
  input: GQDeleteOneSchemaTemplateInput;
};

export type GQMutationDeleteOrganizationArgs = {
  input: GQDeleteOrganizationInput;
};

export type GQMutationDeletePlanAdminArgs = {
  id: Scalars['ID']['input'];
};

export type GQMutationDeletePublicDisclosureArgs = {
  input: GQDeletePublicDisclosureInput;
};

export type GQMutationDeletePublicDisclosureTargetArgs = {
  input: GQDeletePublicDisclosureTargetInput;
};

export type GQMutationDeleteReportAnswerWarningsByValidatorIdArgs = {
  input: GQDeleteReportAnswerWarningsByValidatorIdInput;
};

export type GQMutationDeleteReportAnswersArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQMutationDeleteReportConfigArgs = {
  input: GQDeleteReportConfigInput;
};

export type GQMutationDeleteReportQuestionArgs = {
  input: GQDeleteReportQuestionInput;
};

export type GQMutationDeleteReportQuestionContainerArgs = {
  input: GQDeleteReportQuestionContainerInput;
};

export type GQMutationDeleteReportQuestionIdentifierMappingArgs = {
  identifierId1: Scalars['ID']['input'];
  identifierId2: Scalars['ID']['input'];
};

export type GQMutationDeleteReportingFrameworkArgs = {
  input: GQDeleteReportingFrameworkInput;
};

export type GQMutationDeleteReportingFrameworkDataPointsArgs = {
  input: GQDeleteReportingFrameworkDataPointsInput;
};

export type GQMutationDeleteRoleArgs = {
  input: GQDeleteRoleInput;
};

export type GQMutationDeleteSupplierChartAdminArgs = {
  input: GQDeleteSupplierChartInputAdmin;
};

export type GQMutationDeleteTargetArgs = {
  input: GQDeleteTargetInput;
};

export type GQMutationDeleteTcfdArchetypeRiskArgs = {
  input: GQDeleteTcfdArchetypeRiskInput;
};

export type GQMutationDeleteUserUploadTaskArgs = {
  input: GQDeleteUserUploadTaskInput;
};

export type GQMutationDuplicateCtsArgs = {
  input: GQDuplicateCtsInput;
};

export type GQMutationDuplicateMeasurementTestSuiteArgs = {
  input: GQDuplicateMeasurementTestSuiteInput;
};

export type GQMutationDuplicatePlanAdminArgs = {
  id: Scalars['ID']['input'];
};

export type GQMutationEditEmissionsYearArgs = {
  input: GQEditEmissionsYearInput;
};

export type GQMutationEditExternalReportQuestionArgs = {
  input: GQEditExternalReportQuestionInput;
};

export type GQMutationEditExternalReportTypeWithRevisionArgs = {
  input: GQEditExternalReportTypeWithRevisionInput;
};

export type GQMutationEditPublicDisclosureArgs = {
  input: GQEditPublicDisclosureInput;
};

export type GQMutationEnqueueBackgroundJobArgs = {
  input: GQEnqueueBackgroundJobInput;
};

export type GQMutationEraseReportComponentArgs = {
  input: GQEraseReportComponentInput;
};

export type GQMutationExportMeasurementTestSuiteArgs = {
  input: GQIdInput;
};

export type GQMutationExportMeasurementTestSuiteBundleArgs = {
  input: GQIdInput;
};

export type GQMutationFetchIntegrationDataArgs = {
  input: GQFetchIntegrationDataInput;
};

export type GQMutationFindDuckArgs = {
  input: GQFindDuckInput;
};

export type GQMutationFixReduxValidationErrorsArgs = {
  input: GQFixReduxValidationErrorsInput;
};

export type GQMutationForkEmissionsModelArgs = {
  input: GQForkEmissionsModelInput;
};

export type GQMutationFulfillShareFileRequestArgs = {
  input: GQFulfillShareFileRequestInput;
};

export type GQMutationGenerateGmailXmlArgs = {
  input: GQGenerateGmailXmlInput;
};

export type GQMutationGetNewDecryptedTokenArgs = {
  input: GQGetNewDecryptedTokenInput;
};

export type GQMutationGrantContractorAccessToOrgArgs = {
  input: GQGrantContractorAccessToOrgInput;
};

export type GQMutationGrantCurrentUserAdminAccessToOrgArgs = {
  input: GQGrantCurrentUserAdminAccessToOrgInput;
};

export type GQMutationGrantFinanceUsersPortcoAccessesArgs = {
  input: GQGrantFinanceUsersPortcoAccessesInput;
};

export type GQMutationGrantUserRoleArgs = {
  input: GQGrantUserRoleInput;
};

export type GQMutationImportMeasurementTestSuiteArgs = {
  input: GQImportMeasurementTestSuiteInput;
};

export type GQMutationInitializeDefaultSupplierViewAdminArgs = {
  input: GQInitializeDefaultSupplierViewAdminInput;
};

export type GQMutationInitializeWorkosArgs = {
  input: GQInitializeWorkosInput;
};

export type GQMutationInsertReportQuestionIdentifierArgs = {
  input: GQInsertReportQuestionIdentifierInput;
};

export type GQMutationInvalidateMeasurementTestSuitePageArgs = {
  input: GQIdInput;
};

export type GQMutationLinkAndUnlinkEmissionsModelsToReleaseArgs = {
  input: GQLinkAndUnlinkEmissionsModelsToReleaseInput;
};

export type GQMutationLinkGlobalFootprintTagsToReleaseArgs = {
  input: GQLinkGlobalFootprintTagsToReleaseInput;
};

export type GQMutationLintReportConfigArgs = {
  input: GQLintReportConfigInput;
};

export type GQMutationLogLocalMeasurementStateArgs = {
  data: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  tag: Scalars['String']['input'];
};

export type GQMutationLoginAsUserArgs = {
  input: GQLoginAsUserInput;
};

export type GQMutationMakeFootprintSnapshotUserVisibleArgs = {
  input: GQMakeFootprintSnapshotUserVisibleInput;
};

export type GQMutationMarkAdminUploadCompletedArgs = {
  input: GQMarkAdminUploadCompletedInput;
};

export type GQMutationMarkMeasurementProjectDatasetCompletedArgs = {
  input: GQMarkMeasurementProjectDatasetCompletedInput;
};

export type GQMutationMarkUserUploadAcknowledgedArgs = {
  input: GQMarkUserUploadAcknowledgedInput;
};

export type GQMutationMarkUserUploadedTablesIgnoredArgs = {
  input: GQMarkUserUploadedTablesIgnoredInput;
};

export type GQMutationMergeAssetsArgs = {
  input: GQMergeAssetsInput;
};

export type GQMutationMigrateOrgPlansToOrgFiscalMonthArgs = {
  input: GQMigrateOrgPlansToOrgFiscalMonthInput;
};

export type GQMutationParquetDataToCsvArgs = {
  input: GQParquetDataToCsvInput;
};

export type GQMutationPreviewDataIssueEmailsArgs = {
  input: GQPreviewDataIssueEmailsInput;
};

export type GQMutationPreviewFinancialsReviewEmailsArgs = {
  input: GQPreviewFinancialsReviewEmailsInput;
};

export type GQMutationProcessMaterialVariantAndLcaFileArgs = {
  input: GQProcessMaterialVariantAndLcaFileInput;
};

export type GQMutationProcessProductionGraphFileArgs = {
  input: GQProcessProductionGraphFileInput;
};

export type GQMutationPublishCtsVersionArgs = {
  input: GQPublishCtsVersionInput;
};

export type GQMutationPublishCtsVersionForTestingArgs = {
  input: GQPublishCtsVersionInput;
};

export type GQMutationPublishDataIssuesArgs = {
  input: GQPublishDataIssuesInput;
};

export type GQMutationPublishEngagementTaskConfigForAdminArgs = {
  input: GQPublishEngagementTaskConfigForAdminInput;
};

export type GQMutationPublishExtractorArgs = {
  input: GQPublishExtractorInput;
};

export type GQMutationPublishFinancialsReviewItemsArgs = {
  input: GQPublishFinancialsReviewItemsInput;
};

export type GQMutationPublishReferenceDataVersionArgs = {
  input: GQPublishReferenceDataVersionInput;
};

export type GQMutationPushCtsVersionToOneSchemaArgs = {
  input: GQPushCtsVersionToOneSchemaInput;
};

export type GQMutationPushStagingReportConfigToProdArgs = {
  input: GQPushStagingReportConfigToProdInput;
};

export type GQMutationRefreshAssetYearEstimationsArgs = {
  input: GQRefreshAssetYearEstimationsInput;
};

export type GQMutationRefreshCarbonRemovalActivityDataTableArgs = {
  input: GQRefreshCarbonRemovalActivityDataTableInput;
};

export type GQMutationRegenerateUserSignupTokenAdminVersionArgs = {
  input: GQRegenerateUserSignupTokenInput;
};

export type GQMutationReingestCompanyIngestionDiffsArgs = {
  input: GQReingestCompanyIngestionDiffInput;
};

export type GQMutationReleaseEmissionsModelActiveDraftArgs = {
  input: GQReleaseEmissionsModelActiveDraftInput;
};

export type GQMutationRemoveCalculationTagFromReferenceDataArgs = {
  input: GQRemoveCalculationTagFromReferenceDataInput;
};

export type GQMutationRemoveCalculationTagFromStableModelArgs = {
  input: GQRemoveCalculationTagFromStableModelInput;
};

export type GQMutationRemoveMarketplaceSupplierPointOfContactArgs = {
  input: GQRemoveMarketplaceSupplierPointOfContactInput;
};

export type GQMutationRemoveMarketplaceSupplierWatershedEmployeeArgs = {
  input: GQRemoveMarketplaceSupplierWatershedEmployeeInput;
};

export type GQMutationRemoveUserAdminVersionArgs = {
  input: GQRemoveUserAdminVersionInput;
};

export type GQMutationRenameCustomEmissionsFactorSetupTaskArgs = {
  input: GQRenameCustomEmissionsFactorSetupTaskInput;
};

export type GQMutationReplaceReportingCdpMappingsArgs = {
  input: GQReplaceReportingCdpMappingsInput;
};

export type GQMutationResetLockedUserAdminVersionArgs = {
  input: GQResetLockedUserAdminVersionInput;
};

export type GQMutationRestoreReportArgs = {
  input: GQRestoreReportInput;
};

export type GQMutationReviewPrepublicationTestResultsForEmissionsModelArgs = {
  input: GQReviewPrepublicationTestResultsInput;
};

export type GQMutationReviewPrepublicationTestResultsForReferenceDataRevisionArgs =
  {
    input: GQReviewPrepublicationTestResultsInput;
  };

export type GQMutationReviewPrepublicationTestResultsForReleaseArgs = {
  input: GQReviewPrepublicationTestResultsInput;
};

export type GQMutationRevokePermissionItemArgs = {
  input: GQRevokePermissionItemInput;
};

export type GQMutationRevokeUserRoleArgs = {
  input: GQRevokeUserRoleInput;
};

export type GQMutationRunCtsTransformTestCaseArgs = {
  input: GQRunCtsTransformTestCaseInput;
};

export type GQMutationRunDataRegistryMigrationArgs = {
  input: GQRunDataRegistryMigrationInput;
};

export type GQMutationRunExtractorArgs = {
  input: GQRunExtractorInput;
};

export type GQMutationRunPlaygroundSqlArgs = {
  input: GQRunPlaygroundSqlInput;
};

export type GQMutationRunReportAnswerVerifierArgs = {
  input: GQRunReportAnswerVerifierInput;
};

export type GQMutationRunSchemaValidationForReferenceDataVersionArgs = {
  input: GQRunSchemaValidationForReferenceDataVersionInput;
};

export type GQMutationSaveEmissionsModelDraftArgs = {
  input: GQSaveEmissionsModelDraftInput;
};

export type GQMutationSaveFootprintTagArgs = {
  input: GQSaveFootprintTagInput;
};

export type GQMutationSaveGlobalFootprintTagArgs = {
  input: GQSaveGlobalFootprintTagInput;
};

export type GQMutationScoreAnswerArgs = {
  answerId: Scalars['ID']['input'];
  score: InputMaybe<GQDisclosureQualityScore>;
};

export type GQMutationSeedBatOntologyArgs = {
  input: GQSeedBatOntologyInput;
};

export type GQMutationSetCalculationTagReferenceDatasArgs = {
  sourceIds: Array<Scalars['ID']['input']>;
  tag: Scalars['String']['input'];
};

export type GQMutationSetCalculationTagStableModelsArgs = {
  stableModelIds: Array<Scalars['ID']['input']>;
  tag: Scalars['String']['input'];
};

export type GQMutationSetEmissionsModelIsArchivedArgs = {
  input: GQSetEmissionsModelIsArchivedInput;
};

export type GQMutationSetForecastFootprintSnapshotIdArgs = {
  input: GQSetForecastFootprintSnapshotIdInput;
};

export type GQMutationSetOrgsForFeatureFlagArgs = {
  input: GQSetOrgsForFeatureFlagInput;
};

export type GQMutationSetPreferredLocaleArgs = {
  input: GQSetPreferredLocaleInput;
};

export type GQMutationSetSchemaRegistrySettingsArgs = {
  input: GQSetSchemaRegistrySettingsInput;
};

export type GQMutationSetUserUploadedTableNotIgnoredArgs = {
  input: GQSetUserUploadedTableNotIgnoredInput;
};

export type GQMutationSetUserUploadedTableSqlTableNameArgs = {
  input: GQSetUserUploadedTableSqlTableNameInput;
};

export type GQMutationShareFilesArgs = {
  input: GQShareFilesInput;
};

export type GQMutationShareMeasurementProjectArgs = {
  input: GQShareMeasurementProjectInput;
};

export type GQMutationSignalExecuteMeasurementTestSuiteArgs = {
  input: GQSignalExecuteMeasurementTestSuiteInput;
};

export type GQMutationStartPrepublicationTestsForEmissionsModelArgs = {
  input: GQStartPrepublicationTestsForEmissionsModelInput;
};

export type GQMutationStartPrepublicationTestsForReferenceDataRevisionArgs = {
  input: GQStartPrepublicationTestsForReferenceDataRevisionInput;
};

export type GQMutationStartPrepublicationTestsForReleaseArgs = {
  input: GQStartPrepublicationTestsForReleaseInput;
};

export type GQMutationStartPublishingEmissionsModelArgs = {
  input: GQStartPublishingEmissionsModelInput;
};

export type GQMutationStartWorkflowArgs = {
  input: GQStartWorkflowInput;
};

export type GQMutationSwapEmissionsModelExpectationsForFootprintTestRowsArgs = {
  input: GQSwapEmissionsModelExpectationsForFootprintTestRowsInput;
};

export type GQMutationSwapEmissionsModelExpectationsForTestRowsArgs = {
  input: GQSwapEmissionsModelExpectationsForMeasurementTestRowsInput;
};

export type GQMutationSyncFeatureFlagsArgs = {
  input: GQSyncFeatureFlagsInput;
};

export type GQMutationSyncMeasurementMappingsArgs = {
  input: GQSyncMeasurementMappingsInput;
};

export type GQMutationTestOrgCompanyDuckDbPerformanceArgs = {
  input: GQTestOrgCompanyDuckDbPerformanceInput;
};

export type GQMutationThrowErrorArgs = {
  input: GQThrowErrorInput;
};

export type GQMutationToggleCliqMutabilityForEmissionsModelArgs = {
  input: GQToggleCliqMutabilityForEmissionsModelInput;
};

export type GQMutationToggleCliqSyncForReferenceDataSourceArgs = {
  input: GQToggleCliqSyncForReferenceDataSourceInput;
};

export type GQMutationToggleMeasurementTestSuiteCriticalArgs = {
  measurementTestSuiteId: Scalars['ID']['input'];
};

export type GQMutationToggleVendorMatchingTaskAutomappingArgs = {
  vendorMatchingTaskId: Scalars['ID']['input'];
};

export type GQMutationUnassociateIntegrationConnectionFromDatasourceArgs = {
  input: GQUnassociateIntegrationConnectionFromDatasourceInput;
};

export type GQMutationUncompleteMeasurementProjectArgs = {
  input: GQUncompleteMeasurementProjectInput;
};

export type GQMutationUndeleteMeasurementTestSuiteArgs = {
  input: GQIdInput;
};

export type GQMutationUndoFacilitiesApprovalsMigrationArgs = {
  input: GQUndoFacilitiesApprovalsMigrationInput;
};

export type GQMutationUnlinkGlobalFootprintTagsFromReleaseArgs = {
  input: GQUnlinkGlobalFootprintTagsFromReleaseInput;
};

export type GQMutationUnpublishActivityDataTableArgs = {
  input: GQUnpublishActivityDataTableInput;
};

export type GQMutationUnpublishCtsVersionArgs = {
  input: GQUnpublishCtsVersionInput;
};

export type GQMutationUpdateActivityDataTableArgs = {
  input: GQUpdateActivityDataTableInput;
};

export type GQMutationUpdateCalculationTagArgs = {
  input: GQUpdateCalculationTagInput;
};

export type GQMutationUpdateCanonicalClimateProgramProjectArgs = {
  input: GQUpdateCanonicalClimateProgramProjectInput;
};

export type GQMutationUpdateCanonicalClimateProgramProjectRequirementDatasetArgs =
  {
    input: GQUpdateCanonicalClimateProgramProjectRequirementDatasetInput;
  };

export type GQMutationUpdateCanonicalClimateProgramProjectRequirementGhgpArgs =
  {
    input: GQUpdateCanonicalClimateProgramProjectRequirementGhgpInput;
  };

export type GQMutationUpdateCanonicalDatasetArgs = {
  input: GQUpdateCanonicalDatasetInput;
};

export type GQMutationUpdateCanonicalDatasourceArgs = {
  input: GQUpdateCanonicalDatasourceInput;
};

export type GQMutationUpdateCommentArgs = {
  input: GQUpdateCommentInput;
};

export type GQMutationUpdateCompanyClimateCommitmentArgs = {
  input: GQUpdateCompanyClimateCommitmentInput;
};

export type GQMutationUpdateCompanySourceKeyArgs = {
  input: GQUpdateCompanySourceKeyInput;
};

export type GQMutationUpdateCtsArgs = {
  input: GQUpdateCtsInput;
};

export type GQMutationUpdateCtsTransformTestCaseArgs = {
  input: GQUpdateCtsTransformTestCaseInput;
};

export type GQMutationUpdateDataIssueArgs = {
  input: GQUpdateDataIssueInput;
};

export type GQMutationUpdateDataIssuesArgs = {
  input: Array<GQUpdateDataIssueInput>;
};

export type GQMutationUpdateDataRegistryBatchMigratorRunArgs = {
  input: GQUpdateDataRegistryBatchMigratorRunInput;
};

export type GQMutationUpdateDatasetArgs = {
  input: GQUpdateDatasetInput;
};

export type GQMutationUpdateDatasourceAdminArgs = {
  input: GQUpdateDatasourceAdminInput;
};

export type GQMutationUpdateEacPriceEstimateArgs = {
  input: GQUpdateEacPriceEstimateInput;
};

export type GQMutationUpdateEacPriceEstimatesArgs = {
  input: GQUpdateEacPriceEstimatesInput;
};

export type GQMutationUpdateEmissionsModelReleaseLifecyclePhaseArgs = {
  input: GQUpdateEmissionsModelReleaseLifecyclePhaseInput;
};

export type GQMutationUpdateEmissionsModelReleaseMetadataArgs = {
  input: GQUpdateEmissionsModelReleaseMetadataInput;
};

export type GQMutationUpdateEmissionsModelStableArgs = {
  input: GQUpdateEmissionsModelStableInput;
};

export type GQMutationUpdateEngagementTaskConfigForAdminArgs = {
  input: GQEngagementTaskConfigInputForAdmin;
};

export type GQMutationUpdateFileMetadataPermissionDelegatesArgs = {
  input: GQUpdateFileMetadataPermissionDelegatesInput;
};

export type GQMutationUpdateFinancialsReviewItemArgs = {
  input: GQUpdateFinancialsReviewItemInput;
};

export type GQMutationUpdateForecastArgs = {
  input: GQUpdateForecastInput;
};

export type GQMutationUpdateFundArgs = {
  input: GQUpdateFundInput;
};

export type GQMutationUpdateIngestionInstructionsArgs = {
  input: GQUpdateIngestionInstructionsInput;
};

export type GQMutationUpdateIntegrationConnectionArgs = {
  input: GQUpdateIntegrationConnectionInput;
};

export type GQMutationUpdateLifecycleAssessmentArgs = {
  input: GQUpdateLifecycleAssessmentInput;
};

export type GQMutationUpdateMarketplaceAllocationInstructionArgs = {
  input: GQUpdateMarketplaceAllocationInstructionInput;
};

export type GQMutationUpdateMarketplaceDeveloperArgs = {
  input: GQUpdateMarketplaceDeveloperInput;
};

export type GQMutationUpdateMarketplaceDocumentArgs = {
  input: GQUpdateMarketplaceDocumentInput;
};

export type GQMutationUpdateMarketplaceProjectArgs = {
  input: GQUpdateMarketplaceProjectInput;
};

export type GQMutationUpdateMarketplaceProjectArchetypeArgs = {
  input: GQUpdateMarketplaceProjectArchetypeInput;
};

export type GQMutationUpdateMarketplacePurchaseArgs = {
  input: GQUpdateMarketplacePurchaseInput;
};

export type GQMutationUpdateMarketplacePurchaseLineItemArgs = {
  input: GQUpdateMarketplacePurchaseLineItemInput;
};

export type GQMutationUpdateMarketplaceSupplierArgs = {
  input: GQUpdateMarketplaceSupplierInput;
};

export type GQMutationUpdateMeasurementProjectArgs = {
  input: GQUpdateMeasurementProjectInput;
};

export type GQMutationUpdateMeasurementTestSuiteArgs = {
  input: GQUpdateMeasurementTestSuiteInput;
};

export type GQMutationUpdateMeasurementTestSuiteBartArgs = {
  input: GQUpdateMeasurementTestSuiteBartInput;
};

export type GQMutationUpdateOrgForFeatureFlagArgs = {
  input: GQUpdateOrgForFeatureFlagInput;
};

export type GQMutationUpdateOrgProfileArgs = {
  input: GQUpdateOrgProfileInput;
};

export type GQMutationUpdateOrganizationArgs = {
  input: GQUpdateOrganizationInput;
};

export type GQMutationUpdatePipelineResultArgs = {
  input: GQUpdatePipelineResultInput;
};

export type GQMutationUpdatePublicDisclosureTargetArgs = {
  input: GQUpdatePublicDisclosureTargetInput;
};

export type GQMutationUpdateReferenceDataCitationArgs = {
  input: GQUpdateReferenceDataCitationInput;
};

export type GQMutationUpdateReferenceDataRevisionArgs = {
  input: GQUpdateReferenceDataRevisionInput;
};

export type GQMutationUpdateReferenceDataSourceArgs = {
  input: GQUpdateReferenceDataSourceInput;
};

export type GQMutationUpdateReferenceDataVersionArgs = {
  input: GQUpdateReferenceDataVersionInput;
};

export type GQMutationUpdateReferenceDataVersionSchemaArgs = {
  input: GQUpdateReferenceDataVersionSchemaInput;
};

export type GQMutationUpdateReportArgs = {
  input: GQUpdateReportInput;
};

export type GQMutationUpdateReportConfigArgs = {
  input: GQUpdateReportConfigInput;
};

export type GQMutationUpdateReportEsrsMappingArgs = {
  input: GQUpdateReportEsrsMappingInput;
};

export type GQMutationUpdateReportQuestionArgs = {
  input: GQUpdateReportQuestionInput;
};

export type GQMutationUpdateReportQuestionContainerArgs = {
  input: GQUpdateReportQuestionContainerInput;
};

export type GQMutationUpdateReportingFrameworkArgs = {
  input: GQUpdateReportingFrameworkInput;
};

export type GQMutationUpdateReportingFrameworkDataPointsArgs = {
  input: GQUpdateReportingFrameworkDataPointsInput;
};

export type GQMutationUpdateReportingIntentionsForOrgArgs = {
  input: GQUpdateReportingIntentionsForOrgInput;
};

export type GQMutationUpdateRoleArgs = {
  input: GQUpdateRoleInput;
};

export type GQMutationUpdateSupplierChartAdminArgs = {
  input: GQUpdateSupplierChartInputAdmin;
};

export type GQMutationUpdateSuppliersSettingsAdminArgs = {
  input: GQUpdateSuppliersSettingsAdminInput;
};

export type GQMutationUpdateTargetArgs = {
  input: GQUpdateTargetInput;
};

export type GQMutationUpdateTcfdArchetypeOpportunityArgs = {
  input: GQUpdateTcfdArchetypeOpportunityInput;
};

export type GQMutationUpdateTcfdArchetypeRiskArgs = {
  input: GQUpdateTcfdArchetypeRiskInput;
};

export type GQMutationUpdateTestRowsInTestSuiteBartArgs = {
  input: GQUpdateTestRowsInTestSuiteBartInput;
};

export type GQMutationUpdateUserEmailAdminVersionArgs = {
  input: GQUpdateUserEmailAdminVersionInput;
};

export type GQMutationUpdateUserNameAdminVersionArgs = {
  input: GQUpdateUserNameAdminVersionInput;
};

export type GQMutationUpdateUserUploadTaskArgs = {
  input: GQUpdateUserUploadTaskInput;
};

export type GQMutationUploadMarketplaceDocumentsArgs = {
  input: GQUploadMarketplaceDocumentsInput;
};

export type GQMutationUpsertCdpColumnMappingsArgs = {
  input: GQUpsertCdpColumnMappingsInput;
};

export type GQMutationUpsertCdpQuestionMappingsArgs = {
  input: GQUpsertCdpQuestionMappingsInput;
};

export type GQMutationUpsertCompanyArgs = {
  input: GQCompanyInput;
};

export type GQMutationUpsertDocumentParseAttemptArgs = {
  input: GQUpsertDocumentParseAttemptInput;
};

export type GQMutationUpsertFootprintTestSuiteConfigArgs = {
  input: GQUpsertFootprintTestSuiteConfigInput;
};

export type GQMutationValidateEmissionsModelMatcherArgs = {
  input: GQValidateEmissionsModelMatcherInput;
};

export type GQMutationValidateSchemaRegistrySettingsArgs = {
  input: GQValidateSchemaRegistrySettingsInput;
};

export type GQMutationVerifyReportAnswerWithAlertArgs = {
  input: GQVerifyReportAnswerWithAlertInput;
};

export type GQMutationVerifyReportAnswersArgs = {
  reportAnswerIds: Array<Scalars['ID']['input']>;
};

export type GQNaicsEf = {
  __typename?: 'NaicsEF';
  emissionsSpecifier: Scalars['String']['output'];
  emissionsSubspecifier: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kgco2e: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
};

export type GQNetZeroCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'NetZeroCommitment';
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    targetYear: Maybe<Scalars['Int']['output']>;
  };

export type GQNetZeroCommitmentInput = {
  targetYear?: InputMaybe<Scalars['Int']['input']>;
};

export const GQNetZeroNormalizationKind = {
  Revenue: 'Revenue',
  Total: 'Total',
} as const;

export type GQNetZeroNormalizationKind =
  (typeof GQNetZeroNormalizationKind)[keyof typeof GQNetZeroNormalizationKind];
export type GQNetZeroPlan = GQIdInterface & {
  __typename?: 'NetZeroPlan';
  descriptionMd: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  summary: Maybe<GQNetZeroSummary>;
};

export type GQNetZeroPlanSummaryArgs = {
  kind: InputMaybe<GQNetZeroNormalizationKind>;
};

export type GQNetZeroSummary = {
  __typename?: 'NetZeroSummary';
  kind: GQNetZeroNormalizationKind;
  years: Array<GQNetZeroSummaryYear>;
};

export type GQNetZeroSummaryYear = {
  __typename?: 'NetZeroSummaryYear';
  bauEmissionsAvoided: Scalars['Float']['output'];
  carbonRemoval: Scalars['Float']['output'];
  emissionsWithReductions: Scalars['Float']['output'];
  kind: GQNetZeroNormalizationKind;
  year: Scalars['Int']['output'];
};

export type GQNetsuiteIntegrationSettings = {
  __typename?: 'NetsuiteIntegrationSettings';
  accountNamesToExclude: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  transactionTypeFilters: Array<Scalars['String']['output']>;
  usePostingPeriodForDateFilters: Scalars['Boolean']['output'];
};

export type GQNetsuiteIntegrationSettingsInput = {
  accountNamesToExclude: Array<Scalars['String']['input']>;
  transactionTypeFilters: Array<Scalars['String']['input']>;
  usePostingPeriodForDateFilters: Scalars['Boolean']['input'];
};

export type GQNewMeasurementMappingInput = {
  authorExplanation?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endMonth: Scalars['YearMonth']['input'];
  ghgCategoryId?: InputMaybe<Scalars['String']['input']>;
  inputEmissionsSpecifier: Scalars['String']['input'];
  inputEmissionsSubspecifier: Scalars['String']['input'];
  outputEmissionsSpecifier: Scalars['String']['input'];
  outputEmissionsSubspecifier: Scalars['String']['input'];
  shouldInclude: Scalars['Boolean']['input'];
  startMonth: Scalars['YearMonth']['input'];
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  vendorEntity: Scalars['String']['input'];
};

export const GQNextLifecycleStage = {
  Measurement: 'Measurement',
  Reductions: 'Reductions',
  Reporting: 'Reporting',
} as const;

export type GQNextLifecycleStage =
  (typeof GQNextLifecycleStage)[keyof typeof GQNextLifecycleStage];
export const GQObjectType = {
  Building: 'Building',
} as const;

export type GQObjectType = (typeof GQObjectType)[keyof typeof GQObjectType];
export const GQOnboardingKind = {
  BenchmarksV1Onboarding: 'BenchmarksV1Onboarding',
  BiDrilldownBeta: 'BiDrilldownBeta',
  BiDrilldownSavedViews: 'BiDrilldownSavedViews',
  CdpUpdateFy2024Q4Onboarding: 'CDPUpdateFY2024Q4Onboarding',
  ClimateProgramTimelineOnboarding: 'ClimateProgramTimelineOnboarding',
  ConvergenceHomepage: 'ConvergenceHomepage',
  ConvergenceOverall: 'ConvergenceOverall',
  ConvergenceReporting: 'ConvergenceReporting',
  ConvergenceWhereAreViews: 'ConvergenceWhereAreViews',
  DataLineageCalculationTableOnboarding:
    'DataLineageCalculationTableOnboarding',
  DataLineageDetailViewOnboarding: 'DataLineageDetailViewOnboarding',
  DataReviewOnboarding: 'DataReviewOnboarding',
  DedupeV2Banner: 'DedupeV2Banner',
  DrilldownDataGridBeta: 'DrilldownDataGridBeta',
  FinanceFlagsOnboarding: 'FinanceFlagsOnboarding',
  FootprintFeedbackSurvey: 'FootprintFeedbackSurvey',
  IdiOnboarding: 'IdiOnboarding',
  MarketplaceOnboarding: 'MarketplaceOnboarding',
  MeasurementEmptyStateExpandHowToMeasure:
    'MeasurementEmptyStateExpandHowToMeasure',
  MeasurementTaskTabOnboarding: 'MeasurementTaskTabOnboarding',
  MeasurementTasksOnboarding: 'MeasurementTasksOnboarding',
  MeasurementTimelineOnboarding: 'MeasurementTimelineOnboarding',
  MultipleFootprintsBeta: 'MultipleFootprintsBeta',
  MultipleFootprintsIntroTour: 'MultipleFootprintsIntroTour',
  NetworkTasksCalloutOnboarding: 'NetworkTasksCalloutOnboarding',
  NetworkTasksOnboarding: 'NetworkTasksOnboarding',
  NewBuildingsPageOnboarding: 'NewBuildingsPageOnboarding',
  NewToMeasurementOnboarding: 'NewToMeasurementOnboarding',
  ReductionsOnboarding: 'ReductionsOnboarding',
  ReduxEnterpriseOnboarding: 'ReduxEnterpriseOnboarding',
  ReportingCustomReportsWelcome: 'ReportingCustomReportsWelcome',
  SavedViewsLandingPage: 'SavedViewsLandingPage',
  TcfdOnboarding: 'TcfdOnboarding',
  ValueMappingOnboarding: 'ValueMappingOnboarding',
  WelcomeOnboarding: 'WelcomeOnboarding',
} as const;

export type GQOnboardingKind =
  (typeof GQOnboardingKind)[keyof typeof GQOnboardingKind];
export const GQOneSchemaDataFormat = {
  AssetCorporate: 'AssetCorporate',
  AssetGroup: 'AssetGroup',
  AssetHoldingAll: 'AssetHoldingAll',
  AssetPersonalMotorVehicleInsurance: 'AssetPersonalMotorVehicleInsurance',
  AssetRealEstate: 'AssetRealEstate',
  AssetSovereignBond: 'AssetSovereignBond',
  AssetYearDefault: 'AssetYearDefault',
  Fund: 'Fund',
  PersonalMotorVehicleInsuranceAssetHolding:
    'PersonalMotorVehicleInsuranceAssetHolding',
  PersonalMotorVehicleInsuranceAssetYear:
    'PersonalMotorVehicleInsuranceAssetYear',
  PointOfContact: 'PointOfContact',
  SovereignBondAssetYear: 'SovereignBondAssetYear',
  SupplierContact: 'SupplierContact',
} as const;

export type GQOneSchemaDataFormat =
  (typeof GQOneSchemaDataFormat)[keyof typeof GQOneSchemaDataFormat];
export type GQOneSchemaJwtPayload = {
  __typename?: 'OneSchemaJwtPayload';
  token: Scalars['String']['output'];
};

export type GQOrgAccess = {
  __typename?: 'OrgAccess';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  loginActivated: Scalars['Boolean']['output'];
};

export type GQOrgAdminSuppliers = {
  __typename?: 'OrgAdminSuppliers';
  organization: GQOrganization;
  suppliers: GQAdminSuppliers;
};

export type GQOrgCompany = {
  __typename?: 'OrgCompany';
  company: Maybe<GQCompany>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  source: Maybe<GQOrgCompanySource>;
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
};

export type GQOrgCompanyAndCompanyNameForCompany = {
  companyInfo: GQCreateCompanyBaseInput;
  orgCompanyName: Scalars['String']['input'];
};

export type GQOrgCompanyDuckDbPerformanceResult = {
  __typename?: 'OrgCompanyDuckDbPerformanceResult';
  success: Maybe<Scalars['Boolean']['output']>;
  totalRows: Maybe<Scalars['Int']['output']>;
  totalTime: Maybe<Scalars['Int']['output']>;
};

export const GQOrgCompanySource = {
  CorporateSupplier: 'CorporateSupplier',
  FundHolding: 'FundHolding',
  NonFootprintSupplier: 'NonFootprintSupplier',
  SandboxCompany: 'SandboxCompany',
} as const;

export type GQOrgCompanySource =
  (typeof GQOrgCompanySource)[keyof typeof GQOrgCompanySource];
export type GQOrgConnection = {
  __typename?: 'OrgConnection';
  edges: Array<Maybe<GQOrgEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Org' and its cursor. */
export type GQOrgEdge = {
  __typename?: 'OrgEdge';
  cursor: Scalars['String']['output'];
  node: GQOrganization;
};

/** An object representing limited essential information about an organization */
export type GQOrgLimitedProfile = {
  __typename?: 'OrgLimitedProfile';
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  engagementTasks: Array<GQEngagementTaskLimitedProfile>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  watershedPlanLegacy: GQWatershedPlanLegacy;
};

export type GQOrgPointOfContact = GQIdInterface & {
  __typename?: 'OrgPointOfContact';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  kind: GQOrgPointOfContactKind;
  orgId: Scalars['String']['output'];
  watershedEmployee: GQWatershedEmployee;
};

export const GQOrgPointOfContactKind = {
  AccountManager: 'AccountManager',
  AccountOwner: 'AccountOwner',
  CarbonDataAnalyst: 'CarbonDataAnalyst',
  ClimateAdvisor: 'ClimateAdvisor',
  CustomerSuccessManager: 'CustomerSuccessManager',
} as const;

export type GQOrgPointOfContactKind =
  (typeof GQOrgPointOfContactKind)[keyof typeof GQOrgPointOfContactKind];
export type GQOrgPost = GQIdInterface & {
  __typename?: 'OrgPost';
  content: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  summary: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type GQOrgPostConnection = {
  __typename?: 'OrgPostConnection';
  edges: Array<Maybe<GQOrgPostEdge>>;
  pageInfo: GQPageInfo;
};

export type GQOrgPostEdge = {
  __typename?: 'OrgPostEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQOrgPost>;
};

export type GQOrgProfile = {
  __typename?: 'OrgProfile';
  climateCommitments: Maybe<Array<GQCompanyClimateCommitmentKind>>;
  climateGoals: Maybe<Array<Scalars['String']['output']>>;
  climateMotivation: Maybe<Scalars['String']['output']>;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  completedBy: Maybe<GQUser>;
  country: Maybe<Scalars['String']['output']>;
  fiscalYearStartMonth: Maybe<Scalars['Int']['output']>;
  hasClimateCommitments: Maybe<GQYesNoUnknown>;
  hasMeasuredFootprintOutsideOfWatershed: Maybe<GQYesNoUnknown>;
  id: Scalars['ID']['output'];
  industry: Maybe<Scalars['String']['output']>;
  isCompanyDataConfirmed: Maybe<Scalars['Boolean']['output']>;
  lastNonWatershedMeasurementYearString: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  otherClimateCommitment: Maybe<Scalars['String']['output']>;
  /** @deprecated Use country. Remove in July 2023 */
  primaryOperatingCountry: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<GQUser>;
};

export type GQOrgStructureVersion = {
  __typename?: 'OrgStructureVersion';
  effectiveFromDate: Maybe<Scalars['DateTime']['output']>;
  effectiveToDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  orgUnitTypes: Array<GQOrgUnitType>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: GQUser;
};

export type GQOrgStructureVersionUpload = {
  __typename?: 'OrgStructureVersionUpload';
  accepted: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['ID']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  diff: Maybe<Scalars['UntypedData']['output']>;
  diffFileMetadataId: Maybe<Scalars['ID']['output']>;
  errorMessages: Scalars['UntypedData']['output'];
  id: Scalars['ID']['output'];
  isInvalid: Maybe<Scalars['Boolean']['output']>;
  orgId: Scalars['ID']['output'];
  orgStructureVersionId: Scalars['ID']['output'];
  originalFileMetadataId: Maybe<Scalars['ID']['output']>;
  rawOutputFileMetadataId: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uploadStats: Scalars['UntypedData']['output'];
};

export type GQOrgUnit = {
  __typename?: 'OrgUnit';
  description: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgUnitDatasources: Array<GQOrgUnitDatasource>;
  orgUnitTypeId: Scalars['String']['output'];
  relatedOrgUnits: Array<GQOrgUnit>;
  stableId: Scalars['String']['output'];
};

export type GQOrgUnitDatasource = {
  __typename?: 'OrgUnitDatasource';
  createdAt: Scalars['DateTime']['output'];
  datasourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgUnitStableId: Scalars['String']['output'];
  orgUnitTypeStableId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQOrgUnitRelationship = {
  __typename?: 'OrgUnitRelationship';
  id: Scalars['ID']['output'];
  orgUnitIdFrom: Scalars['ID']['output'];
  orgUnitIdTo: Scalars['ID']['output'];
};

export const GQOrgUnitRelationshipCardinality = {
  ManyToMany: 'ManyToMany',
  OneToMany: 'OneToMany',
  OneToOne: 'OneToOne',
} as const;

export type GQOrgUnitRelationshipCardinality =
  (typeof GQOrgUnitRelationshipCardinality)[keyof typeof GQOrgUnitRelationshipCardinality];
export type GQOrgUnitType = {
  __typename?: 'OrgUnitType';
  cyclesWhenFromNode: Array<Maybe<Array<Scalars['String']['output']>>>;
  cyclesWhenToNode: Array<Maybe<Array<Scalars['String']['output']>>>;
  enabledDatasets: Array<GQDatasetForOrgUnitType>;
  enabledForAllDatasets: Scalars['Boolean']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isLegalEntity: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  orgUnits: Array<GQOrgUnit>;
  relatedOrgUnitTypes: Array<GQOrgUnitType>;
  relatedOrgUnits: Array<GQOrgUnit>;
  relationship: GQOrgUnitTypeRelationship;
  relationships: Array<GQOrgUnitTypeRelationship>;
  requiredForAllDatasets: Scalars['Boolean']['output'];
  stableId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: GQUser;
};

export type GQOrgUnitTypeCyclesWhenFromNodeArgs = {
  baseOrgUnitTypeId: Scalars['ID']['input'];
};

export type GQOrgUnitTypeCyclesWhenToNodeArgs = {
  baseOrgUnitTypeId: Scalars['ID']['input'];
};

export type GQOrgUnitTypeRelatedOrgUnitsArgs = {
  relatedOrgUnitTypeId: Scalars['ID']['input'];
};

export type GQOrgUnitTypeRelationshipArgs = {
  relatedOrgUnitTypeId: Scalars['ID']['input'];
};

export type GQOrgUnitTypeRelationship = {
  __typename?: 'OrgUnitTypeRelationship';
  cardinality: GQOrgUnitRelationshipCardinality;
  defaultOrgUnitId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  orgUnitTypeIdFrom: Scalars['ID']['output'];
  orgUnitTypeIdTo: Scalars['ID']['output'];
};

export type GQOrganization = GQIdInterface & {
  __typename?: 'Organization';
  apiKeys: Array<GQApiKey>;
  bigqueryDatasetName: Maybe<Scalars['String']['output']>;
  bigqueryTableSchema: Scalars['String']['output'];
  buildingCompletionIntervalsUntyped: Array<GQBuildingCompletionIntervalsUntyped>;
  buildingNames: Array<Scalars['String']['output']>;
  buildingsUntyped: Array<GQBuildingUntyped>;
  canAccessCorporate: Maybe<Scalars['Boolean']['output']>;
  canAccessFinance: Maybe<Scalars['Boolean']['output']>;
  company: GQCompany;
  cookieTimeoutDays: Maybe<Scalars['Int']['output']>;
  country: Maybe<Scalars['String']['output']>;
  csrdDataRequirementsConfigs: Array<GQCsrdDataRequirementsConfig>;
  currency: Maybe<Scalars['String']['output']>;
  dataIssues: Array<GQDataIssue>;
  datasets: Array<GQDataset>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  demoOrg: Scalars['Boolean']['output'];
  domains: Array<Scalars['String']['output']>;
  engagementFunnels: Array<GQEngagementFunnel>;
  facilitiesMetadata: GQFacilitiesMetadata;
  fiscalYearStartMonth: Maybe<Scalars['Int']['output']>;
  footprintCategoryRules: Array<GQFootprintCategoryRule>;
  footprintExclusionRules: Array<GQFootprintExclusionRule>;
  footprintExclusionSuggestions: Array<GQFootprintExclusionSuggestion>;
  hasActiveWorkosConnection: Scalars['Boolean']['output'];
  hasAnyPv2PipelineResults: Scalars['Boolean']['output'];
  hasUsers: Scalars['Boolean']['output'];
  helpChannel: Maybe<Scalars['String']['output']>;
  iconRemotePath: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ingestionCustomFields: Array<GQIngestionCustomField>;
  isWatershed: Scalars['Boolean']['output'];
  legalName: Maybe<Scalars['String']['output']>;
  lifecycle: Maybe<GQClimateProgramLifecycle>;
  logoRemotePath: Maybe<Scalars['String']['output']>;
  magicLinkDisabled: Scalars['Boolean']['output'];
  measurementProjects: Array<GQMeasurementProject>;
  name: Scalars['String']['output'];
  notionUrl: Maybe<Scalars['String']['output']>;
  orgAccess: Maybe<GQOrgAccess>;
  orgCompanies: Array<GQOrgCompany>;
  passwordAuthDisabled: Scalars['Boolean']['output'];
  pointsOfContact: Array<GQOrgPointOfContact>;
  pointsOfContactIncludingDeleted: Array<GQOrgPointOfContact>;
  productWaitlistTypes: Array<GQProductWaitlistType>;
  profile: GQOrgProfile;
  region: GQRegion;
  roles: Maybe<GQRoleConnection>;
  sbtiTargetExclusions: Array<GQSbtiTargetExclusion>;
  sessionTimeoutMinutes: Maybe<Scalars['Int']['output']>;
  sharedFileRequests: GQSharedFileRequestConnection;
  sharedFiles: GQSharedFileConnection;
  shouldShowOnboarding: Scalars['Boolean']['output'];
  stagingOrg: Maybe<Scalars['Boolean']['output']>;
  targets: GQTargetConnection;
  testOrg: Scalars['Boolean']['output'];
  users: Maybe<GQUserConnection>;
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
  watershedSlackChannelId: Maybe<Scalars['String']['output']>;
  workosConnections: Array<GQWorkosConnection>;
  workosDirectories: Array<GQWorkosDirectory>;
  workosOrgId: Maybe<Scalars['String']['output']>;
};

export type GQOrganizationCsrdDataRequirementsConfigsArgs = {
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQOrganizationFootprintCategoryRulesArgs = {
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
};

export type GQOrganizationFootprintExclusionRulesArgs = {
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
};

export type GQOrganizationFootprintExclusionSuggestionsArgs = {
  footprintSnapshotId: Scalars['ID']['input'];
};

export type GQOrganizationMeasurementProjectsArgs = {
  active: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQOrganizationPointsOfContactArgs = {
  kinds: InputMaybe<Array<GQOrgPointOfContactKind>>;
};

export type GQOrganizationRolesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationSharedFileRequestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationSharedFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationTargetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationUsersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQUserFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationInboxItem = GQDataIssue | GQUserUploadTask;

export type GQOrganizationInboxItemConnection = {
  __typename?: 'OrganizationInboxItemConnection';
  edges: Array<Maybe<GQOrganizationInboxItemEdge>>;
  pageInfo: GQPageInfo;
};

export type GQOrganizationInboxItemEdge = {
  __typename?: 'OrganizationInboxItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQOrganizationInboxItem>;
};

export type GQOrganizationPayload = {
  __typename?: 'OrganizationPayload';
  org: GQOrganization;
};

export const GQOrganizationState = {
  Active: 'ACTIVE',
  Onboarding: 'ONBOARDING',
} as const;

export type GQOrganizationState =
  (typeof GQOrganizationState)[keyof typeof GQOrganizationState];
/** The Relay compliant 'PageInfo' type, containing data necessary to paginate this connection. */
export type GQPageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
  /**
   * Only some connections will provide a total row count, because it can be
   * expensive to calculate.
   */
  totalRowCount: Maybe<Scalars['Int']['output']>;
};

export type GQParameterResolver = GQIdInterface & {
  __typename?: 'ParameterResolver';
  authorId: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type GQParentRelationship = {
  __typename?: 'ParentRelationship';
  id: Scalars['ID']['output'];
  parent: GQCompany;
  parentId: Scalars['ID']['output'];
  relationshipEndDate: Maybe<Scalars['YearMonth']['output']>;
  relationshipStartDate: Maybe<Scalars['YearMonth']['output']>;
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export type GQParquetColumn = {
  __typename?: 'ParquetColumn';
  approxDistinctValues: Scalars['Int']['output'];
  maxValue: Scalars['JSONString']['output'];
  minValue: Scalars['JSONString']['output'];
  name: Scalars['String']['output'];
  numNulls: Scalars['Int']['output'];
  sampleValues: Array<Scalars['JSONString']['output']>;
  sqlType: Scalars['String']['output'];
};

export type GQParquetData = GQIdInterface & {
  __typename?: 'ParquetData';
  columns: Array<GQParquetColumn>;
  id: Scalars['ID']['output'];
  ndjsonByteBoundaries: Maybe<Array<Scalars['Int53']['output']>>;
  ndjsonSignedUrl: Maybe<Scalars['String']['output']>;
  ndjsonSize: Maybe<Scalars['Int53']['output']>;
  numRecords: Scalars['Int']['output'];
};

export type GQParquetDataToCsvInput = {
  parquetDataId: Scalars['ID']['input'];
};

export type GQParquetDataToCsvPayload = {
  __typename?: 'ParquetDataToCsvPayload';
  jobId: Scalars['String']['output'];
};

export const GQPcafScore = {
  Score1: 'Score1',
  Score2: 'Score2',
  Score3: 'Score3',
  Score4: 'Score4',
  Score5: 'Score5',
} as const;

export type GQPcafScore = (typeof GQPcafScore)[keyof typeof GQPcafScore];
export type GQPeerCompanyGroup = {
  __typename?: 'PeerCompanyGroup';
  createdAt: Scalars['DateTime']['output'];
  footprintKinds: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  members: Array<GQPeerCompanyGroupMember>;
  name: Scalars['String']['output'];
  revenueForRevenueAtRisk: Maybe<Scalars['Int53']['output']>;
  selfBenchmarks: Array<GQBenchmark>;
  shouldHideFromFootprintOverview: Scalars['Boolean']['output'];
};

export type GQPeerCompanyGroupSelfBenchmarksArgs = {
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQPeerCompanyGroupMember = {
  __typename?: 'PeerCompanyGroupMember';
  company: GQCompany;
  id: Scalars['ID']['output'];
  mostRecentHighQualityBenchmark: Maybe<GQBenchmark>;
  revenueAtRisk: Maybe<Scalars['Int53']['output']>;
  shouldHideFromFootprintOverview: Scalars['Boolean']['output'];
};

export type GQPeerCompanyGroupPayload = {
  __typename?: 'PeerCompanyGroupPayload';
  id: Scalars['ID']['output'];
  peerCompanyGroup: GQPeerCompanyGroup;
};

export type GQPeerExternalReportAnswers = {
  __typename?: 'PeerExternalReportAnswers';
  answers: Array<GQExternalReportAnswer>;
  peerCompanies: Array<GQCompany>;
  questions: Array<GQExternalReportQuestion>;
};

export const GQPermission = {
  Measurement: 'Measurement',
  MeasurementAdmin: 'MeasurementAdmin',
  Viewer: 'Viewer',
  WatershedAdmin: 'WatershedAdmin',
} as const;

export type GQPermission = (typeof GQPermission)[keyof typeof GQPermission];
export type GQPermissionConnection = {
  __typename?: 'PermissionConnection';
  edges: Array<Maybe<GQPermissionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPermissionEdge = {
  __typename?: 'PermissionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPermissionItem>;
};

export type GQPermissionItem = {
  __typename?: 'PermissionItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  object: Maybe<
    | GQDataset
    | GQDatasource
    | GQFootprint
    | GQFund
    | GQReport
    | GQReportQuestionInstance
  >;
  objectId: Maybe<Scalars['ID']['output']>;
  org: GQOrganization;
  permission: GQPermissionType;
  revokedAt: Maybe<Scalars['DateTime']['output']>;
  role: Maybe<GQRole>;
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
};

export type GQPermissionItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  permission: GQPermissionType;
  revokedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GQPermissionObject = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQPermissionObjectConnection = {
  __typename?: 'PermissionObjectConnection';
  edges: Array<Maybe<GQPermissionObjectEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPermissionObjectEdge = {
  __typename?: 'PermissionObjectEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<
    | GQDataset
    | GQDatasource
    | GQFootprint
    | GQFund
    | GQReport
    | GQReportQuestionInstance
  >;
};

export const GQPermissionObjectType = {
  Dataset: 'Dataset',
  Datasource: 'Datasource',
  Footprint: 'Footprint',
  Fund: 'Fund',
  Report: 'Report',
  ReportQuestionInstance: 'ReportQuestionInstance',
} as const;

export type GQPermissionObjectType =
  (typeof GQPermissionObjectType)[keyof typeof GQPermissionObjectType];
export type GQPermissionObjectWithChildren = {
  children: Array<
    | GQDataset
    | GQDatasource
    | GQFootprint
    | GQFund
    | GQReport
    | GQReportQuestionInstance
  >;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQPermissionObjectWithChildrenChildrenArgs = {
  search: InputMaybe<Scalars['String']['input']>;
};

export const GQPermissionType = {
  Admin: 'Admin',
  AnyUser: 'AnyUser',
  ApproveDataset: 'ApproveDataset',
  ApproveDatasource: 'ApproveDatasource',
  ApproveFootprint: 'ApproveFootprint',
  CorporateAdmin: 'CorporateAdmin',
  EditReport: 'EditReport',
  EditReportQuestionInstance: 'EditReportQuestionInstance',
  FinanceAdmin: 'FinanceAdmin',
  FinanceReadOnly: 'FinanceReadOnly',
  ManageCompanyTags: 'ManageCompanyTags',
  ManageDataset: 'ManageDataset',
  ManageDatasource: 'ManageDatasource',
  ManageDisclosures: 'ManageDisclosures',
  ManageFund: 'ManageFund',
  ManageMarketplacePurchases: 'ManageMarketplacePurchases',
  ManageMeasurement: 'ManageMeasurement',
  ManageOrgHierarchy: 'ManageOrgHierarchy',
  ManageReductionPlans: 'ManageReductionPlans',
  ManageSingleSignOn: 'ManageSingleSignOn',
  ManageSuppliers: 'ManageSuppliers',
  ViewAuditDetail: 'ViewAuditDetail',
  ViewEmployeeReport: 'ViewEmployeeReport',
  ViewFootprint: 'ViewFootprint',
  ViewFootprintDetail: 'ViewFootprintDetail',
  ViewLearningHub: 'ViewLearningHub',
  ViewReductions: 'ViewReductions',
  ViewReport: 'ViewReport',
  ViewReportQuestionInstance: 'ViewReportQuestionInstance',
  WatershedAdmin: 'WatershedAdmin',
} as const;

export type GQPermissionType =
  (typeof GQPermissionType)[keyof typeof GQPermissionType];
export type GQPerson = {
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Please use isWatershedEmployee or isWatershedContractor */
  isWatershed: Scalars['Boolean']['output'];
  isWatershedContractor: Scalars['Boolean']['output'];
  isWatershedEmployee: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type GQPipelineConfig = {
  __typename?: 'PipelineConfig';
  coverageInterval: Scalars['YMInterval']['output'];
  pipelineResultId: Scalars['ID']['output'];
};

export type GQPipelineConfigInput = {
  coverageInterval: Scalars['YMInterval']['input'];
  pipelineResultId: Scalars['ID']['input'];
};

export const GQPipelineKind = {
  AmazonCurPipeline: 'amazon_cur_pipeline',
  BuildingsPipeline: 'buildings_pipeline',
  CloudComputePipeline: 'cloud_compute_pipeline',
  CloudSpendPipeline: 'cloud_spend_pipeline',
  CommutePipeline: 'commute_pipeline',
  DangerousExperiment: 'dangerous_experiment',
  EmissionsModel: 'emissions_model',
  FinancialsPipeline: 'financials_pipeline',
  ManualUpload: 'manual_upload',
  PipelineV2All: 'pipeline_v2_all',
  PipelineV2Cloud: 'pipeline_v2_cloud',
  PipelineV2Commute: 'pipeline_v2_commute',
  PipelineV2Financials: 'pipeline_v2_financials',
  PipelineV2Flights: 'pipeline_v2_flights',
  PipelineV2Offices: 'pipeline_v2_offices',
  PipelineV2Travel: 'pipeline_v2_travel',
  PipelineV2Wfh: 'pipeline_v2_wfh',
  TravelPipeline: 'travel_pipeline',
  WastePipeline: 'waste_pipeline',
  WfhPipeline: 'wfh_pipeline',
} as const;

export type GQPipelineKind =
  (typeof GQPipelineKind)[keyof typeof GQPipelineKind];
export type GQPipelineOutputSummary = {
  __typename?: 'PipelineOutputSummary';
  emissions: Array<GQPipelineOutputSummaryEmission>;
  footprintQuantityMeta: Maybe<GQFootprintQuantityKindMetadata>;
  format: Scalars['Int']['output'];
  warnings: Maybe<Array<GQValidationWarning>>;
};

export type GQPipelineOutputSummaryEmission = {
  __typename?: 'PipelineOutputSummaryEmission';
  adtName: Maybe<Scalars['String']['output']>;
  businessCategory: Maybe<Scalars['String']['output']>;
  footprintKind: Scalars['String']['output'];
  kgCo2e: Scalars['Float']['output'];
  month: Scalars['Date']['output'];
};

export type GQPipelineResult = GQIdInterface & {
  __typename?: 'PipelineResult';
  coverageInterval: Scalars['YMInterval']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  emissionsModelEvaluations: Array<GQEmissionsModelEvaluation>;
  id: Scalars['ID']['output'];
  isManual: Scalars['Boolean']['output'];
  kgco2eByColumnSummary: Array<GQPipelineResultQuerySummaryRow>;
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  parquetDataId: Maybe<Scalars['ID']['output']>;
  pipelineKind: GQPipelineKind;
  pipelineRunConfig: Scalars['String']['output'];
  prkFileMetadataId: Maybe<Scalars['String']['output']>;
  runUserId: Maybe<Scalars['ID']['output']>;
  sourceData: Scalars['String']['output'];
  summary: GQPipelineOutputSummary;
  summaryJson: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQPipelineResultKgco2eByColumnSummaryArgs = {
  columns: Array<Scalars['String']['input']>;
};

export type GQPipelineResultConnection = {
  __typename?: 'PipelineResultConnection';
  edges: Array<Maybe<GQPipelineResultEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPipelineResultEdge = {
  __typename?: 'PipelineResultEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPipelineResult>;
};

export type GQPipelineResultEmissionsModelEvaluation = GQIdInterface & {
  __typename?: 'PipelineResultEmissionsModelEvaluation';
  createdAt: Scalars['DateTime']['output'];
  emissionsModelEvaluation: GQEmissionsModelEvaluation;
  emissionsModelEvaluationId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  pipelineResult: GQPipelineResult;
  pipelineResultId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQPipelineResultQuerySummaryColumnValue = {
  __typename?: 'PipelineResultQuerySummaryColumnValue';
  columnName: Scalars['String']['output'];
  columnValue: Maybe<Scalars['String']['output']>;
};

export type GQPipelineResultQuerySummaryRow = {
  __typename?: 'PipelineResultQuerySummaryRow';
  columns: Array<GQPipelineResultQuerySummaryColumnValue>;
  kgco2e: Scalars['Float']['output'];
};

export type GQPipelineRun = GQIdInterface & {
  __typename?: 'PipelineRun';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  pipelineResults: Array<GQPipelineResult>;
};

export type GQPipelineRunConnection = {
  __typename?: 'PipelineRunConnection';
  edges: Array<Maybe<GQPipelineRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPipelineRunEdge = {
  __typename?: 'PipelineRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPipelineRun>;
};

export type GQPlan = GQIdInterface & {
  __typename?: 'Plan';
  baselineYearStart: Scalars['YearMonth']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  fiscalMonth: Scalars['Int']['output'];
  forecast: GQForecast;
  hideCarbonAvoidance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  isSbtValidated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  reduxCarbonOffsets: GQSimpleTimeseries;
  reduxCarbonOffsetsCosts: GQSimpleTimeseries;
  reduxCarbonRemoval: GQSimpleTimeseries;
  reduxCarbonRemovalCosts: GQSimpleTimeseries;
  reduxInitiatives: Array<
    GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative
  >;
  relevantFootprintFields: Array<Scalars['String']['output']>;
  state: GQPlanState;
  targets: Array<GQPlanTarget>;
  updatedAt: Scalars['DateTime']['output'];
  updatedByName: Maybe<Scalars['String']['output']>;
  variables: GQPlanVariables;
};

export type GQPlanTargetsArgs = {
  ids: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQPlanCarbonFund = {
  __typename?: 'PlanCarbonFund';
  highQualityOffset: Scalars['Float']['output'];
  natureRemoval: Scalars['Float']['output'];
  offset: Scalars['Float']['output'];
  rndRemoval: Scalars['Float']['output'];
};

export type GQPlanCarbonFundInput = {
  highQualityOffset: Scalars['Float']['input'];
  natureRemoval: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  rndRemoval: Scalars['Float']['input'];
};

export type GQPlanConnection = {
  __typename?: 'PlanConnection';
  edges: Array<Maybe<GQPlanEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Plan' and its cursor. */
export type GQPlanEdge = {
  __typename?: 'PlanEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPlan>;
};

export const GQPlanState = {
  Archived: 'Archived',
  Committed: 'Committed',
  Draft: 'Draft',
} as const;

export type GQPlanState = (typeof GQPlanState)[keyof typeof GQPlanState];
export type GQPlanTarget = GQIdInterface & {
  __typename?: 'PlanTarget';
  assignedTo: Maybe<Scalars['String']['output']>;
  baseYear: Scalars['YearMonth']['output'];
  comparisonType: GQPlanTargetTargetComparisonType;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customIntensityConfigId: Maybe<Scalars['ID']['output']>;
  customNotes: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  emissionsTargetCustom: GQSimpleTimeseries;
  filters: GQFilterExpressionGroup;
  id: Scalars['ID']['output'];
  intensityType: GQPlanTargetIntensityType;
  interimTargetDate: Maybe<Scalars['YearMonth']['output']>;
  interimTargetValue: Maybe<Scalars['Float']['output']>;
  parentTarget: Maybe<GQPlanTarget>;
  parentTargetId: Maybe<Scalars['String']['output']>;
  reductionRate: GQTargetReductionRate;
  reportDescription: Scalars['String']['output'];
  subtargets: Array<GQPlanTarget>;
};

export const GQPlanTargetIntensityType = {
  Absolute: 'Absolute',
  Custom: 'Custom',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  NightsStayed: 'NightsStayed',
  Orders: 'Orders',
  Patients: 'Patients',
  RenewableElectricity: 'RenewableElectricity',
  Revenue: 'Revenue',
  SupplierEngagement: 'SupplierEngagement',
  SupplierEngagementBySpend: 'SupplierEngagementBySpend',
} as const;

export type GQPlanTargetIntensityType =
  (typeof GQPlanTargetIntensityType)[keyof typeof GQPlanTargetIntensityType];
export const GQPlanTargetTargetComparisonType = {
  BaseYear: 'BaseYear',
  BusinessAsUsual: 'BusinessAsUsual',
} as const;

export type GQPlanTargetTargetComparisonType =
  (typeof GQPlanTargetTargetComparisonType)[keyof typeof GQPlanTargetTargetComparisonType];
export type GQPlanVariables = {
  __typename?: 'PlanVariables';
  cleanPowerStart: Scalars['YearMonth']['output'];
  commitmentsHasNetZero: Scalars['Boolean']['output'];
  commitmentsHasSBT: Scalars['Boolean']['output'];
  commitmentsNetZeroYear: Scalars['Int']['output'];
  commitmentsSBTScope3: GQSbtScope3Target;
  commitmentsSBTScope12: GQSbtScope1And2Target;
  commitmentsSBTSubmissionDate: Scalars['YearMonth']['output'];
  commitmentsSBTTargetYear: Scalars['Int']['output'];
  commitmentsSBTTermLength: GQSbtTermLength;
  flightsPerEmployeeForecastEnd: Scalars['Float']['output'];
  netZeroSuppliersReductionPercent: Scalars['Float']['output'];
  percentWorkFromHomeForecastEnd: Scalars['Float']['output'];
};

export type GQPlanVariablesInput = {
  cleanPowerStart: Scalars['YearMonth']['input'];
  commitmentsHasNetZero: Scalars['Boolean']['input'];
  commitmentsHasSBT: Scalars['Boolean']['input'];
  commitmentsNetZeroYear: Scalars['Int']['input'];
  commitmentsSBTScope3: GQSbtScope3Target;
  commitmentsSBTScope12: GQSbtScope1And2Target;
  commitmentsSBTSubmissionDate: Scalars['YearMonth']['input'];
  commitmentsSBTTargetYear: Scalars['Int']['input'];
  commitmentsSBTTermLength: GQSbtTermLength;
  flightsPerEmployeeForecastEnd: Scalars['Float']['input'];
  netZeroSuppliersReductionPercent?: InputMaybe<Scalars['Float']['input']>;
  percentWorkFromHomeForecastEnd: Scalars['Float']['input'];
};

export type GQPlaygroundSql = GQIdInterface & {
  __typename?: 'PlaygroundSql';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  sql: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQPlaygroundSqlRun = GQIdInterface &
  GQQueryRunInterface & {
    __typename?: 'PlaygroundSqlRun';
    createdAt: Scalars['DateTime']['output'];
    error: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    jobError: Maybe<GQSerializableError>;
    output: Maybe<GQParquetData>;
    sql: Scalars['String']['output'];
    state: GQBackgroundJobState;
    user: Maybe<GQUser>;
  };

export type GQPlaygroundSqlRunConnection = {
  __typename?: 'PlaygroundSqlRunConnection';
  edges: Array<Maybe<GQPlaygroundSqlRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPlaygroundSqlRunEdge = {
  __typename?: 'PlaygroundSqlRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPlaygroundSqlRun>;
};

export type GQPortcoFootprintReviewRequestMetadata = {
  __typename?: 'PortcoFootprintReviewRequestMetadata';
  footprintSnapshotId: Scalars['ID']['output'];
  footprintVersionKind: GQFootprintSnapshotStatus;
  id: Scalars['ID']['output'];
  status: GQWatershedFootprintReviewRequestStatus;
};

export type GQPrecomputedActivityBasedInitiative = GQIdInterface &
  GQInitiative & {
    __typename?: 'PrecomputedActivityBasedInitiative';
    costTimeseries: GQSimpleTimeseries;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    description: Maybe<Scalars['String']['output']>;
    enabled: Scalars['Boolean']['output'];
    filters: GQFilterExpressionGroupWithNewFilters;
    id: Scalars['ID']['output'];
    implementationTimeseries: GQSimpleTimeseries;
    lever: GQPrecomputedLever;
    name: Scalars['String']['output'];
    plan: GQPlan;
    scheduleType: GQScheduleType;
  };

export const GQPrecomputedLever = {
  ChangeCareLabelToAirDry: 'ChangeCareLabelToAirDry',
  ChangeCareLabelToWashCold: 'ChangeCareLabelToWashCold',
  ElectrifyGasStovesInOffices: 'ElectrifyGasStovesInOffices',
  EliminateEmployeeWfhElectricityEmissions:
    'EliminateEmployeeWfhElectricityEmissions',
  EnergyEfficiencyImprovementsHeatPumps:
    'EnergyEfficiencyImprovementsHeatPumps',
  GreenRideShareOptions: 'GreenRideShareOptions',
  GroundTransportInsteadOfAir: 'GroundTransportInsteadOfAir',
  ImplementGreenCodingPractices: 'ImplementGreenCodingPractices',
  ImproveElectricityEfficiencyOfficeBuildings:
    'ImproveElectricityEfficiencyOfficeBuildings',
  LocalizeSupply: 'LocalizeSupply',
  OptimizeCloudComputeType: 'OptimizeCloudComputeType',
  OptimizeCloudHostingLocation: 'OptimizeCloudHostingLocation',
  ProductUsersAdoptMoreEfficientWasherDryers:
    'ProductUsersAdoptMoreEfficientWasherDryers',
  PurchaseReCsOrEaCs: 'PurchaseRECsOrEACs',
  ReduceEmissionsFromElectricityProduction:
    'ReduceEmissionsFromElectricityProduction',
  ReduceRefrigerantLeakageRate: 'ReduceRefrigerantLeakageRate',
  ReduceTotalTravelMiles: 'ReduceTotalTravelMiles',
  ReplaceConventionalJetFuelWithSustainableAviationFuel:
    'ReplaceConventionalJetFuelWithSustainableAviationFuel',
  RequirePassengerTrainInsteadOfShortHaulFlight:
    'RequirePassengerTrainInsteadOfShortHaulFlight',
  SwapAirFreightToRailFreight: 'SwapAirFreightToRailFreight',
  SwapAirFreightToSeaFreight: 'SwapAirFreightToSeaFreight',
  SwapEmployeeCommuteFromIceVehicleToElectricVehicle:
    'SwapEmployeeCommuteFromIceVehicleToElectricVehicle',
  SwapEmployeeCommuteFromIceVehicleToPublicTransport:
    'SwapEmployeeCommuteFromIceVehicleToPublicTransport',
  SwapFlightClassFromBusinessToEconomy: 'SwapFlightClassFromBusinessToEconomy',
  SwapFlightClassFromFirstToEconomy: 'SwapFlightClassFromFirstToEconomy',
  SwapGroundTransportToRailFreight: 'SwapGroundTransportToRailFreight',
  SwapProductsSentToLandfillToRecycled: 'SwapProductsSentToLandfillToRecycled',
  SwapToLowerGwpRefrigerants: 'SwapToLowerGWPRefrigerants',
  SwapWasteFromLandfillToCompost: 'SwapWasteFromLandfillToCompost',
  SwapWasteFromLandfillToRecycle: 'SwapWasteFromLandfillToRecycle',
  SwitchNaturalGasToHeatPumps: 'SwitchNaturalGasToHeatPumps',
  SwitchToLowEmissionsCloudProvider: 'SwitchToLowEmissionsCloudProvider',
} as const;

export type GQPrecomputedLever =
  (typeof GQPrecomputedLever)[keyof typeof GQPrecomputedLever];
export type GQPreflightEraseReportComponentPayload = {
  __typename?: 'PreflightEraseReportComponentPayload';
  affectedOrgs: Array<GQOrganization>;
  componentId: Scalars['String']['output'];
  reportAnswers: Array<GQReportAnswer>;
  reportConfigs: Array<GQReportConfig>;
  reportQuestions: Array<GQReportQuestion>;
  reports: Array<GQReport>;
};

export type GQPrepublicationTestResults = {
  __typename?: 'PrepublicationTestResults';
  emissionsModelValidationErrors: Maybe<Array<GQEmissionsModelError>>;
  measurementTestSuiteExecutions: Array<GQPrepublicationTestSuiteExecution>;
};

export type GQPrepublicationTestSuiteExecution = {
  __typename?: 'PrepublicationTestSuiteExecution';
  errorMessage: Maybe<Scalars['String']['output']>;
  footprintTestSuiteExecution: Maybe<GQFootprintTestSuiteExecution>;
  measurementTestSuite: GQMeasurementTestSuite;
  methodologyTestSuiteExecution: GQMethodologyTestSuiteExecution;
};

export type GQPresetCustomTag = {
  __typename?: 'PresetCustomTag';
  ingestionCustomField: GQIngestionCustomField;
  ingestionCustomFieldDatasource: GQIngestionCustomFieldDatasource;
};

export type GQPresetOrgUnit = {
  __typename?: 'PresetOrgUnit';
  orgUnit: GQOrgUnit;
  orgUnitDatasource: GQOrgUnitDatasource;
};

export type GQPreviewDataIssueEmailsInput = {
  commentIds: Array<Scalars['ID']['input']>;
  issueIds: Array<Scalars['ID']['input']>;
};

export type GQPreviewDataIssueEmailsPayload = {
  __typename?: 'PreviewDataIssueEmailsPayload';
  numberOfEmails: Scalars['Int']['output'];
  sampleEmails: Array<GQEmailContentsWithUserRecipient>;
};

export type GQPreviewFinancialsReviewEmailsInput = {
  measurementProjectId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQPreviewFinancialsReviewEmailsPayload = {
  __typename?: 'PreviewFinancialsReviewEmailsPayload';
  emails: Array<GQEmailContentsWithUserRecipient>;
};

export type GQPrivateDisclosure = {
  __typename?: 'PrivateDisclosure';
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceId: Maybe<Scalars['ID']['output']>;
  cdpVendorData: Maybe<GQCdpVendorData>;
  companyId: Maybe<Scalars['ID']['output']>;
  companyIngestionRecordUpdateId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  disclosureQualityExplanation: Maybe<Scalars['String']['output']>;
  disclosureQualityScore: Maybe<GQDisclosureQualityScore>;
  engagementTaskId: Maybe<Scalars['ID']['output']>;
  footprintDisclosureId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
  privateDisclosureType: GQPrivateDisclosureType;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  sourceId: Scalars['ID']['output'];
  surveyId: Maybe<Scalars['ID']['output']>;
  thirdPartyVerified: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userEditorId: Scalars['ID']['output'];
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export type GQPrivateDisclosureInput = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export const GQPrivateDisclosureType = {
  Cdp: 'CDP',
  Estimate: 'Estimate',
  ManualEntry: 'ManualEntry',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQPrivateDisclosureType =
  (typeof GQPrivateDisclosureType)[keyof typeof GQPrivateDisclosureType];
export type GQProcessCompanyIngestionDiffPayload = {
  __typename?: 'ProcessCompanyIngestionDiffPayload';
  companyIngestionDiff: GQCompanyIngestionDiff;
  companyIngestionRecordUpdates: Array<GQCompanyIngestionRecordUpdate>;
};

export type GQProcessCompanyIngestionUpdatePayload = {
  __typename?: 'ProcessCompanyIngestionUpdatePayload';
  company: Maybe<GQCompany>;
  companyIngestionRecordUpdate: GQCompanyIngestionRecordUpdate;
};

export type GQProcessMaterialVariantAndLcaFileInput = {
  fileMetadataId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQProcessMaterialVariantAndLcaFilePayload = {
  __typename?: 'ProcessMaterialVariantAndLCAFilePayload';
  success: Scalars['Boolean']['output'];
};

export type GQProcessProductionGraphFileInput = {
  fileMetadataId: Scalars['ID']['input'];
  lifecycleAssessmentId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQProcessProductionGraphFilePayload = {
  __typename?: 'ProcessProductionGraphFilePayload';
  id: Scalars['ID']['output'];
};

export const GQProductWaitlistType = {
  Ppa: 'Ppa',
  SupplyChain: 'SupplyChain',
  Vppa: 'Vppa',
} as const;

export type GQProductWaitlistType =
  (typeof GQProductWaitlistType)[keyof typeof GQProductWaitlistType];
export type GQProductionGraphData = {
  __typename?: 'ProductionGraphData';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastVersionId: Maybe<Scalars['ID']['output']>;
  lifecycleAssessmentId: Scalars['ID']['output'];
  nodes: Array<GQProductionGraphNode>;
};

export type GQProductionGraphDataInput = {
  lifecycleAssessmentId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQProductionGraphNode = {
  __typename?: 'ProductionGraphNode';
  airDistance: Maybe<Scalars['Float']['output']>;
  airDistanceUnit: Maybe<Scalars['String']['output']>;
  assumptions: Maybe<Scalars['String']['output']>;
  autobomTier: Maybe<Scalars['Float']['output']>;
  biogenicEmissionsKgco2e: Maybe<Scalars['Float']['output']>;
  conversionFactorCitation: Maybe<Scalars['String']['output']>;
  conversionFactorList: Maybe<Scalars['String']['output']>;
  conversionFactorUnit: Maybe<Scalars['String']['output']>;
  conversionFactorValue: Maybe<Scalars['Float']['output']>;
  countryCodes: Maybe<Scalars['String']['output']>;
  cumulativeBiogenicEmissionsKgco2e: Maybe<Scalars['Float']['output']>;
  cumulativeEmissionsKgco2e: Maybe<Scalars['Float']['output']>;
  cumulativeFlagEmissionsKgco2e: Maybe<Scalars['Float']['output']>;
  downstreamNodeIdentifier: Maybe<Scalars['String']['output']>;
  ecoinventActivitiesRaw: Maybe<Scalars['String']['output']>;
  ecoinventActivity: Maybe<Scalars['String']['output']>;
  ecoinventCpcClassification: Maybe<Scalars['String']['output']>;
  ecoinventEmissionsFactor: Maybe<Scalars['Float']['output']>;
  ecoinventGeography: Maybe<Scalars['String']['output']>;
  ecoinventHsCodeClassification: Maybe<Scalars['String']['output']>;
  ecoinventIsicClassification: Maybe<Scalars['String']['output']>;
  ecoinventReferenceProductName: Maybe<Scalars['String']['output']>;
  ecoinventUnit: Maybe<Scalars['String']['output']>;
  edits: Maybe<Scalars['String']['output']>;
  emissionsKgco2e: Maybe<Scalars['Float']['output']>;
  facilityAssumptions: Maybe<Scalars['String']['output']>;
  facilityCompany: Maybe<Scalars['String']['output']>;
  facilityDescription: Maybe<Scalars['String']['output']>;
  facilityLocation: Maybe<Scalars['String']['output']>;
  facilityName: Maybe<Scalars['String']['output']>;
  flagEmissionsKgco2e: Maybe<Scalars['Float']['output']>;
  identifier: Scalars['String']['output'];
  inputRates: Array<GQProductionGraphNodeInputRate>;
  lifecycleStage: GQLifecycleStage;
  mappedEcoinventActivity: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nodeType: GQProductionGraphNodeType;
  outputAmount: Scalars['Float']['output'];
  outputAmountUnit: Scalars['String']['output'];
  outputMaterialName: Maybe<Scalars['String']['output']>;
  railDistance: Maybe<Scalars['Float']['output']>;
  railDistanceUnit: Maybe<Scalars['String']['output']>;
  rawProcessInputsResponse: Maybe<Scalars['String']['output']>;
  roadDistance: Maybe<Scalars['Float']['output']>;
  roadDistanceUnit: Maybe<Scalars['String']['output']>;
  supplierName: Maybe<Scalars['String']['output']>;
  tags: Maybe<Array<Scalars['String']['output']>>;
  unitConversionResponse: Maybe<Scalars['String']['output']>;
  waterDistance: Maybe<Scalars['Float']['output']>;
  waterDistanceUnit: Maybe<Scalars['String']['output']>;
};

export const GQProductionGraphNodeEditType = {
  AddNode: 'AddNode',
  DeleteNode: 'DeleteNode',
  EditNode: 'EditNode',
} as const;

export type GQProductionGraphNodeEditType =
  (typeof GQProductionGraphNodeEditType)[keyof typeof GQProductionGraphNodeEditType];
export type GQProductionGraphNodeInputRate = {
  __typename?: 'ProductionGraphNodeInputRate';
  childNodeIdentifier: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
  unitChildPerUnit: Scalars['String']['output'];
};

export const GQProductionGraphNodeType = {
  Material: 'material',
  ProcessInput: 'process_input',
  Processing: 'processing',
  Transportation: 'transportation',
} as const;

export type GQProductionGraphNodeType =
  (typeof GQProductionGraphNodeType)[keyof typeof GQProductionGraphNodeType];
export type GQProductionGraphProcess = {
  __typename?: 'ProductionGraphProcess';
  geography: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  processName: Scalars['String']['output'];
  referenceProductDescription: Maybe<Scalars['String']['output']>;
  referenceProductName: Maybe<Scalars['String']['output']>;
};

export type GQProgramSubstatusData = {
  __typename?: 'ProgramSubstatusData';
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  statusLabel: Scalars['String']['output'];
};

export type GQPruneOrphanedQuestionsForConfigPayload = {
  __typename?: 'PruneOrphanedQuestionsForConfigPayload';
  deleteCount: Scalars['Float']['output'];
};

export type GQPublicDisclosure = {
  __typename?: 'PublicDisclosure';
  cdpVendorData: Maybe<GQCdpVendorData>;
  company: Maybe<GQCompany>;
  companyId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  externalScore: Maybe<Scalars['String']['output']>;
  gsUrl: Maybe<Scalars['String']['output']>;
  historicalEmissionsYearsDetails: Array<GQEmissionsYear>;
  id: Scalars['ID']['output'];
  publicUrl: Scalars['String']['output'];
  publishingYear: Scalars['Int']['output'];
  qualityScore: Maybe<GQDisclosureQualityScore>;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  reportType: GQExternalReportType;
  reportingYears: Array<Scalars['Int']['output']>;
  sectionUrls: Maybe<Array<GQExternalReportSectionUrl>>;
  source: GQPublicDisclosureSource;
  sourceRecordId: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQPublicDisclosureConnection = {
  __typename?: 'PublicDisclosureConnection';
  edges: Array<GQPublicDisclosureEdge>;
  pageInfo: GQPageInfo;
};

export type GQPublicDisclosureEdge = {
  __typename?: 'PublicDisclosureEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPublicDisclosure>;
};

export type GQPublicDisclosureInput = {
  excludeCdp?: InputMaybe<Scalars['Boolean']['input']>;
  mustHaveUrl?: InputMaybe<Scalars['Boolean']['input']>;
};

export const GQPublicDisclosureSource = {
  Licensed: 'Licensed',
  Scraped: 'Scraped',
} as const;

export type GQPublicDisclosureSource =
  (typeof GQPublicDisclosureSource)[keyof typeof GQPublicDisclosureSource];
export type GQPublishCtsVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQPublishCtsVersionPayload = {
  __typename?: 'PublishCtsVersionPayload';
  ctsVersion: GQCustomerTargetSchemaVersion;
  customerTargetSchema: GQCustomerTargetSchema;
};

export type GQPublishDataIssuesInput = {
  commentIds: Array<Scalars['ID']['input']>;
  emails: Array<GQEmailContentsInput>;
  issueIds: Array<Scalars['ID']['input']>;
};

export type GQPublishDataIssuesPayload = {
  __typename?: 'PublishDataIssuesPayload';
  comments: Array<GQComment>;
  issues: Array<GQDataIssue>;
};

export type GQPublishEngagementTaskConfigForAdminInput = {
  id: Scalars['ID']['input'];
  isPublished: Scalars['Boolean']['input'];
};

export type GQPublishEngagementTaskConfigForAdminPayload = {
  __typename?: 'PublishEngagementTaskConfigForAdminPayload';
  engagementTaskConfig: GQEngagementTaskConfig;
};

export type GQPublishExtractorInput = {
  activityDataExtractorId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  /** @deprecated unused, remove may22 */
  publishExplanationJson?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated unused, remove 2024-11-15 */
  userVisible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQPublishExtractorPayload = {
  __typename?: 'PublishExtractorPayload';
  jobId: Scalars['ID']['output'];
};

export type GQPublishFinancialsReviewItemsInput = {
  emailContents: Array<GQEmailContentsInput>;
  orgId: Scalars['ID']['input'];
};

export type GQPublishFinancialsReviewItemsPayload = {
  __typename?: 'PublishFinancialsReviewItemsPayload';
  financialsReviewItems: GQFinancialsReviewItemConnection;
};

export type GQPublishReferenceDataVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQPublishReferenceDataVersionPayload = {
  __typename?: 'PublishReferenceDataVersionPayload';
  referenceDataVersion: GQReferenceDataVersion;
};

export type GQPushCtsVersionToOneSchemaInput = {
  ctsVersionId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQPushCtsVersionToOneSchemaPayload = {
  __typename?: 'PushCtsVersionToOneSchemaPayload';
  fileMetadata: GQFileMetadata;
  oneSchemaTemplateKey: Scalars['String']['output'];
  oneSchemaTemplateUrl: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type GQPushStagingReportConfigToProdInput = {
  isTesting: Scalars['Boolean']['input'];
  prodConfigId: Scalars['ID']['input'];
  stagingConfigId: Scalars['ID']['input'];
};

export type GQPushStagingReportConfigToProdPayload = {
  __typename?: 'PushStagingReportConfigToProdPayload';
  prodConfig: GQReportConfig;
};

export type GQQuantityTrendAnomaly = {
  __typename?: 'QuantityTrendAnomaly';
  highlightMonth: Scalars['YearMonth']['output'];
};

export type GQQuery = {
  __typename?: 'Query';
  activeWatershedEmployee: Maybe<GQWatershedEmployee>;
  activityDataExtractor: Maybe<GQActivityDataExtractor>;
  activityDataExtractors: GQActivityDataExtractorConnection;
  activityDataExtractorsById: Array<GQActivityDataExtractor>;
  activityDataTable: Maybe<GQActivityDataTable>;
  activityDataTables: GQActivityDataTableConnection;
  activityTypeSchemas: Array<GQActivityTypeSchema>;
  adminLineageGraph: GQGetAdminLineageGraphPayload;
  allIntegrationPartners: Array<GQIntegrationPartner>;
  allReportConfigs: Array<GQReportConfig>;
  applicableDatasources: Array<GQDatasource>;
  assetCorporateAdmin: GQAssetCorporate;
  assetsWithTasks: Array<GQAssetCorporate>;
  availableProjectsForCompany: Array<GQMeasurementProject>;
  backgroundJob: Maybe<GQBackgroundJob>;
  backgroundJobStatus: Maybe<GQBackgroundJobStatus>;
  backgroundJobStatuses: Array<GQBackgroundJobStatus>;
  batsByCalculationTagNames: Array<GQBatsByCalculationTagNamesPayload>;
  businessActivityTypeById: GQBusinessActivityType;
  businessActivityTypeByIds: Array<GQBusinessActivityType>;
  businessActivityTypeByNameAndVersionId: GQBusinessActivityType;
  businessActivityTypeNames: Array<Scalars['String']['output']>;
  businessActivityTypeSettings: Maybe<GQBusinessActivityTypeSettingsConnection>;
  businessActivityTypeVersionById: GQBusinessActivityTypeVersion;
  businessActivityTypeVersionByName: GQBusinessActivityTypeVersion;
  businessActivityTypeVersionLive: GQBusinessActivityTypeVersion;
  businessActivityTypeVersionPrevious: GQBusinessActivityTypeVersion;
  businessActivityTypeVersions: Array<GQBusinessActivityTypeVersion>;
  businessActivityTypeWithFields: Maybe<GQBusinessActivityType>;
  businessActivityTypesForVersion: Array<GQBusinessActivityType>;
  businessActivityTypesPrimaryForVersionWithFields: Array<GQBusinessActivityType>;
  businessCategories: GQBusinessCategoryCollection;
  cadtRowsToMapForOrg: GQCadtRowsToMapForOrgOutput;
  calculationTag: GQCalculationTag;
  calculationTags: Array<GQCalculationTag>;
  calculationTagsWithUsage: Array<GQCalculationTagWithUsage>;
  canAccessOrganization: Scalars['Boolean']['output'];
  canonicalClimateProgramProject: GQCanonicalClimateProgramProject;
  canonicalClimateProgramProjects: Array<GQCanonicalClimateProgramProject>;
  canonicalDataset: Maybe<GQCanonicalDataset>;
  canonicalDatasets: Array<GQCanonicalDataset>;
  cdpApiRegistrations: Array<GQCdpApiRegistration>;
  cdpIdMappings: GQCdpIdMappingsPayload;
  cdpMissingIdMappings: GQCdpMissingIdMappingsPayload;
  cdpOrganizations: Maybe<GQCdpOrganizationPayload>;
  cleanPowerQueryPlaygroundLink: Maybe<Scalars['String']['output']>;
  committedChangesets: Array<GQChangeset>;
  companies: GQCompanyConnection;
  company: Maybe<GQCompany>;
  companyForDashboard: Maybe<GQCompany>;
  companyFuzzyMatchWithEfs: GQCompanySearchResult;
  companyIngestionDiffErrors: Array<GQCompanyIngestionDiffError>;
  companyIngestionRecordUpdates: GQCompanyIngestionRecordUpdateRequestConnection;
  companySourceKeys: Array<GQCompanySourceKey>;
  companyUrls: Array<GQCompanyUrl>;
  compositeDataVersion: GQReferenceDataVersion;
  customerTargetSchema: GQCustomerTargetSchema;
  customerTargetSchemaTransform: GQCustomerTargetSchemaTransform;
  customerTargetSchemas: Array<GQCustomerTargetSchema>;
  dataRegistryBatchMigratorRun: Maybe<GQDataRegistryBatchMigratorRun>;
  dataRegistryBatchMigratorRuns: Maybe<GQDataRegistryBatchMigratorRunConnection>;
  dataRegistryMigrator: Maybe<GQDataRegistryMigrator>;
  dataRegistryMigratorBestPath: Array<GQDataRegistryMigratorAndSchema>;
  dataRegistryMigratorRuns: Maybe<GQDataRegistryMigratorRunConnection>;
  dataRegistryMigrators: Maybe<GQDataRegistryMigratorConnection>;
  dataset: Maybe<GQDataset>;
  datasource: Maybe<GQDatasource>;
  defaultCalculationTags: Array<GQCalculationTag>;
  defaultMappingsUrl: Scalars['String']['output'];
  dependentEmissionsModelReleasesForRdv: Maybe<Array<GQEmissionsModelRelease>>;
  dependentEmissionsModelsForRdv: Maybe<Array<GQEmissionsModelForRdv>>;
  dependentReferenceDataVersionsForRdv: Maybe<
    Array<GQReferenceDataVersionForRdv>
  >;
  disclosure: Maybe<GQDisclosure>;
  duckHuntLeaderboard: Array<GQDuckHuntLeaderboardEntry>;
  ducksForHunter: Array<Scalars['String']['output']>;
  dumpReportConfigToSrc: GQReportConfigSrcPayload;
  eacPriceEstimates: Array<GQEacPriceEstimate>;
  emailsSent: GQEmailSentConnection;
  emissionsModelEditorEval: GQEmissionsModelEditorEvalPayload;
  emissionsModelEvaluation: GQEmissionsModelEvaluation;
  emissionsModelForkRelationships: GQEmissionsModelForkRelationshipsPayload;
  emissionsModelLibraryForStableModel: Array<GQEmissionsModelStable>;
  emissionsModelMatchersForStableId: Array<GQEmissionsModelMatcher>;
  emissionsModelRelease: GQEmissionsModelRelease;
  emissionsModelReleases: Array<GQEmissionsModelRelease>;
  emissionsModelReleasesForEmissionsModelStable: Array<GQEmissionsModelRelease>;
  emissionsModelReleasesForEmissionsModelsStable: Array<GQEmissionsModelReleaseEmissionsModelsStable>;
  emissionsModelReleasesForMethodologyExplorer: Array<GQEmissionsModelRelease>;
  emissionsModelStable: GQEmissionsModelStable;
  emissionsModelStableVersionHistory: GQEmissionsModelVersionHistoryConnection;
  emissionsModelVersion: GQEmissionsModelVersion;
  emissionsModelVersionImportedDescendantsFromDirectImportIds: Array<GQEmissionsModelVersion>;
  emissionsModels: Array<GQEmissionsModelVersion>;
  emissionsModelsForOrg: Array<GQEmissionsModelStable>;
  emissionsModelsStable: GQEmissionsModelStableConnection;
  emissionsModelsStableByIds: Array<GQEmissionsModelStable>;
  emissionsModelsStableForBusinessActivityType: Array<GQEmissionsModelStable>;
  emissionsModelsStablesForReleases: Array<GQEmissionsModelStable>;
  emissionsModelsSupportedBats: Array<Scalars['String']['output']>;
  engagementTaskConfig: GQEngagementTaskConfig;
  engagementTaskConfigs: Array<GQEngagementTaskConfig>;
  engagementTasksAdmin: GQEngagementTaskConnection;
  externalReportAnswersForQuestion: GQExternalReportAnswersConnection;
  externalReportAnswersForReport: Array<GQExternalReportAnswer>;
  externalReportQuestions: Array<GQExternalReportQuestion>;
  externalReportTypesWithRevision: Array<GQExternalReportTypeWithRevision>;
  extractorRun: Maybe<GQExtractorRun>;
  featureFlagDisabledForOrg: Scalars['Boolean']['output'];
  featureFlagEnabledForOrg: Scalars['Boolean']['output'];
  featureFlagEnabledGlobally: Scalars['Boolean']['output'];
  featureFlags: GQFeatureFlagConnection;
  files: GQFileMetadataConnection;
  financeMeasurementProgressRowsAdmin: Array<GQFinanceMeasurementProgressRow>;
  financialsReviewItemsAdmin: GQFinancialsReviewItemConnection;
  findReferenceDataSource: GQReferenceDataSource;
  footprintDebug: GQFootprintDebugPayload;
  footprintSnapshot: GQFootprintSnapshot;
  footprintSnapshots: GQFootprintSnapshotConnection;
  footprintTag: Maybe<GQFootprintTag>;
  footprintTagsForOrg: Array<GQFootprintTag>;
  footprintTestSuiteCategoryRules: Maybe<Array<GQFootprintCategoryRule>>;
  footprintTestSuiteExclusionRules: Maybe<Array<GQFootprintExclusionRule>>;
  footprintTestSuiteExecutionStepResult: GQFootprintTestExecutionStepResult;
  footprintTestSuiteFootprintTags: Maybe<Array<GQFootprintTag>>;
  footprintTestSuiteMarketplaceAllocInstrs: Maybe<
    Array<GQMarketplaceAllocationInstruction>
  >;
  footprintsForOrg: GQFootprintConnection;
  forecastAdmin: Maybe<GQForecast>;
  formalReport: Maybe<GQReport>;
  formalReportsAdmin: GQReportConnection;
  getReferenceDataFromRawSlugs: Array<GQReferenceDataSource>;
  getReportObjectConfigPath: Maybe<GQGetReportObjectConfigPathPayload>;
  globalEngagementTaskConfigs: Array<GQEngagementTaskConfig>;
  globalFootprintTags: Array<GQGlobalFootprintTag>;
  globalMappingsUrl: Scalars['String']['output'];
  inboxItemsForOrg: GQOrganizationInboxItemConnection;
  integrationConnection: GQIntegrationConnection;
  integrationConnections: GQIntegrationConnectionConnection;
  integrationDataPulls: Array<Maybe<GQIntegrationDataPull>>;
  latestBusinessActivityTypeInstanceForName: GQBusinessActivityType;
  latestBusinessActivityTypeInstances: Array<GQBusinessActivityType>;
  latestDocumentParseAttempt: GQDocumentParseAttempt;
  latestEmissionsModelVersionForStableId: Maybe<GQEmissionsModelVersion>;
  latestPublishedRevisionForRevisionIdOrNull: Maybe<GQReferenceDataRevision>;
  latestReferenceDataRevisionForVersion: Maybe<GQReferenceDataRevision>;
  latestReferenceDataRevisionsForOrg: Array<Maybe<GQReferenceDataRevision>>;
  legacyAssumptionAsMeasurementMappings: GQLegacyAssumptionAsMeasurementMappingConnection;
  legacyMappingsCodesUrl: Scalars['String']['output'];
  lifecycleAssessments: Array<GQLifecycleAssessment>;
  mappedExternalReportQuestions: GQMappedExternalReportQuestions;
  mappingCategoryIds: GQMappingCategoryIds;
  marketplaceAllocationInstructions: GQMarketplaceAllocationInstructionConnection;
  marketplaceDeveloper: GQMarketplaceDeveloper;
  marketplaceDevelopers: GQMarketplaceDeveloperConnection;
  marketplaceDocument: GQMarketplaceDocument;
  marketplaceDocuments: GQMarketplaceDocumentConnection;
  marketplaceInternationalEacProjects: Array<GQMarketplaceProject>;
  marketplacePointsOfContact: GQMarketplacePointOfContactConnection;
  marketplaceProject: GQMarketplaceProject;
  marketplaceProjectArchetype: GQMarketplaceProjectArchetype;
  marketplaceProjectArchetypes: GQMarketplaceProjectArchetypeConnection;
  marketplaceProjectOffering: GQMarketplaceProjectOffering;
  marketplaceProjectOfferingUpdates: GQMarketplaceProjectOfferingConnection;
  marketplaceProjectOfferings: GQMarketplaceProjectOfferingConnection;
  marketplaceProjects: GQMarketplaceProjectConnection;
  marketplacePurchase: GQMarketplacePurchase;
  marketplacePurchaseLineItem: GQMarketplacePurchaseLineItem;
  marketplacePurchaseLineItems: GQMarketplacePurchaseLineItemConnection;
  marketplacePurchases: GQMarketplacePurchaseConnection;
  marketplaceSupplier: GQMarketplaceSupplier;
  marketplaceSuppliers: GQMarketplaceSupplierConnection;
  materialVariants: Array<GQMaterialVariant>;
  measurementMappingOutputOptions: GQMeasurementMappingOutputOptionConnection;
  measurementMappings: GQMeasurementMappingConnection;
  measurementProject: GQMeasurementProject;
  measurementProjectStatusShares: Array<GQMeasurementProjectStatusShare>;
  measurementTestSuite: GQMeasurementTestSuite;
  measurementTestSuiteChangelog: GQMeasurementTestSuiteChangelogConnection;
  measurementTestSuites: Array<GQMeasurementTestSuite>;
  methodologyTestSuiteExecution: GQMethodologyTestSuiteExecution;
  methodologyTestSuiteValidationResults: Maybe<GQMethodologyTestSuiteValidationResults>;
  naicsEFs: Array<GQNaicsEf>;
  object: GQGenericObjectPayload;
  orderedBusinessActivityTypeInstancesForName: Array<GQBusinessActivityType>;
  orgFootprintDisclosureShareLogs: GQFootprintDisclosureShareLogConnection;
  orgFootprintDisclosures: GQFootprintDisclosureConnection;
  orgFunds: GQFundConnection;
  organization: GQOrganization;
  organizations: GQOrgConnection;
  pipelineResult: Maybe<GQPipelineResult>;
  pipelineResults: GQPipelineResultConnection;
  pipelineRuns: GQPipelineRunConnection;
  plan: Maybe<GQPlan>;
  plansForOrg: Array<GQPlan>;
  playgroundSql: Maybe<GQPlaygroundSql>;
  playgroundSqlRun: Maybe<GQPlaygroundSqlRun>;
  playgroundSqlRuns: GQPlaygroundSqlRunConnection;
  portcoOrgs: Array<GQOrganization>;
  portcos: Array<GQAssetCorporate>;
  preflightEraseReportComponent: Maybe<GQPreflightEraseReportComponentPayload>;
  prepublicationMeasurementTestResults: GQPrepublicationTestResults;
  previewCadtBuildingsPush: Maybe<GQCadtBuildingsPushPreview>;
  privateDisclosureIDs: Maybe<Array<Scalars['ID']['output']>>;
  productionGraphDataForLifecycleAssessment: Maybe<GQProductionGraphData>;
  publicDisclosureIDs: Maybe<Array<Scalars['ID']['output']>>;
  publicDisclosuresAdmin: GQPublicDisclosureConnection;
  rawCustomerTargetSchemaVersion: GQCustomerTargetSchemaVersion;
  referenceDataAncestryForVersionIds: Array<GQReferenceDataAncestorRelationship>;
  referenceDataCitation: GQReferenceDataCitation;
  referenceDataCitationSlugs: Array<Scalars['String']['output']>;
  referenceDataCitations: GQReferenceDataCitationConnection;
  referenceDataDiffer: GQReferenceDataDifferResult;
  referenceDataOverlay: GQReferenceDataOverlay;
  referenceDataOverlayByRevisionId: Maybe<GQReferenceDataOverlay>;
  referenceDataRevision: GQReferenceDataRevision;
  referenceDataRevisionsByVersionId: GQReferenceDataRevisionConnection;
  referenceDataSource: GQReferenceDataSource;
  referenceDataSourceByName: GQReferenceDataSource;
  referenceDataSources: Maybe<GQReferenceDataSourceConnection>;
  referenceDataSqlForRevisionId: Maybe<GQReferenceDataSql>;
  referenceDataVersion: GQReferenceDataVersion;
  referenceDataVersionBySlug: GQReferenceDataVersion;
  referenceDataVersionsBySlugs: Array<Maybe<GQReferenceDataVersionSlugOutput>>;
  referenceDataVersionsBySlugsIfExists: Array<
    Maybe<GQReferenceDataVersionSlugOutput>
  >;
  refreshableAssets: Array<GQAssetCorporate>;
  region: GQRegion;
  reportAnswerVerifierFailures: GQReportAnswerVerifierFailureConnection;
  reportAnswerVerifierOptions: GQReportAnswerVerifierOptions;
  reportAnswers: GQReportAnswerConnection;
  reportConfig: GQReportConfig;
  reportConfigFrameworkMapping: Maybe<GQReportConfigFrameworkMapping>;
  reportConfigQuestions: Array<GQReportQuestion>;
  reportConfigs: GQReportConfigConnection;
  reportQuestion: GQReportQuestion;
  reportQuestionContainer: GQReportQuestionContainer;
  reportQuestionIdentifierMapping: GQReportQuestionIdentifierMapping;
  reportQuestionIdentifiers: GQReportQuestionIdentifierConnection;
  reportingFootprintHealthChecks: Maybe<GQReportingFootprintHealthChecksPayload>;
  reports: GQReportConnection;
  role: Maybe<GQRole>;
  schemaRegistrySchema: Maybe<GQSchemaRegistrySchema>;
  schemaRegistrySchemaVersion: Maybe<GQSchemaRegistrySchemaVersion>;
  schemaRegistrySchemaVersions: Maybe<GQSchemaRegistrySchemaVersionConnection>;
  schemaRegistrySchemas: Maybe<GQSchemaRegistrySchemaConnection>;
  searchReferenceDataSourceName: Array<GQReferenceDataSource>;
  shouldShowDuck: Scalars['Boolean']['output'];
  signOneSchemaJwt: GQOneSchemaJwtPayload;
  signedParquetUrl: Scalars['String']['output'];
  supplierTableColumns: Array<GQSupplierTableColumn>;
  supplierViewsAdmin: Array<GQSupplierView>;
  suppliersAdmin: Maybe<GQOrgAdminSuppliers>;
  suppliersSettingsAdmin: GQSuppliersSettings;
  tcfdArchetypeOpportunities: Array<GQTcfdArchetypeOpportunity>;
  tcfdArchetypeRisks: Array<GQTcfdArchetypeRisk>;
  throwErrorQuery: Maybe<GQThrowErrorPayload>;
  uploadsForActivityType: GQUploadConnection;
  user: Maybe<GQUser>;
  userUpload: GQUserUpload;
  userUploadTask: Maybe<GQUserUploadTask>;
  userUploadTasksForOrg: Array<GQUserUploadTask>;
  userUploadedTable: Maybe<GQUserUploadedTable>;
  userUploadedTables: GQUserUploadedTableConnection;
  userUploadedTablesById: Array<GQUserUploadedTable>;
  userUploads: GQUserUploadConnection;
  users: Array<GQUser>;
  validateUpdateReferenceDataVersionSchema: GQReferenceDataSchemaUpdateValidation;
  vendorMatchingTasks: Array<GQVendorMatchingTask>;
  watershedEmployees: GQWatershedEmployeeConnection;
};

export type GQQueryActivityDataExtractorArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryActivityDataExtractorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  ids: Array<Scalars['ID']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryActivityDataExtractorsByIdArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryActivityDataTableArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryActivityDataTablesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryAdminLineageGraphArgs = {
  input: GQGetAdminLineageGraphInput;
};

export type GQQueryAllReportConfigsArgs = {
  filterHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryApplicableDatasourcesArgs = {
  integrationPartnerId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQQueryAssetCorporateAdminArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryAssetsWithTasksArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryAvailableProjectsForCompanyArgs = {
  companyId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryBackgroundJobArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBackgroundJobStatusArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBackgroundJobStatusesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryBatsByCalculationTagNamesArgs = {
  calculationTagNames: Array<Scalars['String']['input']>;
};

export type GQQueryBusinessActivityTypeByIdArgs = {
  batId: Scalars['ID']['input'];
};

export type GQQueryBusinessActivityTypeByIdsArgs = {
  batIds: Array<Scalars['ID']['input']>;
};

export type GQQueryBusinessActivityTypeByNameAndVersionIdArgs = {
  name: Scalars['String']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQQueryBusinessActivityTypeSettingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryBusinessActivityTypeVersionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBusinessActivityTypeVersionByNameArgs = {
  versionName: Scalars['String']['input'];
};

export type GQQueryBusinessActivityTypeWithFieldsArgs = {
  typeName: Scalars['String']['input'];
  versionName: Scalars['String']['input'];
};

export type GQQueryBusinessActivityTypesForVersionArgs = {
  versionName: Scalars['String']['input'];
};

export type GQQueryBusinessActivityTypesPrimaryForVersionWithFieldsArgs = {
  versionName: Scalars['String']['input'];
};

export type GQQueryCadtRowsToMapForOrgArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  endYearMonth: InputMaybe<Scalars['YearMonth']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
  startYearMonth: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQQueryCalculationTagArgs = {
  tagId: Scalars['ID']['input'];
};

export type GQQueryCanAccessOrganizationArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryCanonicalClimateProgramProjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCanonicalDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCdpMissingIdMappingsArgs = {
  orgId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQQueryCleanPowerQueryPlaygroundLinkArgs = {
  orgId: Scalars['ID']['input'];
  pipelineRunConfig: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryCommittedChangesetsArgs = {
  committedAfter: Scalars['DateTime']['input'];
  firstChangesetRows: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryCompaniesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDemo: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCompanyForDashboardArgs = {
  disableBetterCompanyUpgrade: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryCompanyFuzzyMatchWithEfsArgs = {
  allowMatchById: InputMaybe<Scalars['Boolean']['input']>;
  filterHiddenCompanies: InputMaybe<Scalars['Boolean']['input']>;
  q: Scalars['String']['input'];
  searchDemo: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryCompanyIngestionRecordUpdatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQCompanyIngestionRecordUpdateFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  sortDirection: InputMaybe<GQDataGridSortDirection>;
};

export type GQQueryCompanyUrlsArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQueryCompositeDataVersionArgs = {
  compositeDataVersionId: Scalars['ID']['input'];
};

export type GQQueryCustomerTargetSchemaArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCustomerTargetSchemaTransformArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryDataRegistryBatchMigratorRunArgs = {
  batchMigratorRunId: Scalars['ID']['input'];
};

export type GQQueryDataRegistryBatchMigratorRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  schemaId: InputMaybe<Scalars['ID']['input']>;
  searchDescription: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryDataRegistryMigratorArgs = {
  migratorId: Scalars['ID']['input'];
};

export type GQQueryDataRegistryMigratorBestPathArgs = {
  fromSchemaVersionId: Scalars['ID']['input'];
  toSchemaVersionId: Scalars['ID']['input'];
};

export type GQQueryDataRegistryMigratorRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  batchMigratorRunId: InputMaybe<Scalars['ID']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQDataRegistryMigratorRunFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  searchText: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryDataRegistryMigratorsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  schemaId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryDatasourceArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryDependentEmissionsModelReleasesForRdvArgs = {
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryDependentEmissionsModelsForRdvArgs = {
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryDependentReferenceDataVersionsForRdvArgs = {
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryDisclosureArgs = {
  opts: InputMaybe<GQDisclosureInput>;
  privateDisclosureId: InputMaybe<Scalars['ID']['input']>;
  publicDisclosureId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryDumpReportConfigToSrcArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmailsSentArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelEditorEvalArgs = {
  input: GQEmissionsModelEditorEvalInput;
};

export type GQQueryEmissionsModelEvaluationArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelForkRelationshipsArgs = {
  stableId: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelLibraryForStableModelArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelMatchersForStableIdArgs = {
  id: Scalars['ID']['input'];
  includeDrafts: Scalars['Boolean']['input'];
};

export type GQQueryEmissionsModelReleaseArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelReleasesArgs = {
  businessActivityTypeNames: InputMaybe<Array<Scalars['String']['input']>>;
  includeAllOrgs: InputMaybe<Scalars['Boolean']['input']>;
  includeAlphas: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelReleasesForEmissionsModelStableArgs = {
  stableId: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelReleasesForEmissionsModelsStableArgs = {
  stableIds: Array<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelStableArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelStableVersionHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryEmissionsModelVersionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelVersionImportedDescendantsFromDirectImportIdsArgs =
  {
    ids: Array<Scalars['ID']['input']>;
  };

export type GQQueryEmissionsModelsArgs = {
  emissionsModelStableId: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelsForOrgArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelsStableArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  emissionsModelListTab: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  showArchived: Scalars['Boolean']['input'];
};

export type GQQueryEmissionsModelsStableByIdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelsStableForBusinessActivityTypeArgs = {
  businessActvityTypeName: Scalars['String']['input'];
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelsStablesForReleasesArgs = {
  excludeOrgSpecificModels: InputMaybe<Scalars['Boolean']['input']>;
  releaseIds: Array<Scalars['ID']['input']>;
};

export type GQQueryEmissionsModelsSupportedBatsArgs = {
  calculationTags: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQQueryEngagementTaskConfigArgs = {
  engagementTaskConfigId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskConfigsArgs = {
  configGroup: InputMaybe<GQEngagementTaskConfigGroup>;
  includeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeGlobalConfigs: InputMaybe<Scalars['Boolean']['input']>;
  includeUnpublishedGlobal: InputMaybe<Scalars['Boolean']['input']>;
  includeUnpublishedOrgSpecific: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryEngagementTasksAdminArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryExternalReportAnswersForQuestionArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyIds: InputMaybe<Array<Scalars['ID']['input']>>;
  externalReportQuestionId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryExternalReportAnswersForReportArgs = {
  publicDisclosureId: Scalars['ID']['input'];
};

export type GQQueryExternalReportQuestionsArgs = {
  externalReportTypeId: Scalars['ID']['input'];
};

export type GQQueryExtractorRunArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFeatureFlagDisabledForOrgArgs = {
  flag: GQFlags;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFeatureFlagEnabledForOrgArgs = {
  flag: GQFlags;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFeatureFlagEnabledGloballyArgs = {
  flag: GQFlags;
};

export type GQQueryFeatureFlagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQFileMetadataFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFinanceMeasurementProgressRowsAdminArgs = {
  orgId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryFinancialsReviewItemsAdminArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFindReferenceDataSourceArgs = {
  query: Scalars['String']['input'];
};

export type GQQueryFootprintDebugArgs = {
  fpsId: Scalars['ID']['input'];
};

export type GQQueryFootprintSnapshotArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFootprintSnapshotsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  footprintSnapshotIds: InputMaybe<Array<Scalars['ID']['input']>>;
  isAutoGenerated: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryFootprintTagArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFootprintTagsForOrgArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryFootprintTestSuiteCategoryRulesArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryFootprintTestSuiteExclusionRulesArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryFootprintTestSuiteExecutionStepResultArgs = {
  footprintAssemblyStep: GQFootprintAssemblyStep;
  footprintTestSuiteExecutionId: Scalars['ID']['input'];
};

export type GQQueryFootprintTestSuiteFootprintTagsArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryFootprintTestSuiteMarketplaceAllocInstrsArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryFootprintsForOrgArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryForecastAdminArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryFormalReportArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFormalReportsAdminArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryGetReferenceDataFromRawSlugsArgs = {
  slugs: Array<Scalars['String']['input']>;
};

export type GQQueryGetReportObjectConfigPathArgs = {
  input: GQGetReportObjectConfigPathInput;
};

export type GQQueryGlobalFootprintTagsArgs = {
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
  latestOfEachTag: InputMaybe<Scalars['Boolean']['input']>;
  tagName: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryInboxItemsForOrgArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryIntegrationConnectionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryIntegrationConnectionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryIntegrationDataPullsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryLatestBusinessActivityTypeInstanceForNameArgs = {
  name: Scalars['String']['input'];
};

export type GQQueryLatestDocumentParseAttemptArgs = {
  fileId: Scalars['ID']['input'];
  modelType: GQDocumentParseAttemptModelType;
};

export type GQQueryLatestEmissionsModelVersionForStableIdArgs = {
  id: Scalars['ID']['input'];
  includeDrafts: Scalars['Boolean']['input'];
};

export type GQQueryLatestPublishedRevisionForRevisionIdOrNullArgs = {
  revisionId: Scalars['ID']['input'];
};

export type GQQueryLatestReferenceDataRevisionForVersionArgs = {
  anyOrg: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryLatestReferenceDataRevisionsForOrgArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryLegacyAssumptionAsMeasurementMappingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryLifecycleAssessmentsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryMappedExternalReportQuestionsArgs = {
  questionId: Scalars['ID']['input'];
};

export type GQQueryMarketplaceAllocationInstructionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryMarketplaceDeveloperArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceDevelopersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceDocumentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplacePointsOfContactArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceProjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceProjectArchetypeArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceProjectArchetypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceProjectOfferingArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceProjectOfferingUpdatesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  baseOfferingId: Scalars['ID']['input'];
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceProjectOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  archetypeId: InputMaybe<Scalars['ID']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceProjectsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  supplierId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryMarketplacePurchaseArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplacePurchaseLineItemArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplacePurchaseLineItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplacePurchasesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplaceSupplierArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceSuppliersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMaterialVariantsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryMeasurementMappingOutputOptionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMeasurementMappingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryMeasurementProjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMeasurementTestSuiteArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMeasurementTestSuiteChangelogArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMeasurementTestSuitesArgs = {
  criticalOnly: Scalars['Boolean']['input'];
  includeDeleted: Scalars['Boolean']['input'];
};

export type GQQueryMethodologyTestSuiteExecutionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMethodologyTestSuiteValidationResultsArgs = {
  measurementTestSuiteId: Scalars['ID']['input'];
};

export type GQQueryObjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryOrderedBusinessActivityTypeInstancesForNameArgs = {
  name: Scalars['String']['input'];
};

export type GQQueryOrgFootprintDisclosureShareLogsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryOrgFootprintDisclosuresArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryOrgFundsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryOrganizationArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryOrganizationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  demoTestOnly: InputMaybe<Scalars['Boolean']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  ids: InputMaybe<Array<Scalars['ID']['input']>>;
  last: InputMaybe<Scalars['Int']['input']>;
  onlyOrgsWithAccess: InputMaybe<Scalars['Boolean']['input']>;
  region: InputMaybe<Scalars['String']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryPipelineResultArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPipelineResultsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
  pipelineResultIds: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQQueryPipelineRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryPlanArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPlansForOrgArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPlaygroundSqlArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPlaygroundSqlRunArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPlaygroundSqlRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
  userId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryPortcoOrgsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryPortcosArgs = {
  includeCompany: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryPreflightEraseReportComponentArgs = {
  input: GQEraseReportComponentInput;
};

export type GQQueryPrepublicationMeasurementTestResultsArgs = {
  workflowId: Scalars['ID']['input'];
};

export type GQQueryPreviewCadtBuildingsPushArgs = {
  activityDataExtractorId: Scalars['ID']['input'];
};

export type GQQueryPrivateDisclosureIDsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryProductionGraphDataForLifecycleAssessmentArgs = {
  input: GQProductionGraphDataInput;
};

export type GQQueryPublicDisclosuresAdminArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryRawCustomerTargetSchemaVersionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReferenceDataAncestryForVersionIdsArgs = {
  versionIds: Array<Scalars['ID']['input']>;
};

export type GQQueryReferenceDataCitationArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReferenceDataCitationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeSlugWithName: InputMaybe<Scalars['String']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  searchString: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryReferenceDataDifferArgs = {
  sourceRevisionId: Scalars['ID']['input'];
  targetRevisionId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataOverlayArgs = {
  overlayId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataOverlayByRevisionIdArgs = {
  referenceDataRevision: Scalars['ID']['input'];
};

export type GQQueryReferenceDataRevisionArgs = {
  revisionId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataRevisionsByVersionIdArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataSourceArgs = {
  referenceDataSourceId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataSourceByNameArgs = {
  name: Scalars['String']['input'];
};

export type GQQueryReferenceDataSourcesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  filter: GQReferenceDataSourceKindFilter;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  tagIdsFilter: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQQueryReferenceDataSqlForRevisionIdArgs = {
  revisionId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataVersionArgs = {
  referenceDataVersionId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataVersionBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type GQQueryReferenceDataVersionsBySlugsArgs = {
  slugs: Array<Scalars['String']['input']>;
};

export type GQQueryReferenceDataVersionsBySlugsIfExistsArgs = {
  slugs: Array<Scalars['String']['input']>;
};

export type GQQueryRefreshableAssetsArgs = {
  orgId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryReportAnswerVerifierFailuresArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportAnswerFilters: GQReportAnswerFilterInput;
};

export type GQQueryReportAnswersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQReportAnswerFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryReportConfigArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportConfigFrameworkMappingArgs = {
  reportConfigId: Scalars['ID']['input'];
};

export type GQQueryReportConfigQuestionsArgs = {
  reportConfigId: Scalars['ID']['input'];
};

export type GQQueryReportConfigsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryReportQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportQuestionContainerArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportQuestionIdentifierMappingArgs = {
  reportQuestionIdentifierId: Scalars['ID']['input'];
};

export type GQQueryReportQuestionIdentifiersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryReportingFootprintHealthChecksArgs = {
  input: GQReportingFootprintHealthChecksInput;
};

export type GQQueryReportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  configId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  kind: InputMaybe<Scalars['String']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
  status: InputMaybe<GQReportStatus>;
};

export type GQQueryRoleArgs = {
  id: Scalars['ID']['input'];
};

export type GQQuerySchemaRegistrySchemaArgs = {
  schemaId: Scalars['ID']['input'];
};

export type GQQuerySchemaRegistrySchemaVersionArgs = {
  schemaVersionId: Scalars['ID']['input'];
};

export type GQQuerySchemaRegistrySchemaVersionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  schemaId: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQuerySchemaRegistrySchemasArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQuerySearchReferenceDataSourceNameArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
  q: Scalars['String']['input'];
};

export type GQQueryShouldShowDuckArgs = {
  duckName: Scalars['String']['input'];
};

export type GQQuerySignOneSchemaJwtArgs = {
  payload: Scalars['JSONString']['input'];
};

export type GQQuerySignedParquetUrlArgs = {
  id: Scalars['ID']['input'];
};

export type GQQuerySupplierTableColumnsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQuerySupplierViewsAdminArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQuerySuppliersAdminArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQuerySuppliersSettingsAdminArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryThrowErrorQueryArgs = {
  input: GQThrowErrorInput;
};

export type GQQueryUploadsForActivityTypeArgs = {
  activityType: Scalars['String']['input'];
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadTaskArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadTasksForOrgArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryUserUploadedTableArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadedTablesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryUserUploadedTablesByIdArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryUserUploadsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  datasourceId: InputMaybe<Scalars['ID']['input']>;
  datasourceIds: InputMaybe<Array<Scalars['ID']['input']>>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeRevisions: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryUsersArgs = {
  includeWatershedEmployees: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryValidateUpdateReferenceDataVersionSchemaArgs = {
  orgId: InputMaybe<Scalars['String']['input']>;
  referenceDataVersionId: Scalars['ID']['input'];
  schemaJson: Scalars['JSONSchema']['input'];
};

export type GQQueryVendorMatchingTasksArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryWatershedEmployeesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgPointOfContactKind: InputMaybe<GQOrgPointOfContactKind>;
};

export type GQQueryRunInterface = {
  error: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobError: Maybe<GQSerializableError>;
  state: GQBackgroundJobState;
};

export type GQQuestion = {
  comments: GQCommentConnection;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type GQQuestionCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQRawCadtRowToMap = {
  __typename?: 'RawCadtRowToMap';
  adt_ids: Array<Scalars['String']['output']>;
  description_from_cadt: Maybe<Scalars['String']['output']>;
  emissions_specifier: Scalars['String']['output'];
  emissions_subspecifier: Scalars['String']['output'];
  month_earliest: Scalars['YearMonth']['output'];
  month_latest: Scalars['YearMonth']['output'];
  top_vendors: Array<GQRawCadtRowToMapVendor>;
  total_amount: Maybe<Scalars['Float']['output']>;
  unit: Maybe<Scalars['String']['output']>;
  unnormalized_emissions_subspecifier: Scalars['String']['output'];
};

export type GQRawCadtRowToMapConnection = {
  __typename?: 'RawCadtRowToMapConnection';
  edges: Array<Maybe<GQRawCadtRowToMapEdge>>;
  pageInfo: GQPageInfo;
};

export type GQRawCadtRowToMapEdge = {
  __typename?: 'RawCadtRowToMapEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQRawCadtRowToMap>;
};

export type GQRawCadtRowToMapVendor = {
  __typename?: 'RawCadtRowToMapVendor';
  amount: Scalars['Float']['output'];
  vendor_entity: Scalars['String']['output'];
};

export type GQReductionTargetByYear = {
  __typename?: 'ReductionTargetByYear';
  reductionTarget: Scalars['Float']['output'];
  targetYear: Scalars['YearMonth']['output'];
};

export type GQReductionTargetsInfo = {
  __typename?: 'ReductionTargetsInfo';
  heading: Scalars['String']['output'];
  reductionTargets: Array<GQReductionTargetsInfoItem>;
};

export type GQReductionTargetsInfoItem = {
  __typename?: 'ReductionTargetsInfoItem';
  description: Scalars['String']['output'];
  heading: Scalars['String']['output'];
  subheading: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQReferenceDataAncestorRelationship = {
  __typename?: 'ReferenceDataAncestorRelationship';
  upstreamVersionId: Scalars['ID']['output'];
  upstreamVersionSlug: Scalars['String']['output'];
  versionId: Scalars['ID']['output'];
  versionSlug: Scalars['String']['output'];
};

export type GQReferenceDataCitation = {
  __typename?: 'ReferenceDataCitation';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  author: Maybe<GQUser>;
  authorUserId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  externalSourceNotes: Maybe<Scalars['String']['output']>;
  externalSourceUrl: Maybe<Scalars['String']['output']>;
  hasLicenseRestrictions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  internalSourceNotes: Scalars['String']['output'];
  internalSourceUrl: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  isCustomerProvided: Scalars['Boolean']['output'];
  isSupplierSpecific: Scalars['Boolean']['output'];
  org: Maybe<GQOrganization>;
  orgId: Maybe<Scalars['String']['output']>;
  privateDisclosureId: Maybe<Scalars['String']['output']>;
  publicDisclosureId: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReferenceDataCitationConnection = {
  __typename?: 'ReferenceDataCitationConnection';
  edges: Array<GQReferenceDataCitationEdge>;
  pageInfo: GQPageInfo;
};

export type GQReferenceDataCitationEdge = {
  __typename?: 'ReferenceDataCitationEdge';
  cursor: Scalars['String']['output'];
  node: GQReferenceDataCitation;
};

export type GQReferenceDataDifferResult = {
  __typename?: 'ReferenceDataDifferResult';
  fileMetadata: GQFileMetadata;
  sourceReferenceDataRevision: GQReferenceDataRevision;
  targetReferenceDataRevision: GQReferenceDataRevision;
};

export const GQReferenceDataKind = {
  Composite: 'Composite',
  Translation: 'Translation',
} as const;

export type GQReferenceDataKind =
  (typeof GQReferenceDataKind)[keyof typeof GQReferenceDataKind];
export type GQReferenceDataOverlay = {
  __typename?: 'ReferenceDataOverlay';
  globalCdrId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['String']['output'];
  signedMergedDataUrl: Scalars['String']['output'];
  source: Maybe<GQReferenceDataSource>;
};

export type GQReferenceDataRevision = {
  __typename?: 'ReferenceDataRevision';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  assumptionSource: Maybe<GQAssumptionSource>;
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  internalSourceNotes: Maybe<Scalars['String']['output']>;
  isLatestForOrgAndState: Scalars['Boolean']['output'];
  isOverlay: Maybe<Scalars['Boolean']['output']>;
  isValueMappingLegacyMigration: Maybe<Scalars['Boolean']['output']>;
  kind: Maybe<GQReferenceDataRevisionKind>;
  lastCompatibleSchema: Maybe<Scalars['ID']['output']>;
  orgId: Maybe<Scalars['String']['output']>;
  organization: Maybe<GQOrganization>;
  outputFileId: Scalars['ID']['output'];
  revisionName: Scalars['String']['output'];
  rowsAsJson: Maybe<Scalars['JSONString']['output']>;
  schemaJson: Maybe<Scalars['JSONSchema']['output']>;
  schemaLastCheckedAgainst: Maybe<Scalars['ID']['output']>;
  signedParquetOutputUrl: Scalars['String']['output'];
  signedSourceUrl: Maybe<Scalars['String']['output']>;
  source: GQReferenceDataSource;
  sourceFileId: Maybe<Scalars['ID']['output']>;
  sourceId: Scalars['ID']['output'];
  sqlRun: Maybe<GQReferenceDataSql>;
  state: GQReferenceDataRevisionState;
  tableAlias: Maybe<Scalars['String']['output']>;
  updates: Scalars['JSONSchema']['output'];
  upstreamReferenceDataVersions: Array<GQReferenceDataVersion>;
  userAuthorId: Maybe<Scalars['ID']['output']>;
  version: GQReferenceDataVersion;
  versionId: Scalars['ID']['output'];
};

export type GQReferenceDataRevisionConnection = {
  __typename?: 'ReferenceDataRevisionConnection';
  edges: Array<Maybe<GQReferenceDataRevisionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReferenceDataRevisionEdge = {
  __typename?: 'ReferenceDataRevisionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReferenceDataRevision>;
};

export const GQReferenceDataRevisionKind = {
  CsvUpload: 'CsvUpload',
  SqlQuery: 'SqlQuery',
  SystemGenerated: 'SystemGenerated',
} as const;

export type GQReferenceDataRevisionKind =
  (typeof GQReferenceDataRevisionKind)[keyof typeof GQReferenceDataRevisionKind];
export const GQReferenceDataRevisionState = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type GQReferenceDataRevisionState =
  (typeof GQReferenceDataRevisionState)[keyof typeof GQReferenceDataRevisionState];
export type GQReferenceDataRevisionsByVersion = {
  __typename?: 'ReferenceDataRevisionsByVersion';
  revisions: Array<GQReferenceDataRevision>;
  version: GQReferenceDataVersion;
};

export type GQReferenceDataSchema = {
  __typename?: 'ReferenceDataSchema';
  id: Scalars['ID']['output'];
  schemaJson: Scalars['JSONSchema']['output'];
};

export type GQReferenceDataSchemaUpdateValidation = {
  __typename?: 'ReferenceDataSchemaUpdateValidation';
  errors: Array<Scalars['String']['output']>;
  warnings: Array<Scalars['String']['output']>;
};

export type GQReferenceDataSource = {
  __typename?: 'ReferenceDataSource';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  assumptionSources: Maybe<Array<GQAssumptionSource>>;
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  externalSourceNotes: Maybe<Scalars['String']['output']>;
  externalSourceUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCompositeData: Scalars['Boolean']['output'];
  isSyncedWithCliq: Scalars['Boolean']['output'];
  kind: Maybe<GQReferenceDataKind>;
  latestPublishedVersion: Maybe<GQReferenceDataVersion>;
  latestSignedParquetOutputUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  organization: Maybe<GQOrganization>;
  orgsWithRevisions: Maybe<Array<GQOrganization>>;
  referenceDataVersions: Array<GQReferenceDataVersion>;
  tags: Maybe<Array<GQCalculationTag>>;
  updatedAt: Scalars['DateTime']['output'];
  userAuthorId: Maybe<Scalars['ID']['output']>;
};

export type GQReferenceDataSourceLatestSignedParquetOutputUrlArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataSourceOrgsWithRevisionsArgs = {
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQReferenceDataSourceReferenceDataVersionsArgs = {
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQReferenceDataSourceConnection = {
  __typename?: 'ReferenceDataSourceConnection';
  edges: Array<Maybe<GQReferenceDataSourceEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReferenceDataSourceEdge = {
  __typename?: 'ReferenceDataSourceEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReferenceDataSource>;
};

export const GQReferenceDataSourceKindFilter = {
  All: 'All',
  Composite: 'Composite',
  Reference: 'Reference',
} as const;

export type GQReferenceDataSourceKindFilter =
  (typeof GQReferenceDataSourceKindFilter)[keyof typeof GQReferenceDataSourceKindFilter];
export type GQReferenceDataSql = {
  __typename?: 'ReferenceDataSql';
  createdAt: Scalars['DateTime']['output'];
  duckdbVersion: Scalars['String']['output'];
  error: Maybe<Scalars['String']['output']>;
  gitHash: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  queryResultFileId: Maybe<Scalars['ID']['output']>;
  revisionId: Maybe<Scalars['ID']['output']>;
  revisionLastUpdatedDate: Maybe<Scalars['DateTime']['output']>;
  revisionState: Maybe<GQReferenceDataRevisionState>;
  signedParquetOutputUrl: Maybe<Scalars['String']['output']>;
  signedParquetRawResultUrl: Maybe<Scalars['String']['output']>;
  sql: Scalars['String']['output'];
  status: Maybe<GQReferenceDataSqlStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warnings: Maybe<Scalars['JSONString']['output']>;
};

export const GQReferenceDataSqlStatus = {
  Error: 'Error',
  Success: 'Success',
} as const;

export type GQReferenceDataSqlStatus =
  (typeof GQReferenceDataSqlStatus)[keyof typeof GQReferenceDataSqlStatus];
export type GQReferenceDataVersion = {
  __typename?: 'ReferenceDataVersion';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  author: Maybe<GQUser>;
  canPublish: Scalars['Boolean']['output'];
  citations: Array<GQReferenceDataCitation>;
  compositeDataOrgDetails: Maybe<Array<GQCompositeDataOrgDetail>>;
  createdAt: Scalars['DateTime']['output'];
  hasPublishedRevision: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  latestPublishedRevisionByVersionId: Maybe<GQReferenceDataRevision>;
  latestReferenceDataSqlRun: Maybe<GQReferenceDataSql>;
  latestSchema: Maybe<GQReferenceDataSchema>;
  latestSignedParquetOutputUrl: Maybe<Scalars['String']['output']>;
  referenceDataRevisions: Maybe<Array<GQReferenceDataRevision>>;
  referenceDataSqlRuns: Array<GQReferenceDataSql>;
  slug: Scalars['String']['output'];
  source: GQReferenceDataSource;
  sourceId: Scalars['ID']['output'];
  state: GQReferenceDataVersionState;
  updatedAt: Scalars['DateTime']['output'];
  upstreamReferenceDataVersions: Array<GQReferenceDataVersion>;
  userAuthorId: Maybe<Scalars['ID']['output']>;
  versionName: Scalars['String']['output'];
  versionVintage: Scalars['DateTime']['output'];
};

export type GQReferenceDataVersionLatestPublishedRevisionByVersionIdArgs = {
  anyOrg: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionLatestReferenceDataSqlRunArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionLatestSignedParquetOutputUrlArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionReferenceDataRevisionsArgs = {
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionReferenceDataSqlRunsArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionForRdv = {
  __typename?: 'ReferenceDataVersionForRdv';
  rdsId: Scalars['String']['output'];
  rdsName: Scalars['String']['output'];
  rdvCreatedAt: Scalars['DateTime']['output'];
  rdvId: Scalars['String']['output'];
  rdvIsLatestPublishedVersion: Scalars['Boolean']['output'];
  rdvName: Scalars['String']['output'];
  rdvUpdatedAt: Scalars['DateTime']['output'];
  usingOutdatedRevision: Scalars['Boolean']['output'];
};

export type GQReferenceDataVersionSlugOutput = {
  __typename?: 'ReferenceDataVersionSlugOutput';
  referenceDataVersion: GQReferenceDataVersion;
  slug: Scalars['String']['output'];
};

export const GQReferenceDataVersionState = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type GQReferenceDataVersionState =
  (typeof GQReferenceDataVersionState)[keyof typeof GQReferenceDataVersionState];
export type GQReferenceValueDetails = {
  __typename?: 'ReferenceValueDetails';
  citation: GQTraceReferenceValueCitationDetails;
  description: Maybe<Scalars['String']['output']>;
  refDataCellPath: Scalars['String']['output'];
};

export type GQRefreshAssetYearEstimationsInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assetYearIds: Array<Scalars['ID']['input']>;
};

export type GQRefreshAssetYearEstimationsPayload = {
  __typename?: 'RefreshAssetYearEstimationsPayload';
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQRefreshCarbonRemovalActivityDataTableInput = {
  orgId: Scalars['String']['input'];
};

export type GQRefreshCarbonRemovalActivityDataTablePayload = {
  __typename?: 'RefreshCarbonRemovalActivityDataTablePayload';
  jobId: Maybe<Scalars['ID']['output']>;
};

export type GQRefreshCleanPowerActivityDataTableInput = {
  orgId: Scalars['ID']['input'];
  pipelineResultId: Scalars['ID']['input'];
};

export type GQRefreshCleanPowerActivityDataTablePayload = {
  __typename?: 'RefreshCleanPowerActivityDataTablePayload';
  jobId: Scalars['ID']['output'];
  warnings: Maybe<Array<Scalars['String']['output']>>;
};

export type GQRefreshReportPayload = {
  __typename?: 'RefreshReportPayload';
  report: GQReport;
  reportGenerationJobId: Maybe<Scalars['String']['output']>;
};

export const GQRefrigerantLeakageSource = {
  InstalledEquipment: 'InstalledEquipment',
  Manufacturing: 'Manufacturing',
} as const;

export type GQRefrigerantLeakageSource =
  (typeof GQRefrigerantLeakageSource)[keyof typeof GQRefrigerantLeakageSource];
export type GQRegenerateUserSignupTokenInput = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQRegenerateUserSignupTokenPayload = {
  __typename?: 'RegenerateUserSignupTokenPayload';
  user: GQUser;
};

/** The region that the organization is in. This dictates where their data is stores and processed. */
export const GQRegion = {
  Eu: 'eu',
  Us: 'us',
} as const;

export type GQRegion = (typeof GQRegion)[keyof typeof GQRegion];
export type GQReingestCompanyIngestionDiffInput = {
  companyIngestionDiffIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  filters?: InputMaybe<GQCompanyIngestionRecordUpdateFilters>;
  override?: InputMaybe<GQCompanyOverrideMatchInput>;
};

export type GQReingestCompanyIngestionDiffPayload = {
  __typename?: 'ReingestCompanyIngestionDiffPayload';
  companyIngestionDiffs: Array<GQCompanyIngestionDiff>;
  companyIngestionRecordUpdates: Array<GQCompanyIngestionRecordUpdate>;
};

export type GQReleaseEmissionsModelActiveDraftInput = {
  parentEmissionsModelVersionId: Scalars['ID']['input'];
};

export type GQReleaseEmissionsModelActiveDraftPayload =
  | GQReleaseEmissionsModelActiveDraftPayloadNonOp
  | GQReleaseEmissionsModelActiveDraftPayloadSuccess;

export type GQReleaseEmissionsModelActiveDraftPayloadNonOp = {
  __typename?: 'ReleaseEmissionsModelActiveDraftPayloadNonOp';
  message: Maybe<Scalars['String']['output']>;
};

export type GQReleaseEmissionsModelActiveDraftPayloadSuccess = {
  __typename?: 'ReleaseEmissionsModelActiveDraftPayloadSuccess';
  emissionsModelActiveDraftRecord: Maybe<GQEmissionsModelActiveDraft>;
};

export type GQRemoveCalculationTagFromReferenceDataInput = {
  sourceId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type GQRemoveCalculationTagFromReferenceDataPayload = {
  __typename?: 'RemoveCalculationTagFromReferenceDataPayload';
  success: Scalars['Boolean']['output'];
};

export type GQRemoveCalculationTagFromStableModelInput = {
  stableId: Scalars['ID']['input'];
  tagName: Scalars['String']['input'];
};

export type GQRemoveCalculationTagFromStableModelPayload = {
  __typename?: 'RemoveCalculationTagFromStableModelPayload';
  success: Scalars['Boolean']['output'];
};

export type GQRemoveMarketplaceSupplierPointOfContactInput = {
  pointOfContactId: Scalars['ID']['input'];
  supplierId: Scalars['ID']['input'];
};

export type GQRemoveMarketplaceSupplierPointOfContactPayload = {
  __typename?: 'RemoveMarketplaceSupplierPointOfContactPayload';
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQRemoveMarketplaceSupplierWatershedEmployeeInput = {
  supplierId: Scalars['ID']['input'];
  watershedEmployeeId: Scalars['ID']['input'];
};

export type GQRemoveMarketplaceSupplierWatershedEmployeePayload = {
  __typename?: 'RemoveMarketplaceSupplierWatershedEmployeePayload';
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQRemoveUserAdminVersionInput = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQRemoveUserAdminVersionPayload = {
  __typename?: 'RemoveUserAdminVersionPayload';
  user: GQUser;
};

export type GQRenameCustomEmissionsFactorSetupTaskInput = {
  newName?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['ID']['input'];
};

export type GQRenameCustomEmissionsFactorSetupTaskPayload = {
  __typename?: 'RenameCustomEmissionsFactorSetupTaskPayload';
  taskId: Scalars['ID']['output'];
};

export type GQRenewableEnergyPercentagePoint = {
  __typename?: 'RenewableEnergyPercentagePoint';
  renewableEnergyFraction: Scalars['Float']['output'];
  yearStart: Scalars['YearMonth']['output'];
};

export type GQReplaceReportingCdpMappingsColumnMappingInput = {
  cdpRefId: Scalars['String']['input'];
  otherColumnIdMapping?: InputMaybe<Scalars['String']['input']>;
  watershedColumnId?: InputMaybe<Scalars['String']['input']>;
};

export type GQReplaceReportingCdpMappingsInput = {
  columnIdMappings: Array<GQReplaceReportingCdpMappingsColumnMappingInput>;
  questionMappings: Array<GQReplaceReportingCdpMappingsQuestionMappingInput>;
};

export type GQReplaceReportingCdpMappingsPayload = {
  __typename?: 'ReplaceReportingCdpMappingsPayload';
  mappings: GQCdpIdMappingsPayload;
  success: Scalars['Boolean']['output'];
};

export type GQReplaceReportingCdpMappingsQuestionMappingInput = {
  cdpRefId?: InputMaybe<Scalars['String']['input']>;
  identifier: Scalars['ID']['input'];
};

export type GQReport = GQClimateProgramTimelineObject &
  GQIdInterface &
  GQPermissionObject & {
    __typename?: 'Report';
    config: GQReportConfig;
    createdAt: Scalars['DateTime']['output'];
    csrdDataRequirementsConfig: Maybe<Scalars['JSONString']['output']>;
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    downloadableDocxUrl: Scalars['String']['output'];
    downloadableXlsxUrl: Scalars['String']['output'];
    editedAt: Scalars['DateTime']['output'];
    footprintSnapshot: Maybe<GQFootprintSnapshot>;
    id: Scalars['ID']['output'];
    isHidden: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    organization: GQOrganization;
    publishedAt: Maybe<Scalars['DateTime']['output']>;
    reportKind: Scalars['String']['output'];
    reportStatus: GQReportStatus;
    updatedAt: Scalars['DateTime']['output'];
    warnings: Array<GQReportAnswerWarning>;
  };

export type GQReportAllQuestionsInput = {
  includeComputedItems?: InputMaybe<Scalars['Boolean']['input']>;
  includeInputs?: InputMaybe<Scalars['Boolean']['input']>;
  includeItems?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQReportAnswer = {
  __typename?: 'ReportAnswer';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  editorName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inputJson: Scalars['JSONString']['output'];
  outputJson: Maybe<Scalars['JSONString']['output']>;
  question: GQReportQuestion;
  report: GQReport;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportAnswerConnection = {
  __typename?: 'ReportAnswerConnection';
  edges: Array<Maybe<GQReportAnswerEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerCreatedByUserChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportAnswerCreatedByUserChangelogEvent';
      actor: GQUser;
      id: Scalars['ID']['output'];
      inputJson: Maybe<Scalars['JSONString']['output']>;
      outputJson: Maybe<Scalars['JSONString']['output']>;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportAnswerEdge = {
  __typename?: 'ReportAnswerEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswer>;
};

export type GQReportAnswerFilterInput = {
  componentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reportTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  skipApprovedFailures: Scalars['Boolean']['input'];
};

export type GQReportAnswerFilters = {
  components?: InputMaybe<Array<Scalars['String']['input']>>;
  excludeDemoOrgs?: InputMaybe<Scalars['Boolean']['input']>;
  excludeTestOrgs?: InputMaybe<Scalars['Boolean']['input']>;
  identifierIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jsonText?: InputMaybe<Scalars['String']['input']>;
  orgIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reportConfigIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reportQuestionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQReportAnswerHistoryConnection = {
  __typename?: 'ReportAnswerHistoryConnection';
  edges: Array<Maybe<GQReportAnswerHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerHistoryEdge = {
  __typename?: 'ReportAnswerHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswer>;
};

export type GQReportAnswerOverride = {
  __typename?: 'ReportAnswerOverride';
  componentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  editorName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  outputJson: Scalars['JSONString']['output'];
  question: GQReportQuestion;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportAnswerOverrideHistoryConnection = {
  __typename?: 'ReportAnswerOverrideHistoryConnection';
  edges: Array<Maybe<GQReportAnswerOverrideHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerOverrideHistoryEdge = {
  __typename?: 'ReportAnswerOverrideHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswerOverride>;
};

export const GQReportAnswerOverrideState = {
  UseDefault: 'UseDefault',
  UseOverride: 'UseOverride',
} as const;

export type GQReportAnswerOverrideState =
  (typeof GQReportAnswerOverrideState)[keyof typeof GQReportAnswerOverrideState];
export type GQReportAnswerOverrideStateHistoryConnection = {
  __typename?: 'ReportAnswerOverrideStateHistoryConnection';
  edges: Array<Maybe<GQReportAnswerOverrideStateHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerOverrideStateHistoryEdge = {
  __typename?: 'ReportAnswerOverrideStateHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswerOverrideStateLogEvent>;
};

export type GQReportAnswerOverrideStateLogEvent = {
  __typename?: 'ReportAnswerOverrideStateLogEvent';
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQUser;
  id: Scalars['ID']['output'];
  overrideState: GQReportAnswerOverrideState;
};

export type GQReportAnswerVerifierErrorMessage = {
  __typename?: 'ReportAnswerVerifierErrorMessage';
  count: Scalars['Int']['output'];
  errorMessage: Scalars['String']['output'];
};

export type GQReportAnswerVerifierFailure = GQIdInterface & {
  __typename?: 'ReportAnswerVerifierFailure';
  errorJson: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  mismatchApprovalTime: Maybe<Scalars['DateTime']['output']>;
  mismatchTime: Scalars['DateTime']['output'];
  orgId: Scalars['ID']['output'];
  organization: GQOrganization;
  outputJson: Maybe<Scalars['JSONString']['output']>;
  reportAnswer: GQReportAnswer;
  reportAnswerUpdatedAt: Scalars['DateTime']['output'];
};

export type GQReportAnswerVerifierFailureConnection = {
  __typename?: 'ReportAnswerVerifierFailureConnection';
  edges: Array<Maybe<GQReportAnswerVerifierFailureEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerVerifierFailureEdge = {
  __typename?: 'ReportAnswerVerifierFailureEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswerVerifierFailure>;
};

export type GQReportAnswerVerifierOptions = {
  __typename?: 'ReportAnswerVerifierOptions';
  componentIds: Maybe<Array<Scalars['String']['output']>>;
  organizations: Array<GQOrganization>;
  reportTypes: Maybe<Array<Scalars['String']['output']>>;
};

export type GQReportAnswerWarning = {
  __typename?: 'ReportAnswerWarning';
  acknowledgedBy: Maybe<GQUser>;
  answer: GQReportAnswer;
  id: Scalars['ID']['output'];
  outputJson: Scalars['JSONString']['output'];
  reportId: Scalars['String']['output'];
  validatorId: Scalars['String']['output'];
};

export type GQReportAttachment = {
  __typename?: 'ReportAttachment';
  authorId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  reportId: Scalars['ID']['output'];
  reportQuestionId: Scalars['ID']['output'];
};

export type GQReportAttachmentItem = {
  __typename?: 'ReportAttachmentItem';
  authorId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  fmdId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  kind: GQReportAttachmentItemKind;
  name: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export const GQReportAttachmentItemKind = {
  File: 'File',
  Url: 'Url',
} as const;

export type GQReportAttachmentItemKind =
  (typeof GQReportAttachmentItemKind)[keyof typeof GQReportAttachmentItemKind];
export const GQReportCalloutIcon = {
  Error: 'Error',
  Info: 'Info',
  Question: 'Question',
  Warning: 'Warning',
} as const;

export type GQReportCalloutIcon =
  (typeof GQReportCalloutIcon)[keyof typeof GQReportCalloutIcon];
export type GQReportConfig = {
  __typename?: 'ReportConfig';
  allContainers: Array<GQReportQuestionContainer>;
  allQuestions: Array<GQReportQuestion>;
  allVisibleInputs: Array<GQReportQuestion>;
  allVisibleItems: Array<GQReportQuestionOrContainer>;
  allVisibleQuestions: Array<GQReportQuestion>;
  brandColor: Scalars['String']['output'];
  computedItemIds: Array<Scalars['ID']['output']>;
  computedItems: Array<GQReportQuestion>;
  containers: Array<GQReportQuestionContainer>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** @deprecated Unused field */
  hasReductionPlan: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  inputIds: Array<Scalars['ID']['output']>;
  inputs: Array<GQReportQuestion>;
  isCorporateReport: Scalars['Boolean']['output'];
  isCreationBlocked: Scalars['Boolean']['output'];
  /** @deprecated Unused field */
  isDirectEditLocked: Maybe<Scalars['Boolean']['output']>;
  isFinanceReport: Scalars['Boolean']['output'];
  isHiddenFromOverview: Scalars['Boolean']['output'];
  isRegulatoryExposureBlocked: Scalars['Boolean']['output'];
  itemIds: Array<Scalars['ID']['output']>;
  /** @deprecated Use containers or questions instead. */
  items: Array<GQReportQuestionOrContainer>;
  lastLintedAt: Maybe<Scalars['DateTime']['output']>;
  latestComponentUpdatedAt: Maybe<Scalars['DateTime']['output']>;
  latestLinterStatus: Maybe<GQConfigLinterStatus>;
  longName: Scalars['String']['output'];
  questions: Array<GQReportQuestion>;
  reportObjectives: Array<GQCanonicalReportObjective>;
  reportType: Scalars['String']['output'];
  requiredPermissions: Array<GQPermissionType>;
  shortName: Scalars['String']['output'];
  status: Maybe<GQReportConfigStatus>;
  updatedAt: Scalars['DateTime']['output'];
  useApprovals: Scalars['Boolean']['output'];
  useMateriality: Scalars['Boolean']['output'];
  useOverrides: Scalars['Boolean']['output'];
  useReportingIntention: Scalars['Boolean']['output'];
  useTableDisplay: Maybe<Scalars['Boolean']['output']>;
};

export type GQReportConfigAllQuestionsArgs = {
  input: InputMaybe<GQReportAllQuestionsInput>;
};

export type GQReportConfigAllVisibleInputsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportConfigAllVisibleItemsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportConfigAllVisibleQuestionsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportConfigComputedItemsArgs = {
  filter: InputMaybe<GQReportQuestionFilter>;
};

export type GQReportConfigLatestComponentUpdatedAtArgs = {
  id: Scalars['ID']['input'];
};

export type GQReportConfigConnection = {
  __typename?: 'ReportConfigConnection';
  edges: Array<Maybe<GQReportConfigEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportConfigEdge = {
  __typename?: 'ReportConfigEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportConfig>;
};

export type GQReportConfigFrameworkMapping = {
  __typename?: 'ReportConfigFrameworkMapping';
  csv: Maybe<Scalars['String']['output']>;
  reportConfigId: Scalars['ID']['output'];
};

export type GQReportConfigImportBehaviorFlagsInput = {
  deleteUnusedItems: Scalars['Boolean']['input'];
  modifyExistingItems: Scalars['Boolean']['input'];
};

export type GQReportConfigSrcPayload = {
  __typename?: 'ReportConfigSrcPayload';
  lintStatus: GQConfigLinterStatus;
  src: Scalars['JSONString']['output'];
};

export const GQReportConfigStatus = {
  Beta: 'Beta',
  ComingSoon: 'ComingSoon',
  Inactive: 'Inactive',
  New: 'New',
} as const;

export type GQReportConfigStatus =
  (typeof GQReportConfigStatus)[keyof typeof GQReportConfigStatus];
export type GQReportConnection = {
  __typename?: 'ReportConnection';
  edges: Array<Maybe<GQReportEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportEdge = {
  __typename?: 'ReportEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReport>;
};

export type GQReportExplanation = {
  __typename?: 'ReportExplanation';
  markdownText: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type GQReportInput = {
  __typename?: 'ReportInput';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQReportItemCallout = GQIReportItem & {
  __typename?: 'ReportItemCallout';
  callout: Maybe<Scalars['String']['output']>;
  icon: Maybe<GQReportCalloutIcon>;
  id: Scalars['ID']['output'];
  isMarkdown: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export const GQReportItemKind = {
  Callout: 'CALLOUT',
  SecrStandardTable: 'SECR_STANDARD_TABLE',
  SecrTable: 'SECR_TABLE',
  SingleValue: 'SINGLE_VALUE',
  StandardTable: 'STANDARD_TABLE',
} as const;

export type GQReportItemKind =
  (typeof GQReportItemKind)[keyof typeof GQReportItemKind];
export type GQReportItemSecrStandardTable = GQIReportItem & {
  __typename?: 'ReportItemSecrStandardTable';
  id: Scalars['ID']['output'];
  isFullWidth: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  rows: Array<GQReportTableRow>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export type GQReportItemSingleValue = GQIReportItem & {
  __typename?: 'ReportItemSingleValue';
  explanation: Maybe<GQReportExplanation>;
  id: Scalars['ID']['output'];
  isFullWidth: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  sublabel: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type GQReportItemStandardTable = GQIReportItem & {
  __typename?: 'ReportItemStandardTable';
  columns: Array<GQReportTableColumn>;
  id: Scalars['ID']['output'];
  isFullWidth: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  rows: Array<GQReportTableRow>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export const GQReportMode = {
  Corporate: 'Corporate',
  Finance: 'Finance',
} as const;

export type GQReportMode = (typeof GQReportMode)[keyof typeof GQReportMode];
export type GQReportObjectConfigPathItem = {
  __typename?: 'ReportObjectConfigPathItem';
  id: Maybe<Scalars['ID']['output']>;
  isComputed: Scalars['Boolean']['output'];
  isInput: Scalars['Boolean']['output'];
  label: Maybe<Scalars['String']['output']>;
};

export const GQReportObjectType = {
  Module: 'module',
  Question: 'question',
} as const;

export type GQReportObjectType =
  (typeof GQReportObjectType)[keyof typeof GQReportObjectType];
export type GQReportQuestion = {
  __typename?: 'ReportQuestion';
  allowNotes: Scalars['Boolean']['output'];
  allowedAttachmentKinds: Maybe<Array<GQReportAttachmentItemKind>>;
  answer: Maybe<GQReportAnswer>;
  answerOverride: Maybe<GQReportAnswerOverride>;
  assignees: Array<GQUser>;
  cdpSyncHistory: GQCdpSyncQuestionHistoryConnection;
  componentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  dependentItems: GQDependentReportItems;
  /** @deprecated use parentContainer label instead */
  displayName: Maybe<Scalars['String']['output']>;
  dynamicInputJson: Maybe<Scalars['JSONString']['output']>;
  externalAnswerSuggestions: Array<GQExternalReportAnswerSuggestion>;
  guidanceJson: Maybe<Scalars['JSONString']['output']>;
  history: GQReportAnswerHistoryConnection;
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  identifierId: Scalars['String']['output'];
  instance: GQReportQuestionInstance;
  isComputed: Scalars['Boolean']['output'];
  isDynamic: Maybe<Scalars['Boolean']['output']>;
  materiality: Maybe<GQMaterialityStatus>;
  notes: Maybe<GQDiscussion>;
  overrideHistory: GQReportAnswerOverrideHistoryConnection;
  overrideState: GQReportAnswerOverrideState;
  overrideStateHistory: GQReportAnswerOverrideStateHistoryConnection;
  parentContainer: Maybe<GQReportQuestionContainer>;
  path: Array<GQReportObjectConfigPathItem>;
  peerExternalReportAnswers: Maybe<GQPeerExternalReportAnswers>;
  questionsWithMatchingOutputSchema: Array<GQReportQuestion>;
  reportConfig: GQReportConfig;
  reportConfigId: Scalars['ID']['output'];
  resolvedInputs: GQResolvedReportQuestionInputs;
  resolvedReportingIntention: Maybe<GQResolvedReportingIntention>;
  resolvedStatus: GQResolvedReportQuestionStatus;
  resolvedStatusIncludingContainer: GQResolvedReportQuestionStatus;
  resolvedWarnings: Array<GQReportAnswerWarning>;
  staticInputJson: Maybe<Scalars['JSONString']['output']>;
  statusJson: Scalars['JSONString']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportQuestionAnswerArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionAnswerOverrideArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionAssigneesArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionCdpSyncHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionDependentItemsArgs = {
  filter: InputMaybe<GQDependentQuestionFilter>;
};

export type GQReportQuestionExternalAnswerSuggestionsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionInstanceArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionMaterialityArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionNotesArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionOverrideHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionOverrideStateArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionOverrideStateHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedInputsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedReportingIntentionArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedStatusArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedStatusIncludingContainerArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedWarningsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionActorChangelogEvent = {
  actor: GQUser;
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type GQReportQuestionApprovalActionChangelogEvent = {
  actor: GQUser;
  comment: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
  previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
  timestamp: Scalars['DateTime']['output'];
};

export type GQReportQuestionApprovedChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionApprovalActionChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionApprovedChangelogEvent';
      actor: GQUser;
      comment: Maybe<Scalars['String']['output']>;
      id: Scalars['ID']['output'];
      isLocked: Scalars['Boolean']['output'];
      newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportQuestionApproversUpdatedChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionApproversUpdatedChangelogEvent';
      actor: GQUser;
      id: Scalars['ID']['output'];
      isLocked: Scalars['Boolean']['output'];
      newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      newApprovers: Array<GQUser>;
      previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      previousApprovers: Array<GQUser>;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportQuestionAssigneesUpdatedChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionAssigneesUpdatedChangelogEvent';
      actor: GQUser;
      id: Scalars['ID']['output'];
      newAssignees: Array<GQUser>;
      previousAssignees: Array<GQUser>;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportQuestionChangelogEvent = {
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type GQReportQuestionContainer = {
  __typename?: 'ReportQuestionContainer';
  allVisibleContainers: Array<GQReportQuestionContainer>;
  allVisibleQuestions: Array<GQReportQuestion>;
  assignees: Array<GQUser>;
  automaticProgress: GQResolvedContainerProgress;
  containers: Array<GQReportQuestionContainer>;
  description: Maybe<Scalars['String']['output']>;
  fullWidth: Scalars['Boolean']['output'];
  guidanceJson: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  itemIds: Array<Scalars['ID']['output']>;
  items: Array<GQReportQuestionOrContainer>;
  label: Maybe<Scalars['String']['output']>;
  overriddenProgress: Maybe<GQResolvedContainerProgress>;
  parentContainer: Maybe<GQReportQuestionContainer>;
  questionsWithResolvedWarnings: Array<GQReportQuestion>;
  reportConfig: GQReportConfig;
  reportConfigId: Scalars['ID']['output'];
  resolvedStatus: GQResolvedReportQuestionStatus;
  statusJson: Scalars['JSONString']['output'];
};

export type GQReportQuestionContainerAllVisibleContainersArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerAllVisibleQuestionsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerAssigneesArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerAutomaticProgressArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerOverriddenProgressArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerQuestionsWithResolvedWarningsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerResolvedStatusArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionFilter = {
  componentId?: InputMaybe<Scalars['String']['input']>;
};

export type GQReportQuestionIdentifier = {
  __typename?: 'ReportQuestionIdentifier';
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
};

export type GQReportQuestionIdentifierConnection = {
  __typename?: 'ReportQuestionIdentifierConnection';
  edges: Array<Maybe<GQReportQuestionIdentifierEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportQuestionIdentifierEdge = {
  __typename?: 'ReportQuestionIdentifierEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportQuestionIdentifier>;
};

export type GQReportQuestionIdentifierMapping = {
  __typename?: 'ReportQuestionIdentifierMapping';
  id: Scalars['ID']['output'];
  questionIdentifier: GQReportQuestionIdentifier;
  relatedQuestionIdentifiers: Array<GQReportQuestionIdentifier>;
};

export type GQReportQuestionInstance = GQIdInterface &
  GQPermissionObject & {
    __typename?: 'ReportQuestionInstance';
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
  };

export const GQReportQuestionInstanceApprovalStatus = {
  Approved: 'Approved',
  NotSubmitted: 'NotSubmitted',
  Rejected: 'Rejected',
  Submitted: 'Submitted',
} as const;

export type GQReportQuestionInstanceApprovalStatus =
  (typeof GQReportQuestionInstanceApprovalStatus)[keyof typeof GQReportQuestionInstanceApprovalStatus];
export type GQReportQuestionInstancePayload = {
  __typename?: 'ReportQuestionInstancePayload';
  reportId: Maybe<Scalars['String']['output']>;
  reportQuestionId: Maybe<Scalars['String']['output']>;
};

export type GQReportQuestionOrContainer =
  | GQReportQuestion
  | GQReportQuestionContainer;

export type GQReportQuestionRejectedChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionApprovalActionChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionRejectedChangelogEvent';
      actor: GQUser;
      comment: Maybe<Scalars['String']['output']>;
      id: Scalars['ID']['output'];
      isLocked: Scalars['Boolean']['output'];
      newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportQuestionSeriesMetric = {
  __typename?: 'ReportQuestionSeriesMetric';
  series: Maybe<Array<GQReportQuestionSeriesMetricItem>>;
};

export type GQReportQuestionSeriesMetricItem = {
  __typename?: 'ReportQuestionSeriesMetricItem';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQReportQuestionSingleValueMetric = {
  __typename?: 'ReportQuestionSingleValueMetric';
  value: Maybe<Scalars['String']['output']>;
};

export const GQReportQuestionStatus = {
  Blocked: 'Blocked',
  Excluded: 'Excluded',
  Optional: 'Optional',
  Required: 'Required',
} as const;

export type GQReportQuestionStatus =
  (typeof GQReportQuestionStatus)[keyof typeof GQReportQuestionStatus];
export type GQReportQuestionSubmittedChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionApprovalActionChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionSubmittedChangelogEvent';
      actor: GQUser;
      comment: Maybe<Scalars['String']['output']>;
      id: Scalars['ID']['output'];
      isLocked: Scalars['Boolean']['output'];
      newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportQuestionWithdrawnChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionApprovalActionChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionWithdrawnChangelogEvent';
      actor: GQUser;
      comment: Maybe<Scalars['String']['output']>;
      id: Scalars['ID']['output'];
      isLocked: Scalars['Boolean']['output'];
      newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportSection = {
  __typename?: 'ReportSection';
  id: Scalars['ID']['output'];
  isCollapsible: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  items: Array<
    | GQReportItemCallout
    | GQReportItemSecrStandardTable
    | GQReportItemSingleValue
    | GQReportItemStandardTable
  >;
  label: Maybe<Scalars['String']['output']>;
  sectionId: Scalars['String']['output'];
  sublabel: Maybe<Scalars['String']['output']>;
};

export const GQReportStatus = {
  Editing: 'Editing',
  Failed: 'Failed',
  Locked: 'Locked',
  Processing: 'Processing',
  Published: 'Published',
  Submitted: 'Submitted',
} as const;

export type GQReportStatus =
  (typeof GQReportStatus)[keyof typeof GQReportStatus];
export type GQReportTableColumn = {
  __typename?: 'ReportTableColumn';
  alignment: Maybe<GQTableCellAlignment>;
  isMarkdown: Maybe<Scalars['Boolean']['output']>;
  label: Maybe<Scalars['String']['output']>;
  minWidthPx: Maybe<Scalars['Int']['output']>;
};

export type GQReportTableRow = {
  __typename?: 'ReportTableRow';
  colspans: Maybe<Array<Scalars['Int']['output']>>;
  explanations: Maybe<Array<Maybe<GQReportExplanation>>>;
  strong: Maybe<Scalars['Boolean']['output']>;
  values: Array<Scalars['String']['output']>;
};

export type GQReportWithConfig = {
  __typename?: 'ReportWithConfig';
  config: GQReportConfig;
  id: Scalars['ID']['output'];
  report: GQReport;
};

export type GQReportingFootprintHealthCheckFailure = {
  __typename?: 'ReportingFootprintHealthCheckFailure';
  outputJson: Scalars['JSONString']['output'];
  validatorId: Scalars['String']['output'];
};

export type GQReportingFootprintHealthChecksInput = {
  footprintSnapshotId: Scalars['ID']['input'];
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQReportingFootprintHealthChecksPayload = {
  __typename?: 'ReportingFootprintHealthChecksPayload';
  failures: Array<GQReportingFootprintHealthCheckFailure>;
  footprintSnapshotId: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
};

export type GQReportingFramework = {
  __typename?: 'ReportingFramework';
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQUser;
  description: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportingFrameworkDataPoint = {
  __typename?: 'ReportingFrameworkDataPoint';
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQUser;
  description: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  reportingFramework: GQReportingFramework;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportingFrameworkDataPointInput = {
  description: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  identifier: Scalars['String']['input'];
};

export type GQReportingFrameworkDataPointUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export const GQReportingIntention = {
  IsNotReporting: 'IsNotReporting',
  IsReporting: 'IsReporting',
} as const;

export type GQReportingIntention =
  (typeof GQReportingIntention)[keyof typeof GQReportingIntention];
export type GQReportingIntentionLogEvent = {
  __typename?: 'ReportingIntentionLogEvent';
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQUser;
  reason: Maybe<Scalars['String']['output']>;
};

export type GQRepresentativeNaicsCode = GQIdInterface & {
  __typename?: 'RepresentativeNaicsCode';
  companyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  naicsCode: Scalars['String']['output'];
};

export type GQRepresentativeNaicsCodeConnection = {
  __typename?: 'RepresentativeNaicsCodeConnection';
  edges: Array<Maybe<GQRepresentativeNaicsCodeEdge>>;
  pageInfo: GQPageInfo;
};

export type GQRepresentativeNaicsCodeEdge = {
  __typename?: 'RepresentativeNaicsCodeEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQRepresentativeNaicsCode>;
};

export const GQResetFootprintCustomAction = {
  AddFootprintDisclosure: 'AddFootprintDisclosure',
  ResetDemoFund: 'ResetDemoFund',
  ResetDemoFundBlank: 'ResetDemoFundBlank',
} as const;

export type GQResetFootprintCustomAction =
  (typeof GQResetFootprintCustomAction)[keyof typeof GQResetFootprintCustomAction];
export type GQResetLockedUserAdminVersionInput = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQResetLockedUserAdminVersionPayload = {
  __typename?: 'ResetLockedUserAdminVersionPayload';
  user: GQUser;
};

export const GQResolvedContainerProgress = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  NotApplicable: 'NotApplicable',
  NotStarted: 'NotStarted',
  OptedOut: 'OptedOut',
} as const;

export type GQResolvedContainerProgress =
  (typeof GQResolvedContainerProgress)[keyof typeof GQResolvedContainerProgress];
export type GQResolvedReportQuestionInputs =
  | GQResolvedReportQuestionInputsFailure
  | GQResolvedReportQuestionInputsSuccess;

export type GQResolvedReportQuestionInputsFailure = {
  __typename?: 'ResolvedReportQuestionInputsFailure';
  errorMessage: Maybe<Scalars['String']['output']>;
  questionIds: Array<Scalars['ID']['output']>;
};

export type GQResolvedReportQuestionInputsSuccess = {
  __typename?: 'ResolvedReportQuestionInputsSuccess';
  inputs: Scalars['JSONString']['output'];
  unparsedInputs: Scalars['JSONString']['output'];
};

export type GQResolvedReportQuestionStatus = {
  __typename?: 'ResolvedReportQuestionStatus';
  blockingMessage: Maybe<Scalars['String']['output']>;
  excludingQuestionId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  status: GQReportQuestionStatus;
};

export type GQResolvedReportingIntention = {
  __typename?: 'ResolvedReportingIntention';
  event: Maybe<GQReportingIntentionLogEvent>;
  id: Scalars['ID']['output'];
  intention: GQReportingIntention;
};

export type GQRestoreReportAdminPayload = {
  __typename?: 'RestoreReportAdminPayload';
  report: GQReport;
};

export type GQRestoreReportInput = {
  reportId: Scalars['ID']['input'];
};

export type GQRevenueSourceOption = {
  __typename?: 'RevenueSourceOption';
  currencyCode: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  revenue: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type GQReviewPrepublicationTestResultsForEmissionsModelPayload = {
  __typename?: 'ReviewPrepublicationTestResultsForEmissionsModelPayload';
  errorCode: Maybe<GQMeasurementResourcePublicationErrorCode>;
  outcome: GQMeasurementResourcePublishWorkflowOutcome;
  publishedEmissionsModelMatchers: Maybe<Array<GQEmissionsModelMatcher>>;
  publishedEmissionsModelVersion: Maybe<GQEmissionsModelVersion>;
};

export type GQReviewPrepublicationTestResultsForReferenceDataRevisionPayload = {
  __typename?: 'ReviewPrepublicationTestResultsForReferenceDataRevisionPayload';
  errorCode: Maybe<GQMeasurementResourcePublicationErrorCode>;
  outcome: GQMeasurementResourcePublishWorkflowOutcome;
  publishedReferenceDataRevision: Maybe<GQReferenceDataRevision>;
};

export type GQReviewPrepublicationTestResultsForReleasePayload = {
  __typename?: 'ReviewPrepublicationTestResultsForReleasePayload';
  errorCode: Maybe<GQMeasurementResourcePublicationErrorCode>;
  outcome: GQMeasurementResourcePublishWorkflowOutcome;
  publishedRelease: Maybe<GQEmissionsModelRelease>;
};

export type GQReviewPrepublicationTestResultsInput = {
  acceptResultsAndPublish: Scalars['Boolean']['input'];
  approvedTestSuiteIds: Array<Scalars['String']['input']>;
  workflowId: Scalars['String']['input'];
};

export type GQRevokePermissionItemInput = {
  permissionItemId: Scalars['ID']['input'];
};

export type GQRevokePermissionItemPayload = {
  __typename?: 'RevokePermissionItemPayload';
  permissionItem: GQPermissionItem;
};

export type GQRevokeUserRoleInput = {
  orgAccessId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  roleId: Scalars['ID']['input'];
};

export type GQRevokeUserRolePayload = {
  __typename?: 'RevokeUserRolePayload';
  userRoleAssignment: Maybe<GQUserRoleAssignment>;
};

export type GQRole = {
  __typename?: 'Role';
  apiKeys: Array<GQApiKeyRoleAssignment>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<GQUser>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  org: GQOrganization;
  permissionOrigin: Maybe<GQRolePermissionOrigin>;
  permissions: Array<GQPermissionItem>;
  updatedAt: Scalars['DateTime']['output'];
  users: Array<GQUserRoleAssignment>;
  watershedManaged: Scalars['Boolean']['output'];
  workosGroupId: Maybe<Scalars['String']['output']>;
};

export type GQRolePermissionOriginArgs = {
  permission: GQHasPermissionInput;
};

export type GQRoleConnection = {
  __typename?: 'RoleConnection';
  edges: Array<Maybe<GQRoleEdge>>;
  pageInfo: GQPageInfo;
};

export type GQRoleEdge = {
  __typename?: 'RoleEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQRole>;
};

export type GQRolePermissionOrigin = {
  __typename?: 'RolePermissionOrigin';
  id: Scalars['ID']['output'];
  permission: Maybe<GQPermissionItem>;
};

export type GQRunCtsTransformEditorPayload = {
  __typename?: 'RunCtsTransformEditorPayload';
  workflowId: Scalars['String']['output'];
};

export type GQRunCtsTransformTestCaseInput = {
  ctsTransformTestCaseId: Scalars['ID']['input'];
};

export type GQRunCtsVersionTestInput = {
  ctsVersionId: Scalars['ID']['input'];
  fileMetadataId: Scalars['ID']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
};

export type GQRunDataRegistryMigrationInput = {
  description: Scalars['String']['input'];
  fromSchemaVersionId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  toSchemaVersionId: Scalars['ID']['input'];
};

export type GQRunDataRegistryMigrationPayload = {
  __typename?: 'RunDataRegistryMigrationPayload';
  dataRegistryBatchMigratorRun: GQDataRegistryBatchMigratorRun;
};

export type GQRunDemoPlatformInput = {
  demoPlatformConfig?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQRunDemoPlatformPayload = {
  __typename?: 'RunDemoPlatformPayload';
  demoPlatformRunId: Scalars['ID']['output'];
  jobId: Scalars['ID']['output'];
};

export type GQRunExtractorInput = {
  activityType?: InputMaybe<Scalars['String']['input']>;
  adtId: Scalars['ID']['input'];
  kind: GQActivityDataExtractorKind;
  orgId: Scalars['ID']['input'];
  sql: Scalars['String']['input'];
  userUploadedTableIds: Array<Scalars['String']['input']>;
};

export type GQRunExtractorPayload = {
  __typename?: 'RunExtractorPayload';
  run: GQExtractorRun;
};

export type GQRunPlaygroundSqlInput = {
  duckDbInstanceKind?: InputMaybe<GQDuckDbInstanceKind>;
  orgId: Scalars['ID']['input'];
  sql: Scalars['String']['input'];
};

export type GQRunPlaygroundSqlPayload = {
  __typename?: 'RunPlaygroundSqlPayload';
  run: GQPlaygroundSqlRun;
};

export type GQRunReportAnswerVerifierInput = {
  reportAnswerId: Scalars['ID']['input'];
  shouldUseReducedSchemaForComparison?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQRunReportAnswerVerifierPayload = {
  __typename?: 'RunReportAnswerVerifierPayload';
  reportAnswerId: Scalars['ID']['output'];
  reportAnswerVerifierFailure: Maybe<GQReportAnswerVerifierFailure>;
};

export type GQRunSchemaValidationForReferenceDataVersionInput = {
  versionId: Scalars['String']['input'];
};

export type GQRunSchemaValidationForReferenceDataVersionPayload = {
  __typename?: 'RunSchemaValidationForReferenceDataVersionPayload';
  workflowId: Scalars['String']['output'];
};

export type GQSbtCommitment = {
  __typename?: 'SBTCommitment';
  baselineYear: Maybe<Scalars['Int']['output']>;
  commitmentDeadline: Maybe<Scalars['Date']['output']>;
  commitmentType: Maybe<GQSbtCommitmentType>;
  id: Scalars['ID']['output'];
  longTermTargetYear: Maybe<Scalars['Int']['output']>;
  nearTermTargetYear: Maybe<Scalars['Int']['output']>;
  netZeroCommitted: Maybe<Scalars['Boolean']['output']>;
  netZeroTargetYear: Maybe<Scalars['Int']['output']>;
  stage: Maybe<GQSbtCommitmentStage>;
  targetClassification: Maybe<GQSbtTargetClassification>;
};

export type GQSbtCommitmentInput = {
  baselineYear?: InputMaybe<Scalars['Int']['input']>;
  commitmentDeadline?: InputMaybe<Scalars['Date']['input']>;
  commitmentType?: InputMaybe<GQSbtCommitmentType>;
  longTermTargetYear?: InputMaybe<Scalars['Int']['input']>;
  nearTermTargetYear?: InputMaybe<Scalars['Int']['input']>;
  netZeroTargetYear?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<GQSbtCommitmentStage>;
  targetClassification?: InputMaybe<GQSbtTargetClassification>;
};

export const GQSbtScope1And2Target = {
  OnePointFiveC: 'OnePointFiveC',
  WellBelowTwoC: 'WellBelowTwoC',
} as const;

export type GQSbtScope1And2Target =
  (typeof GQSbtScope1And2Target)[keyof typeof GQSbtScope1And2Target];
export const GQSbtScope3Target = {
  Geva7: 'GEVA7',
  OnePointFiveC: 'OnePointFiveC',
  TwoC: 'TwoC',
  WellBelowTwoC: 'WellBelowTwoC',
} as const;

export type GQSbtScope3Target =
  (typeof GQSbtScope3Target)[keyof typeof GQSbtScope3Target];
export const GQSbtTermLength = {
  LongTerm: 'LongTerm',
  NearTerm: 'NearTerm',
} as const;

export type GQSbtTermLength =
  (typeof GQSbtTermLength)[keyof typeof GQSbtTermLength];
export type GQSdiIngestionInstructionStepInput = {
  descriptionMd: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export const GQSasbIndustry = {
  ECommerce: 'ECommerce',
  FoodRetailersAndDistributors: 'FoodRetailersAndDistributors',
  HotelsAndLodging: 'HotelsAndLodging',
  Other: 'Other',
  SoftwareAndItServices: 'SoftwareAndITServices',
} as const;

export type GQSasbIndustry =
  (typeof GQSasbIndustry)[keyof typeof GQSasbIndustry];
export const GQSasbIndustryType = {
  AdvertisingAndMarketing: 'AdvertisingAndMarketing',
  AerospaceAndDefense: 'AerospaceAndDefense',
  AgriculturalProducts: 'AgriculturalProducts',
  AirFreightAndLogistics: 'AirFreightAndLogistics',
  Airlines: 'Airlines',
  AlcoholicBeverages: 'AlcoholicBeverages',
  ApparelAccessoriesAndFootwear: 'ApparelAccessoriesAndFootwear',
  ApplianceManufacturing: 'ApplianceManufacturing',
  AssetManagementAndCustodyActivities: 'AssetManagementAndCustodyActivities',
  AutoParts: 'AutoParts',
  Automobiles: 'Automobiles',
  Biofuels: 'Biofuels',
  BiotechnologyAndPharmaceuticals: 'BiotechnologyAndPharmaceuticals',
  BuildingProductsAndFurnishings: 'BuildingProductsAndFurnishings',
  CarRentalAndLeasing: 'CarRentalAndLeasing',
  CasinosAndGaming: 'CasinosAndGaming',
  Chemicals: 'Chemicals',
  CoalOperations: 'CoalOperations',
  CommercialBanks: 'CommercialBanks',
  ConstructionMaterials: 'ConstructionMaterials',
  ConsumerFinance: 'ConsumerFinance',
  ContainersAndPackaging: 'ContainersAndPackaging',
  CruiseLines: 'CruiseLines',
  DrugRetailers: 'DrugRetailers',
  ECommerce: 'ECommerce',
  Education: 'Education',
  ElectricalAndElectronicEquipment: 'ElectricalAndElectronicEquipment',
  ElectricalUtilitiesAndPowerGenerators:
    'ElectricalUtilitiesAndPowerGenerators',
  ElectronicManufacturingServicesAndOriginalDesignManufacturing:
    'ElectronicManufacturingServicesAndOriginalDesignManufacturing',
  EngineeringAndConstructionServices: 'EngineeringAndConstructionServices',
  FoodRetailersAndDistributors: 'FoodRetailersAndDistributors',
  ForestryManagement: 'ForestryManagement',
  FuelCellsAndIndustrialBatteries: 'FuelCellsAndIndustrialBatteries',
  GasUtilitiesAndDistributors: 'GasUtilitiesAndDistributors',
  Hardware: 'Hardware',
  HealthCareDelivery: 'HealthCareDelivery',
  HealthCareDistributors: 'HealthCareDistributors',
  HomeBuilders: 'HomeBuilders',
  HotelsAndLodging: 'HotelsAndLodging',
  HouseholdAndPersonalProducts: 'HouseholdAndPersonalProducts',
  IndustrialMachineryAndGoods: 'IndustrialMachineryAndGoods',
  Insurance: 'Insurance',
  InternetMediaAndServices: 'InternetMediaAndServices',
  InvestmentBankingAndBrokerage: 'InvestmentBankingAndBrokerage',
  IronAndSteelProducers: 'IronAndSteelProducers',
  LeisureFacilities: 'LeisureFacilities',
  ManagedCare: 'ManagedCare',
  MarineTransportation: 'MarineTransportation',
  MeatPoultryAndDairy: 'MeatPoultryAndDairy',
  MediaAndEntertainment: 'MediaAndEntertainment',
  MedicalEquipmentAndSupplies: 'MedicalEquipmentAndSupplies',
  MetalsAndMining: 'MetalsAndMining',
  MortgageFinance: 'MortgageFinance',
  MultilineAndSpecialtyRetailersAndDistributors:
    'MultilineAndSpecialtyRetailersAndDistributors',
  NonAlcoholicBeverages: 'NonAlcoholicBeverages',
  OilAndGasExplorationAndProduction: 'OilAndGasExplorationAndProduction',
  OilAndGasMidstream: 'OilAndGasMidstream',
  OilAndGasRefiningAndMarketing: 'OilAndGasRefiningAndMarketing',
  OilAndGasServices: 'OilAndGasServices',
  ProcessedFoods: 'ProcessedFoods',
  ProfessionalAndCommercialServices: 'ProfessionalAndCommercialServices',
  PulpAndPaperProducts: 'PulpAndPaperProducts',
  RailTransportation: 'RailTransportation',
  RealEstate: 'RealEstate',
  RealEstateServices: 'RealEstateServices',
  Restaurants: 'Restaurants',
  RoadTransportation: 'RoadTransportation',
  SecurityAndCommodityExchanges: 'SecurityAndCommodityExchanges',
  Semiconductors: 'Semiconductors',
  SoftwareAndItServices: 'SoftwareAndITServices',
  SolarTechnologyAndProjectDevelopers: 'SolarTechnologyAndProjectDevelopers',
  TelecommunicationServices: 'TelecommunicationServices',
  Tobacco: 'Tobacco',
  ToysAndSportingGoods: 'ToysAndSportingGoods',
  WasteManagement: 'WasteManagement',
  WaterUtilitiesAndServices: 'WaterUtilitiesAndServices',
  WindTechnologyAndProjectDevelopers: 'WindTechnologyAndProjectDevelopers',
} as const;

export type GQSasbIndustryType =
  (typeof GQSasbIndustryType)[keyof typeof GQSasbIndustryType];
export const GQSasbSectorType = {
  ConsumerGoods: 'ConsumerGoods',
  ExtractivesAndMineralsProcessing: 'ExtractivesAndMineralsProcessing',
  Financials: 'Financials',
  FoodAndBeverage: 'FoodAndBeverage',
  HealthCare: 'HealthCare',
  Infrastructure: 'Infrastructure',
  RenewableResourcesAndAlternativeEnergy:
    'RenewableResourcesAndAlternativeEnergy',
  ResourceTransformation: 'ResourceTransformation',
  Services: 'Services',
  TechnologyAndCommunications: 'TechnologyAndCommunications',
  Transportation: 'Transportation',
} as const;

export type GQSasbSectorType =
  (typeof GQSasbSectorType)[keyof typeof GQSasbSectorType];
export type GQSaveEmissionsModelDraftInput = {
  changelog: Scalars['String']['input'];
  emissionsModelJson: Scalars['JSONString']['input'];
  matchers: Array<GQEmissionsModelMatcherInput>;
  parameterResolverJson: Scalars['JSONString']['input'];
  parentEmissionsModelVersionId: Scalars['ID']['input'];
  stableId: Scalars['ID']['input'];
};

export type GQSaveEmissionsModelDraftPayload =
  | GQSaveEmissionsModelDraftPayloadInvalid
  | GQSaveEmissionsModelDraftPayloadValid;

export type GQSaveEmissionsModelDraftPayloadInvalid = {
  __typename?: 'SaveEmissionsModelDraftPayloadInvalid';
  validationErrors: Array<GQEmissionsModelError>;
};

export type GQSaveEmissionsModelDraftPayloadValid = {
  __typename?: 'SaveEmissionsModelDraftPayloadValid';
  emissionsModelVersionRecord: GQEmissionsModelVersion;
  matchers: Array<GQEmissionsModelMatcher>;
  parameterResolverRecord: GQParameterResolver;
};

export type GQSaveFootprintTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  existingTagId?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  tagName: Scalars['String']['input'];
  taggingRules: Scalars['String']['input'];
};

export type GQSaveFootprintTagPayload = {
  __typename?: 'SaveFootprintTagPayload';
  footprintTag: GQFootprintTag;
};

export type GQSaveGlobalFootprintTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  tagName: Scalars['String']['input'];
  taggingRules: Scalars['String']['input'];
};

export type GQSaveGlobalFootprintTagPayload = {
  __typename?: 'SaveGlobalFootprintTagPayload';
  footprintTag: GQGlobalFootprintTag;
};

export const GQSbtCommitmentStage = {
  Committed: 'Committed',
  Removed: 'Removed',
  TargetsSet: 'TargetsSet',
} as const;

export type GQSbtCommitmentStage =
  (typeof GQSbtCommitmentStage)[keyof typeof GQSbtCommitmentStage];
export const GQSbtCommitmentType = {
  BaOnePointFiveOptionOne: 'BAOnePointFiveOptionOne',
  BaOnePointFiveOptionOneAndTwo: 'BAOnePointFiveOptionOneAndTwo',
  BaOnePointFiveOptionTwo: 'BAOnePointFiveOptionTwo',
  NetZero: 'NetZero',
  Standard: 'Standard',
} as const;

export type GQSbtCommitmentType =
  (typeof GQSbtCommitmentType)[keyof typeof GQSbtCommitmentType];
export type GQSbtTarget = GQIdInterface & {
  __typename?: 'SbtTarget';
  action: Maybe<Scalars['String']['output']>;
  baseYear: Maybe<Scalars['String']['output']>;
  commitmentDeadline: Maybe<Scalars['String']['output']>;
  commitmentType: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  companyTemperatureAlignment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  datePublished: Maybe<Scalars['String']['output']>;
  dt: Scalars['Date']['output'];
  fullTargetLanguage: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isin: Maybe<Scalars['String']['output']>;
  lei: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  organizationType: Maybe<Scalars['String']['output']>;
  reasonForCommitmentExtensionOrRemoval: Maybe<Scalars['String']['output']>;
  region: Maybe<Scalars['String']['output']>;
  scope: Maybe<Scalars['String']['output']>;
  sector: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  target: Maybe<Scalars['String']['output']>;
  targetClassification: Maybe<Scalars['String']['output']>;
  targetSubtype: Maybe<Scalars['String']['output']>;
  targetType: Maybe<Scalars['String']['output']>;
  targetValue: Maybe<Scalars['String']['output']>;
  targetWording: Maybe<Scalars['String']['output']>;
  targetYear: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  yearType: Maybe<Scalars['String']['output']>;
};

export const GQSbtTargetClassification = {
  OnePointFiveDegrees: 'OnePointFiveDegrees',
  TwoDegrees: 'TwoDegrees',
  WellBelowTwoDegrees: 'WellBelowTwoDegrees',
} as const;

export type GQSbtTargetClassification =
  (typeof GQSbtTargetClassification)[keyof typeof GQSbtTargetClassification];
export type GQSbtiTargetExclusion = {
  __typename?: 'SbtiTargetExclusion';
  filters: GQFilterExpressionGroupWithNewFilters;
  id: Scalars['ID']['output'];
};

export const GQScheduleType = {
  Customized: 'Customized',
  SingleParameterized: 'SingleParameterized',
} as const;

export type GQScheduleType =
  (typeof GQScheduleType)[keyof typeof GQScheduleType];
export type GQSchemaRegistrySchema = {
  __typename?: 'SchemaRegistrySchema';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  latestSchemaVersion: Maybe<GQSchemaRegistrySchemaVersion>;
  name: Scalars['String']['output'];
  settings: GQSchemaRegistrySchemaSettings;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQSchemaRegistrySchemaConnection = {
  __typename?: 'SchemaRegistrySchemaConnection';
  edges: Array<Maybe<GQSchemaRegistrySchemaEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSchemaRegistrySchemaEdge = {
  __typename?: 'SchemaRegistrySchemaEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSchemaRegistrySchema>;
};

export type GQSchemaRegistrySchemaSettings = {
  __typename?: 'SchemaRegistrySchemaSettings';
  dualWriteMode: Maybe<GQDataRegistryDualWriteMode>;
  dualWriteVersion: Maybe<GQSchemaRegistrySchemaVersion>;
  enforceBatchMigrations: Scalars['Boolean']['output'];
  readVersion: Maybe<GQSchemaRegistrySchemaVersion>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  writeVersion: Maybe<GQSchemaRegistrySchemaVersion>;
};

export type GQSchemaRegistrySchemaSettingsInput = {
  dualWriteMode?: InputMaybe<GQDataRegistryDualWriteMode>;
  dualWriteVersionId?: InputMaybe<Scalars['ID']['input']>;
  enforceBatchMigrations: Scalars['Boolean']['input'];
  readVersionId?: InputMaybe<Scalars['ID']['input']>;
  writeVersionId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQSchemaRegistrySchemaVersion = {
  __typename?: 'SchemaRegistrySchemaVersion';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  schema: GQSchemaRegistrySchema;
  schemaCount: Scalars['Int']['output'];
  schemaJson: Scalars['JSONString']['output'];
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['String']['output'];
  versionNumber: Scalars['Int']['output'];
};

export type GQSchemaRegistrySchemaVersionConnection = {
  __typename?: 'SchemaRegistrySchemaVersionConnection';
  edges: Array<Maybe<GQSchemaRegistrySchemaVersionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSchemaRegistrySchemaVersionEdge = {
  __typename?: 'SchemaRegistrySchemaVersionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSchemaRegistrySchemaVersion>;
};

export type GQScienceBasedTargetCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'ScienceBasedTargetCommitment';
    commitment: GQSbtCommitment;
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    submittedToSBTi: Scalars['Boolean']['output'];
  };

export const GQScope2Source = {
  Location: 'Location',
  Market: 'Market',
  Other: 'Other',
} as const;

export type GQScope2Source =
  (typeof GQScope2Source)[keyof typeof GQScope2Source];
export const GQScope3EvaluationStatus = {
  NotEvaluated: 'NOT_EVALUATED',
  NotRelevantCalculated: 'NOT_RELEVANT_CALCULATED',
  NotRelevantExplanationProvided: 'NOT_RELEVANT_EXPLANATION_PROVIDED',
  QuestionNotApplicable: 'QUESTION_NOT_APPLICABLE',
  RelevantCalculated: 'RELEVANT_CALCULATED',
  RelevantNotYetCalculated: 'RELEVANT_NOT_YET_CALCULATED',
  Unknown: 'UNKNOWN',
} as const;

export type GQScope3EvaluationStatus =
  (typeof GQScope3EvaluationStatus)[keyof typeof GQScope3EvaluationStatus];
export const GQScope3OverrideKind = {
  Overall: 'Overall',
  Subscope: 'Subscope',
} as const;

export type GQScope3OverrideKind =
  (typeof GQScope3OverrideKind)[keyof typeof GQScope3OverrideKind];
export type GQSdiInstructionsFaq = {
  answerMd: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type GQSdiInstructionsFaqType = {
  __typename?: 'SdiInstructionsFaqType';
  answerMd: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export type GQSdiInstructionsStep = {
  __typename?: 'SdiInstructionsStep';
  descriptionMd: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQSeedBatOntologyInput = {
  forceUpdateTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQSeedBatOntologyPayload = {
  __typename?: 'SeedBatOntologyPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQSerializableError = {
  __typename?: 'SerializableError';
  code: Maybe<Scalars['String']['output']>;
  details: Maybe<Scalars['JSONString']['output']>;
  errorType: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  stackTrace: Maybe<Scalars['String']['output']>;
};

export type GQSerializedWithHash = {
  hash: Maybe<Scalars['String']['output']>;
  serialized: Scalars['UntypedData']['output'];
};

export type GQSetEmissionsModelIsArchivedInput = {
  emissionsModelStableId: Scalars['ID']['input'];
  isArchived: Scalars['Boolean']['input'];
};

export type GQSetEmissionsModelIsArchivedPayload = {
  __typename?: 'SetEmissionsModelIsArchivedPayload';
  emissionsModelStableRecord: GQEmissionsModelStable;
};

export type GQSetEngagementNotificationSettingsForUserInput = {
  settings: Array<GQEngagementNotificationSettingInput>;
  side: GQEngagementNotificationSide;
  userId: Scalars['ID']['input'];
};

export type GQSetForecastFootprintSnapshotIdInput = {
  footprintSnapshotId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQSetForecastFootprintSnapshotIdPayload = {
  __typename?: 'SetForecastFootprintSnapshotIdPayload';
  forecast: GQForecast;
};

export type GQSetOrgsForFeatureFlagInput = {
  enabledForAllOrgs: Scalars['Boolean']['input'];
  featureFlagId: Scalars['ID']['input'];
  orgIds: Array<Scalars['ID']['input']>;
};

export type GQSetOrgsForFeatureFlagPayload = {
  __typename?: 'SetOrgsForFeatureFlagPayload';
  featureFlag: GQFeatureFlag;
};

export type GQSetPreferredLocaleInput = {
  locale: Scalars['String']['input'];
};

export type GQSetPreferredLocalePayload = {
  __typename?: 'SetPreferredLocalePayload';
  preferredLocale: Maybe<Scalars['String']['output']>;
};

export type GQSetSchemaRegistrySettingsInput = {
  schemaId: Scalars['ID']['input'];
  settings: GQSchemaRegistrySchemaSettingsInput;
};

export type GQSetSchemaRegistrySettingsPayload = {
  __typename?: 'SetSchemaRegistrySettingsPayload';
  schemaId: Scalars['ID']['output'];
  settings: GQSchemaRegistrySchemaSettings;
};

export type GQSetUserUploadedTableNotIgnoredInput = {
  userUploadedTableId: Scalars['ID']['input'];
};

export type GQSetUserUploadedTableNotIgnoredPayload = {
  __typename?: 'SetUserUploadedTableNotIgnoredPayload';
  userUploadedTable: GQUserUploadedTable;
};

export type GQSetUserUploadedTableSqlTableNameInput = {
  newSqlTableName: Scalars['String']['input'];
  userUploadedTableId: Scalars['ID']['input'];
};

export type GQSetUserUploadedTableSqlTableNamePayload = {
  __typename?: 'SetUserUploadedTableSqlTableNamePayload';
  userUploadedTable: GQUserUploadedTable;
};

export const GQSfdrFundClassification = {
  ArticleEight: 'ArticleEight',
  ArticleNine: 'ArticleNine',
  ArticleSix: 'ArticleSix',
} as const;

export type GQSfdrFundClassification =
  (typeof GQSfdrFundClassification)[keyof typeof GQSfdrFundClassification];
export type GQShareFilesInput = {
  climateLeadsToCc?: InputMaybe<Array<Scalars['ID']['input']>>;
  datasourceIds: Array<Scalars['ID']['input']>;
  fileMetadataIds: Array<Scalars['ID']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  orgPointsOfContactToCc?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIds: Array<Scalars['ID']['input']>;
  watershedEmployeeId: Scalars['ID']['input'];
};

export type GQShareFilesPayload = {
  __typename?: 'ShareFilesPayload';
  sharedFiles: Array<GQSharedFile>;
};

export type GQShareMeasurementProjectInput = {
  companyId: Scalars['ID']['input'];
  measurementProjectId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQShareMeasurementProjectPayload = {
  __typename?: 'ShareMeasurementProjectPayload';
  measurementProjectStatusShare: GQMeasurementProjectStatusShare;
};

export type GQSharedFile = {
  __typename?: 'SharedFile';
  createdAt: Scalars['DateTime']['output'];
  datasource: GQDatasource;
  expiresAt: Scalars['DateTime']['output'];
  fileMetadata: GQFileMetadata;
  id: Scalars['ID']['output'];
  measurementProject: Maybe<GQMeasurementProject>;
  orgId: Scalars['ID']['output'];
  signedUrl: Scalars['String']['output'];
  user: GQUser;
  watershedEmployee: Maybe<GQWatershedEmployee>;
};

export type GQSharedFileConnection = {
  __typename?: 'SharedFileConnection';
  edges: Array<Maybe<GQSharedFileEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSharedFileEdge = {
  __typename?: 'SharedFileEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSharedFile>;
};

export type GQSharedFileRequest = {
  __typename?: 'SharedFileRequest';
  createdAt: Scalars['DateTime']['output'];
  datasource: GQDatasource;
  fileMetadata: GQFileMetadata;
  fulfilledAt: Maybe<Scalars['DateTime']['output']>;
  fulfilledBy: Maybe<GQWatershedEmployee>;
  id: Scalars['ID']['output'];
  organization: GQOrganization;
  sharedFile: Maybe<GQSharedFile>;
  user: GQUser;
};

export type GQSharedFileRequestConnection = {
  __typename?: 'SharedFileRequestConnection';
  edges: Array<Maybe<GQSharedFileRequestEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSharedFileRequestEdge = {
  __typename?: 'SharedFileRequestEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSharedFileRequest>;
};

export type GQSharedMeasurementProject = {
  __typename?: 'SharedMeasurementProject';
  id: Scalars['ID']['output'];
  measurementProjectId: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQSignalExecuteMeasurementTestSuiteInput = {
  id: Scalars['ID']['input'];
  stage: GQMeasurementTestSuiteStage;
};

export type GQSignalExecuteMeasurementTestSuitePayload = {
  __typename?: 'SignalExecuteMeasurementTestSuitePayload';
  id: Scalars['ID']['output'];
};

export type GQSimpleTimeseries = {
  __typename?: 'SimpleTimeseries';
  base: Scalars['Date']['output'];
  frequency: GQTimeseriesFrequency;
  id: Maybe<Scalars['ID']['output']>;
  values: Array<Scalars['Float']['output']>;
};

export type GQSimpleTimeseriesInput = {
  base?: InputMaybe<Scalars['Date']['input']>;
  values: Array<Scalars['Float']['input']>;
};

export type GQSnapshotFootprintKindMeta = {
  __typename?: 'SnapshotFootprintKindMeta';
  description: Scalars['String']['output'];
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintKind: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export const GQSpendMethodology = {
  Ceda: 'CEDA',
  Useeio: 'USEEIO',
} as const;

export type GQSpendMethodology =
  (typeof GQSpendMethodology)[keyof typeof GQSpendMethodology];
export const GQSpendMethoodology = {
  Ceda: 'CEDA',
  Useeio: 'USEEIO',
} as const;

export type GQSpendMethoodology =
  (typeof GQSpendMethoodology)[keyof typeof GQSpendMethoodology];
export type GQStartPrepublicationTestsForEmissionsModelInput = {
  changelog: Scalars['String']['input'];
  emissionsModelJson: Scalars['JSONString']['input'];
  emissionsModelStableId: Scalars['ID']['input'];
  matchers: Array<GQEmissionsModelMatcherInput>;
  parameterResolverJson: Scalars['JSONString']['input'];
  parentEmissionsModelVersionId: Scalars['ID']['input'];
};

export type GQStartPrepublicationTestsForReferenceDataRevisionInput = {
  revisionId: Scalars['ID']['input'];
};

export type GQStartPrepublicationTestsForReleaseInput = {
  emissionsModelStableIds: Array<Scalars['ID']['input']>;
  globalFootprintTagInputs: Array<GQEmReleaseGlobalFootprintTagInput>;
  releaseId: Scalars['ID']['input'];
};

export type GQStartPrepublicationTestsPayload = {
  __typename?: 'StartPrepublicationTestsPayload';
  workflowId: Scalars['ID']['output'];
};

export type GQStartPublishingEmissionsModelInput = {
  changelog: Scalars['String']['input'];
  emissionsModelJson: Scalars['JSONString']['input'];
  matchers: Array<GQEmissionsModelMatcherInput>;
  parameterResolverJson: Scalars['JSONString']['input'];
  parentEmissionsModelVersionId: Scalars['ID']['input'];
  stableId: Scalars['ID']['input'];
};

export type GQStartPublishingEmissionsModelPayload =
  | GQStartPublishingEmissionsModelPayloadError
  | GQStartPublishingEmissionsModelPayloadInvalid
  | GQStartPublishingEmissionsModelPayloadValid;

export type GQStartPublishingEmissionsModelPayloadError = {
  __typename?: 'StartPublishingEmissionsModelPayloadError';
  error: GQSerializableError;
};

export type GQStartPublishingEmissionsModelPayloadInvalid = {
  __typename?: 'StartPublishingEmissionsModelPayloadInvalid';
  validationErrors: Array<GQEmissionsModelError>;
};

export type GQStartPublishingEmissionsModelPayloadValid = {
  __typename?: 'StartPublishingEmissionsModelPayloadValid';
  emissionsModelVersionRecord: GQEmissionsModelVersion;
  matchers: Array<GQEmissionsModelMatcher>;
  parameterResolverRecord: GQParameterResolver;
};

export type GQStartWorkflowInput = {
  args: Scalars['JSONString']['input'];
  preventConcurrentWorkflowRuns?: InputMaybe<Scalars['Boolean']['input']>;
  scopedOrgId?: InputMaybe<Scalars['String']['input']>;
  taskQueue?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type GQStartWorkflowPayload = {
  __typename?: 'StartWorkflowPayload';
  workflowExecution: GQWorkflowExecution;
};

export const GQStationaryCombustionFuelKind = {
  AgriculturalByproducts: 'AgriculturalByproducts',
  AnthraciteCoal: 'AnthraciteCoal',
  AsphaltAndRoadOil: 'AsphaltAndRoadOil',
  AviationGasoline: 'AviationGasoline',
  Bagasse: 'Bagasse',
  Bamboo: 'Bamboo',
  Biodiesel: 'Biodiesel',
  BiofuelsAndWaste: 'BiofuelsAndWaste',
  BituminousCoal: 'BituminousCoal',
  BlastFurnaceGas: 'BlastFurnaceGas',
  Butane: 'Butane',
  Butylene: 'Butylene',
  CoalCoke: 'CoalCoke',
  CokeOvenGas: 'CokeOvenGas',
  CompressedNaturalGas: 'CompressedNaturalGas',
  CrudeOil: 'CrudeOil',
  Diesel: 'Diesel',
  DistrictChilledWater: 'DistrictChilledWater',
  DistrictHeat: 'DistrictHeat',
  Ethane: 'Ethane',
  Ethanol: 'Ethanol',
  Ethylene: 'Ethylene',
  FuelGas: 'FuelGas',
  FuelOil1: 'FuelOil1',
  FuelOil2: 'FuelOil2',
  FuelOil4: 'FuelOil4',
  HeavyGasOils: 'HeavyGasOils',
  Isobutane: 'Isobutane',
  Isobutylene: 'Isobutylene',
  Kerosene: 'Kerosene',
  KeroseneTypeJetFuel: 'KeroseneTypeJetFuel',
  LandfillGas: 'LandfillGas',
  LigniteCoal: 'LigniteCoal',
  LiquefiedNaturalGas: 'LiquefiedNaturalGas',
  LiquefiedPetroleumGases: 'LiquefiedPetroleumGases',
  Lubricants: 'Lubricants',
  MixedCommercialSectorCoal: 'MixedCommercialSectorCoal',
  MixedElectricPowerSectorCoal: 'MixedElectricPowerSectorCoal',
  MixedIndustrialCoking: 'MixedIndustrialCoking',
  MixedIndustrialSector: 'MixedIndustrialSector',
  MotorGasoline: 'MotorGasoline',
  MunicipalSolidWaste: 'MunicipalSolidWaste',
  Naphtha: 'Naphtha',
  NaturalGas: 'NaturalGas',
  NaturalGasoline: 'NaturalGasoline',
  NorthAmericanHardwood: 'NorthAmericanHardwood',
  NorthAmericanSoftwood: 'NorthAmericanSoftwood',
  OtherBiomassGases: 'OtherBiomassGases',
  OtherOil: 'OtherOil',
  Peat: 'Peat',
  PentanesPlus: 'PentanesPlus',
  PetrochemicalFeedstocks: 'PetrochemicalFeedstocks',
  PetroleumCoke: 'PetroleumCoke',
  Plastics: 'Plastics',
  Propane: 'Propane',
  PropaneGas: 'PropaneGas',
  Propylene: 'Propylene',
  RenderedAnimalFat: 'RenderedAnimalFat',
  ResidualFuelOil5: 'ResidualFuelOil5',
  ResidualFuelOil6: 'ResidualFuelOil6',
  SolidByproducts: 'SolidByproducts',
  SpecialNaphtha: 'SpecialNaphtha',
  SteamHeat: 'SteamHeat',
  Straw: 'Straw',
  SubBituminousCoal: 'SubBituminousCoal',
  Tires: 'Tires',
  UnfinishedOils: 'UnfinishedOils',
  UsedOil: 'UsedOil',
  VegetableOil: 'VegetableOil',
  WoodAndWoodResiduals: 'WoodAndWoodResiduals',
} as const;

export type GQStationaryCombustionFuelKind =
  (typeof GQStationaryCombustionFuelKind)[keyof typeof GQStationaryCombustionFuelKind];
export type GQStringKeyValue = {
  __typename?: 'StringKeyValue';
  key: Scalars['String']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export type GQStringKeyValueInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GQSuggestionSource = {
  __typename?: 'SuggestionSource';
  pageNumber: Maybe<Scalars['Int']['output']>;
};

export const GQSuggestionSourceType = {
  AiBenchmark: 'AiBenchmark',
  AiExtraction: 'AiExtraction',
} as const;

export type GQSuggestionSourceType =
  (typeof GQSuggestionSourceType)[keyof typeof GQSuggestionSourceType];
export type GQSupplier = {
  __typename?: 'Supplier';
  cleanedFootprintVendorNames: Array<Scalars['String']['output']>;
  climateProgress: GQCompanyClimateProgress;
  company: Maybe<GQCompany>;
  customDataV2: Maybe<Scalars['JSONString']['output']>;
  disclosures: Array<GQDisclosure>;
  emissionsByCategory: Array<GQCategorizedEmissionData>;
  emissionsByMonth: Array<GQLabeledTimeseries>;
  emissionsFactors: Array<GQEmissionsFactorRecordForDashboard>;
  engagementCohortIds: Array<Scalars['ID']['output']>;
  footprintTags: Array<GQStringKeyValue>;
  footprintVendorNames: Array<Scalars['String']['output']>;
  ghgCategoryIds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  historicalEmissions: Maybe<GQSupplierHistoricalEmissions>;
  id: Scalars['ID']['output'];
  industryAverageAllocatedEmissions: Maybe<GQSupplierHistoricalEmissions>;
  isTotalSpendingPartial: Maybe<Scalars['Boolean']['output']>;
  latestCdpDisclosurePublishingYear: Maybe<Scalars['Int']['output']>;
  latestDisclosureDateTime: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  notes: Maybe<Scalars['String']['output']>;
  percentEmissions: Scalars['Float']['output'];
  rows: Array<GQSupplierRow>;
  sbtiStage: GQCompanySbtCommitmentStage;
  supplierType: GQSupplierType;
  totalKgco2e: Scalars['Float']['output'];
  totalKgco2eFromFootprint: Maybe<Scalars['Float']['output']>;
  totalSpendUsd: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use totalSpendUsd instead */
  totalSpending: Maybe<Scalars['Float']['output']>;
};

export type GQSupplierDisclosuresArgs = {
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQSupplierEmissionsByMonthArgs = {
  groupBy: GQSupplierEmissionsGroupByField;
};

export type GQSupplierChart = {
  __typename?: 'SupplierChart';
  aggregateType: Maybe<GQSupplierChartAggregateType>;
  chartType: GQSupplierChartType;
  colorScale: Maybe<Array<Scalars['String']['output']>>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  xField: Maybe<Scalars['ID']['output']>;
  yField: Maybe<Scalars['ID']['output']>;
};

export const GQSupplierChartAggregateType = {
  Count: 'Count',
  SpendSum: 'SpendSum',
  Sum: 'Sum',
} as const;

export type GQSupplierChartAggregateType =
  (typeof GQSupplierChartAggregateType)[keyof typeof GQSupplierChartAggregateType];
export type GQSupplierChartConfig = {
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
};

export type GQSupplierChartInput = {
  chartType: GQSupplierChartType;
  description?: InputMaybe<Scalars['String']['input']>;
  emissionsForecastChartConfig?: InputMaybe<GQSupplierEmissionsForecastChartConfigInput>;
  engagementFunnelChartConfig?: InputMaybe<GQSupplierEngagementFunnelChartInput>;
  metricsChartConfig?: InputMaybe<GQSupplierMetricsChartConfigInput>;
  sbtCommitmentOverTimeChartConfig?: InputMaybe<GQSupplierSbtCommitmentOverTimeChartConfigInput>;
  standardChartConfig?: InputMaybe<GQSupplierStandardChartInput>;
  tasksStatusChangeOverTimeChartConfig?: InputMaybe<GQSupplierTaskStatusChangeOverTimeChartConfigInput>;
  title: Scalars['String']['input'];
};

export const GQSupplierChartType = {
  Donut: 'Donut',
  EmissionsForecast: 'EmissionsForecast',
  EngagementFunnel: 'EngagementFunnel',
  HorizontalBar: 'HorizontalBar',
  Metrics: 'Metrics',
  SbtCommitmentOverTime: 'SbtCommitmentOverTime',
  TasksStatusChangeOverTime: 'TasksStatusChangeOverTime',
} as const;

export type GQSupplierChartType =
  (typeof GQSupplierChartType)[keyof typeof GQSupplierChartType];
export type GQSupplierContact = GQIContact & {
  __typename?: 'SupplierContact';
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  role: Maybe<Scalars['String']['output']>;
  userId: Maybe<Scalars['ID']['output']>;
};

export type GQSupplierCustomData = {
  __typename?: 'SupplierCustomData';
  companyId: Scalars['ID']['output'];
  customData: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  notes: Maybe<Scalars['String']['output']>;
  priority: Maybe<GQSupplierPriority>;
};

export type GQSupplierDetailedEmissions = {
  __typename?: 'SupplierDetailedEmissions';
  emissionsByCategory: Array<GQCategorizedEmissionData>;
  emissionsByMonth: Array<GQLabeledTimeseries>;
  emissionsFactors: Array<GQEmissionsFactorRecordForDashboard>;
  emissionsModelEmissionsFactors: Array<GQEmissionsModelEmissionsFactor>;
  id: Scalars['ID']['output'];
  rows: Array<GQSupplierRow>;
};

export type GQSupplierDetailedEmissionsEmissionsByMonthArgs = {
  groupBy: GQSupplierEmissionsGroupByField;
};

export type GQSupplierEmissionsForecastChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierEmissionsForecastChartConfig';
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  plan: Maybe<GQPlan>;
  title: Scalars['String']['output'];
};

export type GQSupplierEmissionsForecastChartConfigInput = {
  planId: Scalars['ID']['input'];
};

export const GQSupplierEmissionsGroupByField = {
  BusinessCategory: 'businessCategory',
  BusinessSubcategory: 'businessSubcategory',
} as const;

export type GQSupplierEmissionsGroupByField =
  (typeof GQSupplierEmissionsGroupByField)[keyof typeof GQSupplierEmissionsGroupByField];
export type GQSupplierEngagementFunnelChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierEngagementFunnelChartConfig';
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  funnels: Array<GQEngagementFunnel>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
};

export type GQSupplierEngagementFunnelChartInput = {
  engagementFunnels?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQSupplierHistoricalEmissions = {
  __typename?: 'SupplierHistoricalEmissions';
  expenseCategory: Maybe<Scalars['String']['output']>;
  publicUrl: Maybe<Scalars['String']['output']>;
  publishingYear: Maybe<Scalars['Int']['output']>;
  reportingYear: Scalars['Int']['output'];
  revenue: Maybe<Scalars['Float']['output']>;
  revenueCurrency: Maybe<Scalars['String']['output']>;
  revenueUsd: Maybe<Scalars['Float']['output']>;
  scope1: Maybe<Scalars['Float']['output']>;
  scope1Ratio: Maybe<Scalars['Float']['output']>;
  scope2: Maybe<Scalars['Float']['output']>;
  scope2Ratio: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
  scope3Ratio: Maybe<Scalars['Float']['output']>;
  scope301: Maybe<Scalars['Float']['output']>;
  scope302: Maybe<Scalars['Float']['output']>;
  scope303: Maybe<Scalars['Float']['output']>;
  scope304: Maybe<Scalars['Float']['output']>;
  scope305: Maybe<Scalars['Float']['output']>;
  scope306: Maybe<Scalars['Float']['output']>;
  scope307: Maybe<Scalars['Float']['output']>;
  scope308: Maybe<Scalars['Float']['output']>;
  scope309: Maybe<Scalars['Float']['output']>;
  scope310: Maybe<Scalars['Float']['output']>;
  scope311: Maybe<Scalars['Float']['output']>;
  scope312: Maybe<Scalars['Float']['output']>;
  scope313: Maybe<Scalars['Float']['output']>;
  scope314: Maybe<Scalars['Float']['output']>;
  scope315: Maybe<Scalars['Float']['output']>;
  scope316: Maybe<Scalars['Float']['output']>;
  scope317: Maybe<Scalars['Float']['output']>;
  source: GQEmissionsSource;
  surveyId: Maybe<Scalars['ID']['output']>;
  units: GQCompanyEmissionsUnits;
};

export type GQSupplierMappingRowConnection = {
  __typename?: 'SupplierMappingRowConnection';
  edges: Array<Maybe<GQSupplierMappingRowEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSupplierMappingRowEdge = {
  __typename?: 'SupplierMappingRowEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSupplierMappingTableRow>;
};

export type GQSupplierMappingTableRow = GQIdInterface & {
  __typename?: 'SupplierMappingTableRow';
  autoMatched: Maybe<GQAutoMatched>;
  autoMatches: Array<GQCompanyAutoMatch>;
  id: Scalars['ID']['output'];
  orgCompany: Maybe<GQOrgCompany>;
  orgCompanyHasSupplierSpecificEF: Maybe<Scalars['Boolean']['output']>;
  orgCompanyId: Maybe<Scalars['ID']['output']>;
  pendingCompanyCreateRequest: Maybe<GQCompanyChangeRequest>;
  ranAutoMapping: Scalars['Boolean']['output'];
  rawVendorEntity: Scalars['String']['output'];
  subRows: Array<GQSupplierMappingTableSubRow>;
  totalSpendUsd: Scalars['Float']['output'];
};

export type GQSupplierMappingTableSubRow = GQIdInterface & {
  __typename?: 'SupplierMappingTableSubRow';
  category: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  currencyQuantity: Scalars['Float']['output'];
  datasetNames: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  spendUsd: Scalars['Float']['output'];
  subCategory: Scalars['String']['output'];
};

export const GQSupplierMatchingSortOption = {
  Matched: 'matched',
  Spend: 'spend',
  Unmatched: 'unmatched',
} as const;

export type GQSupplierMatchingSortOption =
  (typeof GQSupplierMatchingSortOption)[keyof typeof GQSupplierMatchingSortOption];
export type GQSupplierMetricsChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierMetricsChartConfig';
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metricsTypes: Array<GQSupplyChainMetricType>;
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
};

export type GQSupplierMetricsChartConfigInput = {
  metricsTypes: Array<GQSupplyChainMetricType>;
};

export const GQSupplierPriority = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type GQSupplierPriority =
  (typeof GQSupplierPriority)[keyof typeof GQSupplierPriority];
export type GQSupplierRow = {
  __typename?: 'SupplierRow';
  beaCode: Maybe<Scalars['String']['output']>;
  categoryId: Scalars['String']['output'];
  company: Maybe<GQCompany>;
  convertedQuantity: Scalars['Float']['output'];
  convertedUnit: Scalars['String']['output'];
  emissionsFactor: Maybe<GQEmissionsFactorRecordForDashboard>;
  emissionsFactorId: Maybe<Scalars['String']['output']>;
  footprintTags: Array<GQStringKeyValue>;
  footprintVendorName: Maybe<Scalars['String']['output']>;
  ghgCategoryId: Maybe<Scalars['String']['output']>;
  groupingKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inputUnit: Maybe<Scalars['String']['output']>;
  kgco2eQuantity: Scalars['Float']['output'];
  month: Maybe<Scalars['YearMonth']['output']>;
  name: Scalars['String']['output'];
  rawVendorName: Maybe<Scalars['String']['output']>;
  subcategoryId: Scalars['String']['output'];
  supplierType: GQSupplierType;
};

export type GQSupplierSbtCommitmentOverTimeChartConfig =
  GQSupplierChartConfig & {
    __typename?: 'SupplierSbtCommitmentOverTimeChartConfig';
    chartType: GQSupplierChartType;
    colorScale: Array<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    orgId: Maybe<Scalars['ID']['output']>;
    title: Scalars['String']['output'];
  };

export type GQSupplierSbtCommitmentOverTimeChartConfigInput = {
  colorScale: Array<Scalars['String']['input']>;
};

export type GQSupplierScoreCriteria = {
  __typename?: 'SupplierScoreCriteria';
  field: Scalars['String']['output'];
  filters: Array<GQBiQueryFilter>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  supplierScoreId: Scalars['String']['output'];
  weight: Scalars['Int']['output'];
};

export type GQSupplierScorecard = {
  __typename?: 'SupplierScorecard';
  criteria: Array<GQSupplierScoreCriteria>;
  field: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQSupplierStandardChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierStandardChartConfig';
  aggregateType: GQSupplierChartAggregateType;
  chartType: GQSupplierChartType;
  colorScale: Array<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  xField: Scalars['ID']['output'];
  yField: Maybe<Scalars['ID']['output']>;
};

export type GQSupplierStandardChartInput = {
  aggregateType: GQSupplierChartAggregateType;
  colorScale: Array<Scalars['String']['input']>;
  xField: Scalars['ID']['input'];
  yField?: InputMaybe<Scalars['ID']['input']>;
};

export type GQSupplierTable = {
  __typename?: 'SupplierTable';
  columns: Array<GQSupplierTableColumn>;
};

export type GQSupplierTableColumn = {
  __typename?: 'SupplierTableColumn';
  baseEngagementTaskConfigId: Maybe<Scalars['ID']['output']>;
  columnFormat: Maybe<GQSupplierTableColumnFormat>;
  csatTagName: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  field: Scalars['String']['output'];
  footprintTagName: Maybe<Scalars['String']['output']>;
  headerName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSupplierScore: Scalars['Boolean']['output'];
  isSupplierScoreCriteria: Scalars['Boolean']['output'];
  questionKey: Maybe<Scalars['String']['output']>;
  selectOptions: Maybe<Array<Scalars['String']['output']>>;
  visible: Maybe<Scalars['Boolean']['output']>;
};

export const GQSupplierTableColumnFormat = {
  CurrencyUsdCents: 'CurrencyUsdCents',
  Date: 'Date',
  FootprintTag: 'FootprintTag',
  Integer: 'Integer',
  Number: 'Number',
  Percentage: 'Percentage',
  SingleSelect: 'SingleSelect',
  String: 'String',
} as const;

export type GQSupplierTableColumnFormat =
  (typeof GQSupplierTableColumnFormat)[keyof typeof GQSupplierTableColumnFormat];
export type GQSupplierTaskStatusChangeOverTimeChartConfig =
  GQSupplierChartConfig & {
    __typename?: 'SupplierTaskStatusChangeOverTimeChartConfig';
    chartType: GQSupplierChartType;
    colorScale: Array<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isCumulative: Maybe<Scalars['Boolean']['output']>;
    orgId: Maybe<Scalars['ID']['output']>;
    tasksStatusChangeConfig: Maybe<Array<GQSurveyDefinitionAndStatus>>;
    title: Scalars['String']['output'];
  };

export type GQSupplierTaskStatusChangeOverTimeChartConfigInput = {
  colorScale: Array<Scalars['String']['input']>;
  isCumulative?: InputMaybe<Scalars['Boolean']['input']>;
  tasksStatusChangeConfig?: InputMaybe<Scalars['JSONString']['input']>;
};

export const GQSupplierType = {
  KnownSupplier: 'KnownSupplier',
  NonFootprintSupplier: 'NonFootprintSupplier',
  SandboxCompany: 'SandboxCompany',
  SmallSuppliersAggregated: 'SmallSuppliersAggregated',
  UnknownSuppliersAggregated: 'UnknownSuppliersAggregated',
} as const;

export type GQSupplierType =
  (typeof GQSupplierType)[keyof typeof GQSupplierType];
export type GQSupplierView = GQIdInterface & {
  __typename?: 'SupplierView';
  chartOrder: Maybe<Array<GQSupplierViewChartOrder>>;
  chartsV2: Array<
    | GQSupplierEmissionsForecastChartConfig
    | GQSupplierEngagementFunnelChartConfig
    | GQSupplierMetricsChartConfig
    | GQSupplierSbtCommitmentOverTimeChartConfig
    | GQSupplierStandardChartConfig
    | GQSupplierTaskStatusChangeOverTimeChartConfig
  >;
  columnOrder: Maybe<Array<GQSupplierViewColumnOrder>>;
  filters: Maybe<GQFilterExpressionGroupWithNewFilters>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  reductionPlan: Maybe<GQPlan>;
  table: GQSupplierTable;
};

export type GQSupplierViewChartOrder = {
  __typename?: 'SupplierViewChartOrder';
  chartId: Scalars['ID']['output'];
  visible: Scalars['Boolean']['output'];
};

export type GQSupplierViewColumnOrder = {
  __typename?: 'SupplierViewColumnOrder';
  columnId: Scalars['ID']['output'];
  visible: Scalars['Boolean']['output'];
  width: Scalars['Int']['output'];
};

export type GQSuppliersData = {
  __typename?: 'SuppliersData';
  data: Scalars['UntypedData']['output'];
  id: Scalars['String']['output'];
};

export type GQSuppliersSettings = {
  __typename?: 'SuppliersSettings';
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintSnapshotIds: Array<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  includedGhgCategories: Array<Scalars['String']['output']>;
  suppressNotifications: Scalars['Boolean']['output'];
  viewLatestSupplierMapping: Scalars['Boolean']['output'];
};

export const GQSupplyChainMetricType = {
  ForecastedSuppliersReductionInTargetYearKgco2e:
    'ForecastedSuppliersReductionInTargetYearKgco2e',
  ForecastedSuppliersReductionInTargetYearKgco2eFromInitiatives:
    'ForecastedSuppliersReductionInTargetYearKgco2eFromInitiatives',
  ForecastedSupplyChainSavingsInTargetYear:
    'ForecastedSupplyChainSavingsInTargetYear',
  ForecastedSupplyChainSavingsInTargetYearFromInitiatives:
    'ForecastedSupplyChainSavingsInTargetYearFromInitiatives',
  SuppliersCommitedToSbti: 'SuppliersCommitedToSbti',
  SurveysApproved: 'SurveysApproved',
  SurveysSent: 'SurveysSent',
  SurveysSubmitted: 'SurveysSubmitted',
  TotalSupplyChainKgco2e: 'TotalSupplyChainKgco2e',
} as const;

export type GQSupplyChainMetricType =
  (typeof GQSupplyChainMetricType)[keyof typeof GQSupplyChainMetricType];
export type GQSupportCase = {
  __typename?: 'SupportCase';
  createdAt: Scalars['DateTime']['output'];
  currentAssignee: Maybe<Scalars['String']['output']>;
  currentUserIsSubcribed: Maybe<Scalars['Boolean']['output']>;
  description: Maybe<Scalars['String']['output']>;
  hasUnreadNotifications: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  issueType: Maybe<GQSupportCaseIssueType>;
  lastUpdatedBy: Scalars['String']['output'];
  /** @deprecated Use relatedItem instead */
  objectId: Maybe<Scalars['String']['output']>;
  priority: GQSupportCasePriority;
  productUrl: Maybe<Scalars['String']['output']>;
  relatedItem: Maybe<GQSupportCaseRelatedItem>;
  status: GQSupportCaseStatus;
  subject: Scalars['String']['output'];
  timeline: GQAuditLogConnection;
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
};

export type GQSupportCaseTimelineArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQSupportCaseAttachmentForComment = {
  fileMetadataId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
};

export type GQSupportCaseAttachmentInput = {
  fileName: Scalars['String']['input'];
  sizeBytes: Scalars['Int']['input'];
};

export type GQSupportCaseAttachments = {
  __typename?: 'SupportCaseAttachments';
  fileMetadataId: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export const GQSupportCaseIssueType = {
  Ceda: 'Ceda',
  DataUpload: 'DataUpload',
  DatasetGuidance: 'DatasetGuidance',
  Finance: 'Finance',
  FinancedEmissions: 'FinancedEmissions',
  Footprint: 'Footprint',
  GeneralPlatform: 'GeneralPlatform',
  GettingStarted: 'GettingStarted',
  Marketplace: 'Marketplace',
  Measurement: 'Measurement',
  Measurements: 'Measurements',
  Methodology: 'Methodology',
  PhysicalProducts: 'PhysicalProducts',
  ReductionsAndTargets: 'ReductionsAndTargets',
  Reporting: 'Reporting',
  ReportingAndRegulations: 'ReportingAndRegulations',
  SupplyChain: 'SupplyChain',
} as const;

export type GQSupportCaseIssueType =
  (typeof GQSupportCaseIssueType)[keyof typeof GQSupportCaseIssueType];
export const GQSupportCasePriority = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type GQSupportCasePriority =
  (typeof GQSupportCasePriority)[keyof typeof GQSupportCasePriority];
export type GQSupportCaseRelatedItem = {
  __typename?: 'SupportCaseRelatedItem';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export const GQSupportCaseStatus = {
  Complete: 'Complete',
  OnHoldPendingWatershedAction: 'OnHoldPendingWatershedAction',
  PendingCustomerReply: 'PendingCustomerReply',
  PendingWatershedReply: 'PendingWatershedReply',
} as const;

export type GQSupportCaseStatus =
  (typeof GQSupportCaseStatus)[keyof typeof GQSupportCaseStatus];
export type GQSupportingDocument = GQIdInterface & {
  __typename?: 'SupportingDocument';
  buildingUtilityId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  file: GQFileMetadata;
  id: Scalars['ID']['output'];
  utilityType: Maybe<GQBuildingUtilityType>;
};

export type GQSurveyDefinitionAndStatus = {
  __typename?: 'SurveyDefinitionAndStatus';
  engagementTaskConfigId: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export const GQSurveyDefinitionCategory = {
  DataCollection: 'DataCollection',
  Learning: 'Learning',
  MakeCommitments: 'MakeCommitments',
  Other: 'Other',
  ReductionProjects: 'ReductionProjects',
  ScienceBasedTargets: 'ScienceBasedTargets',
} as const;

export type GQSurveyDefinitionCategory =
  (typeof GQSurveyDefinitionCategory)[keyof typeof GQSurveyDefinitionCategory];
export type GQSwapEmissionsModelExpectationsForFootprintTestRowsInput = {
  footprintTestSuiteConfigId: Scalars['ID']['input'];
  footprintTestSuiteExecutionId: Scalars['ID']['input'];
  oldToNewEmissionsModelIds: Scalars['JSONString']['input'];
};

export type GQSwapEmissionsModelExpectationsForFootprintTestRowsPayload = {
  __typename?: 'SwapEmissionsModelExpectationsForFootprintTestRowsPayload';
  ids: Array<Scalars['ID']['output']>;
};

export type GQSwapEmissionsModelExpectationsForMeasurementTestRowsInput = {
  measurementTestRowIds: Array<Scalars['ID']['input']>;
  measurementTestSuiteId: Scalars['ID']['input'];
  oldToNewEmissionsModelIds: Scalars['JSONString']['input'];
};

export type GQSwapEmissionsModelExpectationsForMeasurementTestRowsPayload = {
  __typename?: 'SwapEmissionsModelExpectationsForMeasurementTestRowsPayload';
  ids: Array<Scalars['ID']['output']>;
};

export type GQSyncFeatureFlagsInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQSyncFeatureFlagsPayload = {
  __typename?: 'SyncFeatureFlagsPayload';
  diff: GQFeatureFlagsDiff;
  featureFlags: Array<GQFeatureFlag>;
};

export type GQSyncMeasurementMappingsInput = {
  create?: InputMaybe<Array<GQNewMeasurementMappingInput>>;
  delete?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgId: Scalars['ID']['input'];
  update?: InputMaybe<Array<GQUpdateMeasurementMappingInput>>;
};

export type GQSyncMeasurementMappingsPayload = {
  __typename?: 'SyncMeasurementMappingsPayload';
  jobId: Maybe<Scalars['String']['output']>;
  mappings: Array<GQMeasurementMapping>;
};

export const GQTableCellAlignment = {
  Center: 'center',
  Left: 'left',
  Right: 'right',
} as const;

export type GQTableCellAlignment =
  (typeof GQTableCellAlignment)[keyof typeof GQTableCellAlignment];
export type GQTablePointer = {
  __typename?: 'TablePointer';
  ids: Array<Scalars['String']['output']>;
  kind: Scalars['String']['output'];
};

export type GQTabularData = {
  __typename?: 'TabularData';
  cells: Array<Array<Scalars['JSONString']['output']>>;
};

export type GQTabularDataInput = {
  cells: Array<Array<Scalars['JSONString']['input']>>;
};

export type GQTarget = GQIdInterface & {
  __typename?: 'Target';
  actualAmount: Maybe<Scalars['Int']['output']>;
  descriptor: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  kind: GQTargetKind;
  startDate: Scalars['Date']['output'];
  targetAmount: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  unit: GQTargetUnit;
};

export type GQTargetConnection = {
  __typename?: 'TargetConnection';
  edges: Array<Maybe<GQTargetEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Target' and its cursor. */
export type GQTargetEdge = {
  __typename?: 'TargetEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQTarget>;
};

export type GQTargetInput = {
  descriptor?: InputMaybe<Scalars['String']['input']>;
  kind: GQTargetKind;
  targetAmount: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  unit: GQTargetUnit;
};

export const GQTargetKind = {
  AvoidedEmissions: 'AvoidedEmissions',
  EmissionsReduction: 'EmissionsReduction',
  NetZero: 'NetZero',
  Offsets: 'Offsets',
} as const;

export type GQTargetKind = (typeof GQTargetKind)[keyof typeof GQTargetKind];
export const GQTargetReductionRate = {
  Custom: 'Custom',
  LinearAnnualReduction: 'LinearAnnualReduction',
  YearOverYear: 'YearOverYear',
} as const;

export type GQTargetReductionRate =
  (typeof GQTargetReductionRate)[keyof typeof GQTargetReductionRate];
export const GQTargetUnit = {
  Percent: 'Percent',
  PercentAbsolute: 'PercentAbsolute',
  Tco2E: 'TCO2E',
  Year: 'Year',
} as const;

export type GQTargetUnit = (typeof GQTargetUnit)[keyof typeof GQTargetUnit];
export type GQTaskStatusDump = {
  __typename?: 'TaskStatusDump';
  assigneeNames: Maybe<Array<Scalars['String']['output']>>;
  datasetName: Maybe<Scalars['String']['output']>;
  status: GQTaskWatershedProcessStateSimplified;
  taskId: Scalars['ID']['output'];
  taskName: Scalars['String']['output'];
};

export const GQTaskUploadState = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  NotStarted: 'NotStarted',
} as const;

export type GQTaskUploadState =
  (typeof GQTaskUploadState)[keyof typeof GQTaskUploadState];
export const GQTaskWatershedProcessState = {
  Blocked: 'Blocked',
  Completed: 'Completed',
  InProgress: 'InProgress',
  NotStarted: 'NotStarted',
  ReviewRequired: 'ReviewRequired',
} as const;

export type GQTaskWatershedProcessState =
  (typeof GQTaskWatershedProcessState)[keyof typeof GQTaskWatershedProcessState];
export const GQTaskWatershedProcessStateSimplified = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  NeedsApproval: 'NeedsApproval',
  None: 'None',
  NotStarted: 'NotStarted',
} as const;

export type GQTaskWatershedProcessStateSimplified =
  (typeof GQTaskWatershedProcessStateSimplified)[keyof typeof GQTaskWatershedProcessStateSimplified];
export type GQTcfdArchetypeOpportunity = GQIdInterface &
  GQITcfdOpportunity & {
    __typename?: 'TcfdArchetypeOpportunity';
    archetypeRiskId: Maybe<Scalars['ID']['output']>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    geographies: Array<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    sectors: Array<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    userDescription: Scalars['String']['output'];
  };

export type GQTcfdArchetypeRisk = GQIdInterface &
  GQITcfdRisk & {
    __typename?: 'TcfdArchetypeRisk';
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    geographies: Array<Scalars['String']['output']>;
    highTrajectoryImpact: GQTcfdRiskImpact;
    id: Scalars['ID']['output'];
    kind: GQTcfdRiskKind;
    lowTrajectoryImpact: GQTcfdRiskImpact;
    mediumTrajectoryImpact: GQTcfdRiskImpact;
    physicalRiskSeverity: Maybe<GQTcfdPhysicalRiskSeverity>;
    possibleImpactDescription: Maybe<Scalars['String']['output']>;
    sectors: Array<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    userDescription: Scalars['String']['output'];
    whyWeChoseThisMd: Scalars['String']['output'];
  };

export const GQTcfdPhysicalRiskSeverity = {
  Acute: 'Acute',
  Chronic: 'Chronic',
} as const;

export type GQTcfdPhysicalRiskSeverity =
  (typeof GQTcfdPhysicalRiskSeverity)[keyof typeof GQTcfdPhysicalRiskSeverity];
export const GQTcfdRiskImpact = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type GQTcfdRiskImpact =
  (typeof GQTcfdRiskImpact)[keyof typeof GQTcfdRiskImpact];
export const GQTcfdRiskKind = {
  Market: 'Market',
  Physical: 'Physical',
  PolicyAndLegal: 'PolicyAndLegal',
  Reputation: 'Reputation',
  Technology: 'Technology',
} as const;

export type GQTcfdRiskKind =
  (typeof GQTcfdRiskKind)[keyof typeof GQTcfdRiskKind];
export const GQTcfdStrategyModuleDistributionModel = {
  B2B: 'B2B',
  B2BAndB2C: 'B2BAndB2C',
  B2C: 'B2C',
} as const;

export type GQTcfdStrategyModuleDistributionModel =
  (typeof GQTcfdStrategyModuleDistributionModel)[keyof typeof GQTcfdStrategyModuleDistributionModel];
export const GQTeam = {
  Calcprint: 'Calcprint',
  CopyDemoData: 'CopyDemoData',
  DataInfrastructure: 'DataInfrastructure',
  DataIngestion: 'DataIngestion',
  DataScience: 'DataScience',
  DatasetsAndObjects: 'DatasetsAndObjects',
  DeveloperExperience: 'DeveloperExperience',
  Engineering: 'Engineering',
  EnterpriseFoundations: 'EnterpriseFoundations',
  Infrastructure: 'Infrastructure',
  Methodology: 'Methodology',
  ProductionGraph: 'ProductionGraph',
  Reductions: 'Reductions',
  Reporting: 'Reporting',
  SupplyChain: 'SupplyChain',
  TestTeam: 'TestTeam',
  Unowned: 'Unowned',
  WinCsrd: 'WinCsrd',
  WinFinance: 'WinFinance',
} as const;

export type GQTeam = (typeof GQTeam)[keyof typeof GQTeam];
export type GQTestOrgCompanyDuckDbPerformanceInput = {
  orgId: Scalars['String']['input'];
  usingPostgresConnection: Scalars['Boolean']['input'];
};

export type GQThrowErrorInput = {
  errorCode?: InputMaybe<Scalars['String']['input']>;
  fooString?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type GQThrowErrorPayload = {
  __typename?: 'ThrowErrorPayload';
  message: Maybe<Scalars['String']['output']>;
};

export type GQTimeseries = {
  __typename?: 'Timeseries';
  base: Scalars['YearMonth']['output'];
  frequency: GQTimeseriesFrequency;
  values: Array<Scalars['Float']['output']>;
};

export const GQTimeseriesFrequency = {
  Monthly: 'Monthly',
  Yearly: 'Yearly',
} as const;

export type GQTimeseriesFrequency =
  (typeof GQTimeseriesFrequency)[keyof typeof GQTimeseriesFrequency];
export type GQToggleCliqMutabilityForEmissionsModelInput = {
  stableId: Scalars['ID']['input'];
};

export type GQToggleCliqMutabilityForEmissionsModelPayload = {
  __typename?: 'ToggleCliqMutabilityForEmissionsModelPayload';
  emissionsModelStable: GQEmissionsModelStable;
};

export type GQToggleCliqSyncForReferenceDataSourceInput = {
  referenceDataSourceId: Scalars['ID']['input'];
};

export type GQToggleCliqSyncForReferenceDataSourcePayload = {
  __typename?: 'ToggleCliqSyncForReferenceDataSourcePayload';
  cliqReferenceDataConnection: GQCliqReferenceDataConnection;
};

export type GQTraceBartValueDetails = {
  __typename?: 'TraceBartValueDetails';
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  pointer: Scalars['String']['output'];
};

export type GQTraceDetails = {
  __typename?: 'TraceDetails';
  bartValueDetails: Array<Scalars['UntypedData']['output']>;
  emissionsModelEvaluationId: Maybe<Scalars['String']['output']>;
  emissionsModelVariableDetails: Array<Scalars['UntypedData']['output']>;
  emissionsModelVersionId: Maybe<Scalars['String']['output']>;
  referenceValueDetails: Array<Scalars['UntypedData']['output']>;
};

export type GQTraceEmissionsModelVariableDetails = {
  __typename?: 'TraceEmissionsModelVariableDetails';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  pointer: Scalars['String']['output'];
};

export type GQTraceReferenceValueCitationDetails = {
  __typename?: 'TraceReferenceValueCitationDetails';
  displayName: Maybe<Scalars['String']['output']>;
  isCustomerProvided: Maybe<Scalars['Boolean']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  org: GQTraceReferenceValueCitationDetailsOrg;
  publicDisclosureId: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type GQTraceReferenceValueCitationDetailsOrg = {
  __typename?: 'TraceReferenceValueCitationDetailsOrg';
  orgId: Maybe<Scalars['String']['output']>;
  orgName: Maybe<Scalars['String']['output']>;
};

export type GQTraceReferenceValueDetails = {
  __typename?: 'TraceReferenceValueDetails';
  citation: GQTraceReferenceValueCitationDetails;
  description: Maybe<Scalars['String']['output']>;
  pointer: Scalars['String']['output'];
};

/**
 * #####################################################
 * Marketplace
 * #####################################################
 */
export type GQTrackedEditor = GQUser | GQWatershedEmployee;

export type GQTransformRun = {
  __typename?: 'TransformRun';
  additionalOutput: Maybe<Scalars['JSONString']['output']>;
  endedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  parameters: Maybe<Scalars['JSONString']['output']>;
  startedAt: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  tables: Array<GQTransformRunTable>;
  transformVersionId: Scalars['String']['output'];
};

export type GQTransformRunGraph = {
  __typename?: 'TransformRunGraph';
  orderedNodes: Array<Scalars['String']['output']>;
  transformRuns: Array<GQTransformRun>;
};

export type GQTransformRunTable = {
  __typename?: 'TransformRunTable';
  name: Scalars['String']['output'];
  pointer: GQTablePointer;
  relationship: GQTransformRunTableRelationship;
};

export const GQTransformRunTableRelationship = {
  Input: 'Input',
  Output: 'Output',
} as const;

export type GQTransformRunTableRelationship =
  (typeof GQTransformRunTableRelationship)[keyof typeof GQTransformRunTableRelationship];
export type GQUnassociateIntegrationConnectionFromDatasourceInput = {
  datasourceId: Scalars['ID']['input'];
  integrationConnectionId: Scalars['ID']['input'];
};

export type GQUnassociateIntegrationConnectionFromDatasourcePayload = {
  __typename?: 'UnassociateIntegrationConnectionFromDatasourcePayload';
  integrationConnection: GQIntegrationConnection;
};

export type GQUnboundParameterBinding = {
  expression: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GQUncompleteMeasurementProjectInput = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQUncompleteMeasurementProjectPayload = {
  __typename?: 'UncompleteMeasurementProjectPayload';
  measurementProject: GQMeasurementProject;
};

export type GQUndeleteMeasurementTestSuitePayload = {
  __typename?: 'UndeleteMeasurementTestSuitePayload';
  id: Scalars['ID']['output'];
};

export type GQUndoFacilitiesApprovalsMigrationDeletedTask = {
  __typename?: 'UndoFacilitiesApprovalsMigrationDeletedTask';
  assigneeIds: Array<Scalars['String']['output']>;
  buildingIds: Array<Scalars['String']['output']>;
  fmdIds: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type GQUndoFacilitiesApprovalsMigrationInput = {
  dryRun: Scalars['Boolean']['input'];
  earliestMigratedDate?: InputMaybe<Scalars['Date']['input']>;
  maxOutputListLength?: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['String']['input'];
};

export type GQUndoFacilitiesApprovalsMigrationPayload = {
  __typename?: 'UndoFacilitiesApprovalsMigrationPayload';
  buildingUtilityIds: Array<Scalars['String']['output']>;
  measurementProjectIds: Array<Scalars['String']['output']>;
  mergedTasks: Array<GQUndoFacilitiesApprovalsMigrationUnmergedTask>;
  restoredTargetTaskIds: Array<Scalars['String']['output']>;
  userUploadIds: Array<Scalars['String']['output']>;
  userUploadTaskIds: Array<Scalars['String']['output']>;
  userUploadedTableIds: Array<Scalars['String']['output']>;
};

export type GQUndoFacilitiesApprovalsMigrationUnmergedTask = {
  __typename?: 'UndoFacilitiesApprovalsMigrationUnmergedTask';
  assigneeIds: Array<Scalars['ID']['output']>;
  buildingIds: Array<Scalars['String']['output']>;
  deletedTasks: Array<GQUndoFacilitiesApprovalsMigrationDeletedTask>;
  state: Scalars['String']['output'];
  targetTaskId: Scalars['ID']['output'];
};

export type GQUnlinkGlobalFootprintTagsFromReleaseInput = {
  globalFootprintTagIds: Array<Scalars['ID']['input']>;
  releaseId: Scalars['ID']['input'];
};

export type GQUnpublishActivityDataTableInput = {
  adtId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQUnpublishActivityDataTablePayload = {
  __typename?: 'UnpublishActivityDataTablePayload';
  workflowId: Scalars['String']['output'];
};

export type GQUnpublishCtsVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQUnpublishCtsVersionPayload = {
  __typename?: 'UnpublishCtsVersionPayload';
  ctsVersion: GQCustomerTargetSchemaVersion;
  customerTargetSchema: GQCustomerTargetSchema;
};

export type GQUpdateActivityDataTableInput = {
  activityDataTableId: Scalars['ID']['input'];
  /** @deprecated Field no longer supported */
  datasourceId?: InputMaybe<Scalars['ID']['input']>;
  datasourceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  filterEndYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  filterStartYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primaryDatasourceId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateActivityDataTablePayload = {
  __typename?: 'UpdateActivityDataTablePayload';
  activityDataTable: GQActivityDataTable;
};

export type GQUpdateAssetCommentInput = {
  commentText: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type GQUpdateAssetCommentPayload = {
  __typename?: 'UpdateAssetCommentPayload';
  assetComment: GQAssetComment;
};

export type GQUpdateAssetCorporateInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  companyBea?: InputMaybe<Scalars['String']['input']>;
  companyDunsNumber?: InputMaybe<Scalars['String']['input']>;
  companyEin?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companyIsin?: InputMaybe<Scalars['String']['input']>;
  companyLei?: InputMaybe<Scalars['String']['input']>;
  companyLocalRegistryId?: InputMaybe<Scalars['String']['input']>;
  companySAndPId?: InputMaybe<Scalars['String']['input']>;
  companyTicker?: InputMaybe<Scalars['String']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  companyVatId?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2?: InputMaybe<GQCountryAlpha2>;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetCorporatePayload = {
  __typename?: 'UpdateAssetCorporatePayload';
  asset: Maybe<GQAssetCorporate>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetGroupInput = {
  action: GQFinanceAuditAction;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetGroupPayload = {
  __typename?: 'UpdateAssetGroupPayload';
  asset: Maybe<GQAssetGroup>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetHoldingNewInput = {
  action: GQFinanceAuditAction;
  assetClass?: InputMaybe<GQFundAssetClass>;
  attributionFactorOverride?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  fundId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  outstandingAmountNative?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ1?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ2?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ3?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ4?: InputMaybe<Scalars['Float']['input']>;
  premiumNative?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  yearOfInitialInvestment?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateAssetHoldingPayload = {
  __typename?: 'UpdateAssetHoldingPayload';
  assetHolding: GQFinanceAssetHolding;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetPersonalMotorVehicleInsuranceInput = {
  action: GQFinanceAuditAction;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetPersonalMotorVehicleInsurancePayload = {
  __typename?: 'UpdateAssetPersonalMotorVehicleInsurancePayload';
  asset: Maybe<GQAssetPersonalMotorVehicleInsurance>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetRealEstateInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  buildingKind?: InputMaybe<Scalars['String']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2?: InputMaybe<GQCountryAlpha2>;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetRealEstatePayload = {
  __typename?: 'UpdateAssetRealEstatePayload';
  asset: Maybe<GQAssetRealEstate>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetSovereignBondInput = {
  action: GQFinanceAuditAction;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sovereignEntity?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetSovereignBondPayload = {
  __typename?: 'UpdateAssetSovereignBondPayload';
  asset: Maybe<GQAssetSovereignBond>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetYear = {
  companyValueUsdCents?: InputMaybe<Scalars['Int53']['input']>;
  id: Scalars['ID']['input'];
};

export type GQUpdateAssetYearNewInput = {
  action: GQFinanceAuditAction;
  assetCommercialRealEstateId?: InputMaybe<Scalars['ID']['input']>;
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  assetPersonalMotorVehicleInsuranceId?: InputMaybe<Scalars['ID']['input']>;
  assetSovereignBondId?: InputMaybe<Scalars['ID']['input']>;
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  cloudSpendNative?: InputMaybe<Scalars['Float']['input']>;
  committedToNearTermSbtAlignedNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  demonstratingYoyEmissionsInLineWithNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  emissionsYearId?: InputMaybe<Scalars['ID']['input']>;
  estimationMethodology?: InputMaybe<GQEstimationMethodology>;
  flightMiles?: InputMaybe<Scalars['Int']['input']>;
  headcount?: InputMaybe<Scalars['Int']['input']>;
  iciAlignmentOverride?: InputMaybe<GQIciAlignmentStatus>;
  id: Scalars['ID']['input'];
  initiatedDecarbonizationPlan?: InputMaybe<Scalars['Boolean']['input']>;
  measuringMaterialScope3Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  measuringScope1And2Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  percentEmployeesWfh?: InputMaybe<Scalars['Float']['input']>;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  revenueNative?: InputMaybe<Scalars['Float']['input']>;
  revenueSourceEmissionsYearId?: InputMaybe<Scalars['ID']['input']>;
  scenario?: InputMaybe<Scalars['String']['input']>;
  scope1OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope3OverrideKind?: InputMaybe<GQScope3OverrideKind>;
  scope3OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope3OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope301OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope302OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope303OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope304OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope305OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope306OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope307OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope308OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope309OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope310OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope311OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope312OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope313OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope314OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope315OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  totalMwh?: InputMaybe<Scalars['Float']['input']>;
  valueNative?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateAssetYearNewPayload = {
  __typename?: 'UpdateAssetYearNewPayload';
  assetYear: GQFinanceAssetYearNew;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetYearRevenueSourceInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assetYearId: Scalars['ID']['input'];
  revenueSourceEmissionsYearId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateAssetYearRevenueSourcePayload = {
  __typename?: 'UpdateAssetYearRevenueSourcePayload';
  assetYearId: Scalars['ID']['output'];
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetYearsNewInput = {
  action: GQFinanceAuditAction;
  assetYears: Array<GQUpdateAssetYearNewInput>;
  fileInfo?: InputMaybe<GQFinanceAuditFileInfoInput>;
  /** @deprecated No longer needed */
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateAssetYearsNewPayload = {
  __typename?: 'UpdateAssetYearsNewPayload';
  assetYears: Array<GQFinanceAssetYearNew>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateCalculationTagInput = {
  newName: Scalars['String']['input'];
  oldName: Scalars['String']['input'];
};

export type GQUpdateCalculationTagPayload = {
  __typename?: 'UpdateCalculationTagPayload';
  success: Scalars['Boolean']['output'];
};

export type GQUpdateCanonicalClimateProgramProjectInput = {
  commitmentType?: InputMaybe<Scalars['String']['input']>;
  costEstimate?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  hiddenFromMeasurement?: InputMaybe<Scalars['Boolean']['input']>;
  iconTypeOverride?: InputMaybe<GQClimateProgramProjectIconType>;
  id: Scalars['ID']['input'];
  kind?: InputMaybe<GQClimateProgramProjectKind>;
  matchObjectType?: InputMaybe<Scalars['String']['input']>;
  measurementObjectCopy?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportKind?: InputMaybe<Scalars['String']['input']>;
  routeType?: InputMaybe<Scalars['String']['input']>;
  timeEstimate?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateCanonicalClimateProgramProjectRequirementDatasetInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  canonicalDatasetId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type GQUpdateCanonicalClimateProgramProjectRequirementGhgpInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  ghgScope: Scalars['Int']['input'];
  ghgScope3Category?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  minimumFidelity: GQClimateProgramProjectRequirementFidelity;
};

export type GQUpdateCanonicalDatasetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  selfServeDisabledReason?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateCanonicalDatasourceInput = {
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateCommentInput = {
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export type GQUpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  comment: GQComment;
};

export type GQUpdateCompanyClimateCommitmentInput = {
  carbonNeutralCommitment?: InputMaybe<GQCarbonNeutralCommitmentInput>;
  cleanEnergyCommitment?: InputMaybe<GQCleanEnergyCommitmentInput>;
  commitmentMadeDate?: InputMaybe<Scalars['Date']['input']>;
  commitmentPeriodEnd?: InputMaybe<Scalars['Date']['input']>;
  commitmentPeriodStart?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  kind: GQCompanyClimateCommitmentKind;
  netZeroCommitment?: InputMaybe<GQNetZeroCommitmentInput>;
  publicUrl?: InputMaybe<Scalars['String']['input']>;
  sbtCommitment?: InputMaybe<GQSbtCommitmentInput>;
};

export type GQUpdateCompanySourceKeyInput = {
  id: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  sourceKey: Scalars['String']['input'];
  sourceNotes?: InputMaybe<Scalars['String']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  userVisibleSourceNotes?: InputMaybe<Scalars['String']['input']>;
  userVisibleSourceUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateCtsInput = {
  canonicalDatasetIds: Array<Scalars['ID']['input']>;
  ctsId: Scalars['ID']['input'];
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rank: Scalars['Int']['input'];
};

export type GQUpdateCtsPayload = {
  __typename?: 'UpdateCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQUpdateCtsTransformTestCaseInput = {
  ctsTransformTestCaseId: Scalars['ID']['input'];
  inputJson: Scalars['JSONString']['input'];
  name: Scalars['String']['input'];
};

export type GQUpdateCtsTransformTestCasePayload = {
  __typename?: 'UpdateCtsTransformTestCasePayload';
  ctsTransformTestCase: GQCustomerTargetSchemaTransformTestCase;
};

export type GQUpdateDataIssueInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  shouldCcClimateLeads?: InputMaybe<Scalars['Boolean']['input']>;
  shouldPublish?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<GQDataIssueState>;
  tags?: InputMaybe<Array<GQDataIssueTag>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateDataIssuePayload = {
  __typename?: 'UpdateDataIssuePayload';
  issue: GQDataIssue;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQUpdateDataIssuesPayload = {
  __typename?: 'UpdateDataIssuesPayload';
  payload: Maybe<Array<Maybe<GQUpdateDataIssuePayload>>>;
};

export type GQUpdateDataRegistryBatchMigratorRunInput = {
  batchMigratorRunId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
};

export type GQUpdateDatasetInput = {
  canonicalDatasetId?: InputMaybe<Scalars['ID']['input']>;
  customExplainer?: InputMaybe<Scalars['String']['input']>;
  customName?: InputMaybe<Scalars['String']['input']>;
  customPotentialOverlaps?: InputMaybe<Scalars['String']['input']>;
  customUsedFor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
};

export type GQUpdateDatasourceAdminInput = {
  canonicalDatasourceId?: InputMaybe<Scalars['String']['input']>;
  customerTargetSchemaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  datasetId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<GQIngestionInstructionsInput>;
  isUserConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notesFromWatershedMd?: InputMaybe<Scalars['String']['input']>;
  refreshCadence?: InputMaybe<GQDatasetCadence>;
};

export type GQUpdateEacPriceEstimateInput = {
  alternativeCountries: Array<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  defaultProjectId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  minCreditPurchase: Scalars['Int']['input'];
  priceEstimateUsdCents: Scalars['Int']['input'];
  standard: Scalars['String']['input'];
};

export type GQUpdateEacPriceEstimatePayload = {
  __typename?: 'UpdateEacPriceEstimatePayload';
  eacPriceEstimate: GQEacPriceEstimate;
  id: Scalars['ID']['output'];
};

export type GQUpdateEacPriceEstimatesInput = {
  eacPriceEstimates: Array<GQUpdateEacPriceEstimateInput>;
};

export type GQUpdateEacPriceEstimatesPayload = {
  __typename?: 'UpdateEacPriceEstimatesPayload';
  eacPriceEstimates: Array<GQEacPriceEstimate>;
  ids: Array<Scalars['ID']['output']>;
};

export type GQUpdateEmissionsModelReleaseLifecyclePhaseInput = {
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  releaseId: Scalars['ID']['input'];
};

export type GQUpdateEmissionsModelReleaseMetadataInput = {
  displayName: Scalars['String']['input'];
  externalNotes: Scalars['String']['input'];
  internalNotes: Scalars['String']['input'];
  releaseId: Scalars['ID']['input'];
};

export type GQUpdateEmissionsModelStableInput = {
  description: Scalars['String']['input'];
  emissionsModelStableId: Scalars['ID']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
  tagNames?: InputMaybe<Array<Scalars['String']['input']>>;
  title: Scalars['String']['input'];
};

export type GQUpdateEmissionsModelStablePayload = {
  __typename?: 'UpdateEmissionsModelStablePayload';
  emissionsModelStable: GQEmissionsModelStable;
};

export type GQUpdateFileMetadataPermissionDelegatesInput = {
  dryRun: Scalars['Boolean']['input'];
  orgIds: Array<Scalars['String']['input']>;
};

export type GQUpdateFileMetadataPermissionDelegatesPayload = {
  __typename?: 'UpdateFileMetadataPermissionDelegatesPayload';
  updatedFileMetadatas: Array<GQUpdatedFileMetadataPermissionDelegate>;
};

export type GQUpdateFinanceAutomaticRemindersInput = {
  dataUploadConfig: Scalars['JSONString']['input'];
  dataUploadDate: Scalars['DateTime']['input'];
  footprintFinalizationConfig: Scalars['JSONString']['input'];
  footprintFinalizationDate: Scalars['DateTime']['input'];
};

export type GQUpdateFinanceAutomaticRemindersPayload = {
  __typename?: 'UpdateFinanceAutomaticRemindersPayload';
  financeAutomaticReminders: Array<GQFinanceAutomaticReminder>;
};

export type GQUpdateFinanceColumnWidthInput = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type GQUpdateFinanceWorksheetInput = {
  setColumnOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
  setColumnWidth?: InputMaybe<GQUpdateFinanceColumnWidthInput>;
  setHiddenColumns?: InputMaybe<Array<Scalars['ID']['input']>>;
  worksheetId: Scalars['ID']['input'];
};

export type GQUpdateFinanceWorksheetPayload = {
  __typename?: 'UpdateFinanceWorksheetPayload';
  worksheet: GQFinanceWorksheet;
};

export type GQUpdateFinancialsReviewItemInput = {
  id: Scalars['ID']['input'];
  prompt: Scalars['String']['input'];
};

export type GQUpdateFinancialsReviewItemPayload = {
  __typename?: 'UpdateFinancialsReviewItemPayload';
  financialsReviewItem: GQFinancialsReviewItem;
};

export type GQUpdateForecastInput = {
  acknowledgedFootprintSnapshotId?: InputMaybe<Scalars['ID']['input']>;
  createdCustomIntensityConfigs?: InputMaybe<
    Array<GQCreateCustomIntensityConfigInput>
  >;
  footprintSnapshotId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  intervalEnd?: InputMaybe<Scalars['YearMonth']['input']>;
  referencePeriodInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  updatedCustomIntensityConfigs?: InputMaybe<
    Array<GQCustomIntensityConfigInput>
  >;
};

export type GQUpdateForecastPayload = {
  __typename?: 'UpdateForecastPayload';
  forecast: GQForecast;
  plans: Array<GQPlan>;
};

export type GQUpdateFundInput = {
  excludeAsSandbox?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  fundCategory?: InputMaybe<Scalars['String']['input']>;
  fundGroup?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nameSortable?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateFundPayload = {
  __typename?: 'UpdateFundPayload';
  fund: GQFund;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateIngestionInstructionsInput = {
  canonicalDatasetId?: InputMaybe<Scalars['ID']['input']>;
  canonicalDatasourceId?: InputMaybe<Scalars['ID']['input']>;
  instructions: GQIngestionInstructionsInput;
};

export type GQUpdateIngestionInstructionsPayload = {
  __typename?: 'UpdateIngestionInstructionsPayload';
  canonicalDataset: Maybe<GQCanonicalDataset>;
  canonicalDatasource: Maybe<GQCanonicalDatasource>;
  id: Scalars['ID']['output'];
};

export type GQUpdateIntegrationConnectionInput = {
  id: Scalars['ID']['input'];
  netsuiteIntegrationSettings?: InputMaybe<GQNetsuiteIntegrationSettingsInput>;
};

export type GQUpdateIntegrationConnectionPayload = {
  __typename?: 'UpdateIntegrationConnectionPayload';
  integrationConnection: GQIntegrationConnection;
};

export type GQUpdateLifecycleAssessmentInput = {
  boundary: GQLifecycleAssessmentBoundaryEnum;
  dataUsedNotes?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  productDescription?: InputMaybe<Scalars['String']['input']>;
  productName: Scalars['String']['input'];
  productUnit: Scalars['String']['input'];
  referenceEndDate: Scalars['DateTime']['input'];
  referenceStartDate: Scalars['DateTime']['input'];
  supplierProductExternalId?: InputMaybe<Scalars['String']['input']>;
  validityEndDate: Scalars['DateTime']['input'];
  validityStartDate: Scalars['DateTime']['input'];
};

export type GQUpdateMarketplaceAllocationInstructionInput = {
  amountKwh?: InputMaybe<Scalars['Int']['input']>;
  buildingName?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  footprintKind?: InputMaybe<Scalars['String']['input']>;
  ghgCategoryId: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
  isZeroedOut?: InputMaybe<Scalars['Boolean']['input']>;
  locationCity?: InputMaybe<Scalars['String']['input']>;
  locationCountry?: InputMaybe<Scalars['String']['input']>;
  locationGrid?: InputMaybe<Scalars['String']['input']>;
  locationState?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
  purchaseLineItemId?: InputMaybe<Scalars['ID']['input']>;
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  vendorEntity?: InputMaybe<Scalars['String']['input']>;
  vendorSubentity?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMarketplaceAllocationInstructionPayload = {
  __typename?: 'UpdateMarketplaceAllocationInstructionPayload';
  allocationInstruction: GQMarketplaceAllocationInstruction;
};

export type GQUpdateMarketplaceDeveloperInput = {
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMarketplaceDeveloperPayload = {
  __typename?: 'UpdateMarketplaceDeveloperPayload';
  marketplaceDeveloper: GQMarketplaceDeveloper;
};

export type GQUpdateMarketplaceDocumentInput = {
  id: Scalars['ID']['input'];
  kind?: InputMaybe<GQMarketplaceDocumentKind>;
  noteMd?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMarketplaceDocumentPayload = {
  __typename?: 'UpdateMarketplaceDocumentPayload';
  document: GQMarketplaceDocument;
};

export type GQUpdateMarketplaceProjectArchetypeInput = {
  additionalityRating?: InputMaybe<Scalars['Int']['input']>;
  bannerImageUrl?: InputMaybe<Scalars['String']['input']>;
  certificateBlurb?: InputMaybe<Scalars['String']['input']>;
  certificateColor?: InputMaybe<Scalars['String']['input']>;
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isUserVisible?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<GQMarketplaceProjectArchetypeKind>;
  name?: InputMaybe<Scalars['String']['input']>;
  permanenceRating?: InputMaybe<Scalars['String']['input']>;
  summaryMd?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<GQMarketplaceProjectArchetypeUnit>;
};

export type GQUpdateMarketplaceProjectArchetypePayload = {
  __typename?: 'UpdateMarketplaceProjectArchetypePayload';
  marketplaceProjectArchetype: GQMarketplaceProjectArchetype;
};

export type GQUpdateMarketplaceProjectInput = {
  archetypeId?: InputMaybe<Scalars['ID']['input']>;
  developerId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  internalNotesMd?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  registryId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GQMarketplaceProjectStatus>;
  supplierId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateMarketplaceProjectPayload = {
  __typename?: 'UpdateMarketplaceProjectPayload';
  marketplaceProject: GQMarketplaceProject;
};

export type GQUpdateMarketplacePurchaseEacMetadataInput = {
  ghgCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  interval: Scalars['YMInterval']['input'];
  marketTco2e: Scalars['Float']['input'];
  requestNotes?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMarketplacePurchaseInput = {
  eacMetadata?: InputMaybe<GQUpdateMarketplacePurchaseEacMetadataInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  noteMd?: InputMaybe<Scalars['String']['input']>;
  offerExpiration?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<GQMarketplacePurchaseStatus>;
};

export type GQUpdateMarketplacePurchaseLineItemEacMetadataInput = {
  countryWithEacs: Scalars['String']['input'];
  countryWithEmissions: Scalars['String']['input'];
  marketTco2e: Scalars['Float']['input'];
  mwh: Scalars['Float']['input'];
  numEacsRequested: Scalars['Int']['input'];
};

export type GQUpdateMarketplacePurchaseLineItemInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountUnit?: InputMaybe<GQMarketplaceProjectArchetypeUnit>;
  appliedToFootprint?: InputMaybe<Scalars['Boolean']['input']>;
  cleanPowerContractualInstrument?: InputMaybe<GQCleanPowerContractualInstrument>;
  closeDate?: InputMaybe<Scalars['Date']['input']>;
  coverageInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  deliveryDate?: InputMaybe<Scalars['Date']['input']>;
  eacMetadata?: InputMaybe<GQUpdateMarketplacePurchaseLineItemEacMetadataInput>;
  id: Scalars['ID']['input'];
  mechanism?: InputMaybe<GQMarketplaceProjectArchetypeMechanism>;
  noteMd?: InputMaybe<Scalars['String']['input']>;
  offeringId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<GQMarketplacePurchaseLineItemStatus>;
  unitFeeUsdCents?: InputMaybe<Scalars['Float']['input']>;
  unitPriceUsdCents?: InputMaybe<Scalars['Float']['input']>;
};

export type GQUpdateMarketplacePurchaseLineItemPayload = {
  __typename?: 'UpdateMarketplacePurchaseLineItemPayload';
  marketplacePurchase: GQMarketplacePurchase;
  marketplacePurchaseLineItem: GQMarketplacePurchaseLineItem;
};

export type GQUpdateMarketplacePurchasePayload = {
  __typename?: 'UpdateMarketplacePurchasePayload';
  marketplacePurchase: GQMarketplacePurchase;
};

export type GQUpdateMarketplaceSupplierInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMarketplaceSupplierPayload = {
  __typename?: 'UpdateMarketplaceSupplierPayload';
  marketplaceSupplier: GQMarketplaceSupplier;
};

export type GQUpdateMaterialityAssessmentInput = {
  materialityAssessmentId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMaterialityAssessmentPayload = {
  __typename?: 'UpdateMaterialityAssessmentPayload';
  materialityAssessments: Array<GQMaterialityAssessment>;
  updated: GQMaterialityAssessment;
};

export type GQUpdateMeasurementMappingInput = {
  authorExplanation?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endMonth: Scalars['YearMonth']['input'];
  ghgCategoryId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inputEmissionsSpecifier: Scalars['String']['input'];
  inputEmissionsSubspecifier: Scalars['String']['input'];
  outputEmissionsSpecifier: Scalars['String']['input'];
  outputEmissionsSubspecifier: Scalars['String']['input'];
  shouldInclude: Scalars['Boolean']['input'];
  startMonth: Scalars['YearMonth']['input'];
  subcategoryId?: InputMaybe<Scalars['String']['input']>;
  vendorEntity: Scalars['String']['input'];
};

export type GQUpdateMeasurementProjectInput = {
  coverageEndDate?: InputMaybe<Scalars['Date']['input']>;
  coverageStartDate?: InputMaybe<Scalars['Date']['input']>;
  deadline?: InputMaybe<Scalars['Date']['input']>;
  isApprovalFlowEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  kickoff?: InputMaybe<Scalars['Date']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQUpdateMeasurementProjectPayload = {
  __typename?: 'UpdateMeasurementProjectPayload';
  measurementProject: GQMeasurementProject;
};

export type GQUpdateMeasurementTestSuiteBartInput = {
  businessActivityTypeId?: InputMaybe<Scalars['String']['input']>;
  fieldDefaults?: InputMaybe<Scalars['JSONString']['input']>;
  hiddenFields?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMeasurementTestSuiteBartPayload = {
  __typename?: 'UpdateMeasurementTestSuiteBartPayload';
  measurementTestSuiteBart: GQMeasurementTestSuiteBart;
};

export type GQUpdateMeasurementTestSuiteInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  releaseIds: Array<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMeasurementTestSuitePayload = {
  __typename?: 'UpdateMeasurementTestSuitePayload';
  measurementTestSuite: GQMeasurementTestSuite;
};

export type GQUpdateOrgForFeatureFlagInput = {
  enabled: Scalars['Boolean']['input'];
  featureFlagId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQUpdateOrgForFeatureFlagPayload = {
  __typename?: 'UpdateOrgForFeatureFlagPayload';
  featureFlag: GQFeatureFlag;
};

export type GQUpdateOrgProfileInput = {
  climateCommitments?: InputMaybe<Array<GQCompanyClimateCommitmentKind>>;
  climateGoals?: InputMaybe<Array<Scalars['String']['input']>>;
  climateMotivation?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  hasClimateCommitments?: InputMaybe<GQYesNoUnknown>;
  hasMeasuredFootprintOutsideOfWatershed?: InputMaybe<GQYesNoUnknown>;
  industry?: InputMaybe<Scalars['String']['input']>;
  isCompanyDataConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  isComplete?: InputMaybe<Scalars['Boolean']['input']>;
  lastNonWatershedMeasurementYearString?: InputMaybe<
    Scalars['String']['input']
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  otherClimateCommitment?: InputMaybe<Scalars['String']['input']>;
  otherOperatingCountries?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQUpdateOrgProfilePayload = {
  __typename?: 'UpdateOrgProfilePayload';
  orgProfile: GQOrgProfile;
  organization: GQOrganization;
};

export type GQUpdateOrganizationInput = {
  bigqueryDatasetName?: InputMaybe<Scalars['String']['input']>;
  canAccessCorporate?: InputMaybe<Scalars['Boolean']['input']>;
  canAccessFinance?: InputMaybe<Scalars['Boolean']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  helpChannel?: InputMaybe<Scalars['String']['input']>;
  iconRemotePath?: InputMaybe<Scalars['String']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  logoRemotePath?: InputMaybe<Scalars['String']['input']>;
  magicLinkDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notionUrl?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  passwordAuthDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  stagingOrg?: InputMaybe<Scalars['Boolean']['input']>;
  testOrg?: InputMaybe<Scalars['Boolean']['input']>;
  watershedPlan?: InputMaybe<GQWatershedPlan>;
  watershedPlanLegacy?: InputMaybe<GQWatershedPlanLegacy>;
  watershedSlackChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdatePeerCompanyGroupInput = {
  footprintKinds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  revenueForRevenueAtRisk?: InputMaybe<Scalars['Int53']['input']>;
  shouldHideFromFootprintOverview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdatePipelineResultInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type GQUpdatePipelineResultPayload = {
  __typename?: 'UpdatePipelineResultPayload';
  pipelineResult: GQPipelineResult;
};

export type GQUpdatePlanInput = {
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
  variables?: InputMaybe<GQPlanVariablesInput>;
};

export type GQUpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  plan: GQPlan;
};

export type GQUpdatePlanTargetCategorySetPayload = {
  __typename?: 'UpdatePlanTargetCategorySetPayload';
  planTarget: GQPlanTarget;
};

export type GQUpdatePlanTargetInput = {
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  baseYear?: InputMaybe<Scalars['YearMonth']['input']>;
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  customNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Switching to targetYearInclusive and reductionAmount */
  emissionsTargetCustom?: InputMaybe<GQSimpleTimeseriesInput>;
  filters?: InputMaybe<GQFilterExpressionGroupInput>;
  id: Scalars['ID']['input'];
  intensityType?: InputMaybe<GQPlanTargetIntensityType>;
  interimTarget?: InputMaybe<GQInterimTargetInput>;
  reductionAmount?: InputMaybe<Scalars['Float']['input']>;
  reductionRate?: InputMaybe<GQTargetReductionRate>;
  reportDescription?: InputMaybe<Scalars['String']['input']>;
  targetYearInclusive?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQUpdatePlanTargetPayload = {
  __typename?: 'UpdatePlanTargetPayload';
  forecast: GQForecast;
  planTarget: GQPlanTarget;
};

export type GQUpdatePublicDisclosureTargetInput = {
  disclosureTarget: GQDisclosureTargetInput;
  id: Scalars['ID']['input'];
};

export type GQUpdatePublicDisclosureTargetPayload = {
  __typename?: 'UpdatePublicDisclosureTargetPayload';
  disclosureTarget: GQDisclosureTargetDetails;
};

export type GQUpdateReferenceDataCitationInput = {
  displayName: Scalars['String']['input'];
  externalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  externalSourceUrl?: InputMaybe<Scalars['String']['input']>;
  hasLicenseRestrictions: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  internalSourceNotes: Scalars['String']['input'];
  internalSourceUrl: Scalars['String']['input'];
  privateDisclosureId?: InputMaybe<Scalars['String']['input']>;
  publicDisclosureId?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateReferenceDataCitationPayload = {
  __typename?: 'UpdateReferenceDataCitationPayload';
  referenceDataCitation: GQReferenceDataCitation;
};

export type GQUpdateReferenceDataRevisionInput = {
  id: Scalars['String']['input'];
  internalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  outputFileId?: InputMaybe<Scalars['String']['input']>;
  revisionName?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateReferenceDataRevisionPayload = {
  __typename?: 'UpdateReferenceDataRevisionPayload';
  referenceDataRevision: GQReferenceDataRevision;
};

export type GQUpdateReferenceDataSourceInput = {
  externalSourceNotes?: InputMaybe<Scalars['String']['input']>;
  externalSourceUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateReferenceDataSourcePayload = {
  __typename?: 'UpdateReferenceDataSourcePayload';
  referenceDataSource: GQReferenceDataSource;
};

export type GQUpdateReferenceDataVersionInput = {
  id: Scalars['String']['input'];
  versionName?: InputMaybe<Scalars['String']['input']>;
  versionVintage?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GQUpdateReferenceDataVersionPayload = {
  __typename?: 'UpdateReferenceDataVersionPayload';
  referenceDataVersion: GQReferenceDataVersion;
};

export type GQUpdateReferenceDataVersionSchemaInput = {
  id: Scalars['String']['input'];
  schemaJson: Scalars['JSONSchema']['input'];
};

export type GQUpdateReferenceDataVersionSchemaPayload = {
  __typename?: 'UpdateReferenceDataVersionSchemaPayload';
  referenceDataVersion: GQReferenceDataVersion;
};

export type GQUpdateReportConfigFrameworkMappingError = {
  __typename?: 'UpdateReportConfigFrameworkMappingError';
  msg: Maybe<Scalars['String']['output']>;
};

export type GQUpdateReportConfigFrameworkMappingWarning = {
  __typename?: 'UpdateReportConfigFrameworkMappingWarning';
  msg: Maybe<Scalars['String']['output']>;
};

export type GQUpdateReportConfigInput = {
  brandColor?: InputMaybe<Scalars['String']['input']>;
  computedQuestionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inputQuestionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isCorporateReport?: InputMaybe<Scalars['Boolean']['input']>;
  isCreationBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  isFinanceReport?: InputMaybe<Scalars['Boolean']['input']>;
  isHiddenFromOverview?: InputMaybe<Scalars['Boolean']['input']>;
  isRegulatoryExposureBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  itemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  longName?: InputMaybe<Scalars['String']['input']>;
  reportObjectives?: InputMaybe<Array<GQCanonicalReportObjective>>;
  reportType?: InputMaybe<Scalars['String']['input']>;
  requiredPermissions?: InputMaybe<Array<GQPermissionType>>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GQReportConfigStatus>;
  useApprovals?: InputMaybe<Scalars['Boolean']['input']>;
  useMateriality?: InputMaybe<Scalars['Boolean']['input']>;
  useOverrides?: InputMaybe<Scalars['Boolean']['input']>;
  useReportingIntention?: InputMaybe<Scalars['Boolean']['input']>;
  useTableDisplay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateReportConfigPayload = {
  __typename?: 'UpdateReportConfigPayload';
  reportConfig: GQReportConfig;
};

export type GQUpdateReportEsrsMappingFailure = {
  __typename?: 'UpdateReportEsrsMappingFailure';
  errors: Array<GQUpdateReportConfigFrameworkMappingError>;
};

export type GQUpdateReportEsrsMappingInput = {
  mapping: Scalars['String']['input'];
  reportConfigId: Scalars['ID']['input'];
};

export type GQUpdateReportEsrsMappingPayload =
  | GQUpdateReportEsrsMappingFailure
  | GQUpdateReportEsrsMappingSuccess;

export type GQUpdateReportEsrsMappingSuccess = {
  __typename?: 'UpdateReportEsrsMappingSuccess';
  mapping: GQReportConfigFrameworkMapping;
  warnings: Array<GQUpdateReportConfigFrameworkMappingWarning>;
};

export type GQUpdateReportInput = {
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
  reportStatus?: InputMaybe<GQReportStatus>;
};

export type GQUpdateReportPayload = {
  __typename?: 'UpdateReportPayload';
  report: GQReport;
};

export type GQUpdateReportQuestionContainerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>;
  guidance?: InputMaybe<Scalars['JSONString']['input']>;
  id: Scalars['ID']['input'];
  items?: InputMaybe<Array<Scalars['ID']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQUpdateReportQuestionContainerPayload = {
  __typename?: 'UpdateReportQuestionContainerPayload';
  container: GQReportQuestionContainer;
};

export type GQUpdateReportQuestionIdentifierMappingPayload = {
  __typename?: 'UpdateReportQuestionIdentifierMappingPayload';
  reportQuestionIdentifierMapping1: GQReportQuestionIdentifierMapping;
  reportQuestionIdentifierMapping2: GQReportQuestionIdentifierMapping;
};

export type GQUpdateReportQuestionInput = {
  allowNotes?: InputMaybe<Scalars['Boolean']['input']>;
  allowedAttachmentKinds?: InputMaybe<Array<GQReportAttachmentItemKind>>;
  componentId?: InputMaybe<Scalars['String']['input']>;
  dynamicInputs?: InputMaybe<Scalars['JSONString']['input']>;
  guidance?: InputMaybe<Scalars['JSONString']['input']>;
  id: Scalars['ID']['input'];
  identifier?: InputMaybe<Scalars['String']['input']>;
  isComputed?: InputMaybe<Scalars['Boolean']['input']>;
  isDynamic?: InputMaybe<Scalars['Boolean']['input']>;
  staticInputs?: InputMaybe<Scalars['JSONString']['input']>;
  status?: InputMaybe<Scalars['JSONString']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateReportQuestionPayload = {
  __typename?: 'UpdateReportQuestionPayload';
  question: GQReportQuestion;
};

export type GQUpdateReportingFrameworkDataPointsInput = {
  updates: Array<GQReportingFrameworkDataPointUpdateInput>;
};

export type GQUpdateReportingFrameworkDataPointsPayload = {
  __typename?: 'UpdateReportingFrameworkDataPointsPayload';
  dataPoints: Array<GQReportingFrameworkDataPoint>;
};

export type GQUpdateReportingFrameworkInput = {
  dataPoints?: InputMaybe<Array<GQReportingFrameworkDataPointInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateReportingFrameworkPayload = {
  __typename?: 'UpdateReportingFrameworkPayload';
  reportingFramework: GQReportingFramework;
};

export type GQUpdateReportingIntentionsForOrgInput = {
  intention: GQReportingIntention;
  orgId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
  reportQuestionIds: Array<Scalars['ID']['input']>;
};

export type GQUpdateReportingIntentionsForOrgPayload = {
  __typename?: 'UpdateReportingIntentionsForOrgPayload';
  reportQuestions: Array<GQReportQuestion>;
};

export type GQUpdateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<GQPermissionItemInput>;
  roleId: Scalars['ID']['input'];
  watershedManaged?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateRolePayload = {
  __typename?: 'UpdateRolePayload';
  role: GQRole;
};

export type GQUpdateSimpleTimeseriesInput = {
  base?: InputMaybe<Scalars['Date']['input']>;
  frequency?: InputMaybe<GQTimeseriesFrequency>;
  id: Scalars['ID']['input'];
  values?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type GQUpdateSimpleTimeseriesPayload = {
  __typename?: 'UpdateSimpleTimeseriesPayload';
  timeseries: GQSimpleTimeseries;
};

export type GQUpdateSupplierChartInputAdmin = {
  chartId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  supplierChartInput: GQSupplierChartInput;
};

export type GQUpdateSupplierChartPayloadAdmin = {
  __typename?: 'UpdateSupplierChartPayloadAdmin';
  views: Array<GQSupplierView>;
};

export type GQUpdateSuppliersSettingsAdminInput = {
  orgId: Scalars['ID']['input'];
  updateSuppliersSettingsInput: GQUpdateSuppliersSettingsInput;
};

export type GQUpdateSuppliersSettingsInput = {
  footprintSnapshotIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hideGlobalCharts?: InputMaybe<Scalars['Boolean']['input']>;
  includedGhgCategories?: InputMaybe<Array<Scalars['String']['input']>>;
  suppressNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  viewLatestSupplierMapping?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateSuppliersSettingsPayload = {
  __typename?: 'UpdateSuppliersSettingsPayload';
  suppliersSettings: GQSuppliersSettings;
};

export type GQUpdateTargetInput = {
  target: GQTargetInput;
  targetId: Scalars['ID']['input'];
};

export type GQUpdateTargetPayload = {
  __typename?: 'UpdateTargetPayload';
  org: GQOrganization;
  target: GQTarget;
};

export type GQUpdateTcfdArchetypeOpportunityInput = {
  archetypeRiskId?: InputMaybe<Scalars['ID']['input']>;
  distributionModel?: InputMaybe<GQTcfdStrategyModuleDistributionModel>;
  geographies?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  sectors?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  userDescription?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateTcfdArchetypeRiskInput = {
  distributionModel?: InputMaybe<GQTcfdStrategyModuleDistributionModel>;
  geographies?: InputMaybe<Array<Scalars['String']['input']>>;
  highTrajectoryImpact?: InputMaybe<GQTcfdRiskImpact>;
  id: Scalars['ID']['input'];
  kind?: InputMaybe<GQTcfdRiskKind>;
  lowTrajectoryImpact?: InputMaybe<GQTcfdRiskImpact>;
  mediumTrajectoryImpact?: InputMaybe<GQTcfdRiskImpact>;
  physicalRiskSeverity?: InputMaybe<GQTcfdPhysicalRiskSeverity>;
  possibleImpactDescription?: InputMaybe<Scalars['String']['input']>;
  sectors?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  userDescription?: InputMaybe<Scalars['String']['input']>;
  whyWeChoseThisMd?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateTestRowsInTestSuiteBartInput = {
  activityRows: Array<GQUpdateTestRowsInTestSuiteBartRowInput>;
  id: Scalars['ID']['input'];
};

export type GQUpdateTestRowsInTestSuiteBartPayload = {
  __typename?: 'UpdateTestRowsInTestSuiteBartPayload';
  measurementTestSuiteBart: GQMeasurementTestSuiteBart;
};

export type GQUpdateTestRowsInTestSuiteBartRowInput = {
  activityRow: Scalars['JSONString']['input'];
  id: Scalars['ID']['input'];
};

export type GQUpdateUserEmailAdminVersionInput = {
  newEmail: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQUpdateUserEmailAdminVersionPayload = {
  __typename?: 'UpdateUserEmailAdminVersionPayload';
  user: GQUser;
  watershedEmployeeUpdated: Scalars['Boolean']['output'];
};

export type GQUpdateUserNameAdminVersionInput = {
  newName: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GQUpdateUserNameAdminVersionPayload = {
  __typename?: 'UpdateUserNameAdminVersionPayload';
  user: GQUser;
  watershedEmployeeUpdated: Scalars['Boolean']['output'];
};

export type GQUpdateUserUploadTaskInput = {
  datasourceId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<GQUserUploadTaskState>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQUpdateUserUploadTaskPayload = {
  __typename?: 'UpdateUserUploadTaskPayload';
  userUploadTask: GQUserUploadTask;
};

export type GQUpdatedFileMetadataPermissionDelegate = {
  __typename?: 'UpdatedFileMetadataPermissionDelegate';
  fileMetadataId: Scalars['String']['output'];
  newPermissionDelegateId: Maybe<Scalars['String']['output']>;
  oldPermissionDelegateId: Maybe<Scalars['String']['output']>;
  orgId: Scalars['String']['output'];
};

export type GQUploadConnection = {
  __typename?: 'UploadConnection';
  edges: Array<Maybe<GQUploadEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUploadEdge = {
  __typename?: 'UploadEdge';
  cursor: Scalars['String']['output'];
};

export type GQUploadMarketplaceDocumentInput = {
  fileMetadataId: Scalars['ID']['input'];
  kind: GQMarketplaceDocumentKind;
  name: Scalars['String']['input'];
  noteMd?: InputMaybe<Scalars['String']['input']>;
  purchaseId?: InputMaybe<Scalars['ID']['input']>;
  purchaseLineItemId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUploadMarketplaceDocumentsInput = {
  documents: Array<GQUploadMarketplaceDocumentInput>;
};

export type GQUploadMarketplaceDocumentsPayload = {
  __typename?: 'UploadMarketplaceDocumentsPayload';
  documents: Array<GQMarketplaceDocument>;
};

export type GQUpsertCdpColumnMappingsInput = {
  mappings: Array<GQCdpColumnMappingInput>;
};

export type GQUpsertCdpColumnMappingsPayload = {
  __typename?: 'UpsertCdpColumnMappingsPayload';
  updatedMappings: Array<GQCdpColumnIdMapping>;
};

export type GQUpsertCdpQuestionMappingsInput = {
  mappings: Array<GQCdpQuestionMappingInput>;
};

export type GQUpsertCdpQuestionMappingsPayload = {
  __typename?: 'UpsertCdpQuestionMappingsPayload';
  updatedMappings: Array<GQCdpQuestionIdMapping>;
};

export type GQUpsertCompanyPayload = {
  __typename?: 'UpsertCompanyPayload';
  company: GQCompany;
};

export type GQUpsertCompanyPortalSettingsInput = {
  portalCallout?: InputMaybe<Scalars['String']['input']>;
  portalTitle: Scalars['String']['input'];
  sidebarContent?: InputMaybe<Scalars['String']['input']>;
  toggleHeaderImage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpsertCompanyPortalSettingsPayload = {
  __typename?: 'UpsertCompanyPortalSettingsPayload';
  companyPortalSettings: GQCompanyPortalSettings;
};

export type GQUpsertDocumentParseAttemptInput = {
  modelType: GQDocumentParseAttemptModelType;
  userUploadId: Scalars['ID']['input'];
  utilityUsageData?: InputMaybe<GQUtilityUsageDataInput>;
};

export type GQUpsertFootprintTestSuiteConfigInput = {
  footprintTestSuiteConfigFields: GQFootprintTestSuiteConfigFields;
  measurementTestSuiteId: Scalars['ID']['input'];
};

export type GQUser = GQIdInterface &
  GQPerson & {
    __typename?: 'User';
    accessibleOrgs: Array<GQOrgLimitedProfile>;
    consecutiveLoginFailures: Scalars['Int']['output'];
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    didLatestEmailBounce: Scalars['Boolean']['output'];
    displayName: Scalars['String']['output'];
    email: Scalars['String']['output'];
    engagementNotificationSettings: Array<GQEngagementNotificationSetting>;
    hasAnyPermissions: Scalars['Boolean']['output'];
    hasPermission: Scalars['Boolean']['output'];
    hasPermissions: Scalars['Boolean']['output'];
    hasValidAppSessions: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    isE2ETester: Scalars['Boolean']['output'];
    /** @deprecated Please use isWatershedEmployee or isWatershedContractor */
    isWatershed: Scalars['Boolean']['output'];
    isWatershedContractor: Scalars['Boolean']['output'];
    isWatershedEmployee: Scalars['Boolean']['output'];
    loginActivated: Scalars['Boolean']['output'];
    managedByDirectoryType: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    onboardingsCompleted: Array<GQOnboardingKind>;
    orgAccessCreatedAt: Scalars['DateTime']['output'];
    orgAccessId: Scalars['ID']['output'];
    permissionOrigin: Maybe<GQUserPermissionOrigin>;
    permissions: Array<GQPermissionItem>;
    preferredLocale: Maybe<Scalars['String']['output']>;
    primaryLoginOrgId: Scalars['ID']['output'];
    roles: Array<GQUserRoleAssignment>;
    /** @deprecated signupLink should not be exposed in dashboard */
    signupLink: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['DateTime']['output'];
    userUploadTasks: Array<GQUserUploadTask>;
  };

export type GQUserEngagementNotificationSettingsArgs = {
  side: GQEngagementNotificationSide;
};

export type GQUserHasAnyPermissionsArgs = {
  orgId: Scalars['ID']['input'];
  permissions: Array<GQHasPermissionInput>;
};

export type GQUserHasPermissionArgs = {
  objectId: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  permission: GQPermissionType;
};

export type GQUserHasPermissionsArgs = {
  orgId: Scalars['ID']['input'];
  permissions: Array<GQHasPermissionInput>;
};

export type GQUserOrgAccessCreatedAtArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQUserOrgAccessIdArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQUserPermissionOriginArgs = {
  orgId: Scalars['ID']['input'];
  permissions: Array<GQHasPermissionInput>;
};

export type GQUserPermissionsArgs = {
  includeDeletedOrgAccess: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQUserRolesArgs = {
  includeDeletedOrgAccess: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQUserSignupLinkArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQUserConnection = {
  __typename?: 'UserConnection';
  edges: Array<Maybe<GQUserEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'User' and its cursor. */
export type GQUserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUser>;
};

export type GQUserFilterInput = {
  includeWatershedEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  withAllPermissions?: InputMaybe<Array<GQHasPermissionInput>>;
  withSomePermissions?: InputMaybe<Array<GQHasPermissionInput>>;
};

export type GQUserForOrgId = {
  __typename?: 'UserForOrgId';
  orgId: Scalars['ID']['output'];
  user: Maybe<GQUser>;
};

export type GQUserIngestionReview = {
  __typename?: 'UserIngestionReview';
  id: Scalars['ID']['output'];
  ingestionSchemaId: Maybe<Scalars['String']['output']>;
  userUploadedTableId: Scalars['ID']['output'];
};

export type GQUserInputtedForecastPeriodPoint = {
  __typename?: 'UserInputtedForecastPeriodPoint';
  customGrowthFactors: Array<GQForecastCustomIntensityDataPoint>;
  date: Scalars['Date']['output'];
};

export type GQUserPermissionOrigin = {
  __typename?: 'UserPermissionOrigin';
  id: Scalars['ID']['output'];
  permission: Maybe<GQPermissionItem>;
  role: Maybe<GQUserRoleAssignment>;
};

export type GQUserRoleAssignment = {
  __typename?: 'UserRoleAssignment';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  org: GQOrganization;
  revokedAt: Maybe<Scalars['DateTime']['output']>;
  role: GQRole;
  updatedAt: Scalars['DateTime']['output'];
  user: GQUser;
};

export type GQUserUpload = GQIdInterface & {
  __typename?: 'UserUpload';
  actualUserUploadId: Maybe<Scalars['ID']['output']>;
  allAttempts: Array<GQUserUploadAttempt>;
  /** @deprecated Use UserUploadCandidate */
  apiUpload: Maybe<GQApiUpload>;
  associatedUtilityCount: Scalars['Int']['output'];
  category: GQFileCategory;
  createdAt: Scalars['DateTime']['output'];
  ctsVersion: Maybe<GQCustomerTargetSchemaVersion>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  errorReason: Maybe<Scalars['String']['output']>;
  /** @deprecated Use UserUploadTask.hasStaleSnapshot instead */
  hasStaleSnapshot: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isBeingValueMapped: Scalars['Boolean']['output'];
  latestAdeIdForSDIFacilitiesFlow: Maybe<Scalars['String']['output']>;
  latestAttempt: GQUserUploadAttempt;
  measurementProject: Maybe<GQMeasurementProject>;
  name: Scalars['String']['output'];
  org: GQOrganization;
  processingMode: Maybe<GQUserUploadProcessingMode>;
  processingWorkflowId: Maybe<Scalars['String']['output']>;
  revisionRoot: Maybe<Scalars['ID']['output']>;
  status: GQUserUploadStatus;
  transformsIncludingDeleted: Array<GQCustomerTargetSchemaTransform>;
  uploader: Maybe<GQUser>;
  /** @deprecated Use userUploadKind */
  userUploadCandidate: Maybe<GQUserUploadCandidate>;
  userUploadKind: Maybe<GQUserUploadCandidateKind>;
  userUploadTask: Maybe<GQUserUploadTask>;
  userVisibleAttempt: Maybe<GQUserUploadAttempt>;
  userVisibleErrorMessage: Maybe<Scalars['String']['output']>;
};

export type GQUserUploadMeasurementProjectArgs = {
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUserUploadAttempt = GQIdInterface & {
  __typename?: 'UserUploadAttempt';
  category: GQFileCategory;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  gcloudStorageUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  latestUutsIfRevisionExists: Maybe<Array<GQUserUploadedTable>>;
  name: Scalars['String']['output'];
  previewUrl: Scalars['String']['output'];
  remoteWritten: Scalars['Boolean']['output'];
  size: Scalars['Int53']['output'];
  uploader: Maybe<GQUser>;
  userUpload: GQUserUpload;
  userUploadSource: Maybe<GQUserUploadSource>;
  userUploadedTables: Array<GQUserUploadedTable>;
};

export type GQUserUploadAttemptConnection = {
  __typename?: 'UserUploadAttemptConnection';
  edges: Array<Maybe<GQUserUploadAttemptEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUserUploadAttemptEdge = {
  __typename?: 'UserUploadAttemptEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUploadAttempt>;
};

export type GQUserUploadCandidate = {
  __typename?: 'UserUploadCandidate';
  createdAt: Scalars['Date']['output'];
  ctsVersion: GQCustomerTargetSchemaVersion;
  dataset: GQDataset;
  datasetId: Scalars['ID']['output'];
  errorCount: Maybe<Scalars['Int']['output']>;
  errorData: Maybe<Scalars['JSONString']['output']>;
  fileFeed: Maybe<GQCtsvOneSchemaFileFeed>;
  id: Scalars['ID']['output'];
  latestFileFeedImport: Maybe<GQCtsvOneSchemaFileFeedImport>;
  name: Scalars['String']['output'];
  rowCount: Scalars['Int']['output'];
  status: GQUserUploadCandidateStatus;
  submissions: Array<GQUserUploadCandidateSubmission>;
  userUploadKind: GQUserUploadCandidateKind;
};

export const GQUserUploadCandidateKind = {
  Api: 'Api',
  Document: 'Document',
  File: 'File',
  Form: 'Form',
  Integration: 'Integration',
  Manual: 'Manual',
} as const;

export type GQUserUploadCandidateKind =
  (typeof GQUserUploadCandidateKind)[keyof typeof GQUserUploadCandidateKind];
export const GQUserUploadCandidateStatus = {
  Error: 'error',
  Pending: 'pending',
  Processing: 'processing',
  Submitted: 'submitted',
  Validated: 'validated',
} as const;

export type GQUserUploadCandidateStatus =
  (typeof GQUserUploadCandidateStatus)[keyof typeof GQUserUploadCandidateStatus];
export type GQUserUploadCandidateSubmission = {
  __typename?: 'UserUploadCandidateSubmission';
  apiSubmissionId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  datasourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  measurementProject: GQMeasurementProject;
  processFileWorkflowId: Maybe<Scalars['ID']['output']>;
  userUploadTaskId: Scalars['String']['output'];
};

export type GQUserUploadConnection = {
  __typename?: 'UserUploadConnection';
  edges: Array<Maybe<GQUserUploadEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUserUploadDataGovernanceInfo = {
  __typename?: 'UserUploadDataGovernanceInfo';
  approvers: Array<GQUserWithApprovalStatus>;
  dataset: Scalars['String']['output'];
  datasource: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  uploadedAt: Scalars['DateTime']['output'];
  uploadedBy: Maybe<Scalars['String']['output']>;
  userUploadKind: Maybe<GQUserUploadCandidateKind>;
};

export type GQUserUploadEdge = {
  __typename?: 'UserUploadEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUpload>;
};

export const GQUserUploadProcessingMode = {
  Api: 'Api',
  IdiRawFile: 'IdiRawFile',
  Legacy: 'Legacy',
  NoProcessing: 'NoProcessing',
  Synchronous: 'Synchronous',
} as const;

export type GQUserUploadProcessingMode =
  (typeof GQUserUploadProcessingMode)[keyof typeof GQUserUploadProcessingMode];
export const GQUserUploadSource = {
  InProduct: 'InProduct',
  PublicApi: 'PublicAPI',
} as const;

export type GQUserUploadSource =
  (typeof GQUserUploadSource)[keyof typeof GQUserUploadSource];
export const GQUserUploadStatus = {
  Errored: 'Errored',
  Finalized: 'Finalized',
  Processed: 'Processed',
  Processing: 'Processing',
  Uploaded: 'Uploaded',
  Uploading: 'Uploading',
  Validated: 'Validated',
} as const;

export type GQUserUploadStatus =
  (typeof GQUserUploadStatus)[keyof typeof GQUserUploadStatus];
export type GQUserUploadTask = GQApprovalTarget &
  GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'UserUploadTask';
    approvalStatus: GQApprovalStatus;
    approvers: Array<GQUserWithApprovalStatus>;
    /** @deprecated Use assignees instead */
    assignee: Maybe<GQUser>;
    assignees: Array<GQUser>;
    buildingIds: Array<Scalars['ID']['output']>;
    buildingLockedRevisions: Maybe<Array<Maybe<GQBuildingLockedRevisions>>>;
    buildingTemplateDataUntyped: Maybe<Scalars['UntypedData']['output']>;
    createdAt: Scalars['DateTime']['output'];
    ctsForm: Maybe<GQCtsForm>;
    datasetRequirements: Array<GQClimateProgramProjectRequirementDataset>;
    datasource: GQDatasource;
    dueAt: Maybe<Scalars['DateTime']['output']>;
    facilitiesPreview: GQFacilitiesPreview;
    hasIncompleteBuildings: Scalars['Boolean']['output'];
    hasStaleSnapshot: Scalars['Boolean']['output'];
    /** @deprecated Use valueMappingTask instead. Delete may9 */
    hasValueMappingTask: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    importState: GQDatasourceImportState;
    /** @deprecated Causes performance issues, do not use! */
    isDatasetComplete: Maybe<Scalars['Boolean']['output']>;
    /** @deprecated Use lockState instead */
    isLocked: Scalars['Boolean']['output'];
    issues: Maybe<GQDataIssueConnection>;
    lockState: GQApprovalTargetLockState;
    measurementProject: GQMeasurementProject;
    measurementTask: GQMeasurementTaskFlat;
    state: GQUserUploadTaskState;
    /** @deprecated Use lockState instead */
    unlockRequested: Scalars['Boolean']['output'];
    updatedAt: Scalars['DateTime']['output'];
    userUploads: Maybe<GQUserUploadConnection>;
    valueMappingTask: Maybe<GQValueMappingTask>;
    valueMappingTasks: Array<GQValueMappingTask>;
  };

export type GQUserUploadTaskIssuesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQUserUploadTaskUserUploadsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  categories: InputMaybe<Array<GQFileCategory>>;
  excludeProcessingModes: InputMaybe<Array<GQUserUploadProcessingMode>>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQUserUploadTaskConnection = {
  __typename?: 'UserUploadTaskConnection';
  edges: Array<Maybe<GQUserUploadTaskEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'UserUploadTask' and its cursor. */
export type GQUserUploadTaskEdge = {
  __typename?: 'UserUploadTaskEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUploadTask>;
};

export const GQUserUploadTaskState = {
  Completed: 'Completed',
  Created: 'Created',
  HasReviewItems: 'HasReviewItems',
  InfoRequired: 'InfoRequired',
  Uploaded: 'Uploaded',
  Verifying: 'Verifying',
} as const;

export type GQUserUploadTaskState =
  (typeof GQUserUploadTaskState)[keyof typeof GQUserUploadTaskState];
export type GQUserUploadVerificationLog = GQIdInterface & {
  __typename?: 'UserUploadVerificationLog';
  dataReviewCompleted: Scalars['Boolean']['output'];
  dataset: Scalars['String']['output'];
  datasource: Scalars['String']['output'];
  fileId: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sheetName: Maybe<Scalars['String']['output']>;
  submittedAt: Scalars['DateTime']['output'];
  submittedBy: Scalars['String']['output'];
  transformation: Maybe<Scalars['String']['output']>;
};

export type GQUserUploadedTable = GQIdInterface & {
  __typename?: 'UserUploadedTable';
  /** @deprecated unused, remove jan18 */
  additionalQuestions: Maybe<Array<GQAdditionalQuestionResponse>>;
  businessActivityType: Maybe<Scalars['String']['output']>;
  dataPreview: Maybe<GQUserUploadedTableDataPreview>;
  error: Maybe<Scalars['String']['output']>;
  errorMessage: Maybe<Scalars['String']['output']>;
  errorType: Maybe<Scalars['String']['output']>;
  extBartId: Maybe<Scalars['ID']['output']>;
  gcloudStorageUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integrationDataPull: Maybe<GQIntegrationDataPull>;
  isUsingLatestUIR: Scalars['Boolean']['output'];
  latestParseAttemptGCSSignedUrl: Maybe<Scalars['String']['output']>;
  numRows: Maybe<Scalars['Int']['output']>;
  parentFileMetadata: GQFileMetadata;
  parseConfig: Maybe<GQUserUploadedTableParseConfig>;
  rawDataPreview: Maybe<GQUserUploadedTableDataPreview>;
  rawDataUnshiftedPreview: Maybe<GQUserUploadedTableDataPreview>;
  roboCleanerResult: Maybe<GQUserUploadedTableRoboCleanerResult>;
  schema: Maybe<Array<GQUserUploadedTableSchemaColumn>>;
  sheetIndex: Maybe<Scalars['Int']['output']>;
  sheetName: Maybe<Scalars['String']['output']>;
  shouldIgnore: Scalars['Boolean']['output'];
  sourceFileDeleted: Scalars['Boolean']['output'];
  sqlTableName: Maybe<Scalars['String']['output']>;
  status: GQUserUploadedTableStatus;
  uirSchema: Maybe<Array<GQUserUploadedTableSchemaColumn>>;
  userIngestionReview: Maybe<GQUserIngestionReview>;
  userReviewMetadata: Maybe<GQUserUploadedTableUserReviewMetadata>;
  userUploadTask: Maybe<GQUserUploadTask>;
  userVisibleAttempt: Maybe<GQUserUploadAttempt>;
};

export type GQUserUploadedTableUserVisibleAttemptArgs = {
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUserUploadedTableCell = {
  __typename?: 'UserUploadedTableCell';
  columnName: Scalars['String']['output'];
  value: Maybe<Scalars['JSONString']['output']>;
};

export type GQUserUploadedTableConnection = {
  __typename?: 'UserUploadedTableConnection';
  edges: Array<Maybe<GQUserUploadedTableEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUserUploadedTableDataPreview = {
  __typename?: 'UserUploadedTableDataPreview';
  /** @deprecated Use dataUntyped instead, delete on apr29 */
  data: Array<Array<GQUserUploadedTableCell>>;
  dataUntyped: Scalars['UntypedData']['output'];
  schema: GQUserUploadedTableDataPreviewSchema;
};

export type GQUserUploadedTableDataPreviewSchema = {
  __typename?: 'UserUploadedTableDataPreviewSchema';
  fields: Array<GQUserUploadedTableDataPreviewSchemaField>;
};

export type GQUserUploadedTableDataPreviewSchemaField = {
  __typename?: 'UserUploadedTableDataPreviewSchemaField';
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type GQUserUploadedTableEdge = {
  __typename?: 'UserUploadedTableEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUploadedTable>;
};

export type GQUserUploadedTableParseConfig = {
  __typename?: 'UserUploadedTableParseConfig';
  meltIdVars: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  noHeader: Scalars['Boolean']['output'];
  skipBottomNRows: Scalars['Int']['output'];
  skipTopNRows: Scalars['Int']['output'];
};

export type GQUserUploadedTableRoboCleanerResult = {
  __typename?: 'UserUploadedTableRoboCleanerResult';
  skipTopNRows: Scalars['Int']['output'];
};

export type GQUserUploadedTableSchemaColumn = {
  __typename?: 'UserUploadedTableSchemaColumn';
  alias: Scalars['String']['output'];
  forwardFill: Scalars['Boolean']['output'];
  index: Scalars['Int']['output'];
  kind: GQUserUploadedTableSchemaColumnKind;
  name: Scalars['String']['output'];
};

export const GQUserUploadedTableSchemaColumnKind = {
  Boolean: 'Boolean',
  Date: 'Date',
  Epoch: 'Epoch',
  EuroDate: 'EuroDate',
  EuroFloat: 'EuroFloat',
  ExcelOrdinalDate: 'ExcelOrdinalDate',
  Float: 'Float',
  Month: 'Month',
  String: 'String',
} as const;

export type GQUserUploadedTableSchemaColumnKind =
  (typeof GQUserUploadedTableSchemaColumnKind)[keyof typeof GQUserUploadedTableSchemaColumnKind];
export const GQUserUploadedTableStatus = {
  Error: 'Error',
  Pending: 'Pending',
  Success: 'Success',
  Warning: 'Warning',
} as const;

export type GQUserUploadedTableStatus =
  (typeof GQUserUploadedTableStatus)[keyof typeof GQUserUploadedTableStatus];
export type GQUserUploadedTableUserReviewMetadata = {
  __typename?: 'UserUploadedTableUserReviewMetadata';
  buildingAreaUnitOverride: Maybe<GQBuildingSizeUnit>;
  columnMappings: Array<GQColumnMapping>;
  currencyOverride: Maybe<GQCurrencyOverride>;
  headerRowIndex: Scalars['Int']['output'];
  ingestionQuestionResponses: Maybe<Array<GQIngestionQuestionResponse>>;
  skipBottomNRows: Scalars['Int']['output'];
  stepsCompleted: Maybe<Array<GQImportFlowDataCleaningStep>>;
};

export type GQUserWithApprovalStatus = {
  __typename?: 'UserWithApprovalStatus';
  approvalLastUpdatedAt: Maybe<Scalars['DateTime']['output']>;
  approvalStatus: Maybe<GQApprovalStatus>;
  user: GQUser;
};

export type GQUtilitiesMetadata = {
  __typename?: 'UtilitiesMetadata';
  id: Scalars['ID']['output'];
  utilityTypes: Array<GQUtilitiesMetadataByType>;
};

export type GQUtilitiesMetadataByType = {
  __typename?: 'UtilitiesMetadataByType';
  containingInterval: Scalars['YMInterval']['output'];
  utilityType: Maybe<GQBuildingUtilityType>;
};

export type GQUtilityUsageData = {
  __typename?: 'UtilityUsageData';
  energyConsumptionAmount: Maybe<Scalars['String']['output']>;
  energyConsumptionUnit: Maybe<Scalars['String']['output']>;
  receiverAddress: Maybe<Scalars['String']['output']>;
  serviceAddress: Maybe<Scalars['String']['output']>;
  serviceEndDate: Maybe<Scalars['String']['output']>;
  serviceStartDate: Maybe<Scalars['String']['output']>;
};

export type GQUtilityUsageDataInput = {
  energyConsumptionAmount?: InputMaybe<Scalars['String']['input']>;
  energyConsumptionUnit?: InputMaybe<Scalars['String']['input']>;
  receiverAddress?: InputMaybe<Scalars['String']['input']>;
  serviceAddress?: InputMaybe<Scalars['String']['input']>;
  serviceEndDate?: InputMaybe<Scalars['String']['input']>;
  serviceStartDate?: InputMaybe<Scalars['String']['input']>;
};

export type GQValidateEmissionsModelMatcherInput = {
  matcher: GQEmissionsModelMatcherInput;
  targetUnboundParameters: Scalars['JSONString']['input'];
  validateSingleMatcher: Scalars['Boolean']['input'];
};

export type GQValidateEmissionsModelMatcherPayload = {
  __typename?: 'ValidateEmissionsModelMatcherPayload';
  errors: Array<GQEmissionsModelError>;
  isValid: Scalars['Boolean']['output'];
};

export type GQValidateSchemaRegistrySettingsInput = {
  existingSettings: GQSchemaRegistrySchemaSettingsInput;
  newSettings: GQSchemaRegistrySchemaSettingsInput;
  schemaId: Scalars['ID']['input'];
};

export type GQValidateSchemaRegistrySettingsPayload = {
  __typename?: 'ValidateSchemaRegistrySettingsPayload';
  errors: Array<Scalars['String']['output']>;
  warnings: Array<Scalars['String']['output']>;
};

export type GQValidationWarning = {
  __typename?: 'ValidationWarning';
  blocking: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type GQValueMapping = {
  __typename?: 'ValueMapping';
  id: Scalars['ID']['output'];
  metadata: Maybe<GQValueMappingRowsMetadata>;
  /** @deprecated use DatasourceValueMappingRule.revisionData instead */
  revisionState: Maybe<GQReferenceDataRevisionState>;
  valueMappingRows: Array<GQValueMappingRow>;
};

export type GQValueMappingFileData = {
  __typename?: 'ValueMappingFileData';
  fileData: GQUserUpload;
  id: Scalars['ID']['output'];
  matchedRows: Scalars['JSONSchema']['output'];
};

export type GQValueMappingFileDataFilter = {
  columnNames: Array<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GQValueMappingOutputOption = {
  __typename?: 'ValueMappingOutputOption';
  category: Maybe<Scalars['String']['output']>;
  descriptionLong: Maybe<Scalars['String']['output']>;
  extraData: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['String']['output'];
  isSuggested: Scalars['Boolean']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GQValueMappingOutputPicker = {
  __typename?: 'ValueMappingOutputPicker';
  columnName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  options: Array<GQValueMappingOutputOption>;
  placeholder: Maybe<Scalars['String']['output']>;
};

export const GQValueMappingPublishStatus = {
  Failed: 'Failed',
  LatestPublishSucceeded: 'LatestPublishSucceeded',
  NeverPublished: 'NeverPublished',
  Processing: 'Processing',
} as const;

export type GQValueMappingPublishStatus =
  (typeof GQValueMappingPublishStatus)[keyof typeof GQValueMappingPublishStatus];
export type GQValueMappingRow = {
  __typename?: 'ValueMappingRow';
  baseRowJson: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQValueMappingRowConnection = {
  __typename?: 'ValueMappingRowConnection';
  edges: Array<Maybe<GQValueMappingRowEdge>>;
  metadata: GQValueMappingRowsMetadata;
  pageInfo: GQPageInfo;
};

export type GQValueMappingRowEdge = {
  __typename?: 'ValueMappingRowEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQValueMappingRow>;
};

export type GQValueMappingRowsMetadata = {
  __typename?: 'ValueMappingRowsMetadata';
  allRowsAreFilled: Scalars['Boolean']['output'];
  flaggedRowCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  suggestionsRowCount: Scalars['Int']['output'];
  totalRowCount: Scalars['Int']['output'];
  unmappedRowCount: Scalars['Int']['output'];
};

export type GQValueMappingTableSourceData = {
  __typename?: 'ValueMappingTableSourceData';
  id: Scalars['ID']['output'];
  outputOptionPickers: Array<GQValueMappingOutputPicker>;
};

export type GQValueMappingTask = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'ValueMappingTask';
    id: Scalars['ID']['output'];
    issues: Maybe<GQDataIssueConnection>;
    mappingRuleId: Scalars['String']['output'];
    measurementProject: GQMeasurementProject;
    measurementProjectId: Scalars['ID']['output'];
    orgId: Scalars['ID']['output'];
    publishStatus: GQValueMappingPublishStatus;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
  };

export type GQValueMappingTaskIssuesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQVendor = {
  __typename?: 'Vendor';
  program: Maybe<Scalars['String']['output']>;
  programUrl: Maybe<Scalars['String']['output']>;
  sbtTarget: Maybe<Scalars['String']['output']>;
  targetYear: Maybe<Scalars['Int']['output']>;
  vendor: Scalars['String']['output'];
};

export type GQVendorConnection = {
  __typename?: 'VendorConnection';
  edges: Array<Maybe<GQVendorEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Vendor' and its cursor. */
export type GQVendorEdge = {
  __typename?: 'VendorEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQVendor>;
};

export type GQVendorMatchingTask = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'VendorMatchingTask';
    assignee: Maybe<GQUser>;
    completedAt: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    measurementProject: GQMeasurementProject;
    wasAutomapped: Scalars['Boolean']['output'];
  };

export type GQVerifyReportAnswerWithAlertInput = {
  reportConfigType: Scalars['String']['input'];
  reportQuestionId: Scalars['String']['input'];
};

export type GQVerifyReportAnswerWithAlertPayload = {
  __typename?: 'VerifyReportAnswerWithAlertPayload';
  status: Scalars['String']['output'];
};

export type GQVerifyReportAnswersPayload = {
  __typename?: 'VerifyReportAnswersPayload';
  failureCount: Scalars['Float']['output'];
  successCount: Scalars['Float']['output'];
};

export type GQWasteEmissionsExclusion = {
  __typename?: 'WasteEmissionsExclusion';
  shouldExclude: Scalars['Boolean']['output'];
  wasteEmissionsMethodology: GQWasteEmissionsMethodology;
};

export type GQWasteEmissionsExclusionInput = {
  shouldExclude: Scalars['Boolean']['input'];
  wasteEmissionsMethodology: GQWasteEmissionsMethodology;
};

export const GQWasteEmissionsMethodology = {
  BuildingWaste: 'BuildingWaste',
  Employees: 'Employees',
  Waste: 'Waste',
} as const;

export type GQWasteEmissionsMethodology =
  (typeof GQWasteEmissionsMethodology)[keyof typeof GQWasteEmissionsMethodology];
export type GQWatershedEmployee = GQIdInterface &
  GQPerson & {
    __typename?: 'WatershedEmployee';
    displayName: Scalars['String']['output'];
    email: Scalars['String']['output'];
    handle: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    /** @deprecated Please use isWatershedEmployee or isWatershedContractor */
    isWatershed: Scalars['Boolean']['output'];
    isWatershedContractor: Scalars['Boolean']['output'];
    isWatershedEmployee: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    orgPointsOfContact: Array<GQOrgPointOfContact>;
    user: GQUser;
  };

export type GQWatershedEmployeeConnection = {
  __typename?: 'WatershedEmployeeConnection';
  edges: Array<Maybe<GQWatershedEmployeeEdge>>;
  pageInfo: GQPageInfo;
};

export type GQWatershedEmployeeEdge = {
  __typename?: 'WatershedEmployeeEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQWatershedEmployee>;
};

export type GQWatershedFootprintReviewRequest = {
  __typename?: 'WatershedFootprintReviewRequest';
  createdAt: Scalars['Date']['output'];
  discussion: Maybe<GQDiscussion>;
  id: Scalars['ID']['output'];
  status: GQWatershedFootprintReviewRequestStatus;
  updatedAt: Scalars['Date']['output'];
  user: GQUser;
};

export const GQWatershedFootprintReviewRequestStatus = {
  Done: 'Done',
  NeedsWatershedResponse: 'NeedsWatershedResponse',
  WatershedReviewInitiated: 'WatershedReviewInitiated',
} as const;

export type GQWatershedFootprintReviewRequestStatus =
  (typeof GQWatershedFootprintReviewRequestStatus)[keyof typeof GQWatershedFootprintReviewRequestStatus];
export const GQWatershedPlan = {
  Custom: 'Custom',
  Enterprise: 'Enterprise',
  Essential: 'Essential',
  Finance: 'Finance',
  None: 'None',
  Other: 'Other',
  PortcoPackage: 'PortcoPackage',
  Premium: 'Premium',
  Standard: 'Standard',
} as const;

export type GQWatershedPlan =
  (typeof GQWatershedPlan)[keyof typeof GQWatershedPlan];
export const GQWatershedPlanLegacy = {
  Lite: 'Lite',
  NetZero: 'NetZero',
  NoPlan: 'NoPlan',
  Pro: 'Pro',
  Standard: 'Standard',
} as const;

export type GQWatershedPlanLegacy =
  (typeof GQWatershedPlanLegacy)[keyof typeof GQWatershedPlanLegacy];
export type GQWorkflowExecution = {
  __typename?: 'WorkflowExecution';
  runId: Maybe<Scalars['String']['output']>;
  workflowId: Scalars['String']['output'];
};

export type GQWorkosConnection = {
  __typename?: 'WorkosConnection';
  connectionType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type GQWorkosDirectory = {
  __typename?: 'WorkosDirectory';
  createdAt: Scalars['DateTime']['output'];
  directoryType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type GQYearMonthInterval = {
  __typename?: 'YearMonthInterval';
  end: Scalars['YearMonth']['output'];
  start: Scalars['YearMonth']['output'];
};

export const GQYesNoUnknown = {
  No: 'No',
  Unknown: 'Unknown',
  Yes: 'Yes',
} as const;

export type GQYesNoUnknown =
  (typeof GQYesNoUnknown)[keyof typeof GQYesNoUnknown];
